import { CCol, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { OrganizationBankAccountFormStoreType } from './OrganizationBankAccountFormStore';

interface PropsType extends FormPropsType {
	organizationBankAccountFormStore: OrganizationBankAccountFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class OrganizationBankAccountForm extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.organizationBankAccountFormStore}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				navTabs={[{ name: 'Дополнительно' }]}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.organizationBankAccountFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.organizationBankAccountFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.organizationBankAccountFormStore.model}
													property="bankItn"
													placeholder="Введите номер"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="ИНН"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.organizationBankAccountFormStore.model}
													property="bankCode"
													placeholder="Введите код"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Код банка"
													mobile
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.organizationBankAccountFormStore.model}
													property="bankBIC"
													placeholder="Введите номер"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="БИК"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.organizationBankAccountFormStore.model}
													property="correspondentText"
													placeholder="Введите текст"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Текст корреспондента"
													mobile
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.organizationBankAccountFormStore.model}
													property="destinationText"
													placeholder="Введите текст"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Текст назначения"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.organizationBankAccountFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankName"
										placeholder="Введите наименование"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										invalid={_.isEmpty(this.props.organizationBankAccountFormStore.model.bankName)}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование банка"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="accountNumber"
										placeholder="Введите номер"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										invalid={_.isEmpty(this.props.organizationBankAccountFormStore.model.accountNumber)}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Номер счета"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankCorrAccount"
										placeholder="Введите счет"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Корр. счет банка для расчетов"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankCity"
										placeholder="Введите город"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Город банка"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankAddress"
										placeholder="Введите адрес"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Адрес банка"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankSwiftCode"
										placeholder="Введите код"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="SWIFT"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.organizationBankAccountFormStore.model}
										property="bankPhone"
										placeholder="Введите телефон"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Телефон банка"
										mobile
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.organizationBankAccountFormStore.setValue(this.props.organizationBankAccountFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.organizationBankAccountFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.organizationBankAccountFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.organizationBankAccountFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(OrganizationBankAccountForm));
