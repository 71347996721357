import CIcon from '@coreui/icons-react';
import { CButton, CTooltip } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { FileType } from '../support/modelTypes';

interface PropsType extends RouteComponentProps {
	title?: any;
	modelStore: any;
	upload(data: any): void;
	parentGroup: FileType;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatImageLoad extends Component<PropsType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.inputFileClick = this.inputFileClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	inputFileClick() {
		this.myRef.current.click();
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files !== null) {
			let data = new FormData();
			let metadata: any = {};
			_.forEach(e.target.files, (item) => {
				data.append(item.name, item);
				metadata[item.name] = {
					id: generateRandomId(),
					name: item.name,
					parentId: this.props.parentGroup.id,
					ownerId: this.props.modelStore.model.id as string,
					ownerType: this.props.modelStore.ownerType
				};
			});
			data.append('metadata', JSON.stringify(metadata));
			this.props.upload(data);
		}
	}
	render() {
		return (
			<>
				<input
					type="file"
					className="d-none"
					ref={this.myRef}
					onChange={this.handleChange}
					onClick={(e: any) => {
						e.target.value = null;
					}}
					multiple
				/>
				{this.props.title ? (
					<CButton size="sm" className="nat__button__hover w-100" onClick={this.inputFileClick}>
						{this.props.title}
					</CButton>
				) : (
					<CTooltip
						content="Загрузить"
						advancedOptions={{
							onShow: () => {
								if (this.injected.directoryStore.models.windowSize >= 1235) {
									return true;
								} else {
									return false;
								}
							}
						}}>
						<CButton variant="ghost" className="d-flex text-white control__panel__button" onClick={this.inputFileClick}>
							<CIcon name="cil-plus" className="align-self-center text-danger" size="lg" />
						</CButton>
					</CTooltip>
				)}
			</>
		);
	}
}

export default withRouter(NatImageLoad);
