import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { catalog } from '../support/Catalog';
import { natOpenTriangle, natCloseTriangle } from '../support/NatIcons';
import { models } from '../support/NatModels';
import { routesMap } from '../support/Routes';
import NatRelatedDocumentsStore, { NatRelatedDocumentsStoreType } from './NatRelatedDocumentsStore';

interface PropsType extends RouteComponentProps {
	toggleNatRelatedDocuments?: (status: boolean) => void;
	isModalOpen: boolean;
	store: any;
}

interface StateType {}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatRelatedDocuments extends Component<PropsType, StateType> {
	myRef: any;
	natRelatedDocumentsStore: NatRelatedDocumentsStoreType;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.resetData = this.resetData.bind(this);
		this.toggleNodeByIcon = this.toggleNodeByIcon.bind(this);
		this.toggleNodeByNode = this.toggleNodeByNode.bind(this);
		this.buildTree = this.buildTree.bind(this);
		this.updateTree = this.updateTree.bind(this);
		this.goTo = this.goTo.bind(this);
		this.natRelatedDocumentsStore = new NatRelatedDocumentsStore();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', true);
		controller
			.fetchObjectHierarchyByOwner(this.props.store.pluralName, this.props.store.model)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'tree', this.buildTree(data));
				}
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', false);
			});
	}
	goTo(item: any) {
		if (!_.isEmpty(item.object)) {
			if (routesMap[`/${models[item.objectType].plural}/:elementId`] !== undefined) {
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'activeKey', item.key);
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'focusKey', item.key);
				let url = `/${models[item.objectType].plural}/${item.object.id}`;
				this.props.history.push(url);
			}
		}
	}
	buildTree(list: Array<any>) {
		let handleGroup = (item, nodes, openNodes) => {
			let treeItem = {
				label: !_.isEmpty(catalog.renderDocumentName(item.object, item.objectType)) ? catalog.renderDocumentName(item.object, item.objectType) : `Объект не найден (${item.objectId})`,
				key: item.objectId,
				object: item.object,
				currentObject: item.currentObject,
				objectType: item.objectType,
				nodes: []
			};
			nodes.push(treeItem);
			let childList = item.childs;
			let openNode: string = item.objectId;
			if (!_.isEmpty(openNodes[openNodes.length - 1])) {
				openNode = openNodes[openNodes.length - 1] + `/${item.objectId}`;
			}
			openNodes.push(openNode);
			if (childList && childList.length > 0) {
				for (let child of childList) {
					handleGroup(child, treeItem.nodes, openNodes);
				}
			}
		};
		let result = [] as Array<any>;
		let openNodes = [] as Array<string>;
		let rootList = list;
		if (rootList && rootList.length > 0) {
			for (let root of rootList) {
				handleGroup(root, result, openNodes);
			}
		}
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'openNodes', openNodes);
		return result;
	}
	toggleNodeByIcon(item: any) {
		const openNodes = _.cloneDeep(this.natRelatedDocumentsStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = openNodes.filter((openNode) => openNode !== item.key);
		} else {
			const nodesMap = _.chain(this.natRelatedDocumentsStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.natRelatedDocumentsStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'openNodes', newOpenNodes);
	}
	toggleNodeByNode(item: any) {
		const openNodes = _.cloneDeep(this.natRelatedDocumentsStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = [...openNodes];
		} else {
			const nodesMap = _.chain(this.natRelatedDocumentsStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.natRelatedDocumentsStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore.treeState, 'openNodes', newOpenNodes);
	}
	resetData() {
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'list', []);
	}
	updateTree() {
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', true);
		this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'tree', []);
		controller
			.fetchObjectHierarchyByOwner(this.props.store.pluralName, this.props.store.model)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'tree', this.buildTree(data));
				}
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natRelatedDocumentsStore.setValue(this.natRelatedDocumentsStore, 'isLoading', false);
			});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ОБНОВИТЬ',
				onClick: () => {
					this.updateTree();
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					if (this.props.toggleNatRelatedDocuments !== undefined) {
						this.props.toggleNatRelatedDocuments(false);
					}
					this.resetData();
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return (
									<form className="d-flex w-100">
										{_.map(controlButtons, (item, index) => {
											if (!item.dontShow) {
												return (
													<CButton
														key={index}
														type="button"
														size="sm"
														className="mr-1 nat__button__hover"
														onClick={() => {
															item.onClick();
														}}>
														{item.title}
													</CButton>
												);
											}
										})}
									</form>
								);
							} else {
								return (
									<CDropdown>
										<CDropdownToggle caret className="nat__button__hover">
											МЕНЮ
										</CDropdownToggle>
										<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CDropdownItem
															key={index}
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CDropdownItem>
													);
												}
											})}
										</CDropdownMenu>
									</CDropdown>
								);
							}
						})()}
					</div>
				</CModalHeader>
				<CModalBody className="p-0 m-4">
					<div className="d-flex h-100">
						<div className="nat__modal__tree nat__modal__tree__fullscreen nat__card__wrapper nat__borders__2-75 w-100">
							<TreeMenu
								activeKey={this.natRelatedDocumentsStore.treeState.activeKey}
								focusKey={this.natRelatedDocumentsStore.treeState.focusKey}
								openNodes={this.natRelatedDocumentsStore.treeState.openNodes}
								data={this.natRelatedDocumentsStore.tree}
								hasSearch={false}>
								{({ items }) => (
									<>
										<ul className="rstm-tree-item-group mb-0 p-3">
											{items.map((item) => (
												<ItemComponent
													style={(() => {
														if (item.currentObject) {
															return { fontWeight: 'bold' };
														}
													})()}
													key={item.key}
													level={item.level}
													label={item.label}
													isOpen={item.isOpen}
													hasNodes={item.hasNodes}
													active={item.active}
													focused={item.focused}
													openedIcon={<CIcon src={natOpenTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
													closedIcon={<CIcon src={natCloseTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
													onClick={() => {
														this.goTo(item);
														this.toggleNodeByNode(item);
													}}
													toggleNode={() => {
														this.toggleNodeByIcon(item);
													}}
												/>
											))}
										</ul>
									</>
								)}
							</TreeMenu>
						</div>
					</div>
				</CModalBody>
				{this.natRelatedDocumentsStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatRelatedDocuments);
