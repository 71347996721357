import { CInputGroup } from '@coreui/react';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';

export interface ChildrenPropsType {
	isButtonShow: boolean;
}

interface PropsType {
	children(childrenProps: ChildrenPropsType): ReactElement;
	mobile?: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}
interface StateType {
	isButtonShow: boolean;
}
@inject('directoryStore')
@observer
class NatInputGroup extends Component<PropsType, StateType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.mouseenter = this.mouseenter.bind(this);
		this.mouseleave = this.mouseleave.bind(this);
		this.state = {
			isButtonShow: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.nat__input')?.addEventListener('mouseenter', this.mouseenter);
		this.myRef.current.querySelector('.nat__input')?.addEventListener('mouseleave', this.mouseleave);
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.isButtonShow === this.state.isButtonShow && this.state.isButtonShow) {
			if (this.myRef.current.querySelectorAll('.nat__input__button').length > 1 && this.myRef.current.querySelectorAll('.nat__input__button').length < 3) {
				this.myRef.current.querySelector('.nat__input__buttons').classList.add('nat__right');
			} else if (this.myRef.current.querySelectorAll('.nat__input__button').length >= 3) {
				this.myRef.current.querySelector('.nat__input__buttons').classList.add('nat__right_1');
			}
		}
	}
	componentWillUnmount() {
		this.myRef.current.querySelector('.nat__input')?.removeEventListener('mouseenter', this.mouseenter);
		this.myRef.current.querySelector('.nat__input')?.removeEventListener('mouseleave', this.mouseleave);
	}
	mouseenter() {
		this.setState(
			{
				isButtonShow: true
			},
			() => {
				if (this.myRef.current.querySelectorAll('.nat__input__button').length > 1 && this.myRef.current.querySelectorAll('.nat__input__button').length < 3) {
					this.myRef.current.querySelector('.nat__input__buttons').classList.add('nat__right');
				} else if (this.myRef.current.querySelectorAll('.nat__input__button').length >= 3) {
					this.myRef.current.querySelector('.nat__input__buttons').classList.add('nat__right_1');
				}
			}
		);
	}
	mouseleave() {
		this.setState(
			{
				isButtonShow: false
			},
			() => {
				if (this.myRef.current.querySelectorAll('.nat__input__button').length > 1 && this.myRef.current.querySelectorAll('.nat__input__button').length < 3) {
					this.myRef.current.querySelector('.nat__input__buttons').classList.remove('nat__right');
				} else if (this.myRef.current.querySelectorAll('.nat__input__button').length >= 3) {
					this.myRef.current.querySelector('.nat__input__buttons').classList.add('nat__right_1');
				}
			}
		);
	}
	render() {
		let childrenProps = {
			isButtonShow: this.state.isButtonShow
		};
		return (
			<CInputGroup innerRef={this.myRef}>
				<div className="nat__input d-flex w-100">{this.props.children(childrenProps)}</div>
			</CInputGroup>
		);
	}
}

export default NatInputGroup;
