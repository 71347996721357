import { CCol, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { CashRegisterShiftFormStoreType } from './CashRegisterShiftFormStore';

interface PropsType extends FormPropsType {
	cashRegisterShiftFormStore: CashRegisterShiftFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class CashRegisterShiftForm extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.cashRegisterShiftFormStore}
				navTabs={[{ name: 'Примечание' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.cashRegisterShiftFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.cashRegisterShiftFormStore.model}
										property="periodAt"
										placeholder="Введите дату"
										type="datetime-local"
										step="1"
										formatter={dateTimeFormatter}
										size="sm"
										disabled
										label="Дата документа"
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.cashRegisterShiftFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.cashRegisterShiftFormStore.model.organizationId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Организация"
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.cashRegisterShiftFormStore.model}
										property="statusId"
										relation="status"
										placeholder="Выберите статус"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'cash_register_shift_statuses' }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.cashRegisterShiftFormStore.model.statusId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Статус"
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.cashRegisterShiftFormStore.model}
										property="cashRegisterId"
										relation="cashRegister"
										placeholder="Выберите кассу"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="cashRegisters"
										onlySelect={true}
										filterWhere={{ deleted: false, ownerId: this.props.cashRegisterShiftFormStore.model.organizationId, ownerType: 'OrmOrganization' }}
										autoComplete="off"
										disabled
										label="Касса ККМ"
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.cashRegisterShiftFormStore.model}
										property="startedAt"
										placeholder="Введите дату"
										type="datetime-local"
										step="1"
										formatter={dateTimeFormatter}
										size="sm"
										disabled
										label="Дата открытия"
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.cashRegisterShiftFormStore.model}
										property="closedAt"
										placeholder="Введите дату"
										type="datetime-local"
										step="1"
										formatter={dateTimeFormatter}
										size="sm"
										disabled
										label="Дата закрытия"
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.cashRegisterShiftFormStore.model}
										property="sequenceNumber"
										placeholder="Введите номер"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Номер"
										mobile
										onClick={() => {
											if (!this.props.cashRegisterShiftFormStore.isSequenceNumberChangedManually) {
												this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
											}
										}}
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.cashRegisterShiftFormStore.setValue(this.props.cashRegisterShiftFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.cashRegisterShiftFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: this.props.cashRegisterShiftFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.cashRegisterShiftFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: this.props.cashRegisterShiftFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.cashRegisterShiftFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(CashRegisterShiftForm));
