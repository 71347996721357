import { observable, action, makeObservable, set } from 'mobx';

export interface FormAlertStoreType {
	showAlert: boolean;
	alertText: string;
	setValue(model: any, key: string, value: any): void;
}

class FormAlertStore implements FormAlertStoreType {
	constructor() {
		makeObservable(this, {
			showAlert: observable,
			alertText: observable,
			setValue: action
		});
	}
	showAlert = false;
	alertText = '';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const formAlertStore = new FormAlertStore();

export default FormAlertStore;
