import CIcon from '@coreui/icons-react';
import { CButton, CCardHeader, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import AlertDialog from '../Alerts/AlertDialog';
import { dateFormatter } from '../Formatters/DateFormatter';
import { stringFormatter } from '../Formatters/StringFormatter';
import NatDefaultCheckbox from '../Inputs/NatDefaultCheckBox';
import NatFormGroupLabel from '../Inputs/NatFormGroupLabel';
import NatValueInput from '../Inputs/NatValueInput';
import NatAclMapping from '../Modals/NatAclMapping';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';
import NatFilters from './NatFilters';
import { ChildrenPropsType } from './NatListContainer';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	name: string;
	columns: Array<any>;
	navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean };
	goTo(path: string, state?: Record<string, unknown>): void;
	childrenProps: ChildrenPropsType;
	store: any;
	children: ReactElement;
	dontCreateNewModel?: boolean;
	dontDeleteModels?: boolean;
	createFormUrlParams?: string;
}

interface StateType {
	querySelector: any;
	isDeleteModalOpen: boolean;
	colId: number | null;
	timer: any;
	isFilterOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatList extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.sortList = this.sortList.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.reactions = [];
		this.state = {
			querySelector: null,
			isDeleteModalOpen: false,
			colId: null,
			timer: null,
			isFilterOpen: false
		};
		this.reactions.push(
			reaction(
				() => this.props.store.chips,
				() => {
					// if (value.length !== previousValue.length) {
					if (!_.isEmpty(this.state.querySelector)) {
						this.state.querySelector.scrollTo(0, 0);
						clearTimeout(this.state.timer);
						this.setState({
							timer: setTimeout(this.props.childrenProps.showList, 500)
						});
					}
					// }
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.myRef.current.querySelector('.rgt-container')?.removeEventListener('scroll', this.handleScrollToElement);
	}
	handleScrollToElement() {
		let scrollTop = this.state.querySelector.scrollTop;
		let scrollHeight = this.state.querySelector.scrollHeight;
		let clientHeight = this.state.querySelector.clientHeight;
		let arr = this.props.store.list;
		let itemsPerPage = this.props.store.itemsPerPage;
		let itemsListLength = arr.length;
		if (scrollTop >= scrollHeight - clientHeight && this.props.store.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
			this.props.store.setValue(this.props.store, 'currentPage', this.props.store.currentPage + 1);
			this.props.store.setValue(this.props.store, 'filterSkip', this.props.store.currentPage * this.props.store.itemsPerPage);
			let filter: any = this.props.childrenProps.createFilter();
			this.props.store.setValue(this.props.store, 'responseCode', 0);
			controller
				.findAll(this.props.store.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.store.setValue(this.props.store, 'list', this.props.store.list.concat(data));
					}
					this.props.store.setValue(this.props.store, 'responseCode', 200);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		}
	}
	sortList(colId: number, isAsc: boolean) {
		let order: Array<string> = [];
		this.props.store.setValue(this.props.store, 'sortConfig', { colId, isAsc });
		if (colId !== null) {
			const groupedColumns = _.groupBy(this.props.columns, 'id');
			const sortBy = isAsc ? 'ASC' : 'DESC';
			order = [`${groupedColumns[colId][0].field} ${sortBy}`];
		} else {
			order = ['createdAt DESC'];
		}
		this.state.querySelector.scrollTo(0, 0);
		this.props.store.setValue(this.props.store, 'filterOrder', order);
		this.props.store.setValue(this.props.store, 'currentPage', 0);
		this.props.store.setValue(this.props.store, 'filterSkip', 0);
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		let filter: any = this.props.childrenProps.createFilter();
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				}
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	toggleDeleteModal(status: boolean) {
		this.setState({
			isDeleteModalOpen: status
		});
	}
	toggleFilters(status: boolean) {
		this.setState({
			isFilterOpen: status
		});
	}
	render() {
		return (
			<div className="d-flex nat__page default__nat__card__body__height nat__scrollbar nat__borders__2-75">
				<div className="nat__table__wrapper nat__table__hover">
					<CCardHeader className="nat__page__header nat__card__wrapper d-flex flex-column mt-4 p-2 mx-4 nat__borders__2-75">
						<div className="d-flex align-items-center">
							<div className="d-flex">
								<CButton
									size="sm"
									variant="ghost"
									className="d-flex nat__button__hover mr-1"
									onClick={() => {
										let url: string = this.props.navigationRoute.url + '/new';
										if (this.props.createFormUrlParams) {
											url = url + `?${this.props.createFormUrlParams}`;
										}
										this.props.goTo(url);
									}}
									disabled={this.props.dontCreateNewModel}>
									{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-plus" size="lg" /> : 'ДОБАВИТЬ'}
								</CButton>
								<CButton
									size="sm"
									variant="ghost"
									className="d-flex nat__button__hover mr-1"
									onClick={() => {
										this.state.querySelector.scrollTo(0, 0);
										this.props.store.setValue(this.props.store, 'currentPage', 0);
										this.props.store.setValue(this.props.store, 'filterSkip', 0);
										this.props.store.setValue(this.props.store, 'responseCode', 0);
										this.props.store.setValue(this.props.store, 'list', []);
										this.props.store.setValue(this.props.store, 'collectedModels', []);
										let filter: any = {
											skip: this.props.store.filterSkip,
											limit: this.props.store.itemsPerPage,
											order: this.props.store.filterOrder,
											include: this.props.store.filterInclude
										};
										if (!_.isEmpty(this.props.store.filterWhere)) {
											filter.where = this.props.store.filterWhere;
										}
										controller
											.findAll(this.props.store.pluralName, filter)
											.then((data) => {
												if (!_.isEmpty(data)) {
													this.props.store.setValue(this.props.store, 'list', data);
												} else {
													this.props.store.setValue(this.props.store, 'list', []);
												}
												this.props.store.setValue(this.props.store, 'responseCode', 200);
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
									}}>
									{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-reload" size="lg" /> : 'ОБНОВИТЬ'}
								</CButton>
								<CDropdown>
									<CDropdownToggle variant="ghost" className="nat__button__hover" size="sm">
										{`ИЗМЕНИТЬ (${this.props.store.collectedModels.length})`}
									</CDropdownToggle>
									<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
										<CDropdownItem
											onClick={() => {
												this.toggleDeleteModal(true);
											}}
											disabled={this.props.dontDeleteModels ? this.props.dontDeleteModels : this.props.store.collectedModels.length === 0}>
											Отметить/снять отметку удаления
										</CDropdownItem>
										<CDropdownItem
											onClick={() => {
												this.props.childrenProps.toggleAclModal(true);
											}}
											disabled={this.props.dontDeleteModels ? this.props.dontDeleteModels : this.props.store.collectedModels.length === 0}>
											Доступ
										</CDropdownItem>
									</CDropdownMenu>
								</CDropdown>
								<AlertDialog
									isModalOpen={this.state.isDeleteModalOpen}
									title="Выполнить действие?"
									buttonItemList={[
										{
											title: 'ДА',
											onClick: () => {
												this.toggleDeleteModal(false);
												catalog
													.makeDeleted(this.props.store.pluralName, this.props.store.collectedModels)
													.then(() => {
														this.props.store.setValue(this.props.store, 'responseCode', 0);
														this.props.store.setValue(this.props.store, 'currentPage', 0);
														this.props.store.setValue(this.props.store, 'filterSkip', 0);
														let filter: any = {
															skip: this.props.store.filterSkip,
															limit: this.props.store.itemsPerPage,
															order: this.props.store.filterOrder,
															include: this.props.store.filterInclude
														};
														if (!_.isEmpty(this.props.store.filterWhere)) {
															filter.where = this.props.store.filterWhere;
														}
														this.props.store.setValue(this.props.store, 'collectedModels', []);
														return controller.findAll(this.props.store.pluralName, filter);
													})
													.then((data) => {
														this.props.store.setValue(this.props.store, 'list', []);
														if (!_.isEmpty(data)) {
															this.props.store.setValue(this.props.store, 'list', data);
														}
														this.props.store.setValue(this.props.store, 'responseCode', 200);
													})
													.catch((error) => {
														catalog.handleNatError(error);
													});
											}
										},
										{
											title: 'ОТМЕНА',
											onClick: () => {
												this.toggleDeleteModal(false);
											}
										}
									]}
								/>
							</div>
							<CForm className="form-horizontal d-flex ml-auto align-items-center">
								<CButton
									type="button"
									size="sm"
									className="nat__button__hover"
									onClick={() => {
										this.toggleFilters(true);
									}}>
									{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-filter" size="lg" /> : `ФИЛЬТРЫ (${this.props.store.chips.length})`}
								</CButton>
								{this.state.isFilterOpen && (
									<NatFilters
										store={this.props.store}
										toggleModal={this.toggleFilters}
										isModalOpen={this.state.isFilterOpen}
										resetSearchFields={this.props.childrenProps.resetSearchFields}
										hierarchical={this.props.childrenProps.hierarchical}
										ownerId={this.props.childrenProps.ownerId}
										enumerationItems={this.props.childrenProps.enumerationItems}>
										<>
											{this.props.store.chipsFields.sequenceNumber && (
												<NatValueInput<string> object={this.props.store.chipsFields.sequenceNumber} property="id" placeholder="Введите номер" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Номер" />
											)}
											{this.props.store.chipsFields.name && (
												<NatValueInput<string>
													object={this.props.store.chipsFields.name}
													property="id"
													placeholder="Введите наименование"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Наименование"
												/>
											)}
											{this.props.children}
											{this.props.store.chipsFields.createdAtGt && (
												<NatValueInput<Date> object={this.props.store.chipsFields.createdAtGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Создан от" />
											)}
											{this.props.store.chipsFields.createdAtLt && (
												<NatValueInput<Date> object={this.props.store.chipsFields.createdAtLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="до" />
											)}
											{this.props.store.chipsFields.periodAtGt && (
												<NatValueInput<Date> object={this.props.store.chipsFields.periodAtGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Документ от" />
											)}
											{this.props.store.chipsFields.periodAtLt && (
												<NatValueInput<Date> object={this.props.store.chipsFields.periodAtLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="до" />
											)}
											{this.props.store.chipsFields.status && (
												<NatFormGroupLabel label="Статус" labelColumnSettings="7" labelColumnClassName="text-left align-self-center text-black pl-0" inputColumnClassName="pl__4_5" row={false}>
													{_.map(this.props.store.chipsFields.status.list, (item, index) => {
														return <NatDefaultCheckbox key={index} object={item} property="isChecked" columnClassName="" wrapperClassName="checkbox" row={true} label={catalog.renderCatalogName(item)} />;
													})}
												</NatFormGroupLabel>
											)}
											<NatFormGroupLabel label="Удаленные" labelColumnSettings="7" labelColumnClassName="text-left align-self-center text-black pl-0" inputColumnClassName="pl__4_5" row={false}>
												<NatDefaultCheckbox
													object={this.props.store.chipsFields.deleted.display}
													property="isChecked"
													columnClassName=""
													wrapperClassName="checkbox"
													row={true}
													label={this.props.store.chipsFields.deleted.display.title}
												/>
												<NatDefaultCheckbox
													object={this.props.store.chipsFields.deleted.doNotDisplay}
													property="isChecked"
													columnClassName=""
													wrapperClassName="checkbox"
													row={true}
													label={this.props.store.chipsFields.deleted.doNotDisplay.title}
												/>
											</NatFormGroupLabel>
										</>
									</NatFilters>
								)}
							</CForm>
						</div>
					</CCardHeader>
					{this.props.store.responseCode === 0 && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
					<div className="nat__page__table__wrapper nat__card__wrapper nat__borders__2-75 m-4 default__nat__card__body__height">
						<div className="nat__page__table" ref={this.myRef}>
							<GridTable
								columns={this.props.columns}
								rows={this.props.store.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют', selected: 'Выбрано' }}
								onRowClick={({ data, column }: { data: any; column: any }) => {
									if (column.id !== 'checkbox') {
										this.props.goTo(`${this.props.navigationRoute.url}/` + data.id);
									}
								}}
								showSearch={false}
								showColumnVisibilityManager={false}
								onColumnsChange={() => {}}
								showRowsInformation={false}
								selectedRowsIds={_.map(this.props.store.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => {
									this.props.childrenProps.collectModels(selectedRowsIds);
								}}
								onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
									this.sortList(colId, isAsc);
								}}
								sort={this.props.store.sortConfig}
							/>
						</div>
					</div>
					{this.props.childrenProps.isAclModalOpen && (
						<NatAclMapping isModalOpen={this.props.childrenProps.isAclModalOpen} toggleNatAclMapping={this.props.childrenProps.toggleAclModal} subject={this.props.store.collectedModels} model={this.props.store.name} />
					)}
				</div>
			</div>
		);
	}
}

export default withRouter(NatList);
