import { observable, action, makeObservable, set } from 'mobx';

export interface NatRelatedDocumentsStoreType {
	list: Array<any>;
	treeState: any;
	tree: Array<any>;
	isLoading: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatRelatedDocumentsStore implements NatRelatedDocumentsStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			tree: observable,
			treeState: observable,
			isLoading: observable,
			setValue: action
		});
	}
	list = [];
	tree = [] as Array<any>;
	treeState = {
		activeKey: '',
		focusKey: '',
		openNodes: [] as string[],
		nodes: [] as any[]
	};
	isLoading = false;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natRelatedDocumentsStore = new NatRelatedDocumentsStore();

export default NatRelatedDocumentsStore;
