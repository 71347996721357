import { cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CModal, CModalHeader } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { catalog } from '../support/Catalog';

interface PropsType extends RouteComponentProps {
	isModalOpen: boolean;
	toggleModal(status: boolean): void;
	store?: any;
	children: ReactElement;
	buttonItemList?: Array<ButtonItem>;
	hierarchical?: boolean;
	resetSearchFields?: () => void;
	ownerId?: string;
	enumerationItems?: string;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('navigationContainerStore', 'directoryStore', 'userStore')
@observer
class AlertDialog extends Component<PropsType> {
	reactions: Array<IReactionDisposer>;
	treeRef: any;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.removeChips = this.removeChips.bind(this);
		this.createChips = this.createChips.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetSearchFields = this.resetSearchFields.bind(this);
	}
	componentDidMount() {
		if (this.props.ownerId && this.props.enumerationItems) {
			if (_.isEmpty(this.props.store.chipsFields[this.props.enumerationItems].list)) {
				let statusFilter: any = {
					where: { ownerId: this.props.ownerId },
					order: ['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]
				};
				controller
					.findAll('enumerationItems', statusFilter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							_.forEach(data, (item) => {
								item.isChecked = false;
							});
							if (this.props.enumerationItems !== undefined) {
								this.props.store.setValue(this.props.store.chipsFields[this.props.enumerationItems], 'list', data);
							}
							this.makeReactions();
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			} else {
				this.makeReactions();
			}
		} else {
			this.makeReactions();
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		if (this.props.store.chipsFields !== undefined) {
			_.forIn(this.props.store.chipsFields, (item) => {
				this.reactions.push(
					reaction(
						() => item.id,
						(value, previousValue) => {
							if (value !== previousValue) {
								let chips: Array<any> = [];
								let chipsMap = _.chain(item.chips).keyBy('id').value();
								if (value !== null && value !== undefined && value !== '') {
									if (chipsMap[value] === undefined) {
										if (item.isRelation) {
											chips.push(item.relation);
											item.chips = _.cloneDeep(item.chips.concat(chips));
										} else {
											if (item.isDate) {
												if (item.isGt) {
													chips.push({
														id: generateRandomId(),
														name: {
															ru: `C ${item.id.toLocaleDateString()}`
														}
													});
												} else {
													chips.push({
														id: generateRandomId(),
														name: {
															ru: `по ${item.id.toLocaleDateString()}`
														}
													});
												}
											} else if (item.isBoolean) {
												if (value) {
													chips.push({
														id: generateRandomId(),
														name: { ru: `${item.title}: отображать` }
													});
												} else {
													chips.push({
														id: generateRandomId(),
														name: {
															ru: `${item.title}: не отображать`
														}
													});
												}
											} else if (item.isPrice) {
												if (item.isGte) {
													chips.push({
														id: generateRandomId(),
														name: {
															ru: `C ${item.id}`
														}
													});
												} else {
													chips.push({
														id: generateRandomId(),
														name: {
															ru: `по ${item.id}`
														}
													});
												}
											} else {
												chips.push({
													id: generateRandomId(),
													name: {
														ru: `Поиск по: ${item.title}`
													}
												});
											}
											item.chips = chips;
										}
									}
								} else {
									if (!item.isRelation) {
										item.chips = [];
									} else {
										if (!item.list) {
											item.chips = [];
										}
									}
								}
								this.createChips();
							}
						}
					)
				);
				if (item.isBoolean) {
					this.reactions.push(
						reaction(
							() => item.display.isChecked,
							(value) => {
								if (value) {
									item.doNotDisplay.isChecked = false;
									item.id = true;
									if (this.props.hierarchical) {
										this.props.store.searchFields.deleted = true;
									}
								} else {
									if (!item.doNotDisplay.isChecked) {
										item.display.isChecked = true;
									}
								}
							}
						)
					);
					this.reactions.push(
						reaction(
							() => item.doNotDisplay.isChecked,
							(value) => {
								if (value) {
									item.display.isChecked = false;
									item.id = false;
									if (this.props.hierarchical) {
										this.props.store.searchFields.deleted = false;
									}
								} else {
									if (!item.display.isChecked) {
										item.doNotDisplay.isChecked = true;
									}
								}
							}
						)
					);
				}
				if (item.isRelation) {
					if (!_.isEmpty(item.list)) {
						_.forEach(item.list, (itm) => {
							this.reactions.push(
								reaction(
									() => itm.isChecked,
									(value) => {
										let chipsMap = _.chain(item.chips).keyBy('id').value();
										if (value) {
											item.id = itm.id;
											item.relation = itm;
										} else {
											item.id = null;
											if (chipsMap[itm.id] !== undefined) {
												item.chips.splice(item.chips.indexOf(chipsMap[itm.id]), 1);
												this.createChips();
											}
										}
									}
								)
							);
						});
					}
				}
			});
		}
	}
	createChips() {
		let dates: Array<any> = [];
		let prices: Array<any> = [];
		let other: Array<any> = [];
		_.forIn(this.props.store.chipsFields, (value) => {
			if (value.isDate) {
				dates = dates.concat(_.get(value, 'chips', []));
			} else if (value.isPrice) {
				prices = prices.concat(_.get(value, 'chips', []));
			} else {
				other = other.concat(value.chips);
			}
		});
		this.props.store.setValue(this.props.store, 'chips', dates.concat(prices.concat(other)));
	}
	removeChips(chip: any) {
		_.forIn(this.props.store.chipsFields, (value) => {
			let chipsMap = _.chain(value.chips).keyBy('id').value();
			runInAction(() => {
				if (chipsMap[chip.id] !== undefined) {
					if (value.isRelation) {
						if (value.id === chip.id) {
							value.id = null;
							delete value.relation;
						}
						if (!_.isEmpty(value.list)) {
							let listMap = _.chain(value.list).keyBy('id').value();
							if (listMap[chip.id] !== undefined) {
								listMap[chip.id].isChecked = false;
							}
						}
					} else {
						if (value.isBoolean) {
							if (value.id) {
								value.id = false;
								value.doNotDisplay.isChecked = true;
							} else {
								value.id = true;
								value.display.isChecked = true;
							}
						} else {
							value.id = null;
						}
					}
					value.chips.splice(value.chips.indexOf(chip), 1);
				}
			});
		});
		this.createChips();
	}
	resetSearchFields() {
		if (this.props.resetSearchFields) {
			this.props.resetSearchFields();
		}
		this.createChips();
		this.makeReactions();
	}
	render() {
		return (
			<div className="nat__filter__menu">
				<CModal
					show={this.props.isModalOpen}
					onClose={() => {
						this.props.toggleModal(false);
					}}
					className={(() => {
						if (this.injected.directoryStore.models.windowSize >= 1451) {
							return '';
						} else if (this.injected.directoryStore.models.windowSize <= 1450 && this.injected.directoryStore.models.windowSize >= 805) {
							return 'modal__dialog_45';
						} else if (this.injected.directoryStore.models.windowSize <= 804 && this.injected.directoryStore.models.windowSize >= 570) {
							return 'modal__dialog_65';
						} else {
							return 'modal__dialog_100';
						}
					})()}>
					<CModalHeader className="nat__filter__menu__header nat__card__wrapper d-flex flex-column mt-4 p-2 mx-4 nat__borders__2-75">
						<div className="d-flex align-items-center">
							{this.props.buttonItemList !== undefined ? (
								this.props.buttonItemList.map((value: ButtonItem, index: number) => {
									return (
										<CButton key={index} type="button" size="sm" className="mr-1 nat__button__hover" onClick={value.onClick}>
											{value.title}
										</CButton>
									);
								})
							) : (
								<CButton
									type="button"
									size="sm"
									className="mr-1 nat__button__hover"
									onClick={() => {
										this.resetSearchFields();
									}}>
									ОЧИСТИТЬ
								</CButton>
							)}
							{this.injected.directoryStore.models.windowSize < 570 && (
								<CButton
									type="button"
									size="sm"
									className="mr-1 nat__button__hover"
									onClick={() => {
										this.props.toggleModal(false);
									}}>
									ЗАКРЫТЬ
								</CButton>
							)}
						</div>
					</CModalHeader>
					<div className="chip d-flex mx-4 mt-4">
						<div className="d-flex flex-wrap align-items-center">
							{this.props.store !== undefined &&
								_.map(this.props.store.chips, (item, index) => {
									return (
										<CBadge shape="pill" key={index} className="m-1 d-flex align-items-center nat__badge text-white">
											<div className="mr-1">{catalog.renderCatalogName(item)}</div>
											<CButton
												onClick={() => {
													this.removeChips(item);
												}}
												className="mb-1 p-0 chip__close__button">
												<CIcon content={cilX} size="sm" className="text-white m-0" />
											</CButton>
										</CBadge>
									);
								})}
						</div>
					</div>
					<div className="nat__filter__menu__body mx-2 px-5 mb-3 h-100 nat__scrollbar">{this.props.children}</div>
				</CModal>
			</div>
		);
	}
}

export default withRouter(AlertDialog);
