import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CFormGroup, CInput, CLabel } from '@coreui/react';
import _ from 'lodash';
import { reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import ValueFormatter from '../Formatters/ValueFormatter';
import NatSelection from '../Modals/NatSelection';
import { NatSelectionStoreType } from '../Modals/NatSelectionStore';
import { catalog } from '../support/Catalog';
import { routesMap } from '../support/Routes';
import NatRelationInputStore, { NatRelationInputStoreType } from './InputsStore/NatRelationInputStore';
import NatInputGroup from './NatInputGroup';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	goTo?: (path: string, state?: Record<string, unknown>, handlers?: Record<string, unknown>) => void;
	inputColumnClassName?: string;
	inputClassName?: string;
	type: string;
	invalid?: boolean;
	placeholder: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	useHandleEnter?: boolean;
	relation: string;
	modelName?: string;
	pluralName: string;
	include?: Array<any>;
	filter?: any;
	onlySelect?: boolean;
	filterWhere?: any;
	filterOrder?: any;
	disabled?: boolean;
	readyData?: boolean;
	relations?: Array<any>;
	apiMethod?: () => any;
	placement?: string;
	renderName?: (model: any) => string;
	or?: (value) => Array<any>;
	backspace?: (object, value) => void;
	mobile?: boolean;
	create?: (pluralName, handlers?: Record<string, unknown>) => void;
	hierarchicalSelect?: boolean;
	treeSelect?: boolean;
	disableWrapper?: boolean;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatRelationInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	dropdownRef: any;
	dropdownActionsRef: any;
	natRelationInputStore: NatRelationInputStoreType;
	id: string;
	constructor(props: PropsType<T>) {
		super(props);
		this.dropdownRef = React.createRef();
		this.dropdownActionsRef = React.createRef();
		this.natRelationInputStore = new NatRelationInputStore();
		this.handleFocus = this.handleFocus.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.createFilter = this.createFilter.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.buildSelectedItems = this.buildSelectedItems.bind(this);
		this.saveItems = this.saveItems.bind(this);
		this.id = generateRandomId();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.dropdownRef.current.querySelector('.nat__dropdown__list')?.addEventListener('scroll', this.handleScrollToElement);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'querySelector', this.dropdownRef.current.querySelector('.nat__dropdown__list'));
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						if (_.isEmpty(this.props.object[this.props.relation]) && !_.isEmpty(this.props.object[this.props.property])) {
							let filter: any = {
								where: {
									id: this.props.object[this.props.property]
								}
							};
							if (!_.isEmpty(this.props.include)) {
								filter.include = this.props.include;
							}
							controller
								.findAll(this.props.pluralName, filter)
								.then((data) => {
									if (!_.isEmpty(data)) {
										runInAction(() => {
											this.props.object[this.props.relation] = data[0];
										});
										this.setState({
											value: this.renderValue(this.getValue())
										});
									}
								})
								.catch((error) => {
									catalog.handleNatError(error);
								});
						}
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
		this.addValidation(this.props.validation);
	}
	componentWillUnmount() {
		this.dropdownRef.current.querySelector('.nat__dropdown__list')?.removeEventListener('scroll', this.handleScrollToElement);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'querySelector', null);
		this.reactions.forEach((dispose) => dispose());
	}
	toggleDropDown() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', 0);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', !this.natRelationInputStore.showDropdown);
		this.createFilter();
	}
	handleScrollToElement() {
		if (!this.props.readyData) {
			if (!this.props.apiMethod) {
				let scrollTop = this.natRelationInputStore.querySelector.scrollTop;
				let scrollHeight = this.natRelationInputStore.querySelector.scrollHeight;
				let clientHeight = this.natRelationInputStore.querySelector.clientHeight;
				let arr = this.natRelationInputStore.relations;
				let itemsPerPage = this.natRelationInputStore.itemsPerPage;
				let itemsListLength = arr.length;
				if (scrollTop >= scrollHeight - clientHeight && this.natRelationInputStore.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
					this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', this.natRelationInputStore.currentPage + 1);
					this.natRelationInputStore.setValue(this.natRelationInputStore.filter, 'skip', this.natRelationInputStore.currentPage * this.natRelationInputStore.itemsPerPage);
					let filter = this.natRelationInputStore.filter;
					controller
						.findAll(this.props.pluralName, filter)
						.then((data) => {
							if (!_.isEmpty(data)) {
								this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', this.natRelationInputStore.relations.concat(data));
							}
						})
						.catch((error) => {
							catalog.handleNatError(error);
						});
				}
			}
		}
	}
	createFilter() {
		let filter: any = {
			skip: this.natRelationInputStore.currentPage * this.natRelationInputStore.itemsPerPage,
			limit: this.natRelationInputStore.itemsPerPage,
			order: this.natRelationInputStore.order
		};
		let defaultWhere = {};
		if (!_.isEmpty(this.props.filterWhere)) {
			Object.assign(defaultWhere, this.props.filterWhere);
		}
		filter.where = defaultWhere;
		if (!_.isEmpty(this.props.include)) {
			filter.include = this.props.include;
		}
		if (!_.isEmpty(this.props.filterOrder)) {
			filter.order = this.props.filterOrder;
		}
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'filter', filter);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (!_.get(this.props, 'onlySelect', false)) {
			this.setState({
				value: e.target.value
			});
			this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', 0);
			this.natRelationInputStore.querySelector.scrollTo(0, 0);
			clearTimeout(this.natRelationInputStore.timer);
			this.natRelationInputStore.setValue(
				this.natRelationInputStore,
				'timer',
				setTimeout(() => {
					let filter = this.natRelationInputStore.filter;
					let or: Array<any> = [];
					if (this.props.or !== undefined) {
						or = this.props.or(this.state.value);
					} else {
						or = [
							{
								[`name.${this.injected.directoryStore.models.language}`]: {
									like: this.state.value,
									options: 'i'
								}
							},
							{
								sequenceNumber: {
									like: this.state.value
								}
							}
						];
					}
					this.natRelationInputStore.setValue(this.natRelationInputStore.filter, 'skip', 0);
					this.natRelationInputStore.setValue(this.natRelationInputStore.filter.where, 'or', or);
					if (this.props.apiMethod) {
						this.apiMethod();
					} else {
						controller
							.findAll(this.props.pluralName, filter)
							.then((data) => {
								if (!_.isEmpty(data)) {
									this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', data);
								} else {
									this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
								}
							})
							.catch((error) => {
								catalog.handleNatError(error);
							});
					}
				}, 500)
			);
			const isDelete = this.natRelationInputStore.oldValue && e.target.value.length < this.natRelationInputStore.oldValue.length;
			if (isDelete) {
				// runInAction(() => {
				// 	this.props.object[this.props.property] = null;
				// 	if (!_.isEmpty(this.props.object[this.props.relation])) {
				// 		if (this.props.backspace !== undefined) {
				// 			this.props.backspace(this.props.object[this.props.relation], e.target.value);
				// 		} else {
				// 			if (!_.isEmpty(this.props.object[this.props.relation].name)) {
				// 				this.props.object[this.props.relation].name[this.injected.directoryStore.models.language] = e.target.value;
				// 			}
				// 			if (!_.isEmpty(this.props.object[this.props.relation].displayName)) {
				// 				this.props.object[this.props.relation].displayName[this.injected.directoryStore.models.language] = e.target.value;
				// 			}
				// 			if (!_.isEmpty(this.props.object[this.props.relation].defaultName)) {
				// 				this.props.object[this.props.relation].defaultName[this.injected.directoryStore.models.language] = e.target.value;
				// 			}
				// 		}
				// 	}
				// });
			}
			this.natRelationInputStore.setValue(this.natRelationInputStore, 'oldValue', e.target.value);
		}
	}
	handleClickOutside() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', false);
	}
	handleFocus(event: any) {
		if (this.validate(this.props.object, event.target.value)) {
			this.natRelationInputStore.setValue(this.natRelationInputStore, 'oldValue', event.target.value);
		}
		event.target.select();
	}
	handleKeyDown(event: any) {
		if (event.keyCode === 13) {
			let form = event.target.form;
			if (form && form.length > 0) {
				let index = Array.prototype.indexOf.call(form, event.target);
				let length = form.length;
				let nextElement = null;
				for (index; index < length; index++) {
					let nextElement = _.get(form, `elements[${index + 1}]`, null);
					if (nextElement && !nextElement.disabled && nextElement.type !== 'checkbox') {
						nextElement.focus();
						break;
					} else {
						nextElement = null;
					}
				}
				if (!nextElement) {
					event.target.blur();
				}
				event.preventDefault();
				this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', false);
			}
		} else if (event.keyCode === 8 || event.keyCode === 46) {
			if (_.get(this.props, 'onlySelect', false)) {
				this.setState({
					value: ''
				});
				runInAction(() => {
					this.props.object[this.props.property] = null;
					delete this.props.object[this.props.relation];
				});
				this.natRelationInputStore.setValue(this.natRelationInputStore, 'oldValue', '');
			}
		}
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value);
	}

	renderValue(value: T | undefined): string {
		return this.props.formatter.renderValue(value, this.props.object, this.props.property, this.props.renderName);
	}

	setValue(model: any) {
		let object: any = this.props.object;
		if (this.validate(this.props.object, model)) {
			runInAction(() => {
				if (!_.isEmpty(model.id)) {
					if (object[this.props.property] !== model.id) {
						object[this.props.relation] = model;
						object[this.props.property] = model.id;
					}
				}
			});
			this.natRelationInputStore.setValue(this.natRelationInputStore, 'oldValue', this.props.renderName !== undefined ? this.props.renderName(model) : model.name[this.injected.directoryStore.models.language]);
		}
	}

	getValue(): T {
		let object: any = this.props.object;
		if (object[this.props.relation] !== undefined) {
			return object[this.props.relation];
		}
		return '' as any;
	}
	apiMethod() {
		if (this.props.apiMethod) {
			this.props
				.apiMethod()
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', data);
					} else {
						this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
					}
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		}
	}
	toggleNatSelection(index: number) {
		const position = this.natRelationInputStore.modals.indexOf(index);
		let newDetails = this.natRelationInputStore.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'modals', newDetails);
	}
	buildSelectedItems(arrayIds: Array<string>, store: NatSelectionStoreType) {
		const ids = arrayIds;
		const dict = _.chain(store.list).cloneDeep().keyBy('id').value();
		const currentSelectedItemsMap = _.chain(store.selectedItems).cloneDeep().keyBy('id').value();
		_.map(ids, (item: string) => {
			const selectedItem = dict[item];
			runInAction(() => {
				if (currentSelectedItemsMap[selectedItem.id] === undefined) {
					store.selectedItems.push(selectedItem);
				}
			});
		});
		const buffer = [] as Array<any>;
		let idsMap = _.chain(arrayIds)
			.keyBy((item) => {
				return item;
			})
			.value();
		_.map(store.selectedItems, (item: any) => {
			if (idsMap[item.id] !== undefined) {
				buffer.push(item);
			}
		});
		runInAction(() => {
			store.selectedItems = buffer;
		});
	}
	saveItems(store: NatSelectionStoreType) {
		if (!_.isEmpty(store.selectedItems)) {
			this.setValue(store.selectedItems[0]);
		}
	}
	render() {
		return (
			<CDropdown innerRef={this.dropdownRef} className={_.isEmpty(this.props.label) ? 'nat__dropdown__without__label w-100 nat__dropdown' : 'w-100 nat__dropdown'}>
				<NatInputGroup mobile={this.props.mobile}>
					{(childrenProps) => (
						<CFormGroup
							row
							className={(() => {
								if (!this.props.disableWrapper) {
									if (this.injected.directoryStore.models.windowSize < 490) {
										return 'w-100 align-items-center nat__form__group flex-nowrap';
									} else {
										return 'w-100 align-items-center nat__form__group';
									}
								} else {
									return 'w-100 align-items-center m-0';
								}
							})()}>
							{!_.isEmpty(this.props.label) && (
								<CLabel
									htmlFor={this.id}
									className={(() => {
										if (this.props.label) {
											if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
												return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile';
											} else {
												return 'align-self-center text-truncate pl-3 m-0';
											}
										}
									})()}>
									{this.props.label}
								</CLabel>
							)}
							<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0 d-flex' : 'align-self-center pr-0 pl-0 d-flex'}>
								<CInput
									id={this.id}
									invalid={this.props.invalid}
									type={this.props.type}
									name={this.props.property}
									placeholder={this.props.placeholder}
									value={this.state.value}
									onChange={this.handleChange}
									onFocus={this.handleFocus}
									size={this.props.size}
									className={this.props.inputClassName}
									autoComplete={this.props.autoComplete}
									onClick={(e) => {
										if (!this.natRelationInputStore.showDropdown) {
											this.toggleDropDown();
											let filter = this.natRelationInputStore.filter;
											if (!this.props.readyData) {
												if (this.props.apiMethod) {
													this.apiMethod();
												} else {
													controller
														.findAll(this.props.pluralName, filter)
														.then((data) => {
															if (!_.isEmpty(data)) {
																this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', data);
															}
														})
														.catch((error) => {
															catalog.handleNatError(error);
														});
												}
											}
										}
										e.stopPropagation();
									}}
									onBlur={() => {
										if (!this.natRelationInputStore.showDropdown) {
											this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
											this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', 0);
											this.createFilter();
										}
									}}
									onKeyDown={this.handleKeyDown}
									disabled={this.props.disabled}
								/>
								{childrenProps.isButtonShow && (
									<div
										className={(() => {
											if (this.props.mobile) {
												if (this.injected.directoryStore.models.windowSize > 766) {
													if (this.props.disableWrapper) {
														return 'nat__input__buttons nat__input__buttons__mobile';
													} else {
														return 'nat__input__buttons';
													}
												} else {
													return 'nat__input__buttons nat__input__buttons__mobile';
												}
											} else {
												return 'nat__input__buttons';
											}
										})()}>
										{_.map(this.props.formatter.getActionButtons(this.props.object, this.props.property, this.props.pluralName), (item, index) => {
											let button = (
												<CButton
													key={index}
													type="button"
													className={item.className ? item.className : 'nat__input__button'}
													title={item.title}
													onClick={() => {
														item.onClick(this.props.object, this.props.property, this.props.relation, this.natRelationInputStore);
													}}
													to={item.to}>
													<CIcon name={item.icon} className="text-dark" size="sm" />
												</CButton>
											);
											if (item.disable) {
												if (!this.props.disabled) {
													return button;
												}
											} else {
												return button;
											}
										})}
									</div>
								)}
							</CCol>
						</CFormGroup>
					)}
				</NatInputGroup>
				<CDropdownMenu className="rounded-0 py-0" show={this.natRelationInputStore.showDropdown}>
					<div className="nat__dropdown__list mw-100 nat__dropdown__white__scrollbar">
						{(() => {
							let array = [] as Array<any>;
							if (!this.props.readyData) {
								array = this.natRelationInputStore.relations;
							} else {
								if (this.props.relations !== undefined) {
									array = this.props.relations;
								}
							}
							if (!_.isEmpty(array)) {
								return array.map((item: any, index: number) => {
									return (
										<CDropdownItem
											key={index}
											onClick={(e) => {
												if (item) {
													this.setValue(item);
													this.toggleDropDown();
												}
												e.stopPropagation();
											}}>
											<span className="text-truncate" title={this.props.renderName !== undefined ? this.props.renderName(item) : catalog.renderCatalogName(item)}>
												{this.props.renderName !== undefined ? this.props.renderName(item) : catalog.renderCatalogName(item)}
											</span>
										</CDropdownItem>
									);
								});
							} else {
								return (
									<CDropdownItem
										onClick={(e) => {
											this.toggleDropDown();
											e.stopPropagation();
										}}>
										<span className="text-truncate">Нет данных</span>
									</CDropdownItem>
								);
							}
						})()}
					</div>
					{(() => {
						if (this.dropdownActionsRef.current) {
							if (!_.isEmpty(this.dropdownActionsRef.current.querySelectorAll('.dropdown__action__button'))) {
								return <CDropdownItem divider className="m-0" />;
							}
						}
					})()}
					<div className="d-flex justify-content-between bg-transparent dropdown-item p-0" ref={this.dropdownActionsRef}>
						{this.props.pluralName !== 'enumerationItems' && !this.props.formatter.dontShowActionButtons && (
							<CButton
								onClick={(e) => {
									e.stopPropagation();
									this.toggleNatSelection(1);
									this.toggleDropDown();
								}}
								className="dropdown__action__button nat__button__hover">
								Показать все
							</CButton>
						)}
						{(() => {
							if (routesMap[`/${this.props.pluralName}/:elementId`] !== undefined && !this.props.formatter.dontShowActionButtons) {
								if (this.props.create) {
									return (
										<CButton
											variant="ghost"
											onClick={(e) => {
												e.stopPropagation();
												if (this.props.create) {
													this.props.create(this.props.pluralName, {
														onSave: (model: T) => {
															if (!_.isEmpty(model)) {
																this.setValue(model);
															}
															return Promise.resolve();
														}
													});
												}
											}}
											className="dropdown__action__button nat__button__hover">
											<CIcon name="cil-plus" />
										</CButton>
									);
								} else {
									return (
										<CButton
											className="dropdown__action__button nat__button__hover"
											// to={`/${this.props.pluralName}/new`}
											onClick={() => {
												if (this.props.goTo) {
													this.props.goTo(
														`/${this.props.pluralName}/new`,
														{},
														{
															onSave: (model: T) => {
																if (!_.isEmpty(model)) {
																	this.setValue(model);
																}
																return Promise.resolve();
															}
														}
													);
												}
											}}>
											<CIcon name="cil-plus" />
										</CButton>
									);
								}
							}
						})()}
					</div>
				</CDropdownMenu>

				{this.natRelationInputStore.modals.includes(1) && (
					<NatSelection
						filterWhere={this.props.filterWhere !== undefined ? this.props.filterWhere : {}}
						filterOrder={this.props.filterOrder !== undefined ? this.props.filterOrder : this.natRelationInputStore.order}
						toggleNatSelection={this.toggleNatSelection}
						buildSelectedItems={this.buildSelectedItems}
						saveSelectedItems={this.saveItems}
						tabNumber={1}
						modals={this.natRelationInputStore.modals}
						filterInclude={this.props.include !== undefined ? this.props.include : []}
						hierarchical={this.props.hierarchicalSelect}
						treeSelect={this.props.treeSelect}
						pluralName={this.props.pluralName}
						filterGroupOrder={[`name.${this.injected.directoryStore.models.language} ASC`]}
						filterGroupWhere={{ deleted: false, elementType: 1 }}
						singleSelect
						showCreateButton={routesMap[`/${this.props.pluralName}/:elementId`] !== undefined}
						fetchData={this.props.apiMethod}
						createButton={(() => {
							if (routesMap[`/${this.props.pluralName}/:elementId`] !== undefined) {
								if (this.props.create) {
									return {
										title: 'СОЗДАТЬ',
										onClick: (e) => {
											e.stopPropagation();
											if (this.props.create) {
												this.props.create(this.props.pluralName);
											}
											this.toggleNatSelection(1);
										}
									};
								} else {
									return {
										title: 'СОЗДАТЬ',
										onClick: () => {
											this.toggleNatSelection(1);
										},
										to: `/${this.props.pluralName}/new`
									};
								}
							}
						})()}
						property="id"
					/>
				)}
			</CDropdown>
		);
	}
}

export default enhanceWithClickOutside(NatRelationInput);
