import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, PriceTypeType } from '../../shared/support/modelTypes';
import PriceTypeForm from './PriceTypeForm';
import { PriceTypeFormStoreType } from './PriceTypeFormStore';

interface PropsType extends FormContainerPropsType<PriceTypeFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class PriceTypeFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (key !== 'basePriceType' && key !== 'status' && key !== 'roundingOption' && key !== 'pricingOption') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.statusId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'statusId', 'price_type_statuses.valid');
						this.props.store.setValue(this.props.store.model, 'status', {
							description: null,
							keyId: '',
							id: 'price_type_statuses.valid',
							name: { ru: 'Действует' },
							ownerId: 'price_type_statuses',
							predefined: true,
							predefinedName: 'PriceTypeStatusesValid',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.roundingOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'roundingOptionId', 'rounding_options.by_arithmetic_rules');
						this.props.store.setValue(this.props.store.model, 'roundingOption', {
							description: null,
							keyId: 'by_arithmetic_rules',
							id: 'rounding_options.by_arithmetic_rules',
							name: { ru: 'По арифметическим правилам' },
							ownerId: 'rounding_options',
							predefined: true,
							predefinedName: 'RoundingOptionsByArithmeticRules',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.pricingOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'pricingOptionId', 'pricing_options.manual');
						this.props.store.setValue(this.props.store.model, 'pricingOption', {
							description: null,
							keyId: 'manual',
							id: 'pricing_options.manual',
							name: { ru: 'Вручную' },
							ownerId: 'pricing_options',
							predefined: true,
							predefinedName: 'PricingOptionsManual',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.roundOff,
				(value) => {
					if (!value) {
						this.props.store.setValue(this.props.store.model, 'roundingOptionId', 'rounding_options.by_arithmetic_rules');
						this.props.store.setValue(this.props.store.model, 'roundingAccuracy', 0.01);
						this.props.store.setValue(this.props.store.model, 'roundingOption', {
							description: null,
							keyId: 'by_arithmetic_rules',
							id: 'rounding_options.by_arithmetic_rules',
							name: { ru: 'По арифметическим правилам' },
							ownerId: 'rounding_options',
							predefined: true,
							predefinedName: 'RoundingOptionsByArithmeticRules',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: PriceTypeType) {
		if (model.statusId === 'price_type_statuses.valid' && _.isEmpty(model.status)) {
			model.status = _.cloneDeep(this.props.store.model.status);
		}
		if (model.roundingOptionId === 'rounding_options.by_arithmetic_rules' && _.isEmpty(model.roundingOption)) {
			model.roundingOption = _.cloneDeep(this.props.store.model.roundingOption);
		}
		if (model.pricingOptionId === 'pricing_options.manual' && _.isEmpty(model.pricingOption)) {
			model.pricingOption = _.cloneDeep(this.props.store.model.pricingOption);
		}
		return model;
	}
	prepareModelForPost(model: PriceTypeType) {
		delete model.basePriceType;
		delete model.status;
		delete model.roundingOption;
		delete model.pricingOption;
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.pricingOptionId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/priceTypes/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<PriceTypeForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						priceTypeFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default PriceTypeFormContainer;
