import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';

interface PropsType {
	children: ReactElement;
	windowSize: number;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatMultiInputWrapper extends Component<PropsType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<div className="nat__input__wrapper">
				<div className={this.injected.directoryStore.models.windowSize > this.props.windowSize ? 'd-flex nat__form__group mr-3' : 'd-flex nat__form__group mr-3 flex-wrap'}>{this.props.children}</div>
			</div>
		);
	}
}

export default NatMultiInputWrapper;
