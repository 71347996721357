import { CButton } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatFormGroupLabel from '../../shared/Inputs/NatFormGroupLabel';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { NatSelectionStoreType } from '../../shared/Modals/NatSelectionStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, SettingPricesOfProductType } from '../../shared/support/modelTypes';
import SettingPricesOfProductForm from './SettingPricesOfProductForm';
import { SettingPricesOfProductFormStoreType } from './SettingPricesOfProductFormStore';

interface PropsType extends FormContainerPropsType<SettingPricesOfProductFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	natSelectionStore: NatSelectionStoreType;
}

interface StateType {
	defaultColumns: Array<any>;
	tableQuerySelector: any;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'natSelectionStore')
@observer
class SettingPricesOfProductFormContainer extends Component<PropsType, StateType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.saveSelected = this.saveSelected.bind(this);
		this.deletePriceTypeItems = this.deletePriceTypeItems.bind(this);
		this.buildPriceTypeItems = this.buildPriceTypeItems.bind(this);
		this.savePriceTypeItems = this.savePriceTypeItems.bind(this);
		this.buildColumns = this.buildColumns.bind(this);
		this.buildProductItemsTable = this.buildProductItemsTable.bind(this);
		this.saveProductItems = this.saveProductItems.bind(this);
		this.rebuildProductItems = this.rebuildProductItems.bind(this);
		this.deleteProductItems = this.deleteProductItems.bind(this);
		this.buildProductItemsFeaturesTable = this.buildProductItemsFeaturesTable.bind(this);
		this.getProductsFeaturesAndPrices = this.getProductsFeaturesAndPrices.bind(this);
		this.transformProductItems = this.transformProductItems.bind(this);
		this.getProductsPrices = this.getProductsPrices.bind(this);
		this.validatePrice = this.validatePrice.bind(this);
		this.state = {
			tableQuerySelector: null,
			defaultColumns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!data.isRootString) {
							return (
								<div className="rgt-cell-inner">
									<input type="checkbox" id="checkbox" onChange={() => this.collectModels(data)} />
								</div>
							);
						}
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '325px',
					checkCondition: (item: any) => {
						if (!item.isRoot) {
							return false;
						} else {
							return true;
						}
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!data.isRootString) {
							return (
								<div className="rgt-cell-inner ml-3 p-0 font__size__16">
									<NatRelationInput
										object={data}
										property="productId"
										relation="product"
										placeholder="Выберите товар"
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="products"
										filterWhere={{
											deleted: false,
											elementType: 0,
											typeId: {
												nin: ['product_types.set']
											}
										}}
										autoComplete="off"
										include={['unit']}
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</div>
							);
						} else {
							return catalog.renderCatalogName(data.product);
						}
					}
				},
				{
					id: 2,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					checkCondition: (item: any) => {
						if (!item.isRoot) {
							return false;
						} else {
							return true;
						}
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!data.isRootString) {
							return (
								<div className="rgt-cell-inner ml-3 p-0 font__size__16">
									<NatRelationInput
										object={data}
										property="unitId"
										relation="unit"
										placeholder="Выберите ед.изм."
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="productUnits"
										apiMethod={(): any => {
											if (data.product !== undefined) {
												return controller.fetchUnitsByProduct({ product: data.product });
											} else {
												return new Promise((resolve) => {
													resolve([]);
												});
											}
										}}
										autoComplete="off"
										goTo={this.props.goTo}
									/>
								</div>
							);
						}
					}
				},
				{
					id: 3,
					field: 'feature',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!data.isRootString) {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.isRoot) {
											if (!_.isEmpty(data.product)) {
												if (!_.isEmpty(data.product.useFeatureOptionId) && data.product.useFeatureOptionId !== 'use_feature_options.not_used') {
													return (
														<CButton
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																this.buildProductItemsFeaturesTable(data, _.get(this.props.store.model, 'priceTypeItems', []));
																this.props.store.setValue(this.props.store, 'isRoot', false);
																this.props.store.setValue(this.props.store, 'parentProductItem', data);
																this.props.store.tableQuerySelector.scrollTo(0, 0);
															}}>
															К ХАРАКТЕРИСТИКАМ
														</CButton>
													);
												}
											}
										} else {
											if (data.product !== undefined) {
												if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
													return null;
												} else {
													return (
														<NatRelationInput
															object={data}
															property="featureId"
															relation="feature"
															placeholder="Выберите характеристику"
															type="text"
															size="sm"
															inputClassName="text-left"
															formatter={relationFormatter()}
															pluralName="productFeatures"
															apiMethod={(): any => {
																return controller.fetchFeaturesByProduct({ product: data.product });
															}}
															create={(pluralName, handlers) => {
																this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
															}}
															autoComplete="off"
														/>
													);
												}
											} else {
												return null;
											}
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 4,
					field: 'validityPeriod',
					label: 'Период действия',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (!data.isRoot) {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return catalog.renderCatalogName(data.validityPeriod);
											}
										} else {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return (
													<NatRelationInput
														object={data}
														property="validityPeriodId"
														relation="validityPeriod"
														placeholder="Выберите период"
														type="text"
														size="sm"
														inputClassName="text-left"
														formatter={relationFormatter()}
														pluralName="enumerationItems"
														onlySelect={true}
														filterWhere={{ ownerId: 'periodicity' }}
														filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
														autoComplete="off"
													/>
												);
											} else {
												return null;
											}
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
					}
				}
			)
		);
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.productItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							this.buildColumns(_.get(this.props.store.model, 'priceTypeItems', []), this.state.defaultColumns);
							if (this.props.store.isRoot) {
								this.buildProductItemsTable(_.get(this.props.store.model, 'productItems', []), _.get(this.props.store.model, 'priceTypeItems', []));
							} else {
								this.buildProductItemsFeaturesTable(this.props.store.parentProductItem, _.get(this.props.store.model, 'priceTypeItems', []));
							}
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.priceTypeItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							this.buildColumns(_.get(this.props.store.model, 'priceTypeItems', []), this.state.defaultColumns);
							this.buildProductItemsTable(_.get(this.props.store.model, 'productItems', []), _.get(this.props.store.model, 'priceTypeItems', []));
						}
					}
				}
			)
		);
		_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (key !== 'feature' && key !== 'unit' && key !== 'priceType') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			});
		});
		let priceTypeItemsByPriceTypeId = _.chain(this.props.store.model.priceTypeItems).groupBy('priceTypeId').value();
		let groupedProductItemsByPriceTypeId = _.chain(this.props.store.model.productItems).groupBy('priceTypeId').value();
		_.forEach(_.get(this.props.store.model, 'priceTypeItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			});
			this.formReactions.push(
				reaction(
					() => item.priceTypeId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (this.props.store.model.productItems !== undefined) {
								if (!_.isEmpty(priceTypeItemsByPriceTypeId[value])) {
									item.priceTypeId = null;
									delete item.priceType;
									catalog.showAlert('Этот тип цены уже был выбран ранее!');
								} else {
									if (!_.isEmpty(value)) {
										if (groupedProductItemsByPriceTypeId[previousValue] !== undefined) {
											_.forEach(groupedProductItemsByPriceTypeId[previousValue], (itm) => {
												itm.priceTypeId = value;
												itm.priceType = item.priceType;
											});
										}
										this.rebuildProductItems();
										this.getProductsPrices(this.props.store.model.productItems);
									}
								}
							}
						}
					}
				)
			);
		});
		let productItemsMap = _.chain(this.props.store.model.productItems).keyBy('id').value();
		let groupedProductItemsByProductId = _.chain(this.props.store.model.productItems).groupBy('productId').value();
		_.forEach(this.props.store.productItemsTable, (item) => {
			this.formReactions.push(
				reaction(
					() => item.validityPeriodId,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							if (item.isRoot) {
								_.forEach(groupedProductItemsByProductId[item.productId], (itm) => {
									itm.validityPeriodId = value;
									itm.validityPeriod = item.validityPeriod;
								});
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.unitId,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							if (item.isRoot) {
								_.forEach(groupedProductItemsByProductId[item.productId], (itm) => {
									itm.unitId = value;
									itm.unit = item.unit;
								});
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.featureId,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							if (!_.isEmpty(value)) {
								if (!item.isRoot) {
									let productItemsMapByFeatureId = _.chain(this.props.store.model.productItems).keyBy('featureId').value();
									_.forEach(this.props.store.model.priceTypeItems, (itm) => {
										let productItem = productItemsMap[item[itm.priceTypeId].productItemId];
										if (!_.isEmpty(productItemsMapByFeatureId[value])) {
											productItem.featureId = null;
											item.featureId = null;
											delete productItem.feature;
											delete item.feature;
											catalog.showAlert('Эта характеристика уже была выбрана ранее!');
										} else {
											productItem.featureId = value;
											productItem.feature = item.feature;
											this.props.store.setValue(this.props.store, 'isLoading', true);
											controller
												.updateProductItemListPrices([productItem], productItem.priceType)
												.then((data) => {
													runInAction(() => {
														if (!_.isEmpty(data)) {
															item[itm.priceTypeId].price = data[0].price;
														} else {
															item[itm.priceTypeId].price = 0;
														}
													});
													this.props.store.setValue(this.props.store, 'isLoading', false);
												})
												.catch((error) => {
													catalog.handleNatError(error);
													this.props.store.setValue(this.props.store, 'isLoading', false);
												});
										}
									});
								}
							} else {
								_.forEach(this.props.store.model.priceTypeItems, (itm) => {
									let productItem = productItemsMap[item[itm.priceTypeId].productItemId];
									productItem.featureId = null;
									item.featureId = null;
									delete item.feature;
									delete productItem.feature;
								});
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							if (!_.isEmpty(value)) {
								if (!_.isEmpty(this.props.store.model.priceTypeItems)) {
									let arr: Array<any> = [];
									let productItemsMapByProductId = _.chain(this.props.store.model.productItems).keyBy('productId').value();
									_.forEach(this.props.store.model.priceTypeItems, (itm) => {
										let productItem = productItemsMap[item[itm.priceTypeId].productItemId];
										let index: number | null = null;
										if (this.props.store.model.productItems !== undefined) {
											index = this.props.store.model.productItems.indexOf(productItem);
										}
										productItem.featureId = null;
										delete productItem.feature;
										if (!_.isEmpty(productItemsMapByProductId[value])) {
											productItem.productId = null;
											item.productId = null;
											delete productItem.product;
											delete item.product;
											catalog.showAlert('Этот товар уже был выбран ранее!');
										} else {
											if (!_.isEmpty(item.product)) {
												productItem.productId = value;
												productItem.product = item.product;
												productItem.unitId = item.product.unitId;
												item.unitId = item.product.unitId;
												if (!_.isEmpty(item.product.unit)) {
													productItem.unit = item.product.unit;
													item.unit = item.product.unit;
												}
												if (item.product.useFeatureOptionId !== 'use_feature_options.not_used') {
													if (this.props.store.model.productItems !== undefined) {
														if (index !== null) {
															this.props.store.model.productItems.splice(index, 1);
														}
													}
													arr.push(productItem);
												} else {
													this.props.store.setValue(this.props.store, 'isLoading', true);
													controller
														.updateProductItemListPrices([productItem], productItem.priceType)
														.then((data) => {
															runInAction(() => {
																if (!_.isEmpty(data)) {
																	item[itm.priceTypeId].price = data[0].price;
																} else {
																	item[itm.priceTypeId].price = 0;
																}
																let gropedProductItems = _.chain(this.props.store.model.productItems).groupBy('productId').value();
																let buffer: Array<any> = [];
																_.forIn(gropedProductItems, (itm, key) => {
																	if (key !== previousValue) {
																		buffer = buffer.concat(itm);
																	}
																});
																this.props.store.setValue(this.props.store.model, 'productItems', buffer);
															});
															this.props.store.setValue(this.props.store, 'isLoading', false);
														})
														.catch((error) => {
															catalog.handleNatError(error);
															this.props.store.setValue(this.props.store, 'isLoading', false);
														});
												}
											}
										}
									});
									if (!_.isEmpty(arr)) {
										this.getProductsFeaturesAndPrices(arr);
									}
								}
							} else {
								item.productId = null;
								delete item.product;
								item.unitId = null;
								delete item.unit;
								item.featureId = null;
								delete item.feature;
								_.forEach(this.props.store.model.priceTypeItems, (itm) => {
									let productItem = productItemsMap[item[itm.priceTypeId].productItemId];
									productItem.productId = null;
									delete productItem.product;
									productItem.unitId = null;
									delete productItem.unit;
									productItem.featureId = null;
									delete productItem.feature;
								});
							}
						}
					}
				)
			);
			if (!_.isEmpty(this.props.store.model.priceTypeItems)) {
				_.forEach(this.props.store.model.priceTypeItems, (itm) => {
					if (!_.isEmpty(item[itm.priceTypeId])) {
						this.formReactions.push(
							reaction(
								() => item[itm.priceTypeId].price,
								(value, previousValue) => {
									if (value !== previousValue) {
										this.props.store.setValue(this.props.store, 'isChanged', true);
										let groupedProductItemsByProductIdPriceTypeId = _.chain(this.props.store.model.productItems)
											.groupBy((item) => {
												return `${item.productId}-${item.priceTypeId}`;
											})
											.value();
										if (item.isRoot) {
											_.forEach(groupedProductItemsByProductIdPriceTypeId[`${item.productId}-${itm.priceTypeId}`], (i) => {
												i.price = value;
											});
										} else {
											let productItem = productItemsMap[item[itm.priceTypeId].productItemId];
											productItem.price = value;
										}
									}
								}
							)
						);
					}
				});
			}
		});
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: SettingPricesOfProductType) {
		if (model.priceTypeItems !== undefined) {
			if (model.priceTypeItems.length !== 0) {
				this.buildColumns(_.get(model, 'priceTypeItems', []), this.state.defaultColumns);
				this.buildProductItemsTable(_.get(model, 'productItems', []), model.priceTypeItems);
			}
		}
		return model;
	}
	prepareModelForPost(model: SettingPricesOfProductType) {
		let productItems: Array<any> = [];
		let priceTypeItems: Array<any> = [];
		_.forEach(model.productItems, (item) => {
			delete item.unit;
			delete item.feature;
			delete item.priceType;
			delete item.product;
			runInAction(() => {
				if (!_.isEmpty(item.featureId)) {
					if (item.featureId.indexOf('null', 0) !== -1) {
						item.featureId = null;
					}
				}
			});
			if (!_.isEmpty(item.productId)) {
				if (item.productId.indexOf('null', 0) === -1) {
					productItems.push(item);
				}
			} else {
				productItems.push(item);
			}
		});
		_.forEach(model.priceTypeItems, (item) => {
			delete item.priceType;
			if (!_.isEmpty(item.priceTypeId)) {
				if (item.priceTypeId.indexOf('null', 0) === -1) {
					priceTypeItems.push(item);
				}
			} else {
				priceTypeItems.push(item);
			}
		});
		runInAction(() => {
			model.productItemList = productItems;
		});
		runInAction(() => {
			model.priceTypeItemList = priceTypeItems;
		});
		delete model.organization;
		return model;
	}
	validate() {
		let productItemsGrouped = _.chain(this.props.store.model.productItems).groupBy('productId').value();
		return productItemsGrouped['null'] === undefined && !_.isEmpty(this.props.store.model.organizationId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', items);
		this.makeReactions();
	}
	saveSelected(priceTypeItem: any) {
		const currentPriceTypeItemsMap = _.chain(this.props.store.selectedPriceTypeItems).cloneDeep().keyBy('id').value();
		runInAction(() => {
			if (currentPriceTypeItemsMap[priceTypeItem.id] === undefined) {
				this.props.store.selectedPriceTypeItems.push(priceTypeItem);
			} else {
				this.props.store.selectedPriceTypeItems.splice(this.props.store.selectedPriceTypeItems.indexOf(priceTypeItem), 1);
			}
		});
	}
	deletePriceTypeItems(selectedItems: Array<any>) {
		const currentPriceTypeItemsMap = _.chain(this.props.store.model.priceTypeItems).cloneDeep().keyBy('id').value();
		_.forEach(selectedItems, (item) => {
			if (currentPriceTypeItemsMap[item.id] !== undefined) {
				runInAction(() => {
					_.get(this.props.store, 'model.priceTypeItems', []).splice(_.get(this.props.store, 'model.priceTypeItems', []).indexOf(item), 1);
				});
			}
		});
		this.rebuildProductItems();
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store, 'isChanged', true);
	}
	buildPriceTypeItems(arrayIds: Array<string>) {
		const ids = arrayIds;
		const priceTypesDict = _.chain(this.injected.natSelectionStore.list).cloneDeep().keyBy('id').value();
		const currentPriceTypesItemMap = _.chain(this.injected.natSelectionStore.selectedItems).cloneDeep().keyBy('priceTypeId').value();
		_.map(ids, (item: string) => {
			const priceType = priceTypesDict[item];
			const priceTypeItem = {
				priceTypeId: item,
				priceType,
				id: generateRandomId()
			};
			runInAction(() => {
				if (currentPriceTypesItemMap[priceTypeItem.priceTypeId] === undefined) {
					this.injected.natSelectionStore.selectedItems.push(priceTypeItem);
				}
			});
		});
		const buffer = [] as Array<any>;
		_.map(this.injected.natSelectionStore.selectedItems, (item: any) => {
			if (_.find(ids, (itm: string) => itm === item.priceTypeId) !== undefined) {
				buffer.push(item);
			}
		});
		runInAction(() => {
			this.injected.natSelectionStore.selectedItems = buffer;
		});
	}
	savePriceTypeItems() {
		const arr = [] as Array<any>;
		const currentPriceTypesItemMap = _.chain(this.props.store.model.priceTypeItems).cloneDeep().keyBy('priceTypeId').value();
		_.forEach(this.injected.natSelectionStore.selectedItems, (item: any) => {
			if (currentPriceTypesItemMap[item.priceTypeId] === undefined) {
				arr.push(item);
			}
		});
		this.props.store.setValue(this.props.store.model, 'priceTypeItems', _.get(this.props.store, 'model.priceTypeItems', []).concat(arr));
		this.rebuildProductItems();
		this.makeReactions();
	}
	collectModels(productItem: any) {
		const currentGoodsItemMap = _.chain(this.props.store.selectedItems).cloneDeep().keyBy('id').value();
		const groupedProductItems = _.chain(_.get(this.props.store.model, 'productItems', [])).cloneDeep().groupBy('productId').value();
		runInAction(() => {
			if (currentGoodsItemMap[productItem.id] === undefined) {
				this.props.store.selectedItems.push(productItem);
				if (productItem.isRoot) {
					if (!_.isEmpty(groupedProductItems[productItem.productId])) {
						this.props.store.selectedItems = this.props.store.selectedItems.concat(groupedProductItems[productItem.productId]);
					}
				}
			} else {
				this.props.store.selectedItems.splice(this.props.store.selectedItems.indexOf(productItem), 1);
			}
		});
	}
	deleteProductItems(selectedItems: Array<any>) {
		const currentSelectedItemsMap = _.chain(selectedItems)
			.cloneDeep()
			.keyBy((item) => {
				return `${item.productId}-${item.featureId}`;
			})
			.value();
		const buffer: Array<any> = [];
		if (!_.isEmpty(this.props.store.model.productItems)) {
			_.forEach(this.props.store.model.productItems, (item) => {
				if (currentSelectedItemsMap[`${item.productId}-${item.featureId}`] === undefined) {
					buffer.push(item);
				}
			});
		}
		this.props.store.setValue(this.props.store.model, 'productItems', buffer);
		this.props.store.setValue(this.props.store, 'copyProductItems', buffer);
		this.props.store.setValue(this.props.store.model, 'productItemList', buffer);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.makeReactions();
	}
	saveProductItems(productItems: Array<any>) {
		const arr = [] as Array<any>;
		const currentProductItemMap = _.chain(this.props.store.model.productItems)
			.cloneDeep()
			.keyBy((item) => {
				return `${item.productId}-${item.featureId}`;
			})
			.value();
		_.transform(
			productItems,
			(result: any, value: any) => {
				if (currentProductItemMap[`${value.productId}-${value.featureId}`] === undefined) {
					if (this.props.store.model.priceTypeItems !== undefined) {
						for (let priceType of this.props.store.model.priceTypeItems) {
							let copyValue = _.cloneDeep(value);
							copyValue.id = generateRandomId();
							copyValue.priceTypeId = priceType.priceTypeId;
							if (!_.isEmpty(priceType.priceType)) {
								copyValue.priceType = priceType.priceType;
							}
							result.push(copyValue);
						}
					}
				}
			},
			arr
		);
		this.getProductsFeaturesAndPrices(arr);
	}
	getProductsFeaturesAndPrices(productItems: Array<any>) {
		let arr: Array<any> = [];
		arr = arr.concat(_.get(this.props.store, 'model.productItems', []));
		this.props.store.setValue(this.props.store, 'isLoading', true);
		let productIds: Array<string> = [];
		let priceTypeIds: Array<string> = [];
		let kindIds: Array<string> = [];
		_.forEach(productItems, (item) => {
			productIds.push(item.productId);
			priceTypeIds.push(item.priceTypeId);
			if (!_.isEmpty(item.product)) {
				if (!_.isEmpty(item.product.kindId)) {
					kindIds.push(item.product.kindId);
				}
			}
		});
		let featureFilter: any = {
			where: {
				deleted: false,
				or: [
					{
						ownerId: {
							inq: productIds
						}
					},
					{
						ownerId: {
							inq: kindIds
						}
					}
				]
			},
			order: [`name.${this.injected.directoryStore.models.language} ASC`]
		};
		controller
			.findAll('productFeatures', featureFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					let groupedProductFeatures = _.chain(data).groupBy('ownerId').value();
					let items: Array<any> = [];
					_.forEach(productItems, (item) => {
						let productItem = _.cloneDeep(item);
						if (!_.isEmpty(groupedProductFeatures[item.productId])) {
							_.forEach(groupedProductFeatures[item.productId], (itm) => {
								productItem.id = generateRandomId();
								productItem.featureId = itm.id;
								productItem.feature = itm;
								items.push(_.cloneDeep(productItem));
							});
						} else if (!_.isEmpty(groupedProductFeatures[_.get(item, 'product.kindId', null)])) {
							_.forEach(groupedProductFeatures[_.get(item, 'product.kindId', null)], (itm) => {
								productItem.id = generateRandomId();
								productItem.featureId = itm.id;
								productItem.feature = itm;
								items.push(_.cloneDeep(productItem));
							});
						} else {
							items.push(_.cloneDeep(productItem));
						}
					});
					arr = arr.concat(items);
				} else {
					arr = arr.concat(productItems);
				}
				return controller.updateProductItemListPrices(arr);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store.model, 'productItems', data);
					this.props.store.setValue(this.props.store, 'copyProductItems', this.props.store.model.productItems);
					this.props.store.setValue(this.props.store.model, 'productItemList', this.props.store.model.productItems);
					this.makeReactions();
				}
				this.props.store.setValue(this.props.store, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	validatePrice(object: any, value: any) {
		let priceType = object.priceType;
		let result: any = {
			result: true
		};
		if (priceType && priceType.roundOff) {
			result = {
				result: false
			};
			runInAction(() => {
				object.price = catalog.natRound(value, priceType.roundingAccuracy, priceType.roundingOptionId);
			});
		}
		return result;
	}
	buildColumns(priceTypeItems: Array<any>, defaultColumns: Array<any>) {
		let priceTypeItemsList = _.cloneDeep(priceTypeItems);
		let columns = _.cloneDeep(defaultColumns);
		_.transform(
			priceTypeItemsList,
			(result: any, value: any) => {
				if (!_.isEmpty(value.priceTypeId)) {
					if (!_.isEmpty(value.priceType)) {
						let item = {
							id: value.priceType.id,
							field: value.priceType.id,
							label: _.get(value, `priceType.name.${this.injected.directoryStore.models.language}`, ''),
							cellRenderer: ({ data }: { data: any }) => {
								if (!data.isRootString) {
									return (
										<div className="rgt-cell-inner m-1 p-0">
											<NatFormGroupLabel label="" labelColumnSettings="0" labelColumnClassName="text-left align-self-center text-white" row={true} wrapperClassName="w-100 h-100 m-0" inputColumnClassName="p-0">
												<NatValueInput<number>
													object={data[item.field]}
													property="price"
													placeholder="Введите цену"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={priceFormatter}
													autoComplete="off"
													verticalSwitching
													validation={(object: any, value: any) => {
														return this.validatePrice(object, value);
													}}
													size="sm"
												/>
											</NatFormGroupLabel>
										</div>
									);
								}
							}
						};
						if (!_.isEmpty(item.id)) {
							result.push(item);
						}
					}
				}
			},
			columns
		);
		this.props.store.setValue(this.props.store, 'productItemsColumns', columns);
	}
	buildProductItemsTable(productItems: Array<any>, priceTypeItems: Array<any>) {
		let result: Array<any> = [];
		result = this.transformProductItems(productItems, priceTypeItems, true);
		this.props.store.setValue(this.props.store, 'productItemsTable', result);
		this.makeReactions();
	}
	buildProductItemsFeaturesTable(item: any, priceTypeItems: Array<any>) {
		let result: Array<any> = [];
		const groupedProductItems = _.chain(this.props.store.model.productItems).cloneDeep().groupBy('productId').value();
		result = result.concat(this.transformProductItems(groupedProductItems[item.productId], priceTypeItems, false));
		this.props.store.setValue(this.props.store, 'productItemsTable', result);
		this.makeReactions();
	}
	transformProductItems(productItems: Array<any>, priceTypeItems: Array<any>, isRoot: boolean) {
		return _.chain(productItems)
			.groupBy((item) => {
				if (isRoot) {
					return `${item.productId}`;
				} else {
					return `${item.productId}-${item.featureId}`;
				}
			})
			.transform(function (result: any, value: any) {
				let priceByPriceType = _.chain(value).keyBy('priceTypeId').value();
				let valueFeatureMap = _.chain(value)
					.keyBy((item) => {
						return `${item.featureId}-${item.priceTypeId}`;
					})
					.value();
				let head: any = _.cloneDeep(_.head(value));
				let newItem: any = {
					priceTypeId: _.get(head, 'priceTypeId', null),
					productId: _.get(head, 'productId'),
					unitId: _.get(head, 'unitId'),
					id: generateRandomId(),
					validityPeriodId: _.get(head, 'validityPeriodId'),
					isRoot
				};
				if (isRoot) {
					newItem.featureId = null;
				} else {
					newItem.featureId = _.get(head, 'featureId');
					if (!_.isEmpty(head.feature)) {
						newItem.feature = head.feature;
					}
				}
				if (!_.isEmpty(head.product)) {
					newItem.product = head.product;
				}
				if (!_.isEmpty(head.unit)) {
					newItem.unit = head.unit;
				}
				if (!_.isEmpty(head.validityPeriod)) {
					newItem.validityPeriod = head.validityPeriod;
				}
				for (let priceType of priceTypeItems) {
					if (!_.isEmpty(priceType.priceType)) {
						_.set(newItem, priceType.priceType.id, {
							priceType: _.get(priceType, 'priceType', null),
							priceTypeId: priceType.priceTypeId,
							price: _.get(priceByPriceType, `${priceType.priceTypeId}.price`, 0),
							productItemId: (() => {
								if (isRoot) {
									return _.get(priceByPriceType, `${priceType.priceTypeId}.id`);
								} else {
									if (!_.isEmpty(valueFeatureMap[`${newItem.featureId}-${priceType.priceTypeId}`])) {
										return valueFeatureMap[`${newItem.featureId}-${priceType.priceTypeId}`].id;
									}
								}
							})()
						});
					}
				}
				result.push(newItem);
			}, [])
			.value();
	}
	rebuildProductItems() {
		const groupedProductItems = _.chain(this.props.store.model.productItems).cloneDeep().groupBy('priceTypeId').value();
		let priceTypeId: string | null = null;
		let arr: Array<any> = [];
		if (!_.isEmpty(this.props.store.model.priceTypeItems)) {
			_.forEach(this.props.store.model.priceTypeItems, (item) => {
				if (groupedProductItems[item.priceTypeId] !== undefined) {
					arr = arr.concat(groupedProductItems[item.priceTypeId]);
					priceTypeId = item.priceTypeId;
				} else {
					_.forEach(groupedProductItems[priceTypeId as string], (itm: any) => {
						let productItem = _.cloneDeep(itm);
						productItem.id = generateRandomId();
						productItem.price = 0;
						productItem.priceTypeId = item.priceTypeId;
						if (!_.isEmpty(item.priceType)) {
							productItem.priceType = item.priceType;
						}
						arr.push(productItem);
					});
				}
			});
		}
		this.props.store.setValue(this.props.store.model, 'productItems', arr);
		this.props.store.setValue(this.props.store, 'copyProductItems', arr);
		this.props.store.setValue(this.props.store.model, 'productItemList', arr);
		this.makeReactions();
	}
	getProductsPrices(productItems: Array<any>) {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		this.props.store.setValue(this.props.store, 'productItemsColumns', []);
		this.props.store.setValue(this.props.store, 'productItemsTable', []);
		let productIds: Array<string> = [];
		let priceTypeIds: Array<string> = [];
		_.forEach(productItems, (item) => {
			productIds.push(item.productId);
			priceTypeIds.push(item.priceTypeId);
		});
		controller
			.updateProductItemListPrices(productItems)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store.model, 'productItems', data);
					this.props.store.setValue(this.props.store, 'copyProductItems', this.props.store.model.productItems);
					this.props.store.setValue(this.props.store.model, 'productItemList', this.props.store.model.productItems);
					this.buildColumns(_.get(this.props.store.model, 'priceTypeItems', []), this.state.defaultColumns);
					if (this.props.store.isRoot) {
						this.buildProductItemsTable(_.get(this.props.store.model, 'productItems', []), _.get(this.props.store.model, 'priceTypeItems', []));
					} else {
						this.buildProductItemsFeaturesTable(this.props.store.parentProductItem, _.get(this.props.store.model, 'priceTypeItems', []));
					}
					this.makeReactions();
				}
				this.props.store.setValue(this.props.store, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				isDocument
				parentRoute="/settingPricesOfProducts/"
				notRecalculateAmount
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<SettingPricesOfProductForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						settingPricesOfProductFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
						saveSelected={this.saveSelected}
						deletePriceTypeItems={this.deletePriceTypeItems}
						savePriceTypeItems={this.savePriceTypeItems}
						buildPriceTypeItems={this.buildPriceTypeItems}
						saveProductItems={this.saveProductItems}
						deleteProductItems={this.deleteProductItems}
						buildProductItemsTable={this.buildProductItemsTable}
						buildProductItemsFeaturesTable={this.buildProductItemsFeaturesTable}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default SettingPricesOfProductFormContainer;
