import { observable, action, makeObservable, set } from 'mobx';

import { OutcomeCashOrderType } from '../../shared/support/modelTypes';

export interface OutcomeCashOrderFormStoreType {
	model: OutcomeCashOrderType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	isSequenceNumberChangedManually: boolean;
	setValue(model: any, key: string, value: any): void;
}

class OutcomeCashOrderFormStore implements OutcomeCashOrderFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			isSequenceNumberChangedManually: observable,
			setValue: action
		});
	}

	model = {
		cashRegisterId: null,
		sellerId: null,
		sellerType: null,
		ownerId: null,
		ownerType: null,
		partnerId: null,
		contractId: null,
		organizationId: null,
		note: null,
		posted: false,
		responsibleId: null,
		deleted: false,
		authorId: null,
		amount: 0,
		cashBoxId: null,
		toIssue: null,
		basis: null,
		annex: null,
		accountablePersonId: null,
		organizationBankAccountId: null,
		chiefAccountantId: null,
		cashBoxSenderId: null,
		cashBoxSenderType: null,
		periodAt: null
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'outcomeCashOrders';
	filterInclude = ['partner', 'organization', 'contract', 'organizationBankAccount', 'cashBox', 'chiefAccountant', 'cashRegister', 'accountablePerson'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		}
	];
	isFirstLoad = true;
	name = 'OrmOutcomeCashOrder';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const outcomeCashOrdersFormStore = new OutcomeCashOrderFormStore();

export default OutcomeCashOrderFormStore;
