import { observable, action, makeObservable, set } from 'mobx';

import { ProductFeatureType } from '../../shared/support/modelTypes';

export interface ProductFeatureFormStoreType {
	model: ProductFeatureType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class ProductFeatureFormStore implements ProductFeatureFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		deleted: false,
		displayName: null,
		name: { ru: '' },
		ownerId: null,
		ownerType: null,
		priority: null
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'productFeatures';
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmProductFeature';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productFeatureFormStore = new ProductFeatureFormStore();

export default ProductFeatureFormStore;
