import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyProgramType } from '../../shared/support/modelTypes';

export interface LoyaltyProgramFormStoreType {
	model: LoyaltyProgramType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class LoyaltyProgramFormStore implements LoyaltyProgramFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		maxBonusPercentage: 0,
		conversionRate: 1,
		earnPointsWhenYouPayWithBonuses: true,
		note: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'loyaltyPrograms';
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmLoyaltyProgram';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const loyaltyProgramFormStore = new LoyaltyProgramFormStore();

export default LoyaltyProgramFormStore;
