import { CCol, CFormGroup } from '@coreui/react';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { WarehouseFormStoreType } from './WarehouseFormStore';

interface PropsType extends FormPropsType {
	warehouseFormStore: WarehouseFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class WarehouseForm extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.warehouseFormStore}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				navTabs={[{ name: 'Дополнительно' }]}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.warehouseFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.warehouseFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.warehouseFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.warehouseFormStore.model}
										property="typeId"
										relation="type"
										placeholder="Выберите тип"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'warehouse_types' }}
										autoComplete="off"
										label="Тип"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatCheckbox object={this.props.warehouseFormStore.model} property="controlProductInStock" label="Контролировать остатки" />
								</CCol>
								{this.props.warehouseFormStore.model.typeId === 'warehouse_types.retail' && (
									<CCol md="4">
										<NatRelationInput
											object={this.props.warehouseFormStore.model}
											property="retailPriceTypeId"
											relation="retailPriceType"
											placeholder="Выберите тип"
											type="text"
											size="sm"
											formatter={relationFormatter()}
											pluralName="priceTypes"
											filterWhere={{ deleted: false }}
											autoComplete="off"
											label="Розничный тип цены"
											mobile
											goTo={this.props.goTo}
										/>
									</CCol>
								)}
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.warehouseFormStore.setValue(this.props.warehouseFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.warehouseFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.warehouseFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.warehouseFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(WarehouseForm));
