import { CButton, CCardHeader, CModal, CModalBody, CModalFooter } from '@coreui/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { UseLocation } from '../support/useLocationHoC';

interface PropsType extends RouteComponentProps {
	isModalOpen: boolean;
	title: string;
	buttonItemList: Array<ButtonItem>;
	loadPage?: (query?: any, params?: any) => void;
	query: any;
	className?: string;
	message?: string;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
}

@observer
class AlertDialog extends Component<PropsType> {
	componentDidMount() {
		if (this.props.loadPage) {
			const query = this.props.query;
			const params = this.props.match.params;
			this.props.loadPage(query, params);
		}
	}
	render() {
		let className: string = '';
		if (this.props.className) {
			className = ` ${this.props.className}`;
		}
		return (
			<CModal show={this.props.isModalOpen} className={'nat__alert' + className} size="lg" closeOnBackdrop={false} centered>
				<CCardHeader className="border-0 font-weight-bold bg-transparent">
					<h5>{this.props.title}</h5>
				</CCardHeader>
				{this.props.message && (
					<CModalBody className="d-flex justify-content-start nat__alert__body">
						<h5>{this.props.message}</h5>
					</CModalBody>
				)}
				<CModalFooter className="border-0 d-flex justify-content-end">
					<div>
						{this.props.buttonItemList.map((value: ButtonItem, index: number) => {
							return (
								<CButton key={index} type="button" size="sm" className="mr-1 nat__button__hover" onClick={value.onClick}>
									{value.title}
								</CButton>
							);
						})}
					</div>
				</CModalFooter>
			</CModal>
		);
	}
}

export default UseLocation(withRouter(AlertDialog));
