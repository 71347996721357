import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { isFirefox } from 'react-device-detect';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import Header from '../Components/Header';
import NavMenu from '../Components/NavMenu';
import { NavigationContainerStoreType } from '../ContainersStores/NavigationContainerStore';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';
import Content from './Content';

interface PropsType extends RouteComponentProps {
	match: MatchType;
}
interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	navigationContainerStore: NavigationContainerStoreType;
	directoryStore: OrmStoreType;
}
interface StateType {
	isMinimized: boolean;
	isShown: boolean;
}
@inject('directoryStore', 'userStore', 'navigationContainerStore')
@observer
class Layout extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.state = {
			isMinimized: this.injected.directoryStore.models.windowSize <= 960 ? false : true,
			isShown: false
		};
		this.loadPage = this.loadPage.bind(this);
		this.toggleDisplaySidebar = this.toggleDisplaySidebar.bind(this);
		this.minimizeSidebar = this.minimizeSidebar.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.reactions.push(
			reaction(
				() => this.state.isShown,
				(value) => {
					if (value) {
						catalog.disablePageScroll();
					} else {
						catalog.enablePageScroll();
					}
				}
			)
		);
	}
	componentDidMount() {
		this.loadPage(this.props.match.url);
		window.addEventListener('resize', this.handleResize);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.reactions.forEach((dispose) => dispose());
	}
	get injected() {
		return this.props as InjectedProps;
	}
	loadPage(url: string) {
		if (url === '/') {
			document.title = 'Личный кабинет Natiwi';
		}
	}
	handleResize() {
		const windowSize = window.innerWidth;
		const windowHeight = window.innerHeight;
		runInAction(() => {
			this.injected.directoryStore.models.windowSize = windowSize;
			this.injected.directoryStore.models.clientHeight = windowHeight;
		});
	}
	handleClickOutside() {
		this.setState({
			isShown: false
		});
	}
	toggleDisplaySidebar() {
		this.setState({
			isShown: !this.state.isShown
		});
	}
	minimizeSidebar(status: boolean) {
		this.setState({
			isMinimized: status
		});
	}
	render() {
		return (
			<div className="c-app c-default-layout nat__gardient__bg">
				<NavMenu isShown={this.state.isShown} toggleDisplaySidebar={this.toggleDisplaySidebar} />
				<div className="c-wrapper">
					<Header toggleDisplaySidebar={this.toggleDisplaySidebar} />
					<div
						className={(() => {
							if (isFirefox) {
								return 'c-body flex-row';
							} else {
								return 'c-body';
							}
						})()}>
						<Content />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(enhanceWithClickOutside(Layout));
