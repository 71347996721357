import { observable, action, makeObservable, set } from 'mobx';

import { PartnerSubscriptionInfo } from '../core/Stores/DirectoryStore';
import { EnumerationItemsType, ShoppingCartType, SubscriptionOptionType, SubscriptionPlanType } from '../shared/support/modelTypes';

export interface NatShoppingCartStoreType {
	availableSubscriptionPlans: Array<any>;
	paymentOptionList: Array<any>;
	collectedModels: Array<any>;
	isLoading: boolean;
	shoppingCart: ShoppingCartType;
	selectedSubscriptionPlan: {
		id: string | null;
		item: {
			optionList: Array<SubscriptionOptionType>;
			periodItemList: Array<any>;
			subscriptionPlan: SubscriptionPlanType;
		};
	};
	periodItem: {
		validityPeriod: EnumerationItemsType;
		validityPeriodCount: number;
		validityPeriodId: string;
	};
	productItems: Array<any>;
	refillAmount: number;
	partnerSubscriptionInfo: PartnerSubscriptionInfo;
	setValue(model: any, key: string, value: any): void;
}

class NatShoppingCartStore implements NatShoppingCartStoreType {
	constructor() {
		makeObservable(this, {
			shoppingCart: observable,
			availableSubscriptionPlans: observable,
			paymentOptionList: observable,
			collectedModels: observable,
			isLoading: observable,
			selectedSubscriptionPlan: observable,
			periodItem: observable,
			productItems: observable,
			refillAmount: observable,
			partnerSubscriptionInfo: observable,
			setValue: action
		});
	}
	shoppingCart = {
		organizationId: null,
		partnerId: null,
		paymentOptionId: null,
		loyaltyCardId: null,
		couponCode: null,
		couponId: null,
		priceTypeId: null,
		warehouseId: null,
		contactSetId: null,
		contractId: null,
		storeId: null,
		amount: 0,
		amountWithoutDiscount: 0,
		bonusPointBalance: 0,
		bonusPointOutcome: 0,
		discountsCalculated: false,
		infoMessage: null,
		note: null,
		name: { ru: '' },
		displayName: null,
		priority: 0,
		deleted: false,
		elementType: 0,
		parentId: null,
		ownerId: null,
		ownerType: null,
		productItemList: [],
		productItems: []
	};
	selectedSubscriptionPlan = {
		id: null,
		item: {
			optionList: [],
			periodItemList: [],
			subscriptionPlan: {
				applyLimitValidityPeriodId: 'periodicity.month',
				applyLimitValidityPeriodCount: 0,
				renewable: true,
				level: 0,
				enabled: true,
				description: null,
				name: { ru: '' },
				displayName: null,
				priority: 0,
				deleted: false,
				defaultName: null,
				trial: false,
				applyLimitValidityPeriod: {
					id: 'periodicity.month',
					keyId: 'month',
					ownerId: 'periodicity',
					name: { ru: 'Месяц' },
					description: null,
					priority: 6,
					predefined: true,
					predefinedName: 'PeriodicityMonth',
					predefinedVersion: 0
				},
				periodItems: [],
				productItems: []
			}
		}
	};
	periodItem = {
		validityPeriod: {
			description: null,
			id: 'periodicity.month',
			keyId: 'month',
			name: { ru: 'Месяц', id: '3c0ac215-ef86-4c13-bcd1-0453d186cf50' },
			ownerId: 'periodicity',
			predefined: true,
			predefinedName: 'PeriodicityMonth',
			predefinedVersion: 0,
			priority: 6
		},
		validityPeriodCount: 1,
		validityPeriodId: 'periodicity.month'
	};
	availableSubscriptionPlans = [];
	paymentOptionList = [];
	productItems = [];
	collectedModels = [] as Array<any>;
	isLoading = false;
	refillAmount = 0;
	partnerSubscriptionInfo = {
		periodAt: null,
		subscriptionItemList: []
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natShoppingCartStore = new NatShoppingCartStore();

export default NatShoppingCartStore;
