import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatPolyRelationInput from '../../shared/Inputs/NatPolyRelationInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { PartnerSettlementOperationFormStoreType } from './PartnerSettlementOperationFormStore';

interface PropsType extends FormPropsType {
	partnerSettlementOperationFormStore: PartnerSettlementOperationFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
	print(data: any): void;
	isAlertOpen: boolean;
	toggleAlert(status: boolean): void;
}

interface StateType {
	columns: Array<any>;
	isModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class PartnerSettlementOperationForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.state = {
			isModalOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input
									type="checkbox"
									id="checkbox"
									onChange={() => {
										this.props.childrenProps.saveSelected(data);
									}}
								/>
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'document',
					label: 'Документ',
					width: '425px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatPolyRelationInput
									object={data}
									property="documentId"
									relation="document"
									relationProperty="documentType"
									availableModels={[
										'OrmCheck',
										'OrmCheckRefund',
										'OrmCustomerOrder',
										'OrmCashlessReceipt',
										'OrmIncomeCashOrder',
										'OrmPostingOfProducts',
										'OrmReceiptOfProducts',
										'OrmRefundOfProducts',
										'OrmSaleOfProducts',
										'OrmPartnerSettlementOperation',
										'OrmPartnerSubscriptionOperation'
									]}
									placeholder="Выберите документ"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									filterOrder={['createdAt DESC']}
									filterWhere={{
										deleted: false
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'order',
					label: 'Заказ',
					width: '425px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatPolyRelationInput
									object={data}
									property="orderId"
									relation="order"
									relationProperty="orderType"
									availableModels={['OrmCustomerOrder', 'OrmPartnerSettlementOperation', 'OrmPartnerSubscriptionOperation']}
									placeholder="Выберите заказ"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									filterOrder={['createdAt DESC']}
									filterWhere={{
										deleted: false
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'organization',
					label: 'Организация',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="organizationId"
									relation="organization"
									placeholder="Выберите организацию"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="organizations"
									autoComplete="off"
									filterWhere={{ deleted: false }}
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 4,
					field: 'partner',
					label: 'Контрагент',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="partnerId"
									relation="partner"
									placeholder="Выберите контрагента"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="partners"
									autoComplete="off"
									filterWhere={{ deleted: false, elementType: 0 }}
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'contract',
					label: 'Договор',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="contractId"
									relation="contract"
									placeholder="Выберите договор"
									type="text"
									size="sm"
									formatter={relationFormatter()}
									pluralName="partnerContracts"
									apiMethod={(): any => {
										if (!_.isEmpty(data.partner)) {
											return controller.fetchAvailableContracts(data.partner);
										} else {
											return Promise.reject(new Error('Контрагент не выбран!'));
										}
									}}
									disabled={_.isEmpty(data.partnerId)}
									create={(pluralName, handlers) => {
										this.props.goTo(`/${pluralName}/new?partnerId=${data.partnerId}`, {}, handlers);
									}}
									autoComplete="off"
								/>
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'amount',
					label: 'Сумма',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="amount"
									placeholder="Введите сумму"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={priceFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				},
				{
					id: 7,
					field: 'prepaymentAmount',
					label: 'Сумма предоплаты',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="prepaymentAmount"
									placeholder="Введите сумму"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={priceFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleSelection(status: boolean) {
		this.setState({
			isModalOpen: status
		});
	}
	render() {
		let specificationItemTemplate: any = {
			amount: 0,
			prepaymentAmount: 0,
			organizationId: this.props.partnerSettlementOperationFormStore.model.organizationId,
			contractId: this.props.partnerSettlementOperationFormStore.model.contractId,
			partnerId: this.props.partnerSettlementOperationFormStore.model.partnerId,
			id: '',
			documentType: '',
			documentId: null,
			orderType: '',
			orderId: null
		};
		if (!_.isEmpty(this.props.partnerSettlementOperationFormStore.model.organization)) {
			specificationItemTemplate.organization = this.props.partnerSettlementOperationFormStore.model.organization;
		}
		if (!_.isEmpty(this.props.partnerSettlementOperationFormStore.model.contract)) {
			specificationItemTemplate.contract = this.props.partnerSettlementOperationFormStore.model.contract;
		}
		if (!_.isEmpty(this.props.partnerSettlementOperationFormStore.model.partner)) {
			specificationItemTemplate.partner = this.props.partnerSettlementOperationFormStore.model.partner;
		}
		return (
			<NatHeaderCollapseFormTemplate
				makeReactions={this.props.makeReactions}
				store={this.props.partnerSettlementOperationFormStore}
				isAlertOpen={this.props.isAlertOpen}
				toggleAlert={this.props.toggleAlert}
				navTabs={[{ name: 'Уточнение платежа' }, { name: 'Примечание' }, { name: 'Дополнительно' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(specificationItemTemplate, 'specificationItems');
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.partnerSettlementOperationFormStore.selectedItems, 'specificationItems');
										},
										withoutIcon: true,
										disabled: this.props.partnerSettlementOperationFormStore.selectedItems.length === 0
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.partnerSettlementOperationFormStore.selectedItems, this.props.partnerSettlementOperationFormStore.model.specificationItems as Array<any>, -1);
										},
										withoutIcon: true,
										disabled: this.props.partnerSettlementOperationFormStore.selectedItems.length === 0
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.partnerSettlementOperationFormStore.selectedItems, this.props.partnerSettlementOperationFormStore.model.specificationItems as Array<any>, 1);
										},
										withoutIcon: true,
										disabled: this.props.partnerSettlementOperationFormStore.selectedItems.length === 0
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.partnerSettlementOperationFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.checkColumnVisibility(catalog.convertCellMethods(this.state.columns), _.get(this.props.partnerSettlementOperationFormStore.model, 'specificationItems', []))}
										rows={this.props.partnerSettlementOperationFormStore.model.specificationItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										showSearch={false}
										searchText={this.props.partnerSettlementOperationFormStore.searchText}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.partnerSettlementOperationFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.partnerSettlementOperationFormStore.model}
													property="sequenceNumber"
													placeholder="Введите номер"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Номер"
													mobile
													onClick={() => {
														if (!this.props.partnerSettlementOperationFormStore.isSequenceNumberChangedManually) {
															this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
														}
													}}
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.partnerSettlementOperationFormStore.model}
										property="periodAt"
										placeholder="Введите дату"
										type="datetime-local"
										step="1"
										formatter={dateTimeFormatter}
										size="sm"
										label="Дата документа"
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.organizationId)}
										invalidFeedback="Элемент не выбран"
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="partnerId"
										relation="partner"
										placeholder="Выберите контрагента"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partners"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.partnerId)}
										invalidFeedback="Элемент не выбран"
										label="Контрагент"
										mobile
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="paymentOptionId"
										relation="paymentOption"
										placeholder="Выберите способ оплаты"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="paymentOptions"
										onlySelect={true}
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.paymentOptionId)}
										invalidFeedback="Элемент не выбран"
										label="Способ оплаты"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="contractId"
										relation="contract"
										placeholder="Выберите договор"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partnerContracts"
										apiMethod={(): any => {
											if (!_.isEmpty(this.props.partnerSettlementOperationFormStore.model.partner)) {
												return controller.fetchAvailableContracts(this.props.partnerSettlementOperationFormStore.model.partner);
											} else {
												return Promise.reject(new Error('Контрагент не выбран!'));
											}
										}}
										disabled={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.partnerId)}
										create={(pluralName, handlers) => {
											this.props.goTo(`/${pluralName}/new?partnerId=${this.props.partnerSettlementOperationFormStore.model.partnerId}`, {}, handlers);
										}}
										autoComplete="off"
										label="Договор"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="typeId"
										relation="type"
										placeholder="Выберите тип"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'partner_settlement_operation_types' }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.typeId)}
										invalidFeedback="Элемент не выбран"
										label="Тип"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.partnerSettlementOperationFormStore.model}
										property="statusId"
										relation="status"
										placeholder="Выберите статус"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'payment_operation_statuses' }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.partnerSettlementOperationFormStore.model.statusId)}
										invalidFeedback="Элемент не выбран"
										label="Статус"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<number>
										object={this.props.partnerSettlementOperationFormStore.model}
										property="amount"
										placeholder="Введите сумму"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										formatter={priceFormatter}
										size="sm"
										disabled={true}
										label="Сумма"
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.partnerSettlementOperationFormStore.setValue(this.props.partnerSettlementOperationFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.partnerSettlementOperationFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: this.props.partnerSettlementOperationFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.partnerSettlementOperationFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: this.props.partnerSettlementOperationFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.partnerSettlementOperationFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(PartnerSettlementOperationForm));
