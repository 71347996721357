import { CCol, CFormGroup, CLabel, CTextarea } from '@coreui/react';
import { observer } from 'mobx-react';
import React from 'react';

import ValueFormatter from '../Formatters/ValueFormatter';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType extends ValueFieldPropsType {
	wrapperClassName?: string;
	labelColumnSettings: string;
	labelColumnClassName: string;
	inputColumnClassName?: string;
	inputClassName?: string;
	row: boolean;
	type: string;
	invalid?: boolean;
	placeholder: string;
	pattern?: string;
	formatter: ValueFormatter<string | undefined | null, string>;
	size: string;
	rows?: number;
}

@observer
class NatTextarea extends NatValueField<string | undefined | null, string, PropsType> {
	constructor(props: PropsType) {
		super(props);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if ((this.props.pattern && e.target.value.match(this.props.pattern) != null) || !this.props.pattern) {
			this.setState({
				value: e.target.value
			});
		}
	}
	parseValue(value: string): string | undefined | null {
		return this.props.formatter.parseValue(value);
	}

	renderValue(value: string | undefined | null): string {
		return this.props.formatter.renderValue(value);
	}
	render() {
		return (
			<CFormGroup row={this.props.row} className={this.props.wrapperClassName}>
				{this.props.label !== '' && (
					<CCol md={this.props.labelColumnSettings} className={this.props.labelColumnClassName}>
						<CLabel>{this.props.label}</CLabel>
					</CCol>
				)}
				<CCol className={this.props.inputColumnClassName}>
					<CTextarea
						type={this.props.type}
						name={this.props.property}
						placeholder={this.props.placeholder}
						onChange={this.handleChange}
						value={this.state.value}
						pattern={this.props.pattern}
						size={this.props.size}
						className={this.props.inputClassName}
						readOnly={this.props.readonly}
						disabled={this.props.disabled}
						rows={this.props.rows}
						onBlur={() => {
							let value: string | undefined | null = this.parseValue(this.state.value);
							this.setValue(this.parseValue(this.state.value));
							this.setState({
								value: this.renderValue(value)
							});
						}}
					/>
				</CCol>
			</CFormGroup>
		);
	}
}

export default NatTextarea;
