import { CCardHeader, CCol, CForm, CFormGroup, CNav, CNavItem, CRow, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { controller } from '../core/Controllers/OrmController';
import { OrmStoreType } from '../core/Stores/DirectoryStore';
import AlertDialog from '../shared/Alerts/AlertDialog';
import { dateFormatter } from '../shared/Formatters/DateFormatter';
import { priceFormatter } from '../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../shared/Formatters/RelationFormatter';
import NatRelationInput from '../shared/Inputs/NatRelationInput';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import { FormContainerPropsType } from '../shared/support/modelTypes';
import { UseLocation } from '../shared/support/useLocationHoC';
import { NatStatisticsStoreType } from './NatStatisticsStore';

interface PropsType extends FormContainerPropsType<NatStatisticsStoreType> {}

interface StateType {
	isWelcomeModalOpen: boolean;
	isRegistrationConfirmation: boolean;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatStatistics extends Component<PropsType, StateType> {
	tabRef: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.tabRef = React.createRef();
		this.reactions = [];
		this.toggleTabs = this.toggleTabs.bind(this);
		this.activateDefaultTab = this.activateDefaultTab.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.togglePeriod = this.togglePeriod.bind(this);
		this.fetchMetrics = this.fetchMetrics.bind(this);
		this.state = {
			isWelcomeModalOpen: Boolean(localStorage.getItem('showWelcomeMessage')),
			isRegistrationConfirmation: this.props.query.get('registrationConfirmed') ? Boolean(this.props.query.get('registrationConfirmed')) : false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		if (this.props.navigationRoute.url === this.props.match.url) {
			if (this.props.store.isFirstLoad) {
				this.activateDefaultTab();
				catalog.generateTitle(this.props.navigationRoute, '', '', 'Главная');
				catalog.setConstants(this.props.store, this.props.store.requiredToFill);
				let data = _.cloneDeep(this.props.store.model);
				delete data.organization;
				this.fetchMetrics(data);
			} else {
				this.toggleTabs(this.props.store.activePeriod.name);
			}
			this.makeReactions();
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	makeReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		this.reactions.push(
			reaction(
				() => this.props.store.model.organizationId,
				(value, previousValue) => {
					if (value !== previousValue) {
						let data = _.cloneDeep(this.props.store.model);
						delete data.organization;
						if (!_.isEmpty(data.organizationId) && data.periodFrom !== null && data.periodTo !== null) {
							this.fetchMetrics(data);
						} else {
							catalog.showAlert('Проверьте правильность заполнения полей!');
						}
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.store.model.periodFrom,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (value !== null) {
							let data = _.cloneDeep(this.props.store.model);
							delete data.organization;
							if (this.props.store.isManuallyPeriodEditingActive) {
								if (data.periodTo !== null) {
									this.fetchMetrics(data);
								} else {
									catalog.showAlert('Поле "До" не заполнено!');
								}
							}
						}
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.store.model.periodTo,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (value !== null) {
							let data = _.cloneDeep(this.props.store.model);
							delete data.organization;
							if (this.props.store.isManuallyPeriodEditingActive) {
								if (data.periodFrom !== null) {
									this.fetchMetrics(data);
								} else {
									catalog.showAlert('Поле "Период от" не заполнено!');
								}
							}
						}
					}
				}
			)
		);
	}
	toggleTabs(id: string) {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		_.forEach(tabs, (item) => {
			if (id !== item.id) {
				item.classList.remove('active');
			} else {
				item.classList.add('active');
			}
		});
	}
	activateDefaultTab() {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		if (!_.isEmpty(tabs[0])) {
			tabs[0].classList.add('active');
		}
	}
	togglePeriod(item: any) {
		this.props.store.setValue(this.props.store, 'activePeriod', item);
		this.props.store.setValue(this.props.store, 'isManuallyPeriodEditingActive', false);
		this.props.store.setValue(this.props.store.model, 'periodFrom', item.periodFrom);
		this.props.store.setValue(this.props.store.model, 'periodTo', item.periodTo);
		let data = _.cloneDeep(this.props.store.model);
		delete data.organization;
		if (item.sendRequest) {
			this.fetchMetrics(data);
		} else {
			this.props.store.setValue(this.props.store, 'isManuallyPeriodEditingActive', true);
		}
	}
	fetchMetrics(data: any) {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		controller
			.fetchMetrics(data)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'metrics', data);
				}
				this.props.store.setValue(this.props.store, 'isLoading', false);
				if (this.props.store.isFirstLoad) {
					this.props.store.setValue(this.props.store, 'isFirstLoad', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
				if (this.props.store.isFirstLoad) {
					this.props.store.setValue(this.props.store, 'isFirstLoad', false);
				}
			});
	}
	render() {
		return (
			<div className="d-flex nat__page default__nat__card__body__height nat__scrollbar nat__borders__2-75">
				<div className="nat__table__wrapper">
					<div
						className={(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return 'p-0 nat__form__tabs mt-4 mx-4 mb-3 d-flex align-items-center';
							} else {
								if (this.props.store.isManuallyPeriodEditingActive) {
									return 'p-0 nat__form__tabs nat__form__tabs__mobile mt-4 mx-4 d-flex flex-column align-items-center';
								} else {
									return 'p-0 nat__form__tabs nat__form__tabs__mobile mt-4 mx-4 mb-3 d-flex flex-column align-items-center';
								}
							}
						})()}
						ref={this.tabRef}>
						<CNav variant="tabs" className="p-2 w-100">
							{_.map(this.props.store.periods, (item, index: number) => {
								return (
									<CNavItem className="mr-1" key={index}>
										<a
											className="nav-link cursor__pointer"
											id={item.name}
											onClick={(event) => {
												event.preventDefault();
												this.toggleTabs(item.name);
												this.togglePeriod(item);
											}}>
											{item.name}
										</a>
									</CNavItem>
								);
							})}
						</CNav>
						<CFormGroup
							className={(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return 'mb-0 w-100 mx-4 px-4';
								} else {
									return 'mb-0 w-100 mx-5 px-4 mt-3';
								}
							})()}>
							<CRow className="w-100">
								<CCol lg="12">
									<NatRelationInput
										object={this.props.store.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Организация"
										invalid={_.isEmpty(this.props.store.model.organizationId)}
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CRow>
						</CFormGroup>
					</div>
					{this.props.store.isManuallyPeriodEditingActive && (
						<CForm className="mx-5 mb-3">
							<CRow>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol
											md={(() => {
												if (this.injected.directoryStore.models.windowSize > 1084) {
													return '4';
												} else {
													return '6';
												}
											})()}>
											<NatValueInput<Date>
												object={this.props.store.model}
												property="periodFrom"
												placeholder="Введите дату"
												type="date"
												formatter={dateFormatter}
												size="sm"
												label="Период от"
												invalid={!this.props.store.model.periodFrom}
											/>
										</CCol>
										<CCol
											md={(() => {
												if (this.injected.directoryStore.models.windowSize > 1084) {
													return '4';
												} else {
													return '6';
												}
											})()}>
											<NatValueInput<Date> object={this.props.store.model} property="periodTo" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="До" invalid={!this.props.store.model.periodTo} />
										</CCol>
									</CFormGroup>
								</CCol>
							</CRow>
						</CForm>
					)}
					<div className="d-flex flex-row mx-3 mb-4 nat__statistics__wrapper flex-wrap">
						{_.map(this.props.store.metrics, (item: any, index: number) => {
							return (
								<div
									className={(() => {
										if (this.injected.directoryStore.models.windowSize > 900) {
											return 'px-2 pb-3 nat__statistics__item';
										} else {
											return 'px-2 pb-3 nat__statistics__item w-100 nat__statistics__item__mobile';
										}
									})()}
									key={index}>
									<div className="h-100 nat__card__wrapper nat__borders__2-75 p-2">
										<CCardHeader className="bg-transparent text-dark text-left font__size__16 p-2 pl-3">{catalog.renderCatalogName(item.metric)}</CCardHeader>
										{_.map(item.result.fieldItemList, (itm, ind: number) => {
											return (
												<div className="d-flex justify-content-between p-2 pl-3" key={ind}>
													<div className="font__size__16 text-dark">{catalog.renderCatalogName(itm)}</div>
													<div className="font__size__16 text-dark">
														{(() => {
															if (!_.isEmpty(itm.meaningId)) {
																if (itm.meaningId === 'meanings.currency') {
																	return `${priceFormatter.renderValue(itm.valueId)} руб.`;
																} else {
																	return `${itm.valueId}`;
																}
															} else {
																return `${itm.valueId}`;
															}
														})()}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
						<AlertDialog
							isModalOpen={this.state.isRegistrationConfirmation}
							title="Почта подтверждена!"
							buttonItemList={[
								{
									title: 'OK',
									onClick: () => {
										this.setState({
											isRegistrationConfirmation: false
										});
									}
								}
							]}
						/>
						<AlertDialog
							isModalOpen={this.state.isWelcomeModalOpen}
							title="Добро пожаловать в ваше облако!"
							message={`Для последующих авторизаций необходимо использовать следующее имя "admin@${localStorage.getItem('realm')}".`}
							buttonItemList={[
								{
									title: 'OK',
									onClick: () => {
										localStorage.removeItem('showWelcomeMessage');
										this.setState({
											isWelcomeModalOpen: false
										});
									}
								}
							]}
						/>
					</div>
				</div>
				{this.props.store.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</div>
		);
	}
}

export default UseLocation(withRouter(NatStatistics));
