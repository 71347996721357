import { makeNatiwiApiInstance, natiwiApiInstance } from './instance';

class NatiwiApi {
	login(loginData: { login: string; password: string }) {
		return natiwiApiInstance.post('/tenantClients/loginTenantClient', loginData);
	}
	logout(logoutData: { tenantAccessTokenId: string | null; tenantClientId: string | null }) {
		return makeNatiwiApiInstance().post('/tenantClients/logoutTenantClient', logoutData);
	}
	resetPasswordTenantClient(resetPasswordData: { login: string; callbackUrl: string }) {
		return natiwiApiInstance.post('/tenantClients/resetPasswordTenantClient', resetPasswordData);
	}
	setPasswordTenantClient(setPasswordData: { userId: string; password: string; accessTokenId: string; tenantClientId: string }) {
		return natiwiApiInstance.post('/tenantClients/setPasswordTenantClient', setPasswordData);
	}
	registerTenantClient(registrationData: { email: string; password: string }) {
		return natiwiApiInstance.post('/tenantClients/registerTenantClient', registrationData);
	}
	deployTenantClient() {
		return makeNatiwiApiInstance().post('/tenantClients/deployTenantClient', {});
	}
	checkTenantClientDeployAvailability() {
		return makeNatiwiApiInstance().post('/tenantClients/checkTenantClientDeployAvailability', {});
	}
	findAll(pluralName: string, filter: any) {
		return makeNatiwiApiInstance().get(`/${pluralName}?filter=${JSON.stringify(filter)}`);
	}
	find(pluralName: string, filter: any, id: string) {
		return makeNatiwiApiInstance().get(`/${pluralName}/${id}?filter=${JSON.stringify(filter)}`);
	}
	fetchPartnerSubscriptionInfo() {
		return makeNatiwiApiInstance().post('/subscriptionPlans/fetchPartnerSubscriptionInfo', {});
	}
	fetchAvailableSubscriptionPlans(data?: any) {
		return makeNatiwiApiInstance().post('/subscriptionPlans/fetchAvailableSubscriptionPlans', data);
	}
	fetchPaymentOptionList() {
		return makeNatiwiApiInstance().post('/shoppingCarts/fetchPaymentOptionList', {});
	}
	resetShoppingCart() {
		return makeNatiwiApiInstance().post('/shoppingCarts/resetShoppingCart', {});
	}
	fetchShoppingCart() {
		return makeNatiwiApiInstance().post('/shoppingCarts/fetchShoppingCart', {});
	}
	updateShoppingCart(shoppingCart: any) {
		return makeNatiwiApiInstance().post('/shoppingCarts/updateShoppingCart', shoppingCart);
	}
	checkoutShoppingCart(data: any) {
		return makeNatiwiApiInstance().post('/shoppingCarts/checkoutShoppingCart', data);
	}
	validate(data: any, paymentOptionId: string) {
		return makeNatiwiApiInstance().post(`/paymentOptions/${paymentOptionId}/validate`, data);
	}
	perform(data: any) {
		return makeNatiwiApiInstance().post('/paymentOptions/perform', data);
	}
	cancelCustomerOrder(data: any) {
		return makeNatiwiApiInstance().post(`/customerOrders/${data.id}/cancelOrder`, {});
	}
	cancelOperation(data: any) {
		return makeNatiwiApiInstance().post(`/partnerSubscriptionOperations/${data.id}/cancelOperation`, {});
	}
	fetchCurrentPartnerSettlement() {
		return makeNatiwiApiInstance().post('/partnerSettlements/fetchCurrentPartnerSettlement', {});
	}
	displayLayoutTemplate(data: { objectType: string; objectId: string }, layoutTemplate: any, params: any = {}) {
		let paramsStr: string = `?objectType=${data.objectType}&objectId=${data.objectId}&params=${JSON.stringify(params)}`;
		return makeNatiwiApiInstance('blob').get(`/layoutTemplates/${layoutTemplate.id}/display${paramsStr}`);
	}
}

export const natiwiApi = new NatiwiApi();

export default NatiwiApi;
