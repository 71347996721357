import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalBody, CModalHeader, CNav, CNavItem, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../Alerts/FormAlertStore';
import NatControlPanel from '../Components/NatControlPanel';
import { relationFormatter } from '../Formatters/RelationFormatter';
import NatDefaultCheckbox from '../Inputs/NatDefaultCheckBox';
import NatRelationInput from '../Inputs/NatRelationInput';
import { catalog } from '../support/Catalog';
import { NatAclMappingStoreType } from './NatAclMappingStore';

interface PropsType extends RouteComponentProps {
	toggleNatAclMapping(status: boolean): void;
	isModalOpen: boolean;
	subject: any;
	type?: string;
	pluralName?: string;
	filterInclude?: Array<any>;
	mappingPluralName?: string;
	property?: string;
	model: string;
}

interface StateType {
	activeTab: string;
	tabs: Array<number>;
}

interface InjectedProps extends PropsType {
	natAclMappingStore: NatAclMappingStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore', 'natAclMappingStore', 'formAlertStore')
@observer
class NatAclMapping extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	tabRef: any;
	constructor(props: PropsType) {
		super(props);
		this.tabRef = React.createRef();
		this.resetData = this.resetData.bind(this);
		this.buildRolesTable = this.buildRolesTable.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.addEmptyString = this.addEmptyString.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.bulkUpsert = this.bulkUpsert.bind(this);
		this.save = this.save.bind(this);
		this.deleteUsersMappings = this.deleteUsersMappings.bind(this);
		this.collectIds = this.collectIds.bind(this);
		this.toggleTabs = this.toggleTabs.bind(this);
		this.reactions = [];
		this.state = {
			activeTab: 'roles',
			tabs: [0]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		let groupedAclMapping = _.chain(this.injected.natAclMappingStore.list)
			.groupBy((item) => {
				return `${item.accessType}-${item.principalId}`;
			})
			.value();
		_.forEach(this.injected.natAclMappingStore.aclUserTable, (item: any) => {
			this.reactions.push(
				reaction(
					() => item.read,
					(value, previousValue) => {
						if (item.read !== previousValue) {
							item.isReadChanged = true;
							if (!_.isEmpty(groupedAclMapping[`read-${item.principalId}`])) {
								_.forEach(groupedAclMapping[`read-${item.principalId}`], (itm) => {
									itm.permission = item.read;
								});
							}
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.write,
					(value, previousValue) => {
						if (item.write !== previousValue) {
							item.isWriteChanged = true;
							if (!_.isEmpty(groupedAclMapping[`write-${item.principalId}`])) {
								_.forEach(groupedAclMapping[`write-${item.principalId}`], (itm) => {
									itm.permission = item.write;
								});
							}
						}
					}
				)
			);
		});
		_.forEach(this.injected.natAclMappingStore.aclRoleTable, (item: any) => {
			this.reactions.push(
				reaction(
					() => item.read,
					(value, previousValue) => {
						if (item.read !== previousValue) {
							item.isReadChanged = true;
							if (!_.isEmpty(groupedAclMapping[`read-${item.principalId}`])) {
								_.forEach(groupedAclMapping[`read-${item.principalId}`], (itm) => {
									itm.permission = item.read;
								});
							}
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.write,
					(value, previousValue) => {
						if (item.write !== previousValue) {
							item.isWriteChanged = true;
							if (!_.isEmpty(groupedAclMapping[`write-${item.principalId}`])) {
								_.forEach(groupedAclMapping[`write-${item.principalId}`], (itm) => {
									itm.permission = item.write;
								});
							}
						}
					}
				)
			);
		});
	}
	componentDidMount() {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		if (!_.isEmpty(tabPanes[0])) {
			tabPanes[0].classList.add('nat__table__wrapper');
		}
		if (!_.isEmpty(tabs[0])) {
			tabs[0].classList.add('active');
		}
		this.resetData();
		let filter = {
			where: {
				subjectId: {
					inq: this.collectIds()
				}
			},
			include: this.injected.natAclMappingStore.filterInclude
		};
		let rolesFilter = {
			where: { dynamic: false },
			order: ['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]
		};
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', true);
		controller
			.sliceLast(this.injected.natAclMappingStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'list', data);
					this.buildUsersTable();
					this.makeReactions();
				}
				return controller.fetchRoles(this.injected.natAclMappingStore.pluralName, rolesFilter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'roles', data);
					this.buildRolesTable();
					this.makeReactions();
				}
				this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
			})
			.catch((error) => {
				if (error.response.status === 401) {
					this.injected.userStore.setValue(this.injected.userStore.userLogin, 'statusCode', error.response.status);
				}
				this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	collectIds() {
		let ids: Array<string> = [];
		_.forEach(this.props.subject, (item) => {
			ids.push(item.id);
		});
		return ids;
	}
	resetData() {
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'list', []);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'aclUserTable', []);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'aclRoleTable', []);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'currentPage', 0);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'collectedModels', []);
	}
	buildRolesTable() {
		let groupByPermission = _.chain(this.injected.natAclMappingStore.list)
			.cloneDeep()
			.filter({ principalType: 'Role' })
			.groupBy((item) => {
				return `${item.accessType}-${item.permission}-${item.principalId}`;
			})
			.value();
		let rolesList = _.cloneDeep(this.injected.natAclMappingStore.roles);
		const tableRows = [] as Array<any>;
		_.transform(
			rolesList,
			(result: Array<any>, value: any) => {
				if (value.name !== 'administrator') {
					if (!_.isEmpty(groupByPermission[`write-true-${value.id}`]) && _.isEmpty(groupByPermission[`write-false-${value.id}`])) {
						if (groupByPermission[`write-true-${value.id}`].length === this.props.subject.length) {
							value.write = true;
							value.isWriteIndeterminate = false;
						} else {
							value.write = false;
							value.isWriteIndeterminate = true;
						}
					} else if (_.isEmpty(groupByPermission[`write-true-${value.id}`]) && !_.isEmpty(groupByPermission[`write-false-${value.id}`])) {
						if (groupByPermission[`write-false-${value.id}`].length === this.props.subject.length) {
							value.write = false;
							value.isWriteIndeterminate = false;
						} else {
							value.write = false;
							value.isWriteIndeterminate = true;
						}
					} else if (!_.isEmpty(groupByPermission[`write-true-${value.id}`]) && !_.isEmpty(groupByPermission[`write-false-${value.id}`])) {
						value.write = false;
						value.isWriteIndeterminate = true;
					} else {
						value.write = false;
						value.isWriteIndeterminate = false;
					}
					if (!_.isEmpty(groupByPermission[`read-true-${value.id}`]) && _.isEmpty(groupByPermission[`read-false-${value.id}`])) {
						if (groupByPermission[`read-true-${value.id}`].length === this.props.subject.length) {
							value.read = true;
							value.isReadIndeterminate = false;
						} else {
							value.read = false;
							value.isReadIndeterminate = true;
						}
					} else if (_.isEmpty(groupByPermission[`read-true-${value.id}`]) && !_.isEmpty(groupByPermission[`read-false-${value.id}`])) {
						if (groupByPermission[`read-false-${value.id}`].length === this.props.subject.length) {
							value.read = false;
							value.isReadIndeterminate = false;
						} else {
							value.read = false;
							value.isReadIndeterminate = true;
						}
					} else if (!_.isEmpty(groupByPermission[`read-true-${value.id}`]) && !_.isEmpty(groupByPermission[`read-false-${value.id}`])) {
						value.read = false;
						value.isReadIndeterminate = true;
					} else {
						value.read = false;
						value.isReadIndeterminate = false;
					}
					value.isReadChanged = false;
					value.isWriteChanged = false;
					value.principalId = value.id;
					value.principalType = 'Role';
					this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'subjectType', _.get(this.injected.natAclMappingStore.list[0], 'subjectType', ''));
					result.push(value);
				}
			},
			tableRows
		);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'aclRoleTable', tableRows);
	}
	buildUsersTable() {
		let aclMappingList = _.chain(this.injected.natAclMappingStore.list).cloneDeep().filter({ principalType: 'OrmUser' }).keyBy('principalId').value();
		let groupByPermission = _.chain(this.injected.natAclMappingStore.list)
			.cloneDeep()
			.filter({ principalType: 'OrmUser' })
			.groupBy((item) => {
				return `${item.accessType}-${item.permission}-${item.principalId}`;
			})
			.value();
		const tableRows = [] as Array<any>;
		_.transform(
			aclMappingList,
			(result: Array<any>, value: any) => {
				if (!_.isEmpty(groupByPermission[`write-true-${value.principalId}`]) && _.isEmpty(groupByPermission[`write-false-${value.principalId}`])) {
					if (groupByPermission[`write-true-${value.principalId}`].length === this.props.subject.length) {
						value.write = true;
						value.isWriteIndeterminate = false;
					} else {
						value.write = false;
						value.isWriteIndeterminate = true;
					}
				} else if (_.isEmpty(groupByPermission[`write-true-${value.principalId}`]) && !_.isEmpty(groupByPermission[`write-false-${value.principalId}`])) {
					if (groupByPermission[`write-false-${value.principalId}`].length === this.props.subject.length) {
						value.write = false;
						value.isWriteIndeterminate = false;
					} else {
						value.write = false;
						value.isWriteIndeterminate = true;
					}
				} else if (!_.isEmpty(groupByPermission[`write-true-${value.principalId}`]) && !_.isEmpty(groupByPermission[`write-false-${value.principalId}`])) {
					value.write = false;
					value.isWriteIndeterminate = true;
				} else {
					value.write = false;
					value.isWriteIndeterminate = false;
				}
				if (!_.isEmpty(groupByPermission[`read-true-${value.principalId}`]) && _.isEmpty(groupByPermission[`read-false-${value.principalId}`])) {
					if (groupByPermission[`read-true-${value.principalId}`].length === this.props.subject.length) {
						value.read = true;
						value.isReadIndeterminate = false;
					} else {
						value.read = false;
						value.isReadIndeterminate = true;
					}
				} else if (_.isEmpty(groupByPermission[`read-true-${value.principalId}`]) && !_.isEmpty(groupByPermission[`read-false-${value.principalId}`])) {
					if (groupByPermission[`read-false-${value.principalId}`].length === this.props.subject.length) {
						value.read = false;
						value.isReadIndeterminate = false;
					} else {
						value.read = false;
						value.isReadIndeterminate = true;
					}
				} else if (!_.isEmpty(groupByPermission[`read-true-${value.principalId}`]) && !_.isEmpty(groupByPermission[`read-false-${value.principalId}`])) {
					value.read = false;
					value.isReadIndeterminate = true;
				} else {
					value.read = false;
					value.isReadIndeterminate = false;
				}
				value.isReadChanged = false;
				value.isWriteChanged = false;
				value.deleted = false;
				value.principalType = 'OrmUser';
				let resultMap = _.chain(result).cloneDeep().keyBy('principalId').value();
				if (resultMap[value.principalId] === undefined) {
					result.push(value);
				}
			},
			tableRows
		);
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'aclUserTable', tableRows);
	}
	addEmptyString() {
		let obj = {
			accessType: 'write',
			authorId: null,
			permission: false,
			principalId: null,
			principalType: 'OrmUser',
			sourceId: this.injected.userStore.userLogin.user.id,
			sourceType: 'OrmUser',
			subjectId: null,
			subjectType: this.props.model,
			read: false,
			write: false,
			isWriteIndeterminate: false,
			isReadIndeterminate: false,
			id: generateRandomId(),
			isReadChanged: false,
			isWriteChanged: false,
			deleted: false,
			principal: {
				anonymous: false,
				generatedEmail: false,
				employee: false,
				id: '',
				realm: '',
				username: '',
				password: '',
				email: ''
			}
		};
		const emptyObj = _.find(this.injected.natAclMappingStore.aclUserTable, { principalId: null });
		if (_.isEmpty(emptyObj)) {
			runInAction(() => {
				this.injected.natAclMappingStore.aclUserTable.push(obj);
			});
			this.makeReactions();
		}
	}
	collectModels(data: any) {
		const position = this.injected.natAclMappingStore.collectedModels.indexOf(data);
		let newArray = this.injected.natAclMappingStore.collectedModels.slice();
		if (position !== -1) {
			newArray.splice(position, 1);
		} else {
			newArray.push(data);
		}
		this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'collectedModels', newArray);
	}
	bulkUpsert() {
		let aclMappings = _.cloneDeep(this.injected.natAclMappingStore.list);
		let aclMapping = _.chain(aclMappings)
			.keyBy((item) => {
				return `${item.accessType}-${item.principalId}-${item.subjectId}`;
			})
			.value();
		let aclUserTable = _.cloneDeep(this.injected.natAclMappingStore.aclUserTable);
		let aclRoleTable = _.cloneDeep(this.injected.natAclMappingStore.aclRoleTable);
		let aclTableList = aclUserTable.concat(aclRoleTable);
		let editArray: Array<any> = [];
		_.forEach(aclTableList, (item) => {
			_.forEach(this.props.subject, (itm) => {
				let readAclMapping = aclMapping[`read-${item.principalId}-${itm.id}`];
				let writeAclMapping = aclMapping[`write-${item.principalId}-${itm.id}`];
				let obj = {
					accessType: '',
					authorId: null,
					permission: false,
					principalId: null,
					principalType: item.principalType,
					sourceId: this.injected.userStore.userLogin.user.id,
					sourceType: 'OrmUser',
					subjectId: itm.id,
					subjectType: this.props.model
				};
				if (_.isEmpty(readAclMapping)) {
					if (item.read) {
						obj.accessType = 'read';
						obj.permission = item.read;
						obj.principalId = item.principalId;
						editArray.push(_.cloneDeep(obj));
					}
				} else {
					if (item.isReadChanged) {
						editArray.push(readAclMapping);
					}
				}
				if (_.isEmpty(writeAclMapping)) {
					if (item.write) {
						obj.accessType = 'write';
						obj.permission = item.write;
						obj.principalId = item.principalId;
						editArray.push(_.cloneDeep(obj));
					}
				} else {
					if (item.isWriteChanged) {
						editArray.push(writeAclMapping);
					}
				}
			});
		});
		_.forEach(editArray, (item) => {
			delete item.source;
			delete item.principal;
			delete item.subject;
			item.sourceId = this.injected.userStore.userLogin.user.id;
		});
		if (editArray.length !== 0) {
			let filter = {
				where: {
					subjectId: {
						inq: this.collectIds()
					}
				},
				include: this.injected.natAclMappingStore.filterInclude
			};
			this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', true);
			controller
				.bulkUpsert(this.injected.natAclMappingStore.pluralName, editArray)
				.then(() => {
					catalog.showAlert('Операция прошла успешно');
					return controller.sliceLast(this.injected.natAclMappingStore.pluralName, filter);
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'list', data);
						this.buildUsersTable();
						this.buildRolesTable();
						this.makeReactions();
					}
					this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
				});
		}
	}
	deleteUsersMappings() {
		if (this.injected.natAclMappingStore.collectedModels.length !== 0) {
			let aclMapping = _.chain(this.injected.natAclMappingStore.list)
				.cloneDeep()
				.filter({ principalType: 'OrmUser' })
				.keyBy((item) => {
					return `${item.accessType}-${item.principalId}`;
				})
				.value();
			let where: { subjectId: any; principalId?: any } = {
				subjectId: {
					inq: this.collectIds()
				}
			};
			let arrayPrincipalIds: Array<string> = [];
			_.forEach(this.injected.natAclMappingStore.collectedModels, (item: any) => {
				let readAclMapping = aclMapping[`read-${item.principalId}`];
				let writeAclMapping = aclMapping[`write-${item.principalId}`];
				if (item.deleted) {
					if (!_.isEmpty(readAclMapping) || !_.isEmpty(writeAclMapping)) {
						arrayPrincipalIds.push(item.principalId);
					}
				}
			});
			where.principalId = { inq: arrayPrincipalIds };
			if (arrayPrincipalIds.length !== 0) {
				controller
					.delete(this.injected.natAclMappingStore.pluralName, where)
					.then(() => {
						let filter = {
							where: {
								subjectId: {
									inq: this.collectIds()
								}
							},
							include: this.injected.natAclMappingStore.filterInclude
						};
						catalog.showAlert('Операция прошла успешно');
						return controller.sliceLast(this.injected.natAclMappingStore.pluralName, filter);
					})
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'list', data);
							this.buildUsersTable();
							this.buildRolesTable();
							this.makeReactions();
						}
						this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'collectedModels', []);
						this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.injected.natAclMappingStore.setValue(this.injected.natAclMappingStore, 'isLoading', false);
					});
			}
		}
	}
	save() {
		this.bulkUpsert();
		this.deleteUsersMappings();
	}
	toggleTabs(index: number) {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		_.forEach(tabs, (item, ind: number) => {
			if (index !== ind) {
				item.classList.remove('active');
			} else {
				item.classList.add('active');
			}
		});
		_.forEach(tabPanes, (item, ind: number) => {
			if (index !== ind) {
				item.classList.remove('nat__table__wrapper');
			} else {
				item.classList.add('nat__table__wrapper');
			}
		});
		const position = this.state.tabs.indexOf(index);
		let newDetails: Array<number> = [];
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			tabs: newDetails
		});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'СОХРАНИТЬ',
				onClick: () => {
					this.save();
					this.props.toggleNatAclMapping(false);
				},
				disabled: this.injected.natAclMappingStore.isLoading
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatAclMapping(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}
				innerRef={this.tabRef}>
				<CModalHeader className="d-flex my-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return (
									<form className="d-flex w-100">
										{_.map(controlButtons, (item, index) => {
											if (!item.dontShow) {
												return (
													<CButton
														key={index}
														type="button"
														size="sm"
														className="mr-1 nat__button__hover"
														onClick={() => {
															item.onClick();
														}}>
														{item.title}
													</CButton>
												);
											}
										})}
									</form>
								);
							} else {
								return (
									<CDropdown>
										<CDropdownToggle caret className="nat__button__hover">
											МЕНЮ
										</CDropdownToggle>
										<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CDropdownItem
															key={index}
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CDropdownItem>
													);
												}
											})}
										</CDropdownMenu>
									</CDropdown>
								);
							}
						})()}
					</div>
				</CModalHeader>
				<div className={this.injected.directoryStore.models.windowSize > 1084 ? 'p-0 nat__form__tabs' : 'p-0 nat__form__tabs nat__form__tabs__mobile'}>
					<CNav variant="tabs" className="p-2 mx-4">
						<CNavItem
							className="mr-1"
							onClick={() => {
								this.setState({
									activeTab: 'roles'
								});
							}}>
							<a
								data-tab="roles"
								className="nav-link cursor__pointer"
								onClick={(event) => {
									event.preventDefault();
									this.toggleTabs(0);
								}}>
								Роли
							</a>
						</CNavItem>
						<CNavItem
							className="mr-1"
							onClick={() => {
								this.setState({
									activeTab: 'users'
								});
							}}>
							<a
								data-tab="users"
								className="nav-link cursor__pointer"
								onClick={(event) => {
									event.preventDefault();
									this.toggleTabs(1);
								}}>
								Пользователи
							</a>
						</CNavItem>
					</CNav>
				</div>
				<CModalBody className="p-0 mx-4 mb-4 mt-3">
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover nat__tab">
						<div className="nat__modal__table_1">
							<GridTable
								columns={[
									{
										id: 1,
										field: 'displayName',
										label: 'Роль',
										cellRenderer: ({ data }: { data: any }) => {
											return (
												<div className="rgt-cell-inner rgt-text-truncate">
													{(() => {
														if (!_.isEmpty(data.displayName)) {
															return data.displayName[this.injected.directoryStore.models.language];
														}
													})()}
												</div>
											);
										}
									},
									{
										id: 2,
										field: 'read',
										label: 'Чтение',
										cellRenderer: ({ data }: { data: any }) => {
											if (!data.static) {
												return (
													<div className="rgt-cell-inner">
														<NatDefaultCheckbox object={data} property="read" columnClassName="" wrapperClassName="d-flex m-0 align-items-center" row={true} indeterminate={data.isReadIndeterminate} />
													</div>
												);
											}
										}
									},
									{
										id: 3,
										field: 'write',
										label: 'Запись',
										cellRenderer: ({ data }: { data: any }) => {
											if (!data.static) {
												return (
													<div className="rgt-cell-inner">
														<NatDefaultCheckbox object={data} property="write" columnClassName="" wrapperClassName="d-flex m-0 align-items-center" row={true} indeterminate={data.isWriteIndeterminate} />
													</div>
												);
											}
										}
									}
								]}
								rows={this.injected.natAclMappingStore.aclRoleTable}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								isVirtualScroll={false}
							/>
						</div>
					</div>
					<div className="nat__tab">
						<NatControlPanel
							iconClassname="text-danger"
							className="nat__form__control__buttons nat__card__wrapper nat__borders__2-75 p-2 mb-4"
							buttonItemList={[
								{
									title: 'ДОБАВИТЬ',
									onClick: () => {
										this.addEmptyString();
									},
									withoutIcon: true,
									disabled: false
								},
								{
									title: 'УДАЛИТЬ',
									onClick: () => {
										let aclMapping = _.chain(this.injected.natAclMappingStore.list)
											.cloneDeep()
											.filter({ principalType: 'OrmUser' })
											.keyBy((item) => {
												return `${item.accessType}-${item.principalId}-${item.id}`;
											})
											.value();
										_.forEach(this.injected.natAclMappingStore.collectedModels, (item: any) => {
											if (this.injected.natAclMappingStore.aclUserTable.indexOf(item) !== -1) {
												let readAclMapping = aclMapping[`read-${item.principalId}-${item.id}`];
												let writeAclMapping = aclMapping[`write-${item.principalId}-${item.id}`];
												if (!_.isEmpty(readAclMapping) || !_.isEmpty(writeAclMapping)) {
													item.deleted = true;
												}
												runInAction(() => {
													this.injected.natAclMappingStore.aclUserTable.splice(this.injected.natAclMappingStore.aclUserTable.indexOf(item), 1);
												});
											}
										});
									},
									withoutIcon: true,
									disabled: this.injected.natAclMappingStore.collectedModels.length === 0
								}
							]}
						/>
						<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
							<div className="nat__modal__table_1">
								<GridTable
									columns={[
										{
											id: 'checkbox',
											pinned: true,
											className: '',
											width: '54px',
											minResizeWidth: 0,
											maxResizeWidth: null,
											resizable: false,
											visible: true,
											headerCellRenderer: () => {
												return <div></div>;
											},
											cellRenderer: ({ data }: { data: any }) => {
												return <div className="rgt-cell-inner">{!data.static && <input type="checkbox" id="checkbox" onChange={() => this.collectModels(data)} />}</div>;
											}
										},
										{
											id: 1,
											field: 'principal',
											label: 'Пользователь',
											width: '280px',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div className="rgt-cell-inner ml-3 p-0">
														<NatRelationInput
															object={data}
															property="principalId"
															relation="principal"
															placeholder="Выберите пользователя"
															type="text"
															size="sm"
															inputClassName="text-left"
															formatter={relationFormatter(false, true)}
															pluralName="users"
															filterWhere={{}}
															autoComplete="off"
															renderName={(item) => {
																return item.username || item.email;
															}}
															or={(value) => {
																return [
																	{
																		username: {
																			like: value,
																			options: 'i'
																		}
																	},
																	{
																		email: {
																			like: value,
																			options: 'i'
																		}
																	}
																];
															}}
															backspace={(object, value) => {
																runInAction(() => {
																	if (!_.isEmpty(object.username)) {
																		object.username = value;
																	}
																	if (!_.isEmpty(object.email)) {
																		object.email = value;
																	}
																});
															}}
														/>
													</div>
												);
											}
										},
										{
											id: 2,
											field: 'read',
											label: 'Чтение',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div className="rgt-cell-inner">
														<NatDefaultCheckbox object={data} property="read" columnClassName="" wrapperClassName="d-flex m-0 align-items-center" row={true} indeterminate={data.isReadIndeterminate} />
													</div>
												);
											}
										},
										{
											id: 3,
											field: 'write',
											label: 'Запись',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div className="rgt-cell-inner">
														<NatDefaultCheckbox object={data} property="write" columnClassName="" wrapperClassName="d-flex m-0 align-items-center" row={true} indeterminate={data.isWriteIndeterminate} />
													</div>
												);
											}
										}
									]}
									rows={this.injected.natAclMappingStore.aclUserTable}
									isPaginated={false}
									texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
									showSearch={false}
									showColumnVisibilityManager={false}
									showRowsInformation={false}
									onColumnsChange={() => {}}
									sort={{ colId: null }}
									isVirtualScroll={false}
								/>
							</div>
						</div>
					</div>
					{this.injected.natAclMappingStore.isLoading && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
				</CModalBody>
			</CModal>
		);
	}
}

export default withRouter(NatAclMapping);
