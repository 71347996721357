import axios from 'axios';

import { config } from '../../baseUrls';

export function returnClientApiUrl() {
	return config.CLIENT_API_URL;
}

export function returnNatiwiApiUrl() {
	return config.NATIWI_API_URL;
}

export const instance = axios.create({
	withCredentials: true,
	baseURL: returnClientApiUrl()
});

export const natiwiApiInstance = axios.create({
	withCredentials: true,
	baseURL: returnNatiwiApiUrl()
});

export const benzin = axios.create({
	withCredentials: false,
	baseURL: returnClientApiUrl()
});

export function makeInstance(responseType?: any) {
	let instance = axios.create({
		withCredentials: true,
		baseURL: returnClientApiUrl()
	});
	instance.interceptors.request.use(function (config) {
		let accessToken = localStorage.getItem('accessToken');
		let tenantClientId = localStorage.getItem('tenantClientId');
		if (accessToken) {
			config.headers['x-access-token'] = accessToken;
		}
		if (tenantClientId) {
			config.headers['x-tenant-client-id'] = tenantClientId;
		}
		if (responseType) {
			config.responseType = responseType;
		}
		return config;
	});
	return instance;
}

export function makeNatiwiApiInstance(responseType?: any) {
	let instance = axios.create({
		withCredentials: true,
		baseURL: returnNatiwiApiUrl()
	});
	instance.interceptors.request.use(function (config) {
		let masterAccessToken = localStorage.getItem('masterAccessToken');
		if (masterAccessToken) {
			config.headers['x-access-token'] = masterAccessToken;
		}
		if (responseType) {
			config.responseType = responseType;
		}
		return config;
	});
	return instance;
}
