import { catalog } from '../../shared/support/Catalog';
import { api } from '../Api/Api';

// import { userApi } from '../Api/OrmUserApi';

class OrmController {
	// OrmUser methods

	login(loginData: { email: string; password: string }) {
		return api
			.login(loginData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	logout() {
		return api.logout().catch((error) => {
			return Promise.reject(catalog.handleRequestError(error));
		});
	}
	me() {
		return api
			.me()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	findAll(pluralName: string, filter: any) {
		return api
			.findAll(pluralName, filter)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	find(pluralName: string, filter: any, id: string) {
		return api
			.find(pluralName, filter, id)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	findOne(pluralName: string, filter: any) {
		return api
			.findOne(pluralName, filter)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	bulkUpsert(pluralName: string, models: any) {
		return api
			.bulkUpsert(pluralName, models)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	delete(pluralName: string, filter: any) {
		return api.delete(pluralName, filter).catch((error) => {
			return Promise.reject(catalog.handleRequestError(error));
		});
	}
	makeDeleted(modelName: string, mode: boolean, id: string) {
		return api.makeDeleted(modelName, mode, id).catch((error) => {
			return Promise.reject(catalog.handleRequestError(error));
		});
	}
	makePosted(modelName: string, mode: string, model: any) {
		return api
			.makePosted(modelName, mode, model)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	sliceLast(pluralName: string, filter: any, params?: any) {
		return api
			.sliceLast(pluralName, filter, params)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchAvailableContracts(partner: any, organization?: any) {
		return api
			.fetchAvailableContracts(partner, organization)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchApplicableContract(partner: any, organization?: any) {
		return api
			.fetchApplicableContract(partner, organization)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	uploadFile(data: any) {
		return api
			.uploadFile(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	removeBackground(model: any) {
		return api.removeBackground(model);
	}
	execute(report: any, reportPreset: any) {
		return api
			.execute(report, reportPreset)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	// download(image: any) {
	// 	return api.download(image);
	// }
	upload(container: string, data: any) {
		return api.upload(container, data);
	}
	download(container: string, fileName: any) {
		return api.download(container, fileName);
	}
	balance(pluralName: string, filter: any, params?: any) {
		return api
			.balance(pluralName, filter, params)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	displayLabelTemplate(data: any, params: any = {}) {
		return api
			.displayLabelTemplate(data, params)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	displayLayoutTemplate(data: { objectType: string; objectId: string }, layoutTemplate: any, params: any = {}) {
		return api
			.displayLayoutTemplate(data, layoutTemplate, params)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchRoles(pluralName: string, filter: any) {
		return api
			.fetchRoles(pluralName, filter)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fill(pluralName: string, data: any) {
		return api
			.fill(pluralName, data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchObjectHierarchyByOwner(pluralName: string, data: any) {
		return api
			.fetchObjectHierarchyByOwner(pluralName, data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchProductFeatures(data: any) {
		return api
			.fetchProductFeatures(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchProductUnits(data: any) {
		return api
			.fetchProductUnits(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchProductItemListPrices(productItemList: Array<any>, priceType?: any) {
		return api
			.fetchProductItemListPrices(productItemList, priceType)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	updateProductItemListPrices(productItemList: Array<any>, priceType?: any) {
		return api
			.updateProductItemListPrices(productItemList, priceType)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchPartnerDocuments(data: any) {
		return api
			.fetchPartnerDocuments(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchProductKindFeatures(data: any) {
		return api
			.fetchProductKindFeatures(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	register(data: any) {
		return api.register(data).catch((error) => {
			return Promise.reject(catalog.handleRequestError(error));
		});
	}
	resetPassword(data: any) {
		return api.resetPassword(data).catch((error) => {
			return Promise.reject(
				catalog.handleRequestError(error, {
					404: {
						showMessage: true,
						message: 'Для восстановления пароля необходимо указать Вашу почту!'
					}
				})
			);
		});
	}
	patchUser(data: any) {
		return api.patchUser(data).catch((error) => {
			return Promise.reject(catalog.handleRequestError(error));
		});
	}
	fetchFeaturesByProduct(data: any) {
		return api
			.fetchFeaturesByProduct(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchUnitsByProduct(data: any) {
		return api
			.fetchUnitsByProduct(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchMetrics(data: any) {
		return api
			.fetchMetrics(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchPartnerSubscriptionInfo() {
		return api
			.fetchPartnerSubscriptionInfo()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchAvailableSubscriptionPlans() {
		return api
			.fetchAvailableSubscriptionPlans()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchPaymentOptionList() {
		return api
			.fetchPaymentOptionList()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	resetShoppingCart() {
		return api
			.resetShoppingCart()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchShoppingCart() {
		return api
			.fetchShoppingCart()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	updateShoppingCart(shoppingCart: any) {
		return api
			.updateShoppingCart(shoppingCart)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	checkoutShoppingCart(data: any) {
		return api
			.checkoutShoppingCart(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	validate(data: any) {
		return api
			.validate(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	api() {
		return api
			.api()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	apply(data: any) {
		return api
			.apply(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	returnApiResult(response: any) {
		if (response.status === 200) {
			return response.data;
		}
	}
}

export const controller = new OrmController();

export default OrmController;
