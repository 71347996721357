import CIcon from '@coreui/icons-react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatList from '../../shared/Components/NatList';
import NatListContainer from '../../shared/Components/NatListContainer';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import { ResponsiblePersonsListStoreType } from './ResponsiblePersonsListStore';

interface PropsType extends FormContainerPropsType<ResponsiblePersonsListStoreType> {}

interface StateType {}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class ResponsiblePersonsListContainer extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.createFilter = this.createFilter.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	createFilter() {
		// if (!_.isEmpty(organizationBankAccountsSearchFields.name)) {
		// 	or = [
		// 		{
		// 			name: {
		// 				like: organizationBankAccountsSearchFields.name,
		// 				options: 'i'
		// 			}
		// 		},
		// 		{
		// 			sequenceNumber: {
		// 				like: organizationBankAccountsSearchFields.name
		// 			}
		// 		}
		// 	];
		// 	filter.where.or = or;
		// }
	}
	resetSearchFields() {
		runInAction(() => {
			this.props.store.chipsFields = {
				name: {
					id: null,
					chips: [],
					title: 'Наименование',
					isLocalizedString: true
				},
				position: {
					id: null,
					chips: [],
					title: 'Должность'
				},
				owner: {
					isRelation: true,
					id: null,
					chips: []
				},
				createdAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true
				},
				createdAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false
				},
				deleted: {
					id: false,
					display: { isChecked: false, title: 'Отображать' },
					doNotDisplay: { isChecked: true, title: 'Не отображать' },
					chips: [
						{
							id: generateRandomId(),
							name: {
								ru: 'Удаленные: не отображать'
							}
						}
					],
					isBoolean: true,
					title: 'Удаленные'
				}
			};
		});
	}
	render() {
		return (
			<NatListContainer store={this.props.store} createFilter={this.createFilter} resetSearchFields={this.resetSearchFields} defaultFilterWhere={{ deleted: false }} navigationRoute={this.props.navigationRoute} index={this.props.index}>
				{(childrenProps) => (
					<NatList
						name="responsiblePersons"
						store={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						columns={[
							{
								id: 'checkbox',
								pinned: true,
								className: '',
								width: '54px',
								minResizeWidth: 0,
								maxResizeWidth: null,
								resizable: false,
								visible: true,
								headerCellRenderer: () => {
									return <div></div>;
								},
								cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
									return (
										<div className="rgt-cell-inner ml-0 mr-0">
											<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
										</div>
									);
								}
							},
							{
								id: 1,
								field: 'deleted',
								label: '',
								width: '70px',
								cellRenderer: ({ data }: { data: any }) => {
									return <div className="rgt-cell-inner">{<CIcon src={catalog.pickupCatalogStateImage(data)} size="lg" />}</div>;
								},
								sort: () => {}
							},
							{
								id: 2,
								field: 'createdAt',
								label: 'Дата создания',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{listDateTimeFormatter.renderValue(data.createdAt)}
										</div>
									);
								},
								sort: () => {}
							},
							// {
							// 	id: 3,
							// 	field: 'birthdate',
							// 	label: 'День рождения',
							// 	cellRenderer: ({ data }: { data: any }) => {
							// 		return (
							// 			<div
							// 				className={(() => {
							// 					if (data.deleted) {
							// 						return 'rgt-cell-inner rgt-text-truncate text-secondary';
							// 					} else {
							// 						return 'rgt-cell-inner rgt-text-truncate';
							// 					}
							// 				})()}>
							// 				{listDateTimeFormatter.renderValue(data.birthdate)}
							// 			</div>
							// 		);
							// 	},
							// 	sort: () => {}
							// },
							{
								id: 4,
								field: 'name',
								label: 'Наименование',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 5,
								field: 'position',
								label: 'Должность',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{data.position}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 7,
								field: 'owner',
								label: 'Организация',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.owner)}
										</div>
									);
								},
								sort: () => {}
							}
						]}>
						<>
							{/* <NatFormGroupLabel label="День рождения от" labelColumnSettings="7" labelColumnClassName="text-left align-self-center text-black" row={false}>
								<NatValueInput<Date> object={this.props.store.chipsFields.birthdateGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" />
							</NatFormGroupLabel>
							<NatFormGroupLabel label="до" labelColumnSettings="3" labelColumnClassName="text-left align-self-center text-black" row={false}>
								<NatValueInput<Date> object={this.props.store.chipsFields.birthdateLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" />
							</NatFormGroupLabel> */}
							<NatRelationInput
								object={this.props.store.chipsFields.owner}
								property="id"
								relation="relation"
								placeholder="Выберите организацию"
								type="text"
								size="sm"
								formatter={relationFormatter(true)}
								pluralName="organizations"
								filterWhere={{ deleted: false }}
								autoComplete="off"
								label="Организация"
							/>
							<NatValueInput<string> object={this.props.store.chipsFields.position} property="id" placeholder="Введите должность" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Должность" />
						</>
					</NatList>
				)}
			</NatListContainer>
		);
	}
}

export default ResponsiblePersonsListContainer;
