import CIcon from '@coreui/icons-react';
import { CButton, CListGroup, CListGroupItem, CModal, CModalHeader, CNav, CNavItem } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { ReportsStoreType } from '../../reports/Reports/ReportsStore';
import { NavigationContainerStoreType } from '../ContainersStores/NavigationContainerStore';
import { catalog } from '../support/Catalog';

interface PropsType extends RouteComponentProps {
	isShown: boolean;
	toggleDisplaySidebar(): void;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	navigationContainerStore: NavigationContainerStoreType;
	reportsStore: ReportsStoreType;
	userStore: OrmUserStoreType;
}
interface StateType {
	currentNavGroup: any;
	allNavGroups: any;
}
@inject('directoryStore', 'navigationContainerStore', 'reportsStore', 'userStore')
@observer
class NavMenu extends Component<PropsType, StateType> {
	tabRef: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.tabRef = React.createRef();
		this.toggleNavStyles = this.toggleNavStyles.bind(this);
		this.toggleNav = this.toggleNav.bind(this);
		this.reactions = [];
		this.state = {
			currentNavGroup: null,
			allNavGroups: null
		};
		this.reactions.push(
			reaction(
				() => this.injected.directoryStore.models.windowSize,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (value >= 700) {
							if (this.props.isShown) {
								this.props.toggleDisplaySidebar();
							}
						}
					}
				}
			)
		);
	}
	componentDidMount() {
		this.toggleNav('sales');
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	toggleNav(id: string) {
		let reportsMap: any = _.chain(this.injected.reportsStore.list).keyBy('predefinedName').value();
		let navigationGroupsMap: any = _.chain(this.injected.navigationContainerStore.navigationGroups).cloneDeep().keyBy('group').value();
		if (id !== 'all') {
			let navigationGroup = navigationGroupsMap[id];
			if (id === 'sales') {
				let productInWarehouseReport = reportsMap['ProductInWarehouseReport'];
				if (navigationGroup) {
					if (productInWarehouseReport) {
						navigationGroup.children.reports.list.push({
							url: `/reports/${productInWarehouseReport.id}`,
							title: catalog.renderCatalogName(productInWarehouseReport)
						});
					}
				}
			}
			if (navigationGroup) {
				this.setState({
					currentNavGroup: navigationGroup,
					allNavGroups: null
				});
			}
		} else {
			let navigationGroups = _.chain(this.injected.navigationContainerStore.navigationGroups)
				.cloneDeep()
				.forEach((item) => {
					if (item.group === 'sales') {
						let productInWarehouseReport = reportsMap['ProductInWarehouseReport'];
						if (productInWarehouseReport) {
							if (item.children.reports) {
								item.children.reports.list.push({
									url: `/reports/${productInWarehouseReport.id}`,
									title: catalog.renderCatalogName(productInWarehouseReport)
								});
							}
						}
					}
				})
				.value();
			this.setState({
				currentNavGroup: null,
				allNavGroups: navigationGroups
			});
		}

		this.toggleNavStyles(id);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleNavStyles(id: string) {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		_.forEach(tabs, (item) => {
			if (id !== item.id) {
				item.classList.remove('active');
			} else {
				item.classList.add('active');
			}
		});
	}
	render() {
		return (
			<CModal show={this.props.isShown} closeOnBackdrop={false} size="xl" className="nat__nav__menu">
				<CModalHeader className="p-0 nat__nav__menu__header">
					<CButton
						variant="ghost"
						type="button"
						className="ml-2"
						onClick={() => {
							this.props.toggleDisplaySidebar();
						}}>
						<CIcon name="cil-x" size="lg" />
					</CButton>
				</CModalHeader>
				<div className={this.injected.directoryStore.models.windowSize >= 472 ? 'd-flex h-100 overflow-auto nat__scrollbar' : 'd-flex h-100 flex-column overflow-auto nat__scrollbar'}>
					<div className="nat__navigation h-100">
						<CNav variant="tabs" className={this.injected.directoryStore.models.windowSize >= 472 ? 'p-2 ml-4 nat__scrollbar h-100 mh-100' : 'p-2 mx-4 nat__scrollbar h-100 mh-100'} vertical innerRef={this.tabRef}>
							{_.map(this.injected.navigationContainerStore.navigationGroups, (item, index: number) => {
								return (
									<CNavItem className="mb-2" key={index} id={item.group}>
										<a
											className="nav-link cursor__pointer d-flex flex-column"
											id={item.group}
											onClick={(event: any) => {
												event.preventDefault();
												this.toggleNav(item.group);
											}}>
											{item.icon && <CIcon name={item.icon} size="lg" className="mx-auto" />}
											<span>{item.name}</span>
										</a>
									</CNavItem>
								);
							})}
						</CNav>
					</div>

					{(() => {
						if (this.state.currentNavGroup) {
							return (
								<div className={this.injected.directoryStore.models.windowSize >= 472 ? 'd-flex flex-column overflow-auto nat__scrollbar w-100 px-4' : 'd-flex flex-wrap'}>
									{_.map(this.state.currentNavGroup.children, (item, index) => {
										if (!_.isEmpty(item.list)) {
											return (
												<div key={index} className="w-100 d-flex flex-wrap">
													<CListGroup
														className={
															this.injected.directoryStore.models.windowSize >= 472
																? 'nat__nav__menu__body__list__group nat__card__wrapper p-2 w-100'
																: 'nat__nav__menu__body__list__group nat__card__wrapper mt-3 p-2 mx-4 w-100'
														}>
														<CListGroupItem className="border-0 rounded-0">
															<h5 className="text-left m-0">{item.title}</h5>
														</CListGroupItem>
														{_.map(item.list, (item, index) => {
															return (
																<CListGroupItem
																	key={index}
																	className={(() => {
																		if (item) {
																			if (item.url === _.get(this.injected.navigationContainerStore, 'navigationRoute.url', null) && _.get(this.injected.navigationContainerStore, 'navigationRoute.displayed', false)) {
																				return 'text-left border-0 rounded-0 cursor__pointer active';
																			} else {
																				return 'text-left border-0 rounded-0 cursor__pointer';
																			}
																		}
																	})()}
																	onClick={() => {
																		this.props.history.push(item.url);
																		this.props.toggleDisplaySidebar();
																	}}
																	disabled={_.isEmpty(item.url)}>
																	{item.title}
																</CListGroupItem>
															);
														})}
													</CListGroup>
													{this.injected.directoryStore.models.windowSize >= 472 && <CListGroup className="m-2"></CListGroup>}
												</div>
											);
										}
									})}
								</div>
							);
						} else if (this.state.allNavGroups) {
							return (
								<div className={this.injected.directoryStore.models.windowSize >= 472 ? 'd-flex flex-column overflow-auto nat__scrollbar w-100 px-4' : 'd-flex flex-column'}>
									{_.map(this.state.allNavGroups, (item, index: number) => {
										if (item.group !== 'all') {
											return (
												<div className="d-flex flex-column" key={index}>
													<h4
														className={(() => {
															if (this.injected.directoryStore.models.windowSize >= 472) {
																if (index === 1) {
																	return 'mb-0 align-items-center';
																} else {
																	return 'mt-3 mb-0 align-items-center';
																}
															} else {
																return 'mt-3 mb-0 align-items-center';
															}
														})()}>
														{item.name}
													</h4>
													<div className="d-flex flex-wrap">
														{_.map(item.children, (item: any, index: number) => {
															if (!_.isEmpty(item.list)) {
																return (
																	<CListGroup
																		key={index}
																		className={
																			this.injected.directoryStore.models.windowSize >= 472
																				? 'nat__nav__menu__body__list__group nat__card__wrapper p-2 mt-3 w-100'
																				: 'nat__nav__menu__body__list__group nat__card__wrapper mt-3 p-2 mx-4 w-100'
																		}>
																		<CListGroupItem className="border-0 rounded-0">
																			<h4 className="text-left m-0">{item.title}</h4>
																		</CListGroupItem>
																		{_.map(item.list, (item, index) => {
																			return (
																				<CListGroupItem
																					key={index}
																					className={(() => {
																						if (item) {
																							if (
																								item.url === _.get(this.injected.navigationContainerStore, 'navigationRoute.url', null) &&
																								_.get(this.injected.navigationContainerStore, 'navigationRoute.displayed', false)
																							) {
																								return 'text-left border-0 rounded-0 cursor__pointer active';
																							} else {
																								return 'text-left border-0 rounded-0 cursor__pointer';
																							}
																						}
																					})()}
																					onClick={() => {
																						this.props.history.push(item.url);
																						this.props.toggleDisplaySidebar();
																					}}
																					disabled={_.isEmpty(item.url)}>
																					{item.title}
																				</CListGroupItem>
																			);
																		})}
																	</CListGroup>
																);
															}
														})}
													</div>
												</div>
											);
										}
									})}
								</div>
							);
						}
					})()}
				</div>
			</CModal>
		);
	}
}

export default withRouter(NavMenu);
