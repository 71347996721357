import _ from 'lodash';
import { DateTime } from 'luxon';

import ValueFormatter from './ValueFormatter';

class DateTimeFormatter extends ValueFormatter<Date, string> {
	public parseValue(value: string): Date | undefined | null {
		let result: Date | undefined | null = undefined;
		let dateTime = DateTime.fromFormat(value, `yyyy-LL-dd'${'T'}'TT`);
		if (dateTime.isValid) {
			result = dateTime.toJSDate();
		} else {
			result = null;
		}
		return result;
	}

	public renderValue(value: Date): string {
		if (value !== undefined && value !== null) {
			if (_.isString(value)) {
				value = new Date(value);
			}
			return DateTime.fromJSDate(value).toFormat(`yyyy-LL-dd'${'T'}'TT`);
		}
		return '';
	}
}

export let dateTimeFormatter = new DateTimeFormatter();

export default DateTimeFormatter;
