import { observable, action, makeObservable, set } from 'mobx';

export interface OnlineStoreSettingsStoreType {
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterWhere: any;
	selectedItems: Array<any>;
	formRows: Array<any>;
	isFirstLoad: boolean;
	filterInclude: Array<any>;
	setValue(model: any, key: string, value: any): void;
}

class OnlineStoreSettingsStore implements OnlineStoreSettingsStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterWhere: observable,
			selectedItems: observable,
			formRows: observable,
			isFirstLoad: observable,
			filterInclude: observable,
			setValue: action
		});
	}
	list = [] as Array<any>;
	formRows = [] as Array<any>;
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'constants';
	filterInclude = ['value'];
	filterWhere = {
		deleted: false,
		predefinedName: {
			inq: [
				'DefaultOrganization',
				'DefaultWarehouse',
				'DefaultStore',
				'DefaultPriceType',
				'DefaultProductSegment',
				'DefaultLoyaltyCardKind',
				'DefaultPaymentOption',
				'DefaultCashBox',
				'AllowAnonymousUser',
				'AllowClientProductInStock',
				'AllowOnlyProductInStock',
				'AllowPersonalAccount',
				'PersonalAccountReplenishProduct',
				'AllowSubscriptionPlan'
			]
		}
	};
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const onlineStoreSettingsStore = new OnlineStoreSettingsStore();

export default OnlineStoreSettingsStore;
