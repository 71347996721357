import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { LoyaltyCardKindFormStoreType } from './LoyaltyCardKindFormStore';

interface PropsType extends FormPropsType {
	loyaltyCardKindFormStore: LoyaltyCardKindFormStoreType;
	childrenProps: ChildrenPropsType;
	saveRuleItems(): void;
	buildRuleItems(): void;
}

interface StateType {
	modals: Array<number>;
	isLoyaltyPolicyRangeManagerOpen: boolean;
	isLoyaltyPolicyRangeByProductManagerOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class LoyaltyCardKindForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.toggleNatLoyaltyPolicyRangeManager = this.toggleNatLoyaltyPolicyRangeManager.bind(this);
		this.toggleNatLoyaltyPolicyRangeByProductManager = this.toggleNatLoyaltyPolicyRangeByProductManager.bind(this);
		this.state = {
			modals: [],
			isLoyaltyPolicyRangeManagerOpen: false,
			isLoyaltyPolicyRangeByProductManagerOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleNatSelection(index: number) {
		const position = this.state.modals.indexOf(index);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			modals: newDetails
		});
		if (!this.state.modals.includes(index)) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	toggleNatLoyaltyPolicyRangeManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeManagerOpen: status
		});
	}
	toggleNatLoyaltyPolicyRangeByProductManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeByProductManagerOpen: status
		});
	}
	render() {
		let numberTemplate: any = {
			id: '',
			numberLength: 0,
			numberStartRange: 0,
			numberEndRange: 0
		};
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.loyaltyCardKindFormStore}
				navTabs={[{ name: 'Шаблоны нумерации' }, { name: 'Дополнительно' }, { name: 'Комментарий' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(numberTemplate, 'numberTemplates');
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.loyaltyCardKindFormStore.selectedItems, 'numberTemplates');
										},
										withoutIcon: true,
										disabled: this.props.loyaltyCardKindFormStore.selectedItems.length === 0
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.loyaltyCardKindFormStore.selectedItems, this.props.loyaltyCardKindFormStore.model.numberTemplates, -1);
										},
										withoutIcon: true,
										disabled: this.props.loyaltyCardKindFormStore.selectedItems.length === 0
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.loyaltyCardKindFormStore.selectedItems, this.props.loyaltyCardKindFormStore.model.numberTemplates, 1);
										},
										withoutIcon: true,
										disabled: this.props.loyaltyCardKindFormStore.selectedItems.length === 0
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.loyaltyCardKindFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods([
											{
												id: 'checkbox',
												pinned: true,
												className: '',
												width: '54px',
												minResizeWidth: 0,
												maxResizeWidth: null,
												resizable: false,
												visible: true,
												headerCellRenderer: () => {
													return <div></div>;
												},
												cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
													return (
														<div className="rgt-cell-inner ml-0 mr-0">
															<input
																type="checkbox"
																id="checkbox"
																onChange={() => {
																	this.props.childrenProps.saveSelected(data);
																}}
															/>
														</div>
													);
												}
											},
											{
												id: 1,
												field: 'numberLength',
												label: 'Длина',
												width: '250px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatValueInput<number>
																object={data}
																property="numberLength"
																placeholder="Введите длину"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																inputClassName="text-right"
																formatter={numberFormatter}
																autoComplete="off"
																verticalSwitching
																size="sm"
															/>
														</div>
													);
												}
											},
											{
												id: 2,
												field: 'numberStartRange',
												label: 'Начальный диапазон',
												width: '250px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatValueInput<number>
																object={data}
																property="numberStartRange"
																placeholder="Введите диапазон"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																inputClassName="text-right"
																formatter={numberFormatter}
																autoComplete="off"
																verticalSwitching
																size="sm"
															/>
														</div>
													);
												}
											},
											{
												id: 3,
												field: 'numberEndRange',
												label: 'Конечный диапазон',
												width: '250px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatValueInput<number>
																object={data}
																property="numberEndRange"
																placeholder="Введите диапазон"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																inputClassName="text-right"
																formatter={numberFormatter}
																autoComplete="off"
																verticalSwitching
																size="sm"
															/>
														</div>
													);
												}
											}
										])}
										rows={this.props.loyaltyCardKindFormStore.model.numberTemplates}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										searchText={this.props.loyaltyCardKindFormStore.searchText}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										showSearch={false}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.loyaltyCardKindFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.loyaltyCardKindFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.loyaltyCardKindFormStore.model}
										property="note"
										label=""
										placeholder="Введите комментарий"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.loyaltyCardKindFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.loyaltyCardKindFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.loyaltyCardKindFormStore.model}
										property="loyaltyProgramId"
										relation="loyaltyProgram"
										placeholder="Выберите программу"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="loyaltyPrograms"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Программа лояльности"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.loyaltyCardKindFormStore.model}
										property="statusId"
										relation="status"
										placeholder="Выберите статус"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'loyalty_card_kind_statuses' }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.loyaltyCardKindFormStore.model.statusId)}
										invalidFeedback="Элемент не выбран"
										label="Статус"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.loyaltyCardKindFormStore.model}
										property="startedAt"
										placeholder="Введите дату"
										type="date"
										formatter={dateFormatter}
										size="sm"
										label="Начало действия"
										invalid={!this.props.loyaltyCardKindFormStore.model.startedAt}
										invalidFeedback="Строка не заполнена"
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<Date> object={this.props.loyaltyCardKindFormStore.model} property="expiredAt" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Окончание действия" />
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatCheckbox object={this.props.loyaltyCardKindFormStore.model} property="personalized" label="Персонализированный" />
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.loyaltyCardKindFormStore.setValue(this.props.loyaltyCardKindFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.loyaltyCardKindFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.loyaltyCardKindFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.loyaltyCardKindFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(LoyaltyCardKindForm));
