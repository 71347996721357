import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CTooltip } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';

interface PropsType extends RouteComponentProps {
	buttonItemList?: Array<ButtonItem>;
	iconClassname?: string;
	className?: string;
	inputList?: Array<any>;
	customControlItems?: Array<any>;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	icon?: any;
	withoutSrc?: boolean;
	withoutIcon?: boolean;
	hidden?: boolean;
	children?: Array<ButtonItem>;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatControlPanel extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<div className={_.isEmpty(this.props.className) ? 'nat__table__control__buttons nat__card__wrapper nat__borders__2-75 p-2 mx-4' : this.props.className}>
				<div className="d-flex">
					{(() => {
						if (!_.isEmpty(this.props.buttonItemList)) {
							return _.map(this.props.buttonItemList, (value: ButtonItem, index: number) => {
								if (_.isEmpty(value.children)) {
									if (!value.hidden) {
										if (!value.withoutIcon) {
											return (
												<CTooltip
													key={index}
													content={value.title}
													advancedOptions={{
														onShow: () => {
															if (this.injected.directoryStore.models.windowSize >= 1235) {
																return true;
															} else {
																return false;
															}
														}
													}}>
													<CButton variant="ghost" className="d-flex" onClick={value.onClick} disabled={value.disabled}>
														<CIcon src={!value.withoutSrc ? value.icon : ''} name={value.withoutSrc && value.icon} size="sm" className={this.props.iconClassname} />
													</CButton>
												</CTooltip>
											);
										} else {
											return (
												<CButton key={index} variant="ghost" className="d-flex nat__button__hover mr-1" onClick={value.onClick} disabled={value.disabled}>
													{value.title}
												</CButton>
											);
										}
									}
								} else {
									return (
										<CDropdown key={index}>
											<CDropdownToggle className="mr-1 nat__button__hover" disabled={value.disabled}>
												{value.title}
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(value.children, (item, index) => {
													return (
														<CDropdownItem
															key={index}
															onClick={(e) => {
																item.onClick(e);
															}}
															disabled={item.disabled}>
															{item.title}
														</CDropdownItem>
													);
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							});
						} else if (!_.isEmpty(this.props.customControlItems)) {
							return _.map(this.props.customControlItems, (item: any, index: number) => {
								return <div key={index}>{item}</div>;
							});
						}
					})()}
					<CForm className="ml-auto">
						{this.props.inputList &&
							_.map(this.props.inputList, (value: any) => {
								return value;
							})}
					</CForm>
				</div>
			</div>
		);
	}
}

export default withRouter(NatControlPanel);
