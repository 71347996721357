import CIcon from '@coreui/icons-react';
import { CButton, CCardHeader, CCollapse, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CNav, CNavItem, CRow, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../Alerts/AlertDialog';
import NatAclMapping from '../Modals/NatAclMapping';
import NatRelatedDocuments from '../Modals/NatRelatedDocuments';
import NatSetEditingManager from '../Modals/NatSetEditingManager';
import ProductSelection from '../Modals/ProductSelection';
import { catalog } from '../support/Catalog';

// import NatAclMapping from '../../shared/Components/NatAclMapping';

interface PropsType {
	fields: ReactElement;
	tablePart?: ReactElement;
	store: any;
	makeReactions?: () => void;
	productItemTemplate?: any;
	selectionColumns?: any;
	setManagerColumns?: any;
	productSelection?: boolean;
	productSetEditing?: boolean;
	alert?: ReactElement;
	classNameRow?: string;
	toggleSelection?: (status: boolean) => void;
	toggleSetEditing?: (status: boolean) => void;
	isProductSelectionModalOpen?: boolean;
	isProductSetEditingModalOpen?: boolean;
	controlButtons: Array<ButtonItem>;
	saveProductItems?: (productItems: Array<any>) => void;
	isFormReady?: boolean;
	toggleAlert?: (status: boolean) => void;
	isAlertOpen?: boolean;
	dontDisplayStocksAndPrices?: boolean;
	notRecalculateAmount?: boolean;
	navTabs?: any;
	tabsContent?: (toggleSelection: (status: boolean) => void) => Array<any>;
	isAclModalOpen: boolean;
	toggleAclModal(status: boolean): void;
	isRelatedDocumentsModalOpen?: boolean;
	toggleNatRelatedDocumentsModal?: (status: boolean) => void;
	isRevealCompositionOfSet?: boolean;
	typeFilter?: { nin?: Array<string>; inq?: Array<string> };
	withoutPrices?: boolean;
	dontDisplayFeatures?: boolean;
	formValidProductTypes?: boolean;
	toggleSequenceNumberChangingAlert?: (status: boolean) => void;
	isSequenceNumberChangingModalOpen?: boolean;
}

interface StateType {
	isProductSelectionModalOpen: boolean;
	isProductSetEditingModalOpen: boolean;
	isHeaderShow: boolean;
	tabs: Array<number>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('directoryStore', 'userStore')
@observer
class NatHeaderCollapseFormTemplate extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	tabRef: any;
	tableRef: any;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.state = {
			isProductSelectionModalOpen: false,
			isProductSetEditingModalOpen: false,
			isHeaderShow: true,
			tabs: [0]
		};
		this.tabRef = React.createRef();
		this.tableRef = React.createRef();
		this.toggleSelection = this.toggleSelection.bind(this);
		this.toggleHeader = this.toggleHeader.bind(this);
		this.toggleTabs = this.toggleTabs.bind(this);
		this.activateDefaultTab = this.activateDefaultTab.bind(this);
		this.toggleSetEditing = this.toggleSetEditing.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.activateDefaultTab();
	}
	componentDidUpdate(prevProps: PropsType) {
		if (this.props.navTabs.length !== prevProps.navTabs.length && this.props.navTabs.length !== 0) {
			this.activateDefaultTab();
		}
	}
	toggleSelection(status: boolean) {
		if (this.props.toggleSelection === undefined) {
			this.setState({
				isProductSelectionModalOpen: status
			});
			if (status) {
				catalog.disablePageScroll();
			} else {
				catalog.enablePageScroll();
			}
		} else {
			this.props.toggleSelection(status);
		}
	}
	toggleSetEditing(status: boolean) {
		if (this.props.toggleSetEditing === undefined) {
			this.setState({
				isProductSetEditingModalOpen: status
			});
			if (status) {
				catalog.disablePageScroll();
			} else {
				catalog.enablePageScroll();
			}
		} else {
			this.props.toggleSetEditing(status);
		}
	}
	toggleHeader(status: boolean) {
		this.setState({
			isHeaderShow: status
		});
	}
	toggleTabs(id: string) {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		_.forEach(tabs, (item, index) => {
			if (id !== item.id) {
				item.classList.remove('active');
				tabPanes[index].classList.remove('nat__table__wrapper');
			} else {
				item.classList.add('active');
				tabPanes[index].classList.add('nat__table__wrapper');
			}
		});
	}
	activateDefaultTab() {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		if (!_.isEmpty(tabPanes[0])) {
			tabPanes[0].classList.add('nat__table__wrapper');
		}
		if (!_.isEmpty(tabs[0])) {
			tabs[0].classList.add('active');
		}
	}
	render() {
		let isProductSelectionModalOpen: boolean = false;
		if (this.props.isProductSelectionModalOpen === undefined) {
			isProductSelectionModalOpen = this.state.isProductSelectionModalOpen;
		} else {
			isProductSelectionModalOpen = this.props.isProductSelectionModalOpen;
		}
		let isProductSetEditingModalOpen: boolean = false;
		if (this.props.isProductSetEditingModalOpen === undefined) {
			isProductSetEditingModalOpen = this.state.isProductSetEditingModalOpen;
		} else {
			isProductSetEditingModalOpen = this.props.isProductSetEditingModalOpen;
		}
		return (
			<div
				className={
					isProductSelectionModalOpen || isProductSetEditingModalOpen
						? 'nat__form nat__borders__2-75 default__nat__form__body__height disableOverflowY nat__scrollbar'
						: 'nat__form nat__borders__2-75 default__nat__form__body__height nat__scrollbar'
				}
				ref={this.tabRef}>
				<div className="nat__table__wrapper nat__table__hover">
					<CCardHeader className="nat__form__header">
						<CCardHeader className="d-flex align-items-center nat__form__control__buttons nat__card__wrapper nat__borders__2-75 p-2 mb-3 mx-4 mt-4">
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex">
											{_.map(this.props.controlButtons, (item, index) => {
												if (!item.dontShow) {
													if (!item.children) {
														return (
															<CButton
																key={index}
																type="button"
																size="sm"
																className={item.className ? item.className : 'mr-1 nat__button__hover'}
																onClick={(e) => {
																	item.onClick(e);
																}}
																disabled={item.disabled}>
																{item.title}
															</CButton>
														);
													} else {
														return (
															<CDropdown key={index}>
																<CDropdownToggle className="mr-1 nat__button__hover" disabled={item.disabled}>
																	{item.title}
																</CDropdownToggle>
																<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
																	{_.map(item.children, (item, index) => {
																		return (
																			<CDropdownItem
																				key={index}
																				onClick={(e) => {
																					item.onClick(e);
																				}}
																				disabled={item.disabled}>
																				{item.title}
																			</CDropdownItem>
																		);
																	})}
																</CDropdownMenu>
															</CDropdown>
														);
													}
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(this.props.controlButtons, (item, index) => {
													if (!item.dontShow) {
														if (!item.children) {
															return (
																<CDropdownItem
																	key={index}
																	onClick={(e) => {
																		item.onClick(e);
																	}}
																	disabled={item.disabled}>
																	{item.title}
																</CDropdownItem>
															);
														} else {
															return (
																<div key={index}>
																	<CDropdownItem header>{item.title}</CDropdownItem>
																	{_.map(item.children, (item, index) => {
																		return (
																			<CDropdownItem
																				key={index}
																				onClick={(e) => {
																					item.onClick(e);
																				}}
																				disabled={item.disabled}>
																				{item.title}
																			</CDropdownItem>
																		);
																	})}
																</div>
															);
														}
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
							<CButton
								variant="ghost"
								onClick={() => {
									if (!this.state.isHeaderShow) {
										this.toggleHeader(true);
									} else {
										this.toggleHeader(false);
									}
								}}
								className="ml-auto">
								<CIcon name={!this.state.isHeaderShow ? 'cil-plus' : 'cil-minus'} />
							</CButton>
						</CCardHeader>
					</CCardHeader>
					<div className="nat__table__wrapper nat__table__wrapper__overflow nat__scrollbar">
						<CCollapse show={this.state.isHeaderShow} className={this.state.isHeaderShow ? 'd-flex flex-column' : ''}>
							<CForm className="mx-5 mb-3">
								<CRow className={this.props.classNameRow !== undefined ? this.props.classNameRow : ''}>{this.props.fields}</CRow>
							</CForm>
						</CCollapse>
						{!_.isEmpty(this.props.navTabs) && (
							<>
								<div
									className={(() => {
										if (this.injected.directoryStore.models.windowSize > 1084) {
											if (this.props.navTabs.length < 3 && this.props.navTabs.length > 1) {
												return 'p-0 nat__form__tabs nat__form__tabs-2';
											} else if (this.props.navTabs.length <= 1) {
												return 'p-0 nat__form__tabs nat__form__tabs-1';
											} else {
												return 'p-0 nat__form__tabs';
											}
										} else {
											return 'p-0 nat__form__tabs nat__form__tabs__mobile';
										}
									})()}>
									<CNav variant="tabs" className="p-2 mb-3 mx-4">
										{this.props.navTabs !== undefined &&
											_.map(this.props.navTabs, (item, index: number) => {
												if (!item.hidden) {
													return (
														<CNavItem className="mr-1" key={index}>
															<a
																className="nav-link cursor__pointer"
																id={item.name}
																onClick={(event) => {
																	event.preventDefault();
																	this.toggleTabs(item.name);
																}}>
																{item.name}
															</a>
														</CNavItem>
													);
												}
											})}
									</CNav>
								</div>
								{this.props.tabsContent !== undefined &&
									_.map(this.props.tabsContent(this.toggleSelection), (item, index) => {
										if (!this.props.navTabs[index].hidden) {
											return (
												<div key={index} className="nat__tab" id={this.props.navTabs[index].name}>
													{item}
												</div>
											);
										}
									})}
							</>
						)}
					</div>

					{this.props.store.isLoading && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
					{(() => {
						if (this.props.productSelection) {
							if (isProductSelectionModalOpen) {
								return (
									<ProductSelection
										isRevealCompositionOfSet={this.props.isRevealCompositionOfSet}
										toggleFeedSelection={this.toggleSelection}
										isModalOpen={isProductSelectionModalOpen}
										productItemsColumns={this.props.selectionColumns}
										modelStore={this.props.store}
										makeReactions={this.props.makeReactions}
										productItemTemplate={this.props.productItemTemplate}
										saveProductItems={this.props.saveProductItems}
										notRecalculateAmount={this.props.notRecalculateAmount}
										dontDisplayStocksAndPrices={this.props.dontDisplayStocksAndPrices}
										typeFilter={this.props.typeFilter}
										dontDisplayFeatures={this.props.dontDisplayFeatures}
										formValidProductTypes={this.props.formValidProductTypes}
									/>
								);
							}
						}
					})()}
					{(() => {
						if (this.props.productSetEditing) {
							if (isProductSetEditingModalOpen) {
								return (
									<NatSetEditingManager
										productItemTemplate={this.props.productItemTemplate}
										productItemsColumns={this.props.setManagerColumns}
										isModalOpen={isProductSetEditingModalOpen}
										modelStore={this.props.store}
										toggleNatSetEditingManager={this.toggleSetEditing}
										productItem={this.props.store.productItem}
										withoutPrices={this.props.withoutPrices}
										notRecalculateAmount={this.props.notRecalculateAmount}
										dontDisplayStocksAndPrices={this.props.dontDisplayStocksAndPrices}
										makeReactions={this.props.makeReactions}
										typeFilter={this.props.typeFilter}
										formValidProductTypes={this.props.formValidProductTypes}
									/>
								);
							}
						}
					})()}
					{this.props.alert !== undefined && this.props.alert}
					{this.props.isAlertOpen && (
						<AlertDialog
							isModalOpen={this.props.isAlertOpen}
							title="Пересчитать цены в таблице?"
							buttonItemList={[
								{
									title: 'ДА',
									onClick: () => {
										this.props.store.setValue(this.props.store, 'isLoading', true);
										let productItems = _.get(this.props.store.model, 'productItems', []);
										if (!_.isEmpty(productItems)) {
											this.props.store.setValue(this.props.store, 'isLoading', true);
											controller
												.updateProductItemListPrices(productItems, this.props.store.model.priceType)
												.then((data) => {
													if (!_.isEmpty(data)) {
														this.props.store.setValue(this.props.store.model, 'productItems', data);
														catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
														this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
														if (this.props.makeReactions) {
															this.props.makeReactions();
														}
													}
													this.props.store.setValue(this.props.store, 'isLoading', false);
												})
												.catch((error) => {
													catalog.handleNatError(error);
													this.props.store.setValue(this.props.store, 'isLoading', false);
												});
										} else {
											this.props.store.setValue(this.props.store, 'isLoading', false);
										}
										if (this.props.toggleAlert) {
											this.props.toggleAlert(false);
										}
									}
								},
								{
									title: 'НЕТ',
									onClick: () => {
										if (this.props.toggleAlert) {
											this.props.toggleAlert(false);
										}
									}
								}
							]}
						/>
					)}
					{this.props.isSequenceNumberChangingModalOpen && (
						<AlertDialog
							isModalOpen={this.props.isSequenceNumberChangingModalOpen}
							title="Номер документа формируется автоматически, вы действительно хотите изменить его вручную?"
							buttonItemList={[
								{
									title: 'ДА',
									onClick: () => {
										this.props.store.setValue(this.props.store, 'isSequenceNumberChangedManually', true);
										if (this.props.toggleSequenceNumberChangingAlert) {
											this.props.toggleSequenceNumberChangingAlert(false);
										}
									}
								},
								{
									title: 'НЕТ',
									onClick: () => {
										if (this.props.toggleSequenceNumberChangingAlert) {
											this.props.toggleSequenceNumberChangingAlert(false);
										}
									}
								}
							]}
						/>
					)}
					{this.props.isAclModalOpen && <NatAclMapping isModalOpen={this.props.isAclModalOpen} toggleNatAclMapping={this.props.toggleAclModal} subject={[this.props.store.model]} model={this.props.store.name} />}
					{this.props.isRelatedDocumentsModalOpen && <NatRelatedDocuments isModalOpen={this.props.isRelatedDocumentsModalOpen} toggleNatRelatedDocuments={this.props.toggleNatRelatedDocumentsModal} store={this.props.store} />}
				</div>
			</div>
		);
	}
}

export default NatHeaderCollapseFormTemplate;
