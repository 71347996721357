import { observable, action, makeObservable, set } from 'mobx';

export interface ReportStoreType {
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	reportPresets: Array<any>;
	filters: Array<any>;
	report: any;
	reportColumns: Array<any>;
	isFirstLoad: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class ReportStore implements ReportStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			reportPresets: observable,
			filters: observable,
			report: observable,
			reportColumns: observable,
			isFirstLoad: observable,
			searchText: observable,
			setValue: action
		});
	}
	searchText = '';
	reportColumns = [] as Array<any>;
	list = [] as Array<any>;
	filters = [] as Array<any>;
	report = {};
	reportPresets = [] as Array<any>;
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'reports';
	filterInclude = ['samplingScheme'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const reportStore = new ReportStore();

export default ReportStore;
