import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import NatSelection from '../../shared/Modals/NatSelection';
import { NatSelectionStoreType } from '../../shared/Modals/NatSelectionStore';
import { catalog } from '../../shared/support/Catalog';
import { models } from '../../shared/support/NatModels';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { SettingPricesOfProductFormStoreType } from './SettingPricesOfProductFormStore';

interface PropsType extends FormPropsType {
	settingPricesOfProductFormStore: SettingPricesOfProductFormStoreType;
	childrenProps: ChildrenPropsType;
	saveSelected(priceTypeItem: any): void;
	deletePriceTypeItems(selectedItems: Array<any>): void;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
	buildPriceTypeItems(arrayIds: Array<string>): void;
	savePriceTypeItems(): void;
	saveProductItems(productItems: Array<any>): void;
	deleteProductItems(selectedItems: Array<any>): void;
	buildProductItemsTable(productItems: Array<any>, priceTypeItems: Array<any>): void;
	buildProductItemsFeaturesTable(item: any, priceTypeItems: Array<any>): void;
}

interface StateType {
	columns: Array<any>;
	priceTypeColumns: Array<any>;
	isModalOpen: boolean;
	activeTab: string;
	modals: Array<number>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	natSelectionStore: NatSelectionStoreType;
}

@inject('directoryStore', 'userStore', 'natSelectionStore')
@observer
class SettingPricesOfProductForm extends Component<PropsType, StateType> {
	tableRef: any;
	constructor(props: PropsType) {
		super(props);
		this.tableRef = React.createRef();
		this.toggleSelection = this.toggleSelection.bind(this);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.state = {
			isModalOpen: false,
			activeTab: 'priceTypes',
			modals: [],
			priceTypeColumns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input type="checkbox" id="checkbox" onChange={() => this.props.saveSelected(data)} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'priceType',
					label: 'Тип цены',
					width: '450px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="priceTypeId"
									relation="priceType"
									placeholder="Выберите тип цены"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="priceTypes"
									filterWhere={{ deleted: false }}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				}
			],
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input type="checkbox" id="checkbox" onChange={() => this.props.childrenProps.saveSelected(data)} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productId"
									relation="product"
									placeholder="Выберите товар"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="products"
									filterWhere={{
										deleted: false,
										elementType: 0,
										typeId: {
											nin: ['product_types.set']
										}
									}}
									include={['unit']}
									autoComplete="off"
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'validityPeriod',
					label: 'Период действия',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
											return (
												<NatRelationInput
													object={data}
													property="validityPeriodId"
													relation="validityPeriod"
													placeholder="Выберите период"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'periodicity' }}
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
													autoComplete="off"
												/>
											);
										} else {
											return null;
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				}
			]
		};
	}
	componentDidMount() {
		if (!_.isEmpty(this.tableRef.current)) {
			this.tableRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
			this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'tableQuerySelector', this.tableRef.current.querySelector('.rgt-container'));
		}
	}
	componentWillUnmount() {
		if (!_.isEmpty(this.tableRef.current)) {
			this.tableRef.current.querySelector('.rgt-container')?.removeEventListener('scroll', this.handleScrollToElement);
		}
		this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'tableQuerySelector', null);
	}
	handleScrollToElement() {
		let scrollTop = this.props.settingPricesOfProductFormStore.tableQuerySelector.scrollTop;
		if (this.props.settingPricesOfProductFormStore.isRoot) {
			this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'scrollbarPosition', scrollTop);
		}
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleSelection(status: boolean) {
		this.setState({
			isModalOpen: status
		});
		if (status) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	toggleNatSelection(index: number) {
		const position = this.state.modals.indexOf(index);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			modals: newDetails
		});
		if (!this.state.modals.includes(index)) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	render() {
		let priceTypeItemTemplate = {
			priceTypeId: null,
			priceType: {
				basePriceTypeId: null,
				deleted: false,
				discountMarkupPercent: null,
				displayName: null,
				name: { ru: '' },
				pricingOptionId: 'pricing_options.manual',
				priority: null,
				roundOff: false,
				roundingAccuracy: 0.01,
				roundingOptionId: 'rounding_options.by_arithmetic_rules',
				roundingPsychological: 0,
				statusId: 'price_type_statuses.valid',
				useWhenMakeBasedOn: false,
				useWhenSellingToCustomers: true
			},
			id: ''
		};
		let productItemTemplate = {
			featureId: null,
			id: '',
			price: 0,
			productId: null,
			priceTypeId: null,
			unitId: null,
			validityPeriodId: 'periodicity.month',
			validityPeriod: {
				id: 'periodicity.month',
				keyId: 'month',
				ownerId: 'periodicity',
				name: { ru: 'Месяц' },
				description: null,
				priority: 6,
				predefined: true,
				predefinedName: 'PeriodicityMonth',
				predefinedVersion: 0
			},
			feature: {
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			},
			product: {
				accountingOptionId: 'product_accounting_options.standart',
				area: 0,
				areaUnitId: null,
				article: null,
				caption: null,
				deleted: false,
				description: null,
				displayName: null,
				elementType: 0,
				giftCardValidityExpiredAt: null,
				giftCardValidityPeriodCount: 0,
				giftCardValidityPeriodId: 'periodicity.year',
				giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
				giftCardValue: 0,
				height: 0,
				heightUnitId: null,
				kindId: null,
				labelTemplateId: null,
				length: 0,
				lengthUnitId: null,
				name: { ru: '' },
				organizationId: null,
				packageSetId: null,
				parentId: null,
				primaryImageId: null,
				priority: null,
				supplierId: null,
				typeId: 'product_types.goods',
				unitId: null,
				useArea: false,
				useCustomLabelTemplate: false,
				useFeatureOptionId: 'use_feature_options.not_used',
				useGiftCardPartially: false,
				useHeight: false,
				useLength: false,
				usePackageOptionId: 'use_package_options.not_used',
				useSerialNumbers: false,
				useVolume: false,
				useWeight: false,
				useWidth: false,
				volume: 0,
				volumeUnitId: null,
				weight: 0,
				weightCanManuallySet: false,
				weightUnitId: null,
				width: 0,
				widthUnitId: null
			},
			unit: {
				baseUnitId: null,
				deleted: false,
				displayName: null,
				elementType: 0,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				packageTypeId: 'unit_package_types.final',
				parentId: null,
				priority: null,
				quantityOfOwnerUnitIncludedInPackage: 0,
				quantityOfPackageIncludedInOwnerUnit: 1,
				quantityOfParentPackageIncludedInPackage: 0,
				typeId: null
			},
			priceType: {
				basePriceTypeId: null,
				deleted: false,
				discountMarkupPercent: null,
				displayName: null,
				name: { ru: '' },
				pricingOptionId: 'pricing_options.manual',
				priority: null,
				roundOff: false,
				roundingAccuracy: 0.01,
				roundingOptionId: 'rounding_options.by_arithmetic_rules',
				roundingPsychological: 0,
				statusId: 'price_type_statuses.valid',
				useWhenMakeBasedOn: false,
				useWhenSellingToCustomers: true
			}
		};
		return (
			<NatHeaderCollapseFormTemplate
				// makeReactions={this.props.makeReactions}
				store={this.props.settingPricesOfProductFormStore}
				productSelection
				toggleSelection={this.toggleSelection}
				isProductSelectionModalOpen={this.state.isModalOpen}
				saveProductItems={this.props.saveProductItems}
				dontDisplayStocksAndPrices
				notRecalculateAmount
				productItemTemplate={productItemTemplate}
				selectionColumns={this.state.columns}
				navTabs={[{ name: 'Типы цен' }, { name: 'Товары' }, { name: 'Примечание' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				dontDisplayFeatures
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											let arr: any = _.cloneDeep(this.props.settingPricesOfProductFormStore.model.priceTypeItems);
											let priceTypeItem: any = _.cloneDeep(priceTypeItemTemplate);
											priceTypeItem.priceTypeId = generateRandomId() + '-null';
											priceTypeItem.id = generateRandomId();
											arr.push(priceTypeItem);
											this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore.model, 'priceTypeItems', arr);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.deletePriceTypeItems(this.props.settingPricesOfProductFormStore.selectedPriceTypeItems);
										},
										withoutIcon: true,
										disabled: this.props.settingPricesOfProductFormStore.selectedPriceTypeItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											this.toggleNatSelection(1);
										},
										withoutIcon: true,
										disabled: false
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.settingPricesOfProductFormStore} property="searchPriceTypeText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods(this.state.priceTypeColumns)}
										rows={this.props.settingPricesOfProductFormStore.model.priceTypeItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										showSearch={false}
										searchText={this.props.settingPricesOfProductFormStore.searchPriceTypeText}
									/>
								</form>
							</div>
							{this.state.modals.includes(1) && (
								<NatSelection
									filterWhere={{ deleted: false }}
									filterOrder={['createdAt DESC']}
									toggleNatSelection={this.toggleNatSelection}
									buildSelectedItems={this.props.buildPriceTypeItems}
									saveSelectedItems={this.props.savePriceTypeItems}
									tabNumber={1}
									modals={this.state.modals}
									filterInclude={[]}
									hierarchical={false}
									pluralName="priceTypes"
									property="priceTypeId"
								/>
							)}
						</>,
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											let arr: any = _.cloneDeep(this.props.settingPricesOfProductFormStore.model.productItems);
											let productItem: any = _.cloneDeep(productItemTemplate);
											productItem.productId = generateRandomId() + '-null';
											productItem.featureId = generateRandomId() + '-null';
											_.forEach(this.props.settingPricesOfProductFormStore.model.priceTypeItems, (item) => {
												let copyTemplate = _.cloneDeep(productItem);
												copyTemplate.id = generateRandomId();
												copyTemplate.priceTypeId = item.priceTypeId;
												copyTemplate.priceType = item.priceType;
												if (!this.props.settingPricesOfProductFormStore.isRoot) {
													copyTemplate.productId = this.props.settingPricesOfProductFormStore.parentProductItem.productId;
													copyTemplate.product = this.props.settingPricesOfProductFormStore.parentProductItem.product;
													copyTemplate.unitId = this.props.settingPricesOfProductFormStore.parentProductItem.unitId;
													copyTemplate.price = this.props.settingPricesOfProductFormStore.parentProductItem[item.priceTypeId].price;
													if (!_.isEmpty(this.props.settingPricesOfProductFormStore.parentProductItem.unit)) {
														copyTemplate.unit = this.props.settingPricesOfProductFormStore.parentProductItem.unit;
													}
												} else {
													copyTemplate.featureId = null;
												}
												arr.push(copyTemplate);
											});
											this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore.model, 'productItems', arr);
											this.props.makeReactions();
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.deleteProductItems(this.props.settingPricesOfProductFormStore.selectedItems);
										},
										withoutIcon: true,
										disabled: this.props.settingPricesOfProductFormStore.selectedItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											this.toggleSelection(true);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: !_.isEmpty(this.props.settingPricesOfProductFormStore.parentProductItem) ? `К ТОВАРАМ (${catalog.renderCatalogName(this.props.settingPricesOfProductFormStore.parentProductItem.product)})` : 'К ТОВАРАМ',
										onClick: () => {
											this.props.buildProductItemsTable(_.get(this.props.settingPricesOfProductFormStore.model, 'productItems', []), _.get(this.props.settingPricesOfProductFormStore.model, 'priceTypeItems', []));
											this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'isRoot', true);
											this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'parentProductItem', null);
											this.props.settingPricesOfProductFormStore.tableQuerySelector.scrollTo(0, this.props.settingPricesOfProductFormStore.scrollbarPosition);
										},
										withoutIcon: true,
										disabled: false,
										hidden: this.props.settingPricesOfProductFormStore.isRoot
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.settingPricesOfProductFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table" ref={this.tableRef}>
									<GridTable
										columns={catalog.checkColumnVisibility(catalog.convertCellMethods(this.props.settingPricesOfProductFormStore.productItemsColumns), this.props.settingPricesOfProductFormStore.productItemsTable)}
										rows={this.props.settingPricesOfProductFormStore.productItemsTable}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										highlightSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										showSearch={false}
										searchText={this.props.settingPricesOfProductFormStore.searchText}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.settingPricesOfProductFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date>
										object={this.props.settingPricesOfProductFormStore.model}
										property="periodAt"
										placeholder="Введите дату"
										type="datetime-local"
										step="1"
										formatter={dateTimeFormatter}
										size="sm"
										label="Дата документа"
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.settingPricesOfProductFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.settingPricesOfProductFormStore.model.organizationId)}
										invalidFeedback="Элемент не выбран"
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.settingPricesOfProductFormStore.model}
										property="sequenceNumber"
										placeholder="Введите номер"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Номер"
										mobile
										onClick={() => {
											if (!this.props.settingPricesOfProductFormStore.isSequenceNumberChangedManually) {
												this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
											}
										}}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.settingPricesOfProductFormStore.model}
										property="ownerId"
										relation="owner"
										placeholder="Выберите документ-основание"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName={this.props.settingPricesOfProductFormStore.model.ownerType ? models[this.props.settingPricesOfProductFormStore.model.ownerType].plural : ''}
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Основание"
										mobile
										disabled
										renderName={(model: any) => {
											if (this.props.settingPricesOfProductFormStore.model.ownerType) {
												return catalog.renderDocumentName(model, this.props.settingPricesOfProductFormStore.model.ownerType);
											} else {
												return '';
											}
										}}
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.settingPricesOfProductFormStore.setValue(this.props.settingPricesOfProductFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.settingPricesOfProductFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: this.props.settingPricesOfProductFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.settingPricesOfProductFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: this.props.settingPricesOfProductFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.settingPricesOfProductFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(SettingPricesOfProductForm));
