import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { quantityFormatter } from '../../shared/Formatters/QuantityFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatFormGroupLabel from '../../shared/Inputs/NatFormGroupLabel';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import NatFillModal from '../shared/NatFillModal';
import { PrintLabelFormStoreType } from './PrintLabelFormStore';

interface PropsType extends FormPropsType {
	printLabelFormStore: PrintLabelFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
	saveProductItems(productItems: Array<any>): void;
	print(): void;
	fillProductItems(productItems: Array<any>, productItemTemplate: any): void;
}

interface StateType {
	columns: Array<any>;
	isFillModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class PrintLabelForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleFillModal = this.toggleFillModal.bind(this);
		this.state = {
			isFillModalOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input type="checkbox" id="checkbox" onChange={() => this.props.childrenProps.saveSelected(data)} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatFormGroupLabel label="" labelColumnSettings="0" labelColumnClassName="text-left align-self-center text-white" row={true} wrapperClassName="w-100 h-100 m-0" inputColumnClassName="p-0">
									<NatRelationInput
										object={data}
										property="productId"
										relation="product"
										placeholder="Выберите товар"
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="products"
										filterWhere={{
											deleted: false,
											elementType: 0,
											typeId: {
												nin: ['product_types.set']
											}
										}}
										include={['unit']}
										autoComplete="off"
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</NatFormGroupLabel>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'feature',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="featureId"
													relation="feature"
													placeholder="Выберите характеристику"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="productFeatures"
													apiMethod={(): any => {
														return controller.fetchFeaturesByProduct({ product: data.product });
													}}
													create={(pluralName, handlers) => {
														this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
													}}
													autoComplete="off"
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 4,
					field: 'productBarcode',
					label: 'Штрихкод',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productBarcodeId"
									relation="productBarcode"
									placeholder="Выберите штрихкод"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter(true)}
									pluralName="productBarcodes"
									filterWhere={{ or: [{ productId: data.productId }] }}
									autoComplete="off"
									renderName={(model: any) => {
										return model.barcode;
									}}
								/>
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="quantity"
									placeholder="Введите кол-во"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={quantityFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'price',
					label: 'Цена',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="price"
									placeholder="Введите цену"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={priceFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleFillModal(status: boolean) {
		this.setState({
			isFillModalOpen: status
		});
	}
	render() {
		let productItemTemplate = {
			featureId: null,
			organizationId: this.props.printLabelFormStore.model.organizationId,
			price: 0,
			priceTypeId: this.props.printLabelFormStore.model.priceTypeId,
			labelTemplateId: this.props.printLabelFormStore.model.labelTemplateId,
			unitId: null,
			id: '',
			productBarcodeId: null,
			createdAt: this.props.printLabelFormStore.model.createdAt,
			quantity: 1,
			feature: {
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			},
			product: {
				accountingOptionId: 'product_accounting_options.standart',
				area: 0,
				areaUnitId: null,
				article: null,
				caption: null,
				deleted: false,
				description: null,
				displayName: null,
				elementType: 0,
				giftCardValidityExpiredAt: null,
				giftCardValidityPeriodCount: 0,
				giftCardValidityPeriodId: 'periodicity.year',
				giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
				giftCardValue: 0,
				height: 0,
				heightUnitId: null,
				kindId: null,
				labelTemplateId: null,
				length: 0,
				lengthUnitId: null,
				name: { ru: '' },
				organizationId: null,
				packageSetId: null,
				parentId: null,
				primaryImageId: null,
				priority: null,
				supplierId: null,
				typeId: 'product_types.goods',
				unitId: null,
				useArea: false,
				useCustomLabelTemplate: false,
				useFeatureOptionId: 'use_feature_options.not_used',
				useGiftCardPartially: false,
				useHeight: false,
				useLength: false,
				usePackageOptionId: 'use_package_options.not_used',
				useSerialNumbers: false,
				useVolume: false,
				useWeight: false,
				useWidth: false,
				volume: 0,
				volumeUnitId: null,
				weight: 0,
				weightCanManuallySet: false,
				weightUnitId: null,
				width: 0,
				widthUnitId: null
			},
			unit: {
				baseUnitId: null,
				deleted: false,
				displayName: null,
				elementType: 0,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				packageTypeId: 'unit_package_types.final',
				parentId: null,
				priority: null,
				quantityOfOwnerUnitIncludedInPackage: 0,
				quantityOfPackageIncludedInOwnerUnit: 1,
				quantityOfParentPackageIncludedInPackage: 0,
				typeId: null
			},
			productBarcode: {
				productId: null,
				featureId: null,
				unitId: null,
				barcode: ''
			},
			labelTemplate: this.props.printLabelFormStore.model.labelTemplate,
			organization: this.props.printLabelFormStore.model.organization,
			priceType: this.props.printLabelFormStore.model.priceType
		};
		return (
			<NatHeaderCollapseFormTemplate
				makeReactions={this.props.makeReactions}
				store={this.props.printLabelFormStore}
				productSelection
				isAlertOpen={this.props.childrenProps.isAlertOpen}
				toggleAlert={this.props.childrenProps.toggleAlert}
				saveProductItems={this.props.saveProductItems}
				notRecalculateAmount
				productItemTemplate={productItemTemplate}
				selectionColumns={this.state.columns}
				navTabs={[{ name: 'Товары' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={(toggleSelection: (status: boolean) => void) => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(productItemTemplate);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.printLabelFormStore.selectedItems);
										},
										withoutIcon: true,
										disabled: this.props.printLabelFormStore.selectedItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											toggleSelection(true);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'ЗАПОЛНИТЬ',
										onClick: () => {
											this.toggleFillModal(true);
										},
										withoutIcon: true,
										disabled: false
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.printLabelFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							{this.state.isFillModalOpen && (
								<NatFillModal
									isModalOpen={this.state.isFillModalOpen}
									toggleModal={this.toggleFillModal}
									fillProductItems={(productItems: Array<any>) => {
										this.props.fillProductItems(productItems, productItemTemplate);
									}}
								/>
							)}
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods(this.state.columns)}
										rows={this.props.printLabelFormStore.model.productItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										showSearch={false}
										searchText={this.props.printLabelFormStore.searchText}
									/>
								</form>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date> object={this.props.printLabelFormStore.model} property="createdAt" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Дата" />
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.printLabelFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.printLabelFormStore.model}
										property="labelTemplateId"
										relation="labelTemplate"
										placeholder="Выберите шаблон"
										type="text"
										size="sm"
										formatter={relationFormatter(true)}
										pluralName="labelTemplates"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.printLabelFormStore.model.labelTemplateId)}
										invalidFeedback="Элемент не выбран"
										label="Шаблон"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.printLabelFormStore.model}
										property="priceTypeId"
										relation="priceType"
										placeholder="Выберите тип цены"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="priceTypes"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Тип цены"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ПЕЧАТЬ',
						onClick: () => {
							this.props.print();
						}
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(PrintLabelForm));
