import { observable, action, makeObservable, set } from 'mobx';
import { v4 as generateRandomId } from 'uuid';

import { ChipsBooleanType, ChipsDateType, ChipsStringType, ProductType } from '../../shared/support/modelTypes';

export interface ProductsListStoreType {
	searchFields: { deleted: boolean };
	itemsPerPage: number;
	currentPage: number;
	list: Array<any>;
	responseCode: number;
	filterOrder: Array<string>;
	filterSkip: number;
	filterWhere: { deleted?: boolean; elementType: number; parentId?: string | null | { ing: Array<string> }; name?: { like: string; options: string } };
	filterInclude: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	filterGroupOrder: Array<string>;
	filterGroupWhere: { deleted?: boolean; elementType?: number };
	idArray: Array<any>;
	group: any;
	isSelected: boolean;
	treeState: any;
	groupsTree: Array<any>;
	groups: Array<any>;
	sortConfig: { collId: string; isAsc: boolean };
	chips: Array<any>;
	chipsFields: { name: ChipsStringType; article: ChipsStringType; description: ChipsStringType; createdAtGt: ChipsDateType; createdAtLt: ChipsDateType; deleted: ChipsBooleanType };
	isFirstLoad: boolean;
	name: string;
	rootId: string;
	bulkEditingFields: {
		parentId: string | null;
		parent?: ProductType;
	};
	showChildGroupsElements: boolean;
	setValue(model: any, key: string, value: any): void;
}

class ProductsListStore implements ProductsListStoreType {
	constructor() {
		makeObservable(this, {
			searchFields: observable,
			itemsPerPage: observable,
			currentPage: observable,
			list: observable,
			responseCode: observable,
			filterOrder: observable,
			filterSkip: observable,
			filterWhere: observable,
			filterInclude: observable,
			pluralName: observable,
			collectedModels: observable,
			filterGroupOrder: observable,
			filterGroupWhere: observable,
			idArray: observable,
			group: observable,
			isSelected: observable,
			treeState: observable,
			groupsTree: observable,
			groups: observable,
			sortConfig: observable,
			chips: observable,
			chipsFields: observable,
			isFirstLoad: observable,
			rootId: observable,
			name: observable,
			bulkEditingFields: observable,
			showChildGroupsElements: observable,
			setValue: action
		});
	}
	name = 'OrmProduct';
	searchFields = {
		deleted: false
	};
	itemsPerPage = 50;
	currentPage = 0;
	list = [] as Array<any>;
	responseCode = 0;
	filterOrder = ['createdAt DESC'];
	filterSkip = 0;
	filterWhere = { deleted: false, elementType: 0 };
	filterInclude = ['unit'];
	pluralName = 'products';
	collectedModels = [] as Array<any>;
	groups = [] as Array<any>;
	groupsTree = [] as Array<any>;
	treeState = {
		activeKey: '',
		focusKey: '',
		openNodes: [] as string[],
		nodes: [] as any[]
	};
	isSelected = false;
	group = null;
	idArray = [] as Array<string>;
	filterGroupOrder = ['createdAt DESC'];
	filterGroupWhere = { deleted: false, elementType: 1 };
	sortConfig = { collId: '', isAsc: false };
	chips = [];
	isFirstLoad = true;
	showChildGroupsElements = false;
	chipsFields = {
		name: {
			id: null,
			chips: [],
			title: 'Наименование',
			isLocalizedString: true
		},
		article: {
			id: null,
			chips: [],
			title: 'Артикул'
		},
		description: {
			id: null,
			chips: [],
			title: 'Описание'
		},
		createdAtGt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: true
		},
		createdAtLt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: false
		},
		deleted: {
			id: false,
			display: { isChecked: false, title: 'Отображать' },
			doNotDisplay: { isChecked: true, title: 'Не отображать' },
			chips: [
				{
					id: generateRandomId(),
					name: {
						ru: 'Удаленные: не отображать'
					}
				}
			],
			isBoolean: true,
			title: 'Удаленные'
		}
	};
	bulkEditingFields = {
		parentId: null
	};
	rootId = generateRandomId();
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productsListStore = new ProductsListStore();

export default ProductsListStore;
