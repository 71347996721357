import { cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { matchPath, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../Alerts/AlertDialog';
import { NavigationContainerStoreType, NavigationRouteType } from '../ContainersStores/NavigationContainerStore';
import { routes } from '../support/Routes';
import { MatchType } from '../support/modelTypes';

interface PropsType extends RouteComponentProps {
	location: any;
	match: MatchType;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	navigationContainerStore: NavigationContainerStoreType;
}

interface StateType {
	isModalOpen: boolean;
	isCloseAllModalOpen: boolean;
	navigationRoute: NavigationRouteType | null;
	childMethod: any;
	isDocument: boolean;
	isNavDropdownShow: boolean;
}

@inject('directoryStore', 'userStore', 'navigationContainerStore')
@observer
class NavigationContainer extends Component<PropsType, StateType> {
	ref: any;
	pageRef: any;
	constructor(props: PropsType) {
		super(props);
		this.ref = React.createRef();
		this.pageRef = React.createRef();
		this.generateNavigationRoute = this.generateNavigationRoute.bind(this);
		this.goTo = this.goTo.bind(this);
		this.setActiveNavigationRoute = this.setActiveNavigationRoute.bind(this);
		this.addNavigationRoute = this.addNavigationRoute.bind(this);
		this.removeNavigationRoute = this.removeNavigationRoute.bind(this);
		this.checkStoreChanges = this.checkStoreChanges.bind(this);
		this.replace = this.replace.bind(this);
		this.getChildMethod = this.getChildMethod.bind(this);
		this.determineCoordinates = this.determineCoordinates.bind(this);
		this.closeAll = this.closeAll.bind(this);
		this.getOpenedNavigationRoutes = this.getOpenedNavigationRoutes.bind(this);
		this.setOpenedNavigationRoutes = this.setOpenedNavigationRoutes.bind(this);
		this.state = {
			isModalOpen: false,
			isCloseAllModalOpen: false,
			navigationRoute: null,
			childMethod: null,
			isDocument: false,
			isNavDropdownShow: false
		};
	}

	componentDidMount() {
		// this.getOpenedNavigationRoutes();
		this.generateNavigationRoute(this.props.location.pathname);
		let natTabMenuDropdownList = this.pageRef.current.querySelector('.nat__tab__menu__dropdown__list');
		natTabMenuDropdownList.style.maxHeight = this.pageRef.current.clientHeight - 35.25 + 'px';
		if (this.ref.current !== undefined && this.ref.current !== undefined) {
			this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'navTabs', this.ref.current.querySelector('.nav-tabs'));
		}
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.generateNavigationRoute(this.props.location.pathname);
			// this.setOpenedNavigationRoutes();
		}
		let navigationRoutesMap = _.chain(this.injected.navigationContainerStore.navigationRoutes).keyBy('url').value();
		let navigationRoute: any = navigationRoutesMap[this.props.location.pathname];
		if (!_.isEmpty(navigationRoute)) {
			if (this.injected.navigationContainerStore.activeNavigationRoute === navigationRoute.dataTab) {
				this.determineCoordinates();
				this.injected.navigationContainerStore.navTabs.scrollTo(Number(navigationRoute.x), 0);
			}
		}
	}
	get injected() {
		return this.props as InjectedProps;
	}

	generateNavigationRoute(pathname: string) {
		let route: any = _.find(routes, (value) => {
			return !_.isEmpty(matchPath(pathname, value));
		});
		let handlers: Record<string, unknown> = this.injected.navigationContainerStore.routeHandlers[pathname];
		if (!_.isEmpty(handlers)) {
			delete this.injected.navigationContainerStore.routeHandlers[pathname];
		}
		let url: string = '/';
		if (route) {
			let modal = route.modal;
			let navigationRoutesMap = _.chain(this.injected.navigationContainerStore.navigationRoutes).keyBy('url').value();
			let navigationRoute: any = navigationRoutesMap[pathname];
			if (_.isEmpty(navigationRoute)) {
				let parentRoute =
					this.injected.navigationContainerStore.navigationRoutes[this.injected.navigationContainerStore.currentNavigationRouteIndex] !== undefined
						? this.injected.navigationContainerStore.navigationRoutes[this.injected.navigationContainerStore.currentNavigationRouteIndex].url
						: url;
				navigationRoute = {
					dataTab: _.get(this.props.location, 'key', generateRandomId()),
					url: this.props.location.pathname,
					parentRoute: parentRoute,
					route: route,
					store: new route.store(),
					displayed: !modal,
					cloaseble: true,
					handlers
				};
				this.addNavigationRoute(navigationRoute);
			}
			if (!modal) {
				this.setActiveNavigationRoute(navigationRoute);
			}
		} else {
			this.goTo(url);
		}
	}

	replace(newUrl: string, navigationRoute: any) {
		if (navigationRoute.url !== newUrl) {
			runInAction(() => {
				navigationRoute.url = newUrl;
			});
		}
		this.goTo(navigationRoute.url);
	}
	setOpenedNavigationRoutes() {
		let navigationRoutesData = {
			navigationRoutes: this.injected.navigationContainerStore.navigationRoutes,
			activeNavigationRoute: this.injected.navigationContainerStore.activeNavigationRoute,
			currentNavigationRouteIndex: this.injected.navigationContainerStore.currentNavigationRouteIndex,
			navigationRoute: this.injected.navigationContainerStore.navigationRoute
		};
		localStorage.setItem('navigationRoutesData', JSON.stringify(navigationRoutesData));
	}
	getOpenedNavigationRoutes() {
		let navigationRoutesData = localStorage.getItem('navigationRoutesData');
		if (navigationRoutesData) {
			let parsedNavigationRoutesData = JSON.parse(navigationRoutesData);
			let result: Array<any> = [];
			for (let navigationRoute of parsedNavigationRoutesData.navigationRoutes) {
				let route: any = _.find(routes, (value) => {
					return !_.isEmpty(matchPath(navigationRoute.url, value));
				});
				if (route) {
					navigationRoute.route = route;
					let store = new route.store();
					_.forIn(store, (item, key) => {
						store[key] = navigationRoute.store[key];
					});
					navigationRoute.store = store;
					result.push(navigationRoute);
				}
			}
			let route: any = _.find(routes, (value) => {
				return !_.isEmpty(matchPath(parsedNavigationRoutesData.navigationRoute.url, value));
			});
			if (route) {
				parsedNavigationRoutesData.navigationRoute.route = route;
				let store = new route.store();
				_.forIn(store, (item, key) => {
					store[key] = parsedNavigationRoutesData.navigationRoute.store[key];
				});
				parsedNavigationRoutesData.navigationRoute.store = store;
				this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'navigationRoute', parsedNavigationRoutesData.navigationRoute);
			}
			this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'currentNavigationRouteIndex', parsedNavigationRoutesData.currentNavigationRouteIndex);
			this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'activeNavigationRoute', parsedNavigationRoutesData.activeNavigationRoute);
			this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'navigationRoutes', result);
		}
	}
	checkStoreChanges(navigationRoute: any) {
		if (navigationRoute.store && navigationRoute.store.isChanged) {
			this.setState({
				isModalOpen: true,
				navigationRoute
			});
		} else {
			this.removeNavigationRoute(navigationRoute);
		}
	}

	setActiveNavigationRoute(navigationRoute: any) {
		this.injected.navigationContainerStore.setValue(
			this.injected.navigationContainerStore,
			'currentNavigationRouteIndex',
			_.findIndex(this.injected.navigationContainerStore.navigationRoutes, (item) => item.url === navigationRoute.url)
		);
		this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'activeNavigationRoute', navigationRoute.dataTab);
		this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'navigationRoute', navigationRoute);
	}

	addNavigationRoute(navigationRoute: any) {
		runInAction(() => {
			this.injected.navigationContainerStore.navigationRoutes.push(navigationRoute);
		});
	}

	removeNavigationRoute(navigationRoute: any) {
		runInAction(() => {
			_.pull(this.injected.navigationContainerStore.navigationRoutes, navigationRoute);
			this.setActiveNavigationRoute(this.injected.navigationContainerStore.navigationRoute);
		});
		let parentNavigationRoute = _.find(this.injected.navigationContainerStore.navigationRoutes, { url: navigationRoute.parentRoute });
		let route: string = parentNavigationRoute !== undefined ? parentNavigationRoute.url : '/';
		this.goTo(route);
	}

	goTo(path: string, state?: Record<string, unknown>, handlers?: Record<string, unknown>) {
		if (!_.isEmpty(handlers)) {
			_.set(this.injected.navigationContainerStore.routeHandlers, path, handlers);
		}
		this.props.history.push(path, state);
	}

	getChildMethod(method: any, isDocument?: boolean) {
		this.setState({
			childMethod: method,
			isDocument: isDocument !== undefined ? isDocument : false
		});
	}

	determineCoordinates() {
		if (this.ref.current !== null) {
			let navTabsList = this.ref.current.querySelectorAll('li');
			let refsMap = _.keyBy(navTabsList, (item) => {
				return item.id;
			});
			_.forEach(this.injected.navigationContainerStore.navigationRoutes, (item) => {
				if (!_.isEmpty(navTabsList)) {
					if (!_.isEmpty(refsMap[item.dataTab])) {
						if (!item.x) {
							item.x = refsMap[item.dataTab].getBoundingClientRect().x - refsMap[item.dataTab].parentNode.getBoundingClientRect().x;
						}
					}
				}
			});
		}
	}
	closeAll() {
		this.injected.navigationContainerStore.resetNavigationRoutes();
		this.goTo('/');
		this.setState({
			isCloseAllModalOpen: false
		});
	}
	render() {
		return (
			<CCard className="pt-0 d-flex ml-3 mr-3 mb-3 nat__card c-main border-0 bg-transparent" innerRef={this.pageRef}>
				<CTabs activeTab={this.injected.navigationContainerStore.activeNavigationRoute}>
					<div className="nat__card__navbar border-0 mx-5" ref={this.ref}>
						<CNav variant="tabs" className={this.injected.directoryStore.models.windowSize >= 760 ? 'nat__navbar border-0 nat__navbar__tabs' : 'nat__navbar border-0 nat__navbar__tabs nat__navbar__mobile'}>
							{_(this.injected.navigationContainerStore.navigationRoutes)
								.filter({ displayed: true })
								.map((item: any) => {
									return (
										<CNavItem key={item.dataTab} className="mr-1" id={item.dataTab}>
											<CNavLink className="p-0 border-0" data-tab={item.dataTab}>
												<CButton
													onClick={(e) => {
														e.stopPropagation();
														this.goTo(item.url);
													}}
													className={item.cloaseble ? 'pt-1 pb-1 pr-0' : 'pt-1 pb-1'}>
													{item.title !== undefined && item.title}
												</CButton>
												{item.cloaseble && (
													<CButton
														onClick={(e) => {
															e.stopPropagation();
															this.checkStoreChanges(item);
														}}
														className="pt-1 pb-1 pl-1">
														<CIcon content={cilX} size="sm" className="ml-1 mt-0 p-0" />
													</CButton>
												)}
											</CNavLink>
										</CNavItem>
									);
								})
								.value() || ''}
							<CDropdown className="nat__tab__menu m-0 p-0">
								<CDropdownToggle
									variant="ghost"
									caret={false}
									className="p-0 pl-1 pr-1 nat__navigation__button"
									disabled={_.isEmpty(this.injected.navigationContainerStore.navigationRoutes)}
									onClick={() => {
										setTimeout(() => {
											let natTabMenuDropdownItemActive = this.pageRef.current.querySelector('.nat__tab__menu__dropdown__item__active');
											let natTabMenuDropdownList = this.pageRef.current.querySelector('.nat__tab__menu__dropdown__list');
											natTabMenuDropdownList.scrollTo(0, Number(natTabMenuDropdownItemActive.getBoundingClientRect().y));
										}, 0);
									}}>
									<CIcon name="cil-caret-bottom" size="sm" />
								</CDropdownToggle>
								<CDropdownMenu className="m-0 nat__tab__menu__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow nat__borders__2-75">
									{_(this.injected.navigationContainerStore.navigationRoutes)
										.filter({ displayed: true })
										.map((item: any, index: number) => {
											return (
												<CDropdownItem
													key={index}
													className={(() => {
														if (this.injected.navigationContainerStore.activeNavigationRoute === item.dataTab) {
															return 'nat__tab__menu__dropdown__item__active';
														}
													})()}>
													<CButton
														onClick={() => {
															this.goTo(item.url);
														}}
														className="pt-1 pb-1 pl-1 text-truncate text-left mr-auto w-100 h-100">
														{item.title !== undefined && item.title}
													</CButton>
													{item.cloaseble && (
														<CButton onClick={() => this.checkStoreChanges(item)} className="pt-1 pb-1 pr-0">
															<CIcon content={cilX} size="sm" className="ml-1 mt-0 p-0" />
														</CButton>
													)}
												</CDropdownItem>
											);
										})
										.value() || ''}
									<CDropdownItem
										onClick={() =>
											this.setState({
												isCloseAllModalOpen: true
											})
										}>
										<span className="pt-1 pb-1 pl-1">Закрыть все вкладки</span>
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
						</CNav>
					</div>
					<CCardBody className="p-0">
						<CTabContent className="h-100">
							{(() => {
								return (
									<Switch>
										{_.chain(this.injected.navigationContainerStore.navigationRoutes)
											.filter((value) => {
												return value.route && !value.route.modal;
											})
											.groupBy('route.path')
											.values()
											.map((value) => {
												let route = _.chain(value).head().get('route').value();
												if (route) {
													return (
														<Route
															key={route.path}
															exact={route.exact}
															path={route.path}
															render={() =>
																_.map(value, (value) => {
																	return (
																		<CTabPane
																			key={value.dataTab}
																			data-tab={value.dataTab}
																			className={(() => {
																				if (value.route.path.indexOf('elementId', 0) === -1) {
																					return 'default__nat__card__body__height';
																				} else {
																					return 'default__nat__form__body__height';
																				}
																			})()}>
																			<value.route.component
																				removeNavigationRoute={this.checkStoreChanges}
																				replace={this.replace}
																				goTo={this.goTo}
																				getChildMethod={this.getChildMethod}
																				navigationRoute={value}
																				index={this.injected.navigationContainerStore.navigationRoutes.indexOf(value)}
																				store={value.store}
																				handlers={value.handlers}
																			/>
																		</CTabPane>
																	);
																})
															}
														/>
													);
												}
											})
											.concat(
												(() => {
													let navigationRoute = this.injected.navigationContainerStore.navigationRoute;
													if (!navigationRoute) {
														return [];
													}
													let route = navigationRoute.route;
													if (!route) {
														return [];
													}
													return [
														<Route
															key={route.path}
															render={() => {
																return (
																	<CTabPane
																		key={navigationRoute.dataTab}
																		data-tab={navigationRoute.dataTab}
																		className={(() => {
																			if (route.path.indexOf('elementId', 0) === -1) {
																				return 'default__nat__card__body__height';
																			} else {
																				return 'default__nat__form__body__height';
																			}
																		})()}>
																		<route.component
																			removeNavigationRoute={this.checkStoreChanges}
																			replace={this.replace}
																			goTo={this.goTo}
																			getChildMethod={this.getChildMethod}
																			navigationRoute={navigationRoute}
																			index={this.injected.navigationContainerStore.navigationRoutes.indexOf(navigationRoute)}
																			store={navigationRoute.store}
																			handlers={navigationRoute.handlers}
																		/>
																	</CTabPane>
																);
															}}
														/>
													];
												})()
											)
											.value()}
									</Switch>
								);
							})()}
						</CTabContent>
						{_.chain(this.injected.navigationContainerStore.navigationRoutes)
							.filter((value) => {
								return value.route && value.route.modal;
							})
							.map((value) => {
								let route = _.chain(value).get('route').value();
								if (route) {
									return (
										<Route
											key={route.path}
											exact={route.exact}
											path={route.path}
											render={() => {
												return (
													<value.route.component
														removeNavigationRoute={this.checkStoreChanges}
														replace={this.replace}
														goTo={this.goTo}
														getChildMethod={this.getChildMethod}
														navigationRoute={value}
														index={this.injected.navigationContainerStore.navigationRoutes.indexOf(value)}
														store={value.store}
														handlers={value.handlers}
													/>
												);
											}}
										/>
									);
								}
							})
							.last()
							.value()}
					</CCardBody>
				</CTabs>
				<AlertDialog
					isModalOpen={this.state.isModalOpen}
					title="Сохранить изменения?"
					buttonItemList={[
						{
							title: 'СОХРАНИТЬ',
							onClick: (e) => {
								if (this.state.isDocument) {
									if (this.state.navigationRoute !== null) {
										const store = this.state.navigationRoute.store;
										if (store.model.posted) {
											this.state.childMethod(e, 'post');
										} else {
											this.state.childMethod(e, 'write');
										}
									}
								} else {
									this.state.childMethod(e);
								}
								this.removeNavigationRoute(this.state.navigationRoute);
								this.setState({
									isModalOpen: false,
									navigationRoute: null
								});
							}
						},
						{
							title: 'НЕ СОХРАНЯТЬ',
							onClick: () => {
								this.removeNavigationRoute(this.state.navigationRoute);
								this.setState({
									isModalOpen: false,
									navigationRoute: null
								});
							}
						},
						{
							title: 'ОТМЕНА',
							onClick: () => {
								this.setState({
									isModalOpen: false
								});
							}
						}
					]}
				/>
				<AlertDialog
					isModalOpen={this.state.isCloseAllModalOpen}
					title="Все несохраненные изменения будут утеряны. Закрыть все вкладки?"
					buttonItemList={[
						{
							title: 'ЗАКРЫТЬ',
							onClick: () => {
								this.closeAll();
							}
						},
						{
							title: 'ОТМЕНА',
							onClick: () => {
								this.setState({
									isCloseAllModalOpen: false
								});
							}
						}
					]}
				/>
			</CCard>
		);
	}
}

export default withRouter(NavigationContainer);
