import { observable, action, makeObservable, set } from 'mobx';

import { PartnerType } from '../../shared/support/modelTypes';

export interface PartnerFormStoreType {
	model: PartnerType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	secondList: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class PartnerFormStore implements PartnerFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			secondList: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		bankAccountId: null,
		birthdate: null,
		client: true,
		conctactSetId: null,
		contractId: null,
		deleted: false,
		displayName: null,
		elementType: 0,
		firstName: null,
		genderId: null,
		iec: null,
		itn: null,
		lastName: null,
		middleName: null,
		name: { ru: '' },
		otherRelations: false,
		ownershipTypeId: 'partner_ownership_types.individual',
		parentId: null,
		priceTypeId: null,
		priority: null,
		supplier: false,
		typeId: 'partner_types.individual',
		type: {
			description: null,
			id: 'partner_types.individual',
			keyId: 'individual',
			name: { ru: 'Физическое лицо' },
			ownerId: 'partner_types',
			predefined: true,
			predefinedName: 'PartnerTypesIndividual',
			predefinedVersion: 0
		},
		ownershipType: {
			description: null,
			id: 'partner_ownership_types.individual',
			keyId: 'individual',
			name: { ru: 'Физическое лицо' },
			ownerId: 'partner_ownership_types',
			predefined: true,
			predefinedName: 'PartnerOwnershipTypeIndividual',
			predefinedVersion: 0
		}
	};
	list = [];
	secondList = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'partners';
	filterInclude = ['ownershipType', 'type', 'gender', 'customerOrders', 'priceType', 'contract'];
	collectedModels = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultPriceType',
			property: 'priceTypeId',
			relation: 'priceType'
		}
	];
	isFirstLoad = true;
	name = 'OrmPartner';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const partnerFormStore = new PartnerFormStore();

export default PartnerFormStore;
