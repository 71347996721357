import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatFormList from '../../shared/Components/NatFormList';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { selectFormatter } from '../../shared/Formatters/SelectFormatter';
import NatStringSelectInput from '../../shared/Inputs/NatStringSelectInput';
import { catalog } from '../../shared/support/Catalog';
import NatFillModalStore, { NatFillModalStoreType } from './NatFillModalStore';

interface PropsType extends RouteComponentProps {
	toggleModal(status: boolean): void;
	isModalOpen: boolean;
	fillProductItems(productItems: Array<any>): void;
}

interface StateType {
	columns: Array<any>;
	querySelector: any;
	selectList: Array<any>;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatFillModal extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	natFillModalStore: NatFillModalStoreType;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.collectModels = this.collectModels.bind(this);
		this.fill = this.fill.bind(this);
		this.natFillModalStore = new NatFillModalStore();
		this.reactions = [];
		this.state = {
			querySelector: null,
			selectList: [
				{
					name: 'receiptOfProducts',
					displayName: 'Поступления'
				},
				{
					name: 'settingPricesOfProducts',
					displayName: 'Установки цен'
				}
			],
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'sequenceNumber',
					label: 'Номер',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{data.sequenceNumber}</div>;
					},
					sort: () => {}
				},
				{
					id: 2,
					field: 'createdAt',
					label: 'Дата создания',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{listDateTimeFormatter.renderValue(data.createdAt)}</div>;
					},
					sort: () => {}
				},
				{
					id: 3,
					field: 'defaultName',
					label: 'Наименование',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{data.defaultName[this.injected.directoryStore.models.language]}</div>;
					},
					sort: () => {}
				}
			]
		};
		this.reactions.push(
			reaction(
				() => this.natFillModalStore.pluralName,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							catalog.findInstances(value as string, {}, this.natFillModalStore);
						}
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.natFillModalStore.setValue(this.natFillModalStore, 'responseCode', 0);
		catalog.findInstances(this.natFillModalStore.pluralName as string, {}, this.natFillModalStore);
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	collectModels(arrayIds: Array<any>, array: Array<any>) {
		let grouped = _.chain(array).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let model = grouped[item];
			if (!_.isEmpty(model)) {
				if (_.find(this.natFillModalStore.collectedModels, (itm: any) => itm.id === model.id) === undefined) {
					runInAction(() => {
						this.natFillModalStore.collectedModels.push(model);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.natFillModalStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.natFillModalStore.setValue(this.natFillModalStore, 'collectedModels', buffer);
	}
	fill() {
		if (!_.isEmpty(this.natFillModalStore.collectedModels)) {
			let productItems: Array<any> = [];
			_.forEach(this.natFillModalStore.collectedModels, (item) => {
				productItems = productItems.concat(item.productItems);
			});
			this.props.fillProductItems(productItems);
		} else {
			catalog.showAlert('Элементы для переноса не выбраны!');
		}
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАПОЛНИТЬ',
				onClick: () => {
					this.fill();
					this.props.toggleModal(false);
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleModal(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return (
									<form className="d-flex w-100">
										{_.map(controlButtons, (item, index) => {
											if (!item.dontShow) {
												return (
													<CButton
														key={index}
														type="button"
														size="sm"
														className="mr-1 nat__button__hover"
														onClick={() => {
															item.onClick();
														}}>
														{item.title}
													</CButton>
												);
											}
										})}
									</form>
								);
							} else {
								return (
									<CDropdown>
										<CDropdownToggle caret className="nat__button__hover">
											МЕНЮ
										</CDropdownToggle>
										<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CDropdownItem
															key={index}
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CDropdownItem>
													);
												}
											})}
										</CDropdownMenu>
									</CDropdown>
								);
							}
						})()}
					</div>
					<CForm>
						<NatStringSelectInput
							object={this.natFillModalStore}
							property="pluralName"
							type="text"
							size="sm"
							formatter={selectFormatter}
							autoComplete="off"
							list={this.state.selectList}
							placeholder="Выберите"
							label="ЗАПОЛНИТЬ ИЗ:"
							formGroupClassName="w-100 align-items-center nat__form__group mb-0"
						/>
					</CForm>
				</CModalHeader>
				<CModalBody className="p-0 m-4">
					<NatFormList
						setValue={this.natFillModalStore.setValue}
						data={this.natFillModalStore[this.natFillModalStore.pluralName as string]}
						store={this.natFillModalStore}
						collectModels={this.collectModels}
						goTo={() => {}}
						scrollable
						sortable
						wrapperClassName="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100"
						className="nat__modal__table_1 nat__table__hover"
						columns={this.state.columns}>
						<></>
					</NatFormList>
				</CModalBody>
				{this.natFillModalStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatFillModal);
