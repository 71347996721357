import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { natiwiController } from '../../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import UserLoginContainer from '../../login/UserLoginContainer';
import AlertDialog from '../Alerts/AlertDialog';
import { NavigationContainerStoreType } from '../ContainersStores/NavigationContainerStore';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';
import { UseLocation } from '../support/useLocationHoC';
import Layout from './Layout';

interface PropsType extends RouteComponentProps {
	location: any;
	match: MatchType;
	query: any;
}
interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	navigationContainerStore: NavigationContainerStoreType;
	directoryStore: OrmStoreType;
}
interface StateType {
	isPaymentAlertOpen: boolean;
}
@inject('directoryStore', 'userStore', 'navigationContainerStore')
@observer
class AppRouter extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.state = {
			isPaymentAlertOpen: false
		};
	}
	componentDidMount() {
		if (!this.injected.userStore.userLogin.isAuth) {
			if (this.props.location.pathname.indexOf('users', 0) === -1) {
				this.props.history.push(`/users/login?redirect=${this.props.location.pathname + this.props.location.search}`);
			}
		} else {
			if (this.props.location.pathname.indexOf('shoppingCart', 0) !== -1) {
				if (!localStorage.getItem('masterAccessToken') || this.injected.directoryStore.models.apiData.mode !== 'tenant') {
					this.props.history.push('/');
				} else {
					let mode = this.props.query.get('mode');
					if (mode) {
						if (mode === 'extend') {
							let subscriptionItemListHead = _.head(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList);
							if (subscriptionItemListHead) {
								if (!subscriptionItemListHead.subscriptionPlan.renewable || subscriptionItemListHead.subscriptionPlan.trial) {
									this.props.history.push('/');
								}
							} else {
								this.props.history.push('/');
							}
						} else if (mode === 'personalAccount') {
							if (!_.isEmpty(this.injected.directoryStore.models.constants['AllowPersonalAccount'])) {
								if (!this.injected.directoryStore.models.constants['AllowPersonalAccount'].valueId) {
									this.props.history.push('/');
								}
							} else {
								this.props.history.push('/');
							}
						}
					}
				}
			}
		}
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<Switch>
				<Route
					exact
					path="/shoppingCart/checkout/:elementId/validate"
					render={() => (
						<AlertDialog
							loadPage={(query?: any, params?: any) => {
								this.setState({ isPaymentAlertOpen: true });
								let elementId = params ? params.elementId : null;
								let queryString = query ? query.toString() : undefined;
								let validateData: { queryString: string } = {
									queryString
								};
								if (!_.isEmpty(elementId)) {
									natiwiController
										.validate(validateData, elementId)
										.then((data) => {
											if (!_.isEmpty(data)) {
												if (data.statusId === 'payment_operation_statuses.paid') {
													this.props.history.push('/shoppingCart/checkout/paid');
												} else if (data.statusId === 'payment_operation_statuses.not_paid') {
													this.props.history.push('/shoppingCart/checkout/success');
												} else {
													this.props.history.push('/shoppingCart/checkout/failure');
												}
											} else {
												this.props.history.push('/shoppingCart/checkout/failure');
											}
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.props.history.push('/shoppingCart/checkout/failure');
										});
								}
							}}
							isModalOpen={this.state.isPaymentAlertOpen}
							title="Проверяю....."
							className="nat__gardient__bg"
							buttonItemList={[]}
						/>
					)}
				/>
				<Route
					exact
					path="/shoppingCart/checkout/paid"
					render={() => (
						<AlertDialog
							loadPage={() => {
								this.setState({ isPaymentAlertOpen: true });
							}}
							isModalOpen={this.state.isPaymentAlertOpen}
							title="Заказ успешно оплачен!"
							className="nat__gardient__bg"
							buttonItemList={[
								{
									title: 'ОК',
									onClick: () => {
										this.setState({ isPaymentAlertOpen: false });
										natiwiController
											.fetchPartnerSubscriptionInfo()
											.then((data) => {
												if (!_.isEmpty(data)) {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'partnerSubscriptionInfo', data);
												} else {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'partnerSubscriptionInfo', {
														periodAt: null,
														subscriptionItemList: []
													});
												}
												return natiwiController.fetchCurrentPartnerSettlement();
											})
											.then((data) => {
												if (!_.isEmpty(data)) {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'currentPartnerSettlement', data);
												} else {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'currentPartnerSettlement', {
														settlementAmount: 0,
														prepaymentAmount: 0
													});
												}
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
										this.props.history.push('/');
									}
								}
							]}
						/>
					)}
				/>
				<Route
					exact
					path="/shoppingCart/checkout/success"
					render={() => (
						<AlertDialog
							loadPage={() => {
								this.setState({ isPaymentAlertOpen: true });
							}}
							isModalOpen={this.state.isPaymentAlertOpen}
							title="Заказ оформлен!"
							className="nat__gardient__bg"
							buttonItemList={[
								{
									title: 'ОК',
									onClick: () => {
										this.setState({ isPaymentAlertOpen: false });
										natiwiController
											.fetchPartnerSubscriptionInfo()
											.then((data) => {
												if (!_.isEmpty(data)) {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'partnerSubscriptionInfo', data);
												} else {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'partnerSubscriptionInfo', {
														periodAt: null,
														subscriptionItemList: []
													});
												}
												return natiwiController.fetchCurrentPartnerSettlement();
											})
											.then((data) => {
												if (!_.isEmpty(data)) {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'currentPartnerSettlement', data);
												} else {
													this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'currentPartnerSettlement', {
														settlementAmount: 0,
														prepaymentAmount: 0
													});
												}
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
										this.props.history.push('/');
									}
								}
							]}
						/>
					)}
				/>
				<Route
					exact
					path="/shoppingCart/checkout/failure"
					render={() => (
						<AlertDialog
							loadPage={() => {
								this.setState({ isPaymentAlertOpen: true });
							}}
							isModalOpen={this.state.isPaymentAlertOpen}
							title="Во время обработки заказа произошла ошибка!"
							buttonItemList={[
								{
									title: 'ОК',
									onClick: () => {
										this.setState({ isPaymentAlertOpen: false });
										this.props.history.push('/');
									}
								}
							]}
						/>
					)}
				/>
				<Route path="/users" render={() => <UserLoginContainer />} />
				{this.injected.userStore.userLogin.isAuth && <Route path="/" render={() => <Layout />} />}
				<Route
					render={() => {
						return <Redirect to="/" />;
					}}
				/>
			</Switch>
		);
	}
}

export default UseLocation(withRouter(AppRouter));
