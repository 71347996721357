import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CModal, CModalHeader } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';

interface PropsType extends RouteComponentProps {
	toggleNatBulkEditingManager(status: boolean): void;
	items: Array<any>;
	isModalOpen: boolean;
	fields: React.ReactNode;
	columns: Array<any>;
	bulkUpsert(actionAfter?: () => void): void;
}

interface StateType {}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatBulkEditingManager extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАПИСАТЬ',
				onClick: () => {
					this.props.bulkUpsert(() => {
						this.props.toggleNatBulkEditingManager(false);
					});
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatBulkEditingManager(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<CForm className="mx-5 mb-3">{this.props.fields}</CForm>
				</CModalHeader>
				<div className="d-flex flex-column default__nat__card__body__height p-0 mx-4 mb-4">
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1">
							<GridTable
								columns={(() => {
									let arr: Array<any> = [];
									_.forEach(this.props.columns, (item) => {
										if (item.id !== 'checkbox') {
											arr.push(item);
										}
									});
									return arr;
								})()}
								rows={this.props.items}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
							/>
						</div>
					</div>
				</div>
			</CModal>
		);
	}
}

export default withRouter(NatBulkEditingManager);
