import { observable, action, makeObservable, set } from 'mobx';

import { ChipsDateType, ChipsStringType } from '../support/modelTypes';

export interface ProductSelectionStoreType {
	list: Array<any>;
	productsGroups: Array<any>;
	productTableColumns: Array<any>;
	productItems: Array<any>;
	search: string;
	deleted: boolean;
	responseCode: number;
	groupsTree: Array<any>;
	pluralName: string;
	treeState: any;
	selectedIds: Array<any>;
	isLoading: boolean;
	isRoot: boolean;
	scrollbarPosition: number;
	rootProduct: any;
	productFeatureList: Array<any>;
	collectedModels: Array<any>;
	total: number;
	chips: Array<any>;
	chipsFields: { name: ChipsStringType; article: ChipsStringType; createdAtGt: ChipsDateType; createdAtLt: ChipsDateType };
	validProductTypes: Array<string>;
	setValue(model: any, key: string, value: any): void;
}

class ProductSelectionStore implements ProductSelectionStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			productsGroups: observable,
			productTableColumns: observable,
			productItems: observable,
			search: observable,
			deleted: observable,
			responseCode: observable,
			groupsTree: observable,
			pluralName: observable,
			treeState: observable,
			selectedIds: observable,
			isLoading: observable,
			isRoot: observable,
			scrollbarPosition: observable,
			rootProduct: observable,
			productFeatureList: observable,
			collectedModels: observable,
			total: observable,
			chips: observable,
			chipsFields: observable,
			validProductTypes: observable,
			setValue: action
		});
	}
	validProductTypes = [];
	list = [] as Array<any>;
	productFeatureList = [] as Array<any>;
	productsGroups = [] as Array<any>;
	productTableColumns = [] as Array<any>;
	productItems = [] as Array<any>;
	search = '';
	deleted = false;
	responseCode = 0;
	groupsTree = [] as Array<any>;
	pluralName = 'products';
	treeState = {
		activeKey: '',
		focusKey: '',
		openNodes: [] as string[],
		nodes: [] as any[]
	};
	selectedIds = [];
	isLoading = false;
	isRoot = true;
	scrollbarPosition = 0;
	rootProduct = null;
	collectedModels = [] as Array<any>;
	total = 0;
	chips = [];
	chipsFields = {
		name: {
			id: null,
			chips: [],
			title: 'Наименование',
			isLocalizedString: true
		},
		article: {
			id: null,
			chips: [],
			title: 'Артикул'
		},
		createdAtGt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: true
		},
		createdAtLt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: false
		}
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productSelectionStore = new ProductSelectionStore();

export default ProductSelectionStore;
