import _ from 'lodash';
import { runInAction } from 'mobx';

import { NatRelationInputStoreType } from '../Inputs/InputsStore/NatRelationInputStore';
import { catalog } from '../support/Catalog';
import { routesMap } from '../support/Routes';
import ValueFormatter, { ButtonItem } from './ValueFormatter';

class RelationFormatter extends ValueFormatter<any, string> {
	dontShowOpenAction: boolean;
	actionButtons: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>>;
	constructor(dontShowActionButtons?: boolean, dontShowOpenAction?: boolean, buttonItems?: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>>) {
		super(dontShowActionButtons);
		this.actionButtons = [
			{
				icon: 'cil-x',
				title: 'Очистить поле',
				onClick: (object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => {
					runInAction(() => {
						object[property] = null;
						delete object[relation];
					});
					natRelationInputStore.setValue(natRelationInputStore, 'relations', []);
					natRelationInputStore.setValue(natRelationInputStore, 'currentPage', 0);
					natRelationInputStore.setValue(natRelationInputStore, 'showDropdown', false);
					natRelationInputStore.setValue(natRelationInputStore, 'oldValue', '');
				},
				disable: true
			}
		];
		if (buttonItems) {
			this.actionButtons = buttonItems.concat(this.actionButtons);
		}
		this.dontShowOpenAction = false;
		if (dontShowOpenAction) {
			this.dontShowOpenAction = dontShowOpenAction;
		}
	}
	public parseValue(value: any): any {
		throw new Error('Не поддерживается');
	}

	public renderValue(relation: any, object: any, property: string, renderName?: (model: any) => string): string {
		let value: string = '';
		if (!_.isEmpty(relation)) {
			if (renderName !== undefined) {
				value = renderName(relation);
			} else {
				let name = catalog.renderCatalogName(relation);
				if (name !== null) {
					value = name;
				}
			}
		}
		if (_.isEmpty(relation) && !_.isEmpty(object[property])) {
			value = `Объект не найден (${object[property]})`;
		}
		return value;
	}

	public getActionButtons(
		object: any,
		property: string,
		pluralName: string,
		buttonItems: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>>
	): Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>> {
		let actionButtons: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>> = this.actionButtons;
		if (buttonItems) {
			actionButtons = buttonItems.concat(actionButtons);
		}
		if (this.dontShowActionButtons) {
			actionButtons = [
				{
					icon: 'cil-x',
					title: 'Очистить поле',
					onClick: (object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => {
						runInAction(() => {
							object[property] = null;
							delete object[relation];
						});
						natRelationInputStore.setValue(natRelationInputStore, 'relations', []);
						natRelationInputStore.setValue(natRelationInputStore, 'currentPage', 0);
						natRelationInputStore.setValue(natRelationInputStore, 'showDropdown', false);
						natRelationInputStore.setValue(natRelationInputStore, 'oldValue', '');
					},
					disable: true
				}
			];
		} else {
			if (!this.dontShowOpenAction) {
				if (!_.isEmpty(object[property])) {
					if (routesMap[`/${pluralName}/:elementId`]) {
						let result: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>> = [
							{
								icon: 'cil-folder-open',
								title: 'Открыть форму элемента',
								onClick: () => {},
								to: `/${pluralName}/${object[property]}`
							}
						];
						actionButtons = result.concat(actionButtons);
					}
				}
			}
		}
		return actionButtons;
	}
}

export let relationFormatter = (dontShowActionButtons?: boolean, dontShowOpenAction?: boolean, buttonItems?: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType) => void>>) => {
	return new RelationFormatter(dontShowActionButtons, dontShowOpenAction, buttonItems);
};

export default RelationFormatter;
