import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CModal, CModalHeader, CRow, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { listDateFormatter } from '../../shared/Formatters/ListDateFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatDefaultRadio from '../../shared/Inputs/NatDefaultRadio';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import ProductSelection from '../../shared/Modals/ProductSelection';
import { catalog } from '../../shared/support/Catalog';
import NatLoyaltyPolicyRangeByProductManagerStore, { NatLoyaltyPolicyRangeByProductManagerStoreType } from './NatLoyaltyPolicyRangeByProductManagerStore';
import NatLoyaltyPolicyRangeStatusManager from './NatLoyaltyPolicyRangeStatusManager';

interface PropsType extends RouteComponentProps {
	toggleNatLoyaltyPolicyRangeByProductManager(status: boolean): void;
	isModalOpen: boolean;
	modelStore: any;
	goTo(path: string, state?: Record<string, unknown>, handlers?: Record<string, unknown>): void;
}

interface StateType {
	columns: Array<any>;
	isLoyaltyPolicyRangeStatusManagerOpen: boolean;
	isProductSelectionModalOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatLoyaltyPolicyRangeByProductManager extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	natLoyaltyPolicyRangeByProductManagerStore: NatLoyaltyPolicyRangeByProductManagerStoreType;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.collectModels = this.collectModels.bind(this);
		this.resetReactions = this.resetReactions.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.buildSourceList = this.buildSourceList.bind(this);
		this.checkSourceStatus = this.checkSourceStatus.bind(this);
		this.getSource = this.getSource.bind(this);
		this.toggleNatLoyaltyPolicyRangeStatusManager = this.toggleNatLoyaltyPolicyRangeStatusManager.bind(this);
		this.saveEnablePolicyRanges = this.saveEnablePolicyRanges.bind(this);
		this.saveDisablePolicyRanges = this.saveDisablePolicyRanges.bind(this);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.natLoyaltyPolicyRangeByProductManagerStore = new NatLoyaltyPolicyRangeByProductManagerStore();
		this.state = {
			isLoyaltyPolicyRangeStatusManagerOpen: false,
			isProductSelectionModalOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'product',
					label: 'Номенклатура',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{catalog.renderCatalogName(data.product)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 3,
					field: 'feature',
					label: 'Характеристика',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{catalog.renderCatalogName(data.feature)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 4,
					field: 'enabled',
					label: 'Действует',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{data.enabled && <CIcon name="cil-check-alt" size="lg" />}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 5,
					field: 'startedAt',
					label: 'Дата начала',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{listDateFormatter.renderValue(data.startedAt)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 6,
					field: 'expiredAt',
					label: 'Дата окончания',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{(() => {
									if (data.enabled) {
										if (data.expiredAt) {
											return listDateFormatter.renderValue(data.expiredAt);
										} else {
											return 'Бессрочно';
										}
									} else {
										return listDateFormatter.renderValue(data.expiredAt);
									}
								})()}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 7,
					field: 'note',
					label: 'Примечание',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (!data.enabled) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{data.note}
							</div>
						);
					},
					sort: () => {}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetReactions();
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeByProductManagerStore.date,
				() => {
					this.checkSourceStatus();
				}
			)
		);
	}
	componentDidMount() {
		this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'isLoading', true);
		this.getSource()
			.then(() => {
				this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	getSource() {
		let loyaltyPolicyRangeByProductFilter = {
			where: { sourceId: this.props.modelStore.model.id, sourceType: this.props.modelStore.name },
			include: this.natLoyaltyPolicyRangeByProductManagerStore.filterInclude,
			order: this.natLoyaltyPolicyRangeByProductManagerStore.filterOrder
		};
		return controller.findAll(this.natLoyaltyPolicyRangeByProductManagerStore.pluralName, loyaltyPolicyRangeByProductFilter).then((data) => {
			if (!_.isEmpty(data)) {
				this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'loyaltyPolicyRangesByProduct', data);
				this.buildSourceList();
			}
			this.makeReactions();
		});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.natLoyaltyPolicyRangeByProductManagerStore.list).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'collectedModels', buffer);
	}
	resetReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	buildSourceList() {
		let sources = _.chain(this.natLoyaltyPolicyRangeByProductManagerStore.loyaltyPolicyRangesByProduct)
			.groupBy((item) => {
				return `${item.productId}-${item.featureId}`;
			})
			.values()
			.transform((result: Array<any>, value) => {
				let head = _.head(value);
				if (head) {
					let item: any = {
						sourceType: head.sourceType,
						sourceId: head.sourceId,
						id: head.id,
						startedAt: null,
						expiredAt: null,
						enabled: false,
						note: head.note,
						productId: head.productId,
						featureId: head.featureId
					};
					if (head.product) {
						item.product = head.product;
					}
					if (head.feature) {
						item.feature = head.feature;
					}
					let lastEnabledItem = _.chain(value).filter({ enabled: true }).orderBy(['period'], ['asc']).last().value();
					let lastDisabledItem = _.chain(value).filter({ enabled: false }).orderBy(['period'], ['asc']).last().value();
					if (lastEnabledItem) {
						let enabledPeriod = DateTime.fromISO(lastEnabledItem.period);
						let currentDate = DateTime.fromISO(this.natLoyaltyPolicyRangeByProductManagerStore.date.toISOString());
						item.startedAt = lastEnabledItem.period;
						if (lastDisabledItem) {
							let disabledPeriod = DateTime.fromISO(lastDisabledItem.period);
							if (disabledPeriod.diff(enabledPeriod).toObject().milliseconds > 0) {
								item.expiredAt = lastDisabledItem.period;
								if (currentDate.diff(disabledPeriod).toObject().milliseconds < 0 && currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
									item.enabled = true;
									item.note = lastEnabledItem.note;
								} else {
									item.note = lastDisabledItem.note;
								}
							} else {
								if (currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
									item.enabled = true;
									item.note = lastEnabledItem.note;
								}
							}
						} else {
							if (currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
								item.enabled = true;
								item.note = lastEnabledItem.note;
							}
						}
					} else {
						if (lastDisabledItem) {
							item.expiredAt = lastDisabledItem.period;
						}
					}
					result.push(item);
				}
			}, [])
			.value();
		this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'list', sources);
	}
	checkSourceStatus() {
		let currentDate: any = null;
		if (this.natLoyaltyPolicyRangeByProductManagerStore.date) {
			currentDate = DateTime.fromISO(this.natLoyaltyPolicyRangeByProductManagerStore.date.toISOString());
		}
		if (currentDate) {
			let list = _.cloneDeep(this.natLoyaltyPolicyRangeByProductManagerStore.list);
			for (let item of list) {
				if (item.startedAt) {
					let startedAt = DateTime.fromISO(item.startedAt);
					if (item.expiredAt) {
						let expiredAt = DateTime.fromISO(item.expiredAt);
						if (currentDate.diff(expiredAt).toObject().milliseconds < 0 && currentDate.diff(startedAt).toObject().milliseconds > 0) {
							runInAction(() => {
								item.enabled = true;
							});
						} else {
							runInAction(() => {
								item.enabled = false;
							});
						}
					} else {
						if (currentDate.diff(startedAt).toObject().milliseconds > 0) {
							runInAction(() => {
								item.enabled = true;
							});
						} else {
							runInAction(() => {
								item.enabled = false;
							});
						}
					}
				}
			}
			this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'list', list);
		}
	}
	toggleNatLoyaltyPolicyRangeStatusManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeStatusManagerOpen: status
		});
	}
	toggleSelection(status: boolean) {
		this.setState({
			isProductSelectionModalOpen: status
		});
	}
	saveEnablePolicyRanges() {
		let result: Array<any> = [];
		_.transform(
			this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels,
			(result: any, value: any) => {
				let policyRangeByProduct = {
					sourceId: value.sourceId,
					sourceType: value.sourceType,
					note: this.natLoyaltyPolicyRangeByProductManagerStore.note,
					enabled: true,
					period: this.natLoyaltyPolicyRangeByProductManagerStore.startedAt,
					productId: value.productId,
					featureId: value.featureId
				};
				result.push(policyRangeByProduct);
				if (this.natLoyaltyPolicyRangeByProductManagerStore.expiredAt) {
					let copyPolicyRangeByProduct = _.cloneDeep(policyRangeByProduct);
					copyPolicyRangeByProduct.enabled = false;
					copyPolicyRangeByProduct.period = this.natLoyaltyPolicyRangeByProductManagerStore.expiredAt;
					result.push(copyPolicyRangeByProduct);
				}
			},
			result
		);
		return controller.bulkUpsert('loyaltyPolicyRangeByProducts', result).then(() => {
			this.props.modelStore.setValue(this.props.modelStore, 'list', []);
			return this.getSource();
		});
	}
	saveDisablePolicyRanges() {
		let result: Array<any> = [];
		_.transform(
			this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels,
			(result: any, value: any) => {
				let policyRangeByProduct = {
					sourceId: value.sourceId,
					sourceType: value.sourceType,
					note: this.natLoyaltyPolicyRangeByProductManagerStore.note,
					enabled: false,
					period: this.natLoyaltyPolicyRangeByProductManagerStore.expiredAt,
					productId: value.productId,
					featureId: value.featureId
				};
				result.push(policyRangeByProduct);
			},
			result
		);
		return controller.bulkUpsert('loyaltyPolicyRangeByProducts', result).then(() => {
			this.props.modelStore.setValue(this.props.modelStore, 'list', []);
			return this.getSource();
		});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАКРЫТЬ',
				onClick: () => {
					this.props.toggleNatLoyaltyPolicyRangeByProductManager(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<CForm className="mx-5 mb-3">
						<CRow>
							<CCol lg="12">
								<CFormGroup row className="mb-0">
									<CCol md="4">
										<NatValueInput<Date> object={this.natLoyaltyPolicyRangeByProductManagerStore} property="date" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="На дату" />
									</CCol>
								</CFormGroup>
							</CCol>
						</CRow>
					</CForm>
				</CModalHeader>
				<div className="d-flex flex-column default__nat__card__body__height p-0 mx-4 mb-4">
					<NatControlPanel
						iconClassname="text-danger"
						className="nat__form__control__buttons nat__card__wrapper nat__borders__2-75 p-2 my-4"
						customControlItems={[
							<CButton
								key={generateRandomId()}
								variant="ghost"
								className="d-flex nat__button__hover mr-1"
								onClick={() => {
									this.toggleSelection(true);
								}}>
								ПОДБОР
							</CButton>,
							<NatDefaultRadio<string>
								key={generateRandomId()}
								options={[
									{ title: 'Все', value: 'all' },
									{ title: 'Где действуют', value: 'whereOperate' }
								]}
								inline={true}
								row={true}
								labelMd="3"
								optionsMd="9"
								formatter={stringFormatter()}
								object={this.natLoyaltyPolicyRangeByProductManagerStore}
								property="mode"
								label="Показывать"
								className="p-2"
							/>,
							<CDropdown key={generateRandomId()}>
								<CDropdownToggle className="mr-1 nat__button__hover" caret disabled={this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels.length === 0}>
									Установить статус
								</CDropdownToggle>
								<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
									<CDropdownItem
										onClick={() => {
											this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'status', 'enabled');
											this.toggleNatLoyaltyPolicyRangeStatusManager(true);
										}}>
										Действует
									</CDropdownItem>
									<CDropdownItem
										onClick={() => {
											this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'status', 'disabled');
											this.toggleNatLoyaltyPolicyRangeStatusManager(true);
										}}>
										Не действует
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
						]}
					/>
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1">
							<GridTable
								columns={this.state.columns}
								rows={this.natLoyaltyPolicyRangeByProductManagerStore.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								sort={{ colId: null }}
								isVirtualScroll={false}
								selectedRowsIds={_.map(this.natLoyaltyPolicyRangeByProductManagerStore.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => {
									this.collectModels(selectedRowsIds);
								}}
							/>
						</div>
					</div>
				</div>
				{this.natLoyaltyPolicyRangeByProductManagerStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.state.isLoyaltyPolicyRangeStatusManagerOpen && (
					<NatLoyaltyPolicyRangeStatusManager
						isModalOpen={this.state.isLoyaltyPolicyRangeStatusManagerOpen}
						toggleNatLoyaltyPolicyRangeStatusManager={this.toggleNatLoyaltyPolicyRangeStatusManager}
						modelStore={this.natLoyaltyPolicyRangeByProductManagerStore}
						saveEnablePolicyRanges={this.saveEnablePolicyRanges}
						saveDisablePolicyRanges={this.saveDisablePolicyRanges}
					/>
				)}
				{this.state.isProductSelectionModalOpen && (
					<ProductSelection
						toggleFeedSelection={this.toggleSelection}
						isModalOpen={this.state.isProductSelectionModalOpen}
						productItemsColumns={[
							{
								id: 2,
								field: 'featureId',
								label: 'Характеристика',
								width: '325px',
								checkCondition: (item: any) => {
									return catalog.checkFeaturesCondition(item);
								},
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div className="rgt-cell-inner ml-3 p-0">
											{(() => {
												if (data.product !== undefined) {
													if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
														return null;
													} else {
														return (
															<NatRelationInput
																object={data}
																property="featureId"
																relation="feature"
																placeholder="Выберите характеристику"
																type="text"
																size="sm"
																inputClassName="text-left"
																formatter={relationFormatter()}
																pluralName="productFeatures"
																autoComplete="off"
																apiMethod={(): any => {
																	return controller.fetchFeaturesByProduct({ product: data.product });
																}}
																create={(pluralName, handlers) => {
																	this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
																}}
															/>
														);
													}
												} else {
													return null;
												}
											})()}
										</div>
									);
								}
							}
						]}
						modelStore={this.natLoyaltyPolicyRangeByProductManagerStore}
						// makeReactions={this.props.makeReactions}
						productItemTemplate={{
							sourceType: this.props.modelStore.name,
							sourceId: this.props.modelStore.model.id,
							id: generateRandomId(),
							startedAt: null,
							expiredAt: null,
							enabled: false,
							note: null,
							productId: null,
							featureId: null
						}}
						saveProductItems={(productItems: any) => {
							this.natLoyaltyPolicyRangeByProductManagerStore.setValue(this.natLoyaltyPolicyRangeByProductManagerStore, 'collectedModels', productItems);
							this.toggleNatLoyaltyPolicyRangeStatusManager(true);
						}}
						notRecalculateAmount={true}
						dontDisplayStocksAndPrices={true}
						// dontDisplayFeatures={false}
						// formValidProductTypes={this.props.formValidProductTypes}
					/>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatLoyaltyPolicyRangeByProductManager);
