import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyPolicyType } from '../../shared/support/modelTypes';

export interface LoyaltyPolicyFormStoreType {
	model: LoyaltyPolicyType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class LoyaltyPolicyFormStore implements LoyaltyPolicyFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			filterInclude: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		loyaltyProgramId: null,
		loyaltyCardKindId: null,
		priceTypeId: null,
		managerId: null,
		jointUseOptionId: null,
		calcJointUseOptionId: null,
		productSelectionOptionId: 'loyalty_policy_product_selection_options.none',
		productSegmentId: null,
		methodOfProvidingId: null,
		methodOfApplyingId: 'loyalty_policy_methods_of_applying.apply_rules',
		validityPeriodId: 'periodicity.week',
		validityPeriodCount: 0,
		validityDelayPeriodId: 'periodicity.week',
		validityDelayPeriodCount: 0,
		policyValue: 0,
		messageText: null,
		roundingAccuracy: 2,
		roundingOptionId: 'rounding_options.by_arithmetic_rules',
		applyMultiplyToTheParentGroup: false,
		quantityDiscountCondition: 0,
		ruleItemList: [],
		ruleItems: [],
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		elementType: 0,
		parentId: null,
		methodOfApplying: {
			id: 'loyalty_policy_methods_of_applying.apply_rules',
			keyId: 'apply_rules',
			ownerId: 'loyalty_policy_methods_of_applying',
			name: {
				ru: 'Применить правила скидок (наценок)',
				id: 'd2dd42c0-8483-409f-b952-bcba2c8dc828'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'LoyaltyPolicyMethodOfApplyingApplyRules',
			predefinedVersion: 0
		},
		productSelectionOption: {
			id: 'loyalty_policy_product_selection_options.none',
			keyId: 'none',
			ownerId: 'loyalty_policy_product_selection_options',
			name: {
				ru: 'Без ограничений',
				id: '94a4b215-056f-40d8-91e4-5c4ccb968c83'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'LoyaltyPolicyProductSelectionOptionsNone',
			predefinedVersion: 0
		},
		validityPeriod: {
			id: 'periodicity.week',
			keyId: 'week',
			ownerId: 'periodicity',
			name: {
				ru: 'Неделя',
				id: '0aa76a8b-fde6-49da-819f-a986470864c1'
			},
			description: null,
			priority: 4,
			predefined: true,
			predefinedName: 'PeriodicityWeek',
			predefinedVersion: 0
		},
		validityDelayPeriod: {
			id: 'periodicity.week',
			keyId: 'week',
			ownerId: 'periodicity',
			name: {
				ru: 'Неделя',
				id: '0aa76a8b-fde6-49da-819f-a986470864c1'
			},
			description: null,
			priority: 4,
			predefined: true,
			predefinedName: 'PeriodicityWeek',
			predefinedVersion: 0
		},
		roundingOption: {
			id: 'rounding_options.by_arithmetic_rules',
			keyId: 'by_arithmetic_rules',
			ownerId: 'rounding_options',
			name: {
				ru: 'По арифметическим правилам',
				id: '1b2dff6f-2b70-44a0-b401-845322d11f8b'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'RoundingOptionsByArithmeticRules',
			predefinedVersion: 0
		},
		policyRanges: []
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'loyaltyPolicies';
	filterInclude = [
		'priceType',
		'jointUseOption',
		'calcJointUseOption',
		'productSelectionOption',
		'productSegment',
		'methodOfProviding',
		'methodOfApplying',
		'validityPeriod',
		'validityDelayPeriod',
		'roundingOption',
		'policyRanges',
		'parent',
		'manager',
		{
			relation: 'ruleItems',
			scope: {
				include: ['rule']
			}
		}
	];
	selectedItems = [] as Array<any>;
	searchText = '';
	requiredToFill = [
		{
			predefinedName: 'DefaultPriceType',
			property: 'priceTypeId',
			relation: 'priceType'
		}
	];
	isFirstLoad = true;
	name = 'OrmLoyaltyPolicy';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const loyaltyPolicyFormStore = new LoyaltyPolicyFormStore();

export default LoyaltyPolicyFormStore;
