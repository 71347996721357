import { BigNumber } from 'bignumber.js';
import _, { isEmpty } from 'lodash';
import { runInAction } from 'mobx';

import { NatRelationInputStoreType } from '../Inputs/InputsStore/NatRelationInputStore';
import { catalog } from '../support/Catalog';
import ValueFormatter, { ButtonItem } from './ValueFormatter';

class NumberRelationFormatter extends ValueFormatter<number, string> {
	public parseValue(value: string): number | undefined {
		value = value.replace(',', '.');
		if (isEmpty(value)) {
			value = '0';
		}
		return new BigNumber(parseFloat(value)).toNumber();
	}

	public renderValue(value: number, object: any): string {
		let valueStr: string = '';
		if (!_.isEmpty(object)) {
			let name = catalog.renderCatalogName(object);
			valueStr = `${new BigNumber(value).toString()} (${name})`;
		} else {
			valueStr = new BigNumber(value).toString();
		}
		return valueStr;
	}

	public getActionButtons(): Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType, relationProperty: string) => void>> {
		let actionButtons: Array<ButtonItem<(object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType, relationProperty: string) => void>> = [
			{
				icon: 'cil-x',
				title: 'Очистить поле',
				onClick: (object: any, property: string, relation: string, natRelationInputStore: NatRelationInputStoreType, relationProperty: string) => {
					runInAction(() => {
						object[property] = this.parseValue('');
					});
					runInAction(() => {
						object[relationProperty] = null;
						delete object[relation];
					});
					natRelationInputStore.setValue(natRelationInputStore, 'relations', []);
					natRelationInputStore.setValue(natRelationInputStore, 'currentPage', 0);
					natRelationInputStore.setValue(natRelationInputStore, 'showDropdown', false);
				}
			}
		];
		if (this.dontShowActionButtons) {
			actionButtons = [];
		}
		return actionButtons;
	}
}

export let numberRelationFormatter = new NumberRelationFormatter();

export default NumberRelationFormatter;
