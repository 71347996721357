import { CButton, CDropdown, CDropdownMenu, CDropdownToggle, CImg, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import product from '../../assets/product.jpg';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatRemoveBackgroundStore, { NatRemoveBackgroundStoreType } from './NatRemoveBackgroundStore';

interface PropsType extends RouteComponentProps {
	image: any;
	isModalOpen: boolean;
	upload(data: any): void;
	toggleModal(status: boolean): void;
}

interface StateType {}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class NatRemoveBackground extends Component<PropsType, StateType> {
	natRemoveBackgroundStore: NatRemoveBackgroundStoreType;
	constructor(props: PropsType) {
		super(props);
		this.natRemoveBackgroundStore = new NatRemoveBackgroundStore();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.natRemoveBackgroundStore.setValue(this.natRemoveBackgroundStore, 'isLoading', true);
		// controller
		// 	.download(this.props.image)
		// 	.then((response) => {
		// 		if (response.status === 200) {
		// 			let reader = new FileReader();
		// 			let image: { size: string; ['output_format']: string; ['image_file_b64']?: string } = {
		// 				size: 'full',
		// 				['output_format']: 'json'
		// 			};
		// 			reader.readAsDataURL(response.data as File);
		// 			let removedBackgroundImage: ImageType = {
		// 				name: this.props.image.id + '(Benzin)',
		// 				ownerId: this.props.image.ownerId,
		// 				ownerType: this.props.image.ownerType,
		// 				containerType: this.props.image.containerType,
		// 				containerId: this.props.image.containerId
		// 			};
		// 			reader.onloadend = () => {
		// 				image['image_file_b64'] = (reader.result! as string).substr((reader.result! as string).indexOf(',') + 1);
		// 				controller
		// 					.removeBackground(image)
		// 					.then((resp) => {
		// 						removedBackgroundImage.filename = `${this.props.image.id}(Benzin).${resp.data['image_type']}`;
		// 						removedBackgroundImage.file = resp.data['image_raw'];
		// 						this.injected.natRemoveBackgroundStore.setValue(this.injected.natRemoveBackgroundStore, 'isLoading', false);
		// 						this.injected.natRemoveBackgroundStore.setValue(this.injected.natRemoveBackgroundStore, 'benzinImage', resp.data);
		// 						this.injected.natRemoveBackgroundStore.setValue(this.injected.natRemoveBackgroundStore, 'image', removedBackgroundImage);
		// 					})
		// 					.catch((error) => {
		// 						catalog.handleNatError(error);
		// 						this.injected.natRemoveBackgroundStore.setValue(this.injected.natRemoveBackgroundStore, 'isLoading', false);
		// 					});
		// 			};
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		catalog.handleNatError(error);
		// 	});
	}
	render() {
		let controlButtons = (
			<>
				<CButton
					type="button"
					size="sm"
					className={this.injected.directoryStore.models.windowSize > 1084 ? 'mr-1 nat__button__hover' : 'nat__button__hover w-100 p-1 mb-1'}
					onClick={() => {
						// this.props.upload(this.injected.natRemoveBackgroundStore.image);
						this.props.toggleModal(false);
					}}>
					ЗАПИСАТЬ
				</CButton>
				<CButton
					type="button"
					size="sm"
					className={this.injected.directoryStore.models.windowSize > 1084 ? 'mr-1 nat__button__hover' : 'nat__button__hover w-100 p-1 mb-1'}
					onClick={() => {
						this.props.toggleModal(false);
					}}>
					ОТМЕНА
				</CButton>
			</>
		);
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{this.injected.directoryStore.models.windowSize > 1084 ? (
							controlButtons
						) : (
							<CDropdown>
								<CDropdownToggle caret className="nat__button__hover">
									МЕНЮ
								</CDropdownToggle>
								<CDropdownMenu placement="bottom-start" className="rounded-0 nat__dropdown__list">
									{controlButtons}
								</CDropdownMenu>
							</CDropdown>
						)}
					</div>
				</CModalHeader>
				<CModalBody className="pt-0">
					<CImg src={_.isEmpty(this.natRemoveBackgroundStore.benzinImage) ? product : `data:image/${this.natRemoveBackgroundStore.benzinImage['image_type']};base64,${this.natRemoveBackgroundStore.benzinImage['image_raw']}`} className="border" />
				</CModalBody>
				{this.natRemoveBackgroundStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatRemoveBackground);
