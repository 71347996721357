import CIcon from '@coreui/icons-react';
import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../../shared/Alerts/AlertDialog';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { ProductKindFormStoreType } from './ProductKindFormStore';

interface PropsType extends FormPropsType {
	productKindFormStore: ProductKindFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
}
interface StateType {}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class ProductKindForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.productKindFormStore}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				navTabs={[{ name: 'Характеристики', hidden: _.isEmpty(this.props.productKindFormStore.model.useFeatureOptionId) || this.props.productKindFormStore.model.useFeatureOptionId == 'use_feature_options.not_used' }, { name: 'Дополнительно' }]}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											if (_.get(this.props.productKindFormStore, 'isChanged', false)) {
												catalog.showAlert('Для добавления сохраните изменения!');
											} else {
												let data: any = null;
												if (this.props.productKindFormStore.model.useFeatureOptionId === 'use_feature_options.use_by_kind') {
													data = { ownerId: this.props.productKindFormStore.model.id, ownerType: 'OrmProductKind' };
												} else {
													data = null;
												}
												if (!_.isEmpty(data)) {
													this.props.goTo('/productFeatures/new', _.cloneDeep(data));
												}
											}
										},
										withoutIcon: true,
										disabled:
											_.isEmpty(this.props.productKindFormStore.model.useFeatureOptionId) || this.props.productKindFormStore.model.useFeatureOptionId == 'use_feature_options.not_used' || this.props.match.params.elementId === 'new'
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.toggleDeleteModal('productFeatures');
										},
										withoutIcon: true,
										disabled: this.props.productKindFormStore.collectedModels.length === 0
									}
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={[
											{
												id: 'checkbox',
												pinned: true,
												className: '',
												width: '54px',
												minResizeWidth: 0,
												maxResizeWidth: null,
												resizable: false,
												visible: true,
												headerCellRenderer: () => {
													return <div></div>;
												},
												cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
													return (
														<div className="rgt-cell-inner ml-0 mr-0">
															<input type="checkbox" id="checkbox" onChange={onChange} />
														</div>
													);
												}
											},
											{
												id: 1,
												field: 'deleted',
												label: '',
												width: '70px',
												cellRenderer: ({ data }: { data: any }) => {
													return <div className="rgt-cell-inner">{<CIcon src={catalog.pickupCatalogStateImage(data)} size="lg" />}</div>;
												},
												sort: () => {}
											},
											{
												id: 2,
												field: 'name',
												label: 'Имя',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{catalog.renderCatalogName(data)}
														</div>
													);
												},
												sort: () => {}
											}
										]}
										rows={this.props.productKindFormStore.productFeatures.list}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										onRowClick={({ data, column }: { data: any; column: any }) => {
											if (column.id !== 'checkbox') {
												this.props.goTo(`/${this.props.productKindFormStore.productFeatures.pluralName}/` + data.id);
											}
										}}
										selectedRowsIds={_.map(this.props.productKindFormStore.collectedModels, 'id')}
										onSelectedRowsChange={(selectedRowsIds: any) => {
											this.props.childrenProps.collectModels(selectedRowsIds, this.props.productKindFormStore.productFeatures.list);
										}}
									/>
								</form>
							</div>
							<AlertDialog
								isModalOpen={this.props.childrenProps.modals.includes('productFeatures')}
								title="Выполнить действие?"
								buttonItemList={[
									{
										title: 'Да',
										onClick: () => {
											this.props.childrenProps.toggleDeleteModal('productFeatures');
											this.props.productKindFormStore.setValue(this.props.productKindFormStore, 'isLoading', true);
											catalog
												.makeDeleted('productFeatures', this.props.productKindFormStore.collectedModels)
												.then(() => {
													this.props.productKindFormStore.setValue(this.props.productKindFormStore, 'collectedModels', []);
													return controller.fetchProductKindFeatures(this.props.productKindFormStore.model);
												})
												.then((data) => {
													this.props.productKindFormStore.setValue(this.props.productKindFormStore.productFeatures, 'list', []);
													if (!_.isEmpty(data)) {
														this.props.productKindFormStore.setValue(this.props.productKindFormStore.productFeatures, 'list', data);
													}
													this.props.productKindFormStore.setValue(this.props.productKindFormStore, 'isLoading', false);
												})
												.catch((error) => {
													catalog.handleNatError(error);
													this.props.productKindFormStore.setValue(this.props.productKindFormStore, 'isLoading', false);
												});
										}
									},
									{
										title: 'ОТМЕНА',
										onClick: () => {
											this.props.childrenProps.toggleDeleteModal('productFeatures');
										}
									}
								]}
							/>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.productKindFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.productKindFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.productKindFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="accountingOptionId"
										relation="accountingOption"
										placeholder="Выберите вариант"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'product_accounting_options' }}
										autoComplete="off"
										label="Вариант учета"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="unitId"
										relation="unit"
										placeholder="Выберите ед. изм."
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="productUnits"
										filterWhere={{ elementType: 0, deleted: false }}
										autoComplete="off"
										label="Ед. изм."
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="useFeatureOptionId"
										relation="useFeatureOption"
										placeholder="Выберите"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{
											ownerId: 'use_feature_options',
											id: { nin: ['use_feature_options.use_by_product'] }
										}}
										autoComplete="off"
										label="Использование характеристик"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="setPrintOptionId"
										relation="setPrintOption"
										placeholder="Выберите вариант печати"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'product_set_print_options' }}
										autoComplete="off"
										label="Вариант печати набора"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="setPriceCalculationMethodId"
										relation="setPriceCalculationMethod"
										placeholder="Выберите способ"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'product_set_price_calculation_methods' }}
										autoComplete="off"
										label="Способ расчета цены набора"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.productKindFormStore.model}
										property="cashRegisterTaxationTypeId"
										relation="cashRegisterTaxationType"
										placeholder="Выберите вариант"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'cash_register_taxation_types' }}
										autoComplete="off"
										label="Тип налогооблажения кассы ККТ"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.productKindFormStore.setValue(this.props.productKindFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.productKindFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.productKindFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.productKindFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(ProductKindForm));
