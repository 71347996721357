export let models = {
	ACL: {
		plural: null,
		displayName: null,
		pluralDisplayName: null
	},
	OrmAccessToken: {
		plural: 'accessTokens',
		displayName: 'Токен доступа',
		pluralDisplayName: 'Токены доступа'
	},
	OrmAcl: {
		plural: null,
		displayName: 'Cписок контроля доступа',
		pluralDisplayName: 'Списки контроля доступа'
	},
	OrmContainer: {
		plural: 'containers',
		displayName: null,
		pluralDisplayName: null
	},
	OrmEmail: {
		plural: null,
		displayName: 'Почтовый клиент',
		pluralDisplayName: 'Почтовые клиенты'
	},
	DatabaseParam: {
		plural: 'databaseParams',
		displayName: 'Параметр базы данных',
		pluralDisplayName: 'Параметры базы данных'
	},
	OrmConstant: {
		plural: 'constants',
		displayName: 'Константа',
		pluralDisplayName: 'Константы'
	},
	['OrmConstant_Change']: {
		plural: 'constants.changes',
		displayName: '"Константа" изменение',
		pluralDisplayName: '"Константы" изменения'
	},
	OrmAcquiringOperation: {
		plural: 'acquiringOperations',
		displayName: 'Эквайринговая операция',
		pluralDisplayName: 'Эквайринговые операции'
	},
	['OrmAcquiringOperation_Change']: {
		plural: 'acquiringOperations.changes',
		displayName: '"Эквайринговая операция" изменение',
		pluralDisplayName: '"Эквайринговые операции" изменения'
	},
	OrmBillingBalanceCorrection: {
		plural: 'billingBalanceCorrection',
		displayName: 'Корректировка платежного баланса',
		pluralDisplayName: 'Корректировки платежных балансов'
	},
	['OrmBillingBalanceCorrection_Change']: {
		plural: 'billingBalanceCorrection.changes',
		displayName: '"Корректировка платежного баланса" изменение',
		pluralDisplayName: '"Корректировки платежных балансов" изменения'
	},
	OrmBillingBalanceIncomeOperation: {
		plural: 'billingBalanceIncomeOperations',
		displayName: 'Поступление на платежный баланс',
		pluralDisplayName: 'Поступления на платежный баланс'
	},
	'[OrmBillingBalanceIncomeOperation_Change]': {
		plural: 'billingBalanceIncomeOperations.changes',
		displayName: '"Поступление на платежный баланс" изменение',
		pluralDisplayName: '"Поступления на платежный баланс" изменения'
	},
	OrmBillingBalanceOutcomeOperation: {
		plural: 'billingBalanceOutcomeOperations',
		displayName: 'Списание с платежного баланса',
		pluralDisplayName: 'Списания с платежного баланса'
	},
	['OrmBillingBalanceOutcomeOperation_Change']: {
		plural: 'billingBalanceOutcomeOperations.changes',
		displayName: '"Списание с платежного баланса" изменение',
		pluralDisplayName: '"Списания с платежного баланса" изменения'
	},
	OrmBonusPointCorrection: {
		plural: 'bonusPointCorrections',
		displayName: 'Корректировка бонусных баллов',
		pluralDisplayName: 'Корректировки бонусных баллов'
	},
	['OrmBonusPointCorrection_Change']: {
		plural: 'bonusPointCorrections.changes',
		displayName: '"Корректировка бонусных баллов" изменение',
		pluralDisplayName: '"Корректировки бонусных баллов" изменения'
	},
	OrmCashRegisterShift: {
		plural: 'cashRegisterShifts',
		displayName: 'Кассовая смена',
		pluralDisplayName: 'Кассовые смены'
	},
	['OrmCashRegisterShift_Change']: {
		plural: 'cashRegisterShifts.changes',
		displayName: '"Кассовая смена" изменение',
		pluralDisplayName: '"Кассовые смены" изменения'
	},
	OrmCashlessReceipt: {
		plural: 'cashlessReceipts',
		displayName: 'Поступление безналичных денежных средств',
		pluralDisplayName: 'Поступления безналичных денежных средств'
	},
	['OrmCashlessReceipt_Change']: {
		plural: 'cashlessReceipts.changes',
		displayName: '"Поступление безналичных денежных средств" изменение',
		pluralDisplayName: '"Поступления безналичных денежных средств" изменения'
	},
	OrmCashlessWriteOff: {
		plural: 'cashlessWriteOffs',
		displayName: 'Списание безналичных денежных средств',
		pluralDisplayName: 'Списания безналичных денежных средств'
	},
	['OrmCashlessWriteOff_Change']: {
		plural: 'cashlessWriteOffs.changes',
		displayName: '"Списание безналичных денежных средств" изменение',
		pluralDisplayName: '"Списания безналичных денежных средств" изменения'
	},
	OrmCheck: {
		plural: 'checks',
		displayName: 'Чек',
		pluralDisplayName: 'Чеки'
	},
	['OrmCheck_Change']: {
		plural: 'checks.changes',
		displayName: '"Чек" изменение',
		pluralDisplayName: '"Чеки" изменения'
	},
	OrmCheckRefund: {
		plural: 'checkRefunds',
		displayName: 'Чек возврат',
		pluralDisplayName: 'Чеки возврата'
	},
	['OrmCheckRefund_Change']: {
		plural: 'checkRefunds.changes',
		displayName: '"Чек возврат" изменение',
		pluralDisplayName: '"Чеки возврата" изменения'
	},
	OrmCustomerOrder: {
		plural: 'customerOrders',
		displayName: 'Заказ клиента',
		pluralDisplayName: 'Заказы клиентов'
	},
	['OrmCustomerOrder_Change']: {
		plural: 'customerOrders.changes',
		displayName: '"Заказ клиента" изменение',
		pluralDisplayName: '"Заказы клиентов" изменения'
	},
	OrmCashBox: {
		plural: 'cashBoxs',
		displayName: 'Касса',
		pluralDisplayName: 'Кассы'
	},
	['OrmCashBox_Change']: {
		plural: 'cashBoxs.changes',
		displayName: '"Касса" изменение',
		pluralDisplayName: '"Кассы" изменения'
	},
	OrmCashRegister: {
		plural: 'cashRegisters',
		displayName: 'Касса ККМ',
		pluralDisplayName: 'Кассы ККМ'
	},
	['OrmCashRegister_Change']: {
		plural: 'cashRegisters.changes',
		displayName: '"Касса ККМ" изменение',
		pluralDisplayName: '"Кассы ККМ" изменения'
	},
	OrmCashRegisterDistribution: {
		plural: 'cashRegisterDistributions',
		displayName: 'Распределение кассы ККМ',
		pluralDisplayName: 'Распределения касс ККМ'
	},
	['OrmCashRegisterDistribution_Change']: {
		plural: 'cashRegisterDistributions.changes',
		displayName: '"Распределение кассы ККМ" изменение',
		pluralDisplayName: '"Распределения касс ККМ" изменения'
	},
	OrmContactKind: {
		plural: 'contactKinds',
		displayName: 'Вид контактной информации',
		pluralDisplayName: 'Виды контактной информации'
	},
	['OrmContactKind_Change']: {
		plural: 'contactKinds.changes',
		displayName: '"Вид контактной информации" изменение',
		pluralDisplayName: '"Виды контактной информации" изменения'
	},
	OrmCoupon: {
		plural: 'coupons',
		displayName: 'Купон',
		pluralDisplayName: 'Купоны'
	},
	['OrmCoupon_Change']: {
		plural: 'coupons.changes',
		displayName: '"Купон" изменение',
		pluralDisplayName: '"Купоны" изменения'
	},
	OrmCouponKind: {
		plural: 'couponKinds',
		displayName: 'Вид купонов',
		pluralDisplayName: 'Виды купонов'
	},
	['OrmCouponKind_Change']: {
		plural: 'couponKinds.changes',
		displayName: '"Вид купонов" изменение',
		pluralDisplayName: '"Виды купонов" изменения'
	},
	OrmAclMapping: {
		plural: 'aclMappings',
		displayName: 'Соответствие ACL',
		pluralDisplayName: 'Соответствия ACL'
	},
	['OrmAclMapping_Change']: {
		plural: 'aclMappings.changes',
		displayName: '"Соответствие ACL" изменение',
		pluralDisplayName: '"Соответствия ACL" изменения'
	},
	OrmAssemblyState: {
		plural: 'assemblyStates',
		displayName: 'Состояние сборки',
		pluralDisplayName: 'Состояния сборки'
	},
	['OrmAssemblyState_Change']: {
		plural: 'assemblyStates.changes',
		displayName: '"Состояние сборки" изменение',
		pluralDisplayName: '"Состояния сборки" изменения'
	},
	OrmChangePlanObjectMatch: {
		plural: 'changePlanObjectMatches',
		displayName: 'Соответствие объекта плана обмена',
		pluralDisplayName: 'Соответствия объектов планов обменов'
	},
	OrmDeliveryState: {
		plural: 'deliveryStates',
		displayName: 'Состояние доставки',
		pluralDisplayName: 'Состояния доставок'
	},
	['OrmDeliveryState_Change']: {
		plural: 'deliveryStates.changes',
		displayName: '"Состояние доставки" изменение',
		pluralDisplayName: '"Состояния доставок" изменения'
	},
	OrmBillingBalancePoint: {
		plural: 'billingBalancePoints',
		displayName: 'Балл платежного баланса',
		pluralDisplayName: 'Баллы платежного баланса'
	},
	['OrmBillingBalancePoint_Change']: {
		plural: 'billingBalancePoints.changes',
		displayName: '"Балл платежного баланса" изменение',
		pluralDisplayName: '"Баллы платежного баланса" изменения'
	},
	OrmBonusPoint: {
		plural: 'bonusPoints',
		displayName: 'Бонусный балл',
		pluralDisplayName: 'Бонусные баллы'
	},
	['OrmBonusPoint_Change']: {
		plural: 'bonusPoints.changes',
		displayName: '"Бонусный балл" изменение',
		pluralDisplayName: '"Бонусные баллы" изменения'
	},
	OrmCouponUsage: {
		plural: 'couponUsages',
		displayName: 'Использование купонов',
		pluralDisplayName: 'Использования купонов'
	},
	['OrmCouponUsage_Change']: {
		plural: 'couponUsages.changes',
		displayName: '"Использование купонов" изменение',
		pluralDisplayName: '"Использования купонов" изменения'
	},
	OrmCashRegisterDistributionProductKindItem: {
		plural: null,
		displayName: 'Распределение по видам номенклатуры',
		pluralDisplayName: 'Распределения по видам номенклатуры'
	},
	OrmCustomerOrderContactItem: {
		plural: null,
		displayName: 'Контактная информация',
		pluralDisplayName: 'Список контактной информации'
	},
	OrmAcquiringOperationSpecificationItem: {
		plural: null,
		displayName: 'Уточнение платежа',
		pluralDisplayName: 'Уточнения платежей'
	},
	OrmBillingBalanceCorrectionIncomeItem: {
		plural: null,
		displayName: 'Начисление платежного баланса',
		pluralDisplayName: 'Начисления платежного баланса'
	},
	OrmBillingBalanceCorrectionOutcomeItem: {
		plural: null,
		displayName: 'Списание платежного баланса',
		pluralDisplayName: 'Списания платежного баланса'
	},
	OrmBillingBalanceOutcomeOperationSpecificationItem: {
		plural: null,
		displayName: 'Уточнение платежа',
		pluralDisplayName: 'Уточнения платежей'
	},
	OrmBonusPointCorrectionIncomeItem: {
		plural: null,
		displayName: 'Начисление бонусов',
		pluralDisplayName: 'Начисления бонусов'
	},
	OrmBonusPointCorrectionOutcomeItem: {
		plural: null,
		displayName: 'Списание бонусов',
		pluralDisplayName: 'Списания бонусов'
	},
	OrmCheckBonusPointIncomeItem: {
		plural: null,
		displayName: 'Начисленный бонус',
		pluralDisplayName: 'Начисленные бонусы'
	},
	OrmCheckLoyaltyPolicyItem: {
		plural: null,
		displayName: 'Политика лояльности',
		pluralDisplayName: 'Политики лояльности'
	},
	OrmCheckMarkingItem: {
		plural: null,
		displayName: 'Маркировка',
		pluralDisplayName: 'Маркировки'
	},
	OrmCheckPaymentItem: {
		plural: null,
		displayName: 'Оплата',
		pluralDisplayName: 'Оплаты'
	},
	OrmCheckPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmCheckProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmCheckRefundBonusPointIncomeItem: {
		plural: null,
		displayName: 'Списываемый бонусный балл',
		pluralDisplayName: 'Списываемые бонусные баллы'
	},
	OrmCheckRefundMarkingItem: {
		plural: null,
		displayName: 'Маркировка',
		pluralDisplayName: 'Маркировки'
	},
	OrmCheckRefundPaymentItem: {
		plural: null,
		displayName: 'Оплата',
		pluralDisplayName: 'Оплаты'
	},
	OrmCheckRefundPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmCheckRefundProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmCustomerOrderBonusPointIncomeItem: {
		plural: null,
		displayName: 'Начисленный бонусный балл',
		pluralDisplayName: 'Начисленные бонусные баллы'
	},
	OrmCustomerOrderContactSet: {
		plural: null,
		displayName: 'Набор контактной информации',
		pluralDisplayName: 'Наборы контактной информации'
	},
	OrmCustomerOrderLoyaltyPolicyItem: {
		plural: null,
		displayName: 'Политика лояльности',
		pluralDisplayName: 'Политики лояльности'
	},
	OrmCustomerOrderPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmCustomerOrderProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmEnumeration: {
		plural: 'enumerations',
		displayName: 'Группа перечислений',
		pluralDisplayName: 'Группы перечислений'
	},
	OrmEnumerationItem: {
		plural: 'enumerationItems',
		displayName: 'Перечисление',
		pluralDisplayName: 'Перечисления'
	},
	OrmExecutableItem: {
		plural: null,
		displayName: 'Исполняемый элемент',
		pluralDisplayName: 'Исполняемые элементы'
	},
	OrmExtraProperty: {
		plural: 'extraProperties',
		displayName: 'Дополнительное свойство',
		pluralDisplayName: 'Дополнительные свойства'
	},
	['OrmExtraProperty_Change']: {
		plural: 'extraProperties.changes',
		displayName: '"Дополнительное свойство" изменение',
		pluralDisplayName: '"Дополнительные свойства" изменения'
	},
	OrmExtraPropertySet: {
		plural: 'extraPropertySets',
		displayName: 'Набор доп. свойств',
		pluralDisplayName: 'Наборы доп. свойств'
	},
	['OrmExtraPropertySet_Change']: {
		plural: 'extraPropertySets.changes',
		displayName: '"Набор доп. свойств" изменение',
		pluralDisplayName: '"Наборы доп. свойств" изменения'
	},
	OrmExtraPropertySetPropertyItem: {
		plural: null,
		displayName: 'Свойство',
		pluralDisplayName: 'Свойства'
	},
	OrmExtraPropertyValue: {
		plural: 'extraPropertyValues',
		displayName: 'Значение',
		pluralDisplayName: 'Значения'
	},
	['OrmExtraPropertyValue_Change']: {
		plural: 'extraPropertyValues.changes',
		displayName: '"Значение" изменение',
		pluralDisplayName: '"Значения" изменения'
	},
	OrmFile: {
		plural: 'files',
		displayName: 'Файл',
		pluralDisplayName: 'Файлы'
	},
	['OrmFile_Change']: {
		plural: 'files.changes',
		displayName: '"Файл" изменение',
		pluralDisplayName: '"Файлы" изменения'
	},
	OrmFileMetadataItem: {
		plural: null,
		displayName: 'Метаданные файла',
		pluralDisplayName: 'Метаданные файлов'
	},
	OrmFiscalOperation: {
		plural: 'fiscalOperations',
		displayName: 'Фискальная операция',
		pluralDisplayName: 'Фискальные операции'
	},
	['OrmFiscalOperation_Change']: {
		plural: 'fiscalOperations.changes',
		displayName: '"Фискальная операция" изменение',
		pluralDisplayName: '"Фискальные операции" изменения'
	},
	OrmForm: {
		plural: 'forms',
		displayName: 'Форма',
		pluralDisplayName: 'Формы'
	},
	['OrmForm_Change']: {
		plural: 'forms.changes',
		displayName: '"Форма" изменение',
		pluralDisplayName: '"Формы" изменения'
	},
	OrmFormAnswer: {
		plural: 'formAnswers',
		displayName: 'Ответ формы',
		pluralDisplayName: 'Ответы форм'
	},
	['OrmFormAnswer_Change']: {
		plural: 'formAnswers.changes',
		displayName: '"Ответ формы" изменение',
		pluralDisplayName: '"Ответы форм" изменения'
	},
	OrmFormAnswerFieldItem: {
		plural: null,
		displayName: 'Поле ответа',
		pluralDisplayName: 'Поля ответа'
	},
	OrmFormFieldItem: {
		plural: null,
		displayName: 'Поле',
		pluralDisplayName: 'Поля'
	},
	OrmGiftCardPayment: {
		plural: 'giftCardPayments',
		displayName: 'Оплата подарочной картой',
		pluralDisplayName: 'Оплаты подарочными картами'
	},
	['OrmGiftCardPayment_Change']: {
		plural: 'giftCardPayments.changes',
		displayName: '"Оплата подарочной картой" изменение',
		pluralDisplayName: '"Оплаты подарочными картами" изменения'
	},
	OrmGiftCardValue: {
		plural: 'giftCardValues',
		displayName: 'Стоимость подарочной карты',
		pluralDisplayName: 'Стоимость подарочных карт'
	},
	['OrmGiftCardValue_Change']: {
		plural: 'giftCardValues.changes',
		displayName: '"Стоимость подарочной карты" изменение',
		pluralDisplayName: '"Стоимость подарочных карт" изменения'
	},
	OrmHtmlMetaTag: {
		plural: 'htmlMetaTags',
		displayName: 'Html мета тег',
		pluralDisplayName: 'Html мета теги'
	},
	['OrmHtmlMetaTag_Change']: {
		plural: 'htmlMetaTags.changes',
		displayName: '"Html мета тег" изменение',
		pluralDisplayName: '"Html мета теги" изменения'
	},
	OrmIdentityCard: {
		plural: 'identityCards',
		displayName: 'Идентификационная карта',
		pluralDisplayName: 'Идентификационные карты'
	},
	['OrmIdentityCard_Change']: {
		plural: 'identityCards.changes',
		displayName: '"Идентификационная карта" изменение',
		pluralDisplayName: '"Идентификационные карты" изменения'
	},
	OrmImageSize: {
		plural: 'imagesSizes',
		displayName: 'Размер изображения',
		pluralDisplayName: 'Размеры изображений'
	},
	['OrmImageSize_Change']: {
		plural: 'imagesSizes.changes',
		displayName: '"Размер изображения" изменение',
		pluralDisplayName: '"Размеры изображений" изменения'
	},
	OrmIncomeCashOrder: {
		plural: 'incomeCashOrders',
		displayName: 'Приходный кассовый ордер',
		pluralDisplayName: 'Приходные кассовые ордера'
	},
	['OrmIncomeCashOrder_Change']: {
		plural: 'incomeCashOrders.changes',
		displayName: '"Приходный кассовый ордер" изменение',
		pluralDisplayName: '"Приходные кассовые ордера" изменения'
	},
	OrmLabelTemplate: {
		plural: 'labelTemplates',
		displayName: 'Шаблон этикетки',
		pluralDisplayName: 'Шаблоны этикетки'
	},
	['OrmLabelTemplate_Change']: {
		plural: 'labelTemplates.changes',
		displayName: '"Шаблон этикетки" изменение',
		pluralDisplayName: '"Шаблоны этикетки" изменения'
	},
	OrmLayoutTemplate: {
		plural: 'layoutTemplates',
		displayName: 'Шаблон макета',
		pluralDisplayName: 'Шаблоны макетов'
	},
	['OrmLayoutTemplate_Change']: {
		plural: 'layoutTemplates.changes',
		displayName: '"Шаблон макета" изменение',
		pluralDisplayName: '"Шаблоны макетов" изменения'
	},
	OrmLocale: {
		plural: 'locales',
		displayName: 'Локаль',
		pluralDisplayName: 'Локали'
	},
	['OrmLocale_Change']: {
		plural: 'locales.changes',
		displayName: '"Локаль" изменение',
		pluralDisplayName: '"Локали" изменения'
	},
	OrmLocalizedString: {
		plural: null,
		displayName: 'Локализованная строка',
		pluralDisplayName: 'Локализованные строки'
	},
	OrmLoyaltyCard: {
		plural: 'loyaltyCards',
		displayName: 'Карта лояльности',
		pluralDisplayName: 'Карты лояльности'
	},
	['OrmLoyaltyCard_Change']: {
		plural: 'loyaltyCards.changes',
		displayName: '"Карта лояльности" изменение',
		pluralDisplayName: '"Карты лояльности" изменения'
	},
	OrmLoyaltyCardKind: {
		plural: 'loyaltyCardKinds',
		displayName: 'Вид карты лояльности',
		pluralDisplayName: 'Виды карт лояльности'
	},
	['OrmLoyaltyCardKind_Change']: {
		plural: 'loyaltyCardKinds.changes',
		displayName: '"Вид карты лояльности" изменение',
		pluralDisplayName: '"Виды карт лояльности" изменения'
	},
	OrmLoyaltyCardKindNumberTemplate: {
		plural: null,
		displayName: 'Шаблон нумерации',
		pluralDisplayName: 'Шаблоны нумерации'
	},
	OrmLoyaltyPolicy: {
		plural: 'loyaltyPolicies',
		displayName: 'Политика лояльности',
		pluralDisplayName: 'Политики лояльности'
	},
	['OrmLoyaltyPolicy_Change']: {
		plural: 'loyaltyPolicies.changes',
		displayName: '"Политика лояльности" изменение',
		pluralDisplayName: '"Политики лояльности" изменения'
	},
	OrmLoyaltyPolicyRange: {
		plural: 'loyaltyPolicyRanges',
		displayName: 'Действие политики лояльности',
		pluralDisplayName: 'Действия политик лояльности'
	},
	['OrmLoyaltyPolicyRange_Change']: {
		plural: 'loyaltyPolicyRanges.changes',
		displayName: '"Действие политики лояльности" изменение',
		pluralDisplayName: '"Действия политик лояльности" изменения'
	},
	OrmLoyaltyPolicyRangeByProduct: {
		plural: 'loyaltyPolicyRangeByProducts',
		displayName: 'Действие политики лояльности по номенклатуре',
		pluralDisplayName: 'Действия политик лояльности по номенклатуре'
	},
	['OrmLoyaltyPolicyRangeByProduct_Change']: {
		plural: 'loyaltyPolicyRangeByProducts.changes',
		displayName: '"Действие политики лояльности по номенклатуре" изменение',
		pluralDisplayName: '"Действия политик лояльности по номенклатуре" изменения'
	},
	OrmLoyaltyPolicyRule: {
		plural: 'loyaltyPolicyRules',
		displayName: 'Правило политики лояльности',
		pluralDisplayName: 'Правила политик лояльности'
	},
	['OrmLoyaltyPolicyRule_Change']: {
		plural: 'loyaltyPolicyRules.changes',
		displayName: '"Правило политики лояльности" изменение',
		pluralDisplayName: '"Правила политик лояльности" изменения'
	},
	OrmLoyaltyProgram: {
		plural: 'loyaltyPrograms',
		displayName: 'Программа лояльности',
		pluralDisplayName: 'Программы лояльности'
	},
	['OrmLoyaltyProgram_Change']: {
		plural: 'loyaltyPrograms.changes',
		displayName: '"Программа лояльности" изменение',
		pluralDisplayName: '"Программы лояльности" изменения'
	},
	OrmMovementOfProducts: {
		plural: 'movementOfProducts',
		displayName: 'Перемещение товаров',
		pluralDisplayName: 'Перемещения товаров'
	},
	['OrmMovementOfProducts_Change']: {
		plural: 'movementOfProducts.changes',
		displayName: '"Перемещение товаров" изменение',
		pluralDisplayName: '"Перемещения товаров" изменения'
	},
	OrmMovementOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmNaturalPerson: {
		plural: 'naturalPersons',
		displayName: 'Физическое лицо',
		pluralDisplayName: 'Физические лица'
	},
	['OrmNaturalPerson_Change']: {
		plural: 'naturalPersons.changes',
		displayName: '"Физическое лицо" изменение',
		pluralDisplayName: '"Физические лица" изменения'
	},
	OrmNewsPost: {
		plural: 'newsPosts',
		displayName: 'Новость',
		pluralDisplayName: 'Новости'
	},
	['OrmNewsPost_Change']: {
		plural: 'newsPosts.changes',
		displayName: '"Новость" изменение',
		pluralDisplayName: '"Новости" изменения'
	},
	OrmNumerator: {
		plural: 'numerators',
		displayName: 'Нумератор',
		pluralDisplayName: 'Нумераторы'
	},
	OrmNumeratorSequence: {
		plural: 'numeratorSequences',
		displayName: 'Последовательность нумератора',
		pluralDisplayName: 'Последовательности нумераторов'
	},
	OrmObjectExtraProperty: {
		plural: null,
		displayName: 'Дополнительное свойство',
		pluralDisplayName: 'Дополнительное свойство'
	},
	OrmObjectRegistry: {
		plural: 'objectRegistries',
		displayName: 'Реестр объекта',
		pluralDisplayName: 'Реестр объектов'
	},
	OrmOrganization: {
		plural: 'organizations',
		displayName: 'Организация',
		pluralDisplayName: 'Организации'
	},
	['OrmOrganization_Change']: {
		plural: 'organizations.changes',
		displayName: '"Организация" изменение',
		pluralDisplayName: '"Организации" изменения'
	},
	OrmOrganizationBankAccount: {
		plural: 'organizationBankAccounts',
		displayName: 'Банковский счет организации',
		pluralDisplayName: 'Банковские счета организаций'
	},
	['OrmOrganizationBankAccount_Change']: {
		plural: 'organizationBankAccounts.changes',
		displayName: '"Банковский счет организации" изменение',
		pluralDisplayName: '"Банковские счета организаций" изменения'
	},
	OrmOrganizationContact: {
		plural: 'organizationContacts',
		displayName: 'Контакт организации',
		pluralDisplayName: 'Контакты организаций'
	},
	['OrmOrganizationContact_Change']: {
		plural: 'organizationContacts.changes',
		displayName: '"Контакт организации" изменение',
		pluralDisplayName: '"Контакты организаций" изменения'
	},
	OrmOutcomeCashOrder: {
		plural: 'outcomeCashOrders',
		displayName: 'Расходный кассовый ордер',
		pluralDisplayName: 'Расходные кассовые ордера'
	},
	['OrmOutcomeCashOrder_Change']: {
		plural: 'outcomeCashOrders.changes',
		displayName: '"Расходный кассовый ордер" изменение',
		pluralDisplayName: '"Расходные кассовые ордера" изменения'
	},
	OrmPartner: {
		plural: 'partners',
		displayName: 'Партнер',
		pluralDisplayName: 'Партнеры'
	},
	['OrmPartner_Change']: {
		plural: 'partners.changes',
		displayName: '"Партнер" изменение',
		pluralDisplayName: '"Партнеры" изменения'
	},
	OrmPartnerBankAccount: {
		plural: 'partnerBankAccounts',
		displayName: 'Банковский счет парнера',
		pluralDisplayName: 'Банковские счета партнеров'
	},
	['OrmPartnerBankAccount_Change']: {
		plural: 'partnerBankAccounts.changes',
		displayName: '"Банковский счет парнера" изменение',
		pluralDisplayName: '"Банковские счета партнеров" изменения'
	},
	OrmPartnerContact: {
		plural: 'partnerContacts',
		displayName: 'Контактная информация',
		pluralDisplayName: 'Список контактной информации'
	},
	['OrmPartnerContact_Change']: {
		plural: 'partnerContacts.changes',
		displayName: '"Контактная информация" изменение',
		pluralDisplayName: '"Список контактной информации" изменения'
	},
	OrmPartnerContactSet: {
		plural: 'partnerContactSets',
		displayName: 'Набор контактной информации',
		pluralDisplayName: 'Наборы контактной информации'
	},
	['OrmPartnerContactSet_Change']: {
		plural: 'partnerContactSets.changes',
		displayName: '"Набор контактной информации" изменение',
		pluralDisplayName: '"Наборы контактной информации" изменения'
	},
	OrmPartnerContract: {
		plural: 'partnerContracts',
		displayName: 'Договор партнера',
		pluralDisplayName: 'Договоры партнеров'
	},
	['OrmPartnerContract_Change']: {
		plural: 'partnerContracts.changes',
		displayName: '"Договор партнера" изменение',
		pluralDisplayName: '"Договоры партнеров" изменения'
	},
	OrmPartnerSegment: {
		plural: 'partnerSegments',
		displayName: 'Сегмент партнеров',
		pluralDisplayName: 'Сегменты партнеров'
	},
	['OrmPartnerSegment_Change']: {
		plural: 'partnerSegments.changes',
		displayName: '"Сегмент партнеров" изменение',
		pluralDisplayName: '"Сегменты партнеров" изменения'
	},
	OrmPartnerSegmentMapping: {
		plural: 'partnerSegmentMappings',
		displayName: 'Партнер в сегменте',
		pluralDisplayName: 'Партнеры в сегментах'
	},
	['OrmPartnerSegmentMapping_Change']: {
		plural: 'partnerSegmentMappings.changes',
		displayName: '"Партнер в сегменте" изменение',
		pluralDisplayName: '"Партнеры в сегментах" изменения'
	},
	OrmPartnerSettlement: {
		plural: 'partnerSettlements',
		displayName: 'Взаиморасчет с партнером',
		pluralDisplayName: 'Взаиморасчеты с партнерами'
	},
	['OrmPartnerSettlement_Change']: {
		plural: 'partnerSettlements.changes',
		displayName: '"Взаиморасчет с партнером" изменение',
		pluralDisplayName: '"Взаиморасчеты с партнерами" изменения'
	},
	OrmPartnerSettlementOperation: {
		plural: 'partnerSettlementOperations',
		displayName: 'Операция по взаиморасчетам',
		pluralDisplayName: 'Операции по взаиморасчетам'
	},
	OrmPartnerSubscription: {
		plural: 'partnerSubscriptions',
		displayName: 'Подписка контрагента',
		pluralDisplayName: 'Подписки контрагентов'
	},
	['OrmPartnerSubscription_Change']: {
		plural: 'partnerSubscriptions.changes',
		displayName: '"Подписка контрагента" изменение',
		pluralDisplayName: '"Подписки контрагентов" изменения'
	},
	OrmPartnerSubscriptionOperation: {
		plural: 'partnerSubscriptionOperations',
		displayName: 'Операция с подпиской',
		pluralDisplayName: 'Операции с подпиской'
	},
	['OrmPartnerSubscriptionOperation_Change']: {
		plural: 'partnerSubscriptionOperations.changes',
		displayName: '"Операция с подпиской" изменение',
		pluralDisplayName: '"Операции с подпиской" изменения'
	},
	OrmPartnerSubscriptionOperationProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmPartnerSubscriptionOption: {
		plural: 'OrmPartnerSubscriptionOptions',
		displayName: 'Опция подписки контрагента',
		pluralDisplayName: 'Опции подписки контрагентов'
	},
	['OrmPartnerSubscriptionOption_Change']: {
		plural: 'OrmPartnerSubscriptionOptions.changes',
		displayName: '"Опция подписки контрагента" изменение',
		pluralDisplayName: '"Опции подписки контрагентов" изменения'
	},
	OrmPaymentOption: {
		plural: 'paymentOptions',
		displayName: 'Способ оплаты',
		pluralDisplayName: 'Способы оплаты'
	},
	['OrmPaymentOption_Change']: {
		plural: 'paymentOptions.changes',
		displayName: '"Способ оплаты" изменение',
		pluralDisplayName: '"Способы оплаты" изменения'
	},
	OrmPaymentOptionArgumentItem: {
		plural: null,
		displayName: 'Аргумент',
		pluralDisplayName: 'Аргументы'
	},
	OrmPaymentOptionKind: {
		plural: 'paymentOptionKinds',
		displayName: 'Вид способа оплаты',
		pluralDisplayName: 'Виды способов оплаты'
	},
	['OrmPaymentOptionKind_Change']: {
		plural: 'paymentOptionKinds.changes',
		displayName: '"Вид способа оплаты" изменение',
		pluralDisplayName: '"Виды способов оплаты" изменения'
	},
	OrmPaymentOptionKindArgumentItem: {
		plural: null,
		displayName: 'Аргумент',
		pluralDisplayName: 'Аргументы'
	},
	OrmPostingOfProducts: {
		plural: 'postingOfProducts',
		displayName: 'Оприходование товаров',
		pluralDisplayName: 'Оприходования товаров'
	},
	['OrmPostingOfProducts_Change']: {
		plural: 'postingOfProducts.changes',
		displayName: '"Оприходование товаров" изменение',
		pluralDisplayName: '"Оприходования товаров" изменения'
	},
	OrmPostingOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmPriceType: {
		plural: 'priceTypes',
		displayName: 'Тип цены',
		pluralDisplayName: 'Типы цен'
	},
	['OrmPriceType_Change']: {
		plural: 'priceTypes.changes',
		displayName: '"Тип цены" изменение',
		pluralDisplayName: '"Типы цен" изменения'
	},
	OrmProduct: {
		plural: 'products',
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	['OrmProduct_Change']: {
		plural: 'products.changes',
		displayName: '"Номенклатура" изменение',
		pluralDisplayName: '"Номенклатура" изменения'
	},
	OrmProductBarcode: {
		plural: 'productBarcodes',
		displayName: 'Штрихкод',
		pluralDisplayName: 'Штрихкоды'
	},
	['OrmProductBarcode_Change']: {
		plural: 'productBarcodes.changes',
		displayName: '"Штрихкод" изменение',
		pluralDisplayName: '"Штрихкоды" изменения'
	},
	OrmProductFeature: {
		plural: 'productFeatures',
		displayName: 'Характеристика номенклатуры',
		pluralDisplayName: 'Характеристики номенклатуры'
	},
	['OrmProductFeature_Change']: {
		plural: 'productFeatures.changes',
		displayName: '"Характеристика номенклатуры" изменение',
		pluralDisplayName: '"Характеристики номенклатуры" изменения'
	},
	OrmProductInStock: {
		plural: 'productInStocks',
		displayName: 'Товар на складе',
		pluralDisplayName: 'Товары на складах'
	},
	['OrmProductInStock_Change']: {
		plural: 'productInStocks.changes',
		displayName: '"Товар на складе" изменение',
		pluralDisplayName: '"Товары на складах" изменения'
	},
	OrmProductKind: {
		plural: 'productKinds',
		displayName: 'Вид номенклатуры',
		pluralDisplayName: 'Виды номенклатуры'
	},
	['OrmProductKind_Change']: {
		plural: 'productKinds.changes',
		displayName: '"Вид номенклатуры" изменение',
		pluralDisplayName: '"Виды номенклатуры" изменения'
	},
	OrmProductPrice: {
		plural: 'productPrices',
		displayName: 'Цена номенклатуры',
		pluralDisplayName: 'Цены номенклатуры'
	},
	['OrmProductPrice_Change']: {
		plural: 'productPrices.changes',
		displayName: '"Цена номенклатуры" изменение',
		pluralDisplayName: '"Цены номенклатуры" изменения'
	},
	OrmProductSegment: {
		plural: 'productSegments',
		displayName: 'Сегмент номенклатуры',
		pluralDisplayName: 'Сегменты номенклатуры'
	},
	['OrmProductSegment_Change']: {
		plural: 'productSegments.changes',
		displayName: '"Сегмент номенклатуры" изменение',
		pluralDisplayName: '"Сегменты номенклатуры" изменения'
	},
	OrmProductSegmentMapping: {
		plural: 'productSegmentMappings',
		displayName: 'Номенклатура в сегменте',
		pluralDisplayName: 'Номенклатура в сегментах'
	},
	['OrmProductSegmentMapping_Change']: {
		plural: 'productSegmentMappings.changes',
		displayName: '"Номенклатура в сегменте" изменение',
		pluralDisplayName: '"Номенклатура в сегментах" изменения'
	},
	OrmProductSetMapping: {
		plural: 'productSetMappings',
		displayName: 'Состав набора',
		pluralDisplayName: 'Составы наборов'
	},
	['OrmProductSetMapping_Change']: {
		plural: 'productSetMappings.changes',
		displayName: '"Состав набора" изменение',
		pluralDisplayName: '"Составы наборов" изменения'
	},
	OrmProductSpecificationItem: {
		plural: null,
		displayName: 'Характеристика номенклатуры',
		pluralDisplayName: 'Характеристики номенклатуры'
	},
	OrmProductSpecificationSet: {
		plural: 'productSpecificationSets',
		displayName: 'Набор характеристик номенклатуры',
		pluralDisplayName: 'Наборы характеристик номенклатуры'
	},
	['OrmProductSpecificationSet_Change']: {
		plural: 'productSpecificationSets.changes',
		displayName: '"Набор характеристик номенклатуры" изменение',
		pluralDisplayName: '"Наборы характеристик номенклатуры" изменения'
	},
	OrmProductSpecificationSetPropertyItem: {
		plural: null,
		displayName: 'Свойство',
		pluralDisplayName: 'Свойства'
	},
	OrmProductUnit: {
		plural: 'productUnits',
		displayName: 'Единица измерения',
		pluralDisplayName: 'Единицы измерения'
	},
	['OrmProductUnit_Change']: {
		plural: 'productUnits.changes',
		displayName: '"Единица измерения" изменение',
		pluralDisplayName: '"Единицы измерения" изменения'
	},
	OrmProductUnitPackageSet: {
		plural: 'productUnitPackageSets',
		displayName: 'Набор единиц измерения',
		pluralDisplayName: 'Наборы единиц измерения'
	},
	['OrmProductUnitPackageSet_Change']: {
		plural: 'productUnitPackageSets.changes',
		displayName: '"Набор единиц измерения" изменение',
		pluralDisplayName: '"Наборы единиц измерения" изменения'
	},
	OrmValueMetadataItem: {
		plural: null,
		displayName: 'Метаданные значения',
		pluralDisplayName: 'Метаданные значений'
	},
	OrmPropertyMetadataItem: {
		plural: null,
		displayName: 'Метаданные свойства',
		pluralDisplayName: 'Метаданные свойств'
	},
	OrmReceiptOfProducts: {
		plural: 'receiptOfProducts',
		displayName: 'Приходная накладная',
		pluralDisplayName: 'Приходные накладные'
	},
	['OrmReceiptOfProducts_Change']: {
		plural: 'receiptOfProducts.changes',
		displayName: '"Приходная накладная" изменение',
		pluralDisplayName: '"Приходные накладные" изменения'
	},
	OrmReceiptOfProductsPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmReceiptOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmRecountOfProducts: {
		plural: 'recountOfProducts',
		displayName: 'Пересчет товаров',
		pluralDisplayName: 'Пересчеты товаров'
	},
	['OrmRecountOfProducts_Change']: {
		plural: 'recountOfProducts.changes',
		displayName: '"Пересчет товаров" изменение',
		pluralDisplayName: '"Пересчеты товаров" изменения'
	},
	OrmRecountOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmRefundOfProducts: {
		plural: 'refundOfProducts',
		displayName: 'Возврат товаров',
		pluralDisplayName: 'Возвраты товаров'
	},
	['OrmRefundOfProducts_Change']: {
		plural: 'refundOfProducts.changes',
		displayName: '"Возврат товаров" изменение',
		pluralDisplayName: '"Возвраты товаров" изменения'
	},
	OrmRefundOfProductsBonusPointIncomeItem: {
		plural: null,
		displayName: 'Начисленный бонус',
		pluralDisplayName: 'Начисленные бонусы'
	},
	OrmRefundOfProductsBonusPointOutcomeItem: {
		plural: null,
		displayName: 'Списанный бонус',
		pluralDisplayName: 'Списанные бонусы'
	},
	OrmRefundOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmRegisterCorrection: {
		plural: 'registerCorrections',
		displayName: 'Корректировка регистров',
		pluralDisplayName: 'Корректировки регистров'
	},
	OrmRegisterCorrectionBonusPointItem: {
		plural: null,
		displayName: 'Табличная часть "Бонусные баллы"',
		pluralDisplayName: 'Табличные части "Бонусные баллы"'
	},
	OrmRegisterCorrectionCouponUsageItem: {
		plural: null,
		displayName: 'Табличная часть "Использования купонов"',
		pluralDisplayName: 'Табличные части "Использования купонов"'
	},
	OrmRegisterCorrectionGiftCardPaymentItem: {
		plural: null,
		displayName: 'Табличная часть "Оплаты подарочными картами"',
		pluralDisplayName: 'Табличные части "Оплаты подарочными картами"'
	},
	OrmRegisterCorrectionGiftCardValueItem: {
		plural: null,
		displayName: 'Табличная часть "Стоимость подарочных карт"',
		pluralDisplayName: 'Табличные части "Стоимость подарочных карт"'
	},
	OrmRegisterCorrectionPartnerSettlementItem: {
		plural: null,
		displayName: 'Табличная часть "Взаиморасчеты с партнерами"',
		pluralDisplayName: 'Табличные части "Взаиморасчеты с партнерами"'
	},
	OrmRegisterCorrectionPartnerSubscriptionItem: {
		plural: null,
		displayName: 'Табличная часть "Подписки контрагентов"',
		pluralDisplayName: 'Табличные части "Подписки контрагентов"'
	},
	OrmRegisterCorrectionPartnerSubscriptionOptionItem: {
		plural: null,
		displayName: 'Табличная часть "Опции подписки контрагентов"',
		pluralDisplayName: 'Табличные части "Опции подписки контрагентов"'
	},
	OrmRegisterCorrectionProductInStockItem: {
		plural: null,
		displayName: 'Табличная часть "Товары на складах"',
		pluralDisplayName: 'Табличные части "Товары на складах"'
	},
	OrmRegisterCorrectionProductPriceItem: {
		plural: null,
		displayName: 'Табличная часть "Цены номенклатуры"',
		pluralDisplayName: 'Табличные части "Цены номенклатуры"'
	},
	['OrmRegisterCorrection_Change']: {
		plural: 'registerCorrections.changes',
		displayName: '"Корректировка регистров" изменение',
		pluralDisplayName: '"Корректировки регистров" изменения'
	},
	OrmReplicationChangePlan: {
		plural: 'replicationChangePlans',
		displayName: 'Полный план обмена',
		pluralDisplayName: 'Полные планы обмены'
	},
	OrmReport: {
		plural: 'reports',
		displayName: 'Отчет',
		pluralDisplayName: 'Отчеты'
	},
	['OrmReport_Change']: {
		plural: 'reports.changes',
		displayName: '"Отчет" изменение',
		pluralDisplayName: '"Отчеты" изменения'
	},
	OrmReportPreset: {
		plural: 'reportPresets',
		displayName: 'Вариант отчета',
		pluralDisplayName: 'Варианты отчетов'
	},
	['OrmReportPreset_Change']: {
		plural: 'reportPresets.changes',
		displayName: '"Вариант отчета" изменение',
		pluralDisplayName: '"Варианты отчетов" изменения'
	},
	OrmReservationOfProducts: {
		plural: 'reservationOfProducts',
		displayName: 'Резервирование товаров',
		pluralDisplayName: 'Резервирования товаров'
	},
	['OrmReservationOfProducts_Change']: {
		plural: 'reservationOfProducts.changes',
		displayName: '"Резервирование товаров" изменение',
		pluralDisplayName: '"Резервирования товаров" изменения'
	},
	OrmReservationOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	OrmResponsiblePerson: {
		plural: 'responsiblePersons',
		displayName: 'Ответственное лицо',
		pluralDisplayName: 'Ответственные лица'
	},
	['OrmResponsiblePerson_Change']: {
		plural: 'responsiblePersons.changes',
		displayName: '"Ответственное лицо" изменение',
		pluralDisplayName: '"Ответственные лица" изменения'
	},
	OrmSale: {
		plural: 'sales',
		displayName: 'Продажа',
		pluralDisplayName: 'Продажи'
	},
	['OrmSale_Change']: {
		plural: 'sales.changes',
		displayName: '"Продажа" изменение',
		pluralDisplayName: '"Продажи" изменения'
	},
	OrmSaleOfProducts: {
		plural: 'saleOfProducts',
		displayName: 'Расходная накладная',
		pluralDisplayName: 'Расходные накладные'
	},
	['OrmSaleOfProducts_Change']: {
		plural: 'saleOfProducts.changes',
		displayName: '"Расходная накладная" изменение',
		pluralDisplayName: '"Расходные накладные" изменения'
	},
	OrmSaleOfProductsBonusPointIncomeItem: {
		plural: null,
		displayName: 'Начисленный бонусный балл',
		pluralDisplayName: 'Начисленные бонусные баллы'
	},
	OrmSaleOfProductsLoyaltyPolicyItem: {
		plural: null,
		displayName: 'Политика лояльности',
		pluralDisplayName: 'Политики лояльности'
	},
	OrmSaleOfProductsPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmSaleOfProductsProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmSalesReport: {
		plural: 'salesReports',
		displayName: 'Отчет о розничных продажах',
		pluralDisplayName: 'Отчеты о розничных продажах'
	},
	['OrmSalesReport_Change']: {
		plural: 'salesReports.changes',
		displayName: '"Отчет о розничных продажах" изменение',
		pluralDisplayName: '"Отчеты о розничных продажах" изменения'
	},
	OrmSalesReportBonusPointIncomeItem: {
		plural: null,
		displayName: 'Начисленный бонус',
		pluralDisplayName: 'Начисленные бонусы'
	},
	OrmSalesReportBonusPointOutcomeItem: {
		plural: null,
		displayName: 'Списанный бонус',
		pluralDisplayName: 'Списанные бонусы'
	},
	OrmSalesReportCouponUsageItem: {
		plural: null,
		displayName: 'Использование купонов',
		pluralDisplayName: 'Использования купонов'
	},
	OrmSalesReportPaymentItem: {
		plural: null,
		displayName: 'Оплата',
		pluralDisplayName: 'Оплаты'
	},
	OrmSalesReportPrepayItem: {
		plural: null,
		displayName: 'Предоплата',
		pluralDisplayName: 'Предоплаты'
	},
	OrmSalesReportProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmSamplingSchemeAggregateFieldItem: {
		plural: null,
		displayName: 'Агрегированное поле',
		pluralDisplayName: 'Агрегированные поля'
	},
	OrmSamplingSchemeDataSetItem: {
		plural: null,
		displayName: 'Набор данных',
		pluralDisplayName: 'Наборы данных'
	},
	OrmSamplingSchemeFieldItem: {
		plural: null,
		displayName: 'Поле',
		pluralDisplayName: 'Поля'
	},
	OrmSamplingSchemeItem: {
		plural: null,
		displayName: 'Схема выборки данных',
		pluralDisplayName: 'Схемы выборки данных'
	},
	OrmSamplingSchemeParameterFieldItem: {
		plural: null,
		displayName: 'Параметр',
		pluralDisplayName: 'Параметры'
	},
	OrmSamplingSchemePresetConditionFieldItem: {
		plural: null,
		displayName: 'Условие',
		pluralDisplayName: 'Условия'
	},
	OrmSamplingSchemePresetGroupFieldItem: {
		plural: null,
		displayName: 'Элемент группировки',
		pluralDisplayName: 'Элементы группировки'
	},
	OrmSamplingSchemePresetItem: {
		plural: null,
		displayName: 'Вариант настроек схемы выборки данных',
		pluralDisplayName: 'Варианты настроек схемы выборки данных'
	},
	OrmSamplingSchemePresetOrderFieldItem: {
		plural: null,
		displayName: 'Элемент сортировки',
		pluralDisplayName: 'Элементы сортировки'
	},
	OrmSamplingSchemePresetParameterFieldItem: {
		plural: null,
		displayName: 'Параметр',
		pluralDisplayName: 'Параметры'
	},
	OrmSamplingSchemePresetSelectedFieldItem: {
		plural: null,
		displayName: 'Отображаемое поле',
		pluralDisplayName: 'Отображаемые поля'
	},
	OrmSamplingSchemePresetStructureListFieldItem: {
		plural: null,
		displayName: 'Поле структуры группировки списком',
		pluralDisplayName: 'Поля структуры группировки списком'
	},
	OrmSerialNumber: {
		plural: 'serialNumbers',
		displayName: 'Серийный номер',
		pluralDisplayName: 'Серийные номера'
	},
	['OrmSerialNumber_Change']: {
		plural: 'serialNumbers.changes',
		displayName: '"Серийный номер" изменение',
		pluralDisplayName: '"Серийные номера" изменения'
	},
	OrmSerialNumberInStock: {
		plural: 'serialNumberInStocks',
		displayName: 'Серийный номер в наличии',
		pluralDisplayName: 'Серийные номера в наличии'
	},
	['OrmSerialNumberInStock_Change']: {
		plural: 'serialNumberInStocks.changes',
		displayName: '"Серийный номер в наличии" изменение',
		pluralDisplayName: '"Серийные номера в наличии" изменения'
	},
	OrmSettingPricesOfProducts: {
		plural: 'settingPricesOfProducts',
		displayName: 'Установка цен на номенклатуру',
		pluralDisplayName: 'Установки цен на номенклатуру'
	},
	['OrmSettingPricesOfProducts_Change']: {
		plural: 'settingPricesOfProducts.changes',
		displayName: '"Установка цен на номенклатуру" изменение',
		pluralDisplayName: '"Установки цен на номенклатуру" изменения'
	},
	OrmSettingPricesOfProductsPriceTypeItem: {
		plural: null,
		displayName: 'Использованный тип цены',
		pluralDisplayName: 'Использованные типы цен'
	},
	OrmSettingPricesOfProductsProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmShoppingCart: {
		plural: 'shoppingCarts',
		displayName: 'Корзина клиента',
		pluralDisplayName: 'Корзины клиентов'
	},
	['OrmShoppingCart_Change']: {
		plural: 'shoppingCarts.changes',
		displayName: '"Корзина клиента" изменение',
		pluralDisplayName: '"Корзины клиентов" изменения'
	},
	OrmShoppingCartProductItem: {
		plural: null,
		displayName: 'Цена',
		pluralDisplayName: 'Цены'
	},
	OrmStore: {
		plural: 'stores',
		displayName: 'Магазин',
		pluralDisplayName: 'Магазины'
	},
	['OrmStore_Change']: {
		plural: 'stores.changes',
		displayName: '"Магазин" изменение',
		pluralDisplayName: '"Магазины" изменения'
	},
	OrmStoreStructureItem: {
		plural: null,
		displayName: 'Единица структуры магазина',
		pluralDisplayName: 'Единицы структуры магазина'
	},
	OrmSubscriptionOption: {
		plural: 'subscriptionOptions',
		displayName: 'Опция подписки',
		pluralDisplayName: 'Опции подписок'
	},
	['OrmSubscriptionOption_Change']: {
		plural: 'subscriptionOptions.changes',
		displayName: '"Опция подписки" изменение',
		pluralDisplayName: '"Опции подписок" изменения'
	},
	OrmSubscriptionPlan: {
		plural: 'subscriptionPlans',
		displayName: 'План подписки',
		pluralDisplayName: 'Планы подписок'
	},
	['OrmSubscriptionPlan_Change']: {
		plural: 'subscriptionPlans.changes',
		displayName: '"План подписки" изменение',
		pluralDisplayName: '"Планы подписок" изменения'
	},
	OrmSubscriptionPlanProductItem: {
		plural: null,
		displayName: 'Номенклатура',
		pluralDisplayName: 'Номенклатура'
	},
	OrmTimeInterval: {
		plural: null,
		displayName: 'Временной интервал',
		pluralDisplayName: 'Временные интервалы'
	},
	OrmUnknownProductItem: {
		plural: null,
		displayName: 'Неизвестная номенклатура',
		pluralDisplayName: 'Неизвестные номенклатуры'
	},
	OrmUserCredential: {
		plural: 'userCredentials',
		displayName: 'Данные авторизации',
		pluralDisplayName: 'Список данных авторизации'
	},
	OrmUserIdentity: {
		plural: 'userIdentities',
		displayName: 'Маркер пользователя',
		pluralDisplayName: 'Маркеры пользователей'
	},
	OrmWarehouse: {
		plural: 'warehouses',
		displayName: 'Склад',
		pluralDisplayName: 'Склады'
	},
	['OrmWarehouse_Change']: {
		plural: 'warehouses.changes',
		displayName: '"Склад" изменение',
		pluralDisplayName: '"Склады" изменения'
	},
	OrmWriteOffOfProducts: {
		plural: 'writeOffOfProducts',
		displayName: 'Списание товаров',
		pluralDisplayName: 'Списания товаров'
	},
	['OrmWriteOffOfProducts_Change']: {
		plural: 'writeOffOfProducts.changes',
		displayName: '"Списание товаров" изменение',
		pluralDisplayName: '"Списания товаров" изменения'
	},
	OrmWriteOffOfProductsProductItem: {
		plural: null,
		displayName: 'Товар',
		pluralDisplayName: 'Товары'
	},
	Role: {
		plural: null,
		displayName: null,
		pluralDisplayName: null
	},
	RoleMapping: {
		plural: null,
		displayName: null,
		pluralDisplayName: null
	},
	OrmExtension: {
		plural: 'extensions',
		displayName: 'Расширение',
		pluralDisplayName: 'Расширения'
	},
	OrmExtensionArgumentItem: {
		plural: null,
		displayName: 'Аргумент',
		pluralDisplayName: 'Аргументы'
	},
	OrmPassport: {
		plural: 'passports',
		displayName: 'Паспорт авторизации',
		pluralDisplayName: 'Паспорта авторизаций'
	},
	['OrmPassport_Change']: {
		plural: 'passports.changes',
		displayName: '"Паспорт авторизации" изменение',
		pluralDisplayName: '"Паспорта авторизаций" изменения'
	},
	OrmPassportConfig: {
		plural: null,
		displayName: 'Конфигурация паспорта авторизации',
		pluralDisplayName: 'Конфигурации паспортов авторизации'
	},
	OrmSQSClient: {
		plural: null,
		displayName: 'Клиент SQS',
		pluralDisplayName: 'Клиенты SQS'
	},
	OrmTenantServer: {
		plural: 'tenantServers',
		displayName: 'Сервер арендодателей',
		pluralDisplayName: 'Сервера арендодателей'
	},
	['OrmTenantServer_Change']: {
		plural: 'tenantServers.changes',
		displayName: '"Сервер арендодателей" изменение',
		pluralDisplayName: '"Сервера арендодателей" изменения'
	},
	OrmTenantClient: {
		plural: 'tenantClients',
		displayName: 'Клиент арендодателя',
		pluralDisplayName: 'Клиенты арендодателей'
	},
	['OrmTenantClient_Change']: {
		plural: 'tenantClients.changes',
		displayName: '"Клиент арендодателя" изменение',
		pluralDisplayName: '"Клиенты арендодателей" изменения'
	},
	OrmUser: {
		plural: 'users',
		displayName: 'Пользователь',
		pluralDisplayName: 'Пользователи'
	}
};
