import { CCol, CFormGroup, CLabel } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatLoyaltyPolicyRangeByProductManager from '../../loyaltyPolicies/shared/NatLoyaltyPolicyRangeByProductManager';
import { loyaltyPolicyRuleAutocompleteAdapter } from '../../shared/Adapters/ValueAutocomplete/LoyaltyPolicyRuleAutocompleteAdapter';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatMultiInputWrapper from '../../shared/Inputs/NatMultiInputWrapper';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { LoyaltyPolicyRuleFormStoreType } from './LoyaltyPolicyRuleFormStore';

interface PropsType extends FormPropsType {
	loyaltyPolicyRuleFormStore: LoyaltyPolicyRuleFormStoreType;
	childrenProps: ChildrenPropsType;
}

interface StateType {
	isLoyaltyPolicyRangeByProductManagerOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class LoyaltyPolicyRuleForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleNatLoyaltyPolicyRangeByProductManager = this.toggleNatLoyaltyPolicyRangeByProductManager.bind(this);
		this.state = {
			isLoyaltyPolicyRangeByProductManagerOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleNatLoyaltyPolicyRangeByProductManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeByProductManagerOpen: status
		});
	}
	render() {
		let timeIntervalTemplate: any = {
			id: '',
			conditionTypeId: null,
			periodicityId: null,
			start: null,
			end: null
		};
		return (
			<>
				<NatHeaderCollapseFormTemplate
					store={this.props.loyaltyPolicyRuleFormStore}
					navTabs={(() => {
						if (this.props.loyaltyPolicyRuleFormStore.model.elementType === 0) {
							if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.time_of_sale') {
								return [{ name: 'Временные интервалы' }, { name: 'Дополнительно' }];
							} else {
								return [{ name: 'Дополнительно' }];
							}
						} else {
							return [];
						}
					})()}
					isAclModalOpen={this.props.childrenProps.isAclModalOpen}
					toggleAclModal={this.props.childrenProps.toggleAclModal}
					tabsContent={() => {
						if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.time_of_sale') {
							return [
								<>
									<NatControlPanel
										buttonItemList={[
											{
												title: 'ДОБАВИТЬ',
												onClick: () => {
													this.props.childrenProps.pushEmptyTemplate(timeIntervalTemplate, 'timeOfSaleIntervals');
												},
												withoutIcon: true,
												disabled: false
											},
											{
												title: 'УДАЛИТЬ',
												onClick: () => {
													this.props.childrenProps.deleteProductItems(this.props.loyaltyPolicyRuleFormStore.selectedItems, 'ruleItems');
												},
												withoutIcon: true,
												disabled: this.props.loyaltyPolicyRuleFormStore.selectedItems.length === 0
											},
											{
												title: 'ВВЕРХ',
												onClick: () => {
													catalog.moveItems(this.props.loyaltyPolicyRuleFormStore.selectedItems, this.props.loyaltyPolicyRuleFormStore.model.timeOfSaleIntervals, -1);
												},
												withoutIcon: true,
												disabled: this.props.loyaltyPolicyRuleFormStore.selectedItems.length === 0
											},
											{
												title: 'ВНИЗ',
												onClick: () => {
													catalog.moveItems(this.props.loyaltyPolicyRuleFormStore.selectedItems, this.props.loyaltyPolicyRuleFormStore.model.timeOfSaleIntervals, 1);
												},
												withoutIcon: true,
												disabled: this.props.loyaltyPolicyRuleFormStore.selectedItems.length === 0
											}
										]}
										inputList={[
											<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
												<NatValueInput<string> object={this.props.loyaltyPolicyRuleFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
											</div>
										]}
									/>
									<div className="nat__form__table__wrapper m-4 h-100">
										<form className="nat__form__table">
											<GridTable
												columns={catalog.convertCellMethods([
													{
														id: 'checkbox',
														pinned: true,
														className: '',
														width: '54px',
														minResizeWidth: 0,
														maxResizeWidth: null,
														resizable: false,
														visible: true,
														headerCellRenderer: () => {
															return <div></div>;
														},
														cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
															return (
																<div className="rgt-cell-inner ml-0 mr-0">
																	<input
																		type="checkbox"
																		id="checkbox"
																		onChange={() => {
																			this.props.childrenProps.saveSelected(data);
																		}}
																	/>
																</div>
															);
														}
													},
													{
														id: 1,
														field: 'periodicity',
														label: 'Период',
														width: '450px',
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner ml-3 p-0">
																	<NatRelationInput
																		object={data}
																		property="periodicityId"
																		relation="periodicity"
																		placeholder="Выберите период"
																		type="text"
																		size="sm"
																		formatter={relationFormatter()}
																		pluralName="enumerationItems"
																		onlySelect={true}
																		filterWhere={{ ownerId: 'periodicity' }}
																		autoComplete="off"
																		inputClassName="text-left"
																		mobile
																		filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																	/>
																</div>
															);
														}
													},
													{
														id: 2,
														field: 'start',
														label: 'Начало периода',
														width: '250px',
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner ml-3 p-0">
																	<NatValueInput<number>
																		object={data}
																		property="quantity"
																		placeholder="Введите кол-во"
																		type="text"
																		pattern="^([0-9]*[.,])?[0-9]*$"
																		inputClassName="text-right"
																		formatter={numberNullableFormatter}
																		autoComplete="off"
																		verticalSwitching
																		size="sm"
																	/>
																</div>
															);
														}
													},
													{
														id: 3,
														field: 'end',
														label: 'Окончание периода',
														width: '250px',
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner ml-3 p-0">
																	<NatValueInput<number>
																		object={data}
																		property="quantity"
																		placeholder="Введите кол-во"
																		type="text"
																		pattern="^([0-9]*[.,])?[0-9]*$"
																		inputClassName="text-right"
																		formatter={numberNullableFormatter}
																		autoComplete="off"
																		verticalSwitching
																		size="sm"
																	/>
																</div>
															);
														}
													}
												])}
												rows={this.props.loyaltyPolicyRuleFormStore.model.timeOfSaleIntervals}
												isPaginated={false}
												texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
												showColumnVisibilityManager={false}
												showRowsInformation={false}
												searchText={this.props.loyaltyPolicyRuleFormStore.searchText}
												onColumnsChange={() => {}}
												highlightSearch={false}
												isVirtualScroll={false}
												showSearch={false}
											/>
										</form>
									</div>
								</>,
								<>
									<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
										<div className="nat__form__table">
											<CCol lg="12">
												<CFormGroup row className="mb-0">
													<CCol md="4">
														<NatValueInput<number>
															object={this.props.loyaltyPolicyRuleFormStore.model}
															property="priority"
															placeholder="Введите число"
															type="text"
															pattern="^-?([0-9]*[.,])?[0-9]*$"
															formatter={numberNullableFormatter}
															size="sm"
															label="Приоритет"
															mobile
														/>
													</CCol>
													<CCol md="4">
														<NatLocalizedValueInput<LocalizedString>
															object={this.props.loyaltyPolicyRuleFormStore.model}
															property="displayName"
															placeholder="Введите наименование"
															type="text"
															formatter={localizedStringFormatter}
															size="sm"
															autoComplete="off"
															label="Отображаемое наименование"
															mobile
														/>
													</CCol>
												</CFormGroup>
											</CCol>
										</div>
									</div>
								</>
							];
						} else {
							return [
								<>
									<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
										<div className="nat__form__table">
											<CCol lg="12">
												<CFormGroup row className="mb-0">
													<CCol md="4">
														<NatValueInput<number>
															object={this.props.loyaltyPolicyRuleFormStore.model}
															property="priority"
															placeholder="Введите число"
															type="text"
															pattern="^-?([0-9]*[.,])?[0-9]*$"
															formatter={numberNullableFormatter}
															size="sm"
															label="Приоритет"
															mobile
														/>
													</CCol>
													<CCol md="4">
														<NatLocalizedValueInput<LocalizedString>
															object={this.props.loyaltyPolicyRuleFormStore.model}
															property="displayName"
															placeholder="Введите наименование"
															type="text"
															formatter={localizedStringFormatter}
															size="sm"
															autoComplete="off"
															label="Отображаемое наименование"
															mobile
														/>
													</CCol>
												</CFormGroup>
											</CCol>
										</div>
									</div>
								</>
							];
						}
					}}
					fields={(() => {
						if (this.props.loyaltyPolicyRuleFormStore.model.elementType === 0) {
							return (
								<>
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.loyaltyPolicyRuleFormStore.model}
													property="name"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													invalid={true}
													invalidFeedback="Строка не заполнена"
													autoComplete="off"
													label="Наименование"
													mobile
													adapter={loyaltyPolicyRuleAutocompleteAdapter}
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.loyaltyPolicyRuleFormStore.model}
													property="conditionOfProvidingId"
													relation="conditionOfProviding"
													placeholder="Выберите условие"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'loyalty_policy_rule_conditions_of_providing' }}
													autoComplete="off"
													label=" Условие предоставления"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
													invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId)}
													invalidFeedback="Элемент не выбран"
												/>
											</CCol>
										</CFormGroup>
										{(() => {
											if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.one_time_sales') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md={this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId ? '8' : '4'} className="pl-0">
															<NatMultiInputWrapper windowSize={1365}>
																<>
																	<div
																		className={(() => {
																			if (!_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId)) {
																				if (this.injected.directoryStore.models.windowSize > 1365) {
																					return 'd-flex w-100 mr-4';
																				} else {
																					return 'd-flex w-100';
																				}
																			} else {
																				return 'd-flex w-100';
																			}
																		})()}>
																		<NatRelationInput
																			object={this.props.loyaltyPolicyRuleFormStore.model}
																			property="volumeOfSalesLimitationCriteriaId"
																			relation="volumeOfSalesLimitationCriteria"
																			placeholder="Выберите критерий"
																			type="text"
																			size="sm"
																			formatter={relationFormatter()}
																			pluralName="enumerationItems"
																			onlySelect={true}
																			filterWhere={{ ownerId: 'loyalty_policy_rule_volume_of_sales_limitation_criteries' }}
																			autoComplete="off"
																			mobile
																			disableWrapper
																			filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																			invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId)}
																			invalidFeedback="Элемент не выбран"
																		/>
																	</div>
																	{(() => {
																		if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																				</>
																			);
																		} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																				</>
																			);
																		} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_different') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">позиций в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																				</>
																			);
																		} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_identical') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">позиций в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																				</>
																			);
																		} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="validityPeriodId"
																							relation="validityPeriod"
																							placeholder="Выберите период"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'periodicity' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																						/>
																					</div>
																				</>
																			);
																		} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count_of_identical') {
																			return (
																				<>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">в документе</CLabel>
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="comparisonTypeId"
																							relation="comparisonType"
																							placeholder="Выберите тип"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'comparison_types' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																							invalidFeedback="Элемент не выбран"
																						/>
																					</div>
																					<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																						<NatValueInput<number>
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="conditionValue"
																							placeholder="Введите число"
																							type="text"
																							pattern="^([0-9]*[.,])?[0-9]*$"
																							formatter={numberFormatter}
																							size="sm"
																							autoComplete="off"
																							disableWrapper
																							mobile
																						/>
																					</div>
																					<div className="d-flex w-100">
																						<NatRelationInput
																							object={this.props.loyaltyPolicyRuleFormStore.model}
																							property="validityPeriodId"
																							relation="validityPeriod"
																							placeholder="Выберите период"
																							type="text"
																							size="sm"
																							formatter={relationFormatter()}
																							pluralName="enumerationItems"
																							onlySelect={true}
																							filterWhere={{ ownerId: 'periodicity' }}
																							autoComplete="off"
																							disableWrapper
																							mobile
																							filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																						/>
																					</div>
																				</>
																			);
																		}
																	})()}
																</>
															</NatMultiInputWrapper>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.accumulated_sales') {
												return (
													<>
														<CFormGroup row className="mb-0">
															<CCol md={this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId ? '8' : '4'} className="pl-0">
																<NatMultiInputWrapper windowSize={1365}>
																	<>
																		<div
																			className={(() => {
																				if (!_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId)) {
																					if (this.injected.directoryStore.models.windowSize > 1365) {
																						return 'd-flex w-100 mr-4';
																					} else {
																						return 'd-flex w-100';
																					}
																				} else {
																					return 'd-flex w-100';
																				}
																			})()}>
																			<NatRelationInput
																				object={this.props.loyaltyPolicyRuleFormStore.model}
																				property="volumeOfSalesLimitationCriteriaId"
																				relation="volumeOfSalesLimitationCriteria"
																				placeholder="Выберите критерий"
																				type="text"
																				size="sm"
																				formatter={relationFormatter()}
																				pluralName="enumerationItems"
																				onlySelect={true}
																				filterWhere={{ ownerId: 'loyalty_policy_rule_volume_of_sales_limitation_criteries' }}
																				autoComplete="off"
																				mobile
																				disableWrapper
																				filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																				invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId)}
																				invalidFeedback="Элемент не выбран"
																			/>
																		</div>
																		{(() => {
																			if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">проданного товара</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																					</>
																				);
																			} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">проданного товара</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																					</>
																				);
																			} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_different') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">позиций проданного</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																					</>
																				);
																			} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_identical') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">позиций проданного</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																					</>
																				);
																			} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">проданного товара</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="validityPeriodId"
																								relation="validityPeriod"
																								placeholder="Выберите период"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'periodicity' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							/>
																						</div>
																					</>
																				);
																			} else if (this.props.loyaltyPolicyRuleFormStore.model.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count_of_identical') {
																				return (
																					<>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<CLabel className="align-self-center pl-3 m-0 pr-3 text-nowrap">проданного товара</CLabel>
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="comparisonTypeId"
																								relation="comparisonType"
																								placeholder="Выберите тип"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'comparison_types' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																								invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.comparisonTypeId)}
																								invalidFeedback="Элемент не выбран"
																							/>
																						</div>
																						<div className={this.injected.directoryStore.models.windowSize > 1365 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																							<NatValueInput<number>
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="conditionValue"
																								placeholder="Введите число"
																								type="text"
																								pattern="^([0-9]*[.,])?[0-9]*$"
																								formatter={numberFormatter}
																								size="sm"
																								autoComplete="off"
																								disableWrapper
																								mobile
																							/>
																						</div>
																						<div className="d-flex w-100">
																							<NatRelationInput
																								object={this.props.loyaltyPolicyRuleFormStore.model}
																								property="validityPeriodId"
																								relation="validityPeriod"
																								placeholder="Выберите период"
																								type="text"
																								size="sm"
																								formatter={relationFormatter()}
																								pluralName="enumerationItems"
																								onlySelect={true}
																								filterWhere={{ ownerId: 'periodicity' }}
																								autoComplete="off"
																								disableWrapper
																								mobile
																								filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																							/>
																						</div>
																					</>
																				);
																			}
																		})()}
																	</>
																</NatMultiInputWrapper>
															</CCol>
														</CFormGroup>
														<CFormGroup row className="mb-0">
															<CCol md="4">
																<NatCheckbox object={this.props.loyaltyPolicyRuleFormStore.model} property="includeCurrentSaleInAccumulatedSalesVolume" label="Включить текущую продажу в накопленный объем продаж" />
															</CCol>
														</CFormGroup>
													</>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.payment_option') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="paymentOptionId"
																relation="paymentOption"
																placeholder="Выберите способ оплаты"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="paymentOptions"
																onlySelect={true}
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Способ оплаты"
																mobile
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.paymentOptionId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.presence_loyalty_card') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="loyaltyCardKindId"
																relation="loyaltyCardKind"
																placeholder="Выберите вид"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="loyaltyCardKinds"
																onlySelect={true}
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Вид карты лояльности"
																mobile
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.loyaltyCardKindId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.absence_loyalty_card') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="loyaltyCardKindId"
																relation="loyaltyCardKind"
																placeholder="Выберите вид"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="loyaltyCardKinds"
																onlySelect={true}
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Вид карты лояльности"
																mobile
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.loyaltyCardKindId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.birthdate') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="daysBeforeBirthday"
																placeholder="Введите число"
																type="text"
																pattern="^-?([0-9]*[.,])?[0-9]*$"
																formatter={numberNullableFormatter}
																size="sm"
																label="Дней до дня рождения"
																mobile
															/>
														</CCol>
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="daysAfterBirthday"
																placeholder="Введите число"
																type="text"
																pattern="^-?([0-9]*[.,])?[0-9]*$"
																formatter={numberNullableFormatter}
																size="sm"
																label="Дней после дня рождения"
																mobile
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyRuleFormStore.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.partner_segment') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyRuleFormStore.model}
																property="partnerSegmentId"
																relation="partnerSegment"
																placeholder="Выберите сегмент"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="partnerSegments"
																filterWhere={{ deleted: false, elementType: 0 }}
																autoComplete="off"
																label="Сегмент партнеров"
																mobile
																hierarchicalSelect
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.partnerSegmentId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											}
										})()}
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.loyaltyPolicyRuleFormStore.model}
													property="productSelectionOptionId"
													relation="productSelectionOption"
													placeholder="Выберите вариант предоставления"
													type="text"
													size="sm"
													formatter={(() => {
														if (this.props.loyaltyPolicyRuleFormStore.model.productSelectionOptionId === 'loyalty_policy_product_selection_options.list') {
															if (this.props.match.params.elementId === 'new') {
																return relationFormatter();
															} else {
																return relationFormatter(false, false, [
																	{
																		icon: 'cil-plus',
																		title: 'Добавить',
																		onClick: () => {
																			this.toggleNatLoyaltyPolicyRangeByProductManager(true);
																		},
																		to: ''
																	}
																]);
															}
														} else {
															return relationFormatter();
														}
													})()}
													pluralName="enumerationItems"
													filterWhere={{
														ownerId: 'loyalty_policy_product_selection_options',
														id: {
															nin: ['loyalty_policy_product_selection_options.scheme']
														}
													}}
													autoComplete="off"
													onlySelect
													label="Предоставляется на номенклатуру"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
													invalid={_.isEmpty(this.props.loyaltyPolicyRuleFormStore.model.productSelectionOptionId)}
													invalidFeedback="Элемент не выбран"
												/>
											</CCol>
											{this.props.loyaltyPolicyRuleFormStore.model.productSelectionOptionId === 'loyalty_policy_product_selection_options.segment' && (
												<CCol md="4">
													<NatRelationInput
														object={this.props.loyaltyPolicyRuleFormStore.model}
														property="productSegmentId"
														relation="productSegment"
														placeholder="Выберите сегмент"
														type="text"
														size="sm"
														formatter={relationFormatter()}
														pluralName="productSegments"
														filterWhere={{ deleted: false, elementType: 0 }}
														autoComplete="off"
														label="Сегмент номенклатуры"
														mobile
														hierarchicalSelect
														goTo={this.props.goTo}
													/>
												</CCol>
											)}
										</CFormGroup>
									</CCol>
								</>
							);
						} else {
							return (
								<>
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.loyaltyPolicyRuleFormStore.model}
													property="name"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													invalid={true}
													invalidFeedback="Строка не заполнена"
													autoComplete="off"
													label="Наименование"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.loyaltyPolicyRuleFormStore.model}
													property="parentId"
													relation="parent"
													placeholder="Выберите группу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="loyaltyPolicies"
													filterWhere={{ deleted: false, elementType: 1 }}
													autoComplete="off"
													label="Группа"
													mobile
													treeSelect
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</>
							);
						}
					})()}
					controlButtons={[
						{
							title: 'ОК',
							onClick: (e: any) => {
								this.props.childrenProps
									.post(e)
									.then(() => {
										this.props.removeNavigationRoute(this.props.navigationRoute);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.props.loyaltyPolicyRuleFormStore.setValue(this.props.loyaltyPolicyRuleFormStore, 'isLoading', false);
									});
							},
							disabled: this.props.loyaltyPolicyRuleFormStore.isLoading
						},
						{
							title: 'ЗАПИСАТЬ',
							onClick: (e: any) => {
								this.props.childrenProps.postModel(e, true);
							},
							disabled: this.props.loyaltyPolicyRuleFormStore.isLoading
						},
						{
							title: 'ОТМЕНА',
							onClick: () => {
								this.props.removeNavigationRoute(this.props.navigationRoute);
							}
						},
						{
							title: this.props.loyaltyPolicyRuleFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
							onClick: () => {
								this.props.childrenProps.makeDeleted();
							},
							disabled: this.props.match.params.elementId === 'new'
						},
						{
							title: 'ЕЩЕ',
							onClick: () => {},
							disabled: this.props.match.params.elementId === 'new',
							children: [
								{
									title: 'Управление доступом',
									onClick: () => {
										this.props.childrenProps.toggleAclModal(true);
									}
								}
							]
						}
					]}
				/>
				{this.state.isLoyaltyPolicyRangeByProductManagerOpen && (
					<NatLoyaltyPolicyRangeByProductManager
						isModalOpen={this.state.isLoyaltyPolicyRangeByProductManagerOpen}
						toggleNatLoyaltyPolicyRangeByProductManager={this.toggleNatLoyaltyPolicyRangeByProductManager}
						modelStore={this.props.loyaltyPolicyRuleFormStore}
						goTo={this.props.goTo}
					/>
				)}
			</>
		);
	}
}

export default UseLocation(withRouter(LoyaltyPolicyRuleForm));
