import { CButton } from '@coreui/react';
import { observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';

export interface ChildrenPropsType {
	isContextOpen: boolean;
	x: number;
	y: number;
}

interface PropsType {
	children: ReactElement;
	buttonItemList: Array<ButtonItem>;
}
interface ButtonItem {
	title?: string;
	icon?: ReactElement;
	variant?: any;
	onClick: (e?: any) => void;
	disabled?: boolean;
	className?: any;
	show?: boolean;
}

@observer
class NatTreeButtons extends Component<PropsType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.toggle = this.toggle.bind(this);
	}
	componentDidMount() {
		this.myRef.current.querySelector('.nat__tree__buttons')?.addEventListener('mouseover', this.toggle);
		this.myRef.current.querySelector('.nat__tree__buttons')?.addEventListener('mouseout', this.toggle);
	}
	componentWillUnmount() {
		this.myRef.current.querySelector('.nat__tree__buttons')?.removeEventListener('mouseover', this.toggle);
		this.myRef.current.querySelector('.nat__tree__buttons')?.removeEventListener('mouseout', this.toggle);
	}
	toggle() {
		this.myRef.current.querySelector('.nat__tree__buttons__wrapper').classList.toggle('nat__hover');
	}
	render() {
		return (
			<div ref={this.myRef}>
				<div className="nat__tree__buttons">
					<div className="nat__tree__buttons__wrapper">
						{this.props.buttonItemList.map((value: ButtonItem, index: number) => {
							if (value.show) {
								return (
									<CButton variant={value.variant} className={value.className} onClick={value.onClick} key={index}>
										{value.icon}
									</CButton>
								);
							}
						})}
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default NatTreeButtons;
