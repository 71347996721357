import _ from 'lodash';

import AcquiringOperationFormContainer from '../../acquiringOperations/AcquiringOperation/AcquiringOperationFormContainer';
import AcquiringOperationFormStore from '../../acquiringOperations/AcquiringOperation/AcquiringOperationFormStore';
import AcquiringOperationsListContainer from '../../acquiringOperations/AcquiringOperationsList/AcquiringOperationsListContainer';
import AcquiringOperationsListStore from '../../acquiringOperations/AcquiringOperationsList/AcquiringOperationsListStore';
import CashBoxFormContainer from '../../cashBoxs/CashBox/CashBoxFormContainer';
import CashBoxFormStore from '../../cashBoxs/CashBox/CashBoxFormStore';
import CashBoxsListContainer from '../../cashBoxs/CashBoxsList/CashBoxsListContainer';
import CashBoxsListStore from '../../cashBoxs/CashBoxsList/CashBoxsListStore';
import CashRegisterShiftFormContainer from '../../cashRegisterShifts/CashRegisterShift/CashRegisterShiftFormContainer';
import CashRegisterShiftFormStore from '../../cashRegisterShifts/CashRegisterShift/CashRegisterShiftFormStore';
import CashRegisterShiftsListContainer from '../../cashRegisterShifts/CashRegisterShiftsList/CashRegisterShiftsListContainer';
import CashRegisterShiftsListStore from '../../cashRegisterShifts/CashRegisterShiftsList/CashRegisterShiftsListStore';
import CashRegisterFormContainer from '../../cashRegisters/CashRegister/CashRegisterFormContainer';
import CashRegisterFormStore from '../../cashRegisters/CashRegister/CashRegisterFormStore';
import CashRegistersListContainer from '../../cashRegisters/CashRegistersList/CashRegistersListContainer';
import CashRegistersListStore from '../../cashRegisters/CashRegistersList/CashRegistersListStore';
import CashlessReceiptFormContainer from '../../cashlessReceipts/CashlessReceipt/CashlessReceiptFormContainer';
import CashlessReceiptFormStore from '../../cashlessReceipts/CashlessReceipt/CashlessReceiptFormStore';
import CashlessReceiptsListContainer from '../../cashlessReceipts/CashlessReceiptsList/CashlessReceiptsListContainer';
import CashlessReceiptsListStore from '../../cashlessReceipts/CashlessReceiptsList/CashlessReceiptsListStore';
import CashlessWriteOffFormContainer from '../../cashlessWriteOffs/CashlessWriteOff/CashlessWriteOffFormContainer';
import CashlessWriteOffFormStore from '../../cashlessWriteOffs/CashlessWriteOff/CashlessWriteOffFormStore';
import CashlessWriteOffsListContainer from '../../cashlessWriteOffs/CashlessWriteOffsList/CashlessWriteOffsListContainer';
import CashlessWriteOffsListStore from '../../cashlessWriteOffs/CashlessWriteOffsList/CashlessWriteOffsListStore';
import CheckFormContainer from '../../checks/Check/CheckFormContainer';
import CheckFormStore from '../../checks/Check/CheckFormStore';
import ChecksListContainer from '../../checks/ChecksList/ChecksListContainer';
import ChecksListStore from '../../checks/ChecksList/ChecksListStore';
import CouponKindFormContainer from '../../couponKinds/CoupoKind/CouponKindFormContainer';
import CouponKindFormStore from '../../couponKinds/CoupoKind/CouponKindFormStore';
import CouponKindsListContainer from '../../couponKinds/CouponKindsList/CouponKindsListContainer';
import CouponKindsListStore from '../../couponKinds/CouponKindsList/CouponKindsListStore';
import CouponFormContainer from '../../coupons/Coupon/CouponFormContainer';
import CouponFormStore from '../../coupons/Coupon/CouponFormStore';
import CouponsListContainer from '../../coupons/CouponsList/CouponsListContainer';
import CouponsListStore from '../../coupons/CouponsList/CouponsListStore';
import CustomerOrderFormContainer from '../../customerOrders/CustomerOrder/CustomerOrderFormContainer';
import CustomerOrderFormStore from '../../customerOrders/CustomerOrder/CustomerOrderFormStore';
import CustomerOrdersListContainer from '../../customerOrders/CustomerOrdersList/CustomerOrdersListContainer';
import CustomerOrdersListStore from '../../customerOrders/CustomerOrdersList/CustomerOrdersListStore';
import IncomeCashOrderFormContainer from '../../incomeCashOrders/IncomeCashOrder/IncomeCashOrderFormContainer';
import IncomeCashOrderFormStore from '../../incomeCashOrders/IncomeCashOrder/IncomeCashOrderFormStore';
import IncomeCashOrdersListContainer from '../../incomeCashOrders/IncomeCashOrdersList/IncomeCashOrdersListContainer';
import IncomeCashOrdersListStore from '../../incomeCashOrders/IncomeCashOrdersList/IncomeCashOrdersListStore';
import LoyaltyCardKindFormContainer from '../../loyaltyCardKinds/LoyaltyCardKind/LoyaltyCardKindFormContainer';
import LoyaltyCardKindFormStore from '../../loyaltyCardKinds/LoyaltyCardKind/LoyaltyCardKindFormStore';
import LoyaltyCardKindsListContainer from '../../loyaltyCardKinds/LoyaltyCardKindsList/LoyaltyCardKindsListContainer';
import LoyaltyCardKindsListStore from '../../loyaltyCardKinds/LoyaltyCardKindsList/LoyaltyCardKindsListStore';
import LoyaltyCardFormContainer from '../../loyaltyCards/LoyaltyCard/LoyaltyCardFormContainer';
import LoyaltyCardFormStore from '../../loyaltyCards/LoyaltyCard/LoyaltyCardFormStore';
import LoyaltyCardsListContainer from '../../loyaltyCards/LoyaltyCardsList/LoyaltyCardsListContainer';
import LoyaltyCardsListStore from '../../loyaltyCards/LoyaltyCardsList/LoyaltyCardsListStore';
import LoyaltyPoliciesListContainer from '../../loyaltyPolicies/LoyaltyPoliciesList/LoyaltyPoliciesListContainer';
import LoyaltyPoliciesListStore from '../../loyaltyPolicies/LoyaltyPoliciesList/LoyaltyPoliciesListStore';
import LoyaltyPolicyFormContainer from '../../loyaltyPolicies/LoyaltyPolicy/LoyaltyPolicyFormContainer';
import LoyaltyPolicyFormStore from '../../loyaltyPolicies/LoyaltyPolicy/LoyaltyPolicyFormStore';
import LoyaltyPolicyRuleFormContainer from '../../loyaltyPolicyRules/LoyaltyPolicyRule/LoyaltyPolicyRuleFormContainer';
import LoyaltyPolicyRuleFormStore from '../../loyaltyPolicyRules/LoyaltyPolicyRule/LoyaltyPolicyRuleFormStore';
import LoyaltyProgramFormContainer from '../../loyaltyPrograms/LoyaltyProgram/LoyaltyProgramFormContainer';
import LoyaltyProgramFormStore from '../../loyaltyPrograms/LoyaltyProgram/LoyaltyProgramFormStore';
import LoyaltyProgramsListContainer from '../../loyaltyPrograms/LoyaltyProgramsList/LoyaltyProgramsListContainer';
import LoyaltyProgramsListStore from '../../loyaltyPrograms/LoyaltyProgramsList/LoyaltyProgramsListStore';
import MovementOfProductsFormContainer from '../../movementOfProducts/MovementOfProducts/MovementOfProductsFormContainer';
import MovementOfProductsFormStore from '../../movementOfProducts/MovementOfProducts/MovementOfProductsFormStore';
import MovementOfProductsListContainer from '../../movementOfProducts/MovementOfProductsList/MovementOfProductsListContainer';
import MovementOfProductsListStore from '../../movementOfProducts/MovementOfProductsList/MovementOfProductsListStore';
import NaturalPersonFormContainer from '../../naturalPersons/NaturalPerson/NaturalPersonFormContainer';
import NaturalPersonFormStore from '../../naturalPersons/NaturalPerson/NaturalPersonFormStore';
import NaturalPersonsListContainer from '../../naturalPersons/NaturalPersonsList/NaturalPersonsListContainer';
import NaturalPersonsListStore from '../../naturalPersons/NaturalPersonsList/NaturalPersonsListStore';
import NewsFormContainer from '../../news/News/NewsFormContainer';
import NewsFormStore from '../../news/News/NewsFormStore';
import NewsListContainer from '../../news/NewsList/NewsListContainer';
import NewsListStore from '../../news/NewsList/NewsListStore';
import OrganizationBankAccountFormContainer from '../../organizationBankAccounts/OrganizationBankAccount/OrganizationBankAccountFormContainer';
import OrganizationBankAccountFormStore from '../../organizationBankAccounts/OrganizationBankAccount/OrganizationBankAccountFormStore';
import OrganizationBankAccountsListContainer from '../../organizationBankAccounts/OrganizationBankAccountsList/OrganizationBankAccountsListContainer';
import OrganizationBankAccountsListStore from '../../organizationBankAccounts/OrganizationBankAccountsList/OrganizationBankAccountsListStore';
import OrganizationFormContainer from '../../organizations/Organization/OrganizationFormContainer';
import OrganizationFormStore from '../../organizations/Organization/OrganizationFormStore';
import OrganizationsListContainer from '../../organizations/OrganizationsList/OrganizationsListContainer';
import OrganizationsListStore from '../../organizations/OrganizationsList/OrganizationsListStore';
import OutcomeCashOrderFormContainer from '../../outcomeCashOrders/OutcomeCashOrder/OutcomeCashOrderFormContainer';
import OutcomeCashOrderFormStore from '../../outcomeCashOrders/OutcomeCashOrder/OutcomeCashOrderFormStore';
import OutcomeCashOrdersListContainer from '../../outcomeCashOrders/OutcomeCashOrdersList/OutcomeCashOrdersListContainer';
import OutcomeCashOrdersListStore from '../../outcomeCashOrders/OutcomeCashOrdersList/OutcomeCashOrdersListStore';
import PartnerBankAccountFormContainer from '../../partnerBankAccounts/PartnerBankAccount/PartnerBankAccountFormContainer';
import PartnerBankAccountFormStore from '../../partnerBankAccounts/PartnerBankAccount/PartnerBankAccountFormStore';
import PartnerBankAccountsListContainer from '../../partnerBankAccounts/PartnerBankAccountsList/PartnerBankAccountsListContainer';
import PartnerBankAccountsListStore from '../../partnerBankAccounts/PartnerBankAccountsList/PartnerBankAccountsListStore';
import PartnerContractsListContainer from '../../partnerContracts/PartnerContractsList/PartnerContractsListContainer';
import PartnerContractsListStore from '../../partnerContracts/PartnerContractsList/PartnerContractsListStore';
import PartnerContractFormContainer from '../../partnerContracts/partnerContract/PartnerContractFormContainer';
import PartnerContractFormStore from '../../partnerContracts/partnerContract/PartnerContractFormStore';
import PartnerSettlementOperationFormContainer from '../../partnerSettlementOperations/PartnerSettlementOperation/PartnerSettlementOperationFormContainer';
import PartnerSettlementOperationFormStore from '../../partnerSettlementOperations/PartnerSettlementOperation/PartnerSettlementOperationFormStore';
import PartnerSettlementOperationsListContainer from '../../partnerSettlementOperations/PartnerSettlementOperationsList/PartnerSettlementOperationsListContainer';
import PartnerSettlementOperationsListStore from '../../partnerSettlementOperations/PartnerSettlementOperationsList/PartnerSettlementOperationsListStore';
import PartnerSubscriptionOperationFormContainer from '../../partnerSubscriptionOperations/PartnerSubscriptionOperation/PartnerSubscriptionOperationFormContainer';
import PartnerSubscriptionOperationFormStore from '../../partnerSubscriptionOperations/PartnerSubscriptionOperation/PartnerSubscriptionOperationFormStore';
import PartnerSubscriptionOperationsListContainer from '../../partnerSubscriptionOperations/PartnerSubscriptionOperationsList/PartnerSubscriptionOperationsListContainer';
import PartnerSubscriptionOperationsListStore from '../../partnerSubscriptionOperations/PartnerSubscriptionOperationsList/PartnerSubscriptionOperationsListStore';
import PartnerFormContainer from '../../partners/Partner/PartnerFormContainer';
import PartnerFormStore from '../../partners/Partner/PartnerFormStore';
import PartnersListContainer from '../../partners/PartnersList/PartnersListContainer';
import PartnersListStore from '../../partners/PartnersList/PartnersListStore';
import PaymentOptionFormContainer from '../../paymentOptions/PaymentOption/PaymentOptionFormContainer';
import PaymentOptionFormStore from '../../paymentOptions/PaymentOption/PaymentOptionFormStore';
import PaymentOptionsListContainer from '../../paymentOptions/PaymentOptionsList/PaymentOptionsListContainer';
import PaymentOptionsListStore from '../../paymentOptions/PaymentOptionsList/PaymentOptionsListStore';
import PostingOfProductFormContainer from '../../postingOfProducts/PostingOfProduct/PostingOfProductFormContainer';
import PostingOfProductFormStore from '../../postingOfProducts/PostingOfProduct/PostingOfProductFormStore';
import PostingOfProductsListContainer from '../../postingOfProducts/PostingOfProductsList/PostingOfProductsListContainer';
import PostingOfProductsListStore from '../../postingOfProducts/PostingOfProductsList/PostingOfProductsListStore';
import PriceTypeFormContainer from '../../priceTypes/PriceType/PriceTypeFormContainer';
import PriceTypeFormStore from '../../priceTypes/PriceType/PriceTypeFormStore';
import PriceTypesListContainer from '../../priceTypes/PriceTypesList/PriceTypesListContainer';
import PriceTypesListStore from '../../priceTypes/PriceTypesList/PriceTypesListStore';
import PrintLabelFormContainer from '../../printLabels/PrintLabel/PrintLabelFormContainer';
import PrintLabelFormStore from '../../printLabels/PrintLabel/PrintLabelFormStore';
import ProductFeatureFormContainer from '../../productFeatures/ProductFeature/ProductFeatureFormContainer';
import ProductFeatureFormStore from '../../productFeatures/ProductFeature/ProductFeatureFormStore';
import ProductKindFormContainer from '../../productKinds/ProductKind/ProductKindFormContainer';
import ProductKindFormStore from '../../productKinds/ProductKind/ProductKindFormStore';
import ProductKindsListContainer from '../../productKinds/ProductKindsList/ProductKindsListContainer';
import ProductKindsListStore from '../../productKinds/ProductKindsList/ProductKindsListStore';
import ProductUnitFormContainer from '../../productUnits/ProductUnit/ProductUnitFormContainer';
import ProductUnitFormStore from '../../productUnits/ProductUnit/ProductUnitFormStore';
import ProductUnitsListContainer from '../../productUnits/ProductUnitsList/ProductUnitsListContainer';
import ProductUnitsListStore from '../../productUnits/ProductUnitsList/ProductUnitsListStore';
import ProductFormContainer from '../../products/Product/ProductFormContainer';
import ProductFormStore from '../../products/Product/ProductFormStore';
import ProductsListContainer from '../../products/ProductsList/ProductsListContainer';
import ProductsListStore from '../../products/ProductsList/ProductsListStore';
import ReceiptOfProductFormContainer from '../../receiptOfProducts/ReceiptOfProduct/ReceiptOfProductFormContainer';
import ReceiptOfProductFormStore from '../../receiptOfProducts/ReceiptOfProduct/ReceiptOfProductFormStore';
import ReceiptOfProductsListContainer from '../../receiptOfProducts/ReceiptOfProductsList/ReceiptOfProductsListContainer';
import ReceiptOfProductsListStore from '../../receiptOfProducts/ReceiptOfProductsList/ReceiptOfProductsListStore';
import RecountOfProductFormContainer from '../../recountOfProducts/RecountOfProduct/RecountOfProductFormContainer';
import RecountOfProductFormStore from '../../recountOfProducts/RecountOfProduct/RecountOfProductFormStore';
import RecountOfProductsListContainer from '../../recountOfProducts/RecountOfProductsList/RecountOfProductsListContainer';
import RecountOfProductsListStore from '../../recountOfProducts/RecountOfProductsList/RecountOfProductsListStore';
import RefundOfProductsFormContainer from '../../refundOfproducts/RefundOfProducts/RefundOfProductsFormContainer';
import RefundOfProductsFormStore from '../../refundOfproducts/RefundOfProducts/RefundOfProductsFormStore';
import RefundOfProductsListContainer from '../../refundOfproducts/RefundOfProductsList/RefundOfProductsListContainer';
import RefundOfProductsListStore from '../../refundOfproducts/RefundOfProductsList/RefundOfProductsListStore';
import ReportContainer from '../../reports/Report/ReportContainer';
import ReportStore from '../../reports/Report/ReportStore';
import ResponsiblePersonFormContainer from '../../responsiblePersons/ResponsiblePerson/ResponsiblePersonFormContainer';
import ResponsiblePersonFormStore from '../../responsiblePersons/ResponsiblePerson/ResponsiblePersonFormStore';
import ResponsiblePersonsListContainer from '../../responsiblePersons/ResponsiblePersonsList/ResponsiblePersonsListContainer';
import ResponsiblePersonsListStore from '../../responsiblePersons/ResponsiblePersonsList/ResponsiblePersonsListStore';
import SaleOfProductFormContainer from '../../saleOfProducts/SaleOfProduct/SaleOfProductFormContainer';
import SaleOfProductFormStore from '../../saleOfProducts/SaleOfProduct/SaleOfProductFormStore';
import SaleOfProductsListContainer from '../../saleOfProducts/SaleOfProductsList/SaleOfProductsListContainer';
import SaleOfProductsListStore from '../../saleOfProducts/SaleOfProductsList/SaleOfProductsListStore';
import SalesReportFormContainer from '../../salesReports/SalesReport/SalesReportFormContainer';
import SalesReportFormStore from '../../salesReports/SalesReport/SalesReportFormStore';
import SalesReportsListContainer from '../../salesReports/SalesReportsList/SalesReportsListContainer';
import SalesReportsListStore from '../../salesReports/SalesReportsList/SalesReportsListStore';
import SerialNumberFormContainer from '../../serialNumbers/SerialNumber/SerialNumberFormContainer';
import SerialNumberFormStore from '../../serialNumbers/SerialNumber/SerialNumberFormStore';
import SettingPricesOfProductFormContainer from '../../settingPricesOfProducts/SettingPricesOfProduct/SettingPricesOfProductFormContainer';
import SettingPricesOfProductFormStore from '../../settingPricesOfProducts/SettingPricesOfProduct/SettingPricesOfProductFormStore';
import SettingPricesOfProductsListContainer from '../../settingPricesOfProducts/SettingPricesOfProductsList/SettingPricesOfProductsListContainer';
import SettingPricesOfProductsListStore from '../../settingPricesOfProducts/SettingPricesOfProductsList/SettingPricesOfProductsListStore';
import LoyaltyPolicySettingsContainer from '../../settings/LoyaltyPolicySettings/LoyaltyPolicySettingsContainer';
import LoyaltyPolicySettingsStore from '../../settings/LoyaltyPolicySettings/LoyaltyPolicySettingsStore';
import MainSettingsContainer from '../../settings/MainSettings/MainSettingsContainer';
import MainSettingsStore from '../../settings/MainSettings/MainSettingsStore';
import OnlineStoreSettingsContainer from '../../settings/OnlineStoreSettings/OnlineStoreSettingsContainer';
import OnlineStoreSettingsStore from '../../settings/OnlineStoreSettings/OnlineStoreSettingsStore';
import WarehouseSettingsContainer from '../../settings/WarehouseSettings/WarehouseSettingsContainer';
import WarehouseSettingsStore from '../../settings/WarehouseSettings/WarehouseSettingsStore';
import NatShoppingCart from '../../shoppingCart/NatShoppingCart';
import NatShoppingCartStore from '../../shoppingCart/NatShoppingCartStore';
import NatStatistics from '../../statistics/NatStatistics';
import NatStatisticsStore from '../../statistics/NatStatisticsStore';
import SubscriptionOptionFormContainer from '../../subscriptionOptions/SubscriptionOption/SubscriptionOptionFormContainer';
import SubscriptionOptionFormStore from '../../subscriptionOptions/SubscriptionOption/SubscriptionOptionFormStore';
import SubscriptionOptionsListContainer from '../../subscriptionOptions/SubscriptionOptionsList/SubscriptionOptionsListContainer';
import SubscriptionOptionsListStore from '../../subscriptionOptions/SubscriptionOptionsList/SubscriptionOptionsListStore';
import SubscriptionPlanFormContainer from '../../subscriptionPlans/SubscriptionPlan/SubscriptionPlanFormContainer';
import SubscriptionPlanFormStore from '../../subscriptionPlans/SubscriptionPlan/SubscriptionPlanFormStore';
import SubscriptionPlansListContainer from '../../subscriptionPlans/SubscriptionPlansList/SubscriptionPlansListContainer';
import SubscriptionPlansListStore from '../../subscriptionPlans/SubscriptionPlansList/SubscriptionPlansListStore';
import WarehouseFormContainer from '../../warehouses/Warehouse/WarehouseFormContainer';
import WarehouseFormStore from '../../warehouses/Warehouse/WarehouseFormStore';
import WarehousesListContainer from '../../warehouses/WarehousesList/WarehousesListContainer';
import WarehousesListStore from '../../warehouses/WarehousesList/WarehousesListStore';
import WriteOffOfProductFormContainer from '../../writeOffOfProducts/WriteOffOfProduct/WriteOffOfProductFormContainer';
import WriteOffOfProductFormStore from '../../writeOffOfProducts/WriteOffOfProduct/WriteOffOfProductFormStore';
import WriteOffOfProductsListContainer from '../../writeOffOfProducts/WriteOffOfProductsList/WriteOffOfProductsListContainer';
import WriteOffOfProductsListStore from '../../writeOffOfProducts/WriteOffOfProductsList/WriteOffOfProductsListStore';

export const routes = [
	{ path: '/settingPricesOfProducts', exact: true, component: SettingPricesOfProductsListContainer, store: SettingPricesOfProductsListStore },
	{ path: '/settingPricesOfProducts/:elementId', exact: true, component: SettingPricesOfProductFormContainer, store: SettingPricesOfProductFormStore },
	{ path: '/organizationBankAccounts', exact: true, component: OrganizationBankAccountsListContainer, store: OrganizationBankAccountsListStore },
	{ path: '/organizationBankAccounts/:elementId', exact: true, component: OrganizationBankAccountFormContainer, store: OrganizationBankAccountFormStore },
	{ path: '/organizations', exact: true, component: OrganizationsListContainer, store: OrganizationsListStore },
	{ path: '/organizations/:elementId', exact: true, component: OrganizationFormContainer, store: OrganizationFormStore },
	{ path: '/cashlessWriteOffs', exact: true, component: CashlessWriteOffsListContainer, store: CashlessWriteOffsListStore },
	{ path: '/cashlessWriteOffs/:elementId', exact: true, component: CashlessWriteOffFormContainer, store: CashlessWriteOffFormStore },
	{ path: '/cashlessReceipts', exact: true, component: CashlessReceiptsListContainer, store: CashlessReceiptsListStore },
	{ path: '/cashlessReceipts/:elementId', exact: true, component: CashlessReceiptFormContainer, store: CashlessReceiptFormStore },
	{ path: '/warehouses', exact: true, component: WarehousesListContainer, store: WarehousesListStore },
	{ path: '/warehouses/:elementId', exact: true, component: WarehouseFormContainer, store: WarehouseFormStore },
	{ path: '/productUnits', exact: true, component: ProductUnitsListContainer, store: ProductUnitsListStore },
	{ path: '/productUnits/:elementId', exact: true, component: ProductUnitFormContainer, store: ProductUnitFormStore },
	{ path: '/products', exact: true, component: ProductsListContainer, store: ProductsListStore },
	{ path: '/products/:elementId', exact: true, component: ProductFormContainer, store: ProductFormStore },
	{ path: '/productKinds', exact: true, component: ProductKindsListContainer, store: ProductKindsListStore },
	{ path: '/productKinds/:elementId', exact: true, component: ProductKindFormContainer, store: ProductKindFormStore },
	{ path: '/productFeatures/:elementId', exact: true, component: ProductFeatureFormContainer, store: ProductFeatureFormStore },
	{ path: '/paymentOptions', exact: true, component: PaymentOptionsListContainer, store: PaymentOptionsListStore },
	{ path: '/paymentOptions/:elementId', exact: true, component: PaymentOptionFormContainer, store: PaymentOptionFormStore },
	{ path: '/priceTypes', exact: true, component: PriceTypesListContainer, store: PriceTypesListStore },
	{ path: '/priceTypes/:elementId', exact: true, component: PriceTypeFormContainer, store: PriceTypeFormStore },
	{ path: '/partners', exact: true, component: PartnersListContainer, store: PartnersListStore },
	{ path: '/partners/:elementId', exact: true, component: PartnerFormContainer, store: PartnerFormStore },
	{ path: '/customerOrders', exact: true, component: CustomerOrdersListContainer, store: CustomerOrdersListStore },
	{ path: '/customerOrders/:elementId', exact: true, component: CustomerOrderFormContainer, store: CustomerOrderFormStore },
	{ path: '/writeOffOfProducts', exact: true, component: WriteOffOfProductsListContainer, store: WriteOffOfProductsListStore },
	{ path: '/writeOffOfProducts/:elementId', exact: true, component: WriteOffOfProductFormContainer, store: WriteOffOfProductFormStore },
	{ path: '/salesReports', exact: true, component: SalesReportsListContainer, store: SalesReportsListStore },
	{ path: '/salesReports/:elementId', exact: true, component: SalesReportFormContainer, store: SalesReportFormStore },
	{ path: '/saleOfProducts', exact: true, component: SaleOfProductsListContainer, store: SaleOfProductsListStore },
	{ path: '/saleOfProducts/:elementId', exact: true, component: SaleOfProductFormContainer, store: SaleOfProductFormStore },
	{ path: '/serialNumbers/:elementId', exact: true, component: SerialNumberFormContainer, store: SerialNumberFormStore },
	{ path: '/recountOfProducts', exact: true, component: RecountOfProductsListContainer, store: RecountOfProductsListStore },
	{ path: '/recountOfProducts/:elementId', exact: true, component: RecountOfProductFormContainer, store: RecountOfProductFormStore },
	{ path: '/receiptOfProducts', exact: true, component: ReceiptOfProductsListContainer, store: ReceiptOfProductsListStore },
	{ path: '/receiptOfProducts/:elementId', exact: true, component: ReceiptOfProductFormContainer, store: ReceiptOfProductFormStore },
	{ path: '/postingOfProducts', exact: true, component: PostingOfProductsListContainer, store: PostingOfProductsListStore },
	{ path: '/postingOfProducts/:elementId', exact: true, component: PostingOfProductFormContainer, store: PostingOfProductFormStore },
	{ path: '/checks', exact: true, component: ChecksListContainer, store: ChecksListStore },
	{ path: '/checks/:elementId', exact: true, component: CheckFormContainer, store: CheckFormStore },
	{ path: '/incomeCashOrders', exact: true, component: IncomeCashOrdersListContainer, store: IncomeCashOrdersListStore },
	{ path: '/incomeCashOrders/:elementId', exact: true, component: IncomeCashOrderFormContainer, store: IncomeCashOrderFormStore },
	{ path: '/outcomeCashOrders', exact: true, component: OutcomeCashOrdersListContainer, store: OutcomeCashOrdersListStore },
	{ path: '/outcomeCashOrders/:elementId', exact: true, component: OutcomeCashOrderFormContainer, store: OutcomeCashOrderFormStore },
	{ path: '/partnerContracts/:elementId', exact: true, component: PartnerContractFormContainer, store: PartnerContractFormStore },
	{ path: '/partnerContracts', exact: true, component: PartnerContractsListContainer, store: PartnerContractsListStore },
	{ path: '/reports/:elementId', exact: true, component: ReportContainer, store: ReportStore },
	{ path: '/settings/main', exact: true, component: MainSettingsContainer, store: MainSettingsStore },
	{ path: '/settings/warehouse', exact: true, component: WarehouseSettingsContainer, store: WarehouseSettingsStore },
	{ path: '/settings/loyaltyPolicy', exact: true, component: LoyaltyPolicySettingsContainer, store: LoyaltyPolicySettingsStore },
	{ path: '/settings/onlineStore', exact: true, component: OnlineStoreSettingsContainer, store: OnlineStoreSettingsStore },
	{ path: '/news', exact: true, component: NewsListContainer, store: NewsListStore },
	{ path: '/news/:elementId', exact: true, component: NewsFormContainer, store: NewsFormStore },
	{ path: '/cashRegisterShifts', exact: true, component: CashRegisterShiftsListContainer, store: CashRegisterShiftsListStore },
	{ path: '/cashRegisterShifts/:elementId', exact: true, component: CashRegisterShiftFormContainer, store: CashRegisterShiftFormStore },
	{ path: '/cashRegisters', exact: true, component: CashRegistersListContainer, store: CashRegistersListStore },
	{ path: '/cashRegisters/:elementId', exact: true, component: CashRegisterFormContainer, store: CashRegisterFormStore },
	{ path: '/cashBoxs', exact: true, component: CashBoxsListContainer, store: CashBoxsListStore },
	{ path: '/cashBoxs/:elementId', exact: true, component: CashBoxFormContainer, store: CashBoxFormStore },
	{ path: '/partnerBankAccounts', exact: true, component: PartnerBankAccountsListContainer, store: PartnerBankAccountsListStore },
	{ path: '/partnerBankAccounts/:elementId', exact: true, component: PartnerBankAccountFormContainer, store: PartnerBankAccountFormStore },
	{ path: '/movementOfProducts', exact: true, component: MovementOfProductsListContainer, store: MovementOfProductsListStore },
	{ path: '/movementOfProducts/:elementId', exact: true, component: MovementOfProductsFormContainer, store: MovementOfProductsFormStore },
	{ path: '/refundOfProducts', exact: true, component: RefundOfProductsListContainer, store: RefundOfProductsListStore },
	{ path: '/refundOfProducts/:elementId', exact: true, component: RefundOfProductsFormContainer, store: RefundOfProductsFormStore },
	{ path: '/printLabels/:elementId', exact: true, component: PrintLabelFormContainer, store: PrintLabelFormStore },
	{ path: '/naturalPersons', exact: true, component: NaturalPersonsListContainer, store: NaturalPersonsListStore },
	{ path: '/naturalPersons/:elementId', exact: true, component: NaturalPersonFormContainer, store: NaturalPersonFormStore },
	{ path: '/responsiblePersons', exact: true, component: ResponsiblePersonsListContainer, store: ResponsiblePersonsListStore },
	{ path: '/responsiblePersons/:elementId', exact: true, component: ResponsiblePersonFormContainer, store: ResponsiblePersonFormStore },
	{ path: '/subscriptionOptions', exact: true, component: SubscriptionOptionsListContainer, store: SubscriptionOptionsListStore },
	{ path: '/subscriptionOptions/:elementId', exact: true, component: SubscriptionOptionFormContainer, store: SubscriptionOptionFormStore },
	{ path: '/subscriptionPlans', exact: true, component: SubscriptionPlansListContainer, store: SubscriptionPlansListStore },
	{ path: '/subscriptionPlans/:elementId', exact: true, component: SubscriptionPlanFormContainer, store: SubscriptionPlanFormStore },
	{ path: '/', exact: true, component: NatStatistics, store: NatStatisticsStore, cloaseble: false },
	{ path: '/partnerSubscriptionOperations', exact: true, component: PartnerSubscriptionOperationsListContainer, store: PartnerSubscriptionOperationsListStore },
	{ path: '/partnerSubscriptionOperations/:elementId', exact: true, component: PartnerSubscriptionOperationFormContainer, store: PartnerSubscriptionOperationFormStore },
	{ path: '/acquiringOperations', exact: true, component: AcquiringOperationsListContainer, store: AcquiringOperationsListStore },
	{ path: '/acquiringOperations/:elementId', exact: true, component: AcquiringOperationFormContainer, store: AcquiringOperationFormStore },
	{ path: '/partnerSettlementOperations', exact: true, component: PartnerSettlementOperationsListContainer, store: PartnerSettlementOperationsListStore },
	{ path: '/partnerSettlementOperations/:elementId', exact: true, component: PartnerSettlementOperationFormContainer, store: PartnerSettlementOperationFormStore },
	{ path: '/shoppingCart', exact: true, component: NatShoppingCart, store: NatShoppingCartStore, modal: true },
	{ path: '/loyaltyPolicies', exact: true, component: LoyaltyPoliciesListContainer, store: LoyaltyPoliciesListStore },
	{ path: '/loyaltyPolicies/:elementId', exact: true, component: LoyaltyPolicyFormContainer, store: LoyaltyPolicyFormStore },
	{ path: '/loyaltyPolicyRules/:elementId', exact: true, component: LoyaltyPolicyRuleFormContainer, store: LoyaltyPolicyRuleFormStore },
	{ path: '/loyaltyPrograms', exact: true, component: LoyaltyProgramsListContainer, store: LoyaltyProgramsListStore },
	{ path: '/loyaltyPrograms/:elementId', exact: true, component: LoyaltyProgramFormContainer, store: LoyaltyProgramFormStore },
	{ path: '/loyaltyCardKinds', exact: true, component: LoyaltyCardKindsListContainer, store: LoyaltyCardKindsListStore },
	{ path: '/loyaltyCardKinds/:elementId', exact: true, component: LoyaltyCardKindFormContainer, store: LoyaltyCardKindFormStore },
	{ path: '/loyaltyCards', exact: true, component: LoyaltyCardsListContainer, store: LoyaltyCardsListStore },
	{ path: '/loyaltyCards/:elementId', exact: true, component: LoyaltyCardFormContainer, store: LoyaltyCardFormStore },
	{ path: '/couponKinds', exact: true, component: CouponKindsListContainer, store: CouponKindsListStore },
	{ path: '/couponKinds/:elementId', exact: true, component: CouponKindFormContainer, store: CouponKindFormStore },
	{ path: '/coupons', exact: true, component: CouponsListContainer, store: CouponsListStore },
	{ path: '/coupons/:elementId', exact: true, component: CouponFormContainer, store: CouponFormStore }
];

export let routesMap = _.chain(routes).cloneDeep().keyBy('path').value();
