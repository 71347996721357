import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';

import { controller } from '../core/Controllers/OrmController';
import { natiwiController } from '../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { ReportsStoreType } from '../reports/Reports/ReportsStore';
import WindowSuccess from '../shared/Alerts/WindowSuccess';
import { NavigationContainerStoreType } from '../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../shared/support/Catalog';
import { MatchType } from '../shared/support/modelTypes';
import { UseLocation } from '../shared/support/useLocationHoC';
import Registration from './Registration';
import ResetPassword from './ResetPassword';
import SetPassword from './SetPassword';
import UserLogin from './UserLogin';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	query: any;
}
interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	reportsStore: ReportsStoreType;
	directoryStore: OrmStoreType;
}

@inject('userStore', 'reportsStore', 'directoryStore', 'navigationContainerStore')
@observer
class UserLoginContainer extends Component<PropsType> {
	constructor(props: PropsType) {
		super(props);
		this.login = this.login.bind(this);
		this.registerTenantClient = this.registerTenantClient.bind(this);
		this.resetPasswordMasterClient = this.resetPasswordMasterClient.bind(this);
		this.resetPasswordTenantClient = this.resetPasswordTenantClient.bind(this);
		this.resetPasswordClient = this.resetPasswordClient.bind(this);
		this.setPassword = this.setPassword.bind(this);
		this.setPasswordMasterClient = this.setPasswordMasterClient.bind(this);
		this.setPasswordTenantClient = this.setPasswordTenantClient.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		const query = this.props.query;
		const redirect = query.get('redirect');
		if (redirect) {
			this.injected.userStore.setValue(this.injected.userStore, 'redirect', redirect);
		}
		if (this.injected.userStore.userLogin.isAuth) {
			this.props.history.push('/');
		}
	}
	login() {
		if (this.injected.directoryStore.models.apiData.mode === 'master') {
			return this.masterServerLogin();
		} else if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
			return this.tenantServerLogin();
		} else {
			return Promise.reject(new Error('Что-то пошло не так! Обратитесь в тех. поддержку.'));
		}
	}
	masterServerLogin() {
		let loginData = {
			email: this.injected.userStore.userLogin.email,
			password: this.injected.userStore.userLogin.password
		};
		if (!_.isEmpty(loginData.email) && !_.isEmpty(loginData.password)) {
			return controller.login(loginData).then((data) => {
				this.injected.navigationContainerStore.resetNavigationRoutes();
				if (!_.isEmpty(data)) {
					localStorage.clear();
					localStorage.setItem('accessToken', data.id);
					localStorage.setItem('masterAccessToken', data.id);
					localStorage.setItem('userId', data.userId);
					localStorage.setItem('isAuth', String(true));
				}
			});
		} else {
			return Promise.reject(new Error('Поля email и пароль обязательны для заполнения!'));
		}
	}
	tenantServerLogin() {
		let loginData = {
			login: this.injected.userStore.userLogin.login,
			password: this.injected.userStore.userLogin.password
		};
		if (!_.isEmpty(loginData.login) && !_.isEmpty(loginData.password)) {
			return natiwiController.login(loginData).then((data) => {
				this.injected.navigationContainerStore.resetNavigationRoutes();
				if (!_.isEmpty(data)) {
					localStorage.clear();
					localStorage.setItem('realm', data.realm);
					localStorage.setItem('tenantClientId', data.tenantClientId);
					if (data.tenantAccessToken) {
						localStorage.setItem('accessToken', data.tenantAccessToken.id);
						localStorage.setItem('userId', data.tenantAccessToken.userId);
					}
					if (data.masterAccessToken) {
						localStorage.setItem('masterAccessToken', data.masterAccessToken.id);
					}
					localStorage.setItem('isAuth', String(true));
				}
			});
		} else {
			return Promise.reject(new Error('Поля логин и пароль обязательны для заполнения!'));
		}
	}
	registerTenantClient() {
		let data: any = _.cloneDeep(this.injected.userStore.userRegistration);
		delete data.repeatPassword;
		return natiwiController.registerTenantClient(data).then((data) => {
			if (!_.isEmpty(data)) {
				localStorage.setItem('masterAccessToken', data.accessToken.id);
				localStorage.setItem('accessToken', data.accessToken.id);
				localStorage.setItem('userId', data.user.id);
				localStorage.setItem('username', data.user.username);
			}
		});
	}
	deployTenantClient() {
		return natiwiController.deployTenantClient().then((data) => {
			if (!_.isEmpty(data)) {
				localStorage.setItem('realm', data.tenantClientRealm);
				localStorage.setItem('tenantClientId', data.tenantClientId);
			}
		});
	}
	checkTenantClientDeployAvailability() {
		return new Promise((resolve, reject) => {
			let interval = setInterval(() => {
				natiwiController
					.checkTenantClientDeployAvailability()
					.then((data) => {
						if (!_.isEmpty(data)) {
							if (data.result) {
								clearInterval(interval);
								return resolve(null);
							}
						}
					})
					.catch((error) => {
						clearInterval(interval);
						return reject(error);
					});
			}, 5000);
		});
	}
	resetPasswordMasterClient() {
		let data = {
			email: this.injected.userStore.userRecoveryPassword.recoveryEmail,
			callbackUrl: window.location.origin + '/users/setPassword'
		};
		return controller.resetPassword(data).then(() => {
			this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'recoveryEmail', '');
		});
	}
	resetPasswordTenantClient() {
		let resetPasswordData = {
			login: this.injected.userStore.userRecoveryPassword.recoveryEmail,
			callbackUrl: window.location.origin + '/users/setPassword'
		};
		return natiwiController.resetPasswordTenantClient(resetPasswordData).then((data) => {
			if (!_.isEmpty(data)) {
				if (data.result) {
					this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'recoveryEmail', '');
				} else {
					return Promise.reject(new Error('Сообщение на почту не было отправлено! Обратитесь в тех. поддержку.'));
				}
			}
		});
	}
	resetPasswordClient() {
		if (this.injected.directoryStore.models.apiData.mode === 'master') {
			return this.resetPasswordMasterClient();
		} else if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
			return this.resetPasswordTenantClient();
		} else {
			return Promise.reject(new Error('Что-то пошло не так! Обратитесь в тех. поддержку.'));
		}
	}
	setPasswordMasterClient() {
		let data = {
			data: {
				password: this.injected.userStore.userRecoveryPassword.password
			},
			id: this.injected.userStore.userRecoveryPassword.userId,
			token: this.injected.userStore.userRecoveryPassword.accessTokenId
		};
		return controller.patchUser(data).then((response) => {
			if (response.status === 200) {
				this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'password', '');
				this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'userId', '');
				this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'accessTokenId', '');
			}
		});
	}
	setPasswordTenantClient() {
		let setPasswordData = {
			password: this.injected.userStore.userRecoveryPassword.password,
			userId: this.injected.userStore.userRecoveryPassword.userId,
			accessTokenId: this.injected.userStore.userRecoveryPassword.accessTokenId,
			tenantClientId: this.injected.userStore.userRecoveryPassword.tenantClientId
		};
		return natiwiController.setPasswordTenantClient(setPasswordData).then((data) => {
			if (!_.isEmpty(data)) {
				if (data.result) {
					this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'password', '');
					this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'userId', '');
					this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'accessTokenId', '');
					this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'tenantClientId', '');
				} else {
					return Promise.reject(new Error('Пароль не был изменен! Обратитесь в тех. поддержку.'));
				}
			}
		});
	}
	setPassword() {
		if (this.injected.directoryStore.models.apiData.mode === 'master') {
			return this.setPasswordMasterClient();
		} else if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
			return this.setPasswordTenantClient();
		} else {
			return Promise.reject(new Error('Что-то пошло не так! Обратитесь в тех. поддержку.'));
		}
	}
	render() {
		return (
			<>
				<Route exact path="/users/login" render={() => <UserLogin login={this.login} />} />
				<Route exact path="/users/registration" render={() => <Registration registerTenantClient={this.registerTenantClient} />} />
				<Route exact path="/users/resetPassword" render={() => <ResetPassword resetPasswordClient={this.resetPasswordClient} />} />
				<Route exact path="/users/setPassword" render={() => <SetPassword setPassword={this.setPassword} />} />
				<Route exact path="/users/setPassword/success" render={() => <WindowSuccess title="Восстановление пароля прошло успешно!" />} />
				<Route
					exact
					path="/users/registration/deployTenantClient"
					render={() => (
						<WindowSuccess
							title="Подготавливаем рабочую среду. Это может занять некоторое время..."
							loadPage={() => {
								this.deployTenantClient()
									.then(() => {
										localStorage.setItem('isAuth', String(true));
										localStorage.setItem('showWelcomeMessage', String(true));
										return this.checkTenantClientDeployAvailability();
									})
									.then(() => {
										this.props.history.push('/users/login');
										window.location.reload();
									})
									.catch((error) => {
										catalog.handleNatError(error);
									});
							}}
							justInfo
							loader
						/>
					)}
				/>
				<Route
					exact
					path="/users"
					render={() => {
						return <Redirect to="/users/login" />;
					}}
				/>
			</>
		);
	}
}

export default UseLocation(withRouter(UserLoginContainer));
