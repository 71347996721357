import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../Alerts/FormAlertStore';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	columns: Array<any>;
	goTo(path: string, state?: Record<string, unknown>): void;
	data: {
		list: Array<any>;
		filterWhere?: { productId?: string; ownerId?: string };
		filterInclude?: Array<string>;
		filterSkip?: number;
		itemsPerPage?: number;
		filterOrder?: Array<string>;
		pluralName: string;
	};
	children: ReactElement;
	store: any;
	collectModels(arrayIds: Array<any>, array: Array<any>): void;
	setValue(model: any, key: string, value: any): void;
	scrollable?: boolean;
	sortable?: boolean;
	selectableRow?: boolean;
	className: string;
	wrapperClassName?: string;
}

interface StateType {
	querySelector: any;
	colId: number | null;
	timer: any;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class NatFormList extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	currentPage: number;
	skip: number;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.sortList = this.sortList.bind(this);
		this.reactions = [];
		this.state = {
			querySelector: null,
			colId: null,
			timer: null
		};
		this.currentPage = 0;
		this.skip = 0;
		this.reactions.push(
			reaction(
				() => this.props.data.filterSkip,
				(value) => {
					if (value === 0) {
						this.state.querySelector.scrollTo(0, 0);
						this.currentPage = 0;
						this.skip = 0;
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.myRef.current.querySelector('.rgt-container')?.removeEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: null
		});
	}
	handleScrollToElement() {
		if (this.props.scrollable) {
			let scrollTop = this.state.querySelector.scrollTop;
			let scrollHeight = this.state.querySelector.scrollHeight;
			let clientHeight = this.state.querySelector.clientHeight;
			let arr = this.props.data.list;
			let itemsPerPage = _.get(this.props.data, 'itemsPerPage', 50);
			let itemsListLength = arr.length;
			if (scrollTop >= scrollHeight - clientHeight && this.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
				this.currentPage = this.currentPage + 1;
				this.skip = this.currentPage * itemsPerPage;
				this.props.setValue(this.props.data, 'filterSkip', this.skip);
				let filter: any = {
					skip: this.skip,
					limit: this.props.data.itemsPerPage,
					order: this.props.data.filterOrder,
					include: this.props.data.filterInclude
				};
				if (!_.isEmpty(this.props.data.filterWhere)) {
					filter.where = this.props.data.filterWhere;
				}
				this.props.store.setValue(this.props.store, 'isLoading', true);
				controller
					.findAll(this.props.data.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.setValue(this.props.data, 'list', this.props.data.list.concat(data));
						}
						this.props.store.setValue(this.props.store, 'isLoading', false);
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.props.store.setValue(this.props.store, 'isLoading', false);
					});
			}
		}
	}
	sortList(colId: number, isAsc: boolean) {
		if (this.props.sortable) {
			let order: Array<string> = [];
			if (colId !== null) {
				const groupedColumns = _.groupBy(this.props.columns, 'id');
				const sortBy = isAsc ? 'ASC' : 'DESC';
				order = [`${groupedColumns[colId][0].field} ${sortBy}`];
			} else {
				order = ['createdAt DESC'];
			}
			this.state.querySelector.scrollTo(0, 0);
			this.props.setValue(this.props.data, 'filterOrder', order);
			this.currentPage = 0;
			this.skip = 0;
			this.props.store.setValue(this.props.store, 'isLoading', true);
			let filter: any = {
				skip: this.skip,
				limit: this.props.data.itemsPerPage,
				order: this.props.data.filterOrder,
				include: this.props.data.filterInclude
			};
			if (!_.isEmpty(this.props.data.filterWhere)) {
				filter.where = this.props.data.filterWhere;
			}
			controller
				.findAll(this.props.data.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.setValue(this.props.data, 'list', data);
					}
					this.props.store.setValue(this.props.store, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.props.store.setValue(this.props.store, 'isLoading', false);
				});
		}
	}
	render() {
		return (
			<>
				{this.props.children}
				<div className={_.isEmpty(this.props.wrapperClassName) ? 'nat__form__table__wrapper m-4 h-100' : this.props.wrapperClassName}>
					<form className={this.props.className} ref={this.myRef}>
						<GridTable
							columns={this.props.columns}
							rows={_.get(this.props.data, 'list', [])}
							isPaginated={false}
							texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
							showSearch={false}
							showColumnVisibilityManager={false}
							showRowsInformation={false}
							onColumnsChange={() => {}}
							onRowClick={({ data, column }: { data: any; column: any }) => {
								if (this.props.selectableRow) {
									if (column.id !== 'checkbox') {
										this.props.goTo(`/${this.props.data.pluralName}/` + data.id);
									}
								}
							}}
							selectedRowsIds={_.map(this.props.store.collectedModels, 'id')}
							onSelectedRowsChange={(selectedRowsIds: any) => {
								this.props.collectModels(selectedRowsIds, this.props.data.list);
							}}
							onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
								this.sortList(colId, isAsc);
							}}
						/>
					</form>
				</div>
			</>
		);
	}
}

export default withRouter(NatFormList);
