import { CButton, CCardHeader, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalHeader, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { natiwiController } from '../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { listDateTimeFormatter } from '../shared/Formatters/ListDateTimeFormatter';
import { catalog } from '../shared/support/Catalog';
import NatPartnerSubscriptionInfoStore, { NatPartnerSubscriptionInfoStoreType } from './NatPartnerSubscriptionInfoStore';

interface PropsType extends RouteComponentProps {
	toggleNatPartnerSubscriptionInfo(status: boolean): void;
	isModalOpen: boolean;
}

interface StateType {
	querySelector: any;
	timer: any;
	columns: Array<any>;
	isFilterOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatPartnerSubscriptionInfo extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	natPartnerSubscriptionInfoStore: NatPartnerSubscriptionInfoStoreType;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.natPartnerSubscriptionInfoStore = new NatPartnerSubscriptionInfoStore();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.natPartnerSubscriptionInfoStore.setValue(this.natPartnerSubscriptionInfoStore, 'isLoading', true);
		natiwiController
			.fetchPartnerSubscriptionInfo()
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natPartnerSubscriptionInfoStore.setValue(this.natPartnerSubscriptionInfoStore, 'partnerSubscriptionInfo', data);
				}
				this.natPartnerSubscriptionInfoStore.setValue(this.natPartnerSubscriptionInfoStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natPartnerSubscriptionInfoStore.setValue(this.natPartnerSubscriptionInfoStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatPartnerSubscriptionInfo(false);
				}
			}
		];
		return ReactDOM.createPortal(
			<>
				<CModal
					show={this.props.isModalOpen}
					className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
					size="xl"
					closeOnBackdrop={false}>
					<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<div className="d-flex flex-row mx-3 mb-4 mt-4 nat__partner__subscription__info__wrapper flex-wrap nat__scrollbar">
						{_.map(this.natPartnerSubscriptionInfoStore.partnerSubscriptionInfo.subscriptionItemList, (item: any, index: number) => {
							return (
								<div
									className={(() => {
										if (this.injected.directoryStore.models.windowSize > 900) {
											return 'px-2 pb-3 nat__partner__subscription__info__item';
										} else {
											return 'px-2 pb-3 nat__partner__subscription__info__item w-100 nat__partner__subscription__info__item__mobile';
										}
									})()}
									key={index}>
									<div className="nat__partner__subscription__info__item__wrapper nat__card__wrapper nat__borders__2-75 p-2">
										<CCardHeader className="bg-transparent text-dark text-left font__size__16 p-2 pl-3 d-flex">
											<span>{catalog.renderCatalogName(item.subscriptionPlan)}</span>
											<span className="ml-auto">
												{(() => {
													if (item.expiredAt) {
														return listDateTimeFormatter.renderValue(item.expiredAt);
													} else {
														return 'Навсегда';
													}
												})()}
											</span>
										</CCardHeader>
										{_.map(item.subscriptionOptionItemList, (itm, ind: number) => {
											return (
												<div className="d-flex justify-content-between p-2 pl-3" key={ind}>
													<div className="font__size__16 text-dark text-left nat__partner__subscription__info__item__wrapper__text">{catalog.renderCatalogName(itm.subscriptionOption)}</div>
													<div className="font__size__16 text-dark text-right nat__partner__subscription__info__item__wrapper__text">{itm.quantity}</div>
													<div className="font__size__16 text-dark text-right nat__partner__subscription__info__item__wrapper__text">
														{(() => {
															if (itm.expiredAt) {
																return listDateTimeFormatter.renderValue(itm.expiredAt);
															} else {
																return 'Навсегда';
															}
														})()}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
					{this.natPartnerSubscriptionInfoStore.isLoading && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
				</CModal>
			</>,
			document.querySelector('#modal') as Element
		);
	}
}

export default withRouter(NatPartnerSubscriptionInfo);
