import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import React, { Component } from 'react';

export interface PropsType {
	object: any;
	property: string;
	label?: string;
	readonly?: boolean;
	disabled?: boolean;
	relation?: string;
	validation?: any;
}

interface StateType<T> {
	value: T;
	chips: Array<T>;
	relation: any;
	validationItemList: Array<any>;
	showDropdown: boolean;
	autocompleteItems: Array<T>;
}

abstract class NatValueField<T, K, V extends PropsType> extends Component<V, StateType<K>> {
	reactions: Array<IReactionDisposer>;
	constructor(props: V) {
		super(props);
		this.state = {
			value: this.renderValue(this.getValue()),
			chips: this.props.object[this.props.property],
			relation: this.props.object[_.get(this.props, 'relation', '')],
			validationItemList: [],
			showDropdown: false,
			autocompleteItems: []
		};
		this.reactions = [];
		this.handleChange = this.handleChange.bind(this);
		this.addValidation = this.addValidation.bind(this);
		this.getValue = this.getValue.bind(this);
		this.setValue = this.setValue.bind(this);
		this.validate = this.validate.bind(this);
	}
	protected abstract handleChange(e: React.ChangeEvent<HTMLInputElement>);
	componentDidMount() {
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(value)
						});
					}
				}
			)
		);
		this.addValidation(this.props.validation);
	}
	componentDidUpdate() {
		// this.setValue(this.parseValue(this.state.value));
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	protected toggleDropdown(status: boolean) {
		this.setState({
			showDropdown: status
		});
	}
	protected setValue(value: T) {
		let object: any = this.props.object;
		runInAction(() => {
			object[this.props.property] = value;
		});
	}
	protected getValue(): T {
		let object: any = this.props.object;
		return object[this.props.property];
	}

	protected addValidation(validation): void {
		let validationItemList: Array<any> = [];
		if (validation !== undefined) {
			if (_.isArray(validation)) {
				validationItemList = validation;
			} else {
				validationItemList.push(validation);
			}
		}
		this.setState({
			validationItemList
		});
	}

	protected validate(object: any, value: any): boolean {
		let result: boolean = true;
		for (let validation of this.state.validationItemList) {
			let validationResult = validation(object, value);
			if (!_.isEmpty(validationResult)) {
				if (!validationResult.result) {
					result = false;
					break;
				} else {
					result = true;
				}
			} else {
				result = true;
			}
		}
		return result;
	}

	protected abstract parseValue(value: K): T;

	protected abstract renderValue(value: T): K;
}

export default NatValueField;
