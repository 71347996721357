import { observable, action, makeObservable, set } from 'mobx';

import { ProductType } from '../../shared/support/modelTypes';

interface ListType {
	list: Array<any>;
	filterWhere: { productId?: string; ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterSkip?: number;
	itemsPerPage?: number;
	filterOrder: Array<string>;
	pluralName: string;
}

export interface ProductFormStoreType {
	model: ProductType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	productBarcodes: ListType;
	serialNumbers: ListType;
	productFeatures: ListType;
	files: ListType;
	ownerType: string;
	containerType: string;
	priceTypes: ListType;
	prices: { list: Array<any>; pluralName: string; pricesTableList: Array<any>; isRoot: boolean };
	isFirstLoad: boolean;
	name: string;
	language: string;
	setValue(model: any, key: string, value: any): void;
}

class ProductFormStore implements ProductFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			productBarcodes: observable,
			serialNumbers: observable,
			productFeatures: observable,
			files: observable,
			ownerType: observable,
			containerType: observable,
			priceTypes: observable,
			prices: observable,
			isFirstLoad: observable,
			name: observable,
			language: observable,
			setValue: action
		});
	}

	model = {
		accountingOptionId: 'product_accounting_options.standart',
		area: 0,
		areaUnitId: null,
		article: null,
		caption: null,
		deleted: false,
		description: null,
		displayName: null,
		elementType: 0,
		giftCardValidityExpiredAt: null,
		giftCardValidityPeriodCount: 0,
		giftCardValidityPeriodId: 'periodicity.year',
		giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
		giftCardValue: 0,
		height: 0,
		heightUnitId: null,
		kindId: null,
		labelTemplateId: null,
		length: 0,
		lengthUnitId: null,
		name: { ru: '' },
		organizationId: null,
		packageSetId: null,
		parentId: null,
		primaryImageId: null,
		priority: null,
		supplierId: null,
		typeId: 'product_types.goods',
		unitId: null,
		useArea: false,
		useCustomLabelTemplate: false,
		useFeatureOptionId: 'use_feature_options.not_used',
		useGiftCardPartially: false,
		useHeight: false,
		useLength: false,
		usePackageOptionId: 'use_package_options.not_used',
		useSerialNumbers: false,
		useVolume: false,
		useWeight: false,
		useWidth: false,
		volume: 0,
		volumeUnitId: null,
		weight: 0,
		weightCanManuallySet: false,
		weightUnitId: null,
		width: 0,
		widthUnitId: null,
		images: [],
		useQuantityLimit: false,
		quantityLimitRange: null,
		validityPeriodId: 'periodicity.month',
		subscriptionPlanId: null,
		subscriptionOptionId: null,
		setPrintOptionId: 'product_set_print_options.only_set',
		setPriceCalculationMethodId: 'product_set_price_calculation_methods.by_product_prices',
		setPriceCalculationMethod: {
			description: null,
			id: 'product_set_price_calculation_methods.by_product_prices',
			keyId: 'by_product_prices',
			name: { ru: 'Сумма цен номенклатуры' },
			ownerId: 'product_set_price_calculation_methods',
			predefined: true,
			predefinedName: 'ProductSetPriceCalculationMethodsByProductPrices',
			predefinedVersion: 0,
			priority: 0
		},
		setPrintOption: {
			description: null,
			id: 'product_set_print_options.only_set',
			keyId: 'only_set',
			name: { ru: 'Только набор' },
			ownerId: 'product_set_print_options',
			predefined: true,
			predefinedName: 'ProductSetPrintOptionsOnlySet',
			predefinedVersion: 0,
			priority: 0
		},
		validityPeriod: {
			id: 'periodicity.month',
			keyId: 'month',
			ownerId: 'periodicity',
			name: { ru: 'Месяц' },
			description: null,
			priority: 6,
			predefined: true,
			predefinedName: 'PeriodicityMonth',
			predefinedVersion: 0
		},
		type: {
			id: 'product_types.goods',
			keyId: 'goods',
			ownerId: 'product_types',
			name: { ru: 'Товар' },
			description: null,
			predefined: true,
			predefinedName: 'ProductTypesGoods',
			predefinedVersion: 0
		},
		accountingOption: {
			id: 'product_accounting_options.standart',
			keyId: 'standart',
			ownerId: 'product_accounting_options',
			name: { ru: 'Стандартный' },
			description: null,
			predefined: true,
			predefinedName: 'ProductAccountingOptionsStandart',
			predefinedVersion: 0
		},
		usePackageOption: {
			id: 'use_package_options.not_used',
			keyId: 'not_used',
			ownerId: 'use_package_options',
			name: { ru: 'Не используется' },
			description: null,
			predefined: true,
			predefinedName: 'UsePackageOptionsNotUsed',
			predefinedVersion: 0
		},
		useFeatureOption: {
			id: 'use_feature_options.not_used',
			keyId: 'not_used',
			ownerId: 'use_feature_options',
			name: { ru: 'Не используется' },
			description: null,
			predefined: true,
			predefinedName: 'UseFeatureOptionsNotUsed',
			predefinedVersion: 0
		}
	};
	language = 'ru';
	list = [];
	productBarcodes = {
		list: [],
		filterWhere: {},
		filterInclude: ['unit', 'feature', 'product'],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'productBarcodes'
	};
	serialNumbers = {
		list: [],
		filterWhere: {},
		filterInclude: [],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'serialNumbers'
	};
	productFeatures = {
		list: [],
		filterWhere: {},
		filterInclude: [],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'productFeatures'
	};
	files = {
		list: [],
		filterWhere: {},
		filterInclude: [],
		filterOrder: ['createdAt DESC'],
		pluralName: 'files'
	};
	priceTypes = {
		list: [],
		filterWhere: { deleted: false },
		filterInclude: [],
		filterOrder: ['createdAt DESC'],
		pluralName: 'priceTypes'
	};
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'products';
	filterInclude = ['unit', 'type', 'kind', 'accountingOption', 'usePackageOption', 'useFeatureOption', 'primaryImage', 'parent', 'subscriptionPlan', 'subscriptionOption', 'validityPeriod', 'setPriceCalculationMethod', 'setPrintOption'];
	collectedModels = [] as Array<any>;
	ownerType = 'OrmProduct';
	containerType = 'OrmContainerMetadata';
	prices = {
		list: [],
		pricesTableList: [],
		pluralName: 'productPrices',
		isRoot: true
	};
	isFirstLoad = true;
	name = 'OrmProduct';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productFormStore = new ProductFormStore();

export default ProductFormStore;
