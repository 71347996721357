import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { FormAlertStoreType } from './FormAlertStore';

interface PropsType {}

interface InjectedProps extends PropsType {
	formAlertStore: FormAlertStoreType;
}

@inject('formAlertStore')
@observer
class FormAlert extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<CToaster>
				<CToast
					show={this.injected.formAlertStore.showAlert}
					autohide={3000}
					onStateChange={(status) => {
						if (!status) {
							runInAction(() => {
								this.injected.formAlertStore.showAlert = false;
								this.injected.formAlertStore.alertText = '';
							});
						}
					}}>
					<CToastHeader
						closeButton
						onClick={() =>
							runInAction(() => {
								this.injected.formAlertStore.showAlert = false;
								this.injected.formAlertStore.alertText = '';
							})
						}>
						<span className="ml-auto">Внимание!</span>
					</CToastHeader>
					<CToastBody>{this.injected.formAlertStore.alertText}</CToastBody>
				</CToast>
			</CToaster>
		);
	}
}

export default FormAlert;
