import _ from 'lodash';
import { IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import NatSettingsContainer from '../shared/NatSettingsContainer';
import Settings from '../shared/Settings';
import { MainSettingsStoreType } from './MainSettingsStore';

interface PropsType extends FormContainerPropsType<MainSettingsStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class MainSettingsContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.prepareForPost = this.prepareForPost.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	prepareForPost(list: Array<any>) {
		_.forEach(list, (item) => {
			if (item.valueType === 'value_types.relation') {
				delete item.value;
			}
		});
		return list;
	}
	render() {
		return (
			<NatSettingsContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				prepareForPost={this.prepareForPost}
				parentRoute="/settings/main"
				title="Общие"
				handlers={this.props.handlers}>
				{(childrenProps) => <Settings removeNavigationRoute={this.props.removeNavigationRoute} store={this.props.store} navigationRoute={this.props.navigationRoute} goTo={this.props.goTo} childrenProps={childrenProps} />}
			</NatSettingsContainer>
		);
	}
}

export default MainSettingsContainer;
