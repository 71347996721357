import { CButton, CCardHeader, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CRow, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatAclMapping from '../Modals/NatAclMapping';
import ProductSelection from '../Modals/ProductSelection';
import { catalog } from '../support/Catalog';

// import NatAclMapping from '../../shared/Components/NatAclMapping';

interface PropsType {
	fields: ReactElement | null;
	tableControlPanel?: (toggleSelection: (status: boolean) => void) => ReactElement;
	tablePart?: ReactElement;
	store: any;
	makeReactions?: () => void;
	productItemTemplate?: any;
	selectionColumns?: any;
	productSelection?: boolean;
	alert?: ReactElement;
	classNameRow?: string;
	toggleSelection?: (status: boolean) => void;
	isModalOpen?: boolean;
	controlButtons?: Array<ButtonItem>;
	saveProductItems?: (productItems: Array<any>) => void;
	isAclModalOpen: boolean;
	toggleAclModal(status: boolean): void;
}

interface StateType {
	isModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('directoryStore', 'userStore')
@observer
class NatDefaultFormTemplate extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.state = {
			isModalOpen: false
		};
		this.toggleSelection = this.toggleSelection.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleSelection(status: boolean) {
		if (this.props.toggleSelection === undefined) {
			this.setState({
				isModalOpen: status
			});
		} else {
			this.props.toggleSelection(status);
		}
		if (status) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	render() {
		return (
			<div className="d-flex nat__form nat__borders__2-75 default__nat__form__body__height nat__scrollbar">
				<div className="nat__table__wrapper nat__table__hover">
					<CCardHeader className="nat__form__header">
						<CCardHeader
							className={(() => {
								if (_.isEmpty(this.props.fields)) {
									return 'd-flex align-items-center nat__form__control__buttons nat__card__wrapper nat__borders__2-75 mb-4 mt-4 p-2 mx-4';
								} else {
									return 'd-flex align-items-center nat__form__control__buttons nat__card__wrapper nat__borders__2-75 mb-3 mt-4 p-2 mx-4';
								}
							})()}>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(this.props.controlButtons, (item, index) => {
												if (!item.dontShow) {
													if (!item.children) {
														return (
															<CButton
																key={index}
																type="button"
																size="sm"
																className={item.className ? item.className : 'mr-1 nat__button__hover'}
																onClick={(e) => {
																	item.onClick(e);
																}}
																disabled={item.disabled}>
																{item.title}
															</CButton>
														);
													} else {
														return (
															<CDropdown key={index}>
																<CDropdownToggle className="mr-1 nat__button__hover" disabled={item.disabled}>
																	{item.title}
																</CDropdownToggle>
																<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
																	{_.map(item.children, (item, index) => {
																		return (
																			<CDropdownItem
																				key={index}
																				onClick={(e) => {
																					item.onClick(e);
																				}}
																				disabled={item.disabled}>
																				{item.title}
																			</CDropdownItem>
																		);
																	})}
																</CDropdownMenu>
															</CDropdown>
														);
													}
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(this.props.controlButtons, (item, index) => {
													if (!item.dontShow) {
														if (!item.children) {
															return (
																<CDropdownItem
																	key={index}
																	onClick={(e) => {
																		item.onClick(e);
																	}}
																	disabled={item.disabled}>
																	{item.title}
																</CDropdownItem>
															);
														} else {
															return (
																<>
																	<CDropdownItem header key={index}>
																		{item.title}
																	</CDropdownItem>
																	{_.map(item.children, (item, index) => {
																		return (
																			<CDropdownItem
																				key={index}
																				onClick={(e) => {
																					item.onClick(e);
																				}}
																				disabled={item.disabled}>
																				{item.title}
																			</CDropdownItem>
																		);
																	})}
																</>
															);
														}
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</CCardHeader>
					</CCardHeader>
					<div className="nat__table__wrapper nat__table__wrapper__overflow nat__scrollbar">
						<CForm
							className={(() => {
								if (_.isEmpty(this.props.fields)) {
									return 'mx-5';
								} else {
									return 'mx-5 mb-3';
								}
							})()}>
							<CRow className={this.props.classNameRow !== undefined ? this.props.classNameRow : ''}>{this.props.fields}</CRow>
						</CForm>
						{this.props.tablePart !== undefined && <div className="nat__form__table__wrapper mx-4 mb-4 default__nat__card__body__height">{this.props.tablePart}</div>}
					</div>
					{this.props.store.isLoading && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
					{(() => {
						let isModalOpen: boolean = false;
						if (this.props.isModalOpen === undefined) {
							isModalOpen = this.state.isModalOpen;
						} else {
							isModalOpen = this.props.isModalOpen;
						}
						if (this.props.productSelection) {
							if (isModalOpen) {
								return (
									<ProductSelection
										toggleFeedSelection={this.toggleSelection}
										isModalOpen={isModalOpen}
										productItemsColumns={this.props.selectionColumns}
										modelStore={this.props.store}
										makeReactions={this.props.makeReactions}
										productItemTemplate={this.props.productItemTemplate}
										saveProductItems={this.props.saveProductItems}
									/>
								);
							}
						}
					})()}
					{this.props.alert !== undefined && this.props.alert}
					{this.props.isAclModalOpen && <NatAclMapping isModalOpen={this.props.isAclModalOpen} toggleNatAclMapping={this.props.toggleAclModal} subject={[this.props.store.model]} model={this.props.store.name} />}
				</div>
			</div>
		);
	}
}

export default NatDefaultFormTemplate;
