import _ from 'lodash';

import ValueFormatter from './ValueFormatter';

class SelectFormatter extends ValueFormatter<any, string> {
	public parseValue(value: any): any {
		throw new Error('Не поддерживается');
	}

	public renderValue(relation: any): string {
		let value: string = '';
		if (!_.isEmpty(relation)) {
			let name = '';
			name = relation.displayName;
			if (name !== null) {
				value = name;
			}
		}
		return value;
	}
}

export let selectFormatter = new SelectFormatter();

export default SelectFormatter;
