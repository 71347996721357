export const config = {
	CLIENT_API_URL: (() => {
		if (process.env.REACT_APP_TENANT_API_URL) {
			return process.env.REACT_APP_TENANT_API_URL;
		}
		return window.location.origin + '/api';
	})(),
	NATIWI_API_URL: (() => {
		if (process.env.REACT_APP_MASTER_API_URL) {
			return process.env.REACT_APP_MASTER_API_URL;
		}
		throw new Error('Не указана переменная окружения REACT_APP_MASTER_API_URL');
	})()
};
