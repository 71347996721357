import { CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Component } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { controller } from './core/Controllers/OrmController';
import { natiwiController } from './core/Controllers/OrmNatiwiController';
import { directoryStore } from './core/Stores/DirectoryStore';
import { userStore } from './core/Stores/OrmUserStore';
import { natFillModalStore } from './printLabels/shared/NatFillModalStore';
import { productBarcodeFormStore } from './productBarcodes/ProductBarcode/ProductBarcodeFormStore';
import { reportsStore } from './reports/Reports/ReportsStore';
import './scss/style.scss';
import FormAlert from './shared/Alerts/FormAlert';
import { formAlertStore } from './shared/Alerts/FormAlertStore';
import WindowSuccess from './shared/Alerts/WindowSuccess';
import AppRouter from './shared/Containers/AppRouter';
import { navigationContainerStore } from './shared/ContainersStores/NavigationContainerStore';
import { natAclMappingStore } from './shared/Modals/NatAclMappingStore';
import { natImageManagerStore } from './shared/Modals/NatImageManagerStore';
import { natRemoveBackgroundStore } from './shared/Modals/NatRemoveBackgroundStore';
import { natSelectionStore } from './shared/Modals/NatSelectionStore';
import { catalog } from './shared/support/Catalog';
import { MatchType } from './shared/support/modelTypes';

const stores = {
	userStore,
	directoryStore,
	navigationContainerStore,
	formAlertStore,
	productBarcodeFormStore,
	natSelectionStore,
	reportsStore,
	natRemoveBackgroundStore,
	natImageManagerStore,
	natFillModalStore,
	natAclMappingStore
};
interface PropsType extends RouteComponentProps {
	match: MatchType;
}
interface StateType {
	isInitialized: boolean;
}
@observer
class App extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.state = {
			isInitialized: false
		};
		this.reactions = [];
		this.reactions.push(
			reaction(
				() => directoryStore.models.requestError.status,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (userStore.userLogin.isAuth) {
							if (value === 401 || (value === 500 && _.get(directoryStore.models.requestError, 'data.error.code', '') === 'WORKPLACE_COUNT_LIMIT')) {
								userStore.setValue(userStore.userLogin, 'isAuth', false);
								localStorage.clear();
								this.props.history.push('/users/login');
								directoryStore.setValue(directoryStore.models, 'requestError', { status: 0 });
							}
						}
					}
				}
			)
		);
	}
	componentDidMount() {
		if (localStorage.getItem('isAuth') === 'true') {
			userStore.setValue(userStore.userLogin, 'isAuth', Boolean(localStorage.getItem('isAuth')));
		}
		let promise = controller.api().then((data) => {
			if (!_.isEmpty(data)) {
				directoryStore.setValue(directoryStore.models, 'apiData', data);
			}
		});
		if (userStore.userLogin.isAuth) {
			promise = promise
				.then(() => {
					return controller.me();
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						userStore.setValue(userStore.userLogin, 'user', data);
					}
					if (directoryStore.models.apiData.mode === 'tenant') {
						if (localStorage.getItem('masterAccessToken')) {
							return natiwiController.fetchPartnerSubscriptionInfo();
						}
					}
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						directoryStore.setValue(directoryStore.models, 'partnerSubscriptionInfo', data);
					}
					return controller.findAll('constants', {
						where: { deleted: false },
						include: ['value']
					});
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						directoryStore.setValue(directoryStore.models, 'constants', _.chain(data).keyBy('predefinedName').value());
					}
					return controller.findAll('locales', {
						where: { deleted: false }
					});
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						directoryStore.setValue(directoryStore.models, 'locales', data);
					}
					if (directoryStore.models.apiData.mode === 'tenant') {
						if (localStorage.getItem('masterAccessToken')) {
							return natiwiController.fetchCurrentPartnerSettlement();
						}
					}
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						directoryStore.setValue(directoryStore.models, 'currentPartnerSettlement', data);
					}
					let filter = {
						where: { deleted: false }
					};
					return controller.findAll(reportsStore.pluralName, filter);
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						let navigationGroupsMap: any = _.chain(navigationContainerStore.navigationGroups).keyBy('group').value();
						if (navigationGroupsMap['reports']) {
							if (navigationGroupsMap['reports'].children.reports) {
								let navigationReportsMap = _.chain(navigationGroupsMap['reports'].children.reports.list).keyBy('id').value();
								for (let item of data) {
									if (!navigationReportsMap[item.id]) {
										runInAction(() => {
											navigationGroupsMap['reports'].children.reports.list.push({
												url: `/reports/${item.id}`,
												title: catalog.renderCatalogName(item),
												id: item.id
											});
										});
									}
								}
							}
						}
						reportsStore.setValue(reportsStore, 'list', data);
					}
					this.setState({
						isInitialized: true
					});
				});
		} else {
			promise = promise.then(() => {
				this.setState({
					isInitialized: true
				});
			});
		}
		promise.catch((error) => {
			catalog.handleNatError(error);
			if (_.isEmpty(directoryStore.models.appError)) {
				this.setState({
					isInitialized: true
				});
			}
		});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	render() {
		return (
			<>
				<Provider {...stores}>
					{this.state.isInitialized ? (
						<Switch>
							<Route path="/" render={() => <AppRouter />} />
						</Switch>
					) : (
						(() => {
							if (_.isEmpty(directoryStore.models.appError)) {
								return (
									<div className="nat__spinner__absolute">
										<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" grow />
									</div>
								);
							} else {
								return (
									<WindowSuccess
										title="Сервис временно недоступен"
										buttonItemList={[
											{
												title: 'Обновить',
												onClick: () => {
													window.location.reload();
												}
											}
										]}
									/>
								);
							}
						})()
					)}
					<FormAlert />
				</Provider>
			</>
		);
	}
}

export default withRouter(App);
