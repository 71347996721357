import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CModal, CModalHeader, CRow, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatTabs from '../../shared/Components/NatTabs';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';

interface PropsType extends RouteComponentProps {
	toggleNatLoyaltyPolicyRangeStatusManager(status: boolean): void;
	isModalOpen: boolean;
	modelStore: any;
	saveEnablePolicyRanges(): Promise<any>;
	saveDisablePolicyRanges(): Promise<any>;
}

interface StateType {}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatLoyaltyPolicyRangeStatusManager extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.savePolicyRanges = this.savePolicyRanges.bind(this);
		// this.saveEnablePolicyRanges = this.saveEnablePolicyRanges.bind(this);
		// this.saveDisablePolicyRanges = this.saveDisablePolicyRanges.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	savePolicyRanges() {
		if (this.props.modelStore.status === 'enabled') {
			if (this.props.modelStore.startedAt) {
				return this.props.saveEnablePolicyRanges();
			} else {
				return Promise.reject(new Error('Поле "Действует с" обязательно для заполнения!'));
			}
		} else {
			if (this.props.modelStore.expiredAt) {
				return this.props.saveDisablePolicyRanges();
			} else {
				return Promise.reject(new Error('Поле "Не действует с" обязательно для заполнения!'));
			}
		}
	}
	// saveEnablePolicyRanges() {
	// 	let result: Array<any> = [];
	// 	_.transform(
	// 		this.props.modelStore.collectedModels,
	// 		(result: any, value: any) => {
	// 			let policyRange = {
	// 				loyaltyPolicyId: value.loyaltyPolicyId,
	// 				sourceId: value.sourceId,
	// 				sourceType: value.sourceType,
	// 				note: this.props.modelStore.note,
	// 				enabled: true,
	// 				period: this.props.modelStore.startedAt
	// 			};
	// 			result.push(policyRange);
	// 			if (this.props.modelStore.expiredAt) {
	// 				let copyPolicyRange = _.cloneDeep(policyRange);
	// 				copyPolicyRange.enabled = false;
	// 				copyPolicyRange.period = this.props.modelStore.expiredAt;
	// 				result.push(copyPolicyRange);
	// 			}
	// 		},
	// 		result
	// 	);
	// 	return controller.bulkUpsert('loyaltyPolicyRanges', result).then(() => {
	// 		this.props.modelStore.setValue(this.props.modelStore, 'sources', []);
	// 		return this.props.getSource();
	// 	});
	// }
	// saveDisablePolicyRanges() {
	// 	let result: Array<any> = [];
	// 	_.transform(
	// 		this.props.modelStore.collectedModels,
	// 		(result: any, value: any) => {
	// 			let policyRange = {
	// 				loyaltyPolicyId: value.loyaltyPolicyId,
	// 				sourceId: value.sourceId,
	// 				sourceType: value.sourceType,
	// 				note: this.props.modelStore.note,
	// 				enabled: false,
	// 				period: this.props.modelStore.expiredAt
	// 			};
	// 			result.push(policyRange);
	// 		},
	// 		result
	// 	);
	// 	return controller.bulkUpsert('loyaltyPolicyRanges', result).then(() => {
	// 		this.props.modelStore.setValue(this.props.modelStore, 'sources', []);
	// 		return this.props.getSource();
	// 	});
	// }
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАПИСАТЬ',
				onClick: () => {
					// this.props.toggleNatLoyaltyPolicyRangeStatusManager(false);
					this.props.modelStore.setValue(this.props.modelStore, 'isLoading', true);
					this.savePolicyRanges()
						.then(() => {
							this.props.modelStore.setValue(this.props.modelStore, 'collectedModels', []);
							this.props.toggleNatLoyaltyPolicyRangeStatusManager(false);
							this.props.modelStore.setValue(this.props.modelStore, 'status', 'enabled');
							this.props.modelStore.setValue(this.props.modelStore, 'isLoading', false);
						})
						.catch((error) => {
							catalog.handleNatError(error);
							this.props.modelStore.setValue(this.props.modelStore, 'isLoading', false);
						});
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.modelStore.setValue(this.props.modelStore, 'collectedModels', []);
					this.props.modelStore.setValue(this.props.modelStore, 'status', 'enabled');
					this.props.toggleNatLoyaltyPolicyRangeStatusManager(false);
				}
			}
		];
		return ReactDOM.createPortal(
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<CForm className="mx-5 mb-3">
						<CRow>
							<CCol lg="12">
								{this.props.modelStore.status === 'enabled' ? (
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatValueInput<Date>
												object={this.props.modelStore}
												property="startedAt"
												placeholder="Введите дату"
												type="date"
												formatter={dateFormatter}
												size="sm"
												label="Действует с"
												invalid={!this.props.modelStore.startedAt}
											/>
										</CCol>
										<CCol md="4">
											<NatValueInput<Date> object={this.props.modelStore} property="expiredAt" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="По" />
										</CCol>
									</CFormGroup>
								) : (
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatValueInput<Date>
												object={this.props.modelStore}
												property="expiredAt"
												placeholder="Введите дату"
												type="date"
												formatter={dateFormatter}
												size="sm"
												label="Не действует с"
												invalid={!this.props.modelStore.expiredAt}
											/>
										</CCol>
									</CFormGroup>
								)}
							</CCol>
						</CRow>
					</CForm>
				</CModalHeader>
				<NatTabs
					navItems={[
						{
							title: 'Комментарий'
						}
					]}
				/>
				<div className="d-flex flex-column default__nat__card__body__height p-0 m-4">
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__form__table">
							<NatTextarea
								object={this.props.modelStore}
								property="note"
								label=""
								placeholder="Введите примечание"
								type="text"
								inputColumnClassName="default__nat__card__body__height px-0"
								labelColumnSettings="12"
								labelColumnClassName="text-left align-self-center"
								wrapperClassName="default__nat__card__body__height"
								inputClassName="default__nat__card__body__height nat__scrollbar text-left"
								formatter={stringFormatter()}
								row={false}
								size="sm"
							/>
						</div>
					</div>
				</div>

				{this.props.modelStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>,
			document.querySelector('#modal') as Element
		);
	}
}

export default withRouter(NatLoyaltyPolicyRangeStatusManager);
