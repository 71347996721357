import 'core-js';
import React from 'react';
import 'react-app-polyfill/ie11';
// For IE 11 support
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { icons } from './assets/icons';
import './polyfill';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

React.icons = icons;

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
