import _ from 'lodash';

import { benzin, instance, makeInstance } from './instance';

class Api {
	login(loginData: { email: string; password: string }) {
		return instance.post('/users/login', loginData);
	}
	logout() {
		return makeInstance().post('/users/logout', {});
	}
	me() {
		return makeInstance().get('/users/me');
	}
	findAll(pluralName: string, filter: any) {
		return makeInstance().get(`/${pluralName}?filter=${JSON.stringify(filter)}`);
	}
	find(pluralName: string, filter: any, id: string) {
		return makeInstance().get(`/${pluralName}/${id}?filter=${JSON.stringify(filter)}`);
	}
	findOne(pluralName: string, filter: any) {
		return makeInstance().get(`/${pluralName}/findOne?filter=${JSON.stringify(filter)}`);
	}
	bulkUpsert(pluralName: string, models: any) {
		return makeInstance().post(`/${pluralName}/bulkUpsert`, models);
	}
	delete(pluralName: string, filter: any) {
		return makeInstance().delete(`/${pluralName}?where=${JSON.stringify(filter)}`);
	}
	makeDeleted(modelName: string, mode: boolean, id: string) {
		return makeInstance().post(`/${modelName}/${id}/makeDeleted?mode=${mode}`, {});
	}
	makePosted(modelName: string, mode: string, model: any) {
		return makeInstance().post(`/${modelName}/makePosted?mode=${mode}`, model);
	}
	sliceLast(pluralName: string, filter: any, params: any = {}) {
		let paramsStr: string = '';
		if (!_.isEmpty(params)) {
			paramsStr = `&params=${JSON.stringify(params)}`;
		}
		return makeInstance().get(`/${pluralName}/sliceLast?filter=${JSON.stringify(filter)}${paramsStr}`);
	}
	fetchRoles(pluralName: string, filter: any) {
		return makeInstance().post(`${pluralName}/fetchRoles?filter=${JSON.stringify(filter)}`, {});
	}
	fetchAvailableContracts(partner: any, organization?: any) {
		return makeInstance().post('/partnerContracts/fetchAvailableContracts', {
			partner: partner,
			organization: organization,
			params: {
				fetchExpired: true,
				fetchInvalid: true,
				fetchClosed: true,
				fetchStandart: false
			}
		});
	}
	fetchApplicableContract(partner: any, organization?: any) {
		return makeInstance().post('/partnerContracts/fetchApplicableContract', { partner: partner, organization: organization });
	}
	uploadFile(data: any) {
		return makeInstance().post('/files/upload', data);
	}
	upload(container: string, data: any) {
		return makeInstance().post(`/containers/${container}/upload`, data);
	}
	download(container: string, fileName: any) {
		return makeInstance().get(`/containers/${container}/downLoad/${fileName}`);
	}
	removeBackground(model: any) {
		return benzin.post('/aiServices/removeBackground', model, {
			headers: {
				Authorization: localStorage.getItem('id')
			}
		});
	}
	execute(report: any, reportPreset: any) {
		return makeInstance().post(`/reports/${report.id}/execute`, reportPreset);
	}
	// download(image: any) {
	// 	let id: string | null = null;
	// 	if (!_.isEmpty(image.originalImageId)) {
	// 		id = image.originalImageId;
	// 	} else {
	// 		id = image.id;
	// 	}
	// 	return blob.get(`images/${id}/large/download`, {
	// 		headers: {
	// 			Authorization: localStorage.getItem('id')
	// 		}
	// 	});
	// }
	balance(pluralName: string, filter: any, params: any = {}) {
		let paramsStr: string = '';
		if (!_.isEmpty(params)) {
			paramsStr = `&params=${JSON.stringify(params)}`;
		}
		return makeInstance().get(`/${pluralName}/balance?filter=${JSON.stringify(filter)}${paramsStr}`);
	}
	displayLabelTemplate(data: Array<any>, params: any = {}) {
		let paramsStr: string = '';
		if (!_.isEmpty(params)) {
			paramsStr = `?params=${JSON.stringify(params)}`;
		}
		return makeInstance('blob').post(`/labelTemplates/display${paramsStr}`, data);
	}
	displayLayoutTemplate(data: { objectType: string; objectId: string }, layoutTemplate: any, params: any = {}) {
		let paramsStr: string = `?objectType=${data.objectType}&objectId=${data.objectId}&params=${JSON.stringify(params)}`;
		return makeInstance('blob').get(`/layoutTemplates/${layoutTemplate.id}/display${paramsStr}`);
	}
	fill(pluralName: string, data: any) {
		return makeInstance().post(`/${pluralName}/fill`, {
			fillData: data
		});
	}
	fetchObjectHierarchyByOwner(pluralName: string, data: any) {
		return makeInstance().post(`/${pluralName}/${data.id}/fetchObjectHierarchyByOwner`, {});
	}
	fetchProductFeatures(data: any) {
		return makeInstance().post(`/products/${data.id}/fetchProductFeatures`, {});
	}
	fetchProductUnits(data: any) {
		return makeInstance().post(`/products/${data.id}/fetchProductUnits`, {});
	}
	fetchFeaturesByProduct(data: any) {
		return makeInstance().post('/productFeatures/fetchByProduct', data);
	}
	fetchUnitsByProduct(data: any) {
		return makeInstance().post('/productUnits/fetchByProduct', data);
	}
	fetchProductItemListPrices(productItemList: Array<any>, priceType?: any) {
		return makeInstance().post('/productPrices/fetchProductItemListPrices', {
			productItemList,
			priceType
		});
	}
	updateProductItemListPrices(productItemList: Array<any>, priceType?: any) {
		return makeInstance().post('/productPrices/updateProductItemListPrices', {
			productItemList,
			priceType
		});
	}
	fetchPartnerDocuments(data: any) {
		return makeInstance().post(`/partners/${data.id}/fetchPartnerDocuments`, {});
	}
	fetchProductKindFeatures(data: any) {
		return makeInstance().post(`/productKinds/${data.id}/fetchProductKindFeatures`, {});
	}
	register(data: any) {
		return instance.post('/users/process', data);
	}
	resetPassword(data: any) {
		return instance.post('/users/reset', data);
	}
	patchUser(data: any) {
		return instance.patch(`/users/${data.id}`, data.data, {
			headers: {
				Authorization: data.token
			}
		});
	}
	fetchMetrics(data: any) {
		return makeInstance().post('/metrics/fetchMetrics', data);
	}
	fetchPartnerSubscriptionInfo() {
		return makeInstance().post('/subscriptionPlans/fetchPartnerSubscriptionInfo', {});
	}
	fetchAvailableSubscriptionPlans() {
		return makeInstance().post('/subscriptionPlans/fetchAvailableSubscriptionPlans', {});
	}
	fetchPaymentOptionList() {
		return makeInstance().post('/shoppingCarts/fetchPaymentOptionList', {});
	}
	resetShoppingCart() {
		return makeInstance().post('/shoppingCarts/resetShoppingCart', {});
	}
	fetchShoppingCart() {
		return makeInstance().post('/shoppingCarts/fetchShoppingCart', {});
	}
	updateShoppingCart(shoppingCart: any) {
		return makeInstance().post('/shoppingCarts/updateShoppingCart', shoppingCart);
	}
	checkoutShoppingCart(data: any) {
		return makeInstance().post('/shoppingCarts/checkoutShoppingCart', data);
	}
	validate(data: any) {
		return makeInstance().post('/paymentOptions/validate', data);
	}
	api() {
		return makeInstance().get('');
	}
	apply(data: any) {
		return makeInstance().post('/loyaltyPolicies/apply', data);
	}
}

export const api = new Api();

export default Api;
