import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CFormGroup, CInput, CLabel } from '@coreui/react';
import _ from 'lodash';
import { reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import ValueFormatter from '../Formatters/ValueFormatter';
import { catalog } from '../support/Catalog';
import NatRelationInputStore, { NatRelationInputStoreType } from './InputsStore/NatRelationInputStore';
import NatInputGroup from './NatInputGroup';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	inputColumnClassName?: string;
	inputClassName?: string;
	type: string;
	invalid?: boolean;
	placeholder: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	useHandleEnter?: boolean;
	relation: string;
	modelName?: string;
	pluralName: string;
	include?: Array<string>;
	filter?: any;
	onlySelect?: boolean;
	filterWhere?: any;
	filterOrder?: any;
	pattern?: string;
	relationProperty: string;
	verticalSwitching?: boolean;
	mobile?: boolean;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatNumberRelationInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	myRef: any;
	natRelationInputStore: NatRelationInputStoreType;
	constructor(props: PropsType<T>) {
		super(props);
		this.myRef = React.createRef();
		this.natRelationInputStore = new NatRelationInputStore();
		this.handleEnter = this.handleEnter.bind(this);
		this.createFilter = this.createFilter.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.setRelationValue = this.setRelationValue.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.dropdown-menu')?.addEventListener('scroll', this.handleScrollToElement);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'querySelector', this.myRef.current.querySelector('.dropdown-menu'));
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.relationProperty],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.relationProperty],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						if (_.isEmpty(this.props.object[this.props.relation]) && !_.isEmpty(this.props.object[this.props.relationProperty])) {
							let filter: any = {
								where: {
									id: this.props.object[this.props.relationProperty]
								}
							};
							if (!_.isEmpty(this.props.include)) {
								filter.include = this.props.include;
							}
							controller
								.findAll(this.props.pluralName, filter)
								.then((data) => {
									if (!_.isEmpty(data)) {
										runInAction(() => {
											this.props.object[this.props.relation] = data[0];
										});
									}
								})
								.catch((error) => {
									catalog.handleNatError(error);
								});
						}
					}
				}
			)
		);
		this.addValidation(this.props.validation);
	}
	componentWillUnmount() {
		this.myRef.current.querySelector('.dropdown-menu')?.removeEventListener('scroll', this.handleScrollToElement);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'querySelector', null);
		this.reactions.forEach((dispose) => dispose());
	}
	toggleDropDown() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', 0);
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', !this.natRelationInputStore.showDropdown);
		this.createFilter();
	}
	handleScrollToElement() {
		let scrollTop = this.natRelationInputStore.querySelector.scrollTop;
		let scrollHeight = this.natRelationInputStore.querySelector.scrollHeight;
		let clientHeight = this.natRelationInputStore.querySelector.clientHeight;
		let arr = this.natRelationInputStore.relations;
		let itemsPerPage = this.natRelationInputStore.itemsPerPage;
		let itemsListLength = arr.length;
		if (scrollTop >= scrollHeight - clientHeight && this.natRelationInputStore.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
			this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', this.natRelationInputStore.currentPage + 1);
			this.natRelationInputStore.setValue(this.natRelationInputStore.filter, 'skip', this.natRelationInputStore.currentPage * this.natRelationInputStore.itemsPerPage);
			let filter = this.natRelationInputStore.filter;
			controller
				.findAll(this.props.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', this.natRelationInputStore.relations.concat(data));
					}
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		}
	}
	createFilter() {
		let filter: any = {
			skip: this.natRelationInputStore.currentPage * this.natRelationInputStore.itemsPerPage,
			limit: this.natRelationInputStore.itemsPerPage,
			order: this.natRelationInputStore.order
		};
		let defaultWhere = {};
		if (!_.isEmpty(this.props.filterWhere)) {
			Object.assign(defaultWhere, this.props.filterWhere);
		}
		filter.where = defaultWhere;
		if (!_.isEmpty(this.props.include)) {
			filter.include = this.props.include;
		}
		if (!_.isEmpty(this.props.filterOrder)) {
			filter.order = this.props.filterOrder;
		}
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'filter', filter);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if ((this.props.pattern && e.target.value.match(this.props.pattern) != null) || !this.props.pattern) {
			this.setState({
				value: e.target.value
			});
		}
	}
	handleClickOutside() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', false);
	}
	handleEnter(event: any) {
		if (event.keyCode === 13) {
			let form = event.target.form;
			if (form && form.length > 0) {
				let index = Array.prototype.indexOf.call(form, event.target);
				let length = form.length;
				let nextElement = null;
				for (index; index < length; index++) {
					let nextElement = _.get(form, `elements[${index + 1}]`, null);
					let condition: any = null;
					if (!this.props.verticalSwitching) {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox';
					} else {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox' && nextElement.name === this.props.property;
					}
					if (condition) {
						nextElement.focus();
						break;
					} else {
						nextElement = null;
					}
				}
				if (!nextElement) {
					event.target.blur();
				}
				event.preventDefault();
				this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', false);
			}
		}
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value);
	}

	renderValue(value: T | undefined | null): string {
		return this.props.formatter.renderValue(value, this.props.object[this.props.relation]);
	}
	setRelationValue(model: any) {
		let object: any = this.props.object;
		runInAction(() => {
			object[this.props.relation] = model;
			object[this.props.relationProperty] = model.id;
		});
	}
	render() {
		return (
			<CDropdown innerRef={this.myRef} className={_.isEmpty(this.props.label) ? 'nat__dropdown__without__label w-100 nat__dropdown' : 'w-100 nat__dropdown'}>
				<NatInputGroup mobile={this.props.mobile}>
					{(childrenProps) => (
						<CFormGroup row className={this.injected.directoryStore.models.windowSize < 490 ? 'w-100 align-items-center nat__form__group flex-nowrap' : 'w-100 align-items-center nat__form__group'}>
							{!_.isEmpty(this.props.label) && (
								<CLabel
									className={(() => {
										if (this.props.label) {
											if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
												return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile_100';
											} else {
												return 'align-self-center text-truncate pl-3 m-0';
											}
										}
									})()}>
									{this.props.label}
								</CLabel>
							)}
							<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0 d-flex' : 'align-self-center pr-0 pl-0 d-flex'}>
								<CInput
									invalid={this.props.invalid}
									type={this.props.type}
									name={this.props.property}
									placeholder={this.props.placeholder}
									pattern={this.props.pattern}
									value={this.state.value}
									onChange={this.handleChange}
									onFocus={(e: any) => {
										this.setState(
											{
												value: String(this.parseValue(this.state.value))
											},
											() => e.target.select()
										);
									}}
									size={this.props.size}
									autoComplete={this.props.autoComplete}
									className={this.props.inputClassName}
									onClick={() => {
										if (!this.natRelationInputStore.showDropdown) {
											this.toggleDropDown();
											let filter = this.natRelationInputStore.filter;
											controller
												.findAll(this.props.pluralName, filter)
												.then((data) => {
													if (!_.isEmpty(data)) {
														this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', data);
													}
												})
												.catch((error) => {
													catalog.handleNatError(error);
												});
										}
									}}
									onBlur={() => {
										if (!this.natRelationInputStore.showDropdown) {
											this.natRelationInputStore.setValue(this.natRelationInputStore, 'relations', []);
											this.natRelationInputStore.setValue(this.natRelationInputStore, 'currentPage', 0);
											this.createFilter();
										}
										let value: T | undefined | null = this.parseValue(this.state.value);
										this.setValue(value);
										this.setState({
											value: this.renderValue(value)
										});
									}}
									onKeyDown={this.handleEnter}
									disabled={this.props.disabled}
								/>
								{!this.props.disabled && childrenProps.isButtonShow && (
									<div
										className={(() => {
											if (this.props.mobile) {
												if (this.injected.directoryStore.models.windowSize > 766) {
													return 'nat__input__buttons';
												} else {
													return 'nat__input__buttons nat__input__buttons__mobile';
												}
											} else {
												return 'nat__input__buttons';
											}
										})()}>
										{_.map(this.props.formatter.getActionButtons(), (item, index) => {
											return (
												<CButton
													key={index}
													type="button"
													className={item.className ? item.className : 'nat__input__button'}
													title={item.title}
													onClick={() => {
														item.onClick(this.props.object, this.props.property, this.props.relation, this.natRelationInputStore, this.props.relationProperty);
													}}
													to={item.to}>
													<CIcon name={item.icon} className="text-dark" size="sm" />
												</CButton>
											);
										})}
									</div>
								)}
							</CCol>
						</CFormGroup>
					)}
				</NatInputGroup>
				<CDropdownMenu className="nat__dropdown__list rounded-0 nat__dropdown__white__scrollbar" show={this.natRelationInputStore.showDropdown}>
					{this.natRelationInputStore.relations.map((item: any, index: number) => {
						return (
							<CDropdownItem
								key={index}
								onClick={() => {
									if (item) {
										this.setRelationValue(item);
										this.toggleDropDown();
									}
								}}>
								{catalog.renderCatalogName(item)}
							</CDropdownItem>
						);
					})}
				</CDropdownMenu>
			</CDropdown>
		);
	}
}

export default enhanceWithClickOutside(NatNumberRelationInput);
