import { observable, action, makeObservable, set } from 'mobx';

import { SaleOfProductType } from '../../shared/support/modelTypes';

export interface SaleOfProductFormStoreType {
	model: SaleOfProductType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	productItem: any;
	isSequenceNumberChangedManually: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class SaleOfProductFormStore implements SaleOfProductFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			productItem: observable,
			isSequenceNumberChangedManually: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		amount: 0,
		deleted: false,
		note: null,
		organizationId: null,
		organizationBankAccountId: null,
		ownerId: null,
		ownerType: null,
		posted: false,
		priceTypeId: null,
		responsibleId: null,
		warehouseId: null,
		periodAt: null,
		authorId: null,
		partnerId: null,
		contractId: null,
		customerOrderId: null,
		partnerBankAccountId: null,
		consignorId: null,
		consigneeId: null,
		productItemList: [],
		productItems: [],
		prepayItems: [],
		prepayItemList: [],
		loyaltyPolicyItems: [],
		loyaltyPolicyItemList: [],
		bonusPointIncomeItems: [],
		bonusPointIncomeItemList: []
	};
	searchText = '';
	productItem = {};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'saleOfProducts';
	filterInclude = [
		'organization',
		'priceType',
		'warehouse',
		'partner',
		'contract',
		'organizationBankAccount',
		'partnerBankAccount',
		'consignor',
		'consignee',
		'prepayItems',
		'loyaltyPolicyItems',
		'owner',
		'bonusPointIncomeItems',
		{
			relation: 'productItems',
			scope: {
				include: ['unit', 'feature', 'serialNumber', 'product', 'validityPeriod', 'productSet']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultPriceType',
			property: 'priceTypeId',
			relation: 'priceType'
		},
		{
			predefinedName: 'DefaultWarehouse',
			property: 'warehouseId',
			relation: 'warehouse'
		}
	];
	isFirstLoad = true;
	name = 'OrmSaleOfProducts';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const saleOfProductFormStore = new SaleOfProductFormStore();

export default SaleOfProductFormStore;
