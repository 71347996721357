import { DateTime } from 'luxon';
import { observable, action, makeObservable, set } from 'mobx';

import { OrganizationType } from '../shared/support/modelTypes';

export interface NatStatisticsStoreType {
	metrics: Array<any>;
	isLoading: boolean;
	requiredToFill: Array<any>;
	model: {
		organizationId: string | null;
		organization?: OrganizationType;
		periodFrom: Date | null | string;
		periodTo: Date | null | string;
	};
	isFirstLoad: boolean;
	periods: Array<{ name: string; periodFrom: Date | string | null; periodTo: Date | string | null; sendRequest: boolean }>;
	activePeriod: { name: string; periodFrom: Date | string | null; periodTo: Date | string | null; sendRequest: boolean };
	isManuallyPeriodEditingActive: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatStatisticsStore implements NatStatisticsStoreType {
	constructor() {
		makeObservable(this, {
			metrics: observable,
			isLoading: observable,
			model: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			periods: observable,
			activePeriod: observable,
			isManuallyPeriodEditingActive: observable,
			setValue: action
		});
	}
	metrics = [];
	isLoading = false;
	model = {
		organizationId: null,
		periodFrom: DateTime.now().toISO().substring(0, 10) + 'T00:00:00.000Z',
		periodTo: DateTime.now().toISO()
	};
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		}
	];
	isFirstLoad = true;
	periods = [
		{ name: 'Сегодня', periodFrom: DateTime.now().toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true },
		{ name: 'Вчера', periodFrom: DateTime.now().plus({ days: -1 }).toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().plus({ days: -1 }).toISO().substring(0, 10) + 'T23:59:00.000Z', sendRequest: true },
		{ name: 'Неделя', periodFrom: DateTime.now().plus({ weeks: -1 }).toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true },
		{ name: 'Месяц', periodFrom: DateTime.now().plus({ months: -1 }).toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true },
		{ name: 'Квартал', periodFrom: DateTime.now().plus({ months: -3 }).toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true },
		{ name: 'Год', periodFrom: DateTime.now().plus({ years: -1 }).toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true },
		{ name: 'Вручную', periodFrom: DateTime.now().toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: false }
	];
	activePeriod = { name: 'Сегодня', periodFrom: DateTime.now().toISO().substring(0, 10) + 'T00:00:00.000Z', periodTo: DateTime.now().toISO(), sendRequest: true };
	isManuallyPeriodEditingActive = false;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natStatisticsStore = new NatStatisticsStore();

export default NatStatisticsStore;
