import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyPolicyRangeType } from '../../shared/support/modelTypes';

export interface FilterType {
	mode: string;
	type: string;
}

export interface NatLoyaltyPolicyRangeManagerStoreType {
	date: Date;
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterOrder: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	isLoading: boolean;
	startedAt: Date;
	expiredAt: Date | null;
	note: string | null;
	loyaltyPolicyRanges: Array<LoyaltyPolicyRangeType>;
	tabMetadata: { tabIndex: number; tabMetadata: { type: string } };
	OrmWarehouse: FilterType;
	OrmLoyaltyCardKind: FilterType;
	OrmCouponKind: FilterType;
	OrmPartnerContract: FilterType;
	OrmOrganization: FilterType;
	OrmSubscriptionPlan: FilterType;
	sources: Array<any>;
	status: string;
	setValue(model: any, key: string, value: any): void;
}

class NatLoyaltyPolicyRangeManagerStore implements NatLoyaltyPolicyRangeManagerStoreType {
	constructor() {
		makeObservable(this, {
			date: observable,
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterOrder: observable,
			pluralName: observable,
			collectedModels: observable,
			isLoading: observable,
			startedAt: observable,
			expiredAt: observable,
			note: observable,
			loyaltyPolicyRanges: observable,
			tabMetadata: observable,
			OrmWarehouse: observable,
			OrmLoyaltyCardKind: observable,
			OrmCouponKind: observable,
			OrmPartnerContract: observable,
			OrmOrganization: observable,
			OrmSubscriptionPlan: observable,
			sources: observable,
			status: observable,
			setValue: action
		});
	}
	date = new Date();
	startedAt = new Date();
	expiredAt = null;
	note = null;
	list = [];
	sources = [];
	loyaltyPolicyRanges = [];
	filterWhere = {};
	filterInclude = ['source'];
	filterOrder = ['createdAt DESC'];
	pluralName = 'loyaltyPolicyRanges';
	collectedModels = [] as Array<any>;
	isLoading = false;
	status = 'enabled';
	tabMetadata = {
		tabIndex: 0,
		tabMetadata: {
			type: 'OrmWarehouse'
		}
	};
	OrmWarehouse = {
		mode: 'all',
		type: 'OrmWarehouse'
	};
	OrmLoyaltyCardKind = {
		mode: 'all',
		type: 'OrmLoyaltyCardKind'
	};
	OrmCouponKind = {
		mode: 'all',
		type: 'OrmCouponKind'
	};
	OrmPartnerContract = {
		mode: 'all',
		type: 'OrmPartnerContract'
	};
	OrmOrganization = {
		mode: 'all',
		type: 'OrmOrganization'
	};
	OrmSubscriptionPlan = {
		mode: 'all',
		type: 'OrmSubscriptionPlan'
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natLoyaltyPolicyRangeManagerStore = new NatLoyaltyPolicyRangeManagerStore();

export default NatLoyaltyPolicyRangeManagerStore;
