import { observable, action, makeObservable, set } from 'mobx';

import { PartnerContractType } from '../../shared/support/modelTypes';

export interface PartnerContractFormStoreType {
	model: PartnerContractType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class PartnerContractFormStore implements PartnerContractFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		organizationId: null,
		partnerId: null,
		partnerSegmentId: null,
		priceTypeId: null,
		contractId: null,
		statusId: 'partner_contract_statuses.invalid',
		typeId: null,
		accountingMethodId: 'partner_settlement_accounting_methods.by_contracts',
		standart: false,
		contractDate: null,
		contractNumber: null,
		startedAt: null,
		expiredAt: null,
		manualDiscountLimit: 0,
		manualMarkUpLimit: 0,
		limitManualDiscount: false,
		settlementAmountLimit: 0,
		settlementNumberOfDaysLimit: 0,
		controlSettlementAmount: false,
		controlSettlementNumberOfDays: false,
		note: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		elementType: 0,
		parentId: null,
		authorId: null,
		status: {
			id: 'partner_contract_statuses.invalid',
			keyId: 'invalid',
			ownerId: 'partner_contract_statuses',
			name: { ru: 'Не действует' },
			description: null,
			predefined: true,
			predefinedName: 'PartnerContractStatusesInvalid',
			predefinedVersion: 0
		},
		accountingMethod: {
			id: 'partner_settlement_accounting_methods.by_contracts',
			keyId: 'by_contracts',
			ownerId: 'partner_settlement_accounting_methods',
			name: { ru: 'По договору' },
			description: null,
			predefined: true,
			predefinedName: 'PartnerSettlementAccountingMethodsByContracts',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'partnerContracts';
	filterInclude = ['type', 'accountingMethod', 'status', 'priceType', 'organization'];
	collectedModels = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		}
	];
	isFirstLoad = true;
	name = 'OrmPartnerContract';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const partnerContractFormStore = new PartnerContractFormStore();

export default PartnerContractFormStore;
