import { observable, action, makeObservable, set } from 'mobx';

import { ProductType } from '../support/modelTypes';

export interface NatSetEditingManagerStoreType {
	quantity: number;
	price: number;
	productItems: Array<any>;
	productSetMappings: Array<any>;
	productSet: ProductType;
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterOrder: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	isLoading: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatSetEditingManagerStore implements NatSetEditingManagerStoreType {
	constructor() {
		makeObservable(this, {
			quantity: observable,
			price: observable,
			productItems: observable,
			productSetMappings: observable,
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterOrder: observable,
			pluralName: observable,
			collectedModels: observable,
			isLoading: observable,
			setValue: action
		});
	}
	quantity = 1;
	price = 0;
	productItems = [];
	productSetMappings = [];
	productSet = {
		accountingOptionId: 'product_accounting_options.standart',
		area: 0,
		areaUnitId: null,
		article: null,
		caption: null,
		deleted: false,
		description: null,
		displayName: null,
		elementType: 0,
		giftCardValidityExpiredAt: null,
		giftCardValidityPeriodCount: 0,
		giftCardValidityPeriodId: 'periodicity.year',
		giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
		giftCardValue: 0,
		height: 0,
		heightUnitId: null,
		kindId: null,
		labelTemplateId: null,
		length: 0,
		lengthUnitId: null,
		name: { ru: '' },
		organizationId: null,
		packageSetId: null,
		parentId: null,
		primaryImageId: null,
		priority: null,
		supplierId: null,
		typeId: 'product_types.goods',
		unitId: null,
		useArea: false,
		useCustomLabelTemplate: false,
		useFeatureOptionId: 'use_feature_options.not_used',
		useGiftCardPartially: false,
		useHeight: false,
		useLength: false,
		usePackageOptionId: 'use_package_options.not_used',
		useSerialNumbers: false,
		useVolume: false,
		useWeight: false,
		useWidth: false,
		volume: 0,
		volumeUnitId: null,
		weight: 0,
		weightCanManuallySet: false,
		weightUnitId: null,
		width: 0,
		widthUnitId: null,
		images: [],
		useQuantityLimit: false,
		quantityLimitRange: null,
		validityPeriodId: 'periodicity.month',
		subscriptionPlanId: null,
		subscriptionOptionId: null,
		setPrintOptionId: null,
		setPriceCalculationMethodId: null
	};
	list = [];
	filterWhere = {};
	filterInclude = ['product', 'feature', 'unit', 'validityPeriod', 'productSet'];
	filterOrder = ['createdAt DESC'];
	pluralName = 'productSetMappings';
	collectedModels = [] as Array<any>;
	isLoading = false;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natSetEditingManagerStore = new NatSetEditingManagerStore();

export default NatSetEditingManagerStore;
