import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalHeader, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TreeMenu from 'react-simple-tree-menu';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FiltersType } from '../../shared/support/modelTypes';
import NatFilters from '../Components/NatFilters';
import NatTreeItemComponent from '../Components/NatTreeItemComponent';
import { dateFormatter } from '../Formatters/DateFormatter';
import { priceFormatter } from '../Formatters/PriceFormatter';
import { quantityFormatter } from '../Formatters/QuantityFormatter';
import { natOpenTriangle, natCloseTriangle } from '../support/NatIcons';
import { models } from '../support/NatModels';
import ProductSelectionStore, { ProductSelectionStoreType } from './ProductSelectionStore';

interface PropsType extends RouteComponentProps {
	toggleFeedSelection(status: boolean): void;
	isModalOpen: boolean;
	productItemTemplate: any;
	modelStore: any;
	productItemsColumns: Array<any>;
	makeReactions?: () => void;
	saveProductItems?: (productItems: Array<any>) => void;
	notRecalculateAmount?: boolean;
	dontDisplayStocksAndPrices?: boolean;
	isRevealCompositionOfSet?: boolean;
	dontDisplayFeatures?: boolean;
	typeFilter?: { nin?: Array<string>; inq?: Array<string> };
	formValidProductTypes?: boolean;
}

interface StateType {
	groupId: string | null;
	querySelector: any;
	idArray: Array<string>;
	timer: any;
	columns: Array<any>;
	basketColumns: Array<any>;
	collapses: Array<number>;
	isFilterOpen: boolean;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class ProductSelection extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	filters: FiltersType;
	productSelectionStore: ProductSelectionStoreType;
	treeRef: any;
	totalRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.treeRef = React.createRef();
		this.totalRef = React.createRef();
		this.showNestedElements = this.showNestedElements.bind(this);
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.resetData = this.resetData.bind(this);
		this.returnToRoot = this.returnToRoot.bind(this);
		this.sortList = this.sortList.bind(this);
		this.showList = this.showList.bind(this);
		this.buildTree = this.buildTree.bind(this);
		this.toggleNodeByIcon = this.toggleNodeByIcon.bind(this);
		this.toggleNodeByNode = this.toggleNodeByNode.bind(this);
		this.saveProductItems = this.saveProductItems.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.makeTooltips = this.makeTooltips.bind(this);
		this.getProductsInfo = this.getProductsInfo.bind(this);
		this.showFeatures = this.showFeatures.bind(this);
		this.selectProduct = this.selectProduct.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.deleteProductItems = this.deleteProductItems.bind(this);
		this.toggleCollapses = this.toggleCollapses.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.createFilter = this.createFilter.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.animateTotal = this.animateTotal.bind(this);
		this.formTypes = this.formTypes.bind(this);
		this.reactions = [];
		this.productSelectionStore = new ProductSelectionStore();
		this.filters = {
			itemsPerPage: 50,
			currentPage: 0,
			filterOrder: [`name.${this.injected.directoryStore.models.language} ASC`],
			filterSkip: 0,
			filterWhere: { deleted: false, elementType: 0 },
			filterInclude: ['unit', 'subscriptionOption', 'validityPeriod', 'subscriptionPlan'],
			filterGroupOrder: [`name.${this.injected.directoryStore.models.language} ASC`],
			filterGroupWhere: { deleted: false, elementType: 1 }
		};
		if (!_.isEmpty(this.props.typeFilter)) {
			this.filters.filterWhere.typeId = this.props.typeFilter;
		}
		this.state = {
			groupId: null,
			querySelector: null,
			idArray: [],
			timer: null,
			collapses: this.injected.directoryStore.models.windowSize >= 1200 ? [1] : [1, 2],
			columns: [
				{
					id: 1,
					field: 'name',
					label: 'Наименование',
					width: '300px',
					checkCondition: () => {
						if (this.productSelectionStore.isRoot) {
							return true;
						} else {
							return false;
						}
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data)}</div>;
					},
					sort: () => {}
				},
				{
					id: 2,
					field: 'article',
					label: 'Артикул',
					width: '250px',
					checkCondition: () => {
						if (this.productSelectionStore.isRoot) {
							return true;
						} else {
							return false;
						}
					},
					sort: () => {}
				},
				{
					id: 3,
					field: 'featureId',
					label: 'Характеристика',
					width: '250px',
					dontSortByApi: true,
					checkCondition: () => {
						if (!this.productSelectionStore.isRoot) {
							return true;
						} else {
							return false;
						}
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data.feature)}</div>;
					}
				},
				{
					id: 4,
					field: 'price',
					label: 'Цена',
					width: '250px',
					dontSortByApi: true,
					visible: !this.props.dontDisplayStocksAndPrices,
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate text-right">{data.price !== undefined ? priceFormatter.renderValue(data.price) : ''}</div>;
					}
				},
				{
					id: 5,
					field: 'inStock',
					label: 'Остаток',
					width: '250px',
					visible: !this.props.dontDisplayStocksAndPrices,
					dontSortByApi: true,
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate text-right">{data.inStock !== undefined ? quantityFormatter.renderValue(data.inStock) : ''}</div>;
					}
				}
			],
			basketColumns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					headerCellRenderer: () => {
						return <div className="rgt-header-inner"></div>;
					},
					cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'productId',
					label: 'Номенклатура',
					width: '300px',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data.product)}</div>;
					},
					sort: () => {}
				}
			],
			isFilterOpen: false
		};
		this.reactions.push(
			reaction(
				() => this.productSelectionStore.chips,
				() => {
					if (!_.isEmpty(this.state.querySelector)) {
						this.state.querySelector.scrollTo(0, 0);
						clearTimeout(this.state.timer);
						this.setState({
							timer: setTimeout(this.showList, 500)
						});
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		_.forEach(this.productSelectionStore.productItems, (item) => {
			_.forIn(item, (value, key) => {
				this.reactions.push(
					reaction(
						() => item[key],
						() => {
							if (key === 'quantity' || key === 'price' || key === 'manualDiscountPercent') {
								if (!this.props.notRecalculateAmount) {
									catalog.recalculateList(this.productSelectionStore.productItems);
								}
							}
							if (key === 'quantity') {
								this.productSelectionStore.setValue(this.productSelectionStore, 'total', catalog.calculateTotal(this.productSelectionStore.productItems));
								this.animateTotal();
							}
						}
					)
				);
			});
			this.reactions.push(
				reaction(
					() => item.validityPeriodId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (_.isEmpty(value)) {
								item.validityPeriodId = 'periodicity.month';
								item.validityPeriod = {
									id: 'periodicity.month',
									keyId: 'month',
									ownerId: 'periodicity',
									name: { ru: 'Месяц' },
									description: null,
									priority: 6,
									predefined: true,
									predefinedName: 'PeriodicityMonth',
									predefinedVersion: 0
								};
							} else {
								if (!this.props.dontDisplayStocksAndPrices) {
									this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
									controller
										.updateProductItemListPrices([item], _.get(this.props.modelStore, 'model.priceTypeId', this.props.productItemTemplate.priceTypeId))
										.then((data) => {
											if (!_.isEmpty(data)) {
												runInAction(() => {
													item.price = data[0].price;
												});
												this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
											}
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
										});
								}
							}
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.validityPeriodCount,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (!this.props.dontDisplayStocksAndPrices) {
								this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
								controller
									.updateProductItemListPrices([item], _.get(this.props.modelStore, 'model.priceTypeId', this.props.productItemTemplate.priceTypeId))
									.then((data) => {
										if (!_.isEmpty(data)) {
											runInAction(() => {
												item.price = data[0].price;
											});
											catalog.recalculateList(this.productSelectionStore.productItems);
										}
										this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
										catalog.recalculateList(this.productSelectionStore.productItems);
									});
							}
						}
					}
				)
			);
		});
	}
	componentDidMount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
		this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
		if (this.props.isModalOpen) {
			let groupFilter = {
				where: this.filters.filterGroupWhere,
				order: this.filters.filterGroupOrder
			};
			controller
				.findAll(this.productSelectionStore.pluralName, groupFilter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.productSelectionStore.setValue(this.productSelectionStore, 'groupsTree', this.buildTree(data));
					} else {
						this.productSelectionStore.setValue(this.productSelectionStore, 'groupsTree', this.buildTree([]));
					}
					this.makeTooltips(this.treeRef);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
			let feedFilter = {
				where: this.filters.filterWhere,
				skip: this.filters.filterSkip,
				include: this.filters.filterInclude,
				limit: this.filters.itemsPerPage,
				order: this.filters.filterOrder
			};
			controller
				.findAll(this.productSelectionStore.pluralName, feedFilter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.getProductsInfo(data, false);
					} else {
						this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
					}
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				});
			if (this.props.formValidProductTypes) {
				this.formTypes();
			}
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: null
		});
	}
	handleScrollToElement() {
		if (this.productSelectionStore.isRoot) {
			let scrollTop = this.state.querySelector.scrollTop;
			let scrollHeight = this.state.querySelector.scrollHeight;
			let clientHeight = this.state.querySelector.clientHeight;
			let arr = this.productSelectionStore.list;
			let itemsPerPage = this.filters.itemsPerPage;
			let itemsListLength = arr.length;
			if (this.productSelectionStore.isRoot) {
				this.productSelectionStore.setValue(this.productSelectionStore, 'scrollbarPosition', scrollTop);
			}
			if (scrollTop >= scrollHeight - clientHeight && this.filters.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
				this.filters.currentPage = this.filters.currentPage + 1;
				this.filters.filterSkip = this.filters.currentPage * this.filters.itemsPerPage;
				let feedFilter = {
					where: this.filters.filterWhere,
					skip: this.filters.filterSkip,
					limit: this.filters.itemsPerPage,
					include: this.filters.filterInclude,
					order: this.filters.filterOrder
				};
				this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
				controller
					.findAll(this.productSelectionStore.pluralName, feedFilter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.getProductsInfo(data, true);
						} else {
							this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
					});
			}
		}
	}
	createFilter() {
		let and: Array<any> = [];
		let createdAtGt: { createdAt: { gt: string } } = {
			createdAt: {
				gt: ''
			}
		};
		let createdAtLt: { createdAt: { lt: string } } = {
			createdAt: {
				lt: ''
			}
		};
		let filter: any = {
			where: this.filters.filterWhere,
			skip: this.filters.filterSkip,
			limit: this.filters.itemsPerPage,
			include: this.filters.filterInclude,
			order: this.filters.filterOrder
		};
		let defaultFilterWhere: any = { deleted: false, elementType: 0 };
		if (this.filters.filterWhere.parentId !== undefined) {
			defaultFilterWhere.parentId = this.filters.filterWhere.parentId;
		}
		filter.where = defaultFilterWhere;
		_.forEach(this.productSelectionStore.chipsFields, (item: any, key) => {
			if (item.isDate) {
				if (item.id !== undefined && item.id !== null) {
					if (item.isGt) {
						createdAtGt = {
							createdAt: {
								gt: item.id.toISOString()
							}
						};
						and.push(createdAtGt);
						filter.where.and = and;
					} else {
						createdAtLt = {
							createdAt: {
								lt: item.id.toISOString().substring(0, 10) + 'T23:59:00.000Z'
							}
						};
						and.push(createdAtLt);
						filter.where.and = and;
					}
				}
			} else if (item.isLocalizedString) {
				if (!_.isEmpty(item.id)) {
					filter.where[`${key}.${this.injected.directoryStore.models.language}`] = {
						like: item.id,
						options: 'i'
					};
				} else {
					delete filter.where[`${key}.${this.injected.directoryStore.models.language}`];
				}
			} else {
				if (!_.isEmpty(item.id)) {
					filter.where[key] = {
						like: item.id,
						options: 'i'
					};
				} else {
					delete filter.where[key];
				}
			}
		});
		return filter;
	}
	showList() {
		this.productSelectionStore.setValue(this.productSelectionStore, 'isRoot', true);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', []);
		this.filters.filterSkip = 0;
		this.filters.currentPage = 0;
		let feedFilter = this.createFilter();
		this.filters.filterWhere = feedFilter.where;
		this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
		controller
			.findAll(this.productSelectionStore.pluralName, feedFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.getProductsInfo(data, false);
				} else {
					this.productSelectionStore.setValue(this.productSelectionStore, 'list', []);
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
			});
	}
	sortList(colId: number, isAsc: boolean) {
		if (this.productSelectionStore.isRoot) {
			let order: Array<any> = [];
			const groupedColumns = _.keyBy(this.state.columns, 'id');
			let sortByApi: boolean = true;
			if (colId !== null) {
				const sortBy = isAsc ? 'ASC' : 'DESC';
				order = [`${groupedColumns[colId].field} ${sortBy}`];
				if (groupedColumns[colId].dontSortByApi) {
					sortByApi = !groupedColumns[colId].dontSortByApi;
				}
			} else {
				sortByApi = true;
				order = [`name.${this.injected.directoryStore.models.language} ASC`];
			}
			this.state.querySelector.scrollTo(0, 0);
			if (sortByApi) {
				this.filters.filterOrder = order;
				this.filters.filterSkip = 0;
				this.filters.currentPage = 0;
				this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
				let feedFilter = {
					where: this.filters.filterWhere,
					skip: this.filters.filterSkip,
					limit: this.filters.itemsPerPage,
					include: this.filters.filterInclude,
					order: this.filters.filterOrder
				};
				controller
					.findAll(this.productSelectionStore.pluralName, feedFilter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.getProductsInfo(data, false);
						} else {
							this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
					});
			}
		}
	}
	showNestedElements(item: any) {
		this.productSelectionStore.setValue(this.productSelectionStore, 'isRoot', true);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', []);
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'activeKey', item.key);
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'focusKey', item.key);
		if (!_.isEmpty(item.object.parentId)) {
			let idArray = [] as Array<string>;
			let groupByParentId = _.groupBy(this.productSelectionStore.productsGroups, (value) => {
				return value.parentId || 'null';
			});
			let fetchChildGroupIds = (item) => {
				idArray.push(item.id);
				let childGroupList = groupByParentId[item.id];
				if (childGroupList && childGroupList.length > 0) {
					for (let childGroup of childGroupList) {
						fetchChildGroupIds(childGroup);
					}
				}
			};
			fetchChildGroupIds(item.object);

			this.setState({
				groupId: item.key,
				idArray
			});
			this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
			this.productSelectionStore.setValue(this.productSelectionStore, 'list', []);
			this.filters.filterSkip = 0;
			this.filters.currentPage = 0;
			let feedFilter = {
				where: this.filters.filterWhere,
				skip: this.filters.filterSkip,
				include: this.filters.filterInclude,
				limit: this.filters.itemsPerPage,
				order: this.filters.filterOrder
			};
			feedFilter.where.parentId = { inq: idArray } as any;
			this.filters.filterWhere = feedFilter.where;
			controller
				.findAll(this.productSelectionStore.pluralName, feedFilter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.getProductsInfo(data, false);
					} else {
						this.productSelectionStore.setValue(this.productSelectionStore, 'list', []);
						this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
					}
					this.makeTooltips(this.treeRef);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				});
		} else {
			this.returnToRoot();
		}
	}
	toggleNodeByIcon(item: any) {
		const openNodes = _.cloneDeep(this.productSelectionStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = openNodes.filter((openNode) => openNode !== item.key);
		} else {
			const nodesMap = _.chain(this.productSelectionStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.productSelectionStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'openNodes', newOpenNodes);
		setTimeout(() => {
			this.makeTooltips(this.treeRef);
		}, 0);
	}
	toggleNodeByNode(item: any) {
		const openNodes = _.cloneDeep(this.productSelectionStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = [...openNodes];
		} else {
			const nodesMap = _.chain(this.productSelectionStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.productSelectionStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'openNodes', newOpenNodes);
	}
	resetData() {
		this.productSelectionStore.setValue(this.productSelectionStore, 'productsGroups', []);
		this.productSelectionStore.setValue(this.productSelectionStore, 'list', []);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productItems', []);
		this.productSelectionStore.setValue(this.productSelectionStore, 'search', '');
	}
	returnToRoot() {
		this.productSelectionStore.setValue(this.productSelectionStore, 'isRoot', true);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', []);
		this.setState({
			groupId: null
		});
		this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
		this.filters.filterSkip = 0;
		this.filters.currentPage = 0;
		this.productSelectionStore.setValue(this.productSelectionStore, 'list', []);
		let filterWhere = _.cloneDeep(this.filters.filterWhere);
		delete filterWhere.parentId;
		this.filters.filterWhere = filterWhere;
		let feedFilter = {
			where: this.filters.filterWhere,
			skip: this.filters.filterSkip,
			include: this.filters.filterInclude,
			limit: this.filters.itemsPerPage,
			order: this.filters.filterOrder
		};
		controller
			.findAll(this.productSelectionStore.pluralName, feedFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.getProductsInfo(data, false);
				} else {
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
			});
	}
	buildTree(list: Array<any>) {
		let rootId = generateRandomId();
		let groups: Array<any> = list;
		for (let item of groups) {
			if (item.parentId === null) {
				item.parentId = rootId;
			}
		}
		groups.push({
			parentId: null,
			id: rootId,
			name: { ru: models['OrmProduct'].pluralDisplayName }
		});
		let groupByParentId = _.groupBy(groups, (value) => {
			return value.parentId || 'null';
		});
		let handleGroup = (item, nodes) => {
			let treeItem = {
				label: item.name[this.injected.directoryStore.models.language],
				key: item.id,
				object: item,
				nodes: []
			};
			nodes.push(treeItem);
			let childGroupList = groupByParentId[item.id];
			if (childGroupList && childGroupList.length > 0) {
				for (let childGroup of childGroupList) {
					handleGroup(childGroup, treeItem.nodes);
				}
			}
		};
		let result = [] as Array<any>;
		let rootGroupList = _.get(groupByParentId, 'null');
		if (rootGroupList && rootGroupList.length > 0) {
			for (let rootGroup of rootGroupList) {
				handleGroup(rootGroup, result);
			}
		}
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'openNodes', [rootId]);
		this.productSelectionStore.setValue(this.productSelectionStore.treeState, 'nodes', [{ key: rootId }]);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productsGroups', list);
		return result;
	}
	saveProductItems() {
		if (this.props.saveProductItems === undefined) {
			let save = (productItems: Array<any>) => {
				this.props.modelStore.setValue(this.props.modelStore.model, 'productItems', _.get(this.props.modelStore, 'model.productItems', []).concat(productItems));
				this.props.modelStore.setValue(this.props.modelStore, 'copyProductItems', this.props.modelStore.model.productItems);
				this.props.modelStore.setValue(this.props.modelStore.model, 'productItemList', this.props.modelStore.model.productItems);
			};
			let arr = [] as Array<any>;
			const currentProductItemMap = _.chain(this.props.modelStore.model.productItems).cloneDeep().keyBy('id').value();
			let productIds: Array<any> = [];
			let featureIds: Array<any> = [];
			let productSetItems: Array<any> = [];
			_.forEach(this.productSelectionStore.productItems, (item: any) => {
				if (currentProductItemMap[item.id] === undefined) {
					if (!_.isEmpty(item.product)) {
						if (item.product.typeId === 'product_types.set') {
							if (this.props.isRevealCompositionOfSet) {
								productIds.push(item.productId);
								featureIds.push(item.featureId);
								productSetItems.push(item);
							} else {
								arr.push(item);
							}
						} else {
							arr.push(item);
						}
					} else {
						arr.push(item);
					}
				}
			});
			let productSetMappingFilter = {
				where: {
					productSetId: { inq: productIds },
					productSetFeatureId: { inq: featureIds }
				},
				include: [
					'unit',
					'feature',
					'productSet',
					'validityPeriod',
					{
						relation: 'product',
						scope: {
							include: ['subscriptionOption', 'subscriptionPlan']
						}
					}
				]
			};
			if (this.props.isRevealCompositionOfSet) {
				if (!_.isEmpty(productIds)) {
					let productSetItemsMap = _.chain(productSetItems).keyBy('productId').value();
					controller
						.findAll('productSetMappings', productSetMappingFilter)
						.then((data) => {
							if (!_.isEmpty(data)) {
								let productItems: Array<any> = [];
								let isProductItemsFiltered: boolean = false;
								let validProductTypesMap = _.chain(this.productSelectionStore.validProductTypes)
									.keyBy((item) => {
										return item;
									})
									.value();
								for (let item of data) {
									const productItem = _.cloneDeep(this.props.productItemTemplate);
									let productSetItem = productSetItemsMap[item.productSetId];
									productItem.productSetId = item.productSetId;
									productItem.productSetFeatureId = item.productSetFeatureId;
									productItem.productId = item.productId;
									productItem.quantity = item.quantity * productSetItem.quantity;
									productItem.id = generateRandomId();
									if (item.product) {
										productItem.product = item.product;
									}
									if (productItem.unitId !== undefined) {
										productItem.unitId = item.unitId;
										if (item.unit) {
											productItem.unit = item.unit;
										}
									}
									if (productItem.featureId !== undefined) {
										productItem.featureId = item.featureId;
										if (item.feature) {
											productItem.feature = item.feature;
										}
									}
									if (productItem.validityPeriodId !== undefined) {
										productItem.validityPeriodId = item.validityPeriodId;
										if (item.validityPeriod) {
											productItem.validityPeriod = item.validityPeriod;
										}
									}
									if (productItem.optionId !== undefined) {
										if (!_.isEmpty(item.product)) {
											productItem.optionId = item.product.subscriptionOptionId;
											if (item.product.subscriptionOption) {
												productItem.option = item.product.subscriptionOption;
											}
										}
									}
									if (productItem.subscriptionOptionId !== undefined) {
										if (!_.isEmpty(item.product)) {
											productItem.subscriptionOptionId = item.product.subscriptionOptionId;
											if (item.product.subscriptionOption) {
												productItem.subscriptionOption = item.product.subscriptionOption;
											}
										}
									}
									if (productItem.subscriptionPlanId !== undefined) {
										if (!_.isEmpty(item.product)) {
											productItem.subscriptionPlanId = item.product.subscriptionPlanId;
											if (item.product.subscriptionPlan) {
												productItem.subscriptionPlan = item.product.subscriptionPlan;
											}
										}
									}
									if (item.productSet) {
										productItem.productSet = item.productSet;
									}
									if (!_.isEmpty(this.productSelectionStore.validProductTypes)) {
										if (productItem.product) {
											if (validProductTypesMap[productItem.product.typeId]) {
												productItems.push(productItem);
											}
										}
										isProductItemsFiltered = true;
									} else {
										productItems.push(productItem);
									}
								}
								if (isProductItemsFiltered) {
									let result: Array<any> = [];
									let productItemsGrouped = _.chain(productItems).groupBy('productSetId').value();
									let productSetMappingsGrouped = _.chain(data).groupBy('productSetId').value();
									_.forEach(productItemsGrouped, (item) => {
										if (item[0].productSet) {
											if (item[0].productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
												if (productSetMappingsGrouped[item[0].productSetId]) {
													if (item.length === productSetMappingsGrouped[item[0].productSetId].length) {
														result = result.concat(item);
													}
												}
											} else {
												result = result.concat(item);
											}
										}
									});
									productItems = result;
								}
								return controller.updateProductItemListPrices(productItems);
							}
						})
						.then((data) => {
							if (!_.isEmpty(data)) {
								arr = arr.concat(data);
							}
							save(arr);
						})
						.catch((error) => {
							catalog.handleNatError(error);
							save(arr);
						});
				} else {
					save(arr);
				}
			} else {
				save(arr);
			}
		} else {
			this.props.saveProductItems(this.productSelectionStore.productItems);
		}
		if (this.props.makeReactions !== undefined) {
			this.props.makeReactions();
		}
	}
	makeTooltips(ref: any) {
		_.forEach(ref.current.querySelectorAll('li'), (item) => {
			let text = item.textContent.slice(item.textContent.indexOf('+') + 1);
			if (_.isEmpty(item.title)) {
				item.title = text;
			}
		});
	}
	getProductsInfo(products: Array<any>, concat: boolean) {
		let ids: Array<string> = [];
		let productItems: Array<any> = [];
		if (!this.props.dontDisplayStocksAndPrices) {
			_.forEach(products, (item) => {
				ids.push(item.id);
				productItems.push({
					productId: item.id,
					unitId: item.unitId,
					priceTypeId: _.get(this.props.modelStore, 'model.priceTypeId', this.props.productItemTemplate.priceTypeId)
				});
			});
			let productsMap = _.chain(products).keyBy('id').value();
			let productInStocksFilter = {
				where: {
					productId: {
						inq: ids
					}
				},
				include: ['product']
			};
			Promise.all([controller.balance('productInStocks', productInStocksFilter, { modelDimensions: ['productId'] }), controller.updateProductItemListPrices(productItems)])
				.then((data) => {
					if (!_.isEmpty(data[0])) {
						_.forEach(data[0], (itm) => {
							productsMap[itm.productId].inStock = itm.inStock - itm.inReserve;
						});
					}
					if (!_.isEmpty(data[1])) {
						_.forEach(data[1], (itm) => {
							productsMap[itm.productId].price = itm.price;
						});
					}
					if (!concat) {
						this.productSelectionStore.setValue(this.productSelectionStore, 'list', products);
					} else {
						this.productSelectionStore.setValue(this.productSelectionStore, 'list', this.productSelectionStore.list.concat(products));
					}
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		} else {
			if (!concat) {
				this.productSelectionStore.setValue(this.productSelectionStore, 'list', products);
			} else {
				this.productSelectionStore.setValue(this.productSelectionStore, 'list', this.productSelectionStore.list.concat(products));
			}
			this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
		}
	}
	showFeatures(product: any) {
		this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', true);
		this.productSelectionStore.setValue(this.productSelectionStore, 'rootProduct', product);
		controller
			.fetchFeaturesByProduct({ product })
			.then((data) => {
				if (!_.isEmpty(data)) {
					if (!this.props.dontDisplayStocksAndPrices) {
						let productItems: Array<any> = [];
						for (let item of data) {
							let productItem: any = {
								featureId: item.id,
								id: item.id,
								feature: item,
								productId: product.id,
								product,
								unitId: product.unitId,
								unit: product.unit,
								priceTypeId: _.get(this.props.modelStore, 'model.priceTypeId', this.props.productItemTemplate.priceTypeId)
							};
							productItem.validityPeriodId = product.validityPeriodId;
							if (!_.isEmpty(product.validityPeriod)) {
								productItem.validityPeriod = product.validityPeriod;
							}
							productItem.subscriptionOptionId = product.subscriptionOptionId;
							if (!_.isEmpty(product.subscriptionOption)) {
								productItem.subscriptionOption = product.subscriptionOption;
							}
							productItems.push(productItem);
						}
						return controller.updateProductItemListPrices(productItems);
					} else {
						let result: Array<any> = [];
						for (let item of data) {
							let productItem: any = {
								productId: product.id,
								product,
								feature: item,
								featureId: item.id,
								id: item.id,
								unitId: product.unitId,
								unit: product.unit
							};
							productItem.validityPeriodId = product.validityPeriodId;
							if (!_.isEmpty(product.validityPeriod)) {
								productItem.validityPeriod = product.validityPeriod;
							}
							productItem.subscriptionOptionId = product.subscriptionOptionId;
							if (!_.isEmpty(product.subscriptionOption)) {
								productItem.subscriptionOption = product.subscriptionOption;
							}
							result.push(productItem);
						}
						this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', result);
						this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
					}
				}
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					let result: Array<any> = data;
					let ids: Array<string> = [];
					for (let item of result) {
						ids.push(item.id);
					}
					let productInStocksFilter = {
						where: {
							featureId: {
								inq: ids
							},
							productId: product.id
						},
						include: ['product', 'feature']
					};
					controller
						.balance('productInStocks', productInStocksFilter)
						.then((data) => {
							if (!_.isEmpty(data)) {
								let resultMap = _.chain(result).keyBy('id').value();
								for (let item of data) {
									if (!_.isEmpty(resultMap[item.featureId])) {
										resultMap[item.featureId].inStock = item.inStock - item.inReserve;
									}
								}
							}
							this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', result);
							this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
						})
						.catch((error) => {
							catalog.handleNatError(error);
							this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', result);
							this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
						});
				} else {
					this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.productSelectionStore.setValue(this.productSelectionStore, 'isLoading', false);
			});
	}
	selectProduct(data: any) {
		const currentProductItemsMap = _.chain(this.productSelectionStore.productItems)
			.keyBy((item) => {
				return `${item.productId}-${item.featureId}`;
			})
			.value();
		const productItem = _.cloneDeep(this.props.productItemTemplate);
		productItem.id = generateRandomId();
		if (!this.productSelectionStore.isRoot) {
			productItem.product = data.product;
			productItem.productId = data.productId;
			if (productItem.featureId !== undefined) {
				productItem.featureId = data.featureId;
				productItem.feature = data.feature;
			}
		} else {
			productItem.product = data;
			productItem.productId = data.id;
		}
		if (productItem.unitId !== undefined) {
			productItem.unitId = data.unitId;
			if (!_.isEmpty(data.unit)) {
				productItem.unit = data.unit;
			}
		}
		if (productItem.validityPeriodId !== undefined) {
			productItem.validityPeriodId = data.validityPeriodId;
			if (!_.isEmpty(data.validityPeriod)) {
				productItem.validityPeriod = data.validityPeriod;
			}
		}
		if (productItem.optionId !== undefined) {
			productItem.optionId = data.subscriptionOptionId;
			if (!_.isEmpty(data.subscriptionOption)) {
				productItem.option = data.subscriptionOption;
			}
		}
		if (productItem.subscriptionOptionId !== undefined) {
			productItem.subscriptionOptionId = data.subscriptionOptionId;
			if (data.subscriptionOption) {
				productItem.subscriptionOption = data.subscriptionOption;
			}
		}
		if (productItem.subscriptionPlanId !== undefined) {
			productItem.subscriptionPlanId = data.subscriptionPlanId;
			if (data.subscriptionPlan) {
				productItem.subscriptionPlan = data.subscriptionPlan;
			}
		}
		if (currentProductItemsMap[`${productItem.productId}-${productItem.featureId}`] === undefined) {
			if (this.props.productItemTemplate.price !== undefined) {
				if (data.price !== undefined) {
					productItem.price = data.price;
				}
				runInAction(() => {
					this.productSelectionStore.productItems.push(productItem);
					if (!this.props.notRecalculateAmount) {
						catalog.recalculateList(this.productSelectionStore.productItems);
					}
				});
			} else {
				runInAction(() => {
					this.productSelectionStore.productItems.push(productItem);
				});
			}
		} else {
			if (this.props.productItemTemplate.quantity !== undefined) {
				for (let item of this.productSelectionStore.productItems) {
					if (`${item.productId}-${item.featureId}` === `${productItem.productId}-${productItem.featureId}`) {
						runInAction(() => {
							item.quantity = item.quantity + 1;
						});
						break;
					}
				}
				let copy = _.cloneDeep(this.productSelectionStore.productItems);
				this.productSelectionStore.setValue(this.productSelectionStore, 'productItems', copy);
				if (!this.props.notRecalculateAmount) {
					catalog.recalculateList(this.productSelectionStore.productItems);
				}
			}
		}
		this.productSelectionStore.setValue(this.productSelectionStore, 'total', catalog.calculateTotal(this.productSelectionStore.productItems));
		this.animateTotal();
		this.makeReactions();
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.productSelectionStore.productItems).keyBy('id').value();
		let collectedModelsMap = _.chain(this.productSelectionStore.collectedModels).keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (collectedModelsMap[obj.id] === undefined) {
					runInAction(() => {
						this.productSelectionStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		let idsMap = _.chain(arrayIds)
			.keyBy((item) => {
				return item;
			})
			.value();
		_.map(this.productSelectionStore.collectedModels, (item: any) => {
			if (idsMap[item.id] !== undefined) {
				buffer.push(item);
			}
		});
		this.productSelectionStore.setValue(this.productSelectionStore, 'collectedModels', buffer);
	}
	deleteProductItems(collectedModels: Array<any>, deleteAll?: boolean) {
		if (!deleteAll) {
			const currentProductItemsMap = _.chain(this.productSelectionStore.productItems).keyBy('id').value();
			_.forEach(collectedModels, (item) => {
				if (currentProductItemsMap[item.id] !== undefined) {
					runInAction(() => {
						this.productSelectionStore.productItems.splice(this.productSelectionStore.productItems.indexOf(item), 1);
					});
				}
			});
			this.productSelectionStore.setValue(this.productSelectionStore, 'total', catalog.calculateTotal(this.productSelectionStore.productItems));
			this.animateTotal();
			this.productSelectionStore.setValue(this.productSelectionStore, 'collectedModels', []);
		} else {
			this.productSelectionStore.setValue(this.productSelectionStore, 'productItems', []);
			this.productSelectionStore.setValue(this.productSelectionStore, 'total', catalog.calculateTotal(this.productSelectionStore.productItems));
			this.animateTotal();
			this.productSelectionStore.setValue(this.productSelectionStore, 'collectedModels', []);
		}
	}
	toggleCollapses(index: number) {
		const position = this.state.collapses.indexOf(index);
		let newDetails = this.state.collapses.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			collapses: newDetails
		});
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.productSelectionStore.setValue(this.productSelectionStore, 'selectedItems', []);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productItems', []);
		this.productSelectionStore.setValue(this.productSelectionStore, 'productItems', items);
		this.makeReactions();
	}
	toggleFilters(status: boolean) {
		this.setState({
			isFilterOpen: status
		});
	}
	resetSearchFields() {
		runInAction(() => {
			this.productSelectionStore.chipsFields = {
				name: {
					id: null,
					chips: [],
					title: 'Наименование',
					isLocalizedString: true
				},
				article: {
					id: null,
					chips: [],
					title: 'Артикул'
				},
				createdAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true
				},
				createdAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false
				}
			};
		});
	}
	animateTotal() {
		let totalSpan = this.totalRef.current.querySelector('.nat__selection__total');
		if (totalSpan) {
			if (totalSpan.style.background !== '#0b1751') {
				totalSpan.style.background = '#0b1751';
				totalSpan.style.color = 'white';
				setTimeout(() => {
					totalSpan.style.background = 'transparent';
					totalSpan.style.color = 'black';
				}, 100);
			}
		}
	}
	formTypes() {
		let validTypes: Array<string> = [];
		if (this.props.typeFilter) {
			if (this.props.typeFilter.inq) {
				for (let item of this.props.typeFilter.inq) {
					if (item !== 'product_types.set') {
						validTypes.push(item);
					}
				}
				this.productSelectionStore.setValue(this.productSelectionStore, 'validProductTypes', validTypes);
			} else if (this.props.typeFilter.nin) {
				let filter = {
					where: {
						ownerId: 'product_types',
						id: this.props.typeFilter,
						predefinedName: {
							nin: ['ProductTypesGiftCard']
						}
					}
				};
				controller
					.findAll('enumerationItems', filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							for (let item of data) {
								validTypes.push(item.id);
							}
						}
						this.productSelectionStore.setValue(this.productSelectionStore, 'validProductTypes', validTypes);
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
		}
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ПЕРЕНЕСТИ',
				onClick: () => {
					this.saveProductItems();
					this.props.toggleFeedSelection(false);
					this.resetData();
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleFeedSelection(false);
					this.resetData();
				}
			},
			{
				title: `К ТОВАРАМ (${catalog.renderCatalogName(this.productSelectionStore.rootProduct)})`,
				onClick: () => {
					this.productSelectionStore.setValue(this.productSelectionStore, 'isRoot', true);
					this.productSelectionStore.setValue(this.productSelectionStore, 'productFeatureList', []);
					this.state.querySelector.scrollTo(0, this.productSelectionStore.scrollbarPosition);
				},
				dontShow: this.productSelectionStore.isRoot
			}
		];
		return ReactDOM.createPortal(
			<>
				<CModal
					show={this.props.isModalOpen}
					className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__product__selection__mobile'}
					size="xl"
					closeOnBackdrop={false}>
					<CModalHeader
						className={(() => {
							if (this.injected.directoryStore.models.windowSize >= 1200) {
								if (this.state.collapses.includes(1)) {
									return 'd-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between';
								} else {
									return 'd-flex p-2 m-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between';
								}
							} else {
								return 'd-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between';
							}
						})()}>
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
						<CButton
							type="button"
							size="sm"
							className="nat__button__hover ml-auto"
							onClick={() => {
								this.toggleFilters(true);
							}}>
							{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-filter" size="lg" /> : `ФИЛЬТРЫ (${this.productSelectionStore.chips.length})`}
						</CButton>
						<CButton
							variant="ghost"
							onClick={() => {
								this.toggleCollapses(1);
							}}
							className="">
							<CIcon name={!this.state.collapses.includes(1) ? 'cil-plus' : 'cil-minus'} />
						</CButton>
					</CModalHeader>
					<div className="d-flex flex-column default__nat__card__body__height">
						<div
							className={(() => {
								if (this.state.collapses.includes(1)) {
									if (this.injected.directoryStore.models.windowSize >= 1200) {
										return 'h-100 d-flex m-4';
									} else {
										return 'flex-column d-flex m-4 nat__modal__tree__mobile';
									}
								} else {
									return 'd-none';
								}
							})()}>
							<div className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal__tree nat__tree__wrapper nat__borders__left__2-75' : 'nat__modal__tree nat__card__wrapper nat__borders__2-75'}>
								<TreeMenu
									activeKey={this.productSelectionStore.treeState.activeKey}
									focusKey={this.productSelectionStore.treeState.focusKey}
									openNodes={this.productSelectionStore.treeState.openNodes}
									data={this.productSelectionStore.groupsTree}
									hasSearch={false}>
									{({ items }) => (
										<>
											<ul className="rstm-tree-item-group mb-0 p-3" ref={this.treeRef}>
												{items.map((item) => (
													<NatTreeItemComponent
														key={item.key}
														level={item.level}
														label={item.label}
														isOpen={item.isOpen}
														hasNodes={item.hasNodes}
														active={item.active}
														focused={item.focused}
														openedIcon={<CIcon src={natOpenTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
														closedIcon={<CIcon src={natCloseTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
														onClick={() => {
															this.showNestedElements(item);
															this.toggleNodeByNode(item);
														}}
														toggleNode={() => {
															this.toggleNodeByIcon(item);
														}}
													/>
												))}
											</ul>
										</>
									)}
								</TreeMenu>
							</div>
							<div
								className={
									this.injected.directoryStore.models.windowSize >= 1200
										? 'nat__modal__table__wrapper nat__card__wrapper nat__borders__right__2-75 w-100 nat__table__hover'
										: 'nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover nat__modal__table__mobile'
								}>
								<div className="nat__modal__table_1" ref={this.myRef}>
									<GridTable
										columns={catalog.checkColumnCondition(this.state.columns)}
										rows={(() => {
											if (this.productSelectionStore.isRoot) {
												return this.productSelectionStore.list;
											} else {
												return this.productSelectionStore.productFeatureList;
											}
										})()}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										onRowClick={({ data }: { data: any; column: any }) => {
											if (!this.props.dontDisplayFeatures) {
												if (this.productSelectionStore.isRoot) {
													if (_.isEmpty(data.useFeatureOptionId) || data.useFeatureOptionId === 'use_feature_options.not_used') {
														this.selectProduct(data);
													} else {
														this.productSelectionStore.setValue(this.productSelectionStore, 'isRoot', false);
														this.state.querySelector.scrollTo(0, 0);
														this.showFeatures(data);
													}
												} else {
													this.selectProduct(data);
												}
											} else {
												this.selectProduct(data);
											}
										}}
										onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
											this.sortList(colId, isAsc);
										}}
									/>
								</div>
							</div>
						</div>
						<CModalHeader
							className={(() => {
								if (this.injected.directoryStore.models.windowSize >= 1200) {
									return 'd-flex mb-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between';
								} else {
									return 'd-flex p-2 m-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between';
								}
							})()}>
							<CButton
								type="button"
								size="sm"
								className="nat__button__hover"
								onClick={() => {
									this.deleteProductItems(this.productSelectionStore.collectedModels);
								}}>
								УДАЛИТЬ
							</CButton>
							<CButton
								type="button"
								size="sm"
								className="nat__button__hover"
								onClick={() => {
									this.deleteProductItems(this.productSelectionStore.collectedModels, true);
								}}>
								ОЧИСТИТЬ
							</CButton>
							<span
								className="ml-auto cursor__pointer"
								ref={this.totalRef}
								onClick={() => {
									this.toggleCollapses(2);
								}}>
								<span>Всего выбрано</span>
								<span className="nat__selection__total rounded">{` ${this.productSelectionStore.total} `}</span>
								<span>товаров</span>
							</span>
							<CButton
								variant="ghost"
								onClick={() => {
									this.toggleCollapses(2);
								}}>
								<CIcon name={!this.state.collapses.includes(2) ? 'cil-plus' : 'cil-minus'} />
							</CButton>
						</CModalHeader>
						<div
							className={(() => {
								if (this.state.collapses.includes(2)) {
									if (this.injected.directoryStore.models.windowSize >= 1200) {
										return 'h-100 nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 nat__table__hover mx-4 mb-4 flex-column d-flex';
									} else {
										return 'nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 nat__table__hover mx-4 mb-4 flex-column d-flex nat__modal__table__mobile_2';
									}
								} else {
									return 'd-none';
								}
							})()}>
							<div className="nat__modal__table_2">
								<GridTable
									columns={(() => {
										let arr = _.cloneDeep(this.state.basketColumns);
										_.forEach(this.props.productItemsColumns, (item) => {
											if (item.id !== 'checkbox' && item.id !== 1) {
												arr.push(item);
											}
										});
										return catalog.checkColumnVisibility(arr, this.productSelectionStore.productItems);
									})()}
									rows={this.productSelectionStore.productItems}
									isPaginated={false}
									texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
									showSearch={false}
									showColumnVisibilityManager={false}
									showRowsInformation={false}
									onColumnsChange={() => {}}
									selectedRowsIds={_.map(this.productSelectionStore.collectedModels, 'id')}
									onSelectedRowsChange={(selectedRowsIds: any) => {
										this.collectModels(selectedRowsIds);
									}}
								/>
							</div>
						</div>

						{this.productSelectionStore.isLoading && (
							<div className="nat__spinner__absolute">
								<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
							</div>
						)}
					</div>
				</CModal>
				{this.state.isFilterOpen && (
					<div className="nat__selection__filters">
						<NatFilters store={this.productSelectionStore} toggleModal={this.toggleFilters} isModalOpen={this.state.isFilterOpen} resetSearchFields={this.resetSearchFields}>
							<>
								<NatValueInput<string> object={this.productSelectionStore.chipsFields.name} property="id" placeholder="Введите наименование" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Наименование" />
								<NatValueInput<string> object={this.productSelectionStore.chipsFields.article} property="id" placeholder="Введите артикул" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Артикул" />
								<NatValueInput<Date> object={this.productSelectionStore.chipsFields.createdAtGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Создан от" />
								<NatValueInput<Date> object={this.productSelectionStore.chipsFields.createdAtLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="до" />
							</>
						</NatFilters>
					</div>
				)}
			</>,
			document.querySelector('#modal') as Element
		);
	}
}

export default withRouter(ProductSelection);
