import { BigNumber } from 'bignumber.js';
import { isEmpty } from 'lodash';

import ValueFormatter from './ValueFormatter';

class PercentFormatter extends ValueFormatter<number, string> {
	public parseValue(value: string): number | undefined {
		value = value.replace(',', '.');
		if (isEmpty(value)) {
			value = '0';
		}
		return new BigNumber(value).dividedBy(new BigNumber(100)).toNumber();
	}

	public renderValue(value: number): string {
		return new BigNumber(value).multipliedBy(new BigNumber(100)).toString();
	}
}

export let percentFormatter = new PercentFormatter();

export default PercentFormatter;
