import _ from 'lodash';
import { runInAction } from 'mobx';

import { directoryStore } from '../../core/Stores/DirectoryStore';
import { NatLocalizedValueInputStoreType } from '../Inputs/InputsStore/NatLocalizedValueInputStore';
import { catalog } from '../support/Catalog';
import { LocalizedString } from '../support/modelTypes';
import ValueFormatter, { ButtonItem } from './ValueFormatter';

class LocalizedStringFormatter extends ValueFormatter<LocalizedString, string> {
	public parseValue(value: string, property: any, language: string): LocalizedString | undefined | null {
		let localizedString: LocalizedString | null = _.cloneDeep(property);
		if (!_.isEmpty(localizedString) && localizedString !== null) {
			if (!_.isEmpty(value)) {
				localizedString[language] = value;
			} else {
				localizedString = null;
			}
		} else {
			if (!_.isEmpty(value)) {
				localizedString = {
					[language]: value
				};
			} else {
				localizedString = null;
			}
		}
		return localizedString;
	}

	public renderValue(value: LocalizedString, language: string): string {
		let result: string = '';
		if (!_.isEmpty(value)) {
			result = value[language];
		} else {
			result = '';
		}
		return result;
	}

	public getActionButtons(natLocalizedValueInputStore: NatLocalizedValueInputStoreType): Array<ButtonItem<(object: any, property: string, validate: (object: any, value: LocalizedString | undefined | null) => boolean) => void>> {
		if (this.dontShowActionButtons) {
			return [];
		} else {
			let buttonItems: Array<ButtonItem<(object: any, property: string, validate: (object: any, value: LocalizedString | undefined | null) => boolean) => void>> = [
				{
					icon: 'cil-x',
					title: 'Очистить поле',
					onClick: (object: any, property: string, validate: (object: any, value: LocalizedString | undefined | null) => boolean) => {
						let value: LocalizedString | undefined | null = this.parseValue('', object[property], _.get(natLocalizedValueInputStore, 'language', 'ru'));
						if (validate(object, value)) {
							runInAction(() => {
								object[property] = value;
							});
						}
					}
				}
			];
			if (directoryStore.models.locales.length > 2) {
				buttonItems.unshift({
					icon: '',
					text: natLocalizedValueInputStore.language,
					title: 'Язык',
					children: _.map(directoryStore.models.locales, (item: any) => {
						return {
							icon: '',
							text: catalog.renderCatalogName(item),
							onClick: () => {
								natLocalizedValueInputStore.setValue(natLocalizedValueInputStore, 'language', item.language);
							}
						};
					}),
					onClick: () => {}
				});
			} else if (directoryStore.models.locales.length === 2) {
				buttonItems.unshift({
					icon: '',
					text: natLocalizedValueInputStore.language,
					title: 'Язык',
					onClick: () => {
						let locale = _.find(directoryStore.models.locales, (item: any) => {
							return item.language !== _.get(natLocalizedValueInputStore, 'language', 'ru');
						});
						natLocalizedValueInputStore.setValue(natLocalizedValueInputStore, 'language', locale.language);
					}
				});
			}
			return buttonItems;
		}
	}
}

export let localizedStringFormatter = new LocalizedStringFormatter();

export default LocalizedStringFormatter;
