import CIcon from '@coreui/icons-react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatList from '../../shared/Components/NatList';
import NatListContainer from '../../shared/Components/NatListContainer';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import { CouponsListStoreType } from './CouponsListStore';

interface PropsType extends FormContainerPropsType<CouponsListStoreType> {}

interface StateType {}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class CouponsListContainer extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.createFilter = this.createFilter.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	createFilter() {
		// if (!_.isEmpty(partnersSearchFields.name)) {
		// 	or = [
		// 		{
		// 			name: {
		// 				like: partnersSearchFields.name,
		// 				options: 'i'
		// 			}
		// 		},
		// 		{
		// 			sequenceNumber: {
		// 				like: partnersSearchFields.name
		// 			}
		// 		}
		// 	];
		// 	filter.where.or = or;
		// }
	}
	resetSearchFields() {
		runInAction(() => {
			this.props.store.chipsFields = {
				name: {
					id: null,
					chips: [],
					title: 'Наименование',
					isLocalizedString: true
				},
				sequenceNumber: {
					id: null,
					chips: [],
					title: 'Номер'
				},
				createdAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true
				},
				createdAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false
				},
				deleted: {
					id: false,
					display: { isChecked: false, title: 'Отображать' },
					doNotDisplay: { isChecked: true, title: 'Не отображать' },
					chips: [
						{
							id: generateRandomId(),
							name: {
								ru: 'Удаленные: не отображать'
							}
						}
					],
					isBoolean: true,
					title: 'Удаленные'
				}
			};
		});
	}
	render() {
		return (
			<NatListContainer
				store={this.props.store}
				createFilter={this.createFilter}
				resetSearchFields={this.resetSearchFields}
				defaultFilterWhere={{ deleted: false, elementType: 0 }}
				navigationRoute={this.props.navigationRoute}
				index={this.props.index}>
				{(childrenProps) => (
					<NatList
						name="coupons"
						store={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						columns={[
							{
								id: 'checkbox',
								pinned: true,
								className: '',
								width: '54px',
								minResizeWidth: 0,
								maxResizeWidth: null,
								resizable: false,
								visible: true,
								headerCellRenderer: () => {
									return <div></div>;
								},
								cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
									return (
										<div className="rgt-cell-inner ml-0 mr-0">
											<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
										</div>
									);
								}
							},
							{
								id: 1,
								field: 'deleted',
								label: '',
								width: '70px',
								cellRenderer: ({ data }: { data: any }) => {
									return <div className="rgt-cell-inner">{<CIcon src={catalog.pickupCatalogStateImage(data)} size="lg" />}</div>;
								},
								sort: () => {}
							},
							{
								id: 2,
								field: 'sequenceNumber',
								label: 'Номер',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{data.sequenceNumber}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 3,
								field: 'createdAt',
								label: 'Дата создания',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{listDateTimeFormatter.renderValue(data.createdAt)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 4,
								field: 'name',
								label: 'Имя',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data)}
										</div>
									);
								},
								sort: () => {}
							}
						]}>
						<></>
					</NatList>
				)}
			</NatListContainer>
		);
	}
}

export default CouponsListContainer;
