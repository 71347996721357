import { DateTime } from 'luxon';

import ValueFormatter from './ValueFormatter';

class ListDateTimeFormatter extends ValueFormatter<Date, string> {
	public parseValue(value: string): Date | undefined {
		let result: Date | undefined = undefined;
		let dateRegex = /^\d{4}-\d\d-\d\d$/;
		if (dateRegex.test(value)) {
			result = new Date(value);
		}
		return result;
	}

	public renderValue(value: Date | null): string {
		if (value !== undefined && value !== null) {
			return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT, { locale: 'ru' }).replace(',', '');
		}
		return '';
	}
}

export let listDateTimeFormatter = new ListDateTimeFormatter();

export default ListDateTimeFormatter;
