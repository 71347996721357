import { observable, action, makeObservable, set } from 'mobx';

import { ResponsiblePersonsType } from '../../shared/support/modelTypes';

export interface ResponsiblePersonFormStoreType {
	model: ResponsiblePersonsType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class ResponsiblePersonFormStore implements ResponsiblePersonFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		naturalPersonId: '',
		startedAt: new Date(),
		expiredAt: null,
		typeId: null,
		elementType: 0,
		parentId: null,
		position: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		ownerType: 'OrmOrganization',
		ownerId: null
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'responsiblePersons';
	filterInclude = ['type', 'naturalPerson', 'owner'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmResponsiblePerson';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const responsiblePersonFormStore = new ResponsiblePersonFormStore();

export default ResponsiblePersonFormStore;
