import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CModal, CModalHeader, CRow, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import NatTabs from '../../shared/Components/NatTabs';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { listDateFormatter } from '../../shared/Formatters/ListDateFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatDefaultRadio from '../../shared/Inputs/NatDefaultRadio';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { models } from '../../shared/support/NatModels';
import { LoyaltyPolicyFormStoreType } from '../LoyaltyPolicy/LoyaltyPolicyFormStore';
import NatLoyaltyPolicyRangeManagerStore, { FilterType, NatLoyaltyPolicyRangeManagerStoreType } from './NatLoyaltyPolicyRangeManagerStore';
import NatLoyaltyPolicyRangeStatusManager from './NatLoyaltyPolicyRangeStatusManager';

interface PropsType extends RouteComponentProps {
	toggleNatLoyaltyPolicyRangeManager(status: boolean): void;
	isModalOpen: boolean;
	modelStore: LoyaltyPolicyFormStoreType;
}

interface StateType {
	columns: (type: string) => Array<any>;
	isLoyaltyPolicyRangeStatusManagerOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatLoyaltyPolicyRangeManager extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	natLoyaltyPolicyRangeManagerStore: NatLoyaltyPolicyRangeManagerStoreType;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.collectModels = this.collectModels.bind(this);
		this.resetReactions = this.resetReactions.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.filterSourceList = this.filterSourceList.bind(this);
		this.buildSourceList = this.buildSourceList.bind(this);
		this.checkSourceStatus = this.checkSourceStatus.bind(this);
		this.getSource = this.getSource.bind(this);
		this.toggleNatLoyaltyPolicyRangeStatusManager = this.toggleNatLoyaltyPolicyRangeStatusManager.bind(this);
		this.saveEnablePolicyRanges = this.saveEnablePolicyRanges.bind(this);
		this.saveDisablePolicyRanges = this.saveDisablePolicyRanges.bind(this);
		this.natLoyaltyPolicyRangeManagerStore = new NatLoyaltyPolicyRangeManagerStore();
		this.state = {
			isLoyaltyPolicyRangeStatusManagerOpen: false,
			columns: (type: string) => {
				return [
					{
						id: 'checkbox',
						pinned: true,
						className: '',
						width: '54px',
						minResizeWidth: 0,
						maxResizeWidth: null,
						resizable: false,
						visible: true,
						headerCellRenderer: () => {
							return <div></div>;
						},
						cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
							return (
								<div className="rgt-cell-inner ml-0 mr-0">
									<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
								</div>
							);
						}
					},
					{
						id: 2,
						field: 'source',
						label: models[type].displayName,
						width: '220px',
						cellRenderer: ({ data }: { data: any }) => {
							return (
								<div
									className={(() => {
										if (!data.enabled) {
											return 'rgt-cell-inner rgt-text-truncate text-secondary';
										} else {
											return 'rgt-cell-inner rgt-text-truncate';
										}
									})()}>
									{catalog.renderCatalogName(data.source)}
								</div>
							);
						},
						sort: () => {}
					},
					{
						id: 3,
						field: 'enabled',
						label: 'Действует',
						width: '220px',
						cellRenderer: ({ data }: { data: any }) => {
							return (
								<div
									className={(() => {
										if (!data.enabled) {
											return 'rgt-cell-inner rgt-text-truncate text-secondary';
										} else {
											return 'rgt-cell-inner rgt-text-truncate';
										}
									})()}>
									{data.enabled && <CIcon name="cil-check-alt" size="lg" />}
								</div>
							);
						},
						sort: () => {}
					},
					{
						id: 4,
						field: 'startedAt',
						label: 'Дата начала',
						cellRenderer: ({ data }: { data: any }) => {
							return (
								<div
									className={(() => {
										if (!data.enabled) {
											return 'rgt-cell-inner rgt-text-truncate text-secondary';
										} else {
											return 'rgt-cell-inner rgt-text-truncate';
										}
									})()}>
									{listDateFormatter.renderValue(data.startedAt)}
								</div>
							);
						},
						sort: () => {}
					},
					{
						id: 5,
						field: 'expiredAt',
						label: 'Дата окончания',
						cellRenderer: ({ data }: { data: any }) => {
							return (
								<div
									className={(() => {
										if (!data.enabled) {
											return 'rgt-cell-inner rgt-text-truncate text-secondary';
										} else {
											return 'rgt-cell-inner rgt-text-truncate';
										}
									})()}>
									{(() => {
										if (data.enabled) {
											if (data.expiredAt) {
												return listDateFormatter.renderValue(data.expiredAt);
											} else {
												return 'Бессрочно';
											}
										} else {
											return listDateFormatter.renderValue(data.expiredAt);
										}
									})()}
								</div>
							);
						},
						sort: () => {}
					},
					{
						id: 6,
						field: 'note',
						label: 'Примечание',
						cellRenderer: ({ data }: { data: any }) => {
							return (
								<div
									className={(() => {
										if (!data.enabled) {
											return 'rgt-cell-inner rgt-text-truncate text-secondary';
										} else {
											return 'rgt-cell-inner rgt-text-truncate';
										}
									})()}>
									{data.note}
								</div>
							);
						},
						sort: () => {}
					}
				];
			}
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetReactions();
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmWarehouse.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmWarehouse');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmLoyaltyCardKind.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmLoyaltyCardKind');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmCouponKind.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmCouponKind');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmOrganization.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmOrganization');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmPartnerContract.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmPartnerContract');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.OrmSubscriptionPlan.mode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.filterSourceList('OrmSubscriptionPlan');
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.tabMetadata,
				() => {
					this.filterSourceList(this.natLoyaltyPolicyRangeManagerStore.tabMetadata.tabMetadata.type);
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natLoyaltyPolicyRangeManagerStore.date,
				() => {
					this.checkSourceStatus();
					this.filterSourceList(this.natLoyaltyPolicyRangeManagerStore.tabMetadata.tabMetadata.type);
				}
			)
		);
	}
	componentDidMount() {
		this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'isLoading', true);
		this.getSource()
			.then(() => {
				this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	getSource() {
		let loyaltyPolicyRangeFilter = {
			where: { loyaltyPolicyId: this.props.modelStore.model.id },
			include: this.natLoyaltyPolicyRangeManagerStore.filterInclude,
			order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
		};
		return controller
			.findAll(this.natLoyaltyPolicyRangeManagerStore.pluralName, loyaltyPolicyRangeFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'loyaltyPolicyRanges', data);
				}
				let filter = {
					where: { deleted: false, elementType: 0 },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('warehouses', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmWarehouse');
				}
				let filter = {
					where: { deleted: false, elementType: 0 },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('loyaltyCardKinds', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmLoyaltyCardKind');
				}
				let filter = {
					where: { deleted: false },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('couponKinds', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmCouponKind');
				}
				let filter = {
					where: { deleted: false, elementType: 0 },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('partnerContracts', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmPartnerContract');
				}
				let filter = {
					where: { deleted: false },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('organizations', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmOrganization');
				}
				let filter = {
					where: { deleted: false },
					order: this.natLoyaltyPolicyRangeManagerStore.filterOrder
				};
				return controller.findAll('subscriptionPlans', filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildSourceList(data, 'OrmSubscriptionPlan');
				}
				this.filterSourceList(this.natLoyaltyPolicyRangeManagerStore.tabMetadata.tabMetadata.type);
				this.makeReactions();
			});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.natLoyaltyPolicyRangeManagerStore.list).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.natLoyaltyPolicyRangeManagerStore.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.natLoyaltyPolicyRangeManagerStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.natLoyaltyPolicyRangeManagerStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'collectedModels', buffer);
	}
	resetReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	filterSourceList(sourceType: string) {
		let sourceFilter: FilterType = this.natLoyaltyPolicyRangeManagerStore[sourceType];
		if (sourceFilter.mode === 'all') {
			this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'list', _.filter(this.natLoyaltyPolicyRangeManagerStore.sources, { sourceType: sourceFilter.type }));
		} else if (sourceFilter.mode === 'whereOperate') {
			this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'list', _.filter(this.natLoyaltyPolicyRangeManagerStore.sources, { sourceType: sourceFilter.type, enabled: true }));
		}
	}
	buildSourceList(sources: Array<any>, sourceType: string) {
		let loyaltyPolicyRangesMap = _.chain(this.natLoyaltyPolicyRangeManagerStore.loyaltyPolicyRanges).groupBy('sourceId').value();
		let result: Array<any> = [];
		_.transform(
			sources,
			(result: Array<any>, value: any) => {
				let item: any = {
					sourceType,
					sourceId: value.id,
					id: generateRandomId(),
					startedAt: null,
					expiredAt: null,
					enabled: false,
					note: null,
					source: value,
					loyaltyPolicyId: this.props.modelStore.model.id
				};
				if (!_.isEmpty(loyaltyPolicyRangesMap[value.id])) {
					let lastEnabledItem = _.chain(loyaltyPolicyRangesMap[value.id]).filter({ enabled: true }).orderBy(['period'], ['asc']).last().value();
					let lastDisabledItem = _.chain(loyaltyPolicyRangesMap[value.id]).filter({ enabled: false }).orderBy(['period'], ['asc']).last().value();
					if (lastEnabledItem) {
						let enabledPeriod = DateTime.fromISO(lastEnabledItem.period);
						let currentDate = DateTime.fromISO(this.natLoyaltyPolicyRangeManagerStore.date.toISOString());
						item.startedAt = lastEnabledItem.period;
						if (lastDisabledItem) {
							let disabledPeriod = DateTime.fromISO(lastDisabledItem.period);
							if (disabledPeriod.diff(enabledPeriod).toObject().milliseconds > 0) {
								item.expiredAt = lastDisabledItem.period;
								if (currentDate.diff(disabledPeriod).toObject().milliseconds < 0 && currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
									item.enabled = true;
									item.note = lastEnabledItem.note;
								} else {
									item.note = lastDisabledItem.note;
								}
							} else {
								if (currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
									item.enabled = true;
									item.note = lastEnabledItem.note;
								}
							}
						} else {
							if (currentDate.diff(enabledPeriod).toObject().milliseconds > 0) {
								item.enabled = true;
								item.note = lastEnabledItem.note;
							}
						}
					} else {
						if (lastDisabledItem) {
							item.expiredAt = lastDisabledItem.period;
						}
					}
				}
				result.push(item);
			},
			result
		);
		this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'sources', this.natLoyaltyPolicyRangeManagerStore.sources.concat(result));
	}
	checkSourceStatus() {
		let currentDate: any = null;
		if (this.natLoyaltyPolicyRangeManagerStore.date) {
			currentDate = DateTime.fromISO(this.natLoyaltyPolicyRangeManagerStore.date.toISOString());
		}
		if (currentDate) {
			for (let item of this.natLoyaltyPolicyRangeManagerStore.sources) {
				if (item.startedAt) {
					let startedAt = DateTime.fromISO(item.startedAt);
					if (item.expiredAt) {
						let expiredAt = DateTime.fromISO(item.expiredAt);
						if (currentDate.diff(expiredAt).toObject().milliseconds < 0 && currentDate.diff(startedAt).toObject().milliseconds > 0) {
							runInAction(() => {
								item.enabled = true;
							});
						} else {
							runInAction(() => {
								item.enabled = false;
							});
						}
					} else {
						if (currentDate.diff(startedAt).toObject().milliseconds > 0) {
							runInAction(() => {
								item.enabled = true;
							});
						} else {
							runInAction(() => {
								item.enabled = false;
							});
						}
					}
				}
			}
		}
	}
	toggleNatLoyaltyPolicyRangeStatusManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeStatusManagerOpen: status
		});
	}
	saveEnablePolicyRanges() {
		let result: Array<any> = [];
		_.transform(
			this.natLoyaltyPolicyRangeManagerStore.collectedModels,
			(result: any, value: any) => {
				let policyRange = {
					loyaltyPolicyId: value.loyaltyPolicyId,
					sourceId: value.sourceId,
					sourceType: value.sourceType,
					note: this.natLoyaltyPolicyRangeManagerStore.note,
					enabled: true,
					period: this.natLoyaltyPolicyRangeManagerStore.startedAt
				};
				result.push(policyRange);
				if (this.natLoyaltyPolicyRangeManagerStore.expiredAt) {
					let copyPolicyRange = _.cloneDeep(policyRange);
					copyPolicyRange.enabled = false;
					copyPolicyRange.period = this.natLoyaltyPolicyRangeManagerStore.expiredAt;
					result.push(copyPolicyRange);
				}
			},
			result
		);
		return controller.bulkUpsert('loyaltyPolicyRanges', result).then(() => {
			this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'sources', []);
			return this.getSource();
		});
	}
	saveDisablePolicyRanges() {
		let result: Array<any> = [];
		_.transform(
			this.natLoyaltyPolicyRangeManagerStore.collectedModels,
			(result: any, value: any) => {
				let policyRange = {
					loyaltyPolicyId: value.loyaltyPolicyId,
					sourceId: value.sourceId,
					sourceType: value.sourceType,
					note: this.natLoyaltyPolicyRangeManagerStore.note,
					enabled: false,
					period: this.natLoyaltyPolicyRangeManagerStore.expiredAt
				};
				result.push(policyRange);
			},
			result
		);
		return controller.bulkUpsert('loyaltyPolicyRanges', result).then(() => {
			this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'sources', []);
			return this.getSource();
		});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАКРЫТЬ',
				onClick: () => {
					this.props.toggleNatLoyaltyPolicyRangeManager(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<CForm className="mx-5 mb-3">
						<CRow>
							<CCol lg="12">
								<CFormGroup row className="mb-0">
									<CCol md="4">
										<NatValueInput<Date> object={this.natLoyaltyPolicyRangeManagerStore} property="date" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="На дату" />
									</CCol>
								</CFormGroup>
							</CCol>
						</CRow>
					</CForm>
				</CModalHeader>
				<NatTabs
					getCurrentTab={(data: { tabIndex: number; tabMetadata: { type: string } }) => {
						this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'tabMetadata', data);
					}}
					navItems={[
						{
							title: 'На складах',
							metadata: { type: 'OrmWarehouse' }
						},
						{
							title: 'В видах карт лояльности',
							metadata: { type: 'OrmLoyaltyCardKind' }
						},
						{
							title: 'В видах купонов',
							metadata: { type: 'OrmCouponKind' }
						},
						{
							title: 'В договорах',
							metadata: { type: 'OrmPartnerContract' }
						},
						{
							title: 'В организациях',
							metadata: { type: 'OrmOrganization' }
						},
						{
							title: 'В планах подписок',
							metadata: { type: 'OrmSubscriptionPlan' }
						}
					]}
				/>
				<div className="d-flex flex-column default__nat__card__body__height p-0 mx-4 mb-4">
					<NatControlPanel
						iconClassname="text-danger"
						className="nat__form__control__buttons nat__card__wrapper nat__borders__2-75 p-2 my-4"
						customControlItems={[
							<NatDefaultRadio<string>
								key={generateRandomId()}
								options={[
									{ title: 'Все', value: 'all' },
									{ title: 'Где действуют', value: 'whereOperate' }
								]}
								inline={true}
								row={true}
								labelMd="3"
								optionsMd="9"
								formatter={stringFormatter()}
								object={this.natLoyaltyPolicyRangeManagerStore[this.natLoyaltyPolicyRangeManagerStore.tabMetadata.tabMetadata.type]}
								property="mode"
								label="Показывать"
								className="p-2"
							/>,
							<CDropdown key={generateRandomId()}>
								<CDropdownToggle className="mr-1 nat__button__hover" caret disabled={this.natLoyaltyPolicyRangeManagerStore.collectedModels.length === 0}>
									Установить статус
								</CDropdownToggle>
								<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
									<CDropdownItem
										onClick={() => {
											this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'status', 'enabled');
											this.toggleNatLoyaltyPolicyRangeStatusManager(true);
										}}>
										Действует
									</CDropdownItem>
									<CDropdownItem
										onClick={() => {
											this.natLoyaltyPolicyRangeManagerStore.setValue(this.natLoyaltyPolicyRangeManagerStore, 'status', 'disabled');
											this.toggleNatLoyaltyPolicyRangeStatusManager(true);
										}}>
										Не действует
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
						]}
					/>
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1">
							<GridTable
								columns={this.state.columns(this.natLoyaltyPolicyRangeManagerStore.tabMetadata.tabMetadata.type)}
								rows={this.natLoyaltyPolicyRangeManagerStore.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								sort={{ colId: null }}
								isVirtualScroll={false}
								selectedRowsIds={_.map(this.natLoyaltyPolicyRangeManagerStore.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => {
									this.collectModels(selectedRowsIds);
								}}
							/>
						</div>
					</div>
				</div>
				{this.natLoyaltyPolicyRangeManagerStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.state.isLoyaltyPolicyRangeStatusManagerOpen && (
					<NatLoyaltyPolicyRangeStatusManager
						isModalOpen={this.state.isLoyaltyPolicyRangeStatusManagerOpen}
						toggleNatLoyaltyPolicyRangeStatusManager={this.toggleNatLoyaltyPolicyRangeStatusManager}
						modelStore={this.natLoyaltyPolicyRangeManagerStore}
						saveEnablePolicyRanges={this.saveEnablePolicyRanges}
						saveDisablePolicyRanges={this.saveDisablePolicyRanges}
					/>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatLoyaltyPolicyRangeManager);
