import { observable, action, makeObservable, set } from 'mobx';

import { CashRegisterType } from '../../shared/support/modelTypes';

export interface CashRegisterFormStoreType {
	model: CashRegisterType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class CashRegisterFormStore implements CashRegisterFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		organizationId: null,
		warehouseId: null,
		cashRegisterDistributionId: null,
		typeId: null,
		registrationNumber: null,
		serialNumber: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		ownerId: null,
		ownerType: null
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'cashRegisters';
	filterInclude = ['warehouse', 'organization', 'type'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultWarehouse',
			property: 'warehouseId',
			relation: 'warehouse'
		}
	];
	isFirstLoad = true;
	name = 'OrmCashRegister';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const сashRegisterFormStore = new CashRegisterFormStore();

export default CashRegisterFormStore;
