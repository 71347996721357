import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, PaymentOptionType } from '../../shared/support/modelTypes';
import PaymentOptionForm from './PaymentOptionForm';
import { PaymentOptionFormStoreType } from './PaymentOptionFormStore';

interface PropsType extends FormContainerPropsType<PaymentOptionFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class PaymentOptionFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.showValueItems = this.showValueItems.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (key !== 'type') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.ownerId,
				(value) => {
					if (!_.isEmpty(value)) {
						if (!_.isEmpty(this.props.store.model.owner)) {
							this.props.store.setValue(this.props.store.model, 'typeId', this.props.store.model.owner.typeId);
							this.props.store.setValue(this.props.store.model, 'paymentDocumentType', this.props.store.model.owner.paymentDocumentType);
							this.props.store.setValue(this.props.store.model, 'instantPayment', this.props.store.model.owner.instantPayment);
							this.props.store.setValue(this.props.store.model, 'argumentItems', this.props.store.model.owner.argumentItems);
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: PaymentOptionType) {
		return model;
	}
	prepareModelForPost(model: PaymentOptionType) {
		delete model.type;
		delete model.owner;
		runInAction(() => {
			model.argumentItemList = model.argumentItems;
		});
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.typeId) && !_.isEmpty(this.props.store.model.ownerId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	showValueItems(data: any) {
		if (_.isEmpty(data.valueId)) {
			let result: Array<any> = [];
			_.transform(
				data.valueMetadata.metadata,
				(result: any, value: any) => {
					let item: any = {
						key: value.id,
						required: value.required,
						valueType: value.valueType,
						valueId: value.valueId !== undefined ? value.valueId : null,
						valueMetadata: null,
						valueRelationValue: null,
						description: null,
						id: generateRandomId(),
						name: value.name['__data']
					};
					if (value.valueType === 'value_types.relation') {
						item.valueRelationType = value.relationType;
					}
					result.push(item);
				},
				result
			);
			runInAction(() => {
				data.valueId = result;
			});
		}
		this.props.store.setValue(this.props.store, 'argumentItem', data);
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/paymentOptions/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<PaymentOptionForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						paymentOptionFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						showValueItems={this.showValueItems}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default PaymentOptionFormContainer;
