import { CCol, CFormGroup, CLabel } from '@coreui/react';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

interface PropsType {
	wrapperClassName?: string;
	labelColumnSettings: string;
	labelColumnClassName: string;
	inputColumnClassName?: string;
	row: boolean;
	children: React.ReactNode;
	label: string;
}

@observer
class NatFormGroupLabel extends Component<PropsType> {
	render() {
		return (
			<CFormGroup row={this.props.row} className={this.props.wrapperClassName}>
				{this.props.label !== '' && (
					<CCol md={this.props.labelColumnSettings} className={this.props.labelColumnClassName}>
						<CLabel>{this.props.label}</CLabel>
					</CCol>
				)}
				<CCol
					className={(() => {
						if (this.props.row) {
							return this.props.inputColumnClassName + ' d-flex';
						} else {
							return this.props.inputColumnClassName;
						}
					})()}>
					{this.props.children}
				</CCol>
			</CFormGroup>
		);
	}
}

export default NatFormGroupLabel;
