import { observable, action, makeObservable, set } from 'mobx';

import { NewsType } from '../../shared/support/modelTypes';

export interface NewsFormStoreType {
	model: NewsType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	editorValue: string | null;
	file: string;
	isFirstLoad: boolean;
	language: string;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class NewsFormStore implements NewsFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			editorValue: observable,
			filterInclude: observable,
			file: observable,
			isFirstLoad: observable,
			language: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		caption: null,
		thumbId: null,
		title: { ru: '' },
		template: { ru: '' },
		subtitle: null,
		keywords: [],
		format: null,
		filename: '',
		description: null,
		name: { ru: '' },
		ownerId: null,
		ownerType: null,
		containerType: null,
		containerId: null,
		deleted: false,
		displayName: null,
		elementType: 0,
		parentId: null,
		priority: null,
		authorId: null
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'newsPosts';
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	editorValue = null;
	filterInclude = ['childs', 'htmlMetaTags'];
	file = '';
	isFirstLoad = true;
	language = 'ru';
	name = 'OrmNewsPost';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const newsFormStore = new NewsFormStore();

export default NewsFormStore;
