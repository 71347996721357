import { CButton, CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MatchType } from '../support/modelTypes';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	title: string;
	buttonItemList?: Array<ButtonItem>;
	justInfo?: boolean;
	loader?: boolean;
	loadPage?: () => void;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
}

@observer
class WindowSuccess extends Component<PropsType> {
	componentDidMount() {
		if (this.props.loadPage) {
			this.props.loadPage();
		}
	}
	render() {
		return (
			<div className="justify-content-center align-items-center c-app nat__gardient__bg">
				<CCard className="m-3 p-2 d-flex flex-column align-items-center nat__login nat__card__wrapper nat__borders__2-75">
					<CCardHeader className="border-0 font-weight-bold bg-transparent">
						<h5>{this.props.title}</h5>
					</CCardHeader>
					{!this.props.justInfo ? (
						<CCardBody>
							{this.props.buttonItemList ? (
								this.props.buttonItemList.map((value: ButtonItem, index: number) => {
									return (
										<CButton key={index} type="button" size="lg" className="m-1 nat__button" onClick={value.onClick}>
											{value.title}
										</CButton>
									);
								})
							) : (
								<CButton type="submit" size="lg" className="m-1 nat__button" to="/users/login">
									Закрыть
								</CButton>
							)}
						</CCardBody>
					) : (
						this.props.loader && <CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					)}
				</CCard>
			</div>
		);
	}
}

export default withRouter(WindowSuccess);
