import CIcon from '@coreui/icons-react';
import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { quantityFormatter } from '../../shared/Formatters/QuantityFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { SubscriptionPlanFormStoreType } from './SubscriptionPlanFormStore';

interface PropsType extends FormPropsType {
	subscriptionPlanFormStore: SubscriptionPlanFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
	deletePeriodItems(selectedItems: Array<any>): void;
	saveSelected(periodItem: any): void;
}
interface StateType {
	columns: Array<any>;
	modals: Array<any>;
	periodItemsColumns: Array<any>;
	setManagerColumns: Array<any>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class SubscriptionPlanForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.state = {
			modals: [],
			setManagerColumns: [
				{
					id: 1,
					field: 'productId',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productId"
									relation="product"
									placeholder="Выберите товар"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="products"
									filterWhere={{
										deleted: false,
										elementType: 0,
										typeId: {
											nin: ['product_types.set']
										}
									}}
									include={['unit', 'subscriptionOption', 'validityPeriod']}
									autoComplete="off"
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'optionId',
					label: 'Опция',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkSubscriptionOptionCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (data.product.typeId === 'product_types.subscription_option') {
											return (
												<NatRelationInput
													object={data}
													property="optionId"
													relation="option"
													placeholder="Выберите опцию"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="subscriptionOptions"
													filterWhere={{ deleted: false, elementType: 0 }}
													autoComplete="off"
													goTo={this.props.goTo}
												/>
											);
										} else {
											return null;
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'productSetId',
					label: 'Набор',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkProductSetCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId) ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
					}
				},
				{
					id: 4,
					field: 'unitId',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'featureId',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="featureId"
													relation="feature"
													placeholder="Выберите характеристику"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="productFeatures"
													autoComplete="off"
													apiMethod={(): any => {
														return controller.fetchFeaturesByProduct({ product: data.product });
													}}
													create={(pluralName, handlers) => {
														this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
													}}
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 7,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return quantityFormatter.renderValue(data.quantity);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="quantity"
													placeholder="Введите кол-во"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={quantityFormatter}
													autoComplete="off"
													verticalSwitching
													validation={(object: any, value: any) => {
														return catalog.validateQuantityInput(object, value);
													}}
													size="sm"
												/>
											);
										}
									} else {
										return quantityFormatter.renderValue(data.quantity);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'validityPeriodId',
					label: 'Период действия',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
											return (
												<NatRelationInput
													object={data}
													property="validityPeriodId"
													relation="validityPeriod"
													placeholder="Выберите период"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'periodicity' }}
													autoComplete="off"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											);
										} else {
											return null;
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 8,
					field: 'readOnly',
					label: 'Только чтение',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatCheckbox object={data} property="readOnly" />
							</div>
						);
					}
				},
				{
					id: 9,
					field: 'quantityLimitMinByDefault',
					label: 'Ограничить минимальное количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatCheckbox object={data} property="quantityLimitMinByDefault" />
							</div>
						);
					}
				},
				{
					id: 10,
					field: 'singular',
					label: 'Единичная',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatCheckbox object={data} property="singular" />
							</div>
						);
					}
				}
			],
			periodItemsColumns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input type="checkbox" id="checkbox" onChange={() => this.props.saveSelected(data)} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'validityPeriod',
					label: 'Период действия',
					width: '450px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="validityPeriodId"
									relation="validityPeriod"
									placeholder="Выберите период"
									type="text"
									size="sm"
									formatter={relationFormatter()}
									pluralName="enumerationItems"
									onlySelect={true}
									filterWhere={{ ownerId: 'periodicity' }}
									autoComplete="off"
									mobile
									filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'validityPeriodCountLimitRange',
					label: 'Ограничение срока',
					width: '450px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<string> object={data} verticalSwitching size="sm" property="validityPeriodCountLimitRange" placeholder="Введите ограничение" type="text" formatter={stringFormatter()} autoComplete="off" mobile />
							</div>
						);
					}
				}
			],
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input
									type="checkbox"
									id="checkbox"
									onChange={() => {
										this.props.childrenProps.saveSelected(data);
									}}
								/>
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 ml-2 font__size__16">{catalog.renderCatalogName(data.product)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatRelationInput
										object={data}
										property="productId"
										relation="product"
										placeholder="Выберите товар"
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="products"
										filterWhere={{
											deleted: false,
											elementType: 0,
											typeId: {
												nin: ['product_types.set']
											}
										}}
										include={['unit', 'validityPeriod']}
										autoComplete="off"
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</div>
							);
						}
					}
				},
				{
					id: 2,
					field: 'option',
					label: 'Опция',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkSubscriptionOptionCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 ml-2 font__size__16">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription_option') {
												return catalog.renderCatalogName(data.option);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription_option') {
												return (
													<NatRelationInput
														object={data}
														property="optionId"
														relation="option"
														placeholder="Выберите опцию"
														type="text"
														size="sm"
														inputClassName="text-left"
														formatter={relationFormatter()}
														pluralName="subscriptionOptions"
														filterWhere={{ deleted: false, elementType: 0 }}
														autoComplete="off"
														goTo={this.props.goTo}
													/>
												);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 3,
					field: 'productSetId',
					label: 'Набор',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkProductSetCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId) ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
					}
				},
				{
					id: 4,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 ml-2 font__size__16">{catalog.renderCatalogName(data.unit)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatRelationInput
										object={data}
										property="unitId"
										relation="unit"
										placeholder="Выберите ед.изм."
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="productUnits"
										apiMethod={(): any => {
											if (data.product !== undefined) {
												return controller.fetchUnitsByProduct({ product: data.product });
											} else {
												return new Promise((resolve) => {
													resolve([]);
												});
											}
										}}
										autoComplete="off"
										goTo={this.props.goTo}
									/>
								</div>
							);
						}
					}
				},
				{
					id: 5,
					field: 'feature',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 ml-2 font__size__16">
									{(() => {
										if (data.product !== undefined) {
											if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
												return null;
											} else {
												return catalog.renderCatalogName(data.feature);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
												return null;
											} else {
												return (
													<NatRelationInput
														object={data}
														property="featureId"
														relation="feature"
														placeholder="Выберите характеристику"
														type="text"
														size="sm"
														inputClassName="text-left"
														formatter={relationFormatter()}
														pluralName="productFeatures"
														autoComplete="off"
														apiMethod={(): any => {
															return controller.fetchFeaturesByProduct({ product: data.product });
														}}
														create={(pluralName, handlers) => {
															this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
														}}
													/>
												);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 7,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{quantityFormatter.renderValue(data.quantity)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="quantity"
										placeholder="Введите кол-во"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={quantityFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
									/>
								</div>
							);
						}
					}
				},
				{
					id: 6,
					field: 'validityPeriod',
					label: 'Период действия',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 ml-2 font__size__16">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return catalog.renderCatalogName(data.validityPeriod);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return (
													<NatRelationInput
														object={data}
														property="validityPeriodId"
														relation="validityPeriod"
														placeholder="Выберите период"
														type="text"
														size="sm"
														formatter={relationFormatter()}
														pluralName="enumerationItems"
														onlySelect={true}
														filterWhere={{ ownerId: 'periodicity' }}
														autoComplete="off"
														mobile
														filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
													/>
												);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 8,
					field: 'readOnly',
					label: 'Только чтение',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner">{data.readOnly ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatCheckbox object={data} property="readOnly" />
								</div>
							);
						}
					}
				},
				{
					id: 9,
					field: 'quantityLimitMinByDefault',
					label: 'Ограничить минимальное количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner">{data.quantityLimitMinByDefault ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatCheckbox object={data} property="quantityLimitMinByDefault" />
								</div>
							);
						}
					}
				},
				{
					id: 10,
					field: 'singular',
					label: 'Единичная',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner">{data.singular ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatCheckbox object={data} property="singular" />
								</div>
							);
						}
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleNatSelection(index: number) {
		const position = this.state.modals.indexOf(index);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			modals: newDetails
		});
		if (!this.state.modals.includes(index)) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	render() {
		let periodItemTemplate = {
			validityPeriodId: 'periodicity.month',
			validityPeriod: {
				id: 'periodicity.month',
				keyId: 'month',
				ownerId: 'periodicity',
				name: { ru: 'Месяц' },
				description: null,
				priority: 6,
				predefined: true,
				predefinedName: 'PeriodicityMonth',
				predefinedVersion: 0
			},
			id: '',
			validityPeriodCountLimitRange: ''
		};
		let productItemTemplate = {
			featureId: null,
			productId: null,
			optionId: null,
			quantity: 1,
			unitId: null,
			id: '',
			productSetId: null,
			productSetFeatureId: null,
			quantityLimitMinByDefault: false,
			singular: false,
			readOnly: false,
			validityPeriodId: '',
			option: {
				tag: '',
				typeId: '',
				meaningId: null,
				valueAtZero: null,
				description: null,
				name: { ru: '' },
				displayName: null,
				priority: 0,
				deleted: false,
				defaultName: null,
				parentId: null,
				elementType: 0
			},
			validityPeriod: {
				description: null,
				keyId: '',
				name: { ru: '' },
				ownerId: '',
				predefined: true,
				predefinedName: '',
				predefinedVersion: 0
			},
			feature: {
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			},
			product: {
				accountingOptionId: 'product_accounting_options.standart',
				area: 0,
				areaUnitId: null,
				article: null,
				caption: null,
				deleted: false,
				description: null,
				displayName: null,
				elementType: 0,
				giftCardValidityExpiredAt: null,
				giftCardValidityPeriodCount: 0,
				giftCardValidityPeriodId: 'periodicity.year',
				giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
				giftCardValue: 0,
				height: 0,
				heightUnitId: null,
				kindId: null,
				labelTemplateId: null,
				length: 0,
				lengthUnitId: null,
				name: { ru: '' },
				organizationId: null,
				packageSetId: null,
				parentId: null,
				primaryImageId: null,
				priority: null,
				supplierId: null,
				typeId: 'product_types.goods',
				unitId: null,
				useArea: false,
				useCustomLabelTemplate: false,
				useFeatureOptionId: 'use_feature_options.not_used',
				useGiftCardPartially: false,
				useHeight: false,
				useLength: false,
				usePackageOptionId: 'use_package_options.not_used',
				useSerialNumbers: false,
				useVolume: false,
				useWeight: false,
				useWidth: false,
				volume: 0,
				volumeUnitId: null,
				weight: 0,
				weightCanManuallySet: false,
				weightUnitId: null,
				width: 0,
				widthUnitId: null
			},
			unit: {
				baseUnitId: null,
				deleted: false,
				displayName: null,
				elementType: 0,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				packageTypeId: 'unit_package_types.final',
				parentId: null,
				priority: null,
				quantityOfOwnerUnitIncludedInPackage: 0,
				quantityOfPackageIncludedInOwnerUnit: 1,
				quantityOfParentPackageIncludedInPackage: 0,
				typeId: null
			}
		};
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.subscriptionPlanFormStore}
				navTabs={[{ name: 'Товары' }, { name: 'Период' }, { name: 'Дополнительно' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				productSelection
				dontDisplayStocksAndPrices
				notRecalculateAmount
				productItemTemplate={productItemTemplate}
				selectionColumns={this.state.columns}
				isRevealCompositionOfSet
				productSetEditing
				isProductSetEditingModalOpen={this.props.childrenProps.isProductSetEditingModalOpen}
				toggleSetEditing={this.props.childrenProps.toggleNatSetEditing}
				setManagerColumns={this.state.setManagerColumns}
				withoutPrices
				tabsContent={(toggleSelection: (status: boolean) => void) => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(productItemTemplate);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.subscriptionPlanFormStore.selectedItems);
										},
										withoutIcon: true,
										disabled: this.props.subscriptionPlanFormStore.selectedItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											toggleSelection(true);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.subscriptionPlanFormStore.selectedItems, this.props.subscriptionPlanFormStore.model.productItems as Array<any>, -1);
										},
										withoutIcon: true,
										disabled: this.props.subscriptionPlanFormStore.selectedItems.length === 0
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.subscriptionPlanFormStore.selectedItems, this.props.subscriptionPlanFormStore.model.productItems as Array<any>, 1);
										},
										withoutIcon: true,
										disabled: this.props.subscriptionPlanFormStore.selectedItems.length === 0
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.subscriptionPlanFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.checkColumnVisibility(catalog.convertCellMethods(this.state.columns), _.get(this.props.subscriptionPlanFormStore.model, 'productItems', []))}
										rows={this.props.subscriptionPlanFormStore.model.productItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										onRowClick={({ data, column }: { data: any; column: any }) => {
											if (column.id !== 'checkbox') {
												if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
													this.props.subscriptionPlanFormStore.setValue(this.props.subscriptionPlanFormStore, 'productItem', data);
													this.props.childrenProps.toggleNatSetEditing(true);
												}
											}
										}}
										showSearch={false}
										searchText={this.props.subscriptionPlanFormStore.searchText}
									/>
								</form>
							</div>
						</>,
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											let arr: any = _.cloneDeep(this.props.subscriptionPlanFormStore.model.periodItems);
											let periodItem: any = _.cloneDeep(periodItemTemplate);
											periodItem.id = generateRandomId();
											arr.push(periodItem);
											this.props.subscriptionPlanFormStore.setValue(this.props.subscriptionPlanFormStore.model, 'periodItems', arr);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.deletePeriodItems(this.props.subscriptionPlanFormStore.selectedPeriodItems);
										},
										withoutIcon: true,
										disabled: this.props.subscriptionPlanFormStore.selectedPeriodItems.length === 0
									}
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods(this.state.periodItemsColumns)}
										rows={this.props.subscriptionPlanFormStore.model.periodItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.subscriptionPlanFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.subscriptionPlanFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.subscriptionPlanFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.subscriptionPlanFormStore.model}
										property="description"
										placeholder="Введите описание"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										autoComplete="off"
										label="Описание"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.subscriptionPlanFormStore.model}
										property="applyLimitValidityPeriodId"
										relation="applyLimitValidityPeriod"
										placeholder="Выберите период"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'periodicity' }}
										autoComplete="off"
										label="Период действия ограничения"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<number>
										object={this.props.subscriptionPlanFormStore.model}
										property="level"
										placeholder="Введите уровень"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										formatter={numberFormatter}
										size="sm"
										label="Уровень подписки"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatCheckbox object={this.props.subscriptionPlanFormStore.model} property="enabled" label="Используется" />
								</CCol>
								<CCol md="4">
									<NatCheckbox object={this.props.subscriptionPlanFormStore.model} property="renewable" label="Возобновляемая" />
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatCheckbox object={this.props.subscriptionPlanFormStore.model} property="trial" label="Пробный" />
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.subscriptionPlanFormStore.setValue(this.props.subscriptionPlanFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.subscriptionPlanFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.subscriptionPlanFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.subscriptionPlanFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(SubscriptionPlanForm));
