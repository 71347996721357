import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyPolicyRangeTypeByProduct } from '../../shared/support/modelTypes';

export interface FilterType {
	mode: string;
	type: string;
}

export interface NatLoyaltyPolicyRangeByProductManagerStoreType {
	date: Date;
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterOrder: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	isLoading: boolean;
	startedAt: Date;
	expiredAt: Date | null;
	note: string | null;
	loyaltyPolicyRangesByProduct: Array<LoyaltyPolicyRangeTypeByProduct>;
	status: string;
	mode: string;
	setValue(model: any, key: string, value: any): void;
}

class NatLoyaltyPolicyRangeByProductManagerStore implements NatLoyaltyPolicyRangeByProductManagerStoreType {
	constructor() {
		makeObservable(this, {
			date: observable,
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterOrder: observable,
			pluralName: observable,
			collectedModels: observable,
			isLoading: observable,
			startedAt: observable,
			expiredAt: observable,
			note: observable,
			loyaltyPolicyRangesByProduct: observable,
			status: observable,
			mode: observable,
			setValue: action
		});
	}
	date = new Date();
	startedAt = new Date();
	expiredAt = null;
	note = null;
	list = [];
	loyaltyPolicyRangesByProduct = [];
	filterWhere = {};
	filterInclude = ['product', 'feature'];
	filterOrder = ['createdAt DESC'];
	pluralName = 'loyaltyPolicyRangeByProducts';
	collectedModels = [] as Array<any>;
	isLoading = false;
	status = 'enabled';
	mode = 'all';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natLoyaltyPolicyRangeByProductManagerStore = new NatLoyaltyPolicyRangeByProductManagerStore();

export default NatLoyaltyPolicyRangeByProductManagerStore;
