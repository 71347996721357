import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, OutcomeCashOrderType } from '../../shared/support/modelTypes';
import OutcomeCashOrderForm from './OutcomeCashOrderForm';
import { OutcomeCashOrderFormStoreType } from './OutcomeCashOrderFormStore';

interface PropsType extends FormContainerPropsType<OutcomeCashOrderFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class OutcomeCashOrderFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.partnerId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							catalog.fetchApplicableContract(this.props.store);
						} else {
							this.props.store.model.contractId = null;
							delete this.props.store.model.contract;
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.organizationId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							if (!_.isEmpty(this.props.store.model.organization) && this.props.store.model.organization !== undefined) {
								this.props.store.model.organizationBankAccountId = this.props.store.model.organization.bankAccountId;
								this.props.store.model.organizationBankAccount = this.props.store.model.organization.bankAccount;
							}
						} else {
							this.props.store.model.organizationBankAccountId = null;
							delete this.props.store.model.organizationBankAccount;
						}
						this.props.store.model.cashRegisterId = null;
						delete this.props.store.model.cashRegister;
						this.props.store.model.cashBoxId = null;
						delete this.props.store.model.cashBox;
						if (!_.isEmpty(value)) {
							this.props.store.setValue(this.props.store, 'isLoading', true);
							controller
								.findAll('cashBoxs', {
									where: {
										deleted: false,
										ownerId: value,
										ownerType: 'OrmOrganization'
									}
								})
								.then((data) => {
									if (!_.isEmpty(data)) {
										if (data.length === 1) {
											this.props.store.model.cashBoxId = data[0].id;
											this.props.store.model.cashBox = data[0];
										}
									}
									this.props.store.setValue(this.props.store, 'isLoading', false);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.store.setValue(this.props.store, 'isLoading', false);
								});
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: OutcomeCashOrderType) {
		return model;
	}
	prepareModelForPost(model: OutcomeCashOrderType) {
		delete model.organization;
		delete model.partner;
		delete model.contract;
		delete model.organizationBankAccount;
		delete model.chiefAccountant;
		delete model.cashRegister;
		delete model.cashBox;
		delete model.accountablePerson;
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.partnerId) && !_.isEmpty(this.props.store.model.organizationId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				isDocument
				parentRoute="/outcomeCashOrders/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<OutcomeCashOrderForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						outcomeCashOrdersFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default OutcomeCashOrderFormContainer;
