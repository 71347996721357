import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormGroup, CInput, CLabel } from '@coreui/react';
import _ from 'lodash';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import AutocompleteAdapter from '../Adapters/ValueAutocomplete/AutocompleteAdapter';
import ValueFormatter from '../Formatters/ValueFormatter';
import { catalog } from '../support/Catalog';
import NatLocalizedValueInputStore, { NatLocalizedValueInputStoreType } from './InputsStore/NatLocalizedValueInputStore';
import NatInputGroup from './NatInputGroup';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	inputColumnClassName?: string;
	inputClassName?: string;
	type: string;
	invalid?: boolean;
	placeholder: string;
	pattern?: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	useHandleEnter?: boolean;
	verticalSwitching?: boolean;
	label?: string;
	mobile?: boolean;
	adapter?: AutocompleteAdapter<any, string>;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatLocalizedValueInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	id: string;
	natLocalizedValueInputStore: NatLocalizedValueInputStoreType;
	constructor(props: PropsType<T>) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.natLocalizedValueInputStore = new NatLocalizedValueInputStore();
		this.id = generateRandomId();
		this.reactions.push(
			reaction(
				() => this.natLocalizedValueInputStore.language,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	handleClickOutside() {
		if (this.props.adapter) {
			this.toggleDropdown(false);
		}
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if ((this.props.pattern && e.target.value.match(this.props.pattern) != null) || !this.props.pattern) {
			this.setState({
				value: e.target.value
			});
		}
	}
	handleFocus(event: any) {
		event.target.select();
	}
	handleEnter(event: any) {
		if (event.keyCode === 13) {
			let form = event.target.form;
			if (form && form.length > 0) {
				let index = Array.prototype.indexOf.call(form, event.target);
				let length = form.length;
				let nextElement = null;
				for (index; index < length; index++) {
					let nextElement = _.get(form, `elements[${index + 1}]`, null);
					let condition: any = null;
					if (!this.props.verticalSwitching) {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox';
					} else {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox' && nextElement.name === this.props.property;
					}
					if (condition) {
						nextElement.focus();
						break;
					} else {
						nextElement = null;
					}
				}
				if (!nextElement) {
					event.target.blur();
				}
				event.preventDefault();
			}
		}
	}
	parseValue(value: string): T | undefined | null {
		let language: string = _.get(this.natLocalizedValueInputStore, 'language', 'ru');
		return this.props.formatter.parseValue(value, this.props.object[this.props.property], language);
	}

	renderValue(value: T | undefined | null): string {
		let language: string = _.get(this.natLocalizedValueInputStore, 'language', 'ru');
		return this.props.formatter.renderValue(value, language);
	}
	render() {
		return (
			<CDropdown className="w-100 nat__dropdown">
				<NatInputGroup mobile={this.props.mobile}>
					{(childrenProps) => (
						<CFormGroup row className={this.injected.directoryStore.models.windowSize < 490 ? 'w-100 align-items-center nat__form__group flex-nowrap' : 'w-100 align-items-center nat__form__group'}>
							{!_.isEmpty(this.props.label) && (
								<CLabel
									htmlFor={this.id}
									className={(() => {
										if (this.props.label) {
											if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
												return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile_100';
											} else {
												return 'align-self-center text-truncate pl-3 m-0';
											}
										}
									})()}>
									{this.props.label}
								</CLabel>
							)}
							<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0 d-flex' : 'align-self-center pr-0 pl-0 d-flex'}>
								<CInput
									id={this.id}
									invalid={(() => {
										if (this.props.invalid) {
											if (this.props.object[this.props.property] !== null && this.props.object[this.props.property] !== undefined) {
												return _.isEmpty(this.props.object[this.props.property][_.get(this.natLocalizedValueInputStore, 'language', 'ru')]);
											} else {
												return true;
											}
										} else {
											return false;
										}
									})()}
									type={this.props.type}
									name={this.props.property}
									placeholder={this.props.placeholder}
									onChange={this.handleChange}
									onFocus={this.handleFocus}
									value={this.state.value}
									pattern={this.props.pattern}
									className={this.props.inputClassName}
									readOnly={this.props.readonly}
									disabled={this.props.disabled}
									size={this.props.size}
									autoComplete={this.props.autoComplete}
									onClick={(e) => {
										e.stopPropagation();
										if (this.props.adapter) {
											this.toggleDropdown(true);
											this.props.adapter
												.fetch(this.props.object)
												.then((data) => {
													if (!_.isEmpty(data)) {
														this.setState({
															autocompleteItems: data
														});
													}
												})
												.catch((error) => {
													catalog.handleNatError(error);
												});
										}
									}}
									onBlur={() => {
										let value: T | undefined | null = this.parseValue(this.state.value);
										this.setValue(this.parseValue(this.state.value));
										this.setState({
											value: this.renderValue(value)
										});
									}}
									onKeyDown={_.get(this.props, 'useHandleEnter', true) ? this.handleEnter : () => {}}
								/>
								{!this.props.disabled && childrenProps.isButtonShow && (
									<div
										className={(() => {
											if (this.props.mobile) {
												if (this.injected.directoryStore.models.windowSize > 766) {
													return 'nat__input__buttons';
												} else {
													return 'nat__input__buttons nat__input__buttons__mobile';
												}
											} else {
												return 'nat__input__buttons';
											}
										})()}>
										{_.map(this.props.formatter.getActionButtons(this.natLocalizedValueInputStore), (item, index) => {
											if (item.children) {
												return (
													<CDropdown className="" key={index}>
														<CDropdownToggle title={item.title} className="nat__input__button nat__dropdown__toggle" caret={false}>
															<span className="text-dark c-icon c-icon-sm d-flex align-items-center justify-content-center">{item.text}</span>
														</CDropdownToggle>
														<CDropdownMenu>
															{_.map(item.children, (item, index) => {
																return (
																	<CDropdownItem
																		key={index}
																		onClick={() => {
																			item.onClick();
																		}}>
																		{item.text}
																	</CDropdownItem>
																);
															})}
														</CDropdownMenu>
													</CDropdown>
												);
											} else {
												if (!_.isEmpty(item.icon)) {
													return (
														<CButton
															key={index}
															type="button"
															className={item.className ? item.className : 'nat__input__button'}
															title={item.title}
															onClick={() => {
																item.onClick(this.props.object, this.props.property, this.validate);
															}}>
															<CIcon name={item.icon} className="text-dark" size="sm" />
														</CButton>
													);
												} else {
													return (
														<CButton
															key={index}
															type="button"
															className={item.className ? item.className : 'nat__input__button'}
															title={item.title}
															onClick={() => {
																item.onClick(this.props.object, this.props.property, this.validate);
															}}>
															<span className="text-dark c-icon c-icon-sm d-flex align-items-center justify-content-center">{item.text}</span>
														</CButton>
													);
												}
											}
										})}
									</div>
								)}
							</CCol>
						</CFormGroup>
					)}
				</NatInputGroup>
				{!_.isEmpty(this.state.autocompleteItems) && (
					<CDropdownMenu className="rounded-0 py-0" show={this.state.showDropdown}>
						<div className="nat__dropdown__list mw-100 nat__dropdown__white__scrollbar">
							{_.map(this.state.autocompleteItems, (item: string, index) => {
								return (
									<CDropdownItem
										key={index}
										onClick={(e) => {
											if (item) {
												this.setValue(this.parseValue(item));
												this.toggleDropdown(false);
											}
											e.stopPropagation();
										}}>
										{item}
									</CDropdownItem>
								);
							})}
						</div>
					</CDropdownMenu>
				)}
			</CDropdown>
		);
	}
}

export default enhanceWithClickOutside(NatLocalizedValueInput);
