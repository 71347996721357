import ValueFormatter from './ValueFormatter';

class DateFormatter extends ValueFormatter<Date, string> {
	public parseValue(value: string): Date | undefined | null {
		let result: Date | undefined | null = undefined;
		let dateRegex = /^\d{4}-\d\d-\d\d$/;
		if (dateRegex.test(value)) {
			result = new Date(value);
		} else {
			result = null;
		}
		return result;
	}

	public renderValue(value: Date): string {
		if (value !== undefined && value !== null) {
			return new Date(value).toISOString().substring(0, 10);
		}
		return '';
	}
}

export let dateFormatter = new DateFormatter();

export default DateFormatter;
