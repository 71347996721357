import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { dateFormatter } from '../Formatters/DateFormatter';
import { numberFormatter } from '../Formatters/NumberFormatter';
import { relationFormatter } from '../Formatters/RelationFormatter';
import { stringFormatter } from '../Formatters/StringFormatter';
import { models } from '../support/NatModels';
import NatCheckbox from './NatCheckBox';
import NatChipsInput from './NatChipsInput';
import NatRelationInput from './NatRelationInput';
import NatValueInput from './NatValueInput';

interface PropsType {
	goTo?: (path: string, state?: Record<string, unknown>, handlers?: Record<string, unknown>) => void;
	plurals?: any;
	object: any;
	inputClassName?: string;
	invalid?: boolean;
	pattern?: string;
	size: string;
	row: boolean;
	label: string;
	property: string;
	relation?: string;
	disabled?: boolean;
	validation?: any;
}

interface StateType {
	columns: Array<any>;
	querySelector: any;
	isDeleteModalOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class NatPolymorphicInput extends Component<PropsType, StateType> {
	myRef: any;
	inputs: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.reactions = [];
		this.selectInputComponent = this.selectInputComponent.bind(this);
		this.inputs = {
			relation: (polymorphic: any) => {
				return (
					<NatRelationInput
						object={polymorphic}
						property={this.props.property}
						relation={this.props.relation ? this.props.relation : 'value'}
						placeholder="Выберите из списка"
						type="text"
						size={this.props.size}
						formatter={relationFormatter()}
						inputClassName={this.props.inputClassName}
						pluralName={models[polymorphic.valueRelationType].plural}
						filterWhere={{ deleted: false }}
						autoComplete="off"
						label={this.props.label}
						disabled={this.props.disabled}
						validation={this.props.validation}
						goTo={this.props.goTo}
					/>
				);
			},
			boolean: (polymorphic: any) => {
				return <NatCheckbox object={polymorphic} property={this.props.property} label={this.props.label} disabled={this.props.disabled} />;
			},
			number: (polymorphic: any) => {
				return (
					<NatValueInput<number>
						object={polymorphic}
						property={this.props.property}
						inputClassName={this.props.inputClassName}
						placeholder="Введите число"
						type="text"
						pattern="^([0-9]*[.,])?[0-9]*$"
						formatter={numberFormatter}
						size={this.props.size}
						autoComplete="off"
						label={this.props.label}
						disabled={this.props.disabled}
						validation={this.props.validation}
					/>
				);
			},
			string: (polymorphic: any) => {
				return (
					<NatValueInput<string>
						object={polymorphic}
						property={this.props.property}
						inputClassName={this.props.inputClassName}
						placeholder="Введите строку"
						type="text"
						formatter={stringFormatter()}
						size={this.props.size}
						autoComplete="off"
						label={this.props.label}
						disabled={this.props.disabled}
						validation={this.props.validation}
					/>
				);
			},
			array: (polymorphic: any) => {
				return (
					<NatChipsInput<string>
						object={polymorphic}
						property={this.props.property}
						inputClassName={this.props.inputClassName}
						placeholder="Введите строку"
						type="text"
						formatter={stringFormatter()}
						size={this.props.size}
						autoComplete="off"
						label={this.props.label}
						disabled={this.props.disabled}
						validation={this.props.validation}
					/>
				);
			},
			date: (polymorphic: any) => {
				return (
					<NatValueInput<Date>
						object={polymorphic}
						property={this.props.property}
						inputClassName={this.props.inputClassName}
						placeholder="Введите дату"
						type="date"
						formatter={dateFormatter}
						size={this.props.size}
						label={this.props.label}
						disabled={this.props.disabled}
						validation={this.props.validation}
					/>
				);
			}
		};
		this.reactions.push(
			reaction(
				() => this.props.object.valueId,
				(value, previousValue) => {
					if (this.props.object.valueType === 'relation' || this.props.object.valueType === 'value_types.relation') {
						if (value !== previousValue) {
							this.props.object.valueRelationValue = value;
						}
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	selectInputComponent(polymorphic: any) {
		let types = {
			string: 'string',
			number: 'number',
			array: 'array',
			relation: 'relation',
			boolean: 'boolean',
			date: 'date',
			'value_types.array': 'array',
			'value_types.boolean': 'boolean',
			'value_types.date': 'date',
			'value_types.number': 'number',
			'value_types.relation': 'relation',
			'value_types.string': 'string'
		};
		if (this.inputs[types[polymorphic.valueType]]) {
			return this.inputs[types[polymorphic.valueType]](polymorphic);
		}
	}
	render() {
		return this.selectInputComponent(this.props.object);
	}
}

export default NatPolymorphicInput;
