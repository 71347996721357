import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CModal, CModalHeader, CRow, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../Components/NatControlPanel';
import { priceFormatter } from '../Formatters/PriceFormatter';
import { quantityFormatter } from '../Formatters/QuantityFormatter';
import NatValueInput from '../Inputs/NatValueInput';
import { catalog } from '../support/Catalog';
import NatSetEditingManagerStore, { NatSetEditingManagerStoreType } from './NatSetEditingManagerStore';
import ProductSelection from './ProductSelection';

interface PropsType extends RouteComponentProps {
	toggleNatSetEditingManager(status: boolean): void;
	isModalOpen: boolean;
	productItemTemplate: any;
	modelStore: any;
	productItemsColumns: Array<any>;
	productItem: any;
	withoutPrices?: boolean;
	dontDisplayStocksAndPrices?: boolean;
	notRecalculateAmount?: boolean;
	makeReactions?: () => void;
	typeFilter?: { nin?: Array<string>; inq?: Array<string> };
	formValidProductTypes?: boolean;
}

interface StateType {
	querySelector: any;
	columns: Array<any>;
	isProductSelectionOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatSetEditingManager extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	natSetEditingManagerStore: NatSetEditingManagerStoreType;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.collectModels = this.collectModels.bind(this);
		this.deleteProductItems = this.deleteProductItems.bind(this);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.saveProductItems = this.saveProductItems.bind(this);
		this.getData = this.getData.bind(this);
		this.recalculateQuantity = this.recalculateQuantity.bind(this);
		this.moveToModelProductItems = this.moveToModelProductItems.bind(this);
		this.resetReactions = this.resetReactions.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.natSetEditingManagerStore = new NatSetEditingManagerStore();
		this.state = {
			querySelector: null,
			isProductSelectionOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div className="rgt-header-inner"></div>;
					},
					cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} />
							</div>
						);
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetReactions();
		this.reactions.push(
			reaction(
				() => this.natSetEditingManagerStore.productItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							if (!this.props.withoutPrices) {
								let productItemsGrouped = _.chain(this.natSetEditingManagerStore.productItems).groupBy('productId').value();
								if (productItemsGrouped['null'] === undefined) {
									this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', true);
									controller
										.updateProductItemListPrices(this.natSetEditingManagerStore.productItems)
										.then((data) => {
											if (!_.isEmpty(data)) {
												this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', data);
												catalog.recalculateList(this.natSetEditingManagerStore.productItems);
												this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'price', catalog.recalculateAmount(this.natSetEditingManagerStore.productItems));
												this.makeReactions();
											}
											this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
										});
								}
							}
						}
					}
				}
			)
		);
		_.forEach(this.natSetEditingManagerStore.productItems, (item) => {
			_.forIn(item, (value, key) => {
				this.reactions.push(
					reaction(
						() => item[key],
						() => {
							if (key === 'quantity' || key === 'price' || key === 'manualDiscountPercent') {
								catalog.recalculateList(this.natSetEditingManagerStore.productItems);
							}
						}
					)
				);
			});
			this.reactions.push(
				reaction(
					() => item.validityPeriodId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									if (item.product.typeId === 'product_types.subscription' || item.product.typeId === 'product_types.subscription_option') {
										item.validityPeriodId = 'periodicity.month';
										item.validityPeriod = {
											id: 'periodicity.month',
											keyId: 'month',
											ownerId: 'periodicity',
											name: { ru: 'Месяц' },
											description: null,
											priority: 6,
											predefined: true,
											predefinedName: 'PeriodicityMonth',
											predefinedVersion: 0
										};
									}
								}
							} else {
								if (!this.props.withoutPrices) {
									this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', true);
									controller
										.updateProductItemListPrices([item])
										.then((data) => {
											if (!_.isEmpty(data)) {
												runInAction(() => {
													item.price = data[0].price;
												});
											}
											this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
										});
								}
							}
						}
					}
				)
			);
			if (item.validityPeriodCount !== undefined) {
				this.reactions.push(
					reaction(
						() => item.validityPeriodCount,
						(value, previousValue) => {
							if (value !== previousValue) {
								catalog.recalculateList(this.natSetEditingManagerStore.productItems);
							}
						}
					)
				);
			}

			this.reactions.push(
				reaction(
					() => item.featureId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (!this.props.withoutPrices) {
								this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', true);
								controller
									.updateProductItemListPrices([item])
									.then((data) => {
										if (!_.isEmpty(data)) {
											runInAction(() => {
												item.price = data[0].price;
											});
										}
										this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
									});
							}
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.serialNumberId = null;
							if (item.validityPeriodCount !== undefined) {
								item.validityPeriodCount = 0;
							}
							delete item.feature;
							delete item.serialNumber;
							if (!_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									if (item.validityPeriodId !== item.product.validityPeriodId) {
										item.validityPeriodId = item.product.validityPeriodId;
									} else {
										if (!this.props.withoutPrices) {
											this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', true);
											controller
												.updateProductItemListPrices(this.natSetEditingManagerStore.productItems)
												.then((data) => {
													if (!_.isEmpty(data)) {
														this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', data);
														catalog.recalculateList(this.natSetEditingManagerStore.productItems);
														this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'price', catalog.recalculateAmount(this.natSetEditingManagerStore.productItems));
														this.makeReactions();
													}
													this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
												})
												.catch((error) => {
													catalog.handleNatError(error);
													this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
												});
										}
									}
									if (!_.isEmpty(item.product.validityPeriod)) {
										item.validityPeriod = item.product.validityPeriod;
									}
									if (!_.isEmpty(item.product.unit)) {
										item.unit = item.product.unit;
									}
									item.unitId = item.product.unitId;
								}
							} else {
								item.unitId = null;
								if (item.validityPeriodId !== undefined) {
									item.validityPeriodId = null;
									delete item.validityPeriod;
								}
								if (item.optionId !== undefined) {
									item.optionId = null;
									delete item.option;
								}
								delete item.product;
								delete item.unit;
								this.rebuild(this.natSetEditingManagerStore.productItems);
							}
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.amount,
					(value, previousValue) => {
						if (item.amount !== previousValue) {
							this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'price', catalog.recalculateAmount(this.natSetEditingManagerStore.productItems));
						}
					}
				)
			);
		});
	}
	componentDidMount() {
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'filterWhere', { productSetId: this.props.productItem.productSetId });
		this.getData();
	}
	getData() {
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', true);
		let groupedProductItems = _.chain(this.props.modelStore.model.productItems).groupBy('productSetId').value();
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', _.cloneDeep(groupedProductItems[this.props.productItem.productSetId]));
		if (!this.props.withoutPrices) {
			this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'price', catalog.recalculateAmount(this.natSetEditingManagerStore.productItems));
		}
		this.makeReactions();
		let productSetMappingFilter = {
			where: this.natSetEditingManagerStore.filterWhere,
			include: this.natSetEditingManagerStore.filterInclude
		};
		let productSetFilter = {
			where: { id: this.props.productItem.productSetId }
		};
		controller
			.findAll(this.natSetEditingManagerStore.pluralName, productSetMappingFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					let productSetMappingsMap = _.chain(data).keyBy('productId').value();
					for (let item of this.natSetEditingManagerStore.productItems) {
						if (!_.isEmpty(productSetMappingsMap[item.productId])) {
							item.productSetMapping = productSetMappingsMap[item.productId];
						}
					}
					this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productSetMappings', data);
				}
				return controller.findAll('products', productSetFilter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productSet', data);
				}
				this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	toggleSelection(status: boolean) {
		this.setState({
			isProductSelectionOpen: status
		});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.natSetEditingManagerStore.productItems).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.natSetEditingManagerStore.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.natSetEditingManagerStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.natSetEditingManagerStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'collectedModels', buffer);
	}
	deleteProductItems(selectedItems: Array<any>) {
		const selectedItemsMap = _.chain(selectedItems).cloneDeep().keyBy('id').value();
		let buffer: Array<any> = [];
		_.forEach(this.natSetEditingManagerStore.productItems, (item: any) => {
			if (selectedItemsMap[item.id] === undefined) {
				buffer.push(item);
			}
		});
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'collectedModels', []);
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', buffer);
		this.makeReactions();
	}
	saveProductItems(productItems: Array<any>) {
		let items = _.cloneDeep(productItems);
		const arr = [] as Array<any>;
		const currentProductItemMap = _.chain(this.natSetEditingManagerStore.productItems).cloneDeep().keyBy('id').value();
		_.forEach(items, (item: any) => {
			if (currentProductItemMap[item.id] === undefined) {
				item.productSetId = this.props.productItem.productSetId;
				item.productSetFeatureId = this.props.productItem.productSetFeatureId;
				if (!_.isEmpty(this.props.productItem.productSet)) {
					item.productSet = this.props.productItem.productSet;
				}
				arr.push(item);
			}
		});
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', this.natSetEditingManagerStore.productItems.concat(arr));
		this.makeReactions();
	}
	moveToModelProductItems() {
		let groupedModelProductItems: any = _.chain(this.props.modelStore.model.productItems).groupBy('productSetId').value();
		let filteredModelProductItems: Array<any> = [];
		_.forIn(groupedModelProductItems, (item, key) => {
			if (key !== this.props.productItem.productSetId) {
				filteredModelProductItems = filteredModelProductItems.concat(item);
			}
		});
		for (let item of this.natSetEditingManagerStore.productItems) {
			delete item.productSetMapping;
		}
		filteredModelProductItems = filteredModelProductItems.concat(this.recalculateQuantity(this.natSetEditingManagerStore.productItems));
		this.props.modelStore.setValue(this.props.modelStore.model, 'productItems', filteredModelProductItems);
		this.props.modelStore.setValue(this.props.modelStore.model, 'amount', catalog.recalculateAmount(_.get(this.props.modelStore.model, 'productItems', [])));
		if (this.props.makeReactions !== undefined) {
			this.props.makeReactions();
		}
		this.props.toggleNatSetEditingManager(false);
	}
	recalculateQuantity(productItems: Array<any>) {
		let result: Array<any> = [];
		_.transform(
			productItems,
			(result: any, value: any) => {
				runInAction(() => {
					value.quantity = value.quantity * this.natSetEditingManagerStore.quantity;
				});
				result.push(value);
			},
			result
		);
		return result;
	}
	resetReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'selectedItems', []);
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', []);
		this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', items);
		this.makeReactions();
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ПЕРЕНЕСТИ',
				onClick: () => {
					this.moveToModelProductItems();
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatSetEditingManager(false);
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					<CForm className="mx-5 mb-3">
						<CRow>
							<CCol lg="12">
								<CFormGroup row className="mb-0">
									<CCol md="4">
										<NatValueInput<number>
											object={this.natSetEditingManagerStore}
											property="quantity"
											placeholder="Введите количество"
											type="text"
											pattern="^([0-9]*[.,])?[0-9]*$"
											formatter={quantityFormatter}
											size="sm"
											label="Количество наборов"
											autoComplete="off"
										/>
									</CCol>
									{!this.props.withoutPrices && (
										<CCol md="4">
											<NatValueInput<number>
												object={this.natSetEditingManagerStore}
												property="price"
												placeholder="Введите цену"
												type="text"
												pattern="^([0-9]*[.,])?[0-9]*$"
												formatter={priceFormatter}
												size="sm"
												disabled={true}
												label="Цена набора"
											/>
										</CCol>
									)}
								</CFormGroup>
							</CCol>
						</CRow>
					</CForm>
				</CModalHeader>
				<div className="d-flex flex-column default__nat__card__body__height p-0 mx-4 mb-4">
					<NatControlPanel
						className="nat__table__control__buttons nat__card__wrapper nat__borders__2-75 p-2 mb-4"
						buttonItemList={[
							{
								title: 'ДОБАВИТЬ',
								onClick: () => {
									let arr: any = _.cloneDeep(this.natSetEditingManagerStore.productItems);
									let item: any = _.cloneDeep(this.props.productItemTemplate);
									item.productSetId = this.props.productItem.productSetId;
									if (!_.isEmpty(this.props.productItem.productSet)) {
										item.productSet = this.props.productItem.productSet;
									}
									item.productSetFeatureId = this.props.productItem.productSetFeatureId;
									item.id = generateRandomId();
									arr.push(item);
									this.natSetEditingManagerStore.setValue(this.natSetEditingManagerStore, 'productItems', arr);
									this.makeReactions();
								},
								withoutIcon: true,
								disabled: false
							},
							{
								title: 'УДАЛИТЬ',
								onClick: () => {
									this.deleteProductItems(this.natSetEditingManagerStore.collectedModels);
								},
								withoutIcon: true,
								disabled: this.natSetEditingManagerStore.collectedModels.length === 0
							},
							{
								title: 'ПОДБОР',
								onClick: () => {
									this.toggleSelection(true);
								},
								withoutIcon: true,
								disabled: false
							}
						]}
					/>
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1">
							<GridTable
								columns={(() => {
									let arr = _.cloneDeep(this.state.columns);
									_.forEach(this.props.productItemsColumns, (item) => {
										if (item.id !== 'checkbox') {
											arr.push(item);
										}
									});
									return catalog.checkColumnVisibility(arr, this.natSetEditingManagerStore.productItems);
								})()}
								rows={this.natSetEditingManagerStore.productItems}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								selectedRowsIds={_.map(this.natSetEditingManagerStore.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => this.collectModels(selectedRowsIds)}
							/>
						</div>
					</div>
				</div>
				{this.natSetEditingManagerStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.state.isProductSelectionOpen && (
					<ProductSelection
						toggleFeedSelection={this.toggleSelection}
						isModalOpen={this.state.isProductSelectionOpen}
						productItemsColumns={(() => {
							let arr = _.cloneDeep(this.state.columns);
							_.forEach(this.props.productItemsColumns, (item) => {
								if (item.id !== 'checkbox') {
									arr.push(item);
								}
							});
							return catalog.checkColumnVisibility(arr, this.natSetEditingManagerStore.productItems);
						})()}
						modelStore={this.natSetEditingManagerStore}
						productItemTemplate={this.props.productItemTemplate}
						saveProductItems={this.saveProductItems}
						notRecalculateAmount={this.props.notRecalculateAmount}
						dontDisplayStocksAndPrices={this.props.dontDisplayStocksAndPrices}
						typeFilter={this.props.typeFilter}
						formValidProductTypes={this.props.formValidProductTypes}
					/>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatSetEditingManager);
