import CIcon from '@coreui/icons-react';
import { CButton, CCol, CForm, CFormGroup, CImg } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import product from '../../assets/product.jpg';
import { returnClientApiUrl } from '../../core/Api/instance';
import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../../shared/Alerts/AlertDialog';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatDropImageLoad from '../../shared/Components/NatDropImageLoad';
import NatFormList from '../../shared/Components/NatFormList';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { selectFormatter } from '../../shared/Formatters/SelectFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedTextarea from '../../shared/Inputs/NatLocalizedTextarea';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatStringSelectInput from '../../shared/Inputs/NatStringSelectInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import NatImageManager from '../../shared/Modals/NatImageManager';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import NatSetManager from '../shared/NatSetManager';
import { ProductFormStoreType } from './ProductFormStore';

interface PropsType extends FormPropsType {
	productFormStore: ProductFormStoreType;
	childrenProps: ChildrenPropsType;
	findInstances(pluralName: string, defaultWhere: any, store: any): void;
	pricesBulkUpsert(): void;
	addEmptyString(): void;
	buildFeaturePriceItems(data: any): void;
	buildPriceItems(prices: Array<any>): void;
	replace(newUrl: string, navigationRoute: any): void;
}

interface StateType {
	selectedImage: any;
	isNatImageManagerOpen: boolean;
	isNatSetManagerOpen: boolean;
	selectList: Array<any>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class ProductForm extends Component<PropsType, StateType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
		this.toggleNatImageManager = this.toggleNatImageManager.bind(this);
		this.toggleNatSetManager = this.toggleNatSetManager.bind(this);
		this.state = {
			selectedImage: null,
			isNatImageManagerOpen: false,
			isNatSetManagerOpen: false,
			selectList: [
				{
					name: 'ru',
					displayName: 'Русский'
				}
			]
		};
		this.myRef = React.createRef();
	}
	toggleNatImageManager(status: boolean) {
		this.setState({
			isNatImageManagerOpen: status
		});
	}
	toggleNatSetManager(status: boolean) {
		this.setState({
			isNatSetManagerOpen: status
		});
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<>
				<NatHeaderCollapseFormTemplate
					classNameRow={(() => {
						if (this.props.productFormStore.model.elementType === 0) {
							if (this.injected.directoryStore.models.windowSize < 1150) {
								return 'flex__column';
							}
						}
					})()}
					store={this.props.productFormStore}
					isAclModalOpen={this.props.childrenProps.isAclModalOpen}
					toggleAclModal={this.props.childrenProps.toggleAclModal}
					navTabs={(() => {
						if (this.props.productFormStore.model.elementType === 0) {
							return [
								{ name: 'Цены' },
								{ name: 'Штрихкоды' },
								{
									name: 'Серийные номера',
									hidden:
										(!_.isEmpty(this.injected.directoryStore.models.constants['AllowSerialNumbers']) && !this.injected.directoryStore.models.constants['AllowSerialNumbers'].valueId) || !this.props.productFormStore.model.useSerialNumbers
								},
								{ name: 'Характеристики', hidden: _.isEmpty(this.props.productFormStore.model.useFeatureOptionId) || this.props.productFormStore.model.useFeatureOptionId == 'use_feature_options.not_used' },
								{ name: 'Описание' },
								{ name: 'Дополнительно' }
							];
						} else {
							return [];
						}
					})()}
					tabsContent={() => {
						return [
							<>
								{!this.props.productFormStore.prices.isRoot && (
									<NatControlPanel
										buttonItemList={[
											{
												title: 'К ТИПАМ ЦЕН',
												onClick: () => {
													this.props.buildPriceItems(this.props.productFormStore.prices.list);
												},
												withoutIcon: true,
												disabled: false
											}
										]}
									/>
								)}
								<div className={!this.props.productFormStore.prices.isRoot ? 'nat__form__table__wrapper m-4 h-100' : 'nat__form__table__wrapper mx-4 mb-4 h-100'}>
									<form className="nat__form__table">
										<GridTable
											columns={catalog.checkColumnVisibility(
												[
													{
														id: 1,
														field: 'priceType',
														label: 'Тип цены',
														width: '385px',
														cellRenderer: ({ data }: { data: any }) => {
															return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data.priceType)}</div>;
														},
														sort: () => {}
													},
													{
														id: 2,
														field: 'unitId',
														label: 'Ед.изм.',
														width: '250px',
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner ml-3 p-0">
																	{(() => {
																		if (!this.props.productFormStore.prices.isRoot) {
																			return catalog.renderCatalogName(data.unit);
																		} else {
																			return (
																				<NatRelationInput
																					object={data}
																					property="unitId"
																					relation="unit"
																					placeholder="Выберите ед.изм."
																					type="text"
																					size="sm"
																					inputClassName="text-left"
																					formatter={relationFormatter()}
																					pluralName="productUnits"
																					apiMethod={(): any => {
																						return controller.fetchUnitsByProduct({ product: this.props.productFormStore.model });
																					}}
																					autoComplete="off"
																					goTo={this.props.goTo}
																				/>
																			);
																		}
																	})()}
																</div>
															);
														}
													},
													{
														id: 3,
														field: 'validityPeriodId',
														label: 'Срок',
														width: '250px',
														checkCondition: (item: any) => {
															return catalog.checkValidityPeriodsCondition(item);
														},
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner ml-3 p-0">
																	{(() => {
																		if (!this.props.productFormStore.prices.isRoot) {
																			if (this.props.productFormStore.model.typeId === 'product_types.subscription' || this.props.productFormStore.model.typeId === 'product_types.subscription_option') {
																				return catalog.renderCatalogName(data.validityPeriod);
																			}
																		} else {
																			if (this.props.productFormStore.model.typeId === 'product_types.subscription' || this.props.productFormStore.model.typeId === 'product_types.subscription_option') {
																				return (
																					<NatRelationInput
																						object={data}
																						property="validityPeriodId"
																						relation="validityPeriod"
																						placeholder="Выберите период"
																						type="text"
																						size="sm"
																						inputClassName="text-left"
																						formatter={relationFormatter()}
																						pluralName="enumerationItems"
																						onlySelect={true}
																						filterWhere={{ ownerId: 'periodicity' }}
																						filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																						autoComplete="off"
																					/>
																				);
																			} else {
																				return null;
																			}
																		}
																	})()}
																</div>
															);
														}
													},
													{
														id: 4,
														field: 'feature',
														label: 'Характеристика',
														visible: !_.isEmpty(this.props.productFormStore.model.useFeatureOptionId) && this.props.productFormStore.model.useFeatureOptionId !== 'use_feature_options.not_used',
														width: '385px',
														cellRenderer: ({ data }: { data: any }) => {
															if (!_.isEmpty(data.featureId)) {
																if (this.props.productFormStore.prices.isRoot) {
																	return (
																		<CButton type="button" size="sm" className="mr-1 nat__button__hover" onClick={() => this.props.buildFeaturePriceItems(data)}>
																			К ХАРАКТЕРИСТИКАМ
																		</CButton>
																	);
																} else {
																	return catalog.renderCatalogName(data.feature);
																}
															}
														},
														sort: () => {}
													},
													{
														id: 5,
														field: 'price',
														label: 'Цена',
														width: '385px',
														cellRenderer: ({ data }: { data: any }) => {
															return (
																<div className="rgt-cell-inner">
																	<NatValueInput<number>
																		object={data}
																		property="price"
																		placeholder="Введите кол-во"
																		type="text"
																		pattern="^([0-9]*[.,])?[0-9]*$"
																		inputClassName="text-right"
																		formatter={priceFormatter}
																		size="sm"
																		autoComplete="off"
																	/>
																</div>
															);
														},
														sort: () => {}
													}
												],
												this.props.productFormStore.prices.pricesTableList
											)}
											rows={this.props.productFormStore.prices.pricesTableList}
											isPaginated={false}
											texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
											showColumnVisibilityManager={false}
											showRowsInformation={false}
											onColumnsChange={() => {}}
											showSearch={false}
											isVirtualScroll={false}
										/>
									</form>
								</div>
							</>,
							<>
								<NatFormList
									setValue={this.props.productFormStore.setValue}
									data={this.props.productFormStore.productBarcodes}
									store={this.props.productFormStore}
									collectModels={this.props.childrenProps.collectModels}
									goTo={this.props.goTo}
									scrollable
									sortable
									className="nat__table__hover nat__form__table"
									columns={catalog.checkColumnVisibility(
										[
											{
												id: 'checkbox',
												pinned: true,
												className: '',
												width: '54px',
												minResizeWidth: 0,
												maxResizeWidth: null,
												resizable: false,
												visible: true,
												headerCellRenderer: () => {
													return <div></div>;
												},
												cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
													return (
														<div className="rgt-cell-inner ml-0 mr-0">
															<input type="checkbox" id="checkbox" onChange={onChange} />
														</div>
													);
												}
											},
											{
												id: 2,
												field: 'createdAt',
												label: 'Дата создания',
												width: '150px',
												editable: false,
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.createdAt)}
														</div>
													);
												}
											},
											{
												id: 3,
												field: 'barcode',
												label: 'Штрихкод',
												editorCellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner">
															<NatValueInput<string>
																object={data}
																property="barcode"
																placeholder="Введите штрихкод"
																inputClassName="text-left"
																type="text"
																formatter={stringFormatter()}
																size="sm"
																invalid={_.isEmpty(data.barcode)}
																invalidFeedback=""
																autoComplete="off"
															/>
														</div>
													);
												}
											},
											{
												id: 4,
												field: 'unit',
												label: 'Ед.изм.',
												width: '250px',
												cellRenderer: ({ data }: { data: any }) => {
													return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data.unit)}</div>;
												},
												editorCellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatRelationInput
																object={data}
																property="unitId"
																relation="unit"
																placeholder="Выберите ед.изм."
																type="text"
																size="sm"
																inputClassName="text-left"
																formatter={relationFormatter()}
																pluralName="productUnits"
																apiMethod={(): any => {
																	return controller.fetchUnitsByProduct({ product: this.props.productFormStore.model });
																}}
																onlySelect
																autoComplete="off"
																goTo={this.props.goTo}
															/>
														</div>
													);
												}
											},
											{
												id: 5,
												field: 'feature',
												label: 'Характеристика',
												width: '250px',
												checkCondition: (item: any) => {
													return catalog.checkFeaturesCondition(item);
												},
												cellRenderer: ({ data }: { data: any }) => {
													return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data.feature)}</div>;
												},
												editorCellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatRelationInput
																object={data}
																property="featureId"
																relation="feature"
																placeholder="Выберите характеристику"
																type="text"
																size="sm"
																inputClassName="text-left"
																formatter={relationFormatter()}
																pluralName="productFeatures"
																apiMethod={(): any => {
																	return controller.fetchFeaturesByProduct({ product: this.props.productFormStore.model });
																}}
																create={(pluralName, handlers) => {
																	this.props.goTo(`/${pluralName}/new`, { ownerId: this.props.productFormStore.model.id, ownerType: 'OrmProduct' }, handlers);
																}}
																autoComplete="off"
															/>
														</div>
													);
												}
											},
											{
												id: 'my-buttons-column',
												label: 'Действие',
												width: '150px',
												sortable: false,
												resizable: false,
												cellRenderer: ({ tableManager, data }) => (
													<div className="rgt-cell-inner text-center">
														<CButton
															className="editButton"
															onClick={() => {
																tableManager.rowEditApi.setEditRowId(data.id);
															}}>
															<CIcon name="cil-pencil" size="sm" className="align-self-center text-white p-0 m-1" />
														</CButton>
													</div>
												),
												editorCellRenderer: ({ tableManager, data }) => (
													<div className="rgt-cell-inner text-center">
														<div style={{ display: 'inline-flex' }}>
															<CButton
																className="cancelButton"
																onClick={() => {
																	tableManager.rowEditApi.setEditRowId(null);
																}}>
																<CIcon name="cil-x" size="sm" className="align-self-center text-white p-0 m-1" />
															</CButton>
															<CButton
																className="saveButton"
																onClick={() => {
																	if (!_.isEmpty(data.barcode)) {
																		this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', true);
																		let barcode = _.cloneDeep(data);
																		delete barcode.unit;
																		delete barcode.feature;
																		delete barcode.product;
																		controller
																			.bulkUpsert('productBarcodes', [barcode])
																			.then(() => {
																				this.props.findInstances('productBarcodes', { productId: this.props.productFormStore.model.id }, this.props.productFormStore);
																			})
																			.catch((error) => {
																				catalog.handleNatError(error);
																				this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
																			});
																		tableManager.rowEditApi.setEditRowId(null);
																	} else {
																		catalog.showAlert('Ошибка при сохранении элемента! Проверьте правильность заполнения полей!');
																	}
																}}>
																<CIcon name="cil-check-alt" size="sm" className="align-self-center text-white p-0 m-1" />
															</CButton>
														</div>
													</div>
												)
											}
										],
										this.props.productFormStore.productBarcodes.list
									)}>
									<>
										<NatControlPanel
											buttonItemList={[
												{
													title: 'ДОБАВИТЬ',
													onClick: () => {
														this.props.addEmptyString();
													},
													withoutIcon: true,
													disabled: this.props.match.params.elementId === 'new'
												},
												{
													title: 'УДАЛИТЬ',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('barcodes');
													},
													withoutIcon: true,
													disabled: this.props.productFormStore.collectedModels.length === 0
												}
											]}
										/>
										<AlertDialog
											isModalOpen={this.props.childrenProps.modals.includes('barcodes')}
											title="Выполнить действие?"
											buttonItemList={[
												{
													title: 'Да',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('barcodes');
														let where: { productId: string | undefined; barcode?: any } = {
															productId: this.props.productFormStore.model.id
														};
														let barcodes: Array<string> = [];
														_.forEach(this.props.productFormStore.collectedModels, (item: any) => {
															barcodes.push(item.barcode);
														});
														where.barcode = { inq: barcodes };
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', true);
														controller
															.delete('productBarcodes', where)
															.then(() => {
																this.props.findInstances('productBarcodes', { productId: this.props.productFormStore.model.id }, this.props.productFormStore);
																this.props.productFormStore.setValue(this.props.productFormStore, 'collectedModels', []);
															})
															.catch((error) => {
																catalog.handleNatError(error);
																this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
															});
													}
												},
												{
													title: 'ОТМЕНА',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('barcodes');
													}
												}
											]}
										/>
									</>
								</NatFormList>
							</>,
							<>
								<NatFormList
									setValue={this.props.productFormStore.setValue}
									data={this.props.productFormStore.serialNumbers}
									store={this.props.productFormStore}
									collectModels={this.props.childrenProps.collectModels}
									goTo={this.props.goTo}
									scrollable
									sortable
									selectableRow
									className="nat__table__hover nat__form__table"
									columns={[
										{
											id: 'checkbox',
											pinned: true,
											className: '',
											width: '54px',
											minResizeWidth: 0,
											maxResizeWidth: null,
											resizable: false,
											visible: true,
											headerCellRenderer: () => {
												return <div></div>;
											},
											cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
												return (
													<div className="rgt-cell-inner ml-0 mr-0">
														<input type="checkbox" id="checkbox" onChange={onChange} />
													</div>
												);
											}
										},
										{
											id: 1,
											field: 'deleted',
											label: '',
											width: '70px',
											cellRenderer: ({ data }: { data: any }) => {
												return <div className="rgt-cell-inner">{<CIcon src={catalog.pickupCatalogStateImage(data)} size="lg" />}</div>;
											},
											sort: () => {}
										},
										{
											id: 2,
											field: 'name',
											label: 'Имя',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div
														className={(() => {
															if (data.deleted) {
																return 'rgt-cell-inner rgt-text-truncate text-secondary';
															} else {
																return 'rgt-cell-inner rgt-text-truncate';
															}
														})()}>
														{catalog.renderCatalogName(data)}
													</div>
												);
											},
											sort: () => {}
										},
										{
											id: 3,
											field: 'serialNumber',
											label: 'Серийный номер',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div
														className={(() => {
															if (data.deleted) {
																return 'rgt-cell-inner rgt-text-truncate text-secondary';
															} else {
																return 'rgt-cell-inner rgt-text-truncate';
															}
														})()}>
														{data.serialNumber}
													</div>
												);
											},
											sort: () => {}
										},
										{
											id: 4,
											field: 'gtin',
											label: 'Код GTIN',
											cellRenderer: ({ data }: { data: any }) => {
												return (
													<div
														className={(() => {
															if (data.deleted) {
																return 'rgt-cell-inner rgt-text-truncate text-secondary';
															} else {
																return 'rgt-cell-inner rgt-text-truncate';
															}
														})()}>
														{data.gtin}
													</div>
												);
											},
											sort: () => {}
										}
									]}>
									<>
										<NatControlPanel
											buttonItemList={[
												{
													title: 'ДОБАВИТЬ',
													onClick: () => {
														if (_.get(this.props.productFormStore, 'isChanged', false)) {
															catalog.showAlert('Для добавления сохраните изменения!');
														} else {
															this.props.goTo(`/serialNumbers/new?ownerId=${this.props.productFormStore.model.id}&ownerType=${'OrmProduct'}`);
														}
													},
													withoutIcon: true,
													disabled:
														(!_.isEmpty(this.injected.directoryStore.models.constants['AllowSerialNumbers']) && !this.injected.directoryStore.models.constants['AllowSerialNumbers'].valueId) ||
														!this.props.productFormStore.model.useSerialNumbers ||
														this.props.match.params.elementId === 'new'
												},
												{
													title: 'УДАЛИТЬ',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('serialNumbers');
													},
													withoutIcon: true,
													disabled: this.props.productFormStore.collectedModels.length === 0
												}
											]}
										/>
										<AlertDialog
											isModalOpen={this.props.childrenProps.modals.includes('serialNumbers')}
											title="Выполнить действие?"
											buttonItemList={[
												{
													title: 'Да',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('serialNumbers');
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', true);
														catalog
															.makeDeleted('serialNumbers', this.props.productFormStore.collectedModels)
															.then(() => {
																this.props.findInstances('serialNumbers', { ownerId: this.props.productFormStore.model.id }, this.props.productFormStore);
																this.props.productFormStore.setValue(this.props.productFormStore, 'collectedModels', []);
															})
															.catch((error) => {
																catalog.handleNatError(error);
																this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
															});
													}
												},
												{
													title: 'ОТМЕНА',
													onClick: () => {
														this.props.childrenProps.toggleDeleteModal('serialNumbers');
													}
												}
											]}
										/>
									</>
								</NatFormList>
							</>,
							<>
								<NatControlPanel
									buttonItemList={[
										{
											title: 'ДОБАВИТЬ',
											onClick: () => {
												if (_.get(this.props.productFormStore, 'isChanged', false)) {
													catalog.showAlert('Для добавления сохраните изменения!');
												} else {
													let data: any = null;
													if (this.props.productFormStore.model.useFeatureOptionId === 'use_feature_options.use_by_product') {
														data = { ownerId: this.props.productFormStore.model.id, ownerType: 'OrmProduct' };
													} else if (this.props.productFormStore.model.useFeatureOptionId === 'use_feature_options.use_by_kind') {
														if (!_.isEmpty(this.props.productFormStore.model.kindId)) {
															if (!_.isEmpty(this.props.productFormStore.model.kind)) {
																data = { owner: this.props.productFormStore.model.kind.id, ownerType: 'OrmProductKind' };
															} else {
																data = null;
																catalog.showAlert('Для добавления выберите вид!');
															}
														} else {
															data = null;
															catalog.showAlert('Для добавления выберите вид!');
														}
													} else {
														data = null;
													}
													if (!_.isEmpty(data)) {
														this.props.goTo('/productFeatures/new', _.cloneDeep(data));
													}
												}
											},
											withoutIcon: true,
											disabled: _.isEmpty(this.props.productFormStore.model.useFeatureOptionId) || this.props.productFormStore.model.useFeatureOptionId == 'use_feature_options.not_used' || this.props.match.params.elementId === 'new'
										},
										{
											title: 'УДАЛИТЬ',
											onClick: () => {
												this.props.childrenProps.toggleDeleteModal('productFeatures');
											},
											withoutIcon: true,
											disabled: this.props.productFormStore.collectedModels.length === 0
										}
									]}
								/>
								<div className="nat__form__table__wrapper m-4 h-100">
									<form className="nat__form__table">
										<GridTable
											columns={[
												{
													id: 'checkbox',
													pinned: true,
													className: '',
													width: '54px',
													minResizeWidth: 0,
													maxResizeWidth: null,
													resizable: false,
													visible: true,
													headerCellRenderer: () => {
														return <div></div>;
													},
													cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
														return (
															<div className="rgt-cell-inner ml-0 mr-0">
																<input type="checkbox" id="checkbox" onChange={onChange} />
															</div>
														);
													}
												},
												{
													id: 1,
													field: 'deleted',
													label: '',
													width: '70px',
													cellRenderer: ({ data }: { data: any }) => {
														return <div className="rgt-cell-inner">{<CIcon src={catalog.pickupCatalogStateImage(data)} size="lg" />}</div>;
													},
													sort: () => {}
												},
												{
													id: 2,
													field: 'name',
													label: 'Имя',
													cellRenderer: ({ data }: { data: any }) => {
														return (
															<div
																className={(() => {
																	if (data.deleted) {
																		return 'rgt-cell-inner rgt-text-truncate text-secondary';
																	} else {
																		return 'rgt-cell-inner rgt-text-truncate';
																	}
																})()}>
																{catalog.renderCatalogName(data)}
															</div>
														);
													},
													sort: () => {}
												}
											]}
											rows={this.props.productFormStore.productFeatures.list}
											isPaginated={false}
											texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
											showColumnVisibilityManager={false}
											showRowsInformation={false}
											onColumnsChange={() => {}}
											highlightSearch={false}
											isVirtualScroll={false}
											onRowClick={({ data, column }: { data: any; column: any }) => {
												if (column.id !== 'checkbox') {
													this.props.goTo(`/${this.props.productFormStore.productFeatures.pluralName}/` + data.id);
												}
											}}
											selectedRowsIds={_.map(this.props.productFormStore.collectedModels, 'id')}
											onSelectedRowsChange={(selectedRowsIds: any) => {
												this.props.childrenProps.collectModels(selectedRowsIds, this.props.productFormStore.productFeatures.list);
											}}
										/>
									</form>
								</div>
								<AlertDialog
									isModalOpen={this.props.childrenProps.modals.includes('productFeatures')}
									title="Выполнить действие?"
									buttonItemList={[
										{
											title: 'Да',
											onClick: () => {
												this.props.childrenProps.toggleDeleteModal('productFeatures');
												this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', true);
												catalog
													.makeDeleted('productFeatures', this.props.productFormStore.collectedModels)
													.then(() => {
														this.props.productFormStore.setValue(this.props.productFormStore, 'collectedModels', []);
														return controller.fetchFeaturesByProduct({ product: this.props.productFormStore.model });
													})
													.then((data) => {
														this.props.productFormStore.setValue(this.props.productFormStore.productFeatures, 'list', []);
														if (!_.isEmpty(data)) {
															this.props.productFormStore.setValue(this.props.productFormStore.productFeatures, 'list', data);
														}
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
													})
													.catch((error) => {
														catalog.handleNatError(error);
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
													});
											}
										},
										{
											title: 'ОТМЕНА',
											onClick: () => {
												this.props.childrenProps.toggleDeleteModal('productFeatures');
											}
										}
									]}
								/>
							</>,
							<>
								{(() => {
									if (this.injected.directoryStore.models.locales.length > 1) {
										return (
											<div className="nat__card__wrapper nat__borders__2-75 p-2 mx-4 d-flex align-items-center justify-content-between">
												<CForm className="ml-3">
													<NatStringSelectInput
														object={this.props.productFormStore}
														property="language"
														type="text"
														size="sm"
														formatter={selectFormatter}
														autoComplete="off"
														list={this.state.selectList}
														placeholder="Выберите"
														label="Язык описания"
														formGroupClassName="w-100 align-items-center nat__form__group mb-0"
													/>
												</CForm>
											</div>
										);
									}
								})()}
								<div className={this.injected.directoryStore.models.locales.length > 1 ? 'nat__form__table__wrapper m-4 h-100' : 'nat__form__table__wrapper mx-4 mb-4 h-100'}>
									<div className="nat__form__table">
										<NatLocalizedTextarea
											object={this.props.productFormStore.model}
											property="description"
											label=""
											placeholder="Введите описание"
											type="text"
											inputColumnClassName="default__nat__card__body__height px-0"
											labelColumnSettings="12"
											labelColumnClassName="text-left align-self-center"
											wrapperClassName="default__nat__card__body__height"
											inputClassName="default__nat__card__body__height nat__scrollbar text-left"
											formatter={localizedStringFormatter}
											row={false}
											language={this.props.productFormStore.language}
											size="sm"
										/>
									</div>
								</div>
							</>,
							<>
								<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
									<div className="nat__form__table">
										<CCol lg="12">
											<CFormGroup row className="mb-0">
												<CCol md="4">
													<NatValueInput<number>
														object={this.props.productFormStore.model}
														property="priority"
														placeholder="Введите число"
														type="text"
														pattern="^-?([0-9]*[.,])?[0-9]*$"
														formatter={numberNullableFormatter}
														size="sm"
														label="Приоритет"
														mobile
													/>
												</CCol>
												<CCol md="4">
													<NatLocalizedValueInput<LocalizedString>
														object={this.props.productFormStore.model}
														property="displayName"
														placeholder="Введите наименование"
														type="text"
														formatter={localizedStringFormatter}
														size="sm"
														autoComplete="off"
														label="Отображаемое наименование"
														mobile
													/>
												</CCol>
											</CFormGroup>
											{this.props.productFormStore.model.typeId === 'product_types.set' && (
												<CFormGroup row className="mb-0">
													<CCol md="4">
														<NatRelationInput
															object={this.props.productFormStore.model}
															property="setPrintOptionId"
															relation="setPrintOption"
															placeholder="Выберите вариант печати"
															type="text"
															size="sm"
															formatter={relationFormatter()}
															pluralName="enumerationItems"
															onlySelect={true}
															filterWhere={{ ownerId: 'product_set_print_options' }}
															autoComplete="off"
															label="Вариант печати набора"
															mobile
															filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
															invalid={_.isEmpty(this.props.productFormStore.model.setPrintOptionId)}
															invalidFeedback="Элемент не выбран"
														/>
													</CCol>
													<CCol md="4">
														<NatRelationInput
															object={this.props.productFormStore.model}
															property="setPriceCalculationMethodId"
															relation="setPriceCalculationMethod"
															placeholder="Выберите способ"
															type="text"
															size="sm"
															formatter={relationFormatter()}
															pluralName="enumerationItems"
															onlySelect={true}
															filterWhere={{ ownerId: 'product_set_price_calculation_methods' }}
															autoComplete="off"
															label="Способ расчета цены набора"
															mobile
															filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
															invalid={_.isEmpty(this.props.productFormStore.model.setPriceCalculationMethodId)}
															invalidFeedback="Элемент не выбран"
														/>
													</CCol>
												</CFormGroup>
											)}
											<CFormGroup row className="mb-0">
												{this.props.productFormStore.model.useQuantityLimit && (
													<CCol md="4">
														<NatValueInput<string>
															object={this.props.productFormStore.model}
															property="quantityLimitRange"
															placeholder="Введите диапазон"
															type="text"
															formatter={stringFormatter()}
															size="sm"
															autoComplete="off"
															label="Диапазон ограничения"
															mobile
														/>
													</CCol>
												)}
												{!_.isEmpty(this.injected.directoryStore.models.constants['AllowSerialNumbers']) && this.injected.directoryStore.models.constants['AllowSerialNumbers'].valueId && (
													<CCol md="4">
														<NatCheckbox object={this.props.productFormStore.model} property="useSerialNumbers" label="Использовать серийные номера" />
													</CCol>
												)}
											</CFormGroup>
											{(() => {
												if (this.props.productFormStore.model.typeId === 'product_types.subscription' || this.props.productFormStore.model.typeId === 'product_types.subscription_option') {
													return (
														<>
															<CFormGroup row className="mb-0">
																<CCol md="4">
																	<NatRelationInput
																		object={this.props.productFormStore.model}
																		property="validityPeriodId"
																		relation="validityPeriod"
																		placeholder="Выберите период"
																		type="text"
																		size="sm"
																		formatter={relationFormatter()}
																		pluralName="enumerationItems"
																		onlySelect={true}
																		filterWhere={{ ownerId: 'periodicity' }}
																		autoComplete="off"
																		label="Период действия"
																		mobile
																		filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																	/>
																</CCol>
																<CCol md="4">
																	<NatRelationInput
																		object={this.props.productFormStore.model}
																		property="subscriptionPlanId"
																		relation="subscriptionPlan"
																		placeholder="Выберите план"
																		type="text"
																		size="sm"
																		formatter={relationFormatter()}
																		pluralName="subscriptionPlans"
																		autoComplete="off"
																		label="План подписки"
																		mobile
																		goTo={this.props.goTo}
																	/>
																</CCol>
															</CFormGroup>
														</>
													);
												}
											})()}
											{this.props.productFormStore.model.typeId === 'product_types.subscription_option' && (
												<CFormGroup row className="mb-0">
													<CCol md="4">
														<NatRelationInput
															object={this.props.productFormStore.model}
															property="subscriptionOptionId"
															relation="subscriptionOption"
															placeholder="Выберите опцию"
															type="text"
															size="sm"
															formatter={relationFormatter()}
															pluralName="subscriptionOptions"
															filterWhere={{ deleted: false, elementType: 0 }}
															autoComplete="off"
															label="Опция подписки"
															mobile
															goTo={this.props.goTo}
														/>
													</CCol>
												</CFormGroup>
											)}
											<CFormGroup row className="mb-0">
												<CCol md="4">
													<NatCheckbox object={this.props.productFormStore.model} property="useQuantityLimit" label="Ограничивать количество" />
												</CCol>
											</CFormGroup>
										</CCol>
									</div>
								</div>
							</>
						];
					}}
					fields={(() => {
						if (this.props.productFormStore.model.elementType === 0) {
							return (
								<>
									<CCol
										lg={(() => {
											if (this.injected.directoryStore.models.windowSize > 1150) {
												return '8';
											}
										})()}
										className={(() => {
											if (this.injected.directoryStore.models.windowSize < 1150) {
												return 'flex__100';
											}
										})()}>
										<CFormGroup row className="mb-0">
											<CCol md="6">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.productFormStore.model}
													property="name"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													invalid={true}
													invalidFeedback="Строка не заполнена"
													autoComplete="off"
													label="Наименование"
													mobile
												/>
											</CCol>
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="unitId"
													relation="unit"
													placeholder="Выберите ед.изм."
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="productUnits"
													apiMethod={(): any => {
														return controller.fetchUnitsByProduct({ product: this.props.productFormStore.model });
													}}
													autoComplete="off"
													label="Ед.изм."
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="6">
												<NatValueInput<string>
													object={this.props.productFormStore.model}
													property="article"
													placeholder="Введите артикул"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Артикул"
													mobile
												/>
											</CCol>
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="typeId"
													relation="type"
													placeholder="Выберите тип"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{
														ownerId: 'product_types',
														predefinedName: {
															nin: ['ProductTypesGiftCard']
														}
													}}
													autoComplete="off"
													label="Тип"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="accountingOptionId"
													relation="accountingOption"
													placeholder="Выберите вариант"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'product_accounting_options' }}
													autoComplete="off"
													label="Вариант учета"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											</CCol>
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="kindId"
													relation="kind"
													placeholder="Выберите вид"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="productKinds"
													filterWhere={{ deleted: false, elementType: 0 }}
													autoComplete="off"
													include={['unit', 'accountingOption', 'useFeatureOption', 'setPrintOption', 'setPriceCalculationMethod']}
													label="Вид"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="useFeatureOptionId"
													relation="useFeatureOption"
													placeholder="Выберите"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{
														ownerId: 'use_feature_options',
														predefinedName: {
															nin: ['UseFeatureOptionsUseByAnotherKind']
														}
													}}
													autoComplete="off"
													label="Использование характеристик"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											</CCol>
											<CCol md="6">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="parentId"
													relation="parent"
													placeholder="Выберите группу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="products"
													filterWhere={{ deleted: false, elementType: 1 }}
													autoComplete="off"
													label="Группа"
													mobile
													treeSelect
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
									</CCol>
									<CCol lg={this.injected.directoryStore.models.windowSize > 1379 ? '4' : ''}>
										<NatDropImageLoad
											modelStore={this.props.productFormStore}
											parentGroup={this.props.productFormStore.files.list[0]}
											upload={(uploadData: any) => {
												this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', true);
												controller
													.uploadFile(uploadData)
													.then((data) => {
														if (!_.isEmpty(data)) {
															this.props.productFormStore.setValue(this.props.productFormStore.model, 'primaryImageId', data[0].id);
														}
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
														catalog.showAlert('Загрузка прошла успешно!');
													})
													.catch((error) => {
														catalog.handleNatError(error);
														this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
													});
											}}>
											<CImg
												src={(() => {
													if (!_.isEmpty(this.props.productFormStore.model.primaryImageId)) {
														let src: string = returnClientApiUrl() + `/files/${this.props.productFormStore.model.primaryImageId}/size/large/download`;
														if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
															src = src + `?tenantClientId=${localStorage.getItem('tenantClientId')}`;
														}
														return src;
													} else {
														return product;
													}
												})()}
												className="nat__product__img"
												draggable="false"
											/>
										</NatDropImageLoad>
									</CCol>
								</>
							);
						} else {
							return (
								<>
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.productFormStore.model}
													property="name"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													invalid={true}
													invalidFeedback="Строка не заполнена"
													autoComplete="off"
													label="Наименование"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.productFormStore.model}
													property="description"
													placeholder="Введите описание"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Описание"
													mobile
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.productFormStore.model}
													property="parentId"
													relation="parent"
													placeholder="Выберите группу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="products"
													filterWhere={{ deleted: false, elementType: 1 }}
													autoComplete="off"
													label="Группа"
													mobile
													treeSelect
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</>
							);
						}
					})()}
					controlButtons={[
						{
							title: 'ОК',
							onClick: (e: any) => {
								this.props.childrenProps
									.post(e)
									.then(() => {
										this.props.removeNavigationRoute(this.props.navigationRoute);
										if (this.props.match.params.elementId !== 'new') {
											this.props.pricesBulkUpsert();
										}
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
									});
							},
							disabled: this.props.productFormStore.isLoading
						},
						{
							title: 'ЗАПИСАТЬ',
							onClick: (e: any) => {
								this.props.childrenProps.postModel(e, true);
								if (this.props.match.params.elementId !== 'new') {
									this.props.pricesBulkUpsert();
								}
							},
							disabled: this.props.productFormStore.isLoading
						},
						{
							title: 'ИЗОБРАЖЕНИЯ',
							onClick: () => {
								if (this.props.match.params.elementId === 'new') {
									catalog.showAlert('Сохраните изменения!');
								} else {
									this.toggleNatImageManager(true);
								}
							}
						},
						{
							title: 'СОСТАВ НАБОРА',
							onClick: () => {
								if (this.props.match.params.elementId === 'new') {
									catalog.showAlert('Сохраните изменения!');
								} else {
									this.toggleNatSetManager(true);
								}
							},
							dontShow: this.props.productFormStore.model.elementType !== 0 || this.props.productFormStore.model.typeId !== 'product_types.set'
						},
						{
							title: 'ОТМЕНА',
							onClick: () => {
								this.props.removeNavigationRoute(this.props.navigationRoute);
							}
						},
						{
							title: this.props.productFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
							onClick: () => {
								this.props.childrenProps.makeDeleted();
							},
							disabled: this.props.match.params.elementId === 'new'
						},
						{
							title: 'ЕЩЕ',
							onClick: () => {},
							disabled: this.props.match.params.elementId === 'new',
							children: [
								{
									title: 'Управление доступом',
									onClick: () => {
										this.props.childrenProps.toggleAclModal(true);
									}
								}
							]
						}
					]}
				/>
				{this.state.isNatSetManagerOpen && (
					<NatSetManager
						productFormStore={this.props.productFormStore}
						isModalOpen={this.state.isNatSetManagerOpen}
						toggleNatSetManager={this.toggleNatSetManager}
						goTo={this.props.goTo}
						navigationRoute={this.props.navigationRoute}
						replace={this.props.replace}
					/>
				)}
				{this.state.isNatImageManagerOpen && (
					<NatImageManager
						store={this.props.productFormStore}
						isModalOpen={this.state.isNatImageManagerOpen}
						toggleNatImageManager={this.toggleNatImageManager}
						parentGroup={this.props.productFormStore.files.list[0]}
						removeBackground
						setPrimaryImage={(data) => {
							this.props.productFormStore.setValue(this.props.productFormStore.model, 'primaryImageId', data.id);
						}}
					/>
				)}
			</>
		);
	}
}

export default UseLocation(withRouter(ProductForm));
