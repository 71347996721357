import { observable, action, makeObservable, set } from 'mobx';

export interface NatSelectionStoreType {
	list: Array<any>;
	groups: Array<any>;
	groupsTree: Array<any>;
	currentPage: number;
	itemsPerPage: number;
	selectedItems: Array<any>;
	search: string;
	deleted: boolean;
	responseCode: number;
	treeState: any;
	selectedIds: Array<any>;
	isLoading: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatSelectionStore implements NatSelectionStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			groups: observable,
			groupsTree: observable,
			currentPage: observable,
			itemsPerPage: observable,
			selectedItems: observable,
			search: observable,
			deleted: observable,
			responseCode: observable,
			treeState: observable,
			selectedIds: observable,
			isLoading: observable,
			setValue: action
		});
	}
	list = [] as Array<any>;
	groups = [] as Array<any>;
	groupsTree = [] as Array<any>;
	currentPage = 0;
	itemsPerPage = 50;
	selectedItems = [] as Array<any>;
	selectedIds = [] as Array<any>;
	search = '';
	deleted = false;
	responseCode = 0;
	isLoading = false;
	treeState = {
		activeKey: '',
		focusKey: '',
		openNodes: [] as string[],
		nodes: [] as any[]
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natSelectionStore = new NatSelectionStore();

export default NatSelectionStore;
