import CIcon from '@coreui/icons-react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CHeader, CHeaderNav, CHeaderNavItem, CListGroup, CListGroupItem, CToggler } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { natiwiController } from '../../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatNatiwiOrders from '../../natiwiOrders/NatNatiwiOrders';
import NatPartnerSubscriptionInfo from '../../partnerSubscriptionInfo/NatPartnerSubscriptionInfo';
import { ReportsStoreType } from '../../reports/Reports/ReportsStore';
import { NavigationContainerStoreType } from '../ContainersStores/NavigationContainerStore';
import { listDateTimeFormatter } from '../Formatters/ListDateTimeFormatter';
import { priceFormatter } from '../Formatters/PriceFormatter';
import { catalog } from '../support/Catalog';

interface PropsType extends RouteComponentProps {
	toggleDisplaySidebar(): void;
}
interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	navigationContainerStore: NavigationContainerStoreType;
	directoryStore: OrmStoreType;
	reportsStore: ReportsStoreType;
}
interface StateType {
	isNatShoppingCartOpen: boolean;
	isNatCustomerOrdersModalOpen: boolean;
	isNatPartnerSubscriptionInfoOpen: boolean;
	currentNavGroup: any;
}
@inject('userStore', 'navigationContainerStore', 'directoryStore', 'reportsStore')
@observer
class Header extends Component<PropsType, StateType> {
	headerRef: any;
	constructor(props: PropsType) {
		super(props);
		this.headerRef = React.createRef();
		this.logout = this.logout.bind(this);
		this.resetData = this.resetData.bind(this);
		this.toggleNatShoppingCart = this.toggleNatShoppingCart.bind(this);
		this.toggleNatCustomerOrdersModal = this.toggleNatCustomerOrdersModal.bind(this);
		this.toggleNatPartnerSubscriptionInfo = this.toggleNatPartnerSubscriptionInfo.bind(this);
		this.changeDropdownsMaxHeight = this.changeDropdownsMaxHeight.bind(this);
		this.masterServerLogout = this.masterServerLogout.bind(this);
		this.tenantServerLogout = this.tenantServerLogout.bind(this);
		this.state = {
			isNatShoppingCartOpen: false,
			isNatCustomerOrdersModalOpen: false,
			isNatPartnerSubscriptionInfoOpen: false,
			currentNavGroup: null
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.changeDropdownsMaxHeight();
	}
	componentDidUpdate() {
		this.changeDropdownsMaxHeight();
	}
	logout(): any {
		if (this.injected.directoryStore.models.apiData.mode === 'master') {
			return this.masterServerLogout();
		} else if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
			return this.tenantServerLogout();
		}
	}
	masterServerLogout() {
		return controller.logout();
	}
	tenantServerLogout() {
		let logoutData: { tenantAccessTokenId: string | null; tenantClientId: string | null } = {
			tenantAccessTokenId: localStorage.getItem('accessToken'),
			tenantClientId: localStorage.getItem('tenantClientId')
		};
		return natiwiController.logout(logoutData);
	}
	resetData() {
		this.injected.navigationContainerStore.resetNavigationRoutes();
		this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'activeNavigationRoute', 'main');
	}
	toggleNatShoppingCart(status: boolean) {
		this.setState({
			isNatShoppingCartOpen: status
		});
	}
	toggleNatCustomerOrdersModal(status: boolean) {
		this.setState({
			isNatCustomerOrdersModalOpen: status
		});
	}
	toggleNatPartnerSubscriptionInfo(status: boolean) {
		this.setState({
			isNatPartnerSubscriptionInfoOpen: status
		});
	}
	toggleNav(id: string) {
		let reportsMap: any = _.chain(this.injected.reportsStore.list).keyBy('predefinedName').value();
		let navigationGroupsMap: any = _.chain(this.injected.navigationContainerStore.navigationGroups).cloneDeep().keyBy('group').value();
		let navigationGroup = navigationGroupsMap[id];
		if (id === 'sales') {
			let productInWarehouseReport = reportsMap['ProductInWarehouseReport'];
			if (navigationGroup) {
				if (productInWarehouseReport) {
					navigationGroup.children.reports.list.push({
						url: `/reports/${productInWarehouseReport.id}`,
						title: catalog.renderCatalogName(productInWarehouseReport),
						id: productInWarehouseReport.id
					});
				}
			}
		}
		if (navigationGroup) {
			this.setState({
				currentNavGroup: navigationGroup
			});
		}
	}
	changeDropdownsMaxHeight() {
		let natDropdownList = this.headerRef.current.querySelectorAll('.nat__dropdown__menu');
		for (let item of natDropdownList) {
			item.style.maxHeight = this.injected.directoryStore.models.clientHeight - 56 + 'px';
		}
	}
	render() {
		return (
			<CHeader className="bg-transparent border-0 d-flex" innerRef={this.headerRef}>
				<CHeaderNav>
					{this.injected.userStore.userLogin.isAuth && (
						<>
							{this.injected.directoryStore.models.windowSize <= 699 && (
								<CHeaderNavItem className="px-3 d-flex">
									<CToggler inHeader onClick={this.props.toggleDisplaySidebar} />
								</CHeaderNavItem>
							)}
							<div className="ml-3 d-flex">
								{this.injected.directoryStore.models.windowSize > 699 &&
									_.map(this.injected.navigationContainerStore.navigationGroups, (item, index: number) => {
										if (item.group !== 'all') {
											return (
												<CHeaderNavItem key={index} className="mr-1">
													<CDropdown className={this.injected.directoryStore.models.windowSize > 1365 ? 'nat__dropdown__nav__menu' : 'nat__dropdown__nav__menu nat__dropdown__nav__mobile__menu'}>
														<CDropdownToggle
															className="nat__button__hover nat__button__focus nat__button__hover__grey d-flex align-items-center"
															onClick={() => {
																this.toggleNav(item.group);
															}}
															caret={false}>
															<CIcon name={item.icon} size="lg" className={this.injected.directoryStore.models.windowSize > 1365 ? 'mx-2' : ''} />
															<span className={this.injected.directoryStore.models.windowSize > 1365 ? 'mx-2' : ''}>{this.injected.directoryStore.models.windowSize > 1365 && item.name}</span>
														</CDropdownToggle>
														<CDropdownMenu
															placement="bottom-end"
															className="m-0 nat__dropdown__menu nat__dropdown__shadow border-0 nat__borders__2-75 nat__nav__menu nat__dropdown__menu__background py-2 px-2 overflow-auto nat__dropdown__transparent__scrollbar">
															{this.state.currentNavGroup ? (
																_.map(this.state.currentNavGroup.children, (item) => {
																	if (!_.isEmpty(item.list)) {
																		return (
																			<CListGroup
																				key={generateRandomId()}
																				className={
																					this.injected.directoryStore.models.windowSize > 1365
																						? 'nat__nav__menu__body__list__group nat__card__wrapper p-2 mx-2 my-2 nat__dropdown__transparent__scrollbar'
																						: 'nat__nav__menu__body__list__group nat__card__wrapper p-2 mx-2 my-2 w-100 nat__dropdown__transparent__scrollbar'
																				}>
																				<CListGroupItem className="border-0 rounded-0">
																					<h5 className="text-left m-0">{item.title}</h5>
																				</CListGroupItem>
																				{_.map(item.list, (item) => {
																					return (
																						<CDropdownItem
																							key={generateRandomId()}
																							className={(() => {
																								if (item) {
																									if (
																										item.url === _.get(this.injected.navigationContainerStore, 'navigationRoute.url', null) &&
																										_.get(this.injected.navigationContainerStore, 'navigationRoute.displayed', false)
																									) {
																										return 'text-left border-0 rounded-0 list-group-item list-group-item-action my-1 active';
																									} else {
																										return 'text-left border-0 rounded-0 list-group-item list-group-item-action my-1';
																									}
																								}
																							})()}
																							disabled={_.isEmpty(item.url)}
																							onClick={() => {
																								this.props.history.push(item.url);
																							}}>
																							{item.title}
																						</CDropdownItem>
																					);
																				})}
																			</CListGroup>
																		);
																	}
																})
															) : (
																<></>
															)}
														</CDropdownMenu>
													</CDropdown>
												</CHeaderNavItem>
											);
										}
									})}
							</div>
						</>
					)}
				</CHeaderNav>
				<CHeaderNav className="mr-3 ml-auto">
					<CHeaderNavItem className="">
						<CDropdown className="nat__dropdown__flex">
							<CDropdownToggle className="nat__button__hover nat__button__hover__grey nat__button__focus" caret={false}>
								<CIcon name="cil-user" size="lg" />
							</CDropdownToggle>
							<CDropdownMenu placement="bottom-end" className="m-0 nat__dropdown__menu nat__dropdown__shadow nat__dropdown__transparent__scrollbar border-0 nat__borders__2-75 p-3">
								<div>
									<h5 className="mx-2 my-0">Пользователь:</h5>
									<div className="m-3 text-start d-flex align-items-center">
										<span className="w-100">
											{(() => {
												let user = _.get(this.injected.userStore.userLogin, 'user');
												if (user) {
													if (this.injected.directoryStore.models.apiData.mode === 'master') {
														if (user.username) {
															return user.username;
														} else {
															return user.email;
														}
													} else if (this.injected.directoryStore.models.apiData.mode === 'tenant') {
														if (user.username) {
															return `${user.username}@${localStorage.getItem('realm')}`;
														} else {
															return user.email;
														}
													}
												} else {
													return 'Данные не найдены';
												}
											})()}
										</span>
									</div>
								</div>
								{localStorage.getItem('masterAccessToken') && this.injected.directoryStore.models.apiData.mode === 'tenant' && (
									<>
										<div>
											<h5 className="mx-2 my-0">Текущий тариф:</h5>
											<div className={!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList) ? 'm-3 text-start d-flex align-items-center' : 'm-3 text-center'}>
												<span className="w-100">
													{!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList)
														? catalog.renderCatalogName(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].subscriptionPlan)
														: '-'}
												</span>
												{!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList) && (
													<CDropdownItem
														className="nat__button__hover justify-content-center"
														onClick={() => {
															this.toggleNatPartnerSubscriptionInfo(true);
														}}>
														ПОДРОБНЕЕ
													</CDropdownItem>
												)}
											</div>
										</div>
										<div>
											<h5 className="mx-2 my-0">Действует до:</h5>
											<div className={!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList) ? 'm-3 text-start' : 'm-3 text-center'}>
												<span>
													{(() => {
														if (!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList)) {
															if (this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].expiredAt) {
																return listDateTimeFormatter.renderValue(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].expiredAt);
															} else {
																return 'Навсегда';
															}
														} else {
															return '-';
														}
													})()}
												</span>
											</div>
										</div>
										<div>
											<h5 className="mx-2 my-0">Выбранные опции:</h5>
											<div className="d-flex flex-column nat__dropdown__options m-3">
												{(() => {
													if (!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList)) {
														if (!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].subscriptionOptionItemList)) {
															return _.chain(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].subscriptionOptionItemList)
																.groupBy((item) => {
																	return item.subscriptionOption.id;
																})
																.values()
																.map((item) => {
																	let head: any = _.head(item);
																	return _.reduce(
																		item,
																		(result: any, item: any) => {
																			result.quantity += item.quantity;
																			return result;
																		},
																		{
																			expiredAt: head.expiredAt,
																			quantity: 0,
																			subscriptionOption: head.subscriptionOption,
																			validityPeriodCount: head.validityPeriodCount,
																			validityPeriodId: head.validityPeriodId
																		}
																	);
																})
																.map((item, index) => {
																	return (
																		<span className="py-2 d-flex" key={index}>
																			<span>{catalog.renderCatalogName(item.subscriptionOption)}</span>
																			<span className="ml-auto">{item.quantity}</span>
																		</span>
																	);
																})
																.value();
														} else {
															return <span className="text-center">-</span>;
														}
													} else {
														return <span className="text-center">-</span>;
													}
												})()}
											</div>
										</div>
										{(() => {
											if (!_.isEmpty(this.injected.directoryStore.models.constants['AllowPersonalAccount'])) {
												if (this.injected.directoryStore.models.constants['AllowPersonalAccount'].valueId) {
													return (
														<div>
															<h5 className="mx-2 my-0">Лицевой счет:</h5>
															<div className="m-3 text-start d-flex align-items-center">
																{!_.isEmpty(this.injected.directoryStore.models.currentPartnerSettlement) && (
																	<span className="w-100">{`${priceFormatter.renderValue(-this.injected.directoryStore.models.currentPartnerSettlement.settlementAmount)} руб`}</span>
																)}
																<CDropdownItem
																	className="nat__button__hover justify-content-center"
																	onClick={() => {
																		this.props.history.push('/shoppingCart?mode=personalAccount');
																	}}>
																	ПОПОЛНИТЬ
																</CDropdownItem>
															</div>
														</div>
													);
												}
											}
										})()}
										<CDropdownItem
											className="nat__button__hover justify-content-center"
											onClick={() => {
												this.props.history.push('/shoppingCart?mode=change');
											}}>
											ИЗМЕНИТЬ
										</CDropdownItem>
										<CDropdownItem
											className="nat__button__hover justify-content-center"
											onClick={() => {
												this.props.history.push('/shoppingCart?mode=extend');
											}}
											disabled={(() => {
												if (!_.isEmpty(this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList)) {
													return (
														!this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].subscriptionPlan.renewable ||
														this.injected.directoryStore.models.partnerSubscriptionInfo.subscriptionItemList[0].subscriptionPlan.trial
													);
												} else {
													return true;
												}
											})()}>
											ПРОДЛИТЬ
										</CDropdownItem>
										<CDropdownItem
											className="nat__button__hover justify-content-center"
											onClick={() => {
												this.toggleNatCustomerOrdersModal(true);
											}}>
											ИСТОРИЯ ЗАКАЗОВ
										</CDropdownItem>
									</>
								)}

								{this.injected.userStore.userLogin.isAuth ? (
									<CDropdownItem
										className="nat__button__hover justify-content-center"
										onClick={() => {
											this.logout()
												.then(() => {
													this.injected.userStore.setValue(this.injected.userStore.userLogin, 'isAuth', false);
													localStorage.clear();
													this.props.history.push('/users/login');
													this.resetData();
												})
												.catch((error) => {
													catalog.handleNatError(error);
												});
										}}>
										ВЫЙТИ
									</CDropdownItem>
								) : (
									<CDropdownItem className="nat__button__hover justify-content-center" to={'/users/login'}>
										ВОЙТИ
									</CDropdownItem>
								)}
							</CDropdownMenu>
						</CDropdown>
					</CHeaderNavItem>
				</CHeaderNav>
				{this.state.isNatCustomerOrdersModalOpen && <NatNatiwiOrders isModalOpen={this.state.isNatCustomerOrdersModalOpen} toggleNatNatiwiOrders={this.toggleNatCustomerOrdersModal} />}
				{this.state.isNatPartnerSubscriptionInfoOpen && <NatPartnerSubscriptionInfo isModalOpen={this.state.isNatPartnerSubscriptionInfoOpen} toggleNatPartnerSubscriptionInfo={this.toggleNatPartnerSubscriptionInfo} />}
			</CHeader>
		);
	}
}

export default withRouter(Header);
