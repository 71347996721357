import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatDefaultFormTemplate from '../../shared/Components/NatDefaultFormTemplate';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatFilters from '../../shared/Components/NatFilters';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { ReportStoreType } from './ReportStore';

interface PropsType extends FormPropsType {
	reportStore: ReportStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
	execute(): void;
	clearFilters(): void;
}
interface StateType {
	isFilterOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class Report extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.state = {
			isFilterOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleFilters(status: boolean) {
		this.setState({
			isFilterOpen: status
		});
	}
	render() {
		return (
			<NatDefaultFormTemplate
				store={this.props.reportStore}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tablePart={
					<>
						{this.state.isFilterOpen && (
							<NatFilters
								store={this.props.reportStore}
								toggleModal={this.toggleFilters}
								isModalOpen={this.state.isFilterOpen}
								buttonItemList={[
									{
										title: 'ОЧИСТИТЬ',
										onClick: () => {
											this.props.clearFilters();
										}
									}
								]}>
								<>
									<CFormGroup row className="mb-0">
										<CCol md="10">
											<NatValueInput<string> object={this.props.reportStore} property="searchText" placeholder="Введите текст" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile label="Поиск" />
											{_.map(this.props.reportStore.filters, (item: any, index: number) => {
												if (item.valueType === 'value_types.relation') {
													return (
														<NatRelationInput
															object={item}
															property="valueId"
															relation="value"
															placeholder="Выберите из списка"
															type="text"
															size="sm"
															formatter={relationFormatter(true)}
															pluralName={item.parameterFieldValue.fieldValue.relationPropertyName + 's'}
															filterWhere={{ deleted: false, elementType: 0 }}
															autoComplete="off"
															label={item.parameterFieldValue.name[this.injected.directoryStore.models.language]}
															key={index}
														/>
													);
												} else if (item.valueType === 'value_types.number') {
													return (
														<NatValueInput<number>
															object={item}
															property="valueId"
															placeholder="Введите число"
															type="text"
															pattern="^([0-9]*[.,])?[0-9]*$"
															formatter={priceFormatter}
															size="sm"
															label={item.parameterFieldValue.name[this.injected.directoryStore.models.language]}
															key={index}
														/>
													);
												} else if (item.valueType === 'value_types.string') {
													return (
														<NatValueInput<string>
															object={item}
															property="valueId"
															placeholder="Введите строку"
															type="text"
															formatter={stringFormatter()}
															size="sm"
															autoComplete="off"
															label={item.parameterFieldValue.name[this.injected.directoryStore.models.language]}
															key={index}
														/>
													);
												}
											})}
										</CCol>
									</CFormGroup>
								</>
							</NatFilters>
						)}
						<form className="nat__form__table">
							<GridTable
								columns={this.props.reportStore.reportColumns}
								rows={this.props.reportStore.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								highlightSearch={false}
								showSearch={false}
								searchText={this.props.reportStore.searchText}
							/>
						</form>
					</>
				}
				fields={null}
				controlButtons={[
					{
						title: 'СФОРМИРОВАТЬ',
						onClick: () => {
							this.props.execute();
						},
						disabled: this.props.reportStore.isLoading
					},
					{
						title: 'ФИЛЬТРЫ',
						onClick: () => {
							this.toggleFilters(true);
						},
						disabled: this.props.reportStore.isLoading,
						className: 'nat__button__hover ml-auto'
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(Report));
