import { BigNumber } from 'bignumber.js';
import { isEmpty } from 'lodash';
import { runInAction } from 'mobx';

import ValueFormatter, { ButtonItem } from './ValueFormatter';

class NumberFormatter extends ValueFormatter<number, string> {
	public parseValue(value: string): number | undefined {
		value = value.replace(',', '.');
		if (isEmpty(value)) {
			value = '0';
		}
		return new BigNumber(value).toNumber();
	}

	public renderValue(value: number): string {
		let number = value;
		if (value === null || value === undefined) {
			number = 0;
		}
		return new BigNumber(number).toString();
	}
	public getActionButtons(): Array<ButtonItem<(object: any, property: string, validate: (object: any, value: number | undefined | null) => boolean) => void>> {
		if (this.dontShowActionButtons) {
			return [];
		} else {
			return [
				{
					icon: 'cil-plus',
					title: '+1',
					onClick: (object: any, property: string, validate: (object: any, value: number | undefined | null) => boolean) => {
						this.changeValue(
							object[property],
							object,
							validate,
							(value: number) => {
								runInAction(() => {
									object[property] = value;
								});
							},
							1
						);
					}
				},
				{
					icon: 'cil-minus',
					title: '-1',
					onClick: (object: any, property: string, validate: (object: any, value: number | undefined | null) => boolean) => {
						this.changeValue(
							object[property],
							object,
							validate,
							(value: number) => {
								runInAction(() => {
									object[property] = value;
								});
							},
							-1
						);
					}
				},
				{
					icon: 'cil-x',
					title: 'Очистить поле',
					onClick: (object: any, property: string, validate: (object: any, value: number | undefined | null) => boolean) => {
						let value: number | undefined | null = this.parseValue('');
						if (validate(object, value)) {
							runInAction(() => {
								object[property] = value;
							});
						}
					}
				}
			];
		}
	}
}

export let numberFormatter = new NumberFormatter();

export default NumberFormatter;
