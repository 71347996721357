import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CForm, CFormGroup, CLink } from '@coreui/react';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { stringFormatter } from '../shared/Formatters/StringFormatter';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import { MatchType } from '../shared/support/modelTypes';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	registerTenantClient(): any;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class Registration extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		document.title = 'Регистрация';
		let masterAccessToken = localStorage.getItem('masterAccessToken');
		if (masterAccessToken) {
			this.props.history.push('/users/registration/deployTenantClient');
		}
	}
	render() {
		return (
			<div className="justify-content-center align-items-center c-app nat__gardient__bg">
				<CCard className="d-flex flex-column align-items-center nat__login nat__card__wrapper nat__borders__2-75">
					<CCardHeader className="border-0 font-weight-bold bg-transparent">
						<h4 className="m-0">Регистрация!</h4>
					</CCardHeader>
					<CCardBody className="mx-4 pb-0">
						<CForm className="w-100">
							<NatValueInput<string>
								object={this.injected.userStore.userRegistration}
								property="email"
								placeholder="Введите почту"
								type="text"
								formatter={stringFormatter(true)}
								size="lg"
								autoComplete="off"
								useHandleEnter={false}
								label="Почта"
								loginInput
							/>
							<NatValueInput<string>
								object={this.injected.userStore.userRegistration}
								property="password"
								placeholder="Ввведите пароль"
								type="password"
								formatter={stringFormatter(true)}
								size="lg"
								autoComplete="off"
								useHandleEnter={false}
								label="Пароль"
								loginInput
							/>
							<NatValueInput<string>
								object={this.injected.userStore.userRegistration}
								property="repeatPassword"
								placeholder="Повторите пароль"
								type="password"
								formatter={stringFormatter(true)}
								size="lg"
								autoComplete="off"
								useHandleEnter={false}
								label="Повтор пароля"
								loginInput
								invalid={this.injected.userStore.userRegistration.repeatPassword !== this.injected.userStore.userRegistration.password ? true : false}
							/>
							<CFormGroup className="form-actions d-flex flex-column my-4">
								<CButton
									size="lg"
									className="nat__button"
									onClick={() => {
										this.props
											.registerTenantClient()
											.then(() => {
												this.props.history.push('/users/registration/deployTenantClient');
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
									}}>
									Зарегистрироваться
								</CButton>
							</CFormGroup>
						</CForm>
					</CCardBody>
					<CCardFooter className="d-flex flex-column border-0 bg-transparent pt-0">
						<div>
							{'Есть учетная запись? '}
							<CLink to="/users/login">Вход</CLink>
						</div>
						<CLink to="/users/resetPassword">Забыли пароль?</CLink>
					</CCardFooter>
				</CCard>
			</div>
		);
	}
}

export default withRouter(Registration);
