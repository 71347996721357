import { observable, action, makeObservable, set } from 'mobx';

import { CashRegisterShiftType } from '../../shared/support/modelTypes';

export interface CashRegisterShiftFormStoreType {
	model: CashRegisterShiftType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	isSequenceNumberChangedManually: boolean;
	setValue(model: any, key: string, value: any): void;
}

class CashRegisterShiftFormStore implements CashRegisterShiftFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			isSequenceNumberChangedManually: observable,
			setValue: action
		});
	}

	model = {
		startedAt: null,
		closedAt: null,
		cashRegisterId: null,
		statusId: 'cash_register_shift_statuses.open',
		organizationId: null,
		note: null,
		posted: false,
		periodAt: null,
		responsibleId: null,
		deleted: false,
		authorId: null,
		status: {
			description: null,
			keyId: 'open',
			id: 'cash_register_shift_statuses.open',
			name: { ru: 'Открыта' },
			ownerId: 'cash_register_shift_statuses',
			predefined: true,
			predefinedName: 'CashRegisterShiftStatusesOpen',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'cashRegisterShifts';
	filterInclude = ['organization', 'status', 'cashRegister'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		}
	];
	isFirstLoad = true;
	name = 'OrmCashRegisterShift';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const cashRegisterShiftFormStore = new CashRegisterShiftFormStore();

export default CashRegisterShiftFormStore;
