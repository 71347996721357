import { observable, action, makeObservable, set } from 'mobx';

import { ProductKindType } from '../../shared/support/modelTypes';

interface ListType {
	list: Array<any>;
	filterWhere: { productId?: string; ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterSkip?: number;
	itemsPerPage?: number;
	filterOrder: Array<string>;
	pluralName: string;
}
export interface ProductKindFormStoreType {
	model: ProductKindType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	productFeatures: ListType;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class ProductKindFormStore implements ProductKindFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			productFeatures: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		accountingOptionId: 'product_accounting_options.standart',
		cashRegisterTaxationTypeId: null,
		deleted: false,
		displayName: null,
		elementType: 0,
		labelTemplateId: null,
		name: { ru: '' },
		packageSetId: null,
		parentId: null,
		priority: null,
		propertySetId: null,
		specificationSetId: null,
		unitId: null,
		useFeatureOptionId: null,
		usePackageOptionId: 'use_package_options.not_used',
		useSerialNumbers: false,
		setPrintOptionId: null,
		setPriceCalculationMethodId: null,
		accountingOption: {
			description: null,
			id: 'product_accounting_options.standart',
			keyId: 'standart',
			name: { ru: 'Стандартный' },
			ownerId: 'product_accounting_options',
			predefined: true,
			predefinedName: 'ProductAccountingOptionsStandart',
			predefinedVersion: 0
		},
		useFeatureOption: {
			id: 'use_feature_options.not_used',
			keyId: 'not_used',
			ownerId: 'use_feature_options',
			name: { ru: 'Не используется' },
			description: null,
			predefined: true,
			predefinedName: 'UseFeatureOptionsNotUsed',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'productKinds';
	filterInclude = ['unit', 'accountingOption'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	productFeatures = {
		list: [],
		filterWhere: {},
		filterInclude: [],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'productFeatures'
	};
	isFirstLoad = true;
	name = 'OrmProductKind';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productKindFormStore = new ProductKindFormStore();

export default ProductKindFormStore;
