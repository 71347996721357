import { observable, action, makeObservable, set } from 'mobx';

import { PartnerSubscriptionOperationType } from '../../shared/support/modelTypes';

export interface PartnerSubscriptionOperationFormStoreType {
	model: PartnerSubscriptionOperationType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	productItem: any;
	isSequenceNumberChangedManually: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class PartnerSubscriptionOperationFormStore implements PartnerSubscriptionOperationFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			productItem: observable,
			name: observable,
			isSequenceNumberChangedManually: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		amount: 0,
		cashBoxId: null,
		contractId: null,
		couponId: null,
		deleted: false,
		discountsCalculated: false,
		loyaltyCardId: null,
		managerId: null,
		note: null,
		organizationId: null,
		ownerId: null,
		ownerType: null,
		partnerId: null,
		paymentOptionId: null,
		posted: false,
		priceTypeId: null,
		responsibleId: null,
		statusId: 'partner_subscription_operation_statuses.created',
		storeId: null,
		typeId: '',
		periodAt: null,
		productItemList: [],
		productItems: [],
		prepayItems: [],
		prepayItemList: [],
		loyaltyPolicyItems: [],
		loyaltyPolicyItemList: [],
		bonusPointIncomeItems: [],
		bonusPointIncomeItemList: [],
		status: {
			id: 'partner_subscription_operation_statuses.created',
			keyId: 'created',
			ownerId: 'partner_subscription_operation_statuses',
			name: {
				ru: 'Создан'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'PartnerSubscriptionOperationStatusesCreated',
			predefinedVersion: 0
		}
	};
	searchText = '';
	productItem = {};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'partnerSubscriptionOperations';
	filterInclude = [
		'partner',
		'organization',
		'status',
		'contract',
		'paymentOption',
		'priceType',
		'cashBox',
		'prepayItems',
		'loyaltyPolicyItems',
		'bonusPointIncomeItems',
		{
			relation: 'productItems',
			scope: {
				include: ['unit', 'feature', 'product', 'validityPeriod', 'productSet', 'subscriptionPlan', 'subscriptionOption']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultPriceType',
			property: 'priceTypeId',
			relation: 'priceType'
		},
		{
			predefinedName: 'DefaultPaymentOption',
			property: 'paymentOptionId',
			relation: 'paymentOption'
		},
		{
			predefinedName: 'DefaultCashBox',
			property: 'cashBoxId',
			relation: 'cashBox'
		}
	];
	isFirstLoad = true;
	name = 'OrmPartnerSubscriptionOperation';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const partnerSubscriptionOperationFormStore = new PartnerSubscriptionOperationFormStore();

export default PartnerSubscriptionOperationFormStore;
