import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);
Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

interface PropsType {
	store: any;
	object: any;
	property: string;
	multiLanguage?: boolean;
}
interface StateType {
	value: any;
}
@observer
class NatHtmlEditor extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.onChange = this.onChange.bind(this);
		if (this.props.multiLanguage) {
			this.reactions.push(
				reaction(
					() => this.props.store.language,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.setState({
								value: this.props.object[this.props.store.language]
							});
						}
					}
				)
			);
		}
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value, previousValue) => {
					if (value !== previousValue) {
						if (value) {
							this.setState({
								value: this.props.object[this.props.property]
							});
						}
					}
				}
			)
		);
		this.state = {
			value: this.props.object[this.props.property]
		};
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	onChange(value: any) {
		let object: any = this.props.object;
		runInAction(() => {
			object[this.props.property] = value;
		});
	}
	render() {
		return (
			<ReactQuill
				formats={['align', 'background', 'blockquote', 'bold', 'color', 'float', 'font', 'header', 'height', 'image', 'italic', 'link', 'script', 'strike', 'size', 'underline', 'width', 'list', 'clean']}
				value={this.state.value}
				onChange={this.onChange}
				modules={{
					imageActions: {},
					imageFormats: {},
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						['blockquote'],
						[{ list: 'ordered' }, { list: 'bullet' }],
						[{ script: 'sub' }, { script: 'super' }],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ color: [] }, { background: [] }],
						[{ font: [] }],
						[{ align: [] }],
						['image'],
						['link'],
						['clean']
					]
				}}
			/>
		);
	}
}

export default NatHtmlEditor;
