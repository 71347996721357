import CIcon from '@coreui/icons-react';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CFormGroup, CInput, CLabel } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import AutocompleteAdapter from '../Adapters/ValueAutocomplete/AutocompleteAdapter';
import ValueFormatter from '../Formatters/ValueFormatter';
import { catalog } from '../support/Catalog';
import NatInputGroup from './NatInputGroup';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	inputColumnClassName?: string;
	inputClassName?: string;
	type: string;
	invalid?: boolean;
	placeholder: string;
	pattern?: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	useHandleEnter?: boolean;
	verticalSwitching?: boolean;
	label?: string;
	formGroupClassName?: string;
	mobile?: boolean;
	loginInput?: boolean;
	onClick?: (e: any) => void;
	step?: string;
	adapter?: AutocompleteAdapter<any, string>;
	disableWrapper?: boolean;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatValueInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	id: string;
	constructor(props: PropsType<T>) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.id = generateRandomId();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	handleClickOutside() {
		if (this.props.adapter) {
			this.toggleDropdown(false);
		}
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if ((this.props.pattern && e.target.value.match(this.props.pattern) != null) || !this.props.pattern) {
			this.setState({
				value: e.target.value
			});
		}
	}
	handleFocus(event: any) {
		if (_.isNumber(this.getValue())) {
			this.setState(
				{
					value: String(this.getValue())
				},
				() => {
					event.target.select();
				}
			);
		} else {
			event.target.select();
		}
	}
	handleEnter(event: any) {
		if (event.keyCode === 13) {
			let form = event.target.form;
			if (form && form.length > 0) {
				let index = Array.prototype.indexOf.call(form, event.target);
				let length = form.length;
				let nextElement = null;
				for (index; index < length; index++) {
					let nextElement = _.get(form, `elements[${index + 1}]`, null);
					let condition: any = null;
					if (!this.props.verticalSwitching) {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox';
					} else {
						condition = nextElement && !nextElement.disabled && nextElement.type !== 'checkbox' && nextElement.name === this.props.property;
					}
					if (condition) {
						nextElement.focus();
						break;
					} else {
						nextElement = null;
					}
				}
				if (!nextElement) {
					event.target.blur();
				}
				event.preventDefault();
			}
		}
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value, this.props.object[this.props.property]);
	}

	renderValue(value: T | undefined | null): string {
		return this.props.formatter.renderValue(value);
	}
	render() {
		return (
			<CDropdown className="w-100 nat__dropdown">
				<NatInputGroup mobile={this.props.mobile}>
					{(childrenProps) => (
						<CFormGroup
							row
							className={(() => {
								if (!this.props.disableWrapper) {
									if (this.props.loginInput) {
										return 'nat__login__input w-100 align-items-center nat__form__group';
									} else {
										if (this.injected.directoryStore.models.windowSize < 490) {
											return 'w-100 align-items-center nat__form__group flex-nowrap';
										} else {
											return 'w-100 align-items-center nat__form__group';
										}
									}
								} else {
									return 'w-100 align-items-center m-0';
								}
							})()}>
							{!_.isEmpty(this.props.label) && (
								<CLabel
									htmlFor={this.id}
									title={this.props.label}
									className={(() => {
										if (this.props.label) {
											if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
												return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile';
											} else {
												return 'align-self-center text-truncate pl-3 m-0';
											}
										}
									})()}>
									{this.props.label}
								</CLabel>
							)}
							<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0 d-flex' : 'align-self-center pr-0 pl-0 d-flex'}>
								<CInput
									id={this.id}
									invalid={this.props.invalid}
									type={this.props.type}
									name={this.props.property}
									placeholder={this.props.placeholder}
									onChange={this.handleChange}
									onFocus={this.handleFocus}
									value={this.state.value}
									pattern={this.props.pattern}
									className={this.props.inputClassName}
									readOnly={this.props.readonly}
									disabled={this.props.disabled}
									size={this.props.size}
									autoComplete={this.props.autoComplete}
									step={this.props.step}
									onClick={(e) => {
										e.stopPropagation();
										if (this.props.onClick) {
											this.props.onClick(e);
										}
										if (this.props.adapter) {
											this.toggleDropdown(true);
											this.props.adapter
												.fetch(this.props.object)
												.then((data) => {
													if (!_.isEmpty(data)) {
														this.setState({
															autocompleteItems: data
														});
													}
												})
												.catch((error) => {
													catalog.handleNatError(error);
												});
										}
									}}
									onBlur={() => {
										let value: T | undefined | null = this.parseValue(this.state.value);
										if (this.validate(this.props.object, value)) {
											this.setValue(value);
											this.setState({
												value: this.renderValue(value)
											});
										} else {
											this.setState({
												value: this.renderValue(this.getValue())
											});
										}
									}}
									onKeyDown={_.get(this.props, 'useHandleEnter', true) ? this.handleEnter : () => {}}
								/>
								{!this.props.disabled && childrenProps.isButtonShow && (
									<div
										className={(() => {
											if (this.props.mobile) {
												if (this.injected.directoryStore.models.windowSize > 766) {
													if (this.props.disableWrapper) {
														return 'nat__input__buttons nat__input__buttons__mobile';
													} else {
														return 'nat__input__buttons';
													}
												} else {
													return 'nat__input__buttons nat__input__buttons__mobile';
												}
											} else {
												return 'nat__input__buttons';
											}
										})()}>
										{_.map(this.props.formatter.getActionButtons(), (item, index) => {
											return (
												<CButton
													key={index}
													type="button"
													className={item.className ? item.className : 'nat__input__button'}
													title={item.title}
													onClick={() => {
														item.onClick(this.props.object, this.props.property, this.validate);
													}}>
													<CIcon name={item.icon} className="text-dark" size="sm" />
												</CButton>
											);
										})}
									</div>
								)}
							</CCol>
						</CFormGroup>
					)}
				</NatInputGroup>
				{!_.isEmpty(this.state.autocompleteItems) && (
					<CDropdownMenu className="rounded-0 py-0" show={this.state.showDropdown}>
						<div className="nat__dropdown__list mw-100 nat__dropdown__white__scrollbar">
							{_.map(this.state.autocompleteItems, (item: string, index) => {
								return (
									<CDropdownItem
										key={index}
										onClick={(e) => {
											if (item) {
												this.setValue(this.parseValue(item));
												this.toggleDropdown(false);
											}
											e.stopPropagation();
										}}>
										{item}
									</CDropdownItem>
								);
							})}
						</div>
					</CDropdownMenu>
				)}
			</CDropdown>
		);
	}
}

export default enhanceWithClickOutside(NatValueInput);
