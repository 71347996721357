import { observable, action, makeObservable, set } from 'mobx';

import { directoryStore } from '../../../core/Stores/DirectoryStore';

export interface NatRelationInputStoreType {
	itemsPerPage: number;
	currentPage: number;
	deleted: boolean;
	order: Array<string>;
	relations: Array<any>;
	oldValue: string;
	timer: any;
	querySelector: any;
	showDropdown: boolean;
	filter: any;
	relation: any;
	modals: Array<number>;
	setValue(model: any, key: string, value: any): void;
}

class NatRelationInputStore implements NatRelationInputStoreType {
	constructor() {
		makeObservable(this, {
			itemsPerPage: observable,
			currentPage: observable,
			deleted: observable,
			relations: observable,
			order: observable,
			oldValue: observable,
			timer: observable,
			querySelector: observable,
			showDropdown: observable,
			filter: observable,
			relation: observable,
			modals: observable,
			setValue: action
		});
	}
	itemsPerPage = 50;
	currentPage = 0;
	deleted = false;
	order = [`name.${directoryStore.models.language} ASC`];
	relations = [] as Array<any>;
	oldValue = '';
	timer = null;
	querySelector = null;
	showDropdown = false;
	filter = null;
	relation = null;
	modals = [];
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natRelationInputStore = new NatRelationInputStore();

export default NatRelationInputStore;
