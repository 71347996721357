import { runInAction } from 'mobx';

import ValueFormatter, { ButtonItem } from './ValueFormatter';

class StringFormatter extends ValueFormatter<string, string> {
	public parseValue(value: string): string | undefined {
		return value || '';
	}

	public renderValue(value: string): string {
		return value || '';
	}

	public getActionButtons(): Array<ButtonItem<(object: any, property: string, validate: (object: any, value: string | undefined | null) => boolean) => void>> {
		if (this.dontShowActionButtons) {
			return [];
		} else {
			return [
				{
					icon: 'cil-x',
					title: 'Очистить поле',
					onClick: (object: any, property: string, validate: (object: any, value: string | undefined | null) => boolean) => {
						let value: string | undefined | null = this.parseValue('');
						if (validate(object, value)) {
							runInAction(() => {
								object[property] = value;
							});
						}
					}
				}
			];
		}
	}
}

export let stringFormatter = (dontShowActionButtons?: boolean) => {
	return new StringFormatter(dontShowActionButtons);
};

export default StringFormatter;
