import { observable, action, makeObservable, set } from 'mobx';

import { ProductFeatureType } from '../../shared/support/modelTypes';

interface NatSet {
	featureId: string | null;
	feature?: ProductFeatureType;
}
export interface NatSetManagerStoreType {
	model: NatSet;
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterSkip: number;
	currentPage: number;
	itemsPerPage: number;
	filterOrder: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	responseCode: number;
	deleted: boolean;
	isLoading: boolean;
	isChanged: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatSetManagerStore implements NatSetManagerStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterSkip: observable,
			currentPage: observable,
			itemsPerPage: observable,
			filterOrder: observable,
			pluralName: observable,
			collectedModels: observable,
			responseCode: observable,
			deleted: observable,
			isLoading: observable,
			isChanged: observable,
			setValue: action
		});
	}
	model = {
		featureId: null,
		feature: {
			deleted: false,
			displayName: null,
			name: { ru: '' },
			ownerId: null,
			ownerType: null,
			priority: null
		}
	};
	list = [];
	filterWhere = {};
	filterInclude = ['product', 'feature', 'unit', 'validityPeriod', 'productSet'];
	filterSkip = 0;
	currentPage = 0;
	itemsPerPage = 50;
	filterOrder = ['createdAt DESC'];
	pluralName = 'productSetMappings';
	collectedModels = [] as Array<any>;
	responseCode = 0;
	deleted = false;
	isLoading = false;
	isChanged = false;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natSetManagerStore = new NatSetManagerStore();

export default NatSetManagerStore;
