import { CCol, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { LocalizedString, MatchType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { SubscriptionOptionFormStoreType } from './SubscriptionOptionFormStore';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	removeNavigationRoute(navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean; parentRoute: string }): void;
	navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean; parentRoute: string; title: string };
	goTo(path: string, state?: Record<string, unknown>): void;
	subscriptionOptionFormStore: SubscriptionOptionFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class SubscriptionOptionForm extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.subscriptionOptionFormStore}
				navTabs={[{ name: 'Дополнительно' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.subscriptionOptionFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.subscriptionOptionFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.subscriptionOptionFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.subscriptionOptionFormStore.model}
										property="tag"
										placeholder="Введите ключ"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Уникальный ключ"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.subscriptionOptionFormStore.model}
										property="typeId"
										relation="type"
										placeholder="Выберите тип"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'value_types' }}
										autoComplete="off"
										label="Тип"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
										invalid={_.isEmpty(this.props.subscriptionOptionFormStore.model.typeId)}
										invalidFeedback="Элемент не выбран"
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.subscriptionOptionFormStore.model}
										property="meaningId"
										relation="meaning"
										placeholder="Выберите тип"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'meanings' }}
										autoComplete="off"
										label="Смысловая нагрузка"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.subscriptionOptionFormStore.model}
										property="valueAtZero"
										placeholder="Введите значение"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										autoComplete="off"
										label="Значение при нуле"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.subscriptionOptionFormStore.model}
										property="description"
										placeholder="Введите описание"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										autoComplete="off"
										label="Описание"
										mobile
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.subscriptionOptionFormStore.setValue(this.props.subscriptionOptionFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.subscriptionOptionFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.subscriptionOptionFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.subscriptionOptionFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(SubscriptionOptionForm));
