import { observable, action, makeObservable, set } from 'mobx';

export interface NatRemoveBackgroundStoreType {
	responseCode: number;
	image: any;
	benzinImage: any;
	isLoading: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatRemoveBackgroundStore implements NatRemoveBackgroundStoreType {
	constructor() {
		makeObservable(this, {
			responseCode: observable,
			image: observable,
			benzinImage: observable,
			isLoading: observable,
			setValue: action
		});
	}
	image = {};
	benzinImage = {};
	isLoading = false;
	responseCode = 0;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natRemoveBackgroundStore = new NatRemoveBackgroundStore();

export default NatRemoveBackgroundStore;
