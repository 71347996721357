import { CFormGroup, CInputCheckbox, CLabel } from '@coreui/react';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType extends ValueFieldPropsType {
	wrapperClassName?: string;
	columnClassName: string;
	row: boolean;
	checkboxClassname?: string;
	disabled?: boolean;
	indeterminate?: boolean;
}

@observer
class NatDefaultCheckbox extends NatValueField<boolean, boolean, PropsType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.myRef = React.createRef();
	}
	componentDidMount() {
		if (this.props.indeterminate) {
			this.myRef.current.indeterminate = this.props.indeterminate;
		}
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value: boolean, previousValue: boolean) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(value)
						});
					}
				}
			)
		);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			value: e.target.checked
		});
		this.setValue(this.parseValue(e.target.checked));
	}
	parseValue(value: boolean): boolean {
		return value;
	}

	renderValue(value: boolean): boolean {
		return value;
	}
	render() {
		return (
			<CFormGroup row={this.props.row} className={this.props.wrapperClassName} variant="checkbox">
				<CInputCheckbox checked={this.state.value} onChange={this.handleChange} disabled={this.props.disabled} innerRef={this.myRef} />
				<CLabel>{this.props.label}</CLabel>
			</CFormGroup>
		);
	}
}

export default NatDefaultCheckbox;
