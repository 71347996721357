import { BigNumber } from 'bignumber.js';
import _ from 'lodash';
import { runInAction } from 'mobx';

import { controller } from '../../core/Controllers/OrmController';
import { directoryStore } from '../../core/Stores/DirectoryStore';
import { formAlertStore } from '../Alerts/FormAlertStore';
import { NavigationRouteType } from '../ContainersStores/NavigationContainerStore';
import { priceFormatter } from '../Formatters/PriceFormatter';
import { quantityFormatter } from '../Formatters/QuantityFormatter';
import { models } from '../support/NatModels';
import { natDocDone, natDocRegular, natDocDeleted, natCatDeleted, natCatRegular } from './NatIcons';
import { LocalizedString } from './modelTypes';

class Catalog {
	makeDeleted(pluralName: string, models: Array<any>) {
		return Promise.all(_.map(models, (item: any) => controller.makeDeleted(pluralName, !item.deleted, item.id)));
	}
	renderCatalogName(data: any): string {
		if (!_.isEmpty(data)) {
			if (!_.isEmpty(data.displayName)) {
				return data.displayName[directoryStore.models.language];
			} else if (!_.isEmpty(data.name)) {
				return data.name[directoryStore.models.language];
			} else if (!_.isEmpty(data.defaultName)) {
				return data.defaultName[directoryStore.models.language];
			} else {
				return '';
			}
		} else {
			return '';
		}
	}
	renderDocumentName(data: any, modelName: string) {
		let title: string = '';
		if (!_.isEmpty(data)) {
			if (!_.isEmpty(data.defaultName)) {
				title = data.defaultName[directoryStore.models.language];
			} else {
				title = `${models[modelName].displayName} ${data.sequenceNumber} от ${new Date(data.periodAt).toLocaleDateString()}`;
			}
		}
		return title;
	}
	renderPrice(data: any) {
		let result = priceFormatter.renderValue(data.amount);
		return result;
	}
	renderQuantity(data: any) {
		let result = quantityFormatter.renderValue(data.quantity);
		return result;
	}
	natRound(value, roundingAccuracy, roundingOptionId) {
		if (typeof value !== 'number') {
			throw new TypeError('Expected value to be a number');
		}

		if (roundingAccuracy === Infinity) {
			return value;
		}

		const power = 10 ** roundingAccuracy;
		let result = value;

		switch (roundingOptionId) {
			case 'rounding_options.always_in_favor_of_the_enterprise': {
				result = Math.ceil(Number((value * power).toPrecision(15))) / power;
				break;
			}
			case 'rounding_options.always_in_favor_of_the_client': {
				result = Math.floor(Number((value * power).toPrecision(15))) / power;
				break;
			}
			default: {
				let isRoundingAndNegative = value < 0;
				if (isRoundingAndNegative) {
					value = Math.abs(value);
				}
				result = Math.round(Number((value * power).toPrecision(15))) / power;
				if (isRoundingAndNegative) {
					result = -result;
				}
				break;
			}
		}

		return result;
	}
	showAlert(alertMessage: string) {
		formAlertStore.setValue(formAlertStore, 'alertText', alertMessage);
		formAlertStore.setValue(formAlertStore, 'showAlert', true);
	}
	recalculateList(data: any) {
		for (let item of data) {
			runInAction(() => {
				this.recalculateItem(item);
			});
		}
		return data;
	}
	recalculateItem(data: any) {
		let validityPeriodCount = 1;
		if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
			if (data.validityPeriodCount !== undefined) {
				validityPeriodCount = data.validityPeriodCount;
			}
		}
		let amountWithoutDiscount = data.price * data.quantity * validityPeriodCount;
		data.amount = data.price * data.quantity * validityPeriodCount;
		if (data.manualDiscountPercent) {
			data.manualDiscountAmount = amountWithoutDiscount * data.manualDiscountPercent;
			data.amount -= data.manualDiscountAmount;
		}
		if (data.autoDiscountPercent) {
			data.autoDiscountAmount = amountWithoutDiscount * data.autoDiscountPercent;
			data.amount -= data.autoDiscountAmount;
		}
		if (data.bonusPointOutcomeConverted) {
			data.amount -= data.bonusPointOutcomeConverted;
		}
		return data;
	}
	recalculateAmount(data: any) {
		let updateAmount = _.reduce(
			data,
			(sum: number, item: any) => {
				return item.amount !== undefined ? sum + item.amount : 0;
			},
			0
		);
		return updateAmount;
	}
	calculateTotal(data: any) {
		let total = _.reduce(
			data,
			(sum: number, item: any) => {
				return item.quantity !== undefined ? sum + item.quantity : data.length;
			},
			0
		);
		return total;
	}
	fetchApplicableContract(store: any, include?: Array<string>) {
		store.setValue(store, 'isLoading', true);
		if (!_.isEmpty(store.model.partner)) {
			controller
				.fetchApplicableContract(store.model.partner)
				.then((data) => {
					if (!_.isEmpty(data)) {
						if (include) {
							let filter = {
								where: { id: data.id },
								include
							};
							return controller.findAll('partnerContracts', filter);
						} else {
							store.setValue(store.model, 'contract', data);
							store.setValue(store.model, 'contractId', data.id);
							store.setValue(store, 'isLoading', false);
						}
					} else {
						delete store.model.contract;
						store.setValue(store.model, 'contractId', null);
						store.setValue(store, 'isLoading', false);
					}
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						store.setValue(store.model, 'contract', data[0]);
						store.setValue(store.model, 'contractId', data[0].id);
						store.setValue(store, 'isLoading', false);
					}
				})
				.catch((error) => {
					this.handleNatError(error);
					store.setValue(store, 'isLoading', false);
				});
		}
	}
	setConstants(store: any, requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>) {
		let constants: any = directoryStore.models.constants;
		_.forEach(requiredToFill, (item) => {
			if (constants[item.predefinedName] !== undefined) {
				runInAction(() => {
					store.model[item.relation] = constants[item.predefinedName].value;
					store.model[item.property] = constants[item.predefinedName].valueId;
				});
			}
		});
	}
	generateErrorMessage(error: any, config?: any) {
		let errorMessage = _.get(error.response, 'data.error.message');
		if (config) {
			if (config[error.response.status]) {
				if (config[error.response.status].showMessage) {
					return config[error.response.status].message;
				}
			} else {
				if (errorMessage) {
					return error.response.data.error.message;
				} else {
					return `Ошибка ${error.response.status}`;
				}
			}
		} else {
			if (errorMessage) {
				return error.response.data.error.message;
			} else {
				return `Ошибка ${error.response.status}`;
			}
		}
	}
	generateRequestErrorMessage(error: any, config?: any) {
		let errorMessage = _.get(error.response, 'data.error.message');
		if (config) {
			if (config[error.response.status]) {
				if (config[error.response.status].showMessage) {
					this.showAlert(config[error.response.status].message);
				}
			} else {
				if (errorMessage) {
					this.showAlert(`${error.response.data.error.message}`);
				} else {
					this.showAlert(`Ошибка ${error.response.status}`);
				}
			}
		} else {
			if (errorMessage) {
				this.showAlert(`${error.response.data.error.message}`);
			} else {
				this.showAlert(`Ошибка ${error.response.status}`);
			}
		}
	}
	handleNatError(error: any) {
		// let newError: any = new Error('Непредвиденная ошибка. Обратитесь в тех. поддержку.');
		// // directoryStore.setValue(directoryStore.models, 'appError', error);
		// if (error.message === 'Проверьте подключение к интернету и повторите попытку') {
		// 	newError = error;
		// }
		console.error(error);
		this.showAlert(error.message);
	}
	handleRequestError(error: any, config?: any) {
		let newError: any = new Error('Непредвиденная ошибка. Обратитесь в тех. поддержку.');
		if (!_.isEmpty(error.response)) {
			let errorCode = _.get(error.response, 'data.error.code');
			directoryStore.setValue(directoryStore.models, 'requestError', error.response);
			if (errorCode) {
				if (errorCode === 'AUTHORIZATION_REQUIRED') {
					newError = new Error('Требуется авторизация!');
				} else if (errorCode === 'LOGIN_FAILED') {
					newError = new Error('Проверьте правильность данных для входа!');
				} else if (errorCode === 'INVALID_PASSWORD') {
					newError = new Error('Некорректный пароль!');
				} else {
					newError = new Error(this.generateErrorMessage(error, config));
				}
			} else {
				newError = new Error(this.generateErrorMessage(error, config));
			}
		} else {
			directoryStore.setValue(directoryStore.models, 'appError', error);
			if (error.message === 'Network Error') {
				newError = new Error('Проверьте подключение к интернету и повторите попытку');
			} else {
				console.error(error);
			}
		}
		return newError;
	}
	handleError(error: any, config?: any) {
		if (!_.isEmpty(error.response)) {
			let errorCode = _.get(error.response, 'data.error.code');
			directoryStore.setValue(directoryStore.models, 'requestError', error.response);
			if (errorCode) {
				if (errorCode === 'AUTHORIZATION_REQUIRED') {
					this.showAlert('Требуется авторизация!');
				} else if (errorCode === 'LOGIN_FAILED') {
					this.showAlert('Проверьте правильность данных для входа!');
				} else if (errorCode === 'INVALID_PASSWORD') {
					this.showAlert('Некорректный пароль!');
				} else {
					this.generateRequestErrorMessage(error, config);
				}
			} else {
				this.generateRequestErrorMessage(error, config);
			}
		} else {
			directoryStore.setValue(directoryStore.models, 'appError', error);
			if (error.message === 'Network Error') {
				this.showAlert('Проверьте подключение к интернету и повторите попытку');
			} else {
				console.error(error);
				this.showAlert('Непредвиденная ошибка. Обратитесь в тех. поддержку.');
			}
		}
	}
	findInstances(pluralName: string, defaultWhere: any, store: any, action?: () => void, dontShowLoader?: boolean) {
		store.setValue(store[pluralName], 'list', []);
		let filter: { where: any; include: any; order: any; skip?: number; limit?: number } = {
			where: store[pluralName].filterWhere,
			include: store[pluralName].filterInclude,
			order: store[pluralName].filterOrder
		};
		if (store[pluralName].filterSkip !== undefined) {
			store.setValue(store[pluralName], 'filterSkip', 0);
			filter.skip = store[pluralName].filterSkip;
		}
		if (store[pluralName].itemsPerPage !== undefined) {
			filter.limit = store[pluralName].itemsPerPage;
		}
		if (!_.isEmpty(defaultWhere)) {
			Object.assign(filter.where, defaultWhere);
		}
		store.setValue(store[pluralName], 'filterWhere', filter.where);
		if (!dontShowLoader) {
			store.setValue(store, 'isLoading', true);
		}
		controller
			.findAll(pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					store.setValue(store[pluralName], 'list', data);
				}
				if (action !== undefined) {
					action();
				}
				if (!dontShowLoader) {
					store.setValue(store, 'isLoading', false);
				}
			})
			.catch((error) => {
				this.handleNatError(error);
				if (!dontShowLoader) {
					store.setValue(store, 'isLoading', false);
				}
			});
	}
	disablePageScroll() {
		let body = document.querySelector('body');
		if (body) {
			body.className = 'overflow-hidden';
		}
	}
	enablePageScroll() {
		let body = document.querySelector('body');
		if (body) {
			body.className = '';
		}
	}
	convertCellMethods(columns: Array<any>) {
		let result: Array<any> = [];
		_.transform(
			columns,
			(result: any, value: any) => {
				let column: any = value;
				column.getValue = ({ value }: { value: any }) => {
					if (_.isObject(value)) {
						if (!_.isEmpty(value)) {
							value.toString = () => {
								return this.renderCatalogName(value);
							};
						}
						return value.toString();
					} else {
						return value;
					}
				};
				result.push(column);
			},
			result
		);
		return result;
	}
	checkColumnVisibility(columns: Array<any>, items: Array<any>) {
		let checkVisibility = (column: any) => {
			let visible: boolean = false;
			for (let item of items) {
				if (column.checkCondition(item)) {
					visible = column.checkCondition(item);
					break;
				}
			}
			column.visible = visible;
		};
		let checkedColumns: Array<any> = [];
		for (let column of columns) {
			if (column.checkCondition !== undefined) {
				checkVisibility(column);
			}
			checkedColumns.push(column);
		}
		return checkedColumns;
	}
	checkColumnCondition(columns: Array<any>) {
		let checkVisibility = (column: any) => {
			let visible: boolean = false;
			if (column.checkCondition()) {
				visible = column.checkCondition();
			}
			column.visible = visible;
		};
		let checkedColumns: Array<any> = [];
		for (let column of columns) {
			if (column.checkCondition !== undefined) {
				checkVisibility(column);
			}
			checkedColumns.push(column);
		}
		return checkedColumns;
	}
	checkFeaturesCondition(item: any) {
		if (item.product !== undefined) {
			return !_.isEmpty(item.product.useFeatureOptionId) && item.product.useFeatureOptionId !== 'use_feature_options.not_used';
		} else {
			return false;
		}
	}
	checkSerialNumbersCondition(item: any) {
		if (item.product !== undefined) {
			return directoryStore.models.constants['AllowSerialNumbers'].valueId && item.product.useSerialNumbers;
		} else {
			return false;
		}
	}
	checkValidityPeriodsCondition(item: any) {
		if (item.product !== undefined) {
			return item.product.typeId === 'product_types.subscription' || item.product.typeId === 'product_types.subscription_option';
		} else {
			return false;
		}
	}
	checkSubscriptionOptionCondition(item: any) {
		if (item.product !== undefined) {
			return item.product.typeId === 'product_types.subscription_option';
		} else {
			return false;
		}
	}
	checkProductSetCondition(item: any) {
		return !_.isEmpty(item.productSetId) || !_.isEmpty(item.productSetFeatureId);
	}
	fetchQuantityLimitRange(productSetMapping: any) {
		let variableQuantityLimitRange = '';
		if (!_.isEmpty(productSetMapping)) {
			variableQuantityLimitRange = productSetMapping.variableQuantityLimitRange;
		}
		let result: any = null;
		if (_.isEmpty(variableQuantityLimitRange)) {
			result = null;
		} else {
			result = _.chain(variableQuantityLimitRange)
				.split(',')
				.map((value) => {
					value = _.trim(value);

					let result = Number(value);
					if (!_.isNaN(result)) {
						return result;
					}

					let rangeItem = value.split('-');
					if (rangeItem.length !== 2) {
						return catalog.showAlert(`Значение ${value} неверное`);
					}
					let minValue = Number(rangeItem[0]);
					if (_.isNaN(minValue)) {
						return catalog.showAlert(`Для значения ${value}, минимальный диапазон ${minValue} неверный`);
					}

					let maxValue = Number(rangeItem[1]);
					if (_.isNaN(maxValue)) {
						return catalog.showAlert(`Для значения ${value}, максимальный диапазон ${maxValue} неверный`);
					}

					return _.range(minValue, maxValue + 1, 1);
				})
				.flatten()
				.sortBy()
				.uniq()
				.value();
		}

		return result;
	}

	isValidQuantity(productSetMapping, value) {
		return this.getValidQuantity(productSetMapping, value) === value;
	}

	getValidQuantity(productSetMapping: any, value: number): number {
		let variableQuantity = true;
		if (!_.isEmpty(productSetMapping)) {
			variableQuantity = productSetMapping.variableQuantity;
		}
		let result: number = value;
		if (!variableQuantity) {
			result = productSetMapping.quantity;
		}

		let variableQuantityLimitRange: any = [];
		try {
			variableQuantityLimitRange = this.fetchQuantityLimitRange(productSetMapping);
		} catch (err) {
			// ничего не делаем
		}
		if (variableQuantityLimitRange) {
			if (_.includes(variableQuantityLimitRange, value)) {
				if (productSetMapping.variableQuantityLimitMinByDefault) {
					result = Math.max(value, productSetMapping.quantity);
				}
				result = value;
			} else {
				result = productSetMapping.quantity;
			}
		} else {
			result = value;
		}
		return result;
	}
	generateTitle(navigationRoute: NavigationRouteType, modelType: string, formType: string, title?: string) {
		if (formType === 'form') {
			if (!_.isEmpty(navigationRoute.store.model.id)) {
				if (modelType === 'OrmCatalog') {
					if (!navigationRoute.store.isChanged) {
						runInAction(() => {
							navigationRoute.title = `${catalog.renderCatalogName(navigationRoute.store.model)} (${models[navigationRoute.store.name].displayName})`;
						});
					} else {
						runInAction(() => {
							navigationRoute.title = `${catalog.renderCatalogName(navigationRoute.store.model)} (${models[navigationRoute.store.name].displayName})*`;
						});
					}
				} else if (modelType === 'OrmDocument') {
					if (!navigationRoute.store.isChanged) {
						runInAction(() => {
							navigationRoute.title = catalog.renderDocumentName(navigationRoute.store.model, navigationRoute.store.name);
						});
					} else {
						runInAction(() => {
							navigationRoute.title = `${catalog.renderDocumentName(navigationRoute.store.model, navigationRoute.store.name)}*`;
						});
					}
				}
			} else {
				runInAction(() => {
					navigationRoute.title = `${models[navigationRoute.store.name].displayName} (Новый)*`;
				});
			}
		} else if (formType === 'list') {
			runInAction(() => {
				navigationRoute.title = models[navigationRoute.store.name].pluralDisplayName;
			});
		} else {
			runInAction(() => {
				navigationRoute.title = title;
			});
		}
	}
	moveItems(selectedItemList, itemList, offset) {
		if (offset === 0) {
			return itemList;
		}
		let selectedItemByPosition = {};
		_.chain(itemList)
			.transform(function (result, value, key) {
				let position = selectedItemList.indexOf(value);
				if (position > -1) {
					result.push({
						position: key,
						item: value
					});
				}
			}, [] as Array<any>)
			.thru(function (value) {
				if (offset > 0) {
					return _.reverse(value);
				}
				return value;
			})
			.forEach(function (value) {
				itemList.splice(value.position, 1);
				let newPosition = Math.min(Math.max(value.position + offset, 0), itemList.length);
				let selectedItem = selectedItemByPosition[newPosition];
				if (!_.isNil(selectedItem)) {
					newPosition = value.position;
				}
				selectedItemByPosition[newPosition] = value;
				itemList.splice(newPosition, 0, value.item);
			})
			.value();
	}
	validateQuantityInput(data: any, value: any) {
		let result: any = {
			result: true
		};
		let validQuantity = this.getValidQuantity(data.productSetMapping, new BigNumber(value).toNumber());
		if (value !== validQuantity) {
			result = {
				result: false
			};
		}
		runInAction(() => {
			data.quantity = validQuantity;
		});
		return result;
	}
	pickupDocumentStateImage(data: any) {
		if (!data.deleted) {
			if (data.posted) {
				return natDocDone;
			} else {
				return natDocRegular;
			}
		} else {
			return natDocDeleted;
		}
	}
	pickupCatalogStateImage(data: any) {
		if (!data.deleted) {
			return natCatRegular;
		} else {
			return natCatDeleted;
		}
	}
	fetchLocalizeString(value: LocalizedString | null) {
		if (value) {
			return value[directoryStore.models.language];
		} else {
			return '';
		}
	}
}

export const catalog = new Catalog();

export default Catalog;
