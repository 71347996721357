import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { NatSelectionStoreType } from '../../shared/Modals/NatSelectionStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, CouponKindType } from '../../shared/support/modelTypes';
import CouponKindForm from './CouponKindForm';
import { CouponKindFormStoreType } from './CouponKindFormStore';

interface PropsType extends FormContainerPropsType<CouponKindFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	natSelectionStore: NatSelectionStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'natSelectionStore')
@observer
class CouponKindFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.statusId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'statusId', 'coupon_kind_statuses.valid');
						this.props.store.setValue(this.props.store.model, 'status', {
							id: 'coupon_kind_statuses.valid',
							keyId: 'valid',
							ownerId: 'coupon_kind_statuses',
							name: {
								ru: 'Действует',
								id: '0885a051-3bdf-485c-898a-45148155247f'
							},
							description: null,
							priority: 0,
							predefined: true,
							predefinedName: 'CouponKindStatusesValid',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => any) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: CouponKindType) {
		if (model.statusId === 'coupon_kind_statuses.valid' && _.isEmpty(model.status)) {
			model.status = _.cloneDeep(this.props.store.model.status);
		}
		return model;
	}
	prepareModelForPost(model: CouponKindType) {
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.statusId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/couponKinds/"
				handlers={this.props.handlers}>
				{(childrenProps) => <CouponKindForm removeNavigationRoute={this.props.removeNavigationRoute} couponKindFormStore={this.props.store} navigationRoute={this.props.navigationRoute} goTo={this.props.goTo} childrenProps={childrenProps} />}
			</NatDirectoryFormContainer>
		);
	}
}

export default CouponKindFormContainer;
