import { observable, action, makeObservable, set } from 'mobx';

import { ChipsDateType, ChipsPriceType, ChipsRelationType, ChipsStringType } from '../shared/support/modelTypes';

export interface NatNatiwiOrdersStoreType {
	itemsPerPage: number;
	currentPage: number;
	list: Array<any>;
	filterOrder: Array<string>;
	filterSkip: number;
	filterWhere: { deleted?: boolean; statusId?: any };
	filterInclude: Array<any>;
	pluralName: string;
	isLoading: boolean;
	chipsFields: { sequenceNumber: ChipsStringType; createdAtGt: ChipsDateType; createdAtLt: ChipsDateType; amountGte: ChipsPriceType; amountLte: ChipsPriceType; status: ChipsRelationType };
	sortConfig: { collId: string; isAsc: boolean };
	chips: Array<any>;
	layoutTemplates: Array<any>;
	setValue(model: any, key: string, value: any): void;
}

class NatNatiwiOrdersStore implements NatNatiwiOrdersStoreType {
	constructor() {
		makeObservable(this, {
			itemsPerPage: observable,
			currentPage: observable,
			list: observable,
			filterOrder: observable,
			filterSkip: observable,
			filterWhere: observable,
			filterInclude: observable,
			pluralName: observable,
			isLoading: observable,
			chipsFields: observable,
			sortConfig: observable,
			chips: observable,
			layoutTemplates: observable,
			setValue: action
		});
	}
	chipsFields = {
		sequenceNumber: {
			id: null,
			chips: [],
			title: 'Номер'
		},
		createdAtGt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: true
		},
		createdAtLt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: false
		},
		amountGte: {
			isPrice: true,
			id: null,
			chips: [],
			isGte: true
		},
		amountLte: {
			isPrice: true,
			id: null,
			chips: [],
			isGte: false
		},
		status: {
			isRelation: true,
			id: null,
			chips: [],
			list: []
		}
	};
	sortConfig = { collId: '', isAsc: false };
	chips = [];
	list = [] as Array<any>;
	itemsPerPage = 50;
	currentPage = 0;
	filterOrder = ['createdAt DESC'];
	filterSkip = 0;
	filterWhere = {
		deleted: false,
		statusId: {
			nin: ['partner_subscription_operation_statuses.canceled']
		}
	};
	filterInclude = [
		{
			relation: 'productItems',
			scope: {
				include: ['product']
			}
		},
		'status'
	];
	pluralName = 'partnerSubscriptionOperations';
	search = '';
	deleted = false;
	isLoading = false;
	layoutTemplates = [];
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natNatiwiOrdersStore = new NatNatiwiOrdersStore();

export default NatNatiwiOrdersStore;
