import closeTriangle from '../../assets/close_triangle.svg';
import nCatDeleted from '../../assets/n_cat_deleted.svg';
import nCatRegular from '../../assets/n_cat_regular.svg';
import nDocDeleted from '../../assets/n_doc_deleted.svg';
import nDocDone from '../../assets/n_doc_done.svg';
import nDocRegular from '../../assets/n_doc_regular.svg';
import openTriangle from '../../assets/open_triangle.svg';

export let natDocDone = nDocDone;
export let natDocRegular = nDocRegular;
export let natDocDeleted = nDocDeleted;
export let natCatDeleted = nCatDeleted;
export let natCatRegular = nCatRegular;
export let natCloseTriangle = closeTriangle;
export let natOpenTriangle = openTriangle;
