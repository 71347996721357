import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, SubscriptionPlanType } from '../../shared/support/modelTypes';
import SubscriptionPlanForm from './SubscriptionPlanForm';
import { SubscriptionPlanFormStoreType } from './SubscriptionPlanFormStore';

interface PropsType extends FormContainerPropsType<SubscriptionPlanFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class SubscriptionPlanFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.deletePeriodItems = this.deletePeriodItems.bind(this);
		this.saveSelected = this.saveSelected.bind(this);
		this.rebuild = this.rebuild.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (key !== 'applyLimitValidityPeriod' && key !== 'validityPeriod') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.productItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				}
			)
		);
		_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (key !== 'feature' && key !== 'unit' && key !== 'option' && key !== 'validityPeriod') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			});
			this.formReactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.serialNumberId = null;
							item.optionId = null;
							delete item.option;
							delete item.feature;
							delete item.serialNumber;
							if (!_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									item.validityPeriodId = item.product.validityPeriodId;
									item.validityPeriod = item.product.validityPeriod;
									item.optionId = item.product.subscriptionOptionId;
									item.option = item.product.subscriptionOption;
									item.unitId = item.product.unitId;
									item.unit = item.product.unit;
								}
							} else {
								item.unitId = null;
								item.optionId = null;
								item.validityPeriodId = null;
								delete item.validityPeriod;
								delete item.option;
								delete item.product;
								delete item.unit;
								this.rebuild(_.get(this.props.store.model, 'productItems', []));
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.amount,
					(value, previousValue) => {
						if (item.amount !== previousValue) {
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.applyLimitValidityPeriodId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'applyLimitValidityPeriodId', 'periodicity.month');
						this.props.store.setValue(this.props.store.model, 'applyLimitValidityPeriod', {
							id: 'periodicity.month',
							keyId: 'month',
							ownerId: 'periodicity',
							name: { ru: 'Месяц' },
							description: null,
							priority: 6,
							predefined: true,
							predefinedName: 'PeriodicityMonth',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						let title = _.cloneDeep(this.props.navigationRoute.title);
						if (value) {
							if (this.props.match.params.elementId !== 'new') {
								runInAction(() => {
									this.props.navigationRoute.title = title + '*';
								});
							}
						} else {
							runInAction(() => {
								this.props.navigationRoute.title = title;
							});
						}
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: SubscriptionPlanType) {
		if (model.applyLimitValidityPeriodId === 'periodicity.month' && _.isEmpty(model.applyLimitValidityPeriod)) {
			model.applyLimitValidityPeriod = _.cloneDeep(this.props.store.model.applyLimitValidityPeriod);
		}
		return model;
	}
	prepareModelForPost(model: SubscriptionPlanType) {
		delete model.applyLimitValidityPeriod;
		_.forEach(model.productItems, (item) => {
			delete item.unit;
			delete item.feature;
			delete item.validityPeriod;
			delete item.product;
			delete item.option;
		});
		_.forEach(model.periodItems, (item) => {
			delete item.validityPeriod;
		});
		runInAction(() => {
			model.productItemList = model.productItems;
		});
		runInAction(() => {
			model.periodItemList = model.periodItems;
		});
		return model;
	}
	validate() {
		let productItemsGrouped = _.chain(this.props.store.model.productItems).groupBy('productId').value();
		return productItemsGrouped['null'] === undefined && !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	saveSelected(periodItem: any) {
		const currentPriceTypeItemsMap = _.chain(this.props.store.selectedPeriodItems).cloneDeep().keyBy('id').value();
		runInAction(() => {
			if (currentPriceTypeItemsMap[periodItem.id] === undefined) {
				this.props.store.selectedPeriodItems.push(periodItem);
			} else {
				this.props.store.selectedPeriodItems.splice(this.props.store.selectedPeriodItems.indexOf(periodItem), 1);
			}
		});
	}
	deletePeriodItems(selectedItems: Array<any>) {
		const currentPriceTypeItemsMap = _.chain(this.props.store.model.periodItems).cloneDeep().keyBy('id').value();
		const buffer: Array<any> = [];
		_.forEach(selectedItems, (item) => {
			if (currentPriceTypeItemsMap[item.id] !== undefined) {
				buffer.push(item);
				runInAction(() => {
					_.get(this.props.store, 'model.periodItems', []).splice(_.get(this.props.store, 'model.periodItems', []).indexOf(item), 1);
				});
			}
		});
		_.forEach(buffer, (item) => {
			runInAction(() => {
				this.props.store.selectedPeriodItems.splice(this.props.store.selectedPeriodItems.indexOf(item), 1);
			});
		});
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store, 'isChanged', true);
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', items);
		this.makeReactions();
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/subscriptionPlans/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<SubscriptionPlanForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						subscriptionPlanFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						deletePeriodItems={this.deletePeriodItems}
						saveSelected={this.saveSelected}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default SubscriptionPlanFormContainer;
