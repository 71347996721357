import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CForm, CFormGroup, CLink } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { stringFormatter } from '../shared/Formatters/StringFormatter';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import { MatchType } from '../shared/support/modelTypes';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	resetPasswordClient(): any;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class ResetPassword extends Component<PropsType> {
	constructor(props: PropsType) {
		super(props);
		this.chooseValidInput = this.chooseValidInput.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		document.title = 'Восстановление пароля';
	}
	chooseValidInput() {
		let inputs = {
			master: (
				<NatValueInput<string>
					object={this.injected.userStore.userRecoveryPassword}
					property="recoveryEmail"
					placeholder="Введите почту"
					type="text"
					formatter={stringFormatter(true)}
					size="lg"
					autoComplete="off"
					useHandleEnter={false}
					label="Почта"
					loginInput
					invalid={_.isEmpty(this.injected.userStore.userRecoveryPassword.recoveryEmail)}
				/>
			),
			tenant: (
				<NatValueInput<string>
					object={this.injected.userStore.userRecoveryPassword}
					property="recoveryEmail"
					placeholder="Введите логин"
					type="text"
					formatter={stringFormatter(true)}
					size="lg"
					autoComplete="off"
					useHandleEnter={false}
					label="Логин"
					loginInput
					invalid={_.isEmpty(this.injected.userStore.userRecoveryPassword.recoveryEmail)}
				/>
			)
		};
		if (this.injected.directoryStore.models.apiData.mode) {
			return inputs[this.injected.directoryStore.models.apiData.mode];
		} else {
			return inputs['master'];
		}
	}
	render() {
		return (
			<div className="justify-content-center align-items-center c-app nat__gardient__bg">
				<CCard className="d-flex flex-column align-items-center nat__login nat__card__wrapper nat__borders__2-75">
					<CCardHeader className="border-0 font-weight-bold bg-transparent">
						<h4 className="m-0">Забыли пароль?</h4>
					</CCardHeader>
					<CCardBody className="mx-4 pb-0">
						<CForm className="w-100">
							{this.chooseValidInput()}
							<CFormGroup className="form-actions d-flex flex-column my-4">
								<CButton
									size="lg"
									className="nat__button"
									onClick={() => {
										this.props
											.resetPasswordClient()
											.then(() => {
												this.props.history.push('/users/login');
												catalog.showAlert('Письмо со ссылкой на восстановление пароля отправлено на почту!');
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
									}}>
									Подтвердить
								</CButton>
							</CFormGroup>
						</CForm>
					</CCardBody>
					<CCardFooter className="d-flex flex-column border-0 bg-transparent pt-0">
						{this.injected.directoryStore.models.apiData.mode === 'tenant' && (
							<div>
								{'Еще нет учетной записи? '}
								<CLink to="/users/registration">Регистрация</CLink>
							</div>
						)}
						<CLink to="/users/login">Вход</CLink>
					</CCardFooter>
				</CCard>
			</div>
		);
	}
}

export default withRouter(ResetPassword);
