import _ from 'lodash';
import { IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, SamplingSchemePresetParameterFieldItemType } from '../../shared/support/modelTypes';
import Report from './Report';
import { ReportStoreType } from './ReportStore';

interface PropsType extends FormContainerPropsType<ReportStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class ReportContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.generateTitle = this.generateTitle.bind(this);
		this.execute = this.execute.bind(this);
		this.buildColumns = this.buildColumns.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {}
	loadPage(elementId: string) {
		document.title = 'Отчет';
		let filter = {
			where: { id: elementId },
			include: this.props.store.filterInclude,
			limit: 1
		};
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					const model = data[0];
					catalog.generateTitle(this.props.navigationRoute, '', '', catalog.renderCatalogName(model));
					let filters: Array<SamplingSchemePresetParameterFieldItemType> = [];
					_.forEach(model.samplingScheme.parameterFieldItemList, (item: any) => {
						let obj: any = {
							parameterFieldValue: item,
							valueType: item.fieldValue.typeId,
							valueId: '',
							valueRelationType: item.fieldValue.relationType,
							valueRelationValue: ''
						};
						filters.push(obj);
					});
					this.props.store.setValue(this.props.store, 'filters', filters);
					this.props.store.setValue(this.props.store, 'report', model);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
		let reportPresetFilter = {
			where: { reportId: elementId },
			include: ['report', 'samplingSchemePreset'],
			limit: 1
		};
		controller
			.findAll('reportPresets', reportPresetFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'reportPresets', data);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	fillEmptyRelations(model: any) {
		return model;
	}
	prepareModelForPost(model: any) {
		return model;
	}
	validate() {
		return true;
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	generateTitle() {
		return '';
	}
	execute() {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		const presets = _.cloneDeep(this.props.store.reportPresets);
		const filters = _.cloneDeep(this.props.store.filters);
		if (presets[0]) {
			presets[0].samplingSchemePreset.parameterFieldItemList = filters;
			presets[0].samplingSchemePresetValue = presets[0].samplingSchemePreset;
			controller
				.execute(this.props.store.report, presets[0])
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.buildColumns(data);
					}
					this.props.store.setValue(this.props.store, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		}
	}
	buildColumns(reportsData: any) {
		let result: Array<any> = [];
		_.transform(
			reportsData.columns,
			(result: any, value: any) => {
				let column: any = {
					label: value.name[this.injected.directoryStore.models.language],
					id: value.id
				};
				if (value.typeId === 'value_types.relation') {
					column.field = value.relationPropertyName;
					column.cellRenderer = ({ data }: { data: any; column: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{!_.isEmpty(value.relationPropertyName) && catalog.renderCatalogName(data[value.relationPropertyName])}</div>;
					};
				} else {
					column.field = value.propertyName;
				}
				column.getValue = ({ value }: { value: any }) => {
					if (_.isObject(value)) {
						if (!_.isEmpty(value)) {
							value.toString = () => {
								return catalog.renderCatalogName(value);
							};
						}
						return value.toString();
					} else {
						return value;
					}
				};
				result.push(column);
			},
			result
		);
		this.props.store.setValue(this.props.store, 'reportColumns', result);
		this.props.store.setValue(this.props.store, 'list', reportsData.rows);
	}
	clearFilters() {
		let filters: Array<SamplingSchemePresetParameterFieldItemType> = [];
		_.forEach(this.props.store.report.samplingScheme.parameterFieldItemList, (item: any) => {
			let obj: any = {
				parameterFieldValue: item,
				valueType: item.fieldValue.typeId,
				valueId: '',
				valueRelationType: item.fieldValue.relationType,
				valueRelationValue: ''
			};
			filters.push(obj);
		});
		this.props.store.setValue(this.props.store, 'searchText', '');
		this.props.store.setValue(this.props.store, 'filters', filters);
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				generateTitle={this.generateTitle}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/reports/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<Report
						removeNavigationRoute={this.props.removeNavigationRoute}
						reportStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
						execute={this.execute}
						clearFilters={this.clearFilters}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default ReportContainer;
