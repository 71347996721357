import { observable, action, makeObservable, set } from 'mobx';

export interface ReportsStoreType {
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	setValue(model: any, key: string, value: any): void;
}

class ReportsStore implements ReportsStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			setValue: action
		});
	}

	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'reports';
	filterInclude = ['samplingScheme'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const reportsStore = new ReportsStore();

export default ReportsStore;
