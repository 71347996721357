import CIcon from '@coreui/icons-react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatList from '../../shared/Components/NatList';
import NatListContainer from '../../shared/Components/NatListContainer';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import { PartnerSubscriptionOperationsListStoreType } from './PartnerSubscriptionOperationsListStore';

interface PropsType extends FormContainerPropsType<PartnerSubscriptionOperationsListStoreType> {}

interface StateType {}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class PartnerSubscriptionOperationsListContainer extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.createFilter = this.createFilter.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	resetSearchFields() {
		runInAction(() => {
			_.forEach(this.props.store.chipsFields.status.list, (item) => {
				item.isChecked = false;
			});
			this.props.store.chipsFields = {
				partner: {
					isRelation: true,
					id: null,
					chips: []
				},
				sequenceNumber: {
					id: null,
					chips: [],
					title: 'Номер'
				},
				periodAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true,
					property: 'periodAt'
				},
				periodAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false,
					property: 'periodAt'
				},
				deleted: {
					id: false,
					display: { isChecked: false, title: 'Отображать' },
					doNotDisplay: { isChecked: true, title: 'Не отображать' },
					chips: [
						{
							id: generateRandomId(),
							name: {
								ru: 'Удаленные: не отображать'
							}
						}
					],
					isBoolean: true,
					title: 'Удаленные'
				},
				amountGte: {
					isPrice: true,
					id: null,
					chips: [],
					isGte: true
				},
				amountLte: {
					isPrice: true,
					id: null,
					chips: [],
					isGte: false
				},
				status: {
					isRelation: true,
					id: null,
					chips: [],
					list: this.props.store.chipsFields.status.list
				}
			};
		});
	}
	createFilter() {
		// if (!_.isEmpty(partnerSubscriptionOperationsSearchFields.name)) {
		// 	or = [
		// 		{
		// 			sequenceNumber: {
		// 				like: partnerSubscriptionOperationsSearchFields.name
		// 			}
		// 		}
		// 	];
		// 	filter.where.or = or;
		// }
	}
	render() {
		return (
			<NatListContainer
				store={this.props.store}
				createFilter={this.createFilter}
				resetSearchFields={this.resetSearchFields}
				defaultFilterWhere={{ deleted: false }}
				navigationRoute={this.props.navigationRoute}
				index={this.props.index}
				ownerId="partner_subscription_operation_statuses"
				enumerationItems="status">
				{(childrenProps) => (
					<NatList
						name="partnerSubscriptionOperations"
						store={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						columns={[
							{
								id: 'checkbox',
								pinned: true,
								className: '',
								width: '54px',
								minResizeWidth: 0,
								maxResizeWidth: null,
								resizable: false,
								visible: true,
								headerCellRenderer: () => {
									return <div></div>;
								},
								cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
									return (
										<div className="rgt-cell-inner ml-0 mr-0">
											<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
										</div>
									);
								}
							},
							{
								id: 1,
								field: 'posted',
								label: '',
								width: '70px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div className="rgt-cell-inner">
											<CIcon src={catalog.pickupDocumentStateImage(data)} size="sm" />
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 2,
								field: 'sequenceNumber',
								label: 'Номер',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{data.sequenceNumber}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 3,
								field: 'periodAt',
								label: 'Дата документа',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{listDateTimeFormatter.renderValue(data.periodAt)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 4,
								field: 'partner',
								label: 'Клиент',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.partner)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 5,
								field: 'amount',
								label: 'Сумма',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary text-right';
												} else {
													return 'rgt-cell-inner rgt-text-truncate text-right';
												}
											})()}>
											{catalog.renderPrice(data)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 6,
								field: 'status',
								label: 'Статус',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.status)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 7,
								field: 'type',
								label: 'Тип',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.type)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 8,
								field: 'note',
								label: 'Примечание',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary text-right';
												} else {
													return 'rgt-cell-inner rgt-text-truncate text-right';
												}
											})()}>
											{data.note}
										</div>
									);
								},
								sort: () => {}
							}
						]}>
						<>
							<NatRelationInput
								object={this.props.store.chipsFields.partner}
								property="id"
								relation="relation"
								placeholder="Выберите клиента"
								type="text"
								size="sm"
								formatter={relationFormatter(true)}
								pluralName="partners"
								filterWhere={{ elementType: 0, deleted: false }}
								autoComplete="off"
								label="Клиент"
							/>
							<NatValueInput<number> object={this.props.store.chipsFields.amountGte} property="id" placeholder="Введите сумму" type="text" pattern="^([0-9]*[.,])?[0-9]*$" formatter={priceFormatter} size="sm" label="Сумма от" />
							<NatValueInput<number> object={this.props.store.chipsFields.amountLte} property="id" placeholder="Введите сумму" type="text" pattern="^([0-9]*[.,])?[0-9]*$" formatter={priceFormatter} size="sm" label="до" />
						</>
					</NatList>
				)}
			</NatListContainer>
		);
	}
}

export default PartnerSubscriptionOperationsListContainer;
