import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { models } from '../../shared/support/NatModels';
import { routesMap } from '../../shared/support/Routes';
import { FormContainerPropsType, PartnerType } from '../../shared/support/modelTypes';
import PartnerForm from './PartnerForm';
import { PartnerFormStoreType } from './PartnerFormStore';

interface PropsType extends FormContainerPropsType<PartnerFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class PartnerFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.executeAfterFinding = this.executeAfterFinding.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (key !== 'ownershipType' && key !== 'type' && key !== 'gender' && key !== 'priceType' && key !== 'contract') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.ownershipTypeId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'ownershipTypeId', 'partner_ownership_types.individual');
						this.props.store.setValue(this.props.store.model, 'ownershipType', {
							description: null,
							id: 'partner_ownership_types.individual',
							keyId: 'individual',
							name: { ru: 'Физическое лицо' },
							ownerId: 'partner_ownership_types',
							predefined: true,
							predefinedName: 'PartnerOwnershipTypeIndividual',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.typeId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'typeId', 'partner_types.individual');
						this.props.store.setValue(this.props.store.model, 'type', {
							description: null,
							id: 'partner_types.individual',
							keyId: 'individual',
							name: { ru: 'Физическое лицо' },
							ownerId: 'partner_types',
							predefined: true,
							predefinedName: 'PartnerTypesIndividual',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => any, elementType?: number, parentId?: string) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			if (!_.isEmpty(parentId)) {
				let filter = {
					where: { id: parentId },
					limit: 1
				};
				controller
					.findAll(this.props.store.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.store.setValue(this.props.store.model, 'parent', data[0]);
							this.props.store.setValue(this.props.store.model, 'parentId', data[0].id);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
			this.props.store.setValue(this.props.store.model, 'elementType', Number(elementType));
			if (Number(elementType) === 0) {
				catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			}
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: PartnerType) {
		if (model.ownershipTypeId === 'partner_ownership_types.individual' && _.isEmpty(model.ownershipType)) {
			model.ownershipType = _.cloneDeep(this.props.store.model.ownershipType);
		}
		if (model.typeId === 'partner_types.individual' && _.isEmpty(model.type)) {
			model.type = _.cloneDeep(this.props.store.model.type);
		}
		if (!_.isEmpty(model.birthdate)) {
			model.birthdate = new Date(model.birthdate as any);
		}
		return model;
	}
	prepareModelForPost(model: PartnerType) {
		delete model.priceType;
		delete model.ownershipType;
		delete model.type;
		delete model.gender;
		delete model.contract;
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	executeAfterFinding(model: PartnerType) {
		if (model.elementType === 0) {
			this.props.store.setValue(this.props.store, 'isLoading', true);
			controller
				.fetchAvailableContracts(model)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.store.setValue(this.props.store, 'list', data);
					}
					return controller.fetchPartnerDocuments(model);
				})
				.then((data) => {
					if (!_.isEmpty(data)) {
						let documentsList: Array<any> = [];
						_.forEach(data, (itm) => {
							if (routesMap[`/${models[itm.documentType].plural}/:elementId`] !== undefined) {
								if (!_.isEmpty(itm.document)) {
									itm.document.url = `/${models[itm.documentType].plural}/${itm.document.id}`;
								}
							}
							if (!_.isEmpty(itm.document)) {
								documentsList.push(itm.document);
							}
						});
						this.props.store.setValue(this.props.store, 'secondList', documentsList);
					}
					this.props.store.setValue(this.props.store, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.props.store.setValue(this.props.store, 'isLoading', false);
				});
		}
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				executeAfterFinding={this.props.store.model.elementType === 0 && this.executeAfterFinding}
				parentRoute="/partners/"
				handlers={this.props.handlers}>
				{(childrenProps) => <PartnerForm removeNavigationRoute={this.props.removeNavigationRoute} partnerFormStore={this.props.store} navigationRoute={this.props.navigationRoute} goTo={this.props.goTo} childrenProps={childrenProps} />}
			</NatDirectoryFormContainer>
		);
	}
}

export default PartnerFormContainer;
