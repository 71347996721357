import { observable, action, makeObservable, set } from 'mobx';

export interface NatImageManagerStoreType {
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<string>;
	filterSkip: number;
	currentPage: number;
	itemsPerPage: number;
	filterOrder: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	responseCode: number;
	deleted: boolean;
	isLoading: boolean;
	selectedImage: any;
	setValue(model: any, key: string, value: any): void;
}

class NatImageManagerStore implements NatImageManagerStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterSkip: observable,
			currentPage: observable,
			itemsPerPage: observable,
			filterOrder: observable,
			pluralName: observable,
			collectedModels: observable,
			responseCode: observable,
			deleted: observable,
			isLoading: observable,
			selectedImage: observable,
			setValue: action
		});
	}
	list = [];
	filterWhere = { deleted: false };
	filterInclude = ['metadataItems'];
	filterSkip = 0;
	currentPage = 0;
	itemsPerPage = 50;
	filterOrder = ['createdAt DESC'];
	pluralName = 'files';
	collectedModels = [] as Array<any>;
	responseCode = 0;
	deleted = false;
	isLoading = false;
	selectedImage = {};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natImageManagerStore = new NatImageManagerStore();

export default NatImageManagerStore;
