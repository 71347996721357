import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalHeader, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TreeMenu from 'react-simple-tree-menu';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatTreeItemComponent from '../Components/NatTreeItemComponent';
import { stringFormatter } from '../Formatters/StringFormatter';
import NatDefaultCheckbox from '../Inputs/NatDefaultCheckBox';
import NatValueInput from '../Inputs/NatValueInput';
import { catalog } from '../support/Catalog';
import { natOpenTriangle, natCloseTriangle } from '../support/NatIcons';
import { NatSelectionStoreType } from './NatSelectionStore';

interface PropsType extends RouteComponentProps {
	toggleNatSelection(index: number): void;
	tabNumber: number;
	modals: Array<number>;
	buildSelectedItems(arrayIds: Array<string>, store?: NatSelectionStoreType): void;
	saveSelectedItems(store?: NatSelectionStoreType): void;
	filterWhere: any;
	filterOrder: Array<string>;
	filterInclude: Array<any>;
	filterGroupOrder?: Array<string>;
	filterGroupWhere?: any;
	pluralName: string;
	type?: string;
	hierarchical?: boolean;
	resetData?: () => void;
	property: string;
	columns?: Array<any>;
	controlButtons?: Array<ButtonItem>;
	singleSelect?: boolean;
	treeSelect?: boolean;
	showCreateButton?: boolean;
	createButton?: ButtonItem;
	fetchData?: (pluralName?: string, filter?: any) => any;
}

interface StateType {
	querySelector: any;
	timer: any;
	columns: Array<any>;
	groupId: string | null;
	idArray: Array<string>;
	filterOrder: Array<string>;
	filterInclude: Array<any>;
	filterWhere: any;
}

interface InjectedProps extends PropsType {
	natSelectionStore: NatSelectionStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
	to?: string;
}

@inject('userStore', 'directoryStore', 'natSelectionStore')
@observer
class NatSelection extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	treeRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.treeRef = React.createRef();
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.resetData = this.resetData.bind(this);
		this.sortList = this.sortList.bind(this);
		this.showList = this.showList.bind(this);
		this.returnToRoot = this.returnToRoot.bind(this);
		this.buildTree = this.buildTree.bind(this);
		this.showNestedElements = this.showNestedElements.bind(this);
		this.toggleNodeByIcon = this.toggleNodeByIcon.bind(this);
		this.toggleNodeByNode = this.toggleNodeByNode.bind(this);
		this.makeTooltips = this.makeTooltips.bind(this);
		this.selectItem = this.selectItem.bind(this);
		this.makeTreeReactions = this.makeTreeReactions.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.reactions = [];
		this.state = {
			querySelector: null,
			timer: null,
			groupId: null,
			idArray: [],
			filterOrder: this.props.filterOrder,
			filterWhere: this.props.filterWhere,
			filterInclude: this.props.filterInclude,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
							</div>
						);
					}
				},
				{
					id: 1,
					width: '400px',
					field: 'name',
					label: 'Наименование',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner rgt-text-truncate">{catalog.renderCatalogName(data)}</div>;
					}
				}
			]
		};
		if (this.props.modals.includes(this.props.tabNumber)) {
			this.reactions.push(
				reaction(
					() => this.injected.natSelectionStore.search,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.state.querySelector.scrollTo(0, 0);
							clearTimeout(this.state.timer);
							this.setState({
								timer: setTimeout(this.showList, 500)
							});
						}
					}
				)
			);
		}
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		if (!this.props.treeSelect) {
			this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
			this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
			this.setState({
				querySelector: this.myRef.current.querySelector('.rgt-container')
			});
		}
		if (this.props.modals.includes(this.props.tabNumber)) {
			if (!this.props.treeSelect) {
				let filter = {
					where: this.state.filterWhere,
					include: this.state.filterInclude,
					skip: this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage,
					limit: this.injected.natSelectionStore.itemsPerPage,
					order: this.state.filterOrder
				};
				this.fetchData(this.props.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', data);
						}
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
					});
			}
			if (this.props.hierarchical || this.props.treeSelect) {
				let groupFilter = {
					where: this.props.filterGroupWhere,
					order: this.props.filterGroupOrder
				};
				this.fetchData(this.props.pluralName, groupFilter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'groups', data);
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'groupsTree', this.buildTree(this.injected.natSelectionStore.groups));
						} else {
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'groupsTree', this.buildTree([]));
						}
						this.makeTooltips(this.treeRef);
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		if (!this.props.treeSelect) {
			this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
			this.setState({
				querySelector: null
			});
		}
	}
	handleScrollToElement() {
		if (!this.props.treeSelect) {
			if (!this.props.fetchData) {
				let scrollTop = this.state.querySelector.scrollTop;
				let scrollHeight = this.state.querySelector.scrollHeight;
				let clientHeight = this.state.querySelector.clientHeight;
				let arr = this.injected.natSelectionStore.list;
				let itemsPerPage = this.injected.natSelectionStore.itemsPerPage;
				let itemsListLength = arr.length;
				if (scrollTop >= scrollHeight - clientHeight && this.injected.natSelectionStore.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', this.injected.natSelectionStore.currentPage + 1);
					let skip = this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage;
					let filter = {
						where: this.state.filterWhere,
						include: this.state.filterInclude,
						skip,
						limit: this.injected.natSelectionStore.itemsPerPage,
						order: this.state.filterOrder
					};
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
					this.fetchData(this.props.pluralName, filter)
						.then((data) => {
							if (!_.isEmpty(data)) {
								this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', this.injected.natSelectionStore.list.concat(data));
							}
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
						})
						.catch((error) => {
							catalog.handleNatError(error);
							this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
						});
				}
			}
		}
	}
	showList() {
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', 0);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
		let skip = this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage;
		let filter = {
			where: this.state.filterWhere,
			include: this.state.filterInclude,
			skip,
			limit: this.injected.natSelectionStore.itemsPerPage,
			order: this.state.filterOrder
		};
		if (!_.isEmpty(this.injected.natSelectionStore.search)) {
			filter.where[`name.${this.injected.directoryStore.models.language}`] = { like: this.injected.natSelectionStore.search, options: 'i' };
		} else {
			delete filter.where[`name.${this.injected.directoryStore.models.language}`];
		}
		this.fetchData(this.props.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', data);
				} else {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
				}
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			});
	}
	sortList(colId: number, isAsc: boolean) {
		let order: Array<any> = [];
		if (colId !== null) {
			const groupedColumns = _.groupBy(this.state.columns, 'id');
			const sortBy = isAsc ? 'ASC' : 'DESC';
			order = [`${groupedColumns[colId][0].field} ${sortBy}`];
		} else {
			order = ['createdAt DESC'];
		}
		this.state.querySelector.scrollTo(0, 0);
		this.setState({
			filterOrder: order
		});
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', 0);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
		let skip = this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage;
		let filter = {
			where: this.state.filterWhere,
			include: this.state.filterInclude,
			skip,
			limit: this.injected.natSelectionStore.itemsPerPage,
			order
		};
		this.fetchData(this.props.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', data);
				}
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			});
	}
	showNestedElements(item: any) {
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'activeKey', item.key);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'focusKey', item.key);
		if (!_.isEmpty(item.object.parentId)) {
			let idArray = [] as Array<string>;
			let groupByParentId = _.groupBy(this.injected.natSelectionStore.groups, (value) => {
				return value.parentId || 'null';
			});
			let fetchChildGroupIds = (item) => {
				idArray.push(item.id);
				let childGroupList = groupByParentId[item.id];
				if (childGroupList && childGroupList.length > 0) {
					for (let childGroup of childGroupList) {
						fetchChildGroupIds(childGroup);
					}
				}
			};
			fetchChildGroupIds(item.object);
			this.setState({
				groupId: item.object.id,
				idArray
			});
			this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
			this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
			this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', 0);
			let skip = this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage;
			let filter = {
				where: this.state.filterWhere,
				include: this.state.filterInclude,
				skip,
				limit: this.injected.natSelectionStore.itemsPerPage,
				order: this.state.filterOrder
			};
			filter.where.parentId = { inq: idArray } as any;
			this.fetchData(this.props.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', data);
					} else {
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
					}
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
				});
		} else {
			this.returnToRoot();
		}
	}
	returnToRoot() {
		this.setState({
			groupId: null
		});
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', true);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', 0);
		let skip = this.injected.natSelectionStore.currentPage * this.injected.natSelectionStore.itemsPerPage;
		let filterWhere = _.cloneDeep(this.state.filterWhere);
		delete filterWhere.parentId;
		this.setState({
			filterWhere
		});
		let filter = {
			where: filterWhere,
			include: this.state.filterInclude,
			skip,
			limit: this.injected.natSelectionStore.itemsPerPage,
			order: this.state.filterOrder
		};
		this.fetchData(this.props.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', data);
				} else {
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
				}
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'isLoading', false);
			});
	}
	toggleNodeByIcon(item: any) {
		const openNodes = _.cloneDeep(this.injected.natSelectionStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = openNodes.filter((openNode) => openNode !== item.key);
		} else {
			const nodesMap = _.chain(this.injected.natSelectionStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.injected.natSelectionStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'openNodes', newOpenNodes);
		setTimeout(() => {
			this.makeTooltips(this.treeRef);
		}, 0);
	}
	toggleNodeByNode(item: any) {
		const openNodes = _.cloneDeep(this.injected.natSelectionStore.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = [...openNodes];
		} else {
			const nodesMap = _.chain(this.injected.natSelectionStore.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.injected.natSelectionStore.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'openNodes', newOpenNodes);
	}
	resetData() {
		if (this.props.resetData !== undefined) {
			this.props.resetData();
		}
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'groups', []);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'list', []);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedItems', []);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedIds', []);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'currentPage', 0);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'search', '');
	}
	makeTreeReactions(item: any) {
		this.reactions.push(
			reaction(
				() => item.isChecked,
				(value) => {
					if (value) {
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedItems', [item]);
						_.forEach(this.injected.natSelectionStore.groups, (itm) => {
							if (itm.id !== item.id) {
								itm.isChecked = false;
							}
						});
					} else {
						this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedItems', []);
					}
				}
			)
		);
	}
	buildTree(list: Array<any>) {
		let rootId = generateRandomId();
		let groups: Array<any> = list;
		for (let item of groups) {
			if (item.parentId === null) {
				item.parentId = rootId;
			}
		}
		groups.push({
			parentId: null,
			id: rootId,
			name: { ru: 'Корень' }
		});
		let groupByParentId = _.groupBy(groups, (value) => {
			return value.parentId || 'null';
		});
		let handleGroup = (item, nodes) => {
			let treeItem = {
				label: item.name[this.injected.directoryStore.models.language],
				key: item.id,
				object: item,
				nodes: []
			};
			if (this.props.treeSelect) {
				item.isChecked = false;
				this.makeTreeReactions(item);
			}
			nodes.push(treeItem);
			let childGroupList = groupByParentId[item.id];
			if (childGroupList && childGroupList.length > 0) {
				for (let childGroup of childGroupList) {
					handleGroup(childGroup, treeItem.nodes);
				}
			}
		};
		let result = [] as Array<any>;
		let rootGroupList = _.get(groupByParentId, 'null');
		if (rootGroupList && rootGroupList.length > 0) {
			for (let rootGroup of rootGroupList) {
				handleGroup(rootGroup, result);
			}
		}
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'openNodes', [rootId]);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore.treeState, 'nodes', [{ key: rootId }]);
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'groups', list);
		return result;
	}
	makeTooltips(ref: any) {
		_.forEach(ref.current.querySelectorAll('li'), (item) => {
			let text = item.textContent.slice(item.textContent.indexOf('+') + 1);
			if (_.isEmpty(item.title)) {
				item.title = text;
			}
		});
	}
	selectItem(data: any) {
		let selectedIds: Array<string> = _.cloneDeep(this.injected.natSelectionStore.selectedIds);
		let selectedIdsMap = _.chain(selectedIds)
			.keyBy((item) => {
				return item;
			})
			.value();

		if (this.props.singleSelect) {
			selectedIds = [];
		}
		if (selectedIdsMap[data.id] === undefined) {
			selectedIds.push(data.id);
		} else {
			selectedIds.splice(selectedIds.indexOf(data.id), 1);
		}
		this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedIds', selectedIds);
		this.props.buildSelectedItems(selectedIds, this.injected.natSelectionStore);
	}
	fetchData(pluralName: string, filter: any) {
		if (this.props.fetchData) {
			return this.props.fetchData(pluralName, filter);
		} else {
			return controller.findAll(this.props.pluralName, filter);
		}
	}
	render() {
		let controlButtons: Array<ButtonItem> = [];
		if (this.props.controlButtons !== undefined) {
			controlButtons = this.props.controlButtons;
		} else {
			controlButtons = [
				{
					title: this.props.singleSelect ? 'ВЫБРАТЬ' : 'ПЕРЕНЕСТИ',
					onClick: () => {
						this.props.saveSelectedItems(this.injected.natSelectionStore);
						this.props.toggleNatSelection(this.props.tabNumber);
						this.resetData();
					}
				},
				{
					title: 'ОТМЕНА',
					onClick: () => {
						this.props.toggleNatSelection(this.props.tabNumber);
						this.resetData();
					}
				}
			];
			if (this.props.showCreateButton) {
				if (this.props.createButton) {
					controlButtons = [this.props.createButton].concat(controlButtons);
				}
			}
		}
		return ReactDOM.createPortal(
			<CModal
				show={this.props.modals.includes(this.props.tabNumber)}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__product__selection__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return (
									<form className="d-flex w-100">
										{_.map(controlButtons, (item, index) => {
											if (!item.dontShow) {
												return (
													<CButton
														key={index}
														type="button"
														size="sm"
														className="mr-1 nat__button__hover"
														to={item.to}
														onClick={(e) => {
															item.onClick(e);
														}}>
														{item.title}
													</CButton>
												);
											}
										})}
									</form>
								);
							} else {
								return (
									<CDropdown>
										<CDropdownToggle caret className="nat__button__hover">
											МЕНЮ
										</CDropdownToggle>
										<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CDropdownItem
															key={index}
															to={item.to}
															onClick={(e) => {
																item.onClick(e);
															}}>
															{item.title}
														</CDropdownItem>
													);
												}
											})}
										</CDropdownMenu>
									</CDropdown>
								);
							}
						})()}
					</div>
					{!this.props.treeSelect && (
						<div className="d-flex mr-4 search__height ml-1">
							<NatValueInput<string> object={this.injected.natSelectionStore} property="search" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" />
						</div>
					)}
				</CModalHeader>
				{(() => {
					if (!this.props.treeSelect) {
						return (
							<div className="d-flex flex-column default__nat__card__body__height">
								<div
									className={(() => {
										if (this.injected.directoryStore.models.windowSize >= 1200) {
											return 'h-100 d-flex m-4';
										} else {
											return 'flex-column d-flex m-4 nat__modal__tree__mobile h-100';
										}
									})()}>
									{this.props.hierarchical && (
										<div className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal__tree nat__tree__wrapper nat__borders__left__2-75' : 'nat__modal__tree nat__card__wrapper nat__borders__2-75 mb-4'}>
											<TreeMenu
												activeKey={this.injected.natSelectionStore.treeState.activeKey}
												focusKey={this.injected.natSelectionStore.treeState.focusKey}
												openNodes={this.injected.natSelectionStore.treeState.openNodes}
												data={this.injected.natSelectionStore.groupsTree}
												hasSearch={false}>
												{({ items }) => (
													<>
														<ul className="rstm-tree-item-group mb-0 p-3" ref={this.treeRef}>
															{items.map((item) => (
																<NatTreeItemComponent
																	key={item.key}
																	level={item.level}
																	label={item.label}
																	isOpen={item.isOpen}
																	hasNodes={item.hasNodes}
																	active={item.active}
																	focused={item.focused}
																	openedIcon={<CIcon src={natOpenTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
																	closedIcon={<CIcon src={natCloseTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
																	onClick={() => {
																		this.showNestedElements(item);
																		this.toggleNodeByNode(item);
																	}}
																	toggleNode={() => {
																		this.toggleNodeByIcon(item);
																	}}
																/>
															))}
														</ul>
													</>
												)}
											</TreeMenu>
										</div>
									)}
									<div
										className={(() => {
											if (this.props.hierarchical) {
												if (this.injected.directoryStore.models.windowSize >= 1200) {
													return 'nat__modal__table__wrapper nat__card__wrapper nat__borders__right__2-75 w-100 nat__table__hover';
												} else {
													return 'nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover nat__modal__table__mobile_2';
												}
											} else {
												return 'nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100';
											}
										})()}>
										<div className="nat__modal__table_1" ref={this.myRef}>
											<GridTable
												columns={this.props.columns !== undefined ? this.props.columns : this.state.columns}
												rows={this.injected.natSelectionStore.list}
												isPaginated={false}
												texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
												showSearch={false}
												showColumnVisibilityManager={false}
												showRowsInformation={false}
												onColumnsChange={() => {}}
												selectedRowsIds={_.map(this.injected.natSelectionStore.selectedItems, this.props.property)}
												onRowClick={({ data }: { data: any; column: any }) => {
													this.selectItem(data);
												}}
												onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
													this.sortList(colId, isAsc);
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						);
					} else {
						return (
							<div className="d-flex default__nat__card__body__height m-4">
								<div className="nat__modal__tree nat__modal__tree__fullscreen nat__card__wrapper nat__borders__2-75 w-100">
									<TreeMenu
										activeKey={this.injected.natSelectionStore.treeState.activeKey}
										focusKey={this.injected.natSelectionStore.treeState.focusKey}
										openNodes={this.injected.natSelectionStore.treeState.openNodes}
										data={this.injected.natSelectionStore.groupsTree}
										hasSearch={false}>
										{({ items }) => (
											<>
												<ul className="rstm-tree-item-group mb-0 p-3" ref={this.treeRef}>
													{items.map((item) => (
														<div className="d-flex" key={item.key}>
															<NatDefaultCheckbox object={item.object} property="isChecked" columnClassName="text-left" wrapperClassName="d-flex m-0 align-items-center ml-4 checkbox__width_0" row={true} />
															<NatTreeItemComponent
																key={item.key}
																className="w-100"
																level={item.level}
																label={item.label}
																isOpen={item.isOpen}
																hasNodes={item.hasNodes}
																active={item.active}
																focused={item.focused}
																openedIcon={<CIcon src={natOpenTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
																closedIcon={<CIcon src={natCloseTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
																onClick={() => {
																	this.toggleNodeByNode(item);
																}}
																toggleNode={() => {
																	this.toggleNodeByIcon(item);
																}}
															/>
														</div>
													))}
												</ul>
											</>
										)}
									</TreeMenu>
								</div>
							</div>
						);
					}
				})()}
				{this.injected.natSelectionStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>,
			document.querySelector('#modal') as Element
		);
	}
}

export default withRouter(NatSelection);
