import { observable, action, makeObservable, set } from 'mobx';

import { SubscriptionOptionType, SubscriptionPlanType } from '../shared/support/modelTypes';

interface SubscriptionOptionItem {
	subscriptionOption: SubscriptionOptionType;
	validityPeriodId: string;
	validityPeriodCount: number;
	quantity: number;
	expiredAt: Date;
}

interface SubscriptionItem {
	quantity: number;
	subscriptionPlan: SubscriptionPlanType;
	validityPeriodId: string;
	validityPeriodCount: number;
	expiredAt: Date;
	subscriptionOptionItemList: Array<SubscriptionOptionItem>;
}

interface PartnerSubscriptionInfo {
	subscriptionItemList: Array<SubscriptionItem>;
	periodAt: Date | null;
}
export interface NatPartnerSubscriptionInfoStoreType {
	partnerSubscriptionInfo: PartnerSubscriptionInfo;
	isLoading: boolean;
	setValue(model: any, key: string, value: any): void;
}

class NatPartnerSubscriptionInfoStore implements NatPartnerSubscriptionInfoStoreType {
	constructor() {
		makeObservable(this, {
			partnerSubscriptionInfo: observable,
			isLoading: observable,
			setValue: action
		});
	}
	partnerSubscriptionInfo = {
		periodAt: null,
		subscriptionItemList: []
	};
	isLoading = false;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natPartnerSubscriptionInfoStore = new NatPartnerSubscriptionInfoStore();

export default NatPartnerSubscriptionInfoStore;
