import { CFormGroup, CLabel, CSwitch } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType extends ValueFieldPropsType {}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatCheckbox extends NatValueField<boolean, boolean, PropsType> {
	constructor(props: PropsType) {
		super(props);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			value: e.target.checked
		});
		this.setValue(this.parseValue(e.target.checked));
	}
	parseValue(value: boolean): boolean {
		return value;
	}

	renderValue(value: boolean): boolean {
		return value;
	}
	render() {
		return (
			<CFormGroup row className={this.injected.directoryStore.models.windowSize < 490 ? 'w-100 align-items-center nat__form__group flex-nowrap' : 'w-100 align-items-center nat__form__group'}>
				{!_.isEmpty(this.props.label) && (
					<CLabel
						className={(() => {
							if (this.props.label) {
								if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
									return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile_100';
								} else {
									return 'align-self-center text-truncate pl-3 m-0';
								}
							}
						})()}>
						{this.props.label}
					</CLabel>
				)}
				<div className="ml-auto mr-2">
					<CSwitch onChange={this.handleChange} checked={this.state.value} variant="3d" color="dark" size="sm" className="mt-1" disabled={this.props.disabled} />
				</div>
			</CFormGroup>
		);
	}
}

export default NatCheckbox;
