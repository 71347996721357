import { CButton, CCard, CCardBody, CCol, CForm, CFormGroup, CListGroup, CListGroupItem, CModal, CNav, CNavItem, CRow, CSpinner } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { v4 as generateRandomId } from 'uuid';

import { natiwiController } from '../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { listDateTimeFormatter } from '../shared/Formatters/ListDateTimeFormatter';
import { priceFormatter } from '../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../shared/Formatters/StringFormatter';
import NatPolymorphicInput from '../shared/Inputs/NatPolymorphicInput';
import NatRelationInput from '../shared/Inputs/NatRelationInput';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import { FormContainerPropsType } from '../shared/support/modelTypes';
import { UseLocation } from '../shared/support/useLocationHoC';
import NatShoppingCartStore, { NatShoppingCartStoreType } from './NatShoppingCartStore';

interface PropsType extends FormContainerPropsType<NatShoppingCartStoreType> {}

interface StateType {
	timer: any;
	isOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class NatShoppingCart extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	natShoppingCartStore: NatShoppingCartStoreType;
	tabRef: any;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.tabRef = React.createRef();
		this.resetReactions = this.resetReactions.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.generateShoppingCartItems = this.generateShoppingCartItems.bind(this);
		this.validateOptionInput = this.validateOptionInput.bind(this);
		this.toggleTabs = this.toggleTabs.bind(this);
		this.togglePeriod = this.togglePeriod.bind(this);
		this.getDefaultSubscriptionPlan = this.getDefaultSubscriptionPlan.bind(this);
		this.updateShoppingCart = this.updateShoppingCart.bind(this);
		this.checkoutShoppingCart = this.checkoutShoppingCart.bind(this);
		this.generateShoppingCartOptionItems = this.generateShoppingCartOptionItems.bind(this);
		this.updateShoppingCartProductItems = this.updateShoppingCartProductItems.bind(this);
		this.generateProductItemFields = this.generateProductItemFields.bind(this);
		this.fillPersonalAccount = this.fillPersonalAccount.bind(this);
		this.convertPartnerSubscriptionInfo = this.convertPartnerSubscriptionInfo.bind(this);
		this.toggle = this.toggle.bind(this);
		this.natShoppingCartStore = new NatShoppingCartStore();
		this.state = {
			timer: null,
			isOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetReactions();
		this.reactions.push(
			reaction(
				() => this.natShoppingCartStore.shoppingCart.paymentOptionId,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
						this.updateShoppingCart().then(() => {
							this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
						});
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natShoppingCartStore.shoppingCart.couponCode,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
						this.updateShoppingCart().then(() => {
							this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
						});
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.natShoppingCartStore.selectedSubscriptionPlan.id,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							let subscriptionPlan = _.get(this.natShoppingCartStore, 'selectedSubscriptionPlan.item.subscriptionPlan');
							let subscriptionItemListHead = _.head(this.natShoppingCartStore.partnerSubscriptionInfo.subscriptionItemList);
							if (subscriptionPlan) {
								if (subscriptionItemListHead) {
									if (subscriptionItemListHead.subscriptionPlan) {
										if (subscriptionItemListHead.subscriptionPlan.id === subscriptionPlan.id) {
											this.getDefaultSubscriptionPlan();
										} else {
											if (this.injected.directoryStore.models.shoppingCartMode === 'change') {
												if (subscriptionItemListHead.subscriptionPlan.renewable) {
													this.natShoppingCartStore.setValue(this.natShoppingCartStore.selectedSubscriptionPlan.item, 'periodItemList', [
														{
															validityPeriod: {
																name: { ru: `До конца срока (${listDateTimeFormatter.renderValue(this.natShoppingCartStore.partnerSubscriptionInfo.subscriptionItemList[0].expiredAt)})` }
															},
															validityPeriodCount: 0,
															validityPeriodId: null
														}
													]);
												}
											}
											this.togglePeriod(this.natShoppingCartStore.selectedSubscriptionPlan.item.periodItemList[0], true);
										}
									}
								}
								if (this.natShoppingCartStore.selectedSubscriptionPlan.item.subscriptionPlan) {
									if (this.natShoppingCartStore.selectedSubscriptionPlan.item.subscriptionPlan.productItems) {
										this.generateProductItemFields(this.natShoppingCartStore.selectedSubscriptionPlan.item.subscriptionPlan.productItems);
									}
								}
							}
							this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
							this.updateShoppingCartProductItems().then(() => {
								this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
							});
						} else {
							this.getDefaultSubscriptionPlan();
						}
					}
				}
			)
		);
		_.forEach(this.natShoppingCartStore.productItems, (item) => {
			if (item.mutableProductSet) {
				this.reactions.push(
					reaction(
						() => item.valueId,
						(value, previousValue) => {
							if (value !== previousValue) {
								let selectedSubscriptionPlan = _.get(this.natShoppingCartStore, 'selectedSubscriptionPlan.item.subscriptionPlan');
								if (selectedSubscriptionPlan) {
									let productItemsMap = _.chain(selectedSubscriptionPlan.productItems).keyBy('id').value();
									let productItem = productItemsMap[item.id];
									if (!_.isEmpty(productItem)) {
										if (item.valueType === 'value_types.number') {
											productItem.quantity = value;
										} else if (item.valueType === 'value_types.boolean') {
											if (value) {
												productItem.quantity = 1;
											} else {
												productItem.quantity = 0;
											}
										}
										clearTimeout(this.state.timer);
										this.setState({
											timer: setTimeout(() => {
												this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
												this.updateShoppingCartProductItems().then(() => {
													this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
												});
											}, 500)
										});
									}
								}
							}
						}
					)
				);
			} else {
				this.reactions.push(
					reaction(
						() => item.valueId,
						(value, previousValue) => {
							if (value !== previousValue) {
								let selectedSubscriptionPlan = _.get(this.natShoppingCartStore, 'selectedSubscriptionPlan.item.subscriptionPlan');
								if (selectedSubscriptionPlan) {
									let productItemsMap = _.chain(selectedSubscriptionPlan.productItems)
										.keyBy((item) => {
											return `${item.productId}-${item.productSetId}`;
										})
										.value();
									let productItem = productItemsMap[`${item.productId}-${null}`];
									if (!productItem) {
										let copyProductItem = _.cloneDeep(item.productItem);
										copyProductItem.productSetId = null;
										copyProductItem.id = generateRandomId();
										delete copyProductItem.productSet;
										runInAction(() => {
											_.get(selectedSubscriptionPlan, 'productItems', []).push(copyProductItem);
										});
										let updatedProductItemsMap = _.chain(selectedSubscriptionPlan.productItems)
											.keyBy((item) => {
												return `${item.productId}-${item.productSetId}`;
											})
											.value();
										productItem = updatedProductItemsMap[`${item.productId}-${null}`];
									} else {
										if (item.valueId === item.productItem.quantity) {
											let result: Array<any> = [];
											for (let productItem of _.get(selectedSubscriptionPlan, 'productItems', [])) {
												if (productItem.productSetId !== null) {
													result.push(productItem);
												} else {
													if (productItem.productId !== item.productId) {
														result.push(productItem);
													}
												}
											}
											runInAction(() => {
												selectedSubscriptionPlan.productItems = result;
											});
										}
									}
									if (item.valueType === 'value_types.number') {
										productItem.quantity = value - item.productItem.quantity;
									} else if (item.valueType === 'value_types.boolean') {
										if (value) {
											productItem.quantity = 1;
										} else {
											productItem.quantity = 0;
										}
									}
									clearTimeout(this.state.timer);
									this.setState({
										timer: setTimeout(() => {
											this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
											this.updateShoppingCartProductItems().then(() => {
												this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
											});
										}, 500)
									});
								}
							}
						}
					)
				);
			}
		});
		this.reactions.push(
			reaction(
				() => this.natShoppingCartStore.refillAmount,
				(value, previousValue) => {
					if (value !== previousValue) {
						clearTimeout(this.state.timer);
						this.setState({
							timer: setTimeout(() => {
								this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
								this.fillPersonalAccount().then(() => {
									this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
								});
							}, 500)
						});
					}
				}
			)
		);
	}
	componentDidMount() {
		this.toggle(true);
		const mode = this.props.query.get('mode');
		this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'shoppingCartMode', mode);
		this.convertPartnerSubscriptionInfo();
		this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
		natiwiController
			.fetchAvailableSubscriptionPlans({ fetchTrial: false })
			.then((data) => {
				if (!_.isEmpty(data)) {
					for (let item of data) {
						item.id = generateRandomId();
					}
					this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'availableSubscriptionPlans', data);
				}
				return natiwiController.fetchPaymentOptionList();
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					if (this.injected.directoryStore.models.shoppingCartMode !== 'personalAccount') {
						this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'paymentOptionList', data);
					} else {
						let paymentOptions: Array<any> = [];
						for (let item of data) {
							if (item.typeId !== 'payment_option_types.partner_settlement') {
								paymentOptions.push(item);
							}
						}
						this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'paymentOptionList', paymentOptions);
					}
				}
				return natiwiController.fetchShoppingCart();
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'shoppingCart', data);
				}
				this.makeReactions();
				if (this.injected.directoryStore.models.shoppingCartMode !== 'personalAccount') {
					this.getDefaultSubscriptionPlan();
				} else {
					return this.fillPersonalAccount();
				}
			})
			.then(() => {
				this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		this.toggle(false);
	}
	toggle(status: boolean) {
		this.setState({
			isOpen: status
		});
	}
	generateShoppingCartOptionItems(productItemList: Array<any>) {
		let result: Array<any> = [];
		let subscriptionItemListHead = _.head(this.natShoppingCartStore.partnerSubscriptionInfo.subscriptionItemList);
		if (subscriptionItemListHead) {
			if (!_.isEmpty(subscriptionItemListHead.subscriptionOptionItemList)) {
				let subscriptionOptionListMap = _.chain(subscriptionItemListHead.subscriptionOptionItemList)
					.keyBy((item: any) => {
						return item.subscriptionOption.id;
					})
					.value();
				for (let item of productItemList) {
					if (!_.isEmpty(subscriptionOptionListMap[item.optionId])) {
						if (subscriptionOptionListMap[item.optionId].quantity !== item.quantity) {
							let productItem: any = _.cloneDeep(item);
							if (productItem.productSetId) {
								productItem.quantity = productItem.quantity - subscriptionOptionListMap[item.optionId].quantity;
							}
							result.push(productItem);
						}
					}
				}
			}
		}
		let shoppingCartOptionItems: Array<any> = [];
		for (let item of result) {
			let shoppingCartProductItem = {
				validityPeriodCount: this.natShoppingCartStore.periodItem.validityPeriodCount,
				validityPeriodId: this.natShoppingCartStore.periodItem.validityPeriodId,
				productSetFeatureId: item.productSetFeatureId,
				productId: item.productId,
				optionId: item.optionId,
				featureId: item.featureId,
				id: generateRandomId(),
				productSetId: item.productSetId,
				quantity: item.quantity,
				unitId: item.unitId
			};
			if (shoppingCartProductItem.quantity !== 0) {
				shoppingCartOptionItems.push(shoppingCartProductItem);
			}
		}
		this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItemList', shoppingCartOptionItems);
		this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItems', shoppingCartOptionItems);
	}
	generateShoppingCartItems(productItemList: Array<any>) {
		let shoppingCartProductItems: Array<any> = [];
		for (let item of productItemList) {
			let shoppingCartProductItem = {
				validityPeriodCount: this.natShoppingCartStore.periodItem.validityPeriodCount,
				validityPeriodId: this.natShoppingCartStore.periodItem.validityPeriodId,
				productSetFeatureId: item.productSetFeatureId,
				productId: item.productId,
				optionId: item.optionId,
				featureId: item.featureId,
				id: generateRandomId(),
				productSetId: item.productSetId,
				quantity: item.quantity,
				unitId: item.unitId
			};
			if (shoppingCartProductItem.quantity !== 0) {
				shoppingCartProductItems.push(shoppingCartProductItem);
			}
		}
		this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItemList', shoppingCartProductItems);
		this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItems', shoppingCartProductItems);
	}
	generateProductItemFields(productItemList: Array<any>) {
		let productItems: Array<any> = [];
		for (let item of productItemList) {
			if (!_.isEmpty(item.option)) {
				let productItem: any = {
					id: item.id,
					productId: item.productId,
					name: catalog.renderCatalogName(item.option),
					readOnly: item.readOnly,
					productItem: item,
					mutableProductSet: true
				};
				if (item.productSet) {
					if (item.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
						productItem.mutableProductSet = false;
					}
				}
				productItem.valueType = item.option.typeId;
				if (item.option.typeId === 'value_types.boolean') {
					if (item.quantity === 0) {
						productItem.valueId = false;
					} else {
						productItem.valueId = true;
					}
				} else if (item.option.typeId === 'value_types.number') {
					productItem.valueId = item.quantity;
				} else if (item.option.typeId === 'value_types.string') {
					productItem.valueId = null;
					productItem.readOnly = true;
				}
				productItems.push(productItem);
			}
		}
		this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'productItems', []);
		this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'productItems', productItems);
		this.makeReactions();
	}
	resetReactions() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
	}
	validateOptionInput(data: any, value: any) {
		let availableSubscriptionPlansMap = _.chain(this.natShoppingCartStore.availableSubscriptionPlans).keyBy('id').value();
		let currentDefaultProductItemsMap = _.chain(availableSubscriptionPlansMap[this.natShoppingCartStore.selectedSubscriptionPlan.id as string].subscriptionPlan.productItems)
			.keyBy('id')
			.value();
		let result: any = {
			result: true
		};
		if (!_.isEmpty(data.productItem)) {
			if (data.productItem.quantityLimitMinByDefault) {
				if (!_.isEmpty(currentDefaultProductItemsMap[data.id])) {
					if (value < currentDefaultProductItemsMap[data.id].quantity) {
						result = {
							result: false
						};
						runInAction(() => {
							data.valueId = currentDefaultProductItemsMap[data.id].quantity;
						});
					} else {
						result = {
							result: true
						};
					}
				} else {
					result = {
						result: true
					};
				}
			} else {
				result = {
					result: true
				};
			}
		} else {
			result = {
				result: true
			};
		}
		return result;
	}
	toggleTabs(id: string) {
		if (this.tabRef.current !== null && this.tabRef.current !== undefined) {
			let tabs = this.tabRef.current.querySelectorAll('.nav-link');
			_.forEach(tabs, (item) => {
				if (id !== item.id) {
					item.classList.remove('active');
				} else {
					item.classList.add('active');
				}
			});
		}
	}
	togglePeriod(item: any, dontUpdateShoppingCart?: boolean) {
		if (item) {
			this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'periodItem', item);
			this.toggleTabs(String(item.validityPeriodCount + item.validityPeriodId));
			if (!dontUpdateShoppingCart) {
				this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
				this.updateShoppingCartProductItems().then(() => {
					this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
				});
			}
		}
	}
	updateShoppingCartProductItems() {
		let subscriptionPlan = _.get(this.natShoppingCartStore, 'selectedSubscriptionPlan.item.subscriptionPlan');
		let subscriptionItemListHead = _.head(this.natShoppingCartStore.partnerSubscriptionInfo.subscriptionItemList);
		if (subscriptionPlan) {
			if (!_.isEmpty(subscriptionPlan.productItems)) {
				if (this.injected.directoryStore.models.shoppingCartMode === 'change') {
					if (subscriptionItemListHead) {
						if (subscriptionItemListHead.subscriptionPlan) {
							if (subscriptionItemListHead.subscriptionPlan.id === subscriptionPlan.id) {
								this.generateShoppingCartOptionItems(subscriptionPlan.productItems);
							} else {
								this.generateShoppingCartItems(subscriptionPlan.productItems);
							}
						}
					} else {
						this.generateShoppingCartItems(subscriptionPlan.productItems);
					}
				} else {
					this.generateShoppingCartItems(subscriptionPlan.productItems);
				}
			}
		}
		return this.updateShoppingCart();
	}
	getDefaultSubscriptionPlan() {
		const update = this.props.query.get('update');
		let availableSubscriptionPlansMap = _.chain(this.natShoppingCartStore.availableSubscriptionPlans)
			.keyBy((item) => {
				return item.subscriptionPlan.id;
			})
			.value();
		let availableSubscriptionPlansHead = _.head(this.natShoppingCartStore.availableSubscriptionPlans);
		let defaultSubscriptionPlan: any = { item: null, id: null };
		if (availableSubscriptionPlansHead) {
			defaultSubscriptionPlan = { item: availableSubscriptionPlansHead, id: availableSubscriptionPlansHead.id };
		}
		let subscriptionItemListHead = _.head(this.natShoppingCartStore.partnerSubscriptionInfo.subscriptionItemList);
		if (update) {
			let parsedUpdate = JSON.parse(update);
			let availableSubscriptionPlan = _.cloneDeep(availableSubscriptionPlansMap[parsedUpdate.id]);
			let parsedUpdateProductItemsMap = _.chain(parsedUpdate.productItems).keyBy('productId').value();
			if (availableSubscriptionPlan) {
				for (let item of _.get(availableSubscriptionPlan, 'subscriptionPlan.productItems', [])) {
					if (parsedUpdateProductItemsMap[item.productId]) {
						item.quantity = parsedUpdateProductItemsMap[item.productId].quantity;
					}
				}
				defaultSubscriptionPlan = { item: availableSubscriptionPlan, id: availableSubscriptionPlan.id };
			}
		} else {
			if (subscriptionItemListHead) {
				if (subscriptionItemListHead.subscriptionPlan) {
					let availableSubscriptionPlan = _.cloneDeep(availableSubscriptionPlansMap[subscriptionItemListHead.subscriptionPlan.id as string]);
					if (availableSubscriptionPlan) {
						if (!_.isEmpty(subscriptionItemListHead.subscriptionOptionItemList)) {
							let subscriptionOptionListMap = _.chain(subscriptionItemListHead.subscriptionOptionItemList)
								.keyBy((item: any) => {
									return item.subscriptionOption.id;
								})
								.value();
							for (let item of _.get(availableSubscriptionPlan, 'subscriptionPlan.productItems', [])) {
								if (!_.isEmpty(subscriptionOptionListMap[item.optionId])) {
									item.quantity = subscriptionOptionListMap[item.optionId].quantity;
								}
							}
						}
						if (this.injected.directoryStore.models.shoppingCartMode === 'change') {
							if (subscriptionItemListHead.subscriptionPlan.renewable) {
								availableSubscriptionPlan.periodItemList = [
									{
										validityPeriod: {
											name: { ru: `До конца срока (${listDateTimeFormatter.renderValue(subscriptionItemListHead.expiredAt)})` }
										},
										validityPeriodCount: 0,
										validityPeriodId: null
									}
								];
							}
						}
						defaultSubscriptionPlan = { item: availableSubscriptionPlan, id: availableSubscriptionPlan.id };
					}
				}
			}
		}

		this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'selectedSubscriptionPlan', defaultSubscriptionPlan);
		this.togglePeriod(this.natShoppingCartStore.selectedSubscriptionPlan.item.periodItemList[0], true);
	}
	updateShoppingCart() {
		if (!_.isEmpty(this.natShoppingCartStore.shoppingCart)) {
			return natiwiController
				.updateShoppingCart(this.natShoppingCartStore.shoppingCart)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'shoppingCart', data);
					}
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItems', []);
				});
		} else {
			return new Promise((resolve) => {
				catalog.showAlert('Объект корзины пуст. Обновление невозможно!');
				return resolve(null);
			});
		}
	}
	checkoutShoppingCart() {
		let urls = {
			resolveCallbackUrl: window.location.origin + `/shoppingCart/checkout/${this.natShoppingCartStore.shoppingCart.paymentOptionId}/validate`,
			rejectCallbackUrl: window.location.origin + '/shoppingCart/checkout/failure'
		};
		return natiwiController.checkoutShoppingCart(urls);
	}
	fillPersonalAccount() {
		if (!_.isEmpty(this.injected.directoryStore.models.constants['PersonalAccountReplenishProduct'])) {
			let shoppingCartProductItems: Array<any> = [];
			let product: any = this.injected.directoryStore.models.constants['PersonalAccountReplenishProduct'].value;
			if (!_.isEmpty(product)) {
				let shoppingCartProductItem = {
					productId: product.id,
					id: generateRandomId(),
					quantity: 1,
					unitId: product.unitId,
					price: this.natShoppingCartStore.refillAmount
				};
				shoppingCartProductItems.push(shoppingCartProductItem);
				this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItemList', shoppingCartProductItems);
				this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'productItems', shoppingCartProductItems);
				let paymentOptionListMap = _.chain(this.natShoppingCartStore.paymentOptionList).keyBy('typeId').value();
				if (this.natShoppingCartStore.shoppingCart.paymentOption) {
					if (this.natShoppingCartStore.shoppingCart.paymentOption.typeId === 'payment_option_types.partner_settlement') {
						let defaultPaymentOption = paymentOptionListMap['payment_option_types.acquiring'];
						if (defaultPaymentOption) {
							return new Promise((resolve) => {
								this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'paymentOption', defaultPaymentOption);
								this.natShoppingCartStore.setValue(this.natShoppingCartStore.shoppingCart, 'paymentOptionId', defaultPaymentOption.id);
								return resolve(null);
							});
						} else {
							return this.updateShoppingCart();
						}
					} else {
						return this.updateShoppingCart();
					}
				} else {
					return this.updateShoppingCart();
				}
			} else {
				return new Promise((resolve) => {
					catalog.showAlert('Пополнение лицевого счета невозможно!  Обратитесь в тех. поддержку.');
					return resolve(null);
				});
			}
		} else {
			return new Promise((resolve) => {
				catalog.showAlert('Пополнение лицевого счета невозможно!  Обратитесь в тех. поддержку.');
				return resolve(null);
			});
		}
	}
	convertPartnerSubscriptionInfo() {
		let partnerSubscriptionInfo: any = _.cloneDeep(this.injected.directoryStore.models.partnerSubscriptionInfo);
		if (!_.isEmpty(partnerSubscriptionInfo.subscriptionItemList)) {
			if (!_.isEmpty(partnerSubscriptionInfo.subscriptionItemList[0].subscriptionOptionItemList)) {
				partnerSubscriptionInfo.subscriptionItemList[0].subscriptionOptionItemList = _.chain(partnerSubscriptionInfo.subscriptionItemList[0].subscriptionOptionItemList)
					.groupBy((item) => {
						return item.subscriptionOption.id;
					})
					.values()
					.map((item) => {
						let head: any = _.head(item);
						return _.reduce(
							item,
							(result: any, item: any) => {
								result.quantity += item.quantity;
								return result;
							},
							{
								expiredAt: head.expiredAt,
								quantity: 0,
								subscriptionOption: head.subscriptionOption,
								validityPeriodCount: head.validityPeriodCount,
								validityPeriodId: head.validityPeriodId
							}
						);
					})
					.value();
			}
			this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'partnerSubscriptionInfo', partnerSubscriptionInfo);
		}
	}
	render() {
		return ReactDOM.createPortal(
			<CModal
				centered
				show={this.state.isOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__shopping__cart__modal' : 'nat__modal nat__scrollbar nat__modal__mobile nat__shopping__cart__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<div className={this.injected.directoryStore.models.windowSize > 1199 ? 'h-100 d-flex m-4 nat__subscription__info__wrapper' : 'h-100 d-flex flex-column m-4 nat__subscription__info__wrapper'}>
					<div className={this.injected.directoryStore.models.windowSize > 1199 ? 'nat__subscription__info mr-4' : 'mb-4'}>
						<div className="nat__modal__shopping__cart__wrapper nat__card__wrapper nat__borders__2-75 w-100 d-flex flex-column">
							<CForm className="w-100 mx-3 my-4">
								<CRow>
									<CCol lg="12">
										<CFormGroup className="mb-0 w-100 px-4">
											<CRow className="w-100">
												{this.injected.directoryStore.models.shoppingCartMode !== 'personalAccount' ? (
													<CCol lg="12">
														<NatRelationInput
															object={this.natShoppingCartStore.selectedSubscriptionPlan}
															property="id"
															relation="item"
															placeholder="Выберите тариф"
															type="text"
															size="sm"
															formatter={relationFormatter(false, true)}
															pluralName="enumerationItems"
															autoComplete="off"
															renderName={(model: any) => {
																return catalog.renderCatalogName(model.subscriptionPlan);
															}}
															relations={_.cloneDeep(this.natShoppingCartStore.availableSubscriptionPlans)}
															onlySelect
															readyData
															disabled={this.injected.directoryStore.models.shoppingCartMode === 'extend'}
															label="Тариф"
														/>
														{_.map(this.natShoppingCartStore.productItems, (item, index) => {
															if (item.readOnly && item.productItem.quantity === 0) {
																if (!_.isEmpty(item.productItem)) {
																	if (!_.isEmpty(item.productItem.option)) {
																		if (!_.isEmpty(item.productItem.option.valueAtZero)) {
																			return (
																				<div key={index} className="d-flex nat__subscription__option__readonly align-items-center">
																					<span className="mr-auto">{item.name}</span>
																					<span className="mr-4 font__size__16">{item.productItem.option.valueAtZero[this.injected.directoryStore.models.language]}</span>
																				</div>
																			);
																		} else {
																			return (
																				<div key={index} className="d-flex nat__subscription__option__readonly align-items-center">
																					<span className="mr-auto">{item.name}</span>
																					<span className="mr-4 font__size__16">{item.productItem.quantity}</span>
																				</div>
																			);
																		}
																	}
																}
															} else {
																return (
																	<NatPolymorphicInput
																		key={index}
																		property="valueId"
																		object={item}
																		label={item.name}
																		row={true}
																		size="sm"
																		disabled={item.readOnly || this.injected.directoryStore.models.shoppingCartMode === 'extend'}
																		validation={(object: any, value: any) => {
																			return this.validateOptionInput(object, value);
																		}}
																	/>
																);
															}
														})}
													</CCol>
												) : (
													<CCol lg="12">
														<NatValueInput<number>
															object={this.natShoppingCartStore}
															property="refillAmount"
															placeholder="Введите сумму"
															type="text"
															pattern="^([0-9]*[.,])?[0-9]*$"
															formatter={priceFormatter}
															size="sm"
															label="Сумма пополнения"
														/>
													</CCol>
												)}
											</CRow>
										</CFormGroup>
									</CCol>
								</CRow>
							</CForm>
						</div>
					</div>
					<div className={this.injected.directoryStore.models.windowSize > 1199 ? 'nat__subscription__info' : ''}>
						<div className="nat__modal__shopping__cart__wrapper nat__card__wrapper nat__borders__2-75 w-100 d-flex flex-column">
							<div className="p-0 nat__form__tabs nat__form__tabs__mobile m-4 d-flex flex-column align-items-center" ref={this.tabRef}>
								<CListGroup className="nat__shopping__cart__items w-100 mb-3">
									<CListGroupItem className="d-flex justify-content-between list__header__item">
										<h4>В корзине:</h4>
									</CListGroupItem>
									{_.map(this.natShoppingCartStore.shoppingCart.productItems, (item, index) => {
										return (
											<CListGroupItem key={index} className="d-flex align-items-center">
												{catalog.renderCatalogName(item.product) && (
													<div className="d-flex mr-auto">
														<h6 className="mb-0 text-left">{`${catalog.renderCatalogName(item.product)} (${item.quantity} ${catalog.renderCatalogName(item.unit)})`}</h6>
													</div>
												)}
												<div className="d-flex ml-auto nat__shopping__cart__items__amount">
													<h6 className="mb-0">{priceFormatter.renderValue(item.amount)}</h6>
													<h6 className="ml-1 mb-0">{item.amount !== undefined && 'руб'}</h6>
													{item.amount !== item.amountWithoutDiscount && (
														<div className="del d-flex ml-1">
															<h6 className="ml-1 mb-0">{priceFormatter.renderValue(item.amountWithoutDiscount)}</h6>
															<h6 className="ml-1 mb-0">{item.amountWithoutDiscount !== undefined && 'руб'}</h6>
														</div>
													)}
												</div>
											</CListGroupItem>
										);
									})}
								</CListGroup>
								{this.injected.directoryStore.models.shoppingCartMode !== 'personalAccount' && !_.isEmpty(this.natShoppingCartStore.selectedSubscriptionPlan.id) && (
									<CNav variant="tabs" className="p-2 w-100 mb-3" innerRef={this.tabRef}>
										{_.map(this.natShoppingCartStore.selectedSubscriptionPlan.item.periodItemList, (item, index: number) => {
											return (
												<CNavItem className="mr-1" key={index}>
													<a
														className="nav-link cursor__pointer"
														id={item.validityPeriodCount + item.validityPeriodId}
														onClick={(event) => {
															event.preventDefault();
															this.togglePeriod(item);
														}}>
														{item.validityPeriodId !== null ? `${item.validityPeriodCount} (${catalog.renderCatalogName(item.validityPeriod)})` : catalog.renderCatalogName(item.validityPeriod)}
													</a>
												</CNavItem>
											);
										})}
									</CNav>
								)}
								<CFormGroup className="mb-0 w-100 pl-4">
									<CRow className="w-100">
										<CCol lg="12">
											<NatValueInput<string>
												object={this.natShoppingCartStore.shoppingCart}
												property="couponCode"
												placeholder="Введите промокод"
												type="text"
												formatter={stringFormatter()}
												size="sm"
												autoComplete="off"
												label="Промокод"
												mobile
											/>
										</CCol>
									</CRow>
								</CFormGroup>
								<CFormGroup className="mb-0 w-100 pl-4">
									<CRow className="w-100">
										<CCol lg="12">
											<NatRelationInput
												object={this.natShoppingCartStore.shoppingCart}
												property="paymentOptionId"
												relation="paymentOption"
												placeholder="Выберите способ оплаты"
												type="text"
												size="sm"
												formatter={relationFormatter(true)}
												pluralName="paymentOptions"
												filterWhere={{ deleted: false }}
												autoComplete="off"
												label="Способ оплаты"
												mobile
												relations={_.cloneDeep(this.natShoppingCartStore.paymentOptionList)}
												onlySelect
												readyData
											/>
										</CCol>
									</CRow>
								</CFormGroup>
								{(() => {
									if (!_.isEmpty(this.natShoppingCartStore.shoppingCart.paymentOption) && this.natShoppingCartStore.shoppingCart.paymentOption) {
										if (this.natShoppingCartStore.shoppingCart.paymentOption.description !== null) {
											return <span className="text-center mt-3">{this.natShoppingCartStore.shoppingCart.paymentOption.description[this.injected.directoryStore.models.language]}</span>;
										}
									}
								})()}
							</div>
							<CCard className="m-4 nat__subscription__payment nat__borders__2-75 mt-auto">
								<CCardBody className="d-flex flex-column">
									<div className="d-flex">
										<h4>
											{(() => {
												if (this.natShoppingCartStore.shoppingCart.amount >= 0) {
													return 'К оплате:';
												} else {
													return 'К возврату:';
												}
											})()}
										</h4>
										<h4 className="ml-auto">{priceFormatter.renderValue(this.natShoppingCartStore.shoppingCart.amount)}</h4>
										<h4 className="ml-1">руб</h4>
									</div>
									<div className="d-flex mx-4 w-100 align-self-center">
										<CButton
											type="button"
											size="sm"
											className="mr-1 nat__button__hover nat__subscription__payment__button"
											onClick={() => {
												this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', true);
												this.checkoutShoppingCart()
													.then((data) => {
														if (!_.isEmpty(data)) {
															if (data.paymentRequired) {
																if (!_.isEmpty(data.formUrl)) {
																	this.toggle(false);
																	window.open(data.formUrl, '_self');
																} else {
																	catalog.showAlert('Невозможно оплатить, выберите другой способ оплаты!');
																}
															} else {
																this.props.history.push('/shoppingCart/checkout/paid');
															}
															this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'shoppingCart', data.shoppingCart);
														} else {
															catalog.showAlert('Невозможно оплатить, выберите другой способ оплаты!');
														}
														this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
													})
													.catch((error) => {
														catalog.handleNatError(error);
														this.natShoppingCartStore.setValue(this.natShoppingCartStore, 'isLoading', false);
													});
											}}>
											{(() => {
												let result: string = 'ОПЛАТИТЬ';
												if (this.injected.directoryStore.models.shoppingCartMode === 'extend') {
													result = 'ПРОДЛИТЬ';
												} else if (this.injected.directoryStore.models.shoppingCartMode === 'change') {
													result = 'ИЗМЕНИТЬ';
												} else if (this.injected.directoryStore.models.shoppingCartMode === 'personalAccount') {
													result = 'ПОПОЛНИТЬ';
												}
												return result;
											})()}
										</CButton>
										<CButton
											type="button"
											size="sm"
											className="mr-1 nat__button__hover nat__subscription__payment__button"
											onClick={() => {
												this.props.removeNavigationRoute(this.props.navigationRoute);
											}}>
											ОТМЕНА
										</CButton>
									</div>
								</CCardBody>
							</CCard>
						</div>
					</div>
				</div>
				{this.natShoppingCartStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
			</CModal>,
			document.querySelector('#modal') as Element
		);
	}
}

export default UseLocation(NatShoppingCart);
