import CIcon from '@coreui/icons-react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { TreeMenuItem } from 'react-simple-tree-menu';

const DEFAULT_PADDING = 0.75;
const ICON_SIZE = 1;
const LEVEL_SPACE = 1.75;
const ToggleIcon = ({ on, openedIcon, closedIcon }: { on: boolean; openedIcon: ReactNode; closedIcon: ReactNode }) => (
	<div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
		{on ? openedIcon : closedIcon}
	</div>
);

const NatTreeItemComponent: React.FunctionComponent<TreeMenuItem> = ({ hasNodes = false, isOpen = false, level = 0, onClick, toggleNode, active, focused, openedIcon = '-', closedIcon = '+', style = {}, label = 'unknown', className }) => (
	<li
		className={classNames(className, 'rstm-tree-item', `rstm-tree-item-level${level}`, { 'rstm-tree-item--active': active }, { 'rstm-tree-item--focused': focused })}
		style={{
			marginLeft: `${DEFAULT_PADDING + ICON_SIZE * (hasNodes ? 0 : 1) + level * LEVEL_SPACE}rem`,
			...style
		}}
		role="button"
		aria-pressed={active}
		onClick={onClick}>
		{hasNodes && (
			<div
				className="rstm-toggle-icon"
				onClick={(e) => {
					if (hasNodes && toggleNode) {
						toggleNode();
					}
					e.stopPropagation();
				}}>
				<ToggleIcon on={isOpen} openedIcon={openedIcon} closedIcon={closedIcon} />
			</div>
		)}
		{isOpen || active ? (
			<CIcon
				name="cil-folder-open"
				className={(() => {
					if (hasNodes) {
						return 'mr-2 flex-shrink-0';
					} else {
						return 'mr-2 ml-3 flex-shrink-0';
					}
				})()}
			/>
		) : (
			<CIcon
				name="cil-folder"
				className={(() => {
					if (hasNodes) {
						return 'mr-2 flex-shrink-0';
					} else {
						return 'mr-2 ml-3 flex-shrink-0';
					}
				})()}
			/>
		)}
		{label}
	</li>
);

export default NatTreeItemComponent;
