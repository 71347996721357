import { observable, action, makeObservable, set } from 'mobx';

import { CouponType } from '../../shared/support/modelTypes';

export interface CouponFormStoreType {
	model: CouponType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	isFirstLoad: boolean;
	name: string;
	language: string;
	setValue(model: any, key: string, value: any): void;
}

class CouponFormStore implements CouponFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			filterInclude: observable,
			language: observable,
			setValue: action
		});
	}

	model = {
		partnerId: null,
		code: '',
		quantityOfUses: 0,
		startedAt: null,
		expiredAt: null,
		parentId: null,
		elementType: 0,
		description: null,
		statusId: 'coupon_statuses.valid',
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		ownerType: 'OrmCouponKind',
		ownerId: '',
		status: {
			id: 'coupon_statuses.valid',
			keyId: 'valid',
			ownerId: 'coupon_statuses',
			name: {
				ru: 'Действует',
				id: '03f72f48-9154-464f-afde-33e7896ee477'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'CouponStatusesValid',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'coupons';
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmCoupon';
	filterInclude = ['partner', 'status'];
	language = 'ru';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const couponFormStore = new CouponFormStore();

export default CouponFormStore;
