import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import PrintLabelForm from './PrintLabelForm';
import { PrintLabelFormStoreType } from './PrintLabelFormStore';

interface PropsType extends FormContainerPropsType<PrintLabelFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class PrintLabelFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.saveProductItems = this.saveProductItems.bind(this);
		this.print = this.print.bind(this);
		this.fillProductItems = this.fillProductItems.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
			this.formReactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.productBarcodeId = null;
							if (!_.isEmpty(value)) {
								item.unitId = item.product.unitId;
								item.unit = item.product.unit;
							} else {
								item.unitId = null;
								delete item.unit;
							}
							delete item.feature;
							delete item.productBarcode;
							this.rebuild(_.get(this.props.store.model, 'productItems', []));
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.productBarcodeId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (!_.isEmpty(item.productBarcode)) {
								item.barcode = item.productBarcode.barcode;
							}
						}
					}
				)
			);
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.priceTypeId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
							runInAction(() => {
								item.priceTypeId = value;
								item.priceType = this.props.store.model.priceType;
							});
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.labelTemplateId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
							item.labelTemplateId = value;
							item.labelTemplate = this.props.store.model.labelTemplate;
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.organizationId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
							item.organizationId = value;
							item.organization = this.props.store.model.organization;
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.createdAt,
				(value) => {
					_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
						item.createdAt = value;
					});
				}
			)
		);
	}
	loadPage() {
		document.title = 'Журнал';
		catalog.setConstants(this.props.store, this.props.store.requiredToFill);
		this.props.store.setValue(this.props.store, 'responseCode', 200);
		catalog.generateTitle(this.props.navigationRoute, '', '', 'Печать ценников и этикеток');
		this.props.store.setValue(this.props.store, 'isChanged', false);
		this.makeReactions();
	}
	fillEmptyRelations(model: any) {
		return model;
	}
	prepareModelForPost(model: any) {
		return model;
	}
	validate() {
		return true;
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', items);
		this.makeReactions();
	}
	saveProductItems(productItems: Array<any>) {
		const arr = [] as Array<any>;
		const currentProductItemMap = _.chain(this.props.store.model.productItems).cloneDeep().keyBy('id').value();
		_.forEach(productItems, (item: any) => {
			if (currentProductItemMap[item.id] === undefined) {
				if (!_.isEmpty(item.productBarcode)) {
					item.barcode = item.productBarcode.barcode;
				}
				arr.push(item);
			}
		});
		this.props.store.setValue(this.props.store.model, 'productItems', _.get(this.props.store, 'model.productItems', []).concat(arr));
		this.props.store.setValue(this.props.store.model, 'productItemList', this.props.store.model.productItems);
		this.makeReactions();
	}
	fillProductItems(productItems: Array<any>, productItemTemplate: any) {
		this.resetFormReactions();
		_.map(productItems, (item: any) => {
			const productItem = _.cloneDeep(productItemTemplate);
			productItem.product = item.product;
			productItem.productId = item.productId;
			productItem.id = generateRandomId();
			if (!_.isEmpty(item.featureId)) {
				productItem.featureId = item.featureId;
				productItem.feature = item.feature;
			}
			if (!_.isEmpty(item.unitId)) {
				productItem.unitId = item.unitId;
				productItem.unit = item.unit;
			}
			if (item.price !== undefined) {
				productItem.price = item.price;
				runInAction(() => {
					this.props.store.model.productItems.push(productItem);
				});
				this.makeReactions();
			} else {
				productItem.price = 0;
			}
		});
	}
	print() {
		if (!_.isEmpty(this.props.store.model.labelTemplateId)) {
			this.props.store.setValue(this.props.store, 'isLoading', true);
			let productItems = _.cloneDeep(this.props.store.model.productItems);
			_.forEach(productItems, (item) => {
				if (_.isEmpty(item.featureId)) {
					delete item.feature;
				}
				if (_.isEmpty(item.unitId)) {
					delete item.unit;
				}
				delete item.organizationId;
				delete item.priceTypeId;
				delete item.productBarcodeId;
				delete item.productBarcode;
				delete item.featureId;
				delete item.productId;
				delete item.unitId;
				delete item.id;
			});
			controller
				.displayLabelTemplate(productItems, { format: 'pdf' })
				.then((data) => {
					if (data !== undefined) {
						let url = URL.createObjectURL(new Blob([data], { type: data.type }));
						window.open(url);
					}
					this.props.store.setValue(this.props.store, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.props.store.setValue(this.props.store, 'isLoading', false);
				});
		} else {
			catalog.showAlert('Ошибка при попытке печати! Проверьте правильность заполнения полей!');
		}
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				notRecalculateAmount
				parentRoute="/printLabels/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<PrintLabelForm
						makeReactions={this.makeReactions}
						removeNavigationRoute={this.props.removeNavigationRoute}
						printLabelFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						saveProductItems={this.saveProductItems}
						print={this.print}
						fillProductItems={this.fillProductItems}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default PrintLabelFormContainer;
