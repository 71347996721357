import CIcon from '@coreui/icons-react';
import { CButton, CCardHeader, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TreeMenu from 'react-simple-tree-menu';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import AlertDialog from '../Alerts/AlertDialog';
import { dateFormatter } from '../Formatters/DateFormatter';
import { stringFormatter } from '../Formatters/StringFormatter';
import NatCheckbox from '../Inputs/NatCheckBox';
import NatDefaultCheckbox from '../Inputs/NatDefaultCheckBox';
import NatFormGroupLabel from '../Inputs/NatFormGroupLabel';
import NatValueInput from '../Inputs/NatValueInput';
import NatAclMapping from '../Modals/NatAclMapping';
import NatBulkEditingManager from '../Modals/NatBulkEditingManager';
import { catalog } from '../support/Catalog';
import { natCloseTriangle, natOpenTriangle } from '../support/NatIcons';
import { MatchType } from '../support/modelTypes';
import NatFilters from './NatFilters';
import { ChildrenPropsType } from './NatListContainer';
import NatTreeButtons from './NatTreeButtons';
import NatTreeItemComponent from './NatTreeItemComponent';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	name: string;
	columns: Array<any>;
	navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean };
	goTo(path: string, state?: Record<string, unknown>): void;
	childrenProps: ChildrenPropsType;
	store: any;
	children?: ReactElement;
	addButtonTitle: string;
	editingFields: React.ReactNode;
}

interface StateType {
	querySelector: any;
	isDeleteModalOpen: boolean;
	isDeleteGroupModalOpen: boolean;
	colId: number | null;
	timer: any;
	isFilterOpen: boolean;
	isTreeContextMenuOpen: boolean;
	isTableContextMenuOpen: boolean;
	isBulkEditingManagerOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatHierarchicalList extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	treeRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.treeRef = React.createRef();
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.toggleDeleteGroupModal = this.toggleDeleteGroupModal.bind(this);
		this.sortList = this.sortList.bind(this);
		this.toggleNodeByIcon = this.toggleNodeByIcon.bind(this);
		this.toggleNodeByNode = this.toggleNodeByNode.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.toggleNatBulkEditingManager = this.toggleNatBulkEditingManager.bind(this);
		this.reactions = [];
		this.state = {
			querySelector: null,
			isDeleteModalOpen: false,
			isDeleteGroupModalOpen: false,
			colId: null,
			timer: null,
			isFilterOpen: false,
			isTreeContextMenuOpen: false,
			isTableContextMenuOpen: false,
			isBulkEditingManagerOpen: false
		};
		this.reactions.push(
			reaction(
				() => this.props.store.chips,
				() => {
					// if (value.length !== previousValue.length) {
					if (!_.isEmpty(this.state.querySelector)) {
						this.state.querySelector.scrollTo(0, 0);
						clearTimeout(this.state.timer);
						this.setState({
							timer: setTimeout(this.props.childrenProps.showList, 500)
						});
					}
					// }
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.store.searchFields.deleted,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(this.state.querySelector)) {
							this.state.querySelector.scrollTo(0, 0);
							let groupFilter = {
								where: this.props.store.filterGroupWhere,
								order: this.props.store.filterGroupOrder
							};
							if (!this.props.store.searchFields.deleted) {
								groupFilter.where.deleted = false;
							} else {
								delete groupFilter.where.deleted;
							}
							controller
								.findAll(this.props.store.pluralName, groupFilter)
								.then((data) => {
									if (!_.isEmpty(data)) {
										this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree(data));
									} else {
										this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree([]));
									}
									this.props.childrenProps.makeTooltips(this.props.childrenProps.treeRef);
								})
								.catch((error) => {
									catalog.handleNatError(error);
								});
						}
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.myRef.current.querySelector('.rgt-container')?.removeEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: null
		});
	}
	handleScrollToElement() {
		let scrollTop = this.state.querySelector.scrollTop;
		let scrollHeight = this.state.querySelector.scrollHeight;
		let clientHeight = this.state.querySelector.clientHeight;
		let arr = this.props.store.list;
		let itemsPerPage = this.props.store.itemsPerPage;
		let itemsListLength = arr.length;
		if (scrollTop >= scrollHeight - clientHeight && this.props.store.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
			this.props.store.setValue(this.props.store, 'currentPage', this.props.store.currentPage + 1);
			this.props.store.setValue(this.props.store, 'filterSkip', this.props.store.currentPage * this.props.store.itemsPerPage);
			let filter: any = this.props.childrenProps.createFilter();
			this.props.store.setValue(this.props.store, 'responseCode', 0);
			controller
				.findAll(this.props.store.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.store.setValue(this.props.store, 'list', this.props.store.list.concat(data));
					}
					this.props.store.setValue(this.props.store, 'responseCode', 200);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		}
	}
	sortList(colId: number, isAsc: boolean) {
		let order: Array<string> = [];
		this.props.store.setValue(this.props.store, 'sortConfig', { colId, isAsc });
		if (colId !== null) {
			const groupedColumns = _.groupBy(this.props.columns, 'id');
			const sortBy = isAsc ? 'ASC' : 'DESC';
			order = [`${groupedColumns[colId][0].field} ${sortBy}`];
		} else {
			order = ['createdAt DESC'];
		}
		this.state.querySelector.scrollTo(0, 0);
		this.props.store.setValue(this.props.store, 'filterOrder', order);
		this.props.store.setValue(this.props.store, 'currentPage', 0);
		this.props.store.setValue(this.props.store, 'filterSkip', 0);
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		let filter: any = this.props.childrenProps.createFilter();
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				}
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	toggleNatBulkEditingManager(status: boolean) {
		this.setState({
			isBulkEditingManagerOpen: status
		});
	}
	toggleDeleteModal(status: boolean) {
		this.setState({
			isDeleteModalOpen: status
		});
	}
	toggleDeleteGroupModal(status: boolean) {
		this.setState({
			isDeleteGroupModalOpen: status
		});
	}
	toggleNodeByIcon(item: any) {
		const openNodes = _.cloneDeep(this.props.store.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = openNodes.filter((openNode) => openNode !== item.key);
		} else {
			const nodesMap = _.chain(this.props.store.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.props.store.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.props.store.setValue(this.props.store.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.props.store.setValue(this.props.store.treeState, 'openNodes', newOpenNodes);
		setTimeout(() => {
			this.props.childrenProps.makeTooltips(this.props.childrenProps.treeRef);
		}, 0);
	}
	toggleNodeByNode(item: any) {
		const openNodes = _.cloneDeep(this.props.store.treeState.openNodes);
		let newOpenNodes = [] as Array<string>;
		if (openNodes.includes(item.key)) {
			newOpenNodes = [...openNodes];
		} else {
			const nodesMap = _.chain(this.props.store.treeState.nodes).keyBy('key').value();
			if (nodesMap[item.key] === undefined && item.hasNodes) {
				runInAction(() => {
					this.props.store.treeState.nodes.push(item);
				});
			}
			if (openNodes.length !== 0) {
				if (nodesMap[item.parent] !== undefined) {
					newOpenNodes = [...openNodes, item.key];
				} else {
					newOpenNodes = [item.key];
					this.props.store.setValue(this.props.store.treeState, 'nodes', [item]);
				}
			} else {
				newOpenNodes = [...openNodes, item.key];
			}
		}
		this.props.store.setValue(this.props.store.treeState, 'openNodes', newOpenNodes);
	}
	toggleFilters(status: boolean) {
		this.setState({
			isFilterOpen: status
		});
	}
	render() {
		let columnsMap = _.chain(this.props.columns).cloneDeep().keyBy('field').value();
		return (
			<div className="d-flex nat__page default__nat__card__body__height flex-column nat__scrollbar nat__borders__2-75">
				<CCardHeader className="nat__page__header nat__card__wrapper d-flex flex-column mt-4 p-2 mx-4 nat__borders__2-75">
					<div className="d-flex align-items-center">
						<div className="d-flex">
							<CDropdown>
								<CDropdownToggle variant="ghost" caret={false} className="d-flex nat__button__hover mr-1">
									{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-plus" size="lg" /> : 'ДОБАВИТЬ'}
								</CDropdownToggle>
								<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
									<CDropdownItem
										onClick={() => {
											let url: string = '';
											if (!_.isEmpty(this.props.store.group)) {
												url = this.props.navigationRoute.url + `/new?elementType=1&parentId=${this.props.store.group.id}`;
											} else {
												url = this.props.navigationRoute.url + '/new?elementType=1';
											}
											this.props.goTo(url);
										}}>
										Добавить группу
									</CDropdownItem>
									<CDropdownItem divider className="m-0">
										Action
									</CDropdownItem>
									<CDropdownItem
										onClick={() => {
											let url: string = '';
											if (!_.isEmpty(this.props.store.group)) {
												url = this.props.navigationRoute.url + `/new?elementType=0&parentId=${this.props.store.group.id}`;
											} else {
												url = this.props.navigationRoute.url + '/new?elementType=0';
											}
											this.props.goTo(url);
										}}>
										{this.props.addButtonTitle}
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
							<CButton
								variant="ghost"
								className="d-flex nat__button__hover mr-1"
								onClick={() => {
									this.state.querySelector.scrollTo(0, 0);
									this.props.store.setValue(this.props.store, 'currentPage', 0);
									this.props.store.setValue(this.props.store, 'filterSkip', 0);
									this.props.store.setValue(this.props.store, 'responseCode', 0);
									this.props.store.setValue(this.props.store, 'list', []);
									this.props.store.setValue(this.props.store, 'groupsTree', []);
									this.props.store.setValue(this.props.store, 'collectedModels', []);
									let filter: any = {
										skip: this.props.store.filterSkip,
										limit: this.props.store.itemsPerPage,
										order: this.props.store.filterOrder,
										include: this.props.store.filterInclude
									};
									if (!_.isEmpty(this.props.store.filterWhere)) {
										filter.where = this.props.store.filterWhere;
									}
									let groupFilter = {
										where: this.props.store.filterGroupWhere,
										order: this.props.store.filterGroupOrder
									};
									controller
										.findAll(this.props.store.pluralName, filter)
										.then((data) => {
											if (!_.isEmpty(data)) {
												this.props.store.setValue(this.props.store, 'list', data);
											} else {
												this.props.store.setValue(this.props.store, 'list', []);
											}
											return controller.findAll(this.props.store.pluralName, groupFilter);
										})
										.then((data) => {
											if (!_.isEmpty(data)) {
												this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree(data));
											} else {
												this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree([]));
											}
											this.props.childrenProps.makeTooltips(this.props.childrenProps.treeRef);
											this.props.store.setValue(this.props.store, 'responseCode', 200);
										})
										.catch((error) => {
											catalog.handleNatError(error);
										});
								}}>
								{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-reload" size="lg" /> : 'ОБНОВИТЬ'}
							</CButton>
							<CDropdown>
								<CDropdownToggle variant="ghost" className="nat__button__hover mr-1">
									{`ИЗМЕНИТЬ (${this.props.store.collectedModels.length})`}
								</CDropdownToggle>
								<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
									<CDropdownItem
										onClick={() => {
											this.toggleDeleteModal(true);
										}}
										disabled={this.props.store.collectedModels.length === 0}>
										Отметить/снять отметку удаления
									</CDropdownItem>
									<CDropdownItem
										onClick={() => {
											this.props.childrenProps.toggleAclModal(true);
										}}
										disabled={this.props.store.collectedModels.length === 0}>
										Доступ
									</CDropdownItem>
									<CDropdownItem
										onClick={() => {
											this.toggleNatBulkEditingManager(true);
										}}
										disabled={this.props.store.collectedModels.length === 0}>
										Массовое редактирование
									</CDropdownItem>
								</CDropdownMenu>
							</CDropdown>
							<AlertDialog
								isModalOpen={this.state.isDeleteModalOpen}
								title="Выполнить действие?"
								buttonItemList={[
									{
										title: 'ДА',
										onClick: () => {
											this.toggleDeleteModal(false);
											catalog
												.makeDeleted(this.props.store.pluralName, this.props.store.collectedModels)
												.then(() => {
													this.props.store.setValue(this.props.store, 'responseCode', 0);
													this.props.store.setValue(this.props.store, 'currentPage', 0);
													this.props.store.setValue(this.props.store, 'filterSkip', 0);
													let filter: any = {
														skip: this.props.store.filterSkip,
														limit: this.props.store.itemsPerPage,
														order: this.props.store.filterOrder,
														include: this.props.store.filterInclude
													};
													if (!_.isEmpty(this.props.store.filterWhere)) {
														filter.where = this.props.store.filterWhere;
													}
													this.props.store.setValue(this.props.store, 'collectedModels', []);
													return controller.findAll(this.props.store.pluralName, filter);
												})
												.then((data) => {
													this.props.store.setValue(this.props.store, 'list', []);
													if (!_.isEmpty(data)) {
														this.props.store.setValue(this.props.store, 'list', data);
													}
													this.props.store.setValue(this.props.store, 'responseCode', 200);
												})
												.catch((error) => {
													catalog.handleNatError(error);
												});
										}
									},
									{
										title: 'ОТМЕНА',
										onClick: () => {
											this.toggleDeleteModal(false);
										}
									}
								]}
							/>
						</div>
						<CForm className="form-horizontal d-flex ml-auto">
							<CButton
								type="button"
								size="sm"
								className="nat__button__hover"
								onClick={() => {
									this.toggleFilters(true);
								}}>
								{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-filter" size="lg" /> : `ФИЛЬТРЫ (${this.props.store.chips.length})`}
							</CButton>
							{this.state.isFilterOpen && (
								<NatFilters
									store={this.props.store}
									toggleModal={this.toggleFilters}
									isModalOpen={this.state.isFilterOpen}
									hierarchical={this.props.childrenProps.hierarchical}
									resetSearchFields={this.props.childrenProps.resetSearchFields}
									ownerId={this.props.childrenProps.ownerId}
									enumerationItems={this.props.childrenProps.enumerationItems}>
									<>
										{(() => {
											if (!_.isEmpty(columnsMap['sequenceNumber'])) {
												return (
													<NatValueInput<string>
														object={this.props.store.chipsFields.sequenceNumber}
														property="id"
														placeholder="Введите номер"
														type="text"
														formatter={stringFormatter()}
														size="sm"
														autoComplete="off"
														label="Номер"
													/>
												);
											}
										})()}
										{(() => {
											if (!_.isEmpty(columnsMap['name'])) {
												return (
													<NatValueInput<string>
														object={this.props.store.chipsFields.name}
														property="id"
														placeholder="Введите наименование"
														type="text"
														formatter={stringFormatter()}
														size="sm"
														autoComplete="off"
														label="Наименование"
													/>
												);
											}
										})()}
										{this.props.children}
										<NatValueInput<Date> object={this.props.store.chipsFields.createdAtGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Создан от" />
										<NatValueInput<Date> object={this.props.store.chipsFields.createdAtLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="до" />
										<NatCheckbox object={this.props.store} property="showChildGroupsElements" label="Показывать элементы дочерних групп" />
										<NatFormGroupLabel label="Удаленные" labelColumnSettings="7" labelColumnClassName="text-left align-self-center text-black pl-0" inputColumnClassName="pl__4_5" row={false}>
											<NatDefaultCheckbox
												object={this.props.store.chipsFields.deleted.display}
												property="isChecked"
												columnClassName=""
												wrapperClassName="checkbox"
												row={true}
												label={this.props.store.chipsFields.deleted.display.title}
											/>
											<NatDefaultCheckbox
												object={this.props.store.chipsFields.deleted.doNotDisplay}
												property="isChecked"
												columnClassName=""
												wrapperClassName="checkbox"
												row={true}
												label={this.props.store.chipsFields.deleted.doNotDisplay.title}
											/>
										</NatFormGroupLabel>
									</>
								</NatFilters>
							)}
						</CForm>
					</div>
				</CCardHeader>
				<div className={this.injected.directoryStore.models.windowSize >= 801 ? 'default__nat__card__body__height d-flex m-4' : 'default__nat__card__body__height m-4 d-flex flex-column'}>
					<div className={this.injected.directoryStore.models.windowSize >= 801 ? 'nat__tree nat__tree__wrapper nat__borders__left__2-75' : 'nat__tree nat__card__wrapper nat__borders__2-75 nat__tree__mobile mb-4'}>
						<TreeMenu activeKey={this.props.store.treeState.activeKey} focusKey={this.props.store.treeState.focusKey} openNodes={this.props.store.treeState.openNodes} data={this.props.store.groupsTree} hasSearch={false}>
							{({ items }) => (
								<div ref={this.treeRef}>
									<ul className="rstm-tree-item-group p-3 mb-0" ref={this.props.childrenProps.treeRef}>
										{items.map((item) => (
											<NatTreeButtons
												key={item.key}
												buttonItemList={[
													{
														variant: 'ghost',
														className: 'd-flex nat__tree__button',
														onClick: () => {
															this.props.goTo(`${this.props.navigationRoute.url}/` + item.object.id);
														},
														icon: <CIcon name="cil-pencil" className="align-self-center p-0 m-0 nat__tree__item__icon__height" />,
														show: !_.isEmpty(item.object.parentId)
													},
													{
														variant: 'ghost',
														className: 'd-flex nat__tree__button',
														onClick: () => {
															this.props.store.setValue(this.props.store, 'group', item.object);
															this.toggleDeleteGroupModal(true);
														},
														icon: <CIcon name="cil-trash" className="align-self-center p-0 m-0 nat__tree__item__icon__height" />,
														show: !_.isEmpty(item.object.parentId)
													}
												]}>
												<NatTreeItemComponent
													key={item.key}
													level={item.level}
													label={item.label}
													isOpen={item.isOpen}
													hasNodes={item.hasNodes}
													active={item.active}
													focused={item.focused}
													openedIcon={<CIcon src={natOpenTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
													closedIcon={<CIcon src={natCloseTriangle} className="p-0 m-0 nat__tree__item__icon__height" />}
													style={(() => {
														if (item.object.deleted) {
															return {
																color: '#ced2d8'
															};
														}
													})()}
													onClick={() => {
														this.props.childrenProps.groupTreeItemOnClick(item);
														this.toggleNodeByNode(item);
													}}
													toggleNode={() => {
														this.toggleNodeByIcon(item);
													}}
												/>
											</NatTreeButtons>
										))}
									</ul>
								</div>
							)}
						</TreeMenu>
					</div>
					<div
						className={
							this.injected.directoryStore.models.windowSize >= 801
								? 'nat__page__table__wrapper nat__card__wrapper nat__borders__right__2-75 w-100 nat__table__hover'
								: 'nat__page__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover h-100'
						}>
						<div className="nat__page__table" ref={this.myRef}>
							<GridTable
								columns={this.props.columns}
								rows={this.props.store.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют', selected: 'Выбрано' }}
								onRowClick={({ data, column }: { data: any; column: any }) => {
									if (column.id !== 'checkbox') {
										this.props.goTo(`${this.props.navigationRoute.url}/` + data.id);
									}
								}}
								showSearch={false}
								showColumnVisibilityManager={false}
								onColumnsChange={() => {}}
								showRowsInformation={false}
								selectedRowsIds={_.map(this.props.store.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => this.props.childrenProps.collectModels(selectedRowsIds)}
								onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
									this.sortList(colId, isAsc);
								}}
								sort={this.props.store.sortConfig}
							/>
						</div>
					</div>
				</div>
				{this.props.store.responseCode === 0 && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.props.childrenProps.isAclModalOpen && (
					<NatAclMapping isModalOpen={this.props.childrenProps.isAclModalOpen} toggleNatAclMapping={this.props.childrenProps.toggleAclModal} subject={this.props.store.collectedModels} model={this.props.store.name} />
				)}
				{this.state.isBulkEditingManagerOpen && (
					<NatBulkEditingManager
						isModalOpen={this.state.isBulkEditingManagerOpen}
						toggleNatBulkEditingManager={this.toggleNatBulkEditingManager}
						bulkUpsert={this.props.childrenProps.bulkUpsert}
						items={this.props.store.collectedModels}
						columns={this.props.columns}
						fields={this.props.editingFields}
					/>
				)}
				<AlertDialog
					isModalOpen={this.state.isDeleteGroupModalOpen}
					title="Выполнить действие?"
					buttonItemList={[
						{
							title: 'ДА',
							onClick: () => {
								this.toggleDeleteGroupModal(false);
								let groupedProductSetsGroups = _.chain(this.props.store.groups).cloneDeep().keyBy('id').value();
								catalog
									.makeDeleted(this.props.store.pluralName, [groupedProductSetsGroups[this.props.store.group.id as string]])
									.then(() => {
										let groupFilter = {
											where: this.props.store.filterGroupWhere,
											order: this.props.store.filterGroupOrder
										};
										return controller.findAll(this.props.store.pluralName, groupFilter);
									})
									.then((data) => {
										if (!_.isEmpty(data)) {
											this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree(data));
										} else {
											this.props.store.setValue(this.props.store, 'groupsTree', this.props.childrenProps.buildTree([]));
										}
										this.props.childrenProps.makeTooltips(this.props.childrenProps.treeRef);
									})
									.catch((error) => {
										catalog.handleNatError(error);
									});
							}
						},
						{
							title: 'ОТМЕНА',
							onClick: () => {
								this.toggleDeleteGroupModal(false);
							}
						}
					]}
				/>
			</div>
		);
	}
}
export default withRouter(NatHierarchicalList);
