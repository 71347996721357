import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import NavigationContainer from './NavigationContainer';

class Content extends Component {
	render() {
		return (
			<Switch>
				<Route path="/" render={() => <NavigationContainer />} />
			</Switch>
		);
	}
}

export default Content;
