import _ from 'lodash';
import { IReactionDisposer, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Item } from 'react-simple-tree-menu';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { NavigationContainerStoreType, NavigationRouteType } from '../ContainersStores/NavigationContainerStore';
import { catalog } from '../support/Catalog';
import { models } from '../support/NatModels';
import { MatchType } from '../support/modelTypes';

export interface ChildrenPropsType {
	collectModels(arrayIds: Array<any>): void;
	showList(): void;
	resetSearchFields(): void;
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	returnToRoot(): void;
	groupTreeItemOnClick(item: Item): void;
	buildTree(list: Array<any>, id?: string, parent?: string, children?: string): void;
	isReady: boolean;
	treeRef: any;
	makeTooltips(ref: any): void;
	toggleAclModal(status: boolean): void;
	isAclModalOpen: boolean;
	bulkUpsert(actionAfter?: () => void): void;
	hierarchical?: boolean;
	ownerId?: string;
	enumerationItems?: string;
	createFilter(): void;
}

interface PropsType extends RouteComponentProps {
	location: any;
	match: MatchType;
	children(childrenProps: ChildrenPropsType): ReactElement;
	store: any;
	createFilter(): any;
	resetSearchFields(): void;
	defaultFilterWhere: any;
	hierarchical?: boolean;
	loadPage?: () => void;
	navigationRoute: NavigationRouteType;
	index: number;
	ownerId?: string;
	enumerationItems?: string;
}

interface StateType {
	isReady: boolean;
	isAclModalOpen: boolean;
}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('navigationContainerStore', 'directoryStore', 'userStore')
@observer
class NatListContainer extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	treeRef: any;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.treeRef = React.createRef();
		this.showList = this.showList.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.createFilter = this.createFilter.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.groupTreeItemOnClick = this.groupTreeItemOnClick.bind(this);
		this.returnToRoot = this.returnToRoot.bind(this);
		this.buildTree = this.buildTree.bind(this);
		this.createChips = this.createChips.bind(this);
		this.makeTooltips = this.makeTooltips.bind(this);
		this.toggleAclModal = this.toggleAclModal.bind(this);
		this.updateData = this.updateData.bind(this);
		this.fetchChildGroupIds = this.fetchChildGroupIds.bind(this);
		this.bulkUpsert = this.bulkUpsert.bind(this);
		this.state = {
			isReady: false,
			isAclModalOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		if (this.props.navigationRoute.url === this.props.match.url) {
			if (this.props.store.isFirstLoad) {
				this.loadPage();
			} else {
				this.updateData();
			}
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	loadPage() {
		document.title = 'Журнал';
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		if (this.props.loadPage !== undefined) {
			this.props.loadPage();
		}
		if (this.props.hierarchical) {
			let groupFilter = {
				where: this.props.store.filterGroupWhere,
				order: this.props.store.filterGroupOrder
			};
			controller
				.findAll(this.props.store.pluralName, groupFilter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.store.setValue(this.props.store, 'groupsTree', this.buildTree(data));
					} else {
						this.props.store.setValue(this.props.store, 'groupsTree', this.buildTree([]));
					}
					this.makeTooltips(this.treeRef);
					this.props.store.setValue(this.props.store, 'isFirstLoad', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		} else {
			this.props.store.setValue(this.props.store, 'isFirstLoad', false);
		}
		let filter: any = {
			skip: this.props.store.filterSkip,
			limit: this.props.store.itemsPerPage,
			order: this.props.store.filterOrder,
			include: this.props.store.filterInclude
		};
		if (!_.isEmpty(this.props.store.filterWhere)) {
			filter.where = this.props.store.filterWhere;
			this.createChips();
		}
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				}
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			});
		catalog.generateTitle(this.props.navigationRoute, '', 'list');
	}
	createChips() {
		let dates: Array<any> = [];
		let prices: Array<any> = [];
		let other: Array<any> = [];
		_.forIn(this.props.store.chipsFields, (value) => {
			if (value.isDate) {
				dates = dates.concat(_.get(value, 'chips', []));
			} else if (value.isPrice) {
				prices = prices.concat(_.get(value, 'chips', []));
			} else {
				other = other.concat(value.chips);
			}
		});
		this.props.store.setValue(this.props.store, 'chips', dates.concat(prices.concat(other)));
	}
	// createFilter() {
	// 	let filter = this.props.createFilter();
	// 	if (!this.props.store.searchFields.deleted) {
	// 		filter.where.deleted = false;
	// 	} else {
	// 		delete filter.where.deleted;
	// 	}
	// 	return filter;
	// }
	createFilter() {
		let and: Array<any> = [];
		let date: { createdAtGt: { createdAt: { gt: string } }; createdAtLt: { createdAt: { lt: string } }; periodAtGt: { periodAt: { gt: string } }; periodAtLt: { periodAt: { lt: string } } } = {
			createdAtGt: {
				createdAt: {
					gt: ''
				}
			},
			createdAtLt: {
				createdAt: {
					lt: ''
				}
			},
			periodAtGt: {
				periodAt: {
					gt: ''
				}
			},
			periodAtLt: {
				periodAt: {
					lt: ''
				}
			}
		};
		let amountGte: { amount: { gte: number } } = {
			amount: {
				gte: 0
			}
		};
		let amountLte: { amount: { lte: number } } = {
			amount: {
				lte: 0
			}
		};
		let filter: any = {
			where: this.props.store.filterWhere,
			skip: this.props.store.filterSkip,
			limit: this.props.store.itemsPerPage,
			order: this.props.store.filterOrder,
			include: this.props.store.filterInclude
		};
		let defaultFilterWhere = this.props.defaultFilterWhere;
		if (this.props.store.filterWhere.parentId !== undefined) {
			defaultFilterWhere.parentId = this.props.store.filterWhere.parentId;
		}
		this.props.store.setValue(this.props.store, 'filterWhere', defaultFilterWhere);
		_.forEach(this.props.store.chipsFields, (item, key) => {
			if (item.isRelation) {
				if (!_.isEmpty(this.props.store.chipsFields[key].chips)) {
					let ids = _.chain(this.props.store.chipsFields[key].chips)
						.cloneDeep()
						.transform((result: any, value: any) => {
							result.push(value.id);
						}, [])
						.value();
					filter.where[key + 'Id'] = { inq: ids };
				}
			} else {
				if (item.isDate) {
					if (item.id !== undefined && item.id !== null) {
						if (item.isGt) {
							date[key] = {
								[_.get(item, 'property', 'createdAt')]: {
									gt: item.id.toISOString()
								}
							};
							and.push(date[key]);
							filter.where.and = and;
						} else {
							date[key] = {
								[_.get(item, 'property', 'createdAt')]: {
									lt: item.id.toISOString().substring(0, 10) + 'T23:59:00.000Z'
								}
							};
							and.push(date[key]);
							filter.where.and = and;
						}
					}
				} else if (item.isPrice) {
					if (item.id !== undefined && item.id !== null) {
						if (item.isGte) {
							amountGte = {
								amount: {
									gte: item.id
								}
							};
							and.push(amountGte);
							filter.where.and = and;
						} else {
							amountLte = {
								amount: {
									lte: item.id
								}
							};
							and.push(amountLte);
							filter.where.and = and;
						}
					}
				} else if (item.isBoolean) {
					if (item.id) {
						delete filter.where[key];
					} else {
						filter.where[key] = false;
					}
				} else if (item.isLocalizedString) {
					if (!_.isEmpty(item.id)) {
						filter.where[`${key}.${this.injected.directoryStore.models.language}`] = {
							like: item.id,
							options: 'i'
						};
					} else {
						delete filter.where[`${key}.${this.injected.directoryStore.models.language}`];
					}
				} else {
					if (!_.isEmpty(item.id)) {
						filter.where[key] = {
							like: item.id,
							options: 'i'
						};
					} else {
						delete filter.where[key];
					}
				}
			}
		});
		return filter;
	}
	showList() {
		this.props.store.setValue(this.props.store, 'filterSkip', 0);
		this.props.store.setValue(this.props.store, 'currentPage', 0);
		let filter = this.createFilter();
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				} else {
					this.props.store.setValue(this.props.store, 'list', []);
				}
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.props.store.list).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.props.store.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.props.store.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.props.store.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.props.store.setValue(this.props.store, 'collectedModels', buffer);
	}
	groupTreeItemOnClick(item: Item) {
		this.props.store.setValue(this.props.store.treeState, 'activeKey', item.key);
		this.props.store.setValue(this.props.store.treeState, 'focusKey', item.key);
		if (!_.isEmpty(item.object.parentId)) {
			let idArray = [] as Array<string>;
			this.fetchChildGroupIds(item.object, idArray);
			this.props.store.setValue(this.props.store, 'group', item.object);
			this.props.store.setValue(this.props.store, 'idArray', idArray);
			this.props.store.setValue(this.props.store, 'responseCode', 0);
			this.props.store.setValue(this.props.store, 'list', []);
			this.props.store.setValue(this.props.store, 'currentPage', 0);
			this.props.store.setValue(this.props.store, 'filterSkip', 0);
			let filter = this.createFilter();
			filter.where.parentId = { inq: idArray } as any;
			this.props.store.setValue(this.props.store, 'filterWhere', filter.where);
			controller
				.findAll(this.props.store.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.props.store.setValue(this.props.store, 'list', data);
					} else {
						this.props.store.setValue(this.props.store, 'list', []);
					}
					this.props.store.setValue(this.props.store, 'responseCode', 200);
					this.makeTooltips(this.treeRef);
				})
				.catch((error) => {
					catalog.handleNatError(error);
				});
		} else {
			this.returnToRoot();
		}
	}
	returnToRoot() {
		this.props.store.setValue(this.props.store, 'group', null);
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		this.props.store.setValue(this.props.store, 'currentPage', 0);
		this.props.store.setValue(this.props.store, 'list', []);
		this.props.store.setValue(this.props.store, 'filterSkip', 0);
		let filterWhere = _.cloneDeep(this.props.store.filterWhere);
		delete filterWhere.parentId;
		this.props.store.setValue(this.props.store, 'filterWhere', filterWhere);
		let filter = this.createFilter();
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				} else {
					this.props.store.setValue(this.props.store, 'list', []);
				}
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	buildTree(list: Array<any>) {
		let groups: Array<any> = list;
		for (let item of groups) {
			if (item.parentId === null) {
				item.parentId = this.props.store.rootId;
			}
		}
		groups.push({
			parentId: null,
			id: this.props.store.rootId,
			name: { ru: models[this.props.navigationRoute.store.name].pluralDisplayName }
		});
		let groupByParentId = _.groupBy(groups, (value) => {
			return value.parentId || 'null';
		});
		let handleGroup = (item, nodes) => {
			let treeItem = {
				label: item.name[this.injected.directoryStore.models.language],
				key: item.id,
				object: item,
				nodes: []
			};
			nodes.push(treeItem);
			let childGroupList = groupByParentId[item.id];
			if (childGroupList && childGroupList.length > 0) {
				for (let childGroup of childGroupList) {
					handleGroup(childGroup, treeItem.nodes);
				}
			}
		};
		let result = [] as Array<any>;
		let rootGroupList = _.get(groupByParentId, 'null');
		if (rootGroupList && rootGroupList.length > 0) {
			for (let rootGroup of rootGroupList) {
				handleGroup(rootGroup, result);
			}
		}
		if (this.props.store.isFirstLoad) {
			this.props.store.setValue(this.props.store.treeState, 'openNodes', [this.props.store.rootId]);
			this.props.store.setValue(this.props.store.treeState, 'nodes', [{ key: this.props.store.rootId }]);
		}
		this.injected.store.setValue(this.injected.store, 'groups', groups);
		return result;
	}
	makeTooltips(ref: any) {
		if (ref.current) {
			_.forEach(ref.current.querySelectorAll('li'), (item) => {
				let text = item.textContent.slice(item.textContent.indexOf('+') + 1);
				if (_.isEmpty(item.title)) {
					item.title = text;
				}
			});
		}
	}
	toggleAclModal(status: boolean) {
		this.setState({
			isAclModalOpen: status
		});
	}
	fetchChildGroupIds(item: any, result: Array<any>) {
		result.push(item.id);
		let groupByParentId = _.groupBy(this.props.store.groups, (value) => {
			return value.parentId || 'null';
		});
		let childGroupList = groupByParentId[item.id];
		if (childGroupList && childGroupList.length > 0) {
			for (let childGroup of childGroupList) {
				if (this.props.store.showChildGroupsElements) {
					this.fetchChildGroupIds(childGroup, result);
				}
			}
		}
	}
	updateData() {
		let groupFilter = {
			where: this.props.store.filterGroupWhere,
			order: this.props.store.filterGroupOrder
		};
		this.props.store.setValue(this.props.store, 'filterSkip', 0);
		this.props.store.setValue(this.props.store, 'currentPage', 0);
		let filter = this.createFilter();
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		if (this.props.hierarchical) {
			let idArray = [] as Array<string>;
			if (!_.isEmpty(this.props.store.group)) {
				this.fetchChildGroupIds(this.props.store.group, idArray);
				filter.where.parentId = { inq: idArray } as any;
			}
		}
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'list', data);
				}
				if (this.props.hierarchical) {
					return controller.findAll(this.props.store.pluralName, groupFilter);
				} else {
					this.props.store.setValue(this.props.store, 'responseCode', 200);
				}
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store, 'groupsTree', this.buildTree(data));
				} else {
					this.props.store.setValue(this.props.store, 'groupsTree', this.buildTree([]));
				}
				this.makeTooltips(this.treeRef);
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			});
	}
	bulkUpsert(actionAfter?: () => void) {
		let editItems = _.cloneDeep(this.props.store.collectedModels);
		let bulkEditingFields = this.props.store.bulkEditingFields;
		_.forIn(bulkEditingFields, (value, key) => {
			if (!_.isObject(value)) {
				for (let editItem of editItems) {
					editItem[key] = value;
				}
			} else {
				if (_.isArray(value)) {
					for (let editItem of editItems) {
						editItem[key] = value;
					}
				}
			}
		});
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		controller
			.bulkUpsert(this.props.store.pluralName, editItems)
			.then(() => {
				this.props.store.setValue(this.props.store, 'responseCode', 200);
				if (actionAfter !== undefined) {
					actionAfter();
				}
				this.props.store.setValue(this.props.store, 'collectedModels', []);
				this.showList();
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'responseCode', 200);
			});
	}
	render() {
		let childrenProps = {
			collectModels: this.collectModels,
			showList: this.showList,
			directoryStore: this.injected.directoryStore,
			userStore: this.injected.userStore,
			resetSearchFields: this.props.resetSearchFields,
			returnToRoot: this.returnToRoot,
			groupTreeItemOnClick: this.groupTreeItemOnClick,
			buildTree: this.buildTree,
			isReady: this.props.store.isReady,
			treeRef: this.treeRef,
			makeTooltips: this.makeTooltips,
			toggleAclModal: this.toggleAclModal,
			isAclModalOpen: this.state.isAclModalOpen,
			bulkUpsert: this.bulkUpsert,
			hierarchical: this.props.hierarchical,
			ownerId: this.props.ownerId,
			enumerationItems: this.props.enumerationItems,
			createFilter: this.createFilter
		};
		return <>{this.props.children(childrenProps)}</>;
	}
}

export default withRouter(NatListContainer);
