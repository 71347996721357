import _ from 'lodash';

import { catalog } from '../../support/Catalog';
import { LoyaltyPolicyRuleType } from '../../support/modelTypes';
import AutocompleteAdapter from './AutocompleteAdapter';

class LoyaltyPolicyRuleAutocompleteAdapter extends AutocompleteAdapter<LoyaltyPolicyRuleType, string> {
	public fetch(source: LoyaltyPolicyRuleType): Promise<Array<string>> {
		let result: string = '';
		let whichProducts = '';
		if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.none') {
			whichProducts = 'по всей номенклатуре';
		} else if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.list') {
			whichProducts = 'по номенклатуре из списка';
		} else if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.scheme') {
			whichProducts = 'по номенклатуре по отбору';
		} else if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.segment') {
			whichProducts = `по номенклатуре из сегмента: ${catalog.renderCatalogName(source.productSegment)}`;
		}

		let comparisonType = _.toLower(catalog.renderCatalogName(source.comparisonType));

		if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.one_time_sales') {
			if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount') {
				result += `Сумма в документе ${comparisonType} ${source.conditionValue} руб. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity') {
				result += `Количество в документе ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_different') {
				result += `Количество различных наименований в документе ${comparisonType} ${source.conditionValue} шт. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_identical') {
				result += `Количество одинаковых позиций в документе ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count') {
				result += `Срок в документе ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count_of_identical') {
				result += `Срок одинаковых позиций в документе ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			}
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.accumulated_sales') {
			if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount') {
				result += `Сумма проданной номенклатуры ${comparisonType} ${source.conditionValue} руб. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity') {
				result += `Количество проданной номенклатуры ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_different') {
				result += `Количество различных наименований проданной номенклатуры ${comparisonType} ${source.conditionValue} шт. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.quantity_of_identical') {
				result += `Количество одинаковых позиций проданной номенклатуры ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count') {
				result += `Срок проданной номенклатуры ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			} else if (source.volumeOfSalesLimitationCriteriaId === 'loyalty_policy_rule_volume_of_sales_limitation_criteries.period_count_of_identical') {
				result += `Срок одинаковых позиций проданной номенклатуры ${comparisonType} ${source.conditionValue} ед. ${whichProducts}`;
			}
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.first_sale') {
			result += 'Первая покупка';
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.time_of_sale') {
			result += 'За интервал времени';
			let timeOfSaleIntervalList = source.timeOfSaleIntervalList || source.timeOfSaleIntervals || [];
			for (let timeOfSaleInterval of timeOfSaleIntervalList) {
				result += ` (с ${timeOfSaleInterval.start} до ${timeOfSaleInterval.end} ${catalog.renderCatalogName(timeOfSaleInterval.periodicity)})`;
			}
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.payment_option') {
			result += `Способ оплаты: ${catalog.renderCatalogName(source.paymentOption)}`;
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.presence_loyalty_card') {
			result += `Клиент владелец карты лояльности "${catalog.renderCatalogName(source.loyaltyCardKind)}"`;
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.absence_loyalty_card') {
			result += `Карта лояльности "${catalog.renderCatalogName(source.loyaltyCardKind)}" не зарегистрирована`;
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.birthdate') {
			result += 'День рождения клиента';
		} else if (source.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.partner_segment') {
			result += `Клиент входит в сегмент: ${catalog.renderCatalogName(source.partnerSegment)}`;
		}
		return Promise.resolve([result]);
	}
}

export let loyaltyPolicyRuleAutocompleteAdapter = new LoyaltyPolicyRuleAutocompleteAdapter();

export default LoyaltyPolicyRuleAutocompleteAdapter;
