import { observable, action, makeObservable, set } from 'mobx';

import { ProductBarcodeType } from '../../shared/support/modelTypes';

export interface ProductBarcodeFormStoreType {
	model: ProductBarcodeType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	setValue(model: any, key: string, value: any): void;
}

class ProductBarcodeFormStore implements ProductBarcodeFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			setValue: action
		});
	}

	model = {
		productId: null,
		featureId: null,
		unitId: null,
		barcode: '',
		unit: {
			baseUnitId: null,
			unitId: '',
			deleted: false,
			displayName: null,
			elementType: 0,
			name: { ru: '' },
			ownerId: null,
			ownerType: null,
			packageTypeId: 'unit_package_types.final',
			parentId: null,
			priority: null,
			quantityOfOwnerUnitIncludedInPackage: 0,
			quantityOfPackageIncludedInOwnerUnit: 1,
			quantityOfParentPackageIncludedInPackage: 0,
			typeId: null
		},
		feature: {
			deleted: false,
			displayName: null,
			name: { ru: '' },
			ownerId: null,
			ownerType: null,
			priority: null
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'productBarcodes';
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const productBarcodeFormStore = new ProductBarcodeFormStore();

export default ProductBarcodeFormStore;
