import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CFormGroup, CInput, CLabel } from '@coreui/react';
import _ from 'lodash';
import { reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import enhanceWithClickOutside from 'react-click-outside';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import ValueFormatter from '../Formatters/ValueFormatter';
import NatRelationInputStore, { NatRelationInputStoreType } from './InputsStore/NatRelationInputStore';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	inputColumnClassName?: string;
	inputClassName?: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	type: string;
	invalid?: boolean;
	placeholder: string;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	list?: Array<any>;
	label?: string;
	formGroupClassName?: string;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatStringSelectInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	natRelationInputStore: NatRelationInputStoreType;
	id: string;
	constructor(props: PropsType<T>) {
		super(props);
		this.natRelationInputStore = new NatRelationInputStore();
		this.handleEnter = this.handleEnter.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.id = generateRandomId();
	}
	componentDidMount() {
		this.reactions.push(
			reaction(
				() => this.props.object[this.props.property],
				(value: T, previousValue: T) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	get injected() {
		return this.props as InjectedProps;
	}
	handleChange() {}
	toggleDropDown() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', !this.natRelationInputStore.showDropdown);
	}
	handleClickOutside() {
		this.natRelationInputStore.setValue(this.natRelationInputStore, 'showDropdown', false);
	}
	handleEnter(event: any) {
		if (event.keyCode === 13) {
			let form = event.target.form;
			if (form && form.length > 0) {
				let index = Array.prototype.indexOf.call(form, event.target);
				let length = form.length;
				let nextElement = null;
				for (index; index < length; index++) {
					let nextElement = _.get(form, `elements[${index + 1}]`, null);
					if (nextElement && !nextElement.disabled && nextElement.type !== 'checkbox') {
						nextElement.focus();
						break;
					} else {
						nextElement = null;
					}
				}
				if (!nextElement) {
					event.target.blur();
				}
				event.preventDefault();
			}
		}
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value);
	}

	renderValue(value: T | undefined): string {
		return this.props.formatter.renderValue(value);
	}

	setValue(model: any) {
		let object: any = this.props.object;
		runInAction(() => {
			object[this.props.property] = model.name;
		});
	}

	getValue(): T {
		let object: any = this.props.object;
		let listMap = _.chain(this.props.list).cloneDeep().keyBy('name').value();
		let listItem = listMap[object[this.props.property]];
		if (listItem !== undefined) {
			return listItem;
		}
		return '' as any;
	}
	render() {
		return (
			<CDropdown className="nat__dropdown">
				<CFormGroup
					row
					className={(() => {
						if (_.isEmpty(this.props.formGroupClassName)) {
							if (this.injected.directoryStore.models.windowSize < 490) {
								return 'w-100 align-items-center nat__form__group flex-nowrap';
							} else {
								return 'w-100 align-items-center nat__form__group';
							}
						} else {
							return this.props.formGroupClassName;
						}
					})()}>
					{!_.isEmpty(this.props.label) && (
						<CLabel
							htmlFor={this.id}
							className={(() => {
								if (this.props.label) {
									if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
										return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile_100';
									} else {
										return 'align-self-center text-truncate pl-3 m-0';
									}
								}
							})()}>
							{this.props.label}
						</CLabel>
					)}
					<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0' : 'align-self-center pr-0 pl-0'}>
						<CInput
							id={this.id}
							invalid={this.props.invalid}
							type={this.props.type}
							name={this.props.property}
							placeholder={this.props.placeholder}
							value={this.state.value}
							onChange={this.handleChange}
							size={this.props.size}
							className={this.props.inputClassName}
							autoComplete={this.props.autoComplete}
							onClick={() => {
								if (!this.natRelationInputStore.showDropdown) {
									this.toggleDropDown();
								}
							}}
							onKeyDown={this.handleEnter}
							disabled={this.props.disabled}
						/>
					</CCol>
				</CFormGroup>
				<CDropdownMenu className="nat__dropdown__list rounded-0" show={this.natRelationInputStore.showDropdown}>
					{(() => {
						let array = [] as Array<any>;
						if (this.props.list !== undefined) {
							array = this.props.list;
						}
						return array.map((item: any, index: number) => {
							return (
								<CDropdownItem
									key={index}
									onClick={() => {
										if (item) {
											this.setValue(item);
											this.toggleDropDown();
										}
									}}>
									{item.displayName}
								</CDropdownItem>
							);
						});
					})()}
				</CDropdownMenu>
			</CDropdown>
		);
	}
}

export default enhanceWithClickOutside(NatStringSelectInput);
