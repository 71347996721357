import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyPolicyRuleType } from '../../shared/support/modelTypes';

export interface LoyaltyPolicyRuleFormStoreType {
	model: LoyaltyPolicyRuleType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	isFirstLoad: boolean;
	name: string;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class LoyaltyPolicyRuleFormStore implements LoyaltyPolicyRuleFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		paymentOptionId: null,
		loyaltyCardKindId: null,
		partnerSegmentId: null,
		productSegmentId: null,
		conditionOfProvidingId: null,
		comparisonTypeId: null,
		productSelectionOptionId: 'loyalty_policy_product_selection_options.none',
		volumeOfSalesLimitationCriteriaId: 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount',
		validityPeriodId: null,
		daysBeforeBirthday: 0,
		daysAfterBirthday: 0,
		includeCurrentSaleInAccumulatedSalesVolume: false,
		useFeatures: true,
		conditionValue: 0,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		elementType: 0,
		parentId: null,
		timeOfSaleIntervals: [],
		timeOfSaleIntervalList: [],
		productSelectionOption: {
			id: 'loyalty_policy_product_selection_options.none',
			keyId: 'none',
			ownerId: 'loyalty_policy_product_selection_options',
			name: {
				ru: 'Без ограничений',
				id: '94a4b215-056f-40d8-91e4-5c4ccb968c83'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'LoyaltyPolicyProductSelectionOptionsNone',
			predefinedVersion: 0
		},
		volumeOfSalesLimitationCriteria: {
			id: 'loyalty_policy_rule_volume_of_sales_limitation_criteries.amount',
			keyId: 'amount',
			ownerId: 'loyalty_policy_rule_volume_of_sales_limitation_criteries',
			name: {
				ru: 'Сумма',
				id: 'f139e2d2-6768-463e-a7ac-7843f1e30531'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'LoyaltyPolicyRuleVolumeOfSalesLimitationCriteriaAmount',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'loyaltyPolicyRules';
	filterInclude = [
		'paymentOption',
		'loyaltyCardKind',
		'conditionOfProviding',
		'volumeOfSalesLimitationCriteria',
		'comparisonType',
		'productSelectionOption',
		'validityPeriod',
		'partnerSegment',
		'productSegment',
		{
			relation: 'timeOfSaleIntervals',
			scope: {
				include: ['conditionType', 'periodicity']
			}
		}
	];
	selectedItems = [] as Array<any>;
	searchText = '';
	isFirstLoad = true;
	name = 'OrmLoyaltyPolicyRule';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const loyaltyPolicyRuleFormStore = new LoyaltyPolicyRuleFormStore();

export default LoyaltyPolicyRuleFormStore;
