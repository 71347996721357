import { CCol, CFormGroup, CInputRadio, CLabel } from '@coreui/react';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import ValueFormatter from '../Formatters/ValueFormatter';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	// wrapperClassName?: string;
	// // columnClassName: string;
	inline: boolean;
	row: boolean;
	// checkboxClassname?: string;
	// disabled?: boolean;
	// indeterminate?: boolean;
	options: Array<{ title: string; value: string | number | readonly string[] | undefined }>;
	formatter: ValueFormatter<T | undefined | null, string>;
	labelMd: string;
	optionsMd: string;
	className?: string;
}

@observer
class NatDefaultRadio<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType<T>) {
		super(props);
		this.reactions = [];
		this.myRef = React.createRef();
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			value: e.target.value
		});
		this.setValue(this.parseValue(e.target.value));
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value, this.props.object[this.props.property]);
	}

	renderValue(value: T | undefined | null): string {
		return this.props.formatter.renderValue(value);
	}
	render() {
		return (
			<CFormGroup row={this.props.row} className={this.props.className ? 'align-items-center mb-0 ' + this.props.className : 'align-items-center mb-0'}>
				<CCol md={this.props.labelMd}>
					<CLabel className="mb-0">{this.props.label}</CLabel>
				</CCol>
				<CCol md={this.props.optionsMd}>
					{_.map(this.props.options, (item, index) => {
						return (
							<CFormGroup variant="custom-radio" inline={this.props.inline} key={index}>
								<CInputRadio custom id={`inline-radio${index}`} name="inline-radios" value={item.value} onChange={this.handleChange} checked={this.state.value === item.value} />
								<CLabel variant="custom-checkbox" htmlFor={`inline-radio${index}`}>
									{item.title}
								</CLabel>
							</CFormGroup>
						);
					})}
				</CCol>
			</CFormGroup>
		);
	}
}

export default NatDefaultRadio;
