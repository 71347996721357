import { observable, action, makeObservable, set } from 'mobx';

import { NaturalPersonsType } from '../../shared/support/modelTypes';

export interface NaturalPersonFormStoreType {
	model: NaturalPersonsType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class NaturalPersonFormStore implements NaturalPersonFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		birthdate: null,
		elementType: 0,
		parentId: null,
		genderId: null,
		itn: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'naturalPersons';
	filterInclude = ['gender'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmNaturalPerson';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const naturalPersonFormStore = new NaturalPersonFormStore();

export default NaturalPersonFormStore;
