import _ from 'lodash';
import { IReactionDisposer, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../Alerts/FormAlertStore';
import { NavigationContainerStoreType, NavigationRouteType } from '../ContainersStores/NavigationContainerStore';
import { NatSelectionStoreType } from '../Modals/NatSelectionStore';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';
import { UseLocation } from '../support/useLocationHoC';

export interface ChildrenPropsType {
	collectModels(arrayIds: Array<any>, array: Array<any>): void;
	makePosted(e: any, mode: string, saveWithoutClosing?: boolean): void;
	toggleAclModal(status: boolean): void;
	toggleDeleteModal(modalName: string): void;
	isAclModalOpen: boolean;
	modals: Array<string>;
	saveSelected(productItem: any): void;
	deleteProductItems(selectedItems: Array<any>, key?: string): void;
	toggleAlert(status: boolean): void;
	isAlertOpen: boolean;
	makeDeleted(): void;
	pushEmptyTemplate(template: any, key?: string): void;
	toggleNatSelection(index: number): void;
	selectionModals: Array<number>;
	buildFillItems(arrayIds: Array<string>): void;
	toggleNatRelatedDocumentsModal(status: boolean): void;
	isRelatedDocumentsModalOpen: boolean;
	isProductSetEditingModalOpen: boolean;
	toggleNatSetEditing(status: boolean): void;
	post(e: any): Promise<any>;
	toggleSequenceNumberChangingAlert(status: boolean): void;
	isSequenceNumberChangingModalOpen: boolean;
}
interface PropsType extends RouteComponentProps {
	match: MatchType;
	index: number;
	navigationRoute: NavigationRouteType;
	query: any;
	replace(newUrl: string, navigationRoute: any): void;
	makeReactions(): void;
	resetFormReactions(): void;
	loadPage(elementId: string, findModel: (elementId: string) => void, elementType?: number): void;
	extractStore(store: any): void;
	fillEmptyRelations(model: any): any;
	validate(): boolean;
	prepareModelForPost(model: any): any;
	parentRoute: string;
	store: any;
	children(childrenProps: ChildrenPropsType): ReactElement;
	getChildMethod(method: any, isDocument?: boolean): void;
	isDocument?: boolean;
	updateList?: (elementId: string) => void;
	notRecalculateAmount?: boolean;
	handlers?: Record<string, unknown>;
}

interface StateType {
	isAclModalOpen: boolean;
	modals: Array<string>;
	selectionModals: Array<number>;
	isAlertOpen: boolean;
	isRelatedDocumentsModalOpen: boolean;
	isProductSetEditingModalOpen: boolean;
	isSequenceNumberChangingModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	natSelectionStore: NatSelectionStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'natSelectionStore')
@observer
class NatDocumentFormContainer extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.findModel = this.findModel.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.makePosted = this.makePosted.bind(this);
		this.toggleAclModal = this.toggleAclModal.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.updateForm = this.updateForm.bind(this);
		this.saveSelected = this.saveSelected.bind(this);
		this.deleteProductItems = this.deleteProductItems.bind(this);
		this.toggleAlert = this.toggleAlert.bind(this);
		this.makeDeleted = this.makeDeleted.bind(this);
		this.pushEmptyTemplate = this.pushEmptyTemplate.bind(this);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.buildFillItems = this.buildFillItems.bind(this);
		this.toggleNatRelatedDocumentsModal = this.toggleNatRelatedDocumentsModal.bind(this);
		this.toggleNatSetEditing = this.toggleNatSetEditing.bind(this);
		this.post = this.post.bind(this);
		this.toggleSequenceNumberChangingAlert = this.toggleSequenceNumberChangingAlert.bind(this);
		this.state = {
			isAclModalOpen: false,
			isAlertOpen: false,
			modals: [],
			selectionModals: [],
			isRelatedDocumentsModalOpen: false,
			isProductSetEditingModalOpen: false,
			isSequenceNumberChangingModalOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		const query = this.props.query;
		const elementType = query.get('elementType');
		if (this.props.navigationRoute.url === this.props.match.url) {
			this.injected.navigationContainerStore.setValue(this.injected.navigationContainerStore, 'currentNavigationRouteIndex', this.props.index);
			if (this.props.store.isFirstLoad) {
				this.loadPage(this.props.match.params.elementId, elementType);
			} else {
				this.props.makeReactions();
				if (this.props.updateList !== undefined) {
					this.props.updateList(this.props.match.params.elementId);
				}
			}
			this.props.getChildMethod(this.makePosted, this.props.isDocument);
		}
	}
	componentDidUpdate() {
		if (!this.props.store.isFirstLoad) {
			this.props.makeReactions();
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.props.resetFormReactions();
	}
	loadPage(elementId: string, elementType?: number) {
		document.title = 'Журнал';
		this.props.loadPage(elementId, this.findModel, elementType);
		if (elementId === 'new') {
			if (!_.isEmpty(this.props.location.state)) {
				this.props.store.setValue(this.props.store, 'model', this.props.location.state);
				if (!_.isEmpty(this.props.store.model.productItems)) {
					for (let item of this.props.store.model.productItems) {
						item.id = generateRandomId();
					}
				}
				if (!_.isEmpty(this.props.store.model.priceTypeItems)) {
					for (let item of this.props.store.model.priceTypeItems) {
						item.id = generateRandomId();
					}
				}
			}
			this.props.makeReactions();
			this.props.store.setValue(this.props.store, 'isFirstLoad', false);
		}
	}
	findModel(elementId: string) {
		let filter = {
			where: { id: elementId },
			include: this.props.store.filterInclude,
			limit: 1
		};
		this.props.store.setValue(this.props.store, 'isLoading', true);
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					const model = this.props.fillEmptyRelations(data[0]);
					this.props.store.setValue(this.props.store, 'model', model);
					this.props.store.setValue(this.props.store, 'isChanged', false);
				} else {
					catalog.showAlert('Объект не найден!');
					this.props.store.setValue(this.props.store, 'isChanged', true);
				}
				this.props.makeReactions();
				catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
				this.props.store.setValue(this.props.store, 'isFirstLoad', false);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	onBlur(e: any) {
		let form = e.target.form;
		if (form !== undefined && form !== null) {
			let inputs = form.querySelectorAll('input');
			inputs.forEach((item: any) => {
				item.blur();
			});
		}
	}
	makePosted(e: any, mode: string, saveWithoutClosing?: boolean) {
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		this.onBlur(e);
		let isCorrect: boolean = true;
		if (!_.isEmpty(this.props.store.model.productItems)) {
			for (let item of this.props.store.model.productItems) {
				if (_.get(item, 'product.typeId', null) === 'product_types.subscription' || _.get(item, 'product.typeId', null) === 'product_types.subscription_option') {
					continue;
				} else {
					if (item.price === 0) {
						isCorrect = false;
					}
				}
			}
		}
		if (isCorrect) {
			const copyObject: any = this.props.prepareModelForPost(_.cloneDeep(this.props.store.model));
			if (this.props.validate()) {
				this.props.store.setValue(this.props.store, 'isLoading', true);
				this.reactions.forEach((dispose) => dispose());
				controller
					.makePosted(this.props.store.pluralName, mode, copyObject)
					.then((data) => {
						catalog.showAlert('Действие прошло успешно!');
						if (!_.isEmpty(data)) {
							let onSave = _.get(this.props.handlers, 'onSave') as (model: any) => Promise<void>;
							if (onSave) {
								return onSave(data);
							}
						}
						if (saveWithoutClosing) {
							if (!_.isEmpty(data)) {
								this.findModel(data.id);
								this.props.replace(this.props.parentRoute + data.id, this.props.navigationRoute);
							}
						}
						this.props.store.setValue(this.props.store, 'isLoading', false);
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.props.store.setValue(this.props.store, 'isLoading', false);
					});
			} else {
				catalog.showAlert('Ошибка при сохранении элемента! Проверьте правильность заполнения полей!');
			}
		} else {
			catalog.showAlert('Элементам табличной части необходимо задать цену.');
		}
	}
	post(e: any) {
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		this.onBlur(e);
		let isCorrect: boolean = true;
		if (!_.isEmpty(this.props.store.model.productItems)) {
			for (let item of this.props.store.model.productItems) {
				if (_.get(item, 'product.typeId', null) === 'product_types.subscription' || _.get(item, 'product.typeId', null) === 'product_types.subscription_option') {
					continue;
				} else {
					if (item.price === 0) {
						isCorrect = false;
					}
				}
			}
		}
		if (isCorrect) {
			const copyObject: any = this.props.prepareModelForPost(_.cloneDeep(this.props.store.model));
			if (this.props.validate()) {
				this.props.store.setValue(this.props.store, 'isLoading', true);
				this.reactions.forEach((dispose) => dispose());
				return controller
					.makePosted(this.props.store.pluralName, 'post', copyObject)
					.then((data) => {
						if (!_.isEmpty(data)) {
							let onSave = _.get(this.props.handlers, 'onSave') as (model: any) => Promise<void>;
							if (onSave) {
								return onSave(data);
							}
						}
					})
					.then(() => {
						this.props.store.setValue(this.props.store, 'isChanged', false);
						catalog.showAlert('Запись прошла успешно!');
						this.props.store.setValue(this.props.store, 'isLoading', false);
					});
			} else {
				return Promise.reject(new Error('Ошибка при сохранении элемента! Проверьте правильность заполнения полей!'));
			}
		} else {
			return Promise.reject(new Error('Элементам табличной части необходимо задать цену.'));
		}
	}
	makeDeleted() {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		catalog
			.makeDeleted(this.props.store.pluralName, [this.props.store.model])
			.then((response) => {
				if (response[0].status === 200) {
					catalog.showAlert('Действие прошло успешно!');
					this.findModel(response[0].data.id);
					this.props.replace(this.props.parentRoute + response[0].data.id, this.props.navigationRoute);
					this.props.store.setValue(this.props.store, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	collectModels(arrayIds: Array<any>, array: Array<any>) {
		let grouped = _.chain(array).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let model = grouped[item];
			if (!_.isEmpty(model)) {
				if (_.find(this.props.store.collectedModels, (itm: any) => itm.id === model.id) === undefined) {
					runInAction(() => {
						this.props.store.collectedModels.push(model);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.props.store.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.props.store.setValue(this.props.store, 'collectedModels', buffer);
	}
	toggleSequenceNumberChangingAlert(status: boolean) {
		this.setState({
			isSequenceNumberChangingModalOpen: status
		});
	}
	toggleNatRelatedDocumentsModal(status: boolean) {
		this.setState({
			isRelatedDocumentsModalOpen: status
		});
	}
	toggleAclModal(status: boolean) {
		this.setState({
			isAclModalOpen: status
		});
	}
	toggleAlert(status: boolean) {
		this.setState({
			isAlertOpen: status
		});
	}
	toggleNatSetEditing(status: boolean) {
		this.setState({
			isProductSetEditingModalOpen: status
		});
	}
	toggleDeleteModal(modalName: string) {
		const position = this.state.modals.indexOf(modalName);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(modalName);
		}
		this.setState({
			modals: newDetails
		});
	}
	updateForm(elementId: string) {
		this.findModel(elementId);
		this.props.replace(this.props.parentRoute + elementId, this.props.navigationRoute);
	}
	saveSelected(productItem: any) {
		const currentProductItemsMap = _.chain(this.props.store.selectedItems).cloneDeep().keyBy('id').value();
		runInAction(() => {
			if (currentProductItemsMap[productItem.id] === undefined) {
				this.props.store.selectedItems.push(productItem);
			} else {
				this.props.store.selectedItems.splice(this.props.store.selectedItems.indexOf(productItem), 1);
			}
		});
	}
	deleteProductItems(selectedItems: Array<any>, key?: string) {
		let itemsKey: string = 'productItems';
		if (key) {
			itemsKey = key;
		}
		const currentProductItemsMap = _.chain(this.props.store.model[itemsKey]).cloneDeep().keyBy('id').value();
		_.forEach(selectedItems, (item) => {
			if (currentProductItemsMap[item.id] !== undefined) {
				runInAction(() => {
					_.get(this.props.store, `model.${itemsKey}`, []).splice(_.get(this.props.store, `model.${itemsKey}`, []).indexOf(item), 1);
				});
			}
		});
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store, 'isChanged', true);
		if (!this.props.notRecalculateAmount) {
			this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store, `model.${itemsKey}`, [])));
		}
	}
	pushEmptyTemplate(template: any, key?: string) {
		let itemsKey: string = 'productItems';
		if (key) {
			itemsKey = key;
		}
		let arr: any = _.cloneDeep(this.props.store.model[itemsKey]);
		let copyTemplate = _.cloneDeep(template);
		copyTemplate.id = generateRandomId();
		arr.push(copyTemplate);
		this.props.store.setValue(this.props.store.model, `${itemsKey}`, arr);
		this.props.makeReactions();
	}
	toggleNatSelection(index: number) {
		const position = this.state.selectionModals.indexOf(index);
		let newDetails = this.state.selectionModals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			selectionModals: newDetails
		});
		if (!this.state.selectionModals.includes(index)) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	buildFillItems(arrayIds: Array<string>) {
		const ids = arrayIds;
		const itemsDict = _.chain(this.injected.natSelectionStore.list).cloneDeep().keyBy('id').value();
		_.map(ids, (item: string) => {
			const itm = itemsDict[item];
			if (!_.isEmpty(itm)) {
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedItems', [itm]);
				this.injected.natSelectionStore.setValue(this.injected.natSelectionStore, 'selectedIds', [itm.id]);
			}
		});
	}
	render() {
		let childrenProps = {
			collectModels: this.collectModels,
			makePosted: this.makePosted,
			toggleAclModal: this.toggleAclModal,
			isAclModalOpen: this.state.isAclModalOpen,
			toggleDeleteModal: this.toggleDeleteModal,
			modals: this.state.modals,
			saveSelected: this.saveSelected,
			deleteProductItems: this.deleteProductItems,
			toggleAlert: this.toggleAlert,
			isAlertOpen: this.state.isAlertOpen,
			makeDeleted: this.makeDeleted,
			pushEmptyTemplate: this.pushEmptyTemplate,
			toggleNatSelection: this.toggleNatSelection,
			selectionModals: this.state.selectionModals,
			buildFillItems: this.buildFillItems,
			toggleNatRelatedDocumentsModal: this.toggleNatRelatedDocumentsModal,
			isRelatedDocumentsModalOpen: this.state.isRelatedDocumentsModalOpen,
			isProductSetEditingModalOpen: this.state.isProductSetEditingModalOpen,
			toggleNatSetEditing: this.toggleNatSetEditing,
			post: this.post,
			toggleSequenceNumberChangingAlert: this.toggleSequenceNumberChangingAlert,
			isSequenceNumberChangingModalOpen: this.state.isSequenceNumberChangingModalOpen
		};
		return <>{this.props.children(childrenProps)}</>;
	}
}

export default UseLocation(withRouter(NatDocumentFormContainer));
