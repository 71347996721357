import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormGroup, CModal, CModalHeader, CRow, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../../shared/Alerts/AlertDialog';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { NavigationRouteType } from '../../shared/ContainersStores/NavigationContainerStore';
import { quantityFormatter } from '../../shared/Formatters/QuantityFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import ProductSelection from '../../shared/Modals/ProductSelection';
import { catalog } from '../../shared/support/Catalog';
import { ProductFormStoreType } from '../Product/ProductFormStore';
import NatSetManagerStore, { NatSetManagerStoreType } from './NatSetManagerStore';

interface PropsType extends RouteComponentProps {
	toggleNatSetManager(status: boolean): void;
	isModalOpen: boolean;
	productFormStore: ProductFormStoreType;
	goTo(path: string, state?: Record<string, unknown>, handlers?: Record<string, unknown>): void;
	navigationRoute: NavigationRouteType;
	replace(newUrl: string, navigationRoute: any): void;
}

interface StateType {
	columns: Array<any>;
	querySelector: any;
	isDeleteModalOpen: boolean;
	isProductSelectionOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatSetManager extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	productItemsReactions: Array<IReactionDisposer>;
	natSetManagerStore: NatSetManagerStoreType;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.productItemsReactions = [];
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.saveSetItems = this.saveSetItems.bind(this);
		this.getData = this.getData.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.natSetManagerStore = new NatSetManagerStore();
		this.reactions.push(
			reaction(
				() => this.natSetManagerStore.model.featureId,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.natSetManagerStore.setValue(this.natSetManagerStore, 'filterWhere', { productSetId: this.props.productFormStore.model.id, productSetFeatureId: value });
						this.getData();
					}
				}
			)
		);
		this.state = {
			isDeleteModalOpen: false,
			querySelector: null,
			isProductSelectionOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'productId',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productId"
									relation="product"
									placeholder="Выберите товар"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="products"
									filterWhere={{
										deleted: false,
										elementType: 0,
										typeId: {
											nin: ['product_types.set']
										}
									}}
									include={['unit', 'validityPeriod']}
									autoComplete="off"
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'unitId',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'featureId',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="featureId"
													relation="feature"
													placeholder="Выберите характеристику"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="productFeatures"
													autoComplete="off"
													apiMethod={(): any => {
														return controller.fetchFeaturesByProduct({ product: data.product });
													}}
													create={(pluralName, handlers) => {
														this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
													}}
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 4,
					field: 'validityPeriodId',
					label: 'Период действия',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
											return (
												<NatRelationInput
													object={data}
													property="validityPeriodId"
													relation="validityPeriod"
													placeholder="Выберите период"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'periodicity' }}
													autoComplete="off"
													mobile
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											);
										} else {
											return null;
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="quantity"
									placeholder="Введите кол-во"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={quantityFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'sharedAmount',
					label: 'Доля в сумме',
					width: '140px',
					checkCondition: () => {
						return this.props.productFormStore.model.setPriceCalculationMethodId === 'product_set_price_calculation_methods.by_set_price_via_shared_amount';
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="sharedAmount"
									placeholder="Введите кол-во"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={quantityFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'variableQuantity',
					label: 'Изменяемое количество',
					width: '200px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatCheckbox object={data} property="variableQuantity" />
							</div>
						);
					}
				},
				{
					id: 7,
					field: 'variableQuantityLimitRange',
					label: 'Диапазон ограничения количества',
					width: '200px',
					checkCondition: (item) => {
						return item.variableQuantity;
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<string> object={data} property="variableQuantityLimitRange" placeholder="Введите диапазон" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
							</div>
						);
					}
				},
				{
					id: 8,
					field: 'variableQuantityLimitMinByDefault',
					label: 'Ограничить минимальное количество',
					width: '200px',
					checkCondition: (item) => {
						return item.variableQuantity;
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatCheckbox object={data} property="variableQuantityLimitMinByDefault" />
							</div>
						);
					}
				}
			]
		};
	}
	makeReactions() {
		this.productItemsReactions.forEach((dispose) => dispose());
		this.productItemsReactions = [];
		this.productItemsReactions.push(
			reaction(
				() => this.natSetManagerStore.list,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.natSetManagerStore.setValue(this.natSetManagerStore, 'isChanged', true);
						}
					}
				}
			)
		);
		_.forEach(this.natSetManagerStore.list, (item) => {
			_.forIn(item, (value, key) => {
				this.productItemsReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.natSetManagerStore.setValue(this.natSetManagerStore, 'isChanged', true);
							}
						}
					)
				);
			});
			this.reactions.push(
				reaction(
					() => item.validityPeriodId,
					(value) => {
						if (_.isEmpty(value)) {
							item.validityPeriodId = 'periodicity.month';
							item.validityPeriod = {
								id: 'periodicity.month',
								keyId: 'month',
								ownerId: 'periodicity',
								name: { ru: 'Месяц' },
								description: null,
								priority: 6,
								predefined: true,
								predefinedName: 'PeriodicityMonth',
								predefinedVersion: 0
							};
						}
					}
				)
			);
			this.reactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.serialNumberId = null;
							delete item.feature;
							delete item.serialNumber;
							if (!_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									if (item.validityPeriodId !== undefined) {
										item.validityPeriodId = item.product.validityPeriodId;
										item.validityPeriod = item.product.validityPeriod;
									}
									item.unitId = item.product.unitId;
									item.unit = item.product.unit;
								}
							} else {
								item.unitId = null;
								if (item.validityPeriodId !== undefined) {
									item.validityPeriodId = null;
									delete item.validityPeriod;
								}
								delete item.product;
								delete item.unit;
							}
						}
					}
				)
			);
		});
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.natSetManagerStore.setValue(this.natSetManagerStore, 'filterWhere', { productSetId: this.props.productFormStore.model.id });
		this.getData();
	}
	getData() {
		this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', true);
		let filter = {
			where: this.natSetManagerStore.filterWhere,
			include: this.natSetManagerStore.filterInclude
		};
		controller
			.findAll(this.natSetManagerStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natSetManagerStore.setValue(this.natSetManagerStore, 'list', data);
				}
				this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', false);
				this.makeReactions();
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		this.productItemsReactions.forEach((dispose) => dispose());
		this.productItemsReactions = [];
	}
	toggleDeleteModal(status: boolean) {
		this.setState({
			isDeleteModalOpen: status
		});
	}
	toggleSelection(status: boolean) {
		this.setState({
			isProductSelectionOpen: status
		});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.natSetManagerStore.list).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.natSetManagerStore.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.natSetManagerStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.natSetManagerStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.natSetManagerStore.setValue(this.natSetManagerStore, 'collectedModels', buffer);
	}
	saveSetItems(setItems: Array<any>) {
		let items = _.cloneDeep(setItems);
		const arr = [] as Array<any>;
		const currentProductItemMap = _.chain(this.natSetManagerStore.list).cloneDeep().keyBy('id').value();
		_.forEach(items, (item: any) => {
			if (currentProductItemMap[item.id] === undefined) {
				item.productSetId = this.props.productFormStore.model.id;
				item.productSetFeatureId = this.natSetManagerStore.model.featureId;
				arr.push(item);
			}
		});
		this.natSetManagerStore.setValue(this.natSetManagerStore, 'list', this.natSetManagerStore.list.concat(arr));
		this.makeReactions();
	}
	bulkUpsert() {
		let setItems: Array<any> = _.cloneDeep(this.natSetManagerStore.list);
		let items: Array<any> = [];
		_.forEach(setItems, (item) => {
			delete item.unit;
			delete item.feature;
			delete item.product;
			delete item.validityPeriod;
			if (!_.isEmpty(item.productId)) {
				items.push(item);
			}
		});
		if (!_.isEmpty(items) && this.natSetManagerStore.isChanged) {
			this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', true);
			controller
				.bulkUpsert(this.natSetManagerStore.pluralName, items)
				.then(() => {
					this.props.toggleNatSetManager(false);
					this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', false);
					if (error.response !== undefined) {
						catalog.showAlert(error.response.data.error.message);
					}
				});
		}
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАПИСАТЬ',
				onClick: () => {
					this.bulkUpsert();
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatSetManager(false);
				}
			}
		];
		let setItemTemplate = {
			featureId: null,
			productId: null,
			variableQuantityLimitRange: '',
			sharedAmount: 0,
			quantity: 1,
			unitId: null,
			id: '',
			productSetId: null,
			productSetFeatureId: null,
			variableQuantity: false,
			variableQuantityLimitMinByDefault: false,
			validityPeriodId: '',
			validityPeriod: {
				description: null,
				keyId: '',
				name: { ru: '' },
				ownerId: '',
				predefined: true,
				predefinedName: '',
				predefinedVersion: 0
			},
			feature: {
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			},
			product: {
				accountingOptionId: 'product_accounting_options.standart',
				area: 0,
				areaUnitId: null,
				article: null,
				caption: null,
				deleted: false,
				description: null,
				displayName: null,
				elementType: 0,
				giftCardValidityExpiredAt: null,
				giftCardValidityPeriodCount: 0,
				giftCardValidityPeriodId: 'periodicity.year',
				giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
				giftCardValue: 0,
				height: 0,
				heightUnitId: null,
				kindId: null,
				labelTemplateId: null,
				length: 0,
				lengthUnitId: null,
				name: { ru: '' },
				organizationId: null,
				packageSetId: null,
				parentId: null,
				primaryImageId: null,
				priority: null,
				supplierId: null,
				typeId: 'product_types.goods',
				unitId: null,
				useArea: false,
				useCustomLabelTemplate: false,
				useFeatureOptionId: 'use_feature_options.not_used',
				useGiftCardPartially: false,
				useHeight: false,
				useLength: false,
				usePackageOptionId: 'use_package_options.not_used',
				useSerialNumbers: false,
				useVolume: false,
				useWeight: false,
				useWidth: false,
				volume: 0,
				volumeUnitId: null,
				weight: 0,
				weightCanManuallySet: false,
				weightUnitId: null,
				width: 0,
				widthUnitId: null
			},
			unit: {
				baseUnitId: null,
				deleted: false,
				displayName: null,
				elementType: 0,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				packageTypeId: 'unit_package_types.final',
				parentId: null,
				priority: null,
				quantityOfOwnerUnitIncludedInPackage: 0,
				quantityOfPackageIncludedInOwnerUnit: 1,
				quantityOfParentPackageIncludedInPackage: 0,
				typeId: null
			}
		};
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__modal__fullscreen nat__scrollbar' : 'nat__modal nat__modal__fullscreen nat__modal__mobile nat__scrollbar'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="nat__form__header nat__set__manager__modal__header border-0 p-0">
					<CModalHeader className="d-flex mt-4 p-2 mx-4 mb-3 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
					</CModalHeader>
					{!_.isEmpty(this.props.productFormStore.model.useFeatureOptionId) && this.props.productFormStore.model.useFeatureOptionId !== 'use_feature_options.not_used' && (
						<CForm className="mx-5 mb-3">
							<CRow>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.natSetManagerStore.model}
												property="featureId"
												relation="feature"
												placeholder="Выберите характеристику"
												type="text"
												size="sm"
												inputClassName="text-left"
												formatter={relationFormatter()}
												pluralName="productFeatures"
												autoComplete="off"
												apiMethod={(): any => {
													return controller.fetchFeaturesByProduct({ product: this.props.productFormStore.model });
												}}
												create={(pluralName, handlers) => {
													this.props.goTo(`/${pluralName}/new`, { ownerId: this.props.productFormStore.model.id, ownerType: 'OrmProduct' }, handlers);
												}}
												label="Характеристика"
												mobile
											/>
										</CCol>
									</CFormGroup>
								</CCol>
							</CRow>
						</CForm>
					)}
				</CModalHeader>
				<div className="d-flex flex-column default__nat__card__body__height p-0 mx-4 mb-4">
					<NatControlPanel
						className="nat__table__control__buttons nat__card__wrapper nat__borders__2-75 p-2 mb-4"
						buttonItemList={[
							{
								title: 'ДОБАВИТЬ',
								onClick: () => {
									let arr: any = _.cloneDeep(this.natSetManagerStore.list);
									let item: any = _.cloneDeep(setItemTemplate);
									item.productSetId = this.props.productFormStore.model.id;
									item.productSetFeatureId = this.natSetManagerStore.model.featureId;
									item.id = generateRandomId();
									arr.push(item);
									this.natSetManagerStore.setValue(this.natSetManagerStore, 'list', arr);
									this.makeReactions();
								},
								withoutIcon: true,
								disabled: false
							},
							{
								title: 'УДАЛИТЬ',
								onClick: () => {
									this.toggleDeleteModal(true);
								},
								withoutIcon: true,
								disabled: this.natSetManagerStore.collectedModels.length === 0
							},
							{
								title: 'ПОДБОР',
								onClick: () => {
									this.toggleSelection(true);
								},
								withoutIcon: true,
								disabled: false
							}
						]}
					/>
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1">
							<GridTable
								columns={catalog.checkColumnVisibility(this.state.columns, this.natSetManagerStore.list)}
								rows={this.natSetManagerStore.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								selectedRowsIds={_.map(this.natSetManagerStore.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => this.collectModels(selectedRowsIds)}
							/>
						</div>
					</div>
				</div>
				{this.natSetManagerStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.state.isProductSelectionOpen && (
					<ProductSelection
						toggleFeedSelection={this.toggleSelection}
						isModalOpen={this.state.isProductSelectionOpen}
						productItemsColumns={this.state.columns}
						modelStore={this.props.productFormStore}
						productItemTemplate={setItemTemplate}
						saveProductItems={this.saveSetItems}
						notRecalculateAmount={true}
						dontDisplayStocksAndPrices={true}
						typeFilter={{
							nin: ['product_types.set']
						}}
					/>
				)}
				{this.state.isDeleteModalOpen && (
					<AlertDialog
						isModalOpen={this.state.isDeleteModalOpen}
						title="Выполнить действие?"
						buttonItemList={[
							{
								title: 'Да',
								onClick: () => {
									this.toggleDeleteModal(false);
									let where: { productSetId: string | undefined; id?: any } = {
										productSetId: this.props.productFormStore.model.id
									};
									let id: Array<string> = [];
									_.forEach(this.natSetManagerStore.collectedModels, (item: any) => {
										id.push(item.id);
									});
									where.id = { inq: id };
									this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', true);
									controller
										.delete(this.natSetManagerStore.pluralName, where)
										.then(() => {
											this.props.productFormStore.setValue(this.props.productFormStore, 'collectedModels', []);
											this.natSetManagerStore.setValue(this.natSetManagerStore, 'filterWhere', { productSetId: this.props.productFormStore.model.id, productSetFeatureId: this.natSetManagerStore.model.featureId });
											let filter = {
												where: this.natSetManagerStore.filterWhere,
												include: this.natSetManagerStore.filterInclude
											};
											return controller.findAll(this.natSetManagerStore.pluralName, filter);
										})
										.then((data) => {
											this.natSetManagerStore.setValue(this.natSetManagerStore, 'list', []);
											if (!_.isEmpty(data)) {
												this.natSetManagerStore.setValue(this.natSetManagerStore, 'list', data);
											}
											this.natSetManagerStore.setValue(this.natSetManagerStore, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.props.productFormStore.setValue(this.props.productFormStore, 'isLoading', false);
										});
								}
							},
							{
								title: 'Отмена',
								onClick: () => {
									this.toggleDeleteModal(false);
								}
							}
						]}
					/>
				)}
			</CModal>
		);
	}
}

export default withRouter(NatSetManager);
