import { CCol, CFormGroup, CLabel } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { loyaltyPolicyAutocompleteAdapter } from '../../shared/Adapters/ValueAutocomplete/LoyaltyPolicyAutocompleteAdapter';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { numberRelationFormatter } from '../../shared/Formatters/NumberRelationFormatter';
import { percentFormatter } from '../../shared/Formatters/PercentFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatButtonInput from '../../shared/Inputs/NatButtonInput';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatMultiInputWrapper from '../../shared/Inputs/NatMultiInputWrapper';
import NatNumberRelationInput from '../../shared/Inputs/NatNumberRelationInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import NatSelection from '../../shared/Modals/NatSelection';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import NatLoyaltyPolicyRangeByProductManager from '../shared/NatLoyaltyPolicyRangeByProductManager';
import NatLoyaltyPolicyRangeManager from '../shared/NatLoyaltyPolicyRangeManager';
import { LoyaltyPolicyFormStoreType } from './LoyaltyPolicyFormStore';

interface PropsType extends FormPropsType {
	loyaltyPolicyFormStore: LoyaltyPolicyFormStoreType;
	childrenProps: ChildrenPropsType;
	saveRuleItems(): void;
	buildRuleItems(): void;
}

interface StateType {
	modals: Array<number>;
	isLoyaltyPolicyRangeManagerOpen: boolean;
	isLoyaltyPolicyRangeByProductManagerOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class LoyaltyPolicyForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleNatSelection = this.toggleNatSelection.bind(this);
		this.toggleNatLoyaltyPolicyRangeManager = this.toggleNatLoyaltyPolicyRangeManager.bind(this);
		this.toggleNatLoyaltyPolicyRangeByProductManager = this.toggleNatLoyaltyPolicyRangeByProductManager.bind(this);
		this.state = {
			modals: [],
			isLoyaltyPolicyRangeManagerOpen: false,
			isLoyaltyPolicyRangeByProductManagerOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleNatSelection(index: number) {
		const position = this.state.modals.indexOf(index);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			modals: newDetails
		});
		if (!this.state.modals.includes(index)) {
			catalog.disablePageScroll();
		} else {
			catalog.enablePageScroll();
		}
	}
	toggleNatLoyaltyPolicyRangeManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeManagerOpen: status
		});
	}
	toggleNatLoyaltyPolicyRangeByProductManager(status: boolean) {
		this.setState({
			isLoyaltyPolicyRangeByProductManagerOpen: status
		});
	}
	render() {
		let ruleItemTemplate: any = {
			id: '',
			ruleId: null
		};
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.loyaltyPolicyFormStore}
				navTabs={(() => {
					if (this.props.loyaltyPolicyFormStore.model.elementType === 0) {
						return [{ name: 'Условия применения' }, { name: 'Дополнительно' }];
					} else {
						return [];
					}
				})()}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(ruleItemTemplate, 'ruleItems');
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.loyaltyPolicyFormStore.selectedItems, 'ruleItems');
										},
										withoutIcon: true,
										disabled: this.props.loyaltyPolicyFormStore.selectedItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											this.toggleNatSelection(1);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.loyaltyPolicyFormStore.selectedItems, this.props.loyaltyPolicyFormStore.model.ruleItems, -1);
										},
										withoutIcon: true,
										disabled: this.props.loyaltyPolicyFormStore.selectedItems.length === 0
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.loyaltyPolicyFormStore.selectedItems, this.props.loyaltyPolicyFormStore.model.ruleItems, 1);
										},
										withoutIcon: true,
										disabled: this.props.loyaltyPolicyFormStore.selectedItems.length === 0
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.loyaltyPolicyFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods([
											{
												id: 'checkbox',
												pinned: true,
												className: '',
												width: '54px',
												minResizeWidth: 0,
												maxResizeWidth: null,
												resizable: false,
												visible: true,
												headerCellRenderer: () => {
													return <div></div>;
												},
												cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
													return (
														<div className="rgt-cell-inner ml-0 mr-0">
															<input
																type="checkbox"
																id="checkbox"
																onChange={() => {
																	this.props.childrenProps.saveSelected(data);
																}}
															/>
														</div>
													);
												}
											},
											{
												id: 1,
												field: 'rule',
												label: 'Правило политики лояльности',
												width: '450px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner ml-3 p-0">
															<NatRelationInput
																object={data}
																property="ruleId"
																relation="rule"
																placeholder="Выберите правило"
																type="text"
																size="sm"
																inputClassName="text-left"
																formatter={relationFormatter()}
																pluralName="loyaltyPolicyRules"
																filterWhere={{
																	deleted: false,
																	elementType: 0
																}}
																autoComplete="off"
																hierarchicalSelect
																goTo={this.props.goTo}
															/>
														</div>
													);
												}
											}
										])}
										rows={this.props.loyaltyPolicyFormStore.model.ruleItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										searchText={this.props.loyaltyPolicyFormStore.searchText}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										showSearch={false}
									/>
								</form>
								{this.state.modals.includes(1) && (
									<NatSelection
										filterWhere={{ deleted: false }}
										filterOrder={['createdAt DESC']}
										toggleNatSelection={this.toggleNatSelection}
										buildSelectedItems={this.props.buildRuleItems}
										saveSelectedItems={this.props.saveRuleItems}
										tabNumber={1}
										modals={this.state.modals}
										filterInclude={[]}
										hierarchical={false}
										pluralName="loyaltyPolicyRules"
										property="ruleId"
									/>
								)}
								{this.state.isLoyaltyPolicyRangeManagerOpen && (
									<NatLoyaltyPolicyRangeManager isModalOpen={this.state.isLoyaltyPolicyRangeManagerOpen} toggleNatLoyaltyPolicyRangeManager={this.toggleNatLoyaltyPolicyRangeManager} modelStore={this.props.loyaltyPolicyFormStore} />
								)}
								{this.state.isLoyaltyPolicyRangeByProductManagerOpen && (
									<NatLoyaltyPolicyRangeByProductManager
										isModalOpen={this.state.isLoyaltyPolicyRangeByProductManagerOpen}
										toggleNatLoyaltyPolicyRangeByProductManager={this.toggleNatLoyaltyPolicyRangeByProductManager}
										modelStore={this.props.loyaltyPolicyFormStore}
										goTo={this.props.goTo}
									/>
								)}
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.loyaltyPolicyFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.loyaltyPolicyFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={(() => {
					if (this.props.loyaltyPolicyFormStore.model.elementType === 0) {
						return (
							<>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatLocalizedValueInput<LocalizedString>
												object={this.props.loyaltyPolicyFormStore.model}
												property="name"
												placeholder="Введите наименование"
												type="text"
												formatter={localizedStringFormatter}
												size="sm"
												invalid={true}
												invalidFeedback="Строка не заполнена"
												autoComplete="off"
												label="Наименование"
												mobile
												adapter={loyaltyPolicyAutocompleteAdapter}
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="parentId"
												relation="parent"
												placeholder="Выберите группу"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="loyaltyPolicies"
												filterWhere={{ deleted: false, elementType: 1 }}
												autoComplete="off"
												label="Группа"
												mobile
												treeSelect
												goTo={this.props.goTo}
											/>
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="methodOfApplyingId"
												relation="methodOfApplying"
												placeholder="Выберите способ"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="enumerationItems"
												onlySelect={true}
												filterWhere={{ ownerId: 'loyalty_policy_methods_of_applying' }}
												autoComplete="off"
												label="Способ применения"
												invalid={_.isEmpty(this.props.loyaltyPolicyFormStore.model.methodOfApplyingId)}
												invalidFeedback="Элемент не выбран"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="methodOfProvidingId"
												relation="methodOfProviding"
												placeholder="Выберите способ"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="enumerationItems"
												onlySelect={true}
												filterWhere={(() => {
													if (this.props.loyaltyPolicyFormStore.model.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_rules') {
														return { ownerId: 'loyalty_policy_methods_of_providing' };
													} else {
														return {
															ownerId: 'loyalty_policy_methods_of_providing',
															id: {
																inq: ['loyalty_policy_methods_of_providing.sum', 'loyalty_policy_methods_of_providing.percentage']
															}
														};
													}
												})()}
												autoComplete="off"
												label="Способ предоставления"
												invalid={_.isEmpty(this.props.loyaltyPolicyFormStore.model.methodOfProvidingId)}
												invalidFeedback="Элемент не выбран"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
									</CFormGroup>
									{(() => {
										if (this.props.loyaltyPolicyFormStore.model.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_rules') {
											if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.sum') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="policyValue"
																placeholder="Введите число"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																formatter={numberFormatter}
																size="sm"
																autoComplete="off"
																label="Размер скидки"
																mobile
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.percentage') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="policyValue"
																placeholder="Введите число"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																formatter={percentFormatter}
																size="sm"
																autoComplete="off"
																label="Размер скидки"
																mobile
															/>
														</CCol>
														<CCol md="4">
															<NatCheckbox object={this.props.loyaltyPolicyFormStore.model} property="applyMultiplyToTheParentGroup" label="С учетом суммы примененных скидок" />
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.sum_per_row') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="policyValue"
																placeholder="Введите число"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																formatter={numberFormatter}
																size="sm"
																autoComplete="off"
																label="Размер скидки"
																mobile
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.quantity') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="8" className="pl-0">
															<NatMultiInputWrapper windowSize={626}>
																<>
																	<div className={this.injected.directoryStore.models.windowSize > 626 ? 'd-flex w-100 mr-4' : 'd-flex w-100'}>
																		<CLabel className="align-self-center pl-3 m-0 pr-3">Бесплатно</CLabel>
																		<NatValueInput<number>
																			object={this.props.loyaltyPolicyFormStore.model}
																			property="policyValue"
																			placeholder="Введите число"
																			type="text"
																			pattern="^([0-9]*[.,])?[0-9]*$"
																			formatter={numberFormatter}
																			size="sm"
																			autoComplete="off"
																			mobile
																			disableWrapper
																		/>
																	</div>
																	<div className="d-flex w-100">
																		<CLabel className="align-self-center pl-3 m-0 pr-3">Из</CLabel>
																		<NatValueInput<number>
																			object={this.props.loyaltyPolicyFormStore.model}
																			property="quantityDiscountCondition"
																			placeholder="Введите число"
																			type="text"
																			pattern="^([0-9]*[.,])?[0-9]*$"
																			formatter={numberFormatter}
																			size="sm"
																			autoComplete="off"
																			mobile
																			disableWrapper
																		/>
																	</div>
																</>
															</NatMultiInputWrapper>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.price_type') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyFormStore.model}
																property="priceTypeId"
																relation="priceType"
																placeholder="Выберите тип"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="priceTypes"
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Тип цены"
																mobile
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyFormStore.model.priceTypeId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.message') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatLocalizedValueInput<LocalizedString>
																object={this.props.loyaltyPolicyFormStore.model}
																property="messageText"
																placeholder="Введите сообщение"
																type="text"
																formatter={localizedStringFormatter}
																size="sm"
																autoComplete="off"
																label="Сообщение"
																mobile
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.issuing_loyalty_card') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyFormStore.model}
																property="loyaltyCardKindId"
																relation="loyaltyCardKind"
																placeholder="Выберите вид"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="loyaltyCardKinds"
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Вид карты"
																mobile
																goTo={this.props.goTo}
																invalid={_.isEmpty(this.props.loyaltyPolicyFormStore.model.loyaltyCardKindId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.rounding_amount') {
												return (
													<>
														<CFormGroup row className="mb-0">
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="policyValue"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Минимальная округляемая сумма"
																	mobile
																/>
															</CCol>
															<CCol md="4">
																<NatRelationInput
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingOptionId"
																	relation="roundingOption"
																	placeholder="Выберите вариант округления"
																	type="text"
																	size="sm"
																	formatter={relationFormatter()}
																	pluralName="enumerationItems"
																	filterWhere={{ ownerId: 'rounding_options' }}
																	autoComplete="off"
																	onlySelect
																	label="Вариант округления"
																	mobile
																	filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																/>
															</CCol>
														</CFormGroup>
														<CFormGroup row className="mb-0">
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingAccuracy"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Точность округления"
																	mobile
																/>
															</CCol>
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingPsychological"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Психологическое округление"
																	mobile
																/>
															</CCol>
														</CFormGroup>
													</>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.rounding_per_row') {
												return (
													<>
														<CFormGroup row className="mb-0">
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="policyValue"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Минимальная округляемая сумма"
																	mobile
																/>
															</CCol>
															<CCol md="4">
																<NatRelationInput
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingOptionId"
																	relation="roundingOption"
																	placeholder="Выберите вариант округления"
																	type="text"
																	size="sm"
																	formatter={relationFormatter()}
																	pluralName="enumerationItems"
																	filterWhere={{ ownerId: 'rounding_options' }}
																	autoComplete="off"
																	onlySelect
																	label="Вариант округления"
																	mobile
																	filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																/>
															</CCol>
														</CFormGroup>
														<CFormGroup row className="mb-0">
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingAccuracy"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Точность округления"
																	mobile
																/>
															</CCol>
															<CCol md="4">
																<NatValueInput<number>
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="roundingPsychological"
																	placeholder="Введите число"
																	type="text"
																	pattern="^([0-9]*[.,])?[0-9]*$"
																	formatter={numberFormatter}
																	size="sm"
																	autoComplete="off"
																	label="Психологическое округление"
																	mobile
																/>
															</CCol>
														</CFormGroup>
													</>
												);
											} else if (this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.assign_manager') {
												return (
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyFormStore.model}
																property="managerId"
																relation="manager"
																placeholder="Выберите пользователя"
																type="text"
																size="sm"
																inputClassName="text-left"
																formatter={relationFormatter(false, true)}
																pluralName="users"
																filterWhere={{ employee: true }}
																autoComplete="off"
																label="Ответственный"
																mobile
																renderName={(item) => {
																	return item.username || item.email;
																}}
																or={(value) => {
																	return [
																		{
																			username: {
																				like: value,
																				options: 'i'
																			}
																		},
																		{
																			email: {
																				like: value,
																				options: 'i'
																			}
																		}
																	];
																}}
																backspace={(object, value) => {
																	runInAction(() => {
																		if (!_.isEmpty(object.username)) {
																			object.username = value;
																		}
																		if (!_.isEmpty(object.email)) {
																			object.email = value;
																		}
																	});
																}}
																invalid={_.isEmpty(this.props.loyaltyPolicyFormStore.model.managerId)}
																invalidFeedback="Элемент не выбран"
															/>
														</CCol>
													</CFormGroup>
												);
											}
										} else if (this.props.loyaltyPolicyFormStore.model.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_bonus_points') {
											return (
												<>
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatValueInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="policyValue"
																placeholder="Введите число"
																type="text"
																pattern="^([0-9]*[.,])?[0-9]*$"
																formatter={numberFormatter}
																size="sm"
																autoComplete="off"
																label="Начислить"
																mobile
															/>
														</CCol>
														<CCol md="4">
															<NatRelationInput
																object={this.props.loyaltyPolicyFormStore.model}
																property="loyaltyProgramId"
																relation="loyaltyProgram"
																placeholder="Выберите программу"
																type="text"
																size="sm"
																formatter={relationFormatter()}
																pluralName="loyaltyPrograms"
																filterWhere={{ deleted: false }}
																autoComplete="off"
																label="Программа лояльности"
																mobile
																goTo={this.props.goTo}
															/>
														</CCol>
													</CFormGroup>
													<CFormGroup row className="mb-0">
														<CCol md="4">
															<NatNumberRelationInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="validityPeriodCount"
																relationProperty="validityPeriodId"
																relation="validityPeriod"
																placeholder="Введите период"
																type="text"
																size="sm"
																pattern="^([0-9]*[.,])?[0-9]*$"
																inputClassName="text-right"
																formatter={numberRelationFormatter}
																pluralName="enumerationItems"
																onlySelect={true}
																filterWhere={{ ownerId: 'periodicity' }}
																filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																autoComplete="off"
																label="Период действия"
																mobile
															/>
														</CCol>
														<CCol md="4">
															<NatNumberRelationInput<number>
																object={this.props.loyaltyPolicyFormStore.model}
																property="validityDelayPeriodCount"
																relationProperty="validityDelayPeriodId"
																relation="validityDelayPeriod"
																placeholder="Введите период"
																type="text"
																size="sm"
																pattern="^([0-9]*[.,])?[0-9]*$"
																inputClassName="text-right"
																formatter={numberRelationFormatter}
																pluralName="enumerationItems"
																onlySelect={true}
																filterWhere={{ ownerId: 'periodicity' }}
																filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
																autoComplete="off"
																label="Период отсрочки"
																mobile
															/>
														</CCol>
													</CFormGroup>
													<CFormGroup row className="mb-0">
														{this.props.loyaltyPolicyFormStore.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.percentage' && (
															<CCol md="4">
																<NatCheckbox object={this.props.loyaltyPolicyFormStore.model} property="applyMultiplyToTheParentGroup" label="С учетом суммы примененных скидок" />
															</CCol>
														)}
														{this.props.loyaltyPolicyFormStore.model.productSelectionOptionId === 'loyalty_policy_product_selection_options.segment' && (
															<CCol md="4">
																<NatRelationInput
																	object={this.props.loyaltyPolicyFormStore.model}
																	property="productSegmentId"
																	relation="productSegment"
																	placeholder="Выберите сегмент"
																	type="text"
																	size="sm"
																	formatter={relationFormatter()}
																	pluralName="productSegments"
																	filterWhere={{ deleted: false, elementType: 0 }}
																	autoComplete="off"
																	label="Сегмент"
																	mobile
																	hierarchicalSelect
																	goTo={this.props.goTo}
																/>
															</CCol>
														)}
													</CFormGroup>
												</>
											);
										}
									})()}
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatButtonInput
												label="Получатель"
												buttonItemList={[
													{
														title: !_.isEmpty(this.props.loyaltyPolicyFormStore.model.policyRanges) ? `Изменить (${_.get(this.props.loyaltyPolicyFormStore.model, 'policyRanges', []).length})` : 'Изменить',
														onClick: () => {
															this.toggleNatLoyaltyPolicyRangeManager(true);
														},
														disabled: this.props.match.params.elementId === 'new'
													}
												]}
											/>
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="productSelectionOptionId"
												relation="productSelectionOption"
												placeholder="Выберите вариант предоставления"
												type="text"
												size="sm"
												formatter={(() => {
													if (this.props.loyaltyPolicyFormStore.model.productSelectionOptionId === 'loyalty_policy_product_selection_options.list') {
														if (this.props.match.params.elementId === 'new') {
															return relationFormatter();
														} else {
															return relationFormatter(false, false, [
																{
																	icon: 'cil-plus',
																	title: 'Добавить',
																	onClick: () => {
																		this.toggleNatLoyaltyPolicyRangeByProductManager(true);
																	},
																	to: ''
																}
															]);
														}
													} else {
														return relationFormatter();
													}
												})()}
												pluralName="enumerationItems"
												filterWhere={{
													ownerId: 'loyalty_policy_product_selection_options',
													id: {
														nin: ['loyalty_policy_product_selection_options.scheme']
													}
												}}
												autoComplete="off"
												onlySelect
												label="Предоставляется на номенклатуру"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
									</CFormGroup>
								</CCol>
							</>
						);
					} else {
						return (
							<>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatLocalizedValueInput<LocalizedString>
												object={this.props.loyaltyPolicyFormStore.model}
												property="name"
												placeholder="Введите наименование"
												type="text"
												formatter={localizedStringFormatter}
												size="sm"
												invalid={true}
												invalidFeedback="Строка не заполнена"
												autoComplete="off"
												label="Наименование"
												mobile
												adapter={loyaltyPolicyAutocompleteAdapter}
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="parentId"
												relation="parent"
												placeholder="Выберите группу"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="loyaltyPolicies"
												filterWhere={{ deleted: false, elementType: 1 }}
												autoComplete="off"
												label="Группа"
												mobile
												treeSelect
												goTo={this.props.goTo}
											/>
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.loyaltyPolicyFormStore.model}
												property="jointUseOptionId"
												relation="jointUseOption"
												placeholder="Выберите вариант"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="enumerationItems"
												onlySelect={true}
												filterWhere={{ ownerId: 'loyalty_policy_joint_use_options' }}
												autoComplete="off"
												label="Вариант совместного применения"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
										{(() => {
											if (
												this.props.loyaltyPolicyFormStore.model.jointUseOptionId === 'loyalty_policy_joint_use_options.minimum' ||
												this.props.loyaltyPolicyFormStore.model.jointUseOptionId === 'loyalty_policy_joint_use_options.maximum' ||
												this.props.loyaltyPolicyFormStore.model.jointUseOptionId === 'loyalty_policy_joint_use_options.replacement'
											) {
												return (
													<CCol md="4">
														<NatRelationInput
															object={this.props.loyaltyPolicyFormStore.model}
															property="calcJointUseOptionId"
															relation="calcJointUseOption"
															placeholder="Выберите вариант"
															type="text"
															size="sm"
															formatter={relationFormatter()}
															pluralName="enumerationItems"
															onlySelect={true}
															filterWhere={{ ownerId: 'loyalty_policy_сalc_joint_use_options' }}
															autoComplete="off"
															label="Вариант расчета результата совместного применения"
															mobile
															filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
														/>
													</CCol>
												);
											}
										})()}
									</CFormGroup>
								</CCol>
							</>
						);
					}
				})()}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.loyaltyPolicyFormStore.setValue(this.props.loyaltyPolicyFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.loyaltyPolicyFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.loyaltyPolicyFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.loyaltyPolicyFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(LoyaltyPolicyForm));
