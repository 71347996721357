import CIcon from '@coreui/icons-react';
import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { numberRelationFormatter } from '../../shared/Formatters/NumberRelationFormatter';
import { percentFormatter } from '../../shared/Formatters/PercentFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { quantityFormatter } from '../../shared/Formatters/QuantityFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatNumberRelationInput from '../../shared/Inputs/NatNumberRelationInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { CheckType, FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { CheckFormStoreType } from './CheckFormStore';

interface PropsType extends FormPropsType {
	checkFormStore: CheckFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
	print(data: any): void;
	fillEmptyRelations(model: CheckType): CheckType;
}

interface StateType {
	columns: Array<any>;
	isModalOpen: boolean;
	setManagerColumns: Array<any>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class CheckForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.state = {
			isModalOpen: false,
			setManagerColumns: [
				{
					id: 1,
					field: 'productId',
					label: 'Товар',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productId"
									relation="product"
									placeholder="Выберите товар"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="products"
									filterWhere={{
										deleted: false,
										elementType: 0,
										typeId: {
											nin: ['product_types.set']
										}
									}}
									include={['unit', 'validityPeriod']}
									autoComplete="off"
									disabled
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'productSetId',
					label: 'Набор',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkProductSetCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId) ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
					}
				},
				{
					id: 3,
					field: 'unitId',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									disabled
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 4,
					field: 'featureId',
					label: 'Характеристика',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="featureId"
													relation="feature"
													placeholder="Выберите характеристику"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="productFeatures"
													apiMethod={(): any => {
														return controller.fetchFeaturesByProduct({ product: data.product });
													}}
													create={(pluralName, handlers) => {
														this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
													}}
													autoComplete="off"
													disabled
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'serialNumberId',
					label: 'Серийный номер',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkSerialNumbersCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (!data.product.useSerialNumbers) {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="serialNumberId"
													relation="serialNumber"
													placeholder="Выберите серийный номер"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter(false, true)}
													pluralName="serialNumbers"
													filterWhere={{ deleted: false, or: [{ ownerId: data.productId }] }}
													autoComplete="off"
													disabled
													goTo={this.props.goTo}
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 6,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return quantityFormatter.renderValue(data.quantity);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="quantity"
													placeholder="Введите кол-во"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={quantityFormatter}
													autoComplete="off"
													verticalSwitching
													validation={(object: any, value: any) => {
														return catalog.validateQuantityInput(object, value);
													}}
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return quantityFormatter.renderValue(data.quantity);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 7,
					field: 'validityPeriodCount',
					label: 'Срок',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
											return (
												<NatNumberRelationInput<number>
													object={data}
													property="validityPeriodCount"
													relationProperty="validityPeriodId"
													relation="validityPeriod"
													placeholder="Введите период"
													type="text"
													size="sm"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={numberRelationFormatter}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'periodicity' }}
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
													autoComplete="off"
													verticalSwitching
													disabled
												/>
											);
										} else {
											return null;
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 8,
					field: 'price',
					label: 'Цена',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.price);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="price"
													placeholder="Введите цену"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={priceFormatter}
													autoComplete="off"
													verticalSwitching
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.price);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 9,
					field: 'amount',
					label: 'Сумма',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.amount);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="amount"
													placeholder="Введите сумму"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={priceFormatter}
													autoComplete="off"
													verticalSwitching
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.amount);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 10,
					field: 'manualDiscountPercent',
					label: 'Ручной процент скидки',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.manualDiscountPercent);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="manualDiscountPercent"
													placeholder="Введите процент"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={percentFormatter}
													autoComplete="off"
													verticalSwitching
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.manualDiscountPercent);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 11,
					field: 'manualDiscountAmount',
					label: 'Ручная сумма скидки',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.manualDiscountAmount);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="manualDiscountAmount"
													placeholder="Введите цену"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={priceFormatter}
													autoComplete="off"
													verticalSwitching
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.manualDiscountAmount);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 12,
					field: 'autoDiscountPercent',
					label: 'Автоматический процент скидки',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.autoDiscountPercent);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="autoDiscountPercent"
													placeholder="Введите процент"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={percentFormatter}
													autoComplete="off"
													verticalSwitching
													disabled
													size="sm"
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.autoDiscountPercent);
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 13,
					field: 'autoDiscountAmount',
					label: 'Автоматическая сумма скидки',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0 text-right font__size__16">
								{(() => {
									if (data.productSet !== undefined) {
										if (data.productSet.setPriceCalculationMethodId !== 'product_set_price_calculation_methods.by_product_prices') {
											return priceFormatter.renderValue(data.autoDiscountAmount);
										} else {
											return (
												<NatValueInput<number>
													object={data}
													property="autoDiscountAmount"
													placeholder="Введите цену"
													type="text"
													pattern="^([0-9]*[.,])?[0-9]*$"
													inputClassName="text-right"
													formatter={priceFormatter}
													autoComplete="off"
													verticalSwitching
													size="sm"
													disabled
												/>
											);
										}
									} else {
										return priceFormatter.renderValue(data.autoDiscountAmount);
									}
								})()}
							</div>
						);
					}
				}
			],
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input
									type="checkbox"
									id="checkbox"
									onChange={() => {
										this.props.childrenProps.saveSelected(data);
									}}
								/>
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 ml-2 font__size__16">{catalog.renderCatalogName(data.product)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatRelationInput
										object={data}
										property="productId"
										relation="product"
										placeholder="Выберите товар"
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="products"
										filterWhere={{
											deleted: false,
											elementType: 0,
											typeId: {
												nin: ['product_types.set']
											}
										}}
										include={['unit', 'validityPeriod']}
										autoComplete="off"
										disabled
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</div>
							);
						}
					}
				},
				{
					id: 2,
					field: 'productSetId',
					label: 'Набор',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkProductSetCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId) ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
					}
				},
				{
					id: 3,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 ml-2 font__size__16">{catalog.renderCatalogName(data.unit)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatRelationInput
										object={data}
										property="unitId"
										relation="unit"
										placeholder="Выберите ед.изм."
										type="text"
										size="sm"
										inputClassName="text-left"
										formatter={relationFormatter()}
										pluralName="productUnits"
										apiMethod={(): any => {
											if (data.product !== undefined) {
												return controller.fetchUnitsByProduct({ product: data.product });
											} else {
												return new Promise((resolve) => {
													resolve([]);
												});
											}
										}}
										autoComplete="off"
										disabled
										goTo={this.props.goTo}
									/>
								</div>
							);
						}
					}
				},
				{
					id: 4,
					field: 'feature',
					label: 'Характеристика',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 ml-2 font__size__16">
									{(() => {
										if (data.product !== undefined) {
											if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
												return null;
											} else {
												return catalog.renderCatalogName(data.feature);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
												return null;
											} else {
												return (
													<NatRelationInput
														object={data}
														property="featureId"
														relation="feature"
														placeholder="Выберите характеристику"
														type="text"
														size="sm"
														inputClassName="text-left"
														formatter={relationFormatter()}
														pluralName="productFeatures"
														autoComplete="off"
														apiMethod={(): any => {
															return controller.fetchFeaturesByProduct({ product: data.product });
														}}
														create={(pluralName, handlers) => {
															this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
														}}
														disabled
													/>
												);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 5,
					field: 'serialNumber',
					label: 'Серийный номер',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkSerialNumbersCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 ml-2 font__size__16">
									{(() => {
										if (data.product !== undefined) {
											if (!data.product.useSerialNumbers) {
												return null;
											} else {
												return catalog.renderCatalogName(data.serialNumber);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (!data.product.useSerialNumbers) {
												return null;
											} else {
												return (
													<NatRelationInput
														object={data}
														property="serialNumberId"
														relation="serialNumber"
														placeholder="Выберите серийный номер"
														type="text"
														size="sm"
														inputClassName="text-left"
														formatter={relationFormatter(false, true)}
														pluralName="serialNumbers"
														filterWhere={{ deleted: false, or: [{ ownerId: data.productId }] }}
														autoComplete="off"
														disabled
														goTo={this.props.goTo}
													/>
												);
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 6,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{quantityFormatter.renderValue(data.quantity)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="quantity"
										placeholder="Введите кол-во"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={quantityFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				},
				{
					id: 7,
					field: 'validityPeriodCount',
					label: 'Срок',
					checkCondition: (item: any) => {
						return catalog.checkValidityPeriodsCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return (
								<div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return numberRelationFormatter.renderValue(data.validityPeriodCount, data.validityPeriod);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									{(() => {
										if (data.product !== undefined) {
											if (data.product.typeId === 'product_types.subscription' || data.product.typeId === 'product_types.subscription_option') {
												return (
													<NatNumberRelationInput<number>
														object={data}
														property="validityPeriodCount"
														relationProperty="validityPeriodId"
														relation="validityPeriod"
														placeholder="Введите период"
														type="text"
														size="sm"
														pattern="^([0-9]*[.,])?[0-9]*$"
														inputClassName="text-right"
														formatter={numberRelationFormatter}
														pluralName="enumerationItems"
														onlySelect={true}
														filterWhere={{ ownerId: 'periodicity' }}
														filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
														autoComplete="off"
														verticalSwitching
														disabled
													/>
												);
											} else {
												return null;
											}
										} else {
											return null;
										}
									})()}
								</div>
							);
						}
					}
				},
				{
					id: 8,
					field: 'price',
					label: 'Цена',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{priceFormatter.renderValue(data.price)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="price"
										placeholder="Введите цену"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={priceFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				},
				{
					id: 9,
					field: 'amount',
					label: 'Сумма',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{priceFormatter.renderValue(data.amount)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="amount"
										placeholder="Введите сумму"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={priceFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				},
				{
					id: 10,
					field: 'manualDiscountPercent',
					label: 'Ручной процент скидки',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{percentFormatter.renderValue(data.manualDiscountPercent)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="manualDiscountPercent"
										placeholder="Введите процент"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={percentFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				},
				{
					id: 11,
					field: 'manualDiscountAmount',
					label: 'Ручная сумма скидки',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{priceFormatter.renderValue(data.manualDiscountAmount)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="manualDiscountAmount"
										placeholder="Введите цену"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={priceFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				},
				{
					id: 12,
					field: 'autoDiscountPercent',
					label: 'Автоматический процент скидки',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{percentFormatter.renderValue(data.autoDiscountPercent)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="autoDiscountPercent"
										placeholder="Введите процент"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={percentFormatter}
										autoComplete="off"
										verticalSwitching
										disabled
										size="sm"
									/>
								</div>
							);
						}
					}
				},
				{
					id: 13,
					field: 'autoDiscountAmount',
					label: 'Автоматическая сумма скидки',
					cellRenderer: ({ data }: { data: any }) => {
						if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
							return <div className="rgt-cell-inner p-0 mr__4_8 font__size__16 text-right">{priceFormatter.renderValue(data.autoDiscountAmount)}</div>;
						} else {
							return (
								<div className="rgt-cell-inner ml-3 p-0">
									<NatValueInput<number>
										object={data}
										property="autoDiscountAmount"
										placeholder="Введите цену"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										inputClassName="text-right"
										formatter={priceFormatter}
										autoComplete="off"
										verticalSwitching
										size="sm"
										disabled
									/>
								</div>
							);
						}
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleSelection(status: boolean) {
		this.setState({
			isModalOpen: status
		});
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				makeReactions={this.props.makeReactions}
				store={this.props.checkFormStore}
				productSelection
				isAlertOpen={this.props.childrenProps.isAlertOpen}
				toggleAlert={this.props.childrenProps.toggleAlert}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				isProductSetEditingModalOpen={this.props.childrenProps.isProductSetEditingModalOpen}
				toggleSetEditing={this.props.childrenProps.toggleNatSetEditing}
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				isRevealCompositionOfSet
				productSetEditing
				setManagerColumns={this.state.setManagerColumns}
				productItemTemplate={{
					productSetId: null,
					productSetFeatureId: null,
					amount: 0,
					autoDiscountAmount: 0,
					autoDiscountPercent: 0,
					manualDiscountAmount: 0,
					manualDiscountPercent: 0,
					bonusPointIncome: 0,
					bonusPointIncomeConverted: 0,
					bonusPointOutcome: 0,
					bonusPointOutcomeConverted: 0,
					featureId: null,
					price: 0,
					productId: null,
					quantity: 1,
					reserve: 0,
					serialNumberId: null,
					unitId: null,
					customerOrderId: null,
					validityPeriodCount: 0,
					validityPeriodId: 'periodicity.month',
					validityPeriod: {
						id: 'periodicity.month',
						keyId: 'month',
						ownerId: 'periodicity',
						name: { ru: 'Месяц' },
						description: null,
						priority: 6,
						predefined: true,
						predefinedName: 'PeriodicityMonth',
						predefinedVersion: 0
					},
					feature: {
						deleted: false,
						displayName: null,
						name: { ru: '' },
						ownerId: null,
						ownerType: null,
						priority: null
					},
					product: {
						accountingOptionId: 'product_accounting_options.standart',
						area: 0,
						areaUnitId: null,
						article: null,
						caption: null,
						deleted: false,
						description: null,
						displayName: null,
						elementType: 0,
						giftCardValidityExpiredAt: null,
						giftCardValidityPeriodCount: 0,
						giftCardValidityPeriodId: 'periodicity.year',
						giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
						giftCardValue: 0,
						height: 0,
						heightUnitId: null,
						kindId: null,
						labelTemplateId: null,
						length: 0,
						lengthUnitId: null,
						name: { ru: '' },
						organizationId: null,
						packageSetId: null,
						parentId: null,
						primaryImageId: null,
						priority: null,
						supplierId: null,
						typeId: 'product_types.goods',
						unitId: null,
						useArea: false,
						useCustomLabelTemplate: false,
						useFeatureOptionId: 'use_feature_options.not_used',
						useGiftCardPartially: false,
						useHeight: false,
						useLength: false,
						usePackageOptionId: 'use_package_options.not_used',
						useSerialNumbers: false,
						useVolume: false,
						useWeight: false,
						useWidth: false,
						volume: 0,
						volumeUnitId: null,
						weight: 0,
						weightCanManuallySet: false,
						weightUnitId: null,
						width: 0,
						widthUnitId: null
					},
					unit: {
						baseUnitId: null,
						deleted: false,
						displayName: null,
						elementType: 0,
						name: { ru: '' },
						ownerId: null,
						ownerType: null,
						packageTypeId: 'unit_package_types.final',
						parentId: null,
						priority: null,
						quantityOfOwnerUnitIncludedInPackage: 0,
						quantityOfPackageIncludedInOwnerUnit: 1,
						quantityOfParentPackageIncludedInPackage: 0,
						typeId: null
					},
					serialNumber: {
						serialNumber: '',
						gtin: null,
						deleted: false,
						displayName: null,
						name: { ru: '' },
						ownerId: null,
						ownerType: null,
						priority: null
					}
				}}
				selectionColumns={this.state.columns}
				navTabs={[{ name: 'Товары' }, { name: 'Примечание' }, { name: 'Дополнительно' }]}
				tabsContent={(toggleSelection: (status: boolean) => void) => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											toggleSelection(true);
										},
										withoutIcon: true,
										disabled: true
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.checkFormStore.selectedItems);
										},
										withoutIcon: true,
										disabled: true
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											toggleSelection(true);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.checkFormStore.selectedItems, this.props.checkFormStore.model.productItems as Array<any>, -1);
										},
										withoutIcon: true,
										disabled: true
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.checkFormStore.selectedItems, this.props.checkFormStore.model.productItems as Array<any>, 1);
										},
										withoutIcon: true,
										disabled: true
									},
									{
										title: 'ЦЕНЫ И СКИДКИ',
										onClick: () => {},
										disabled: true,
										children: [
											{
												title: 'Рассчитать автоматические скидки',
												onClick: () => {
													this.props.checkFormStore.setValue(this.props.checkFormStore, 'isLoading', true);
													let model = _.cloneDeep(this.props.checkFormStore.model);
													model.productItemList = model.productItems;
													controller
														.apply(model)
														.then((data) => {
															if (!_.isEmpty(data)) {
																const model = this.props.fillEmptyRelations(data);
																model.productItems = model.productItemList;
																this.props.checkFormStore.setValue(this.props.checkFormStore, 'model', model);
																this.props.makeReactions();
															}
															this.props.checkFormStore.setValue(this.props.checkFormStore, 'isLoading', false);
														})
														.catch((error) => {
															catalog.handleNatError(error);
															this.props.checkFormStore.setValue(this.props.checkFormStore, 'isLoading', false);
														});
												}
											}
										]
									}
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.checkColumnVisibility(this.state.columns, _.get(this.props.checkFormStore.model, 'productItems', []))}
										rows={this.props.checkFormStore.model.productItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										isVirtualScroll={false}
										onRowClick={({ data, column }: { data: any; column: any }) => {
											if (column.id !== 'checkbox') {
												if (!_.isEmpty(data.productSetId) || !_.isEmpty(data.productSetFeatureId)) {
													this.props.checkFormStore.setValue(this.props.checkFormStore, 'productItem', data);
													this.props.childrenProps.toggleNatSetEditing(true);
												}
											}
										}}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.checkFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
										disabled
									/>
								</div>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.checkFormStore.model}
													property="cashRegisterId"
													relation="cashRegister"
													placeholder="Выберите кассу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="cashRegisters"
													onlySelect={true}
													filterWhere={{ deleted: false, ownerId: this.props.checkFormStore.model.organizationId, ownerType: 'OrmOrganization' }}
													autoComplete="off"
													disabled
													label="Касса ККМ"
													goTo={this.props.goTo}
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.checkFormStore.model}
													property="cashRegisterShiftId"
													relation="cashRegisterShift"
													placeholder="Выберите смену"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="cashRegisterShifts"
													onlySelect={true}
													filterWhere={{ deleted: false }}
													autoComplete="off"
													disabled
													label="Кассовая смена"
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.checkFormStore.model}
													property="typeId"
													relation="type"
													placeholder="Выберите тип"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="enumerationItems"
													onlySelect={true}
													filterWhere={{ ownerId: 'check_types' }}
													autoComplete="off"
													disabled
													label="Тип"
													filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
												/>
											</CCol>
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.checkFormStore.model}
													property="sequenceNumber"
													placeholder="Введите номер"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Номер"
													mobile
													onClick={() => {
														if (!this.props.checkFormStore.isSequenceNumberChangedManually) {
															this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
														}
													}}
													disabled
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.checkFormStore.model}
													property="loyaltyCardId"
													relation="loyaltyCard"
													placeholder="Выберите карту"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="loyaltyCards"
													onlySelect={true}
													filterWhere={{ deleted: false }}
													autoComplete="off"
													label="Карта лояльности"
													mobile
													goTo={this.props.goTo}
													disabled
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.checkFormStore.model}
													property="couponId"
													relation="coupon"
													placeholder="Выберите купон"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="coupons"
													onlySelect={true}
													filterWhere={{ deleted: false, elementType: 0 }}
													autoComplete="off"
													label="Купоны"
													mobile
													goTo={this.props.goTo}
													disabled
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date> object={this.props.checkFormStore.model} property="periodAt" placeholder="Введите дату" type="datetime-local" step="1" formatter={dateTimeFormatter} size="sm" disabled label="Дата документа" />
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										disabled
										label="Организация"
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="partnerId"
										relation="partner"
										placeholder="Выберите контрагента"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partners"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.checkFormStore.model.partnerId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Контрагент"
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="warehouseId"
										relation="warehouse"
										placeholder="Выберите склад"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="warehouses"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.checkFormStore.model.warehouseId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Склад"
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="contractId"
										relation="contract"
										placeholder="Выберите договор"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partnerContracts"
										onlySelect={true}
										apiMethod={(): any => {
											if (!_.isEmpty(this.props.checkFormStore.model.partner)) {
												return controller.fetchAvailableContracts(this.props.checkFormStore.model.partner);
											} else {
												return Promise.reject(new Error('Контрагент не выбран!'));
											}
										}}
										create={(pluralName, handlers) => {
											this.props.goTo(`/${pluralName}/new?partnerId=${this.props.checkFormStore.model.partnerId}`, {}, handlers);
										}}
										autoComplete="off"
										disabled
										label="Договор"
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="priceTypeId"
										relation="priceType"
										placeholder="Выберите тип цены"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="priceTypes"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.checkFormStore.model.priceTypeId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Тип цены"
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.checkFormStore.model}
										property="statusId"
										relation="status"
										placeholder="Выберите статус"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'check_statuses' }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.checkFormStore.model.statusId)}
										invalidFeedback="Элемент не выбран"
										disabled
										label="Статус"
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<number> object={this.props.checkFormStore.model} property="amount" placeholder="Введите сумму" type="text" pattern="^([0-9]*[.,])?[0-9]*$" formatter={priceFormatter} size="sm" disabled label="Сумма" />
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.checkFormStore.setValue(this.props.checkFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.checkFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: true
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.checkFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: true
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.checkFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: true
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								},
								disabled: true
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(CheckForm));
