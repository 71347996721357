import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CLink, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../Alerts/AlertDialog';
import NatImageLoad from '../Components/NatImageLoad';
import { catalog } from '../support/Catalog';
import { FileType } from '../support/modelTypes';
import { NatImageManagerStoreType } from './NatImageManagerStore';
import NatRemoveBackground from './NatRemoveBackground';

interface PropsType extends RouteComponentProps {
	toggleNatImageManager(status: boolean): void;
	isModalOpen: boolean;
	store: any;
	setPrimaryImage?: (data: any) => void;
	parentGroup: FileType;
	removeBackground?: boolean;
}

interface StateType {
	columns: Array<any>;
	querySelector: any;
	isDeleteModalOpen: boolean;
	isRemoveBackgroundOpen: boolean;
}

interface InjectedProps extends PropsType {
	natImageManagerStore: NatImageManagerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore', 'natImageManagerStore')
@observer
class NatImageManager extends Component<PropsType, StateType> {
	myRef: any;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.resetData = this.resetData.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.sortList = this.sortList.bind(this);
		this.toggleRemoveBackgroundImage = this.toggleRemoveBackgroundImage.bind(this);
		this.state = {
			isDeleteModalOpen: false,
			querySelector: null,
			isRemoveBackgroundOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
						return (
							<div className="rgt-cell-inner ml-0 mr-0">
								<input type="checkbox" id="checkbox" onChange={onChange} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'name',
					label: 'Наименование',
					width: '400px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{catalog.renderCatalogName(data)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 2,
					field: 'format',
					label: 'Тип',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{data.format}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 3,
					field: 'id',
					label: 'Основное',
					visible: this.props.setPrimaryImage ? true : false,
					cellRenderer: ({ data }: { data: any }) => {
						if (this.props.setPrimaryImage) {
							if (this.props.store.model.primaryImageId === data.id) {
								return (
									<div className="rgt-cell-inner text-center">
										<CIcon name="cil-check-alt" size="lg" />
									</div>
								);
							} else {
								return (
									<div className="rgt-cell-inner text-center d-flex flex-column action">
										<CLink className="m-2 d-flex justify-content-center" onClick={() => this.props.setPrimaryImage !== undefined && this.props.setPrimaryImage(data)}>
											Сделать основным
										</CLink>
									</div>
								);
							}
						}
					},
					sort: () => {}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', true);
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'filterWhere', { deleted: false, ownerId: this.props.store.model.id, elementType: 0, sourceFileId: null });
		let filter = {
			where: this.injected.natImageManagerStore.filterWhere,
			skip: this.injected.natImageManagerStore.filterSkip,
			limit: this.injected.natImageManagerStore.itemsPerPage,
			order: this.injected.natImageManagerStore.filterOrder,
			include: this.injected.natImageManagerStore.filterInclude
		};
		controller
			.findAll('files', filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', data);
				}
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
			});
	}
	componentWillUnmount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: null
		});
	}
	toggleDeleteModal(status: boolean) {
		this.setState({
			isDeleteModalOpen: status
		});
	}
	toggleRemoveBackgroundImage(status: boolean) {
		this.setState({
			isRemoveBackgroundOpen: status
		});
	}
	handleScrollToElement() {
		let scrollTop = this.state.querySelector.scrollTop;
		let scrollHeight = this.state.querySelector.scrollHeight;
		let clientHeight = this.state.querySelector.clientHeight;
		let arr = this.injected.natImageManagerStore.list;
		let itemsPerPage = this.injected.natImageManagerStore.itemsPerPage;
		let itemsListLength = arr.length;
		if (scrollTop >= scrollHeight - clientHeight && this.injected.natImageManagerStore.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
			this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'currentPage', this.injected.natImageManagerStore.currentPage + 1);
			let skip = this.injected.natImageManagerStore.currentPage * this.injected.natImageManagerStore.itemsPerPage;
			let filter = {
				where: this.injected.natImageManagerStore.filterWhere,
				skip,
				limit: this.injected.natImageManagerStore.itemsPerPage,
				order: this.injected.natImageManagerStore.filterOrder
			};
			this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', true);
			controller
				.findAll(this.injected.natImageManagerStore.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', this.injected.natImageManagerStore.list.concat(data));
					}
					this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
				});
		}
	}
	sortList(colId: number, isAsc: boolean) {
		let order: Array<any> = [];
		if (colId !== null) {
			const groupedColumns = _.groupBy(this.state.columns, 'id');
			const sortBy = isAsc ? 'ASC' : 'DESC';
			order = [`${groupedColumns[colId][0].field} ${sortBy}`];
		} else {
			order = ['createdAt DESC'];
		}
		this.state.querySelector.scrollTo(0, 0);
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'filterOrder', order);
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'currentPage', 0);
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', true);
		let skip = this.injected.natImageManagerStore.currentPage * this.injected.natImageManagerStore.itemsPerPage;
		let filter = {
			where: this.injected.natImageManagerStore.filterWhere,
			skip,
			limit: this.injected.natImageManagerStore.itemsPerPage,
			order
		};
		controller
			.findAll(this.injected.natImageManagerStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', data);
				}
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
			});
	}
	collectModels(arrayIds: Array<any>) {
		let grouped = _.chain(this.injected.natImageManagerStore.list).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let obj = grouped[item];
			if (!_.isEmpty(obj)) {
				if (_.find(this.injected.natImageManagerStore.collectedModels, (itm: any) => itm.id === obj.id) === undefined) {
					runInAction(() => {
						this.injected.natImageManagerStore.collectedModels.push(obj);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.injected.natImageManagerStore.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'collectedModels', buffer);
	}
	resetData() {
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', []);
	}
	upload(data: any) {
		this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', true);
		controller
			.uploadFile(data)
			.then(() => {
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'filterSkip', 0);
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'currentPage', 0);
				let filter: any = {
					skip: this.injected.natImageManagerStore.filterSkip,
					limit: this.injected.natImageManagerStore.itemsPerPage,
					order: this.injected.natImageManagerStore.filterOrder,
					include: this.injected.natImageManagerStore.filterInclude
				};
				if (!_.isEmpty(this.injected.natImageManagerStore.filterWhere)) {
					filter.where = this.injected.natImageManagerStore.filterWhere;
				}
				controller
					.findAll('files', filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', data);
						}
						this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
					});
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
				catalog.showAlert('Загрузка прошла успешно!');
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
			});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ЗАГРУЗИТЬ',
				onClick: () => {}
			},
			{
				title: 'УДАЛИТЬ',
				onClick: () => {
					this.toggleDeleteModal(true);
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatImageManager(false);
					this.resetData();
				}
			}
		];
		return (
			<CModal
				show={this.props.isModalOpen}
				className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
				size="xl"
				closeOnBackdrop={false}>
				<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
					<div>
						{(() => {
							if (this.injected.directoryStore.models.windowSize > 1084) {
								return (
									<form className="d-flex w-100">
										{_.map(controlButtons, (item, index) => {
											if (!item.dontShow) {
												if (item.title !== 'ЗАГРУЗИТЬ') {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="ml-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												} else {
													return (
														<NatImageLoad
															key={index}
															modelStore={this.props.store}
															parentGroup={this.props.parentGroup}
															upload={(data: any) => {
																this.upload(data);
															}}
															title={item.title}
														/>
													);
												}
											}
										})}
									</form>
								);
							} else {
								return (
									<form className="d-flex w-100">
										<NatImageLoad
											modelStore={this.props.store}
											parentGroup={this.props.parentGroup}
											upload={(data: any) => {
												this.upload(data);
											}}
											title="ЗАГРУЗИТЬ"
										/>
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="ml-1 rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														if (item.title !== 'ЗАГРУЗИТЬ') {
															return (
																<CDropdownItem
																	key={index}
																	onClick={() => {
																		item.onClick();
																	}}>
																	{item.title}
																</CDropdownItem>
															);
														}
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									</form>
								);
							}
						})()}
					</div>
					<AlertDialog
						isModalOpen={this.state.isDeleteModalOpen}
						title="Выполнить действие?"
						buttonItemList={[
							{
								title: 'Да',
								onClick: () => {
									this.toggleDeleteModal(false);
									catalog
										.makeDeleted('files', this.injected.natImageManagerStore.collectedModels)
										.then(() => {
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', true);
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'filterSkip', 0);
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'currentPage', 0);
											let filter: any = {
												skip: this.injected.natImageManagerStore.filterSkip,
												limit: this.injected.natImageManagerStore.itemsPerPage,
												order: this.injected.natImageManagerStore.filterOrder,
												include: this.injected.natImageManagerStore.filterInclude
											};
											if (!_.isEmpty(this.injected.natImageManagerStore.filterWhere)) {
												filter.where = this.injected.natImageManagerStore.filterWhere;
											}
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'collectedModels', []);
											return controller.findAll('files', filter);
										})
										.then((data) => {
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', []);
											if (!_.isEmpty(data)) {
												this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'list', data);
											}
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'isLoading', false);
										});
								}
							},
							{
								title: 'Отмена',
								onClick: () => {
									this.toggleDeleteModal(false);
								}
							}
						]}
					/>
				</CModalHeader>
				<CModalBody className="p-0 m-4">
					<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100 nat__table__hover">
						<div className="nat__modal__table_1" ref={this.myRef}>
							<GridTable
								columns={this.state.columns}
								rows={this.injected.natImageManagerStore.list}
								isPaginated={false}
								texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
								showSearch={false}
								showColumnVisibilityManager={false}
								showRowsInformation={false}
								onColumnsChange={() => {}}
								selectedRowsIds={_.map(this.injected.natImageManagerStore.collectedModels, 'id')}
								onSelectedRowsChange={(selectedRowsIds: any) => this.collectModels(selectedRowsIds)}
								onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
									this.sortList(colId, isAsc);
								}}
								// onRowClick={({ data, column }: { data: any; column: any }) => {
								// 	if (column.id !== 'checkbox') {
								// 		if (this.props.removeBackground) {
								// 			this.injected.natImageManagerStore.setValue(this.injected.natImageManagerStore, 'selectedImage', data);
								// 			this.toggleRemoveBackgroundImage(true);
								// 		}
								// 	}
								// }}
							/>
						</div>
					</div>
				</CModalBody>
				{this.injected.natImageManagerStore.isLoading && (
					<div className="nat__spinner__absolute">
						<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
					</div>
				)}
				{this.state.isRemoveBackgroundOpen && <NatRemoveBackground toggleModal={this.toggleRemoveBackgroundImage} isModalOpen={this.state.isRemoveBackgroundOpen} image={this.injected.natImageManagerStore.selectedImage} upload={this.upload} />}
			</CModal>
		);
	}
}

export default withRouter(NatImageManager);
