import { observable, action, makeObservable, set } from 'mobx';

import { PriceTypeType } from '../../shared/support/modelTypes';

export interface PriceTypeFormStoreType {
	model: PriceTypeType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class PriceTypeFormStore implements PriceTypeFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		basePriceTypeId: null,
		deleted: false,
		discountMarkupPercent: null,
		displayName: null,
		name: { ru: '' },
		pricingOptionId: 'pricing_options.manual',
		priority: null,
		roundOff: false,
		roundingAccuracy: 0.01,
		roundingOptionId: 'rounding_options.by_arithmetic_rules',
		roundingPsychological: 0,
		statusId: 'price_type_statuses.valid',
		useWhenMakeBasedOn: false,
		useWhenSellingToCustomers: true,
		pricingOption: {
			description: null,
			keyId: 'manual',
			id: 'pricing_options.manual',
			name: { ru: 'Вручную' },
			ownerId: 'pricing_options',
			predefined: true,
			predefinedName: 'PricingOptionsManual',
			predefinedVersion: 0
		},
		roundingOption: {
			description: null,
			keyId: 'by_arithmetic_rules',
			id: 'rounding_options.by_arithmetic_rules',
			name: { ru: 'По арифметическим правилам' },
			ownerId: 'rounding_options',
			predefined: true,
			predefinedName: 'RoundingOptionsByArithmeticRules',
			predefinedVersion: 0
		},
		status: {
			description: null,
			keyId: '',
			id: 'price_type_statuses.valid',
			name: { ru: 'Действует' },
			ownerId: 'price_type_statuses',
			predefined: true,
			predefinedName: 'PriceTypeStatusesValid',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'priceTypes';
	filterInclude = ['pricingOption', 'basePriceType', 'roundingOption', 'status'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmPriceType';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const priceTypeFormStore = new PriceTypeFormStore();

export default PriceTypeFormStore;
