import { CCardBody, CNav, CNavItem } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';

interface PropsType {
	navItems: Array<NavItem>;
	tabItems?: Array<any>;
	getCurrentTab?: (data: { tabIndex: number; tabMetadata: { type: string } }) => void;
}

interface StateType {
	tabs: Array<number>;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

interface NavItem {
	title: string;
	metadata?: { type: string };
}

@inject('directoryStore')
@observer
class NatTabs extends Component<PropsType, StateType> {
	tabRef: any;
	constructor(props: PropsType) {
		super(props);
		this.tabRef = React.createRef();
		this.toggleTabs = this.toggleTabs.bind(this);
		this.state = {
			tabs: [0]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		if (!_.isEmpty(tabPanes[0])) {
			tabPanes[0].classList.add('nat__table__wrapper');
		}
		if (!_.isEmpty(tabs[0])) {
			tabs[0].classList.add('active');
		}
	}
	toggleTabs(index: number) {
		let tabs = this.tabRef.current.querySelectorAll('.nav-link');
		let tabPanes = this.tabRef.current.querySelectorAll('.nat__tab');
		_.forEach(tabs, (item, ind: number) => {
			if (index !== ind) {
				item.classList.remove('active');
			} else {
				item.classList.add('active');
			}
		});
		_.forEach(tabPanes, (item, ind: number) => {
			if (index !== ind) {
				item.classList.remove('nat__table__wrapper');
			} else {
				item.classList.add('nat__table__wrapper');
			}
		});
		const position = this.state.tabs.indexOf(index);
		let newDetails: Array<number> = [];
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(index);
		}
		this.setState({
			tabs: newDetails
		});
	}
	render() {
		return (
			<div ref={this.tabRef}>
				<div
					className={(() => {
						if (this.injected.directoryStore.models.windowSize > 1084) {
							if (this.props.navItems.length < 3 && this.props.navItems.length > 1) {
								return 'p-0 nat__form__tabs nat__form__tabs-2';
							} else if (this.props.navItems.length <= 1) {
								return 'p-0 nat__form__tabs nat__form__tabs-1';
							} else {
								return 'p-0 nat__form__tabs';
							}
						} else {
							return 'p-0 nat__form__tabs nat__form__tabs__mobile';
						}
					})()}>
					<CNav variant="tabs" className="p-2 mx-4">
						{_.map(this.props.navItems, (item, index) => {
							return (
								<CNavItem className="mr-1" key={index}>
									<a
										className="nav-link cursor__pointer"
										onClick={(event) => {
											event.preventDefault();
											this.toggleTabs(index);
											if (this.props.getCurrentTab) {
												if (!_.isEmpty(item.metadata) && item.metadata) {
													this.props.getCurrentTab({ tabIndex: index, tabMetadata: item.metadata });
												}
											}
										}}>
										{item.title}
									</a>
								</CNavItem>
							);
						})}
					</CNav>
				</div>
				{!_.isEmpty(this.props.tabItems) && (
					<CCardBody className="p-0 mx-4 mb-4 mt-3">
						{_.map(this.props.tabItems, (item, index) => {
							return (
								<div className="nat__tab" key={index}>
									{item}
								</div>
							);
						})}
					</CCardBody>
				)}
			</div>
		);
	}
}

export default NatTabs;
