import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CForm, CFormGroup, CLink } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import { stringFormatter } from '../shared/Formatters/StringFormatter';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import { MatchType } from '../shared/support/modelTypes';
import { UseLocation } from '../shared/support/useLocationHoC';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	query: any;
	setPassword(): any;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('userStore', 'directoryStore')
@observer
class SetPassword extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		document.title = 'Восстановление пароля';
		const query = this.props.query;
		const accessToken = query.get('accessTokenId') as any;
		const userId = query.get('userId') as any;
		const tenantClientId = query.get('tenantClientId') as any;
		this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'userId', userId);
		this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'accessTokenId', accessToken);
		this.injected.userStore.setValue(this.injected.userStore.userRecoveryPassword, 'tenantClientId', tenantClientId);
	}
	render() {
		return (
			<div className="justify-content-center align-items-center c-app nat__gardient__bg">
				<CCard className="d-flex flex-column align-items-center nat__login nat__card__wrapper nat__borders__2-75">
					<CCardHeader className="border-0 font-weight-bold bg-transparent">
						<h4 className="m-0">Восстановление пароля</h4>
					</CCardHeader>
					<CCardBody className="mx-4 pb-0">
						<CForm className="w-100">
							<NatValueInput<string>
								object={this.injected.userStore.userRecoveryPassword}
								property="password"
								placeholder="Введите пароль"
								type="password"
								formatter={stringFormatter(true)}
								size="lg"
								autoComplete="off"
								useHandleEnter={false}
								label="Пароль"
								loginInput
								invalid={_.isEmpty(this.injected.userStore.userRecoveryPassword.password)}
							/>
							<CFormGroup className="form-actions d-flex flex-column my-4">
								<CButton
									size="lg"
									className="nat__button"
									onClick={() => {
										this.props
											.setPassword()
											.then(() => {
												this.props.history.push('/users/setPassword/success');
											})
											.catch((error) => {
												catalog.handleNatError(error);
											});
									}}>
									Восстановить пароль
								</CButton>
							</CFormGroup>
						</CForm>
					</CCardBody>
					<CCardFooter className="d-flex flex-column border-0 bg-transparent pt-0">
						{this.injected.directoryStore.models.apiData.mode === 'tenant' && (
							<div>
								{'Еще нет учетной записи? '}
								<CLink to="/users/registration">Регистрация</CLink>
							</div>
						)}
						<CLink to="/users/login">Вход</CLink>
					</CCardFooter>
				</CCard>
			</div>
		);
	}
}

export default UseLocation(withRouter(SetPassword));
