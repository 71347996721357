import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { ProductBarcodeFormStoreType } from '../../productBarcodes/ProductBarcode/ProductBarcodeFormStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, ProductBarcodeType, ProductType } from '../../shared/support/modelTypes';
import ProductForm from './ProductForm';
import { ProductFormStoreType } from './ProductFormStore';

interface PropsType extends FormContainerPropsType<ProductFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	productBarcodeFormStore: ProductBarcodeFormStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'productBarcodeFormStore')
@observer
class ProductFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	priceReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.priceReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.updateList = this.updateList.bind(this);
		this.buildPriceItems = this.buildPriceItems.bind(this);
		this.pricesBulkUpsert = this.pricesBulkUpsert.bind(this);
		this.addEmptyString = this.addEmptyString.bind(this);
		this.createProductItemsList = this.createProductItemsList.bind(this);
		this.buildFeaturePriceItems = this.buildFeaturePriceItems.bind(this);
		this.resetPriceReactions = this.resetPriceReactions.bind(this);
		this.updateFeaturesAndPrices = this.updateFeaturesAndPrices.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentWillUnmount() {
		this.resetPriceReactions();
	}
	makePriceReactions() {
		this.resetPriceReactions();
		let groupedPriceByPriceType = _.chain(this.props.store.prices.list).groupBy('priceTypeId').value();
		let priceItemsMap = _.chain(this.props.store.prices.list)
			.keyBy((item) => {
				return `${item.featureId}-${item.priceTypeId}`;
			})
			.value();
		_.forEach(this.props.store.prices.pricesTableList, (item) => {
			this.priceReactions.push(
				reaction(
					() => item.price,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (this.props.store.prices.isRoot) {
								let priceItems = groupedPriceByPriceType[item.priceTypeId];
								if (!_.isEmpty(priceItems)) {
									_.forEach(priceItems, (priceItem) => {
										priceItem.price = value;
									});
								}
							} else {
								let priceItem = priceItemsMap[`${item.featureId}-${item.priceTypeId}`];
								if (!_.isEmpty(priceItem)) {
									priceItem.price = value;
								}
							}
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
			this.priceReactions.push(
				reaction(
					() => item.unitId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (this.props.store.prices.isRoot) {
								let priceItems = groupedPriceByPriceType[item.priceTypeId];
								if (!_.isEmpty(priceItems)) {
									_.forEach(priceItems, (priceItem) => {
										priceItem.unitId = value;
										priceItem.unit = item.unit;
									});
								}
							} else {
								let priceItem = priceItemsMap[`${item.featureId}-${item.priceTypeId}`];
								if (!_.isEmpty(priceItem)) {
									priceItem.unitId = value;
									priceItem.unit = item.unit;
								}
							}
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
			this.priceReactions.push(
				reaction(
					() => item.validityPeriodId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (this.props.store.prices.isRoot) {
								let priceItems = groupedPriceByPriceType[item.priceTypeId];
								if (!_.isEmpty(priceItems)) {
									_.forEach(priceItems, (priceItem) => {
										priceItem.validityPeriodId = value;
										priceItem.validityPeriod = item.validityPeriod;
									});
								}
							} else {
								let priceItem = priceItemsMap[`${item.featureId}-${item.priceTypeId}`];
								if (!_.isEmpty(priceItem)) {
									priceItem.validityPeriodId = value;
									priceItem.validityPeriod = item.validityPeriod;
								}
							}
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (key !== 'unit' && key !== 'type' && key !== 'kind' && key !== 'accountingOption' && key !== 'usePackageOption' && key !== 'useFeatureOption' && key !== 'parent') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.useQuantityLimit,
				(value) => {
					if (!value) {
						this.props.store.setValue(this.props.store.model, 'quantityLimitRange', null);
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.unitId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (this.props.match.params.elementId !== 'new') {
							this.updateFeaturesAndPrices(this.props.store.model);
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.accountingOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'accountingOptionId', 'product_accounting_options.standart');
						this.props.store.setValue(this.props.store.model, 'accountingOption', {
							id: 'product_accounting_options.standart',
							keyId: 'standart',
							ownerId: 'product_accounting_options',
							name: { ru: 'Стандартный' },
							description: null,
							predefined: true,
							predefinedName: 'ProductAccountingOptionsStandart',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.validityPeriodId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'validityPeriodId', 'periodicity.month');
						this.props.store.setValue(this.props.store.model, 'validityPeriod', {
							id: 'periodicity.month',
							keyId: 'month',
							ownerId: 'periodicity',
							name: { ru: 'Месяц' },
							description: null,
							priority: 6,
							predefined: true,
							predefinedName: 'PeriodicityMonth',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.typeId,
				(value, previousValue) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'typeId', 'product_types.goods');
						this.props.store.setValue(this.props.store.model, 'type', {
							id: 'product_types.goods',
							keyId: 'goods',
							ownerId: 'product_types',
							name: { ru: 'Товар' },
							description: null,
							predefined: true,
							predefinedName: 'ProductTypesGoods',
							predefinedVersion: 0
						});
					}
					if (value !== previousValue) {
						if (this.props.match.params.elementId !== 'new') {
							this.updateFeaturesAndPrices(this.props.store.model);
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.useFeatureOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'useFeatureOptionId', 'use_feature_options.not_used');
						this.props.store.setValue(this.props.store.model, 'useFeatureOption', {
							id: 'use_feature_options.not_used',
							keyId: 'not_used',
							ownerId: 'use_feature_options',
							name: { ru: 'Не используется' },
							description: null,
							predefined: true,
							predefinedName: 'UseFeatureOptionsNotUsed',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.usePackageOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'usePackageOptionId', 'use_package_options.not_used');
						this.props.store.setValue(this.props.store.model, 'usePackageOption', {
							id: 'use_package_options.not_used',
							keyId: 'not_used',
							ownerId: 'use_package_options',
							name: { ru: 'Не используется' },
							description: null,
							predefined: true,
							predefinedName: 'UsePackageOptionsNotUsed',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.kindId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (this.props.match.params.elementId !== 'new') {
							this.updateFeaturesAndPrices(this.props.store.model);
						}
						if (!_.isEmpty(this.props.store.model.kind) && this.props.store.model.kind !== undefined) {
							this.props.store.setValue(this.props.store.model, 'unitId', this.props.store.model.kind.unitId);
							this.props.store.setValue(this.props.store.model, 'accountingOptionId', this.props.store.model.kind.accountingOptionId);
							this.props.store.setValue(this.props.store.model, 'useFeatureOptionId', this.props.store.model.kind.useFeatureOptionId);
							this.props.store.setValue(this.props.store.model, 'useSerialNumbers', this.props.store.model.kind.useSerialNumbers);
							this.props.store.setValue(this.props.store.model, 'setPrintOptionId', this.props.store.model.kind.setPrintOptionId);
							this.props.store.setValue(this.props.store.model, 'setPriceCalculationMethodId', this.props.store.model.kind.setPriceCalculationMethodId);
							if (!_.isEmpty(this.props.store.model.kind.unit)) {
								this.props.store.setValue(this.props.store.model, 'unit', this.props.store.model.kind.unit);
							}
							if (!_.isEmpty(this.props.store.model.kind.accountingOption)) {
								this.props.store.setValue(this.props.store.model, 'accountingOption', this.props.store.model.kind.accountingOption);
							}
							if (!_.isEmpty(this.props.store.model.kind.useFeatureOption)) {
								this.props.store.setValue(this.props.store.model, 'useFeatureOption', this.props.store.model.kind.useFeatureOption);
							}
							if (!_.isEmpty(this.props.store.model.kind.setPrintOption)) {
								this.props.store.setValue(this.props.store.model, 'setPrintOption', this.props.store.model.kind.setPrintOption);
							}
							if (!_.isEmpty(this.props.store.model.kind.setPriceCalculationMethod)) {
								this.props.store.setValue(this.props.store.model, 'setPriceCalculationMethod', this.props.store.model.kind.setPriceCalculationMethod);
							}
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => any, elementType?: number, parentId?: string) {
		document.title = 'Журнал';
		catalog.findInstances('files', { predefinedName: 'ProductImages', elementType: 1 }, this.props.store, () => {}, true);
		if (elementId !== 'new') {
			findModel(elementId);
			catalog.findInstances('productBarcodes', { productId: elementId }, this.props.store, () => {}, true);
			catalog.findInstances('serialNumbers', { ownerId: elementId }, this.props.store, () => {}, true);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			if (!_.isEmpty(parentId)) {
				let filter = {
					where: { id: parentId },
					limit: 1
				};
				controller
					.findAll(this.props.store.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.store.setValue(this.props.store.model, 'parent', data[0]);
							this.props.store.setValue(this.props.store.model, 'parentId', data[0].id);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
			let predefinedUnitFilter = {
				where: {
					predefined: true,
					predefinedName: 'Piece'
				},
				limit: 1
			};
			this.props.store.setValue(this.props.store.model, 'elementType', Number(elementType));
			if (Number(elementType) === 0) {
				controller
					.findAll('productUnits', predefinedUnitFilter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.store.setValue(this.props.store.model, 'unit', data[0]);
							this.props.store.setValue(this.props.store.model, 'unitId', data[0].id);
						}
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
						this.props.store.setValue(this.props.store, 'isChanged', true);
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			} else {
				catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
				this.props.store.setValue(this.props.store, 'isChanged', true);
			}
		}
	}
	fillEmptyRelations(model: ProductType) {
		if (model.accountingOptionId === 'product_accounting_options.standart' && _.isEmpty(model.accountingOption)) {
			model.accountingOption = _.cloneDeep(this.props.store.model.accountingOption);
		}
		if (model.typeId === 'product_types.goods' && _.isEmpty(model.type)) {
			model.type = _.cloneDeep(this.props.store.model.type);
		}
		if (model.usePackageOptionId === 'use_package_options.not_used' && _.isEmpty(model.usePackageOption)) {
			model.usePackageOption = _.cloneDeep(this.props.store.model.usePackageOption);
		}
		if (model.useFeatureOptionId === 'use_feature_options.not_used' && _.isEmpty(model.useFeatureOption)) {
			model.useFeatureOption = _.cloneDeep(this.props.store.model.useFeatureOption);
		}
		if (model.validityPeriodId === 'periodicity.month' && _.isEmpty(model.validityPeriod)) {
			model.validityPeriod = _.cloneDeep(this.props.store.model.validityPeriod);
		}
		return model;
	}
	prepareModelForPost(model: ProductType) {
		delete model.unit;
		delete model.kind;
		delete model.type;
		delete model.accountingOption;
		delete model.usePackageOption;
		delete model.useFeatureOption;
		delete model.validityPeriod;
		return model;
	}
	validate() {
		if (this.props.store.model.typeId === 'product_types.set') {
			return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.setPriceCalculationMethodId) && !_.isEmpty(this.props.store.model.setPrintOptionId);
		} else {
			return !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]);
		}
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetPriceReactions() {
		this.priceReactions.forEach((dispose) => dispose());
		this.priceReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	updateList(model: ProductType) {
		catalog.findInstances('productBarcodes', { productId: model.id }, this.props.store, () => {}, true);
		catalog.findInstances('serialNumbers', { ownerId: model.id }, this.props.store, () => {}, true);
		this.updateFeaturesAndPrices(model);
	}
	buildPriceItems(prices: Array<any>) {
		const currentPricesMap = _.chain(prices).cloneDeep().keyBy('priceTypeId').value();
		const pricesItems = [] as Array<any>;
		_.forEach(currentPricesMap, (item: any) => {
			pricesItems.push(item);
		});
		this.props.store.setValue(this.props.store.prices, 'isRoot', true);
		this.props.store.setValue(this.props.store.prices, 'list', prices);
		this.props.store.setValue(this.props.store.prices, 'pricesTableList', pricesItems);
		this.makePriceReactions();
	}
	buildFeaturePriceItems(data: any) {
		const pricesMap = _.chain(this.props.store.prices.list)
			.cloneDeep()
			.groupBy((item) => {
				return item.priceTypeId === data.priceTypeId;
			})
			.value();
		const pricesItems = pricesMap['true'];
		this.props.store.setValue(this.props.store.prices, 'isRoot', false);
		this.props.store.setValue(this.props.store.prices, 'pricesTableList', pricesItems);
		this.makePriceReactions();
	}
	pricesBulkUpsert() {
		let result: Array<any> = [];
		_.transform(
			this.props.store.prices.list,
			(result: any, value: any) => {
				if (value.price !== 0) {
					delete value.priceType;
					delete value.feature;
					delete value.unit;
					delete value.validityPeriod;
					value.ownerId = value.productId;
					value.ownerType = 'OrmProduct';
					result.push(value);
				}
			},
			result
		);
		controller
			.bulkUpsert('productPrices', result)
			.then(() => {})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	addEmptyString() {
		let obj: ProductBarcodeType = _.cloneDeep(this.injected.productBarcodeFormStore.model);
		obj.id = generateRandomId();
		if (this.props.store.model.id !== undefined) {
			obj.productId = this.props.store.model.id;
			obj.product = this.props.store.model;
		}
		if (!_.isEmpty(this.props.store.model.unitId)) {
			obj.unitId = this.props.store.model.unitId;
			if (!_.isEmpty(this.props.store.model.unit)) {
				obj.unit = this.props.store.model.unit;
			}
		}
		runInAction(() => {
			this.props.store.productBarcodes.list.push(obj);
		});
	}
	createProductItemsList(product: ProductType, priceTypes: Array<any>, productFeatures: Array<any>) {
		let productItems: Array<any> = [];
		_.forEach(priceTypes, (item) => {
			let productItem: any = null;
			if (!_.isEmpty(productFeatures)) {
				_.forEach(productFeatures, (itm) => {
					productItem = {
						productId: product.id,
						product,
						featureId: itm.id,
						feature: itm,
						unitId: product.unitId,
						priceTypeId: item.id,
						priceType: item,
						price: 0,
						id: generateRandomId()
					};
					if (!_.isEmpty(product.unit)) {
						productItem.unit = product.unit;
					}
					if (product.typeId === 'product_types.subscription' || product.typeId === 'product_types.subscription_option') {
						productItem.validityPeriodId = product.validityPeriodId;
						if (!_.isEmpty(product.validityPeriod)) {
							productItem.validityPeriod = product.validityPeriod;
						}
					}
					productItems.push(productItem);
				});
			} else {
				productItem = {
					productId: product.id,
					product,
					unitId: product.unitId,
					priceTypeId: item.id,
					priceType: item,
					price: 0,
					id: generateRandomId()
				};
				if (!_.isEmpty(product.unit)) {
					productItem.unit = product.unit;
				}
				if (product.typeId === 'product_types.subscription' || product.typeId === 'product_types.subscription_option') {
					productItem.validityPeriodId = product.validityPeriodId;
					if (!_.isEmpty(product.validityPeriod)) {
						productItem.validityPeriod = product.validityPeriod;
					}
				}
				productItems.push(productItem);
			}
		});
		return productItems;
	}
	updateFeaturesAndPrices(model: ProductType) {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		controller
			.fetchFeaturesByProduct({ product: model })
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.props.store.setValue(this.props.store.productFeatures, 'list', data);
				}
				return controller.findAll('priceTypes', {
					where: {
						deleted: false
					}
				});
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					let productItems: Array<any> = this.createProductItemsList(model, data, this.props.store.productFeatures.list);
					return controller.updateProductItemListPrices(productItems);
				} else {
					this.props.store.setValue(this.props.store, 'isLoading', false);
				}
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.buildPriceItems(data);
				}
				this.props.store.setValue(this.props.store, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				executeAfterFinding={this.updateFeaturesAndPrices}
				updateList={this.updateList}
				parentRoute="/products/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<ProductForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						productFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						findInstances={catalog.findInstances}
						pricesBulkUpsert={this.pricesBulkUpsert}
						addEmptyString={this.addEmptyString}
						buildFeaturePriceItems={this.buildFeaturePriceItems}
						buildPriceItems={this.buildPriceItems}
						replace={this.props.replace}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default ProductFormContainer;
