import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { CheckType, FormContainerPropsType } from '../../shared/support/modelTypes';
import CheckForm from './CheckForm';
import { CheckFormStoreType } from './CheckFormStore';

interface PropsType extends FormContainerPropsType<CheckFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class CheckFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.rebuild = this.rebuild.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
					}
				}
			)
		);
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.statusId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'statusId', 'check_statuses.pending');
						this.props.store.setValue(this.props.store.model, 'status', {
							id: 'check_statuses.pending',
							keyId: 'pending',
							ownerId: 'check_statuses',
							name: { ru: 'Отложен' },
							description: null,
							predefined: true,
							predefinedName: 'CheckStatusesPending',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.typeId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'typeId', 'check_types.income');
						this.props.store.setValue(this.props.store.model, 'type', {
							id: 'check_types.income',
							keyId: 'income',
							ownerId: 'check_types',
							name: { ru: 'Приход' },
							description: null,
							predefined: true,
							predefinedName: 'CheckTypesIncome',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.productItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.partnerId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							catalog.fetchApplicableContract(this.props.store);
						} else {
							this.props.store.model.contractId = null;
							delete this.props.store.model.contract;
						}
					}
				}
			)
		);
		_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (key !== 'feature' && key !== 'unit' && key !== 'serialNumber') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
							if (key === 'quantity' || key === 'price' || key === 'manualDiscountPercent') {
								catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
							}
						}
					)
				);
			});
			this.formReactions.push(
				reaction(
					() => item.validityPeriodId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									if (item.product.typeId === 'product_types.subscription' || item.product.typeId === 'product_types.subscription_option') {
										item.validityPeriodId = 'periodicity.month';
										item.validityPeriod = {
											id: 'periodicity.month',
											keyId: 'month',
											ownerId: 'periodicity',
											name: { ru: 'Месяц' },
											description: null,
											priority: 6,
											predefined: true,
											predefinedName: 'PeriodicityMonth',
											predefinedVersion: 0
										};
									}
								}
							} else {
								this.props.store.setValue(this.props.store, 'isLoading', true);
								controller
									.updateProductItemListPrices([item], this.props.store.model.priceType)
									.then((data) => {
										if (!_.isEmpty(data)) {
											runInAction(() => {
												item.price = data[0].price;
											});
										}
										this.props.store.setValue(this.props.store, 'isLoading', false);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.props.store.setValue(this.props.store, 'isLoading', false);
									});
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.validityPeriodCount,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isLoading', true);
							controller
								.updateProductItemListPrices([item], this.props.store.model.priceType)
								.then((data) => {
									if (!_.isEmpty(data)) {
										runInAction(() => {
											item.price = data[0].price;
										});
										catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
									}
									this.props.store.setValue(this.props.store, 'isLoading', false);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.store.setValue(this.props.store, 'isLoading', false);
									catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
								});
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.serialNumberId = null;
							item.validityPeriodCount = 0;
							delete item.feature;
							delete item.serialNumber;
							if (!_.isEmpty(value)) {
								if (item.validityPeriodId !== item.product.validityPeriodId) {
									item.validityPeriodId = item.product.validityPeriodId;
								} else {
									this.props.store.setValue(this.props.store, 'isLoading', true);
									controller
										.updateProductItemListPrices([item], this.props.store.model.priceType)
										.then((data) => {
											if (!_.isEmpty(data)) {
												runInAction(() => {
													item.price = data[0].price;
												});
											}
											this.props.store.setValue(this.props.store, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.props.store.setValue(this.props.store, 'isLoading', false);
										});
								}
								if (!_.isEmpty(item.product.validityPeriod)) {
									item.validityPeriod = item.product.validityPeriod;
								}
								if (!_.isEmpty(item.product.unit)) {
									item.unit = item.product.unit;
								}
								item.unitId = item.product.unitId;
							} else {
								item.unitId = null;
								item.validityPeriodId = null;
								delete item.validityPeriod;
								delete item.product;
								delete item.unit;
								this.rebuild(_.get(this.props.store.model, 'productItems', []));
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.amount,
					(value, previousValue) => {
						if (item.amount !== previousValue) {
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: CheckType) {
		if (model.statusId === 'check_statuses.pending' && _.isEmpty(model.status)) {
			model.status = _.cloneDeep(this.props.store.model.status);
		}
		if (model.typeId === 'check_types.income' && _.isEmpty(model.type)) {
			model.type = _.cloneDeep(this.props.store.model.type);
		}
		return model;
	}
	prepareModelForPost(model: CheckType) {
		_.forEach(model.productItems, (item) => {
			delete item.unit;
			delete item.feature;
			delete item.serialNumber;
			delete item.product;
		});
		runInAction(() => {
			model.productItemList = model.productItems;
		});
		runInAction(() => {
			model.loyaltyPolicyItemList = model.loyaltyPolicyItems;
		});
		runInAction(() => {
			model.prepayItemList = model.prepayItems;
		});
		runInAction(() => {
			model.bonusPointIncomeItemList = model.bonusPointIncomeItems;
		});
		runInAction(() => {
			model.paymentItemList = model.paymentItems;
		});
		delete model.organization;
		delete model.partner;
		delete model.cashRegister;
		delete model.status;
		delete model.contract;
		delete model.priceType;
		delete model.cashRegisterShift;
		delete model.warehouse;
		delete model.type;
		return model;
	}
	validate() {
		return !_.isEmpty(this.props.store.model.partnerId) && !_.isEmpty(this.props.store.model.priceTypeId) && !_.isEmpty(this.props.store.model.warehouseId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', items);
		this.makeReactions();
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				isDocument
				parentRoute="/checks/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<CheckForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						checkFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
						fillEmptyRelations={this.fillEmptyRelations}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default CheckFormContainer;
