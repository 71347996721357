import { observable, action, makeObservable, set } from 'mobx';

import { PartnerSettlementOperationType } from '../../shared/support/modelTypes';

export interface PartnerSettlementOperationFormStoreType {
	model: PartnerSettlementOperationType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	isSequenceNumberChangedManually: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class PartnerSettlementOperationFormStore implements PartnerSettlementOperationFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			isSequenceNumberChangedManually: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		amount: 0,
		contractId: null,
		deleted: false,
		organizationId: null,
		ownerId: null,
		ownerType: null,
		partnerId: null,
		paymentOptionId: null,
		posted: false,
		typeId: null,
		operationId: null,
		statusId: 'payment_operation_statuses.not_paid',
		periodAt: null,
		responsibleId: null,
		authorId: null,
		note: null,
		specificationItemList: [],
		specificationItems: [],
		status: {
			id: 'payment_operation_statuses.not_paid',
			keyId: 'not_paid',
			ownerId: 'payment_operation_statuses',
			name: {
				ru: 'Не оплачен'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'PaymentOperationStatusesNotPaid',
			predefinedVersion: 0
		}
	};
	searchText = '';
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'partnerSettlementOperations';
	filterInclude = [
		'partner',
		'organization',
		'status',
		'contract',
		'paymentOption',
		'type',
		{
			relation: 'specificationItems',
			scope: {
				include: ['order', 'document', 'partner', 'contract', 'organization']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultPaymentOption',
			property: 'paymentOptionId',
			relation: 'paymentOption'
		}
	];
	isFirstLoad = true;
	name = 'OrmPartnerSettlementOperation';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const partnerSettlementOperationFormStore = new PartnerSettlementOperationFormStore();

export default PartnerSettlementOperationFormStore;
