import { CCol, CForm, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { selectFormatter } from '../../shared/Formatters/SelectFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatLocalizedTextarea from '../../shared/Inputs/NatLocalizedTextarea';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatStringSelectInput from '../../shared/Inputs/NatStringSelectInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { CouponFormStoreType } from './CouponFormStore';

interface PropsType extends FormPropsType {
	couponFormStore: CouponFormStoreType;
	childrenProps: ChildrenPropsType;
	saveRuleItems(): void;
	buildRuleItems(): void;
}
interface StateType {
	selectList: Array<any>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class CouponForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.state = {
			selectList: [
				{
					name: 'ru',
					displayName: 'Русский'
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.couponFormStore}
				navTabs={[{ name: 'Дополнительно' }, { name: 'Описание' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.couponFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.couponFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>,
						<>
							{(() => {
								if (this.injected.directoryStore.models.locales.length > 1) {
									return (
										<div className="nat__card__wrapper nat__borders__2-75 p-2 mx-4 d-flex align-items-center justify-content-between">
											<CForm className="ml-3">
												<NatStringSelectInput
													object={this.props.couponFormStore}
													property="language"
													type="text"
													size="sm"
													formatter={selectFormatter}
													autoComplete="off"
													list={this.state.selectList}
													placeholder="Выберите"
													label="Язык описания"
													formGroupClassName="w-100 align-items-center nat__form__group mb-0"
												/>
											</CForm>
										</div>
									);
								}
							})()}
							<div className={this.injected.directoryStore.models.locales.length > 1 ? 'nat__form__table__wrapper m-4 h-100' : 'nat__form__table__wrapper mx-4 mb-4 h-100'}>
								<div className="nat__form__table">
									<NatLocalizedTextarea
										object={this.props.couponFormStore.model}
										property="description"
										label=""
										placeholder="Введите описание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={localizedStringFormatter}
										row={false}
										language={this.props.couponFormStore.language}
										size="sm"
									/>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.couponFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.couponFormStore.model}
										property="code"
										placeholder="Введите код"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Код"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.couponFormStore.model}
										property="partnerId"
										relation="partner"
										placeholder="Выберите контрагента"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partners"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										label="Контрагент"
										mobile
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.couponFormStore.model}
										property="statusId"
										relation="status"
										placeholder="Выберите статус"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="enumerationItems"
										onlySelect={true}
										filterWhere={{ ownerId: 'coupon_statuses' }}
										autoComplete="off"
										label="Статус"
										mobile
										filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.couponFormStore.model}
										property="ownerId"
										relation="owner"
										placeholder="Выберите вид"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="couponKinds"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Виды купонов"
										mobile
										invalid={_.isEmpty(this.props.couponFormStore.model.ownerId)}
										invalidFeedback="Элемент не выбран"
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<number>
										object={this.props.couponFormStore.model}
										property="quantityOfUses"
										placeholder="Введите число"
										type="text"
										pattern="^-?([0-9]*[.,])?[0-9]*$"
										formatter={numberFormatter}
										size="sm"
										label="Количество использований"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date> object={this.props.couponFormStore.model} property="startedAt" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Начало действия" />
								</CCol>
								<CCol md="4">
									<NatValueInput<Date> object={this.props.couponFormStore.model} property="expiredAt" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Окончание действия" />
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.couponFormStore.setValue(this.props.couponFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.couponFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.couponFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.couponFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(CouponForm));
