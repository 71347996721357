import { CCol, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { priceFormatter } from '../../shared/Formatters/PriceFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { IncomeCashOrderFormStoreType } from './IncomeCashOrderFormStore';

interface PropsType extends FormPropsType {
	incomeCashOrdersFormStore: IncomeCashOrderFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class IncomeCashOrderForm extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.incomeCashOrdersFormStore}
				navTabs={[{ name: 'Примечание' }, { name: 'Дополнительно' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				tabsContent={() => {
					return [
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.incomeCashOrdersFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.incomeCashOrdersFormStore.model}
													property="organizationBankAccountId"
													relation="organizationBankAccount"
													placeholder="Выберите счет"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="organizationBankAccounts"
													filterWhere={{ deleted: false }}
													autoComplete="off"
													label="Банковский счет организации"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.incomeCashOrdersFormStore.model}
													property="cashBoxId"
													relation="cashBox"
													placeholder="Выберите кассу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="cashBoxs"
													filterWhere={{ deleted: false, ownerId: this.props.incomeCashOrdersFormStore.model.organizationId, ownerType: 'OrmOrganization' }}
													autoComplete="off"
													label="Касса"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatRelationInput
													object={this.props.incomeCashOrdersFormStore.model}
													property="cashRegisterId"
													relation="cashRegister"
													placeholder="Выберите кассу"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="cashRegisters"
													filterWhere={{ deleted: false, organizationId: this.props.incomeCashOrdersFormStore.model.organizationId }}
													autoComplete="off"
													label="Касса ККМ"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.incomeCashOrdersFormStore.model}
													property="chiefAccountantId"
													relation="chiefAccountant"
													placeholder="Выберите бухгалтера"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="responsiblePersons"
													filterWhere={{
														deleted: false,
														ownerId: this.props.incomeCashOrdersFormStore.model.organizationId,
														or: [
															{
																expiredAt: {
																	gt: new Date().toISOString()
																}
															},
															{
																expiredAt: null
															}
														]
													}}
													autoComplete="off"
													label="Главный бухгалтер"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.incomeCashOrdersFormStore.model}
													property="annex"
													placeholder="Введите приложение"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Приложение"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatValueInput<string>
													object={this.props.incomeCashOrdersFormStore.model}
													property="sequenceNumber"
													placeholder="Введите номер"
													type="text"
													formatter={stringFormatter()}
													size="sm"
													autoComplete="off"
													label="Номер"
													mobile
													onClick={() => {
														if (!this.props.incomeCashOrdersFormStore.isSequenceNumberChangedManually) {
															this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
														}
													}}
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date> object={this.props.incomeCashOrdersFormStore.model} property="periodAt" placeholder="Введите дату" type="datetime-local" step="1" formatter={dateTimeFormatter} size="sm" label="Дата документа" />
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.incomeCashOrdersFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.incomeCashOrdersFormStore.model.organizationId)}
										invalidFeedback="Элемент не выбран"
										include={['bankAccount']}
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.incomeCashOrdersFormStore.model}
										property="partnerId"
										relation="partner"
										placeholder="Выберите контрагента"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partners"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										invalid={_.isEmpty(this.props.incomeCashOrdersFormStore.model.partnerId)}
										invalidFeedback="Элемент не выбран"
										label="Контрагент"
										mobile
										hierarchicalSelect
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.incomeCashOrdersFormStore.model}
										property="accountablePersonId"
										relation="accountablePerson"
										placeholder="Выберите подотчетное лицо"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="naturalPersons"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Подотчетное лицо"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.incomeCashOrdersFormStore.model}
										property="contractId"
										relation="contract"
										placeholder="Выберите договор"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="partnerContracts"
										apiMethod={(): any => {
											if (!_.isEmpty(this.props.incomeCashOrdersFormStore.model.partner)) {
												return controller.fetchAvailableContracts(this.props.incomeCashOrdersFormStore.model.partner);
											} else {
												return Promise.reject(new Error('Контрагент не выбран!'));
											}
										}}
										disabled={_.isEmpty(this.props.incomeCashOrdersFormStore.model.partnerId)}
										create={(pluralName, handlers) => {
											this.props.goTo(`/${pluralName}/new?partnerId=${this.props.incomeCashOrdersFormStore.model.partnerId}`, {}, handlers);
										}}
										autoComplete="off"
										label="Договор"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string> object={this.props.incomeCashOrdersFormStore.model} property="basis" placeholder="Введите основание" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Основание" />
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<number>
										object={this.props.incomeCashOrdersFormStore.model}
										property="amount"
										placeholder="Введите сумму"
										type="text"
										pattern="^([0-9]*[.,])?[0-9]*$"
										formatter={priceFormatter}
										size="sm"
										label="Сумма"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.incomeCashOrdersFormStore.model}
										property="takenFrom"
										placeholder="Введите строку"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Принято от"
										mobile
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.incomeCashOrdersFormStore.setValue(this.props.incomeCashOrdersFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.incomeCashOrdersFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: this.props.incomeCashOrdersFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.incomeCashOrdersFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: this.props.incomeCashOrdersFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.incomeCashOrdersFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(IncomeCashOrderForm));
