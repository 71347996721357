import { observable, action, makeObservable, set } from 'mobx';

export interface NatLocalizedValueInputStoreType {
	language: string;
	setValue(model: any, key: string, value: any): void;
}

class NatLocalizedValueInputStore implements NatLocalizedValueInputStoreType {
	constructor() {
		makeObservable(this, {
			language: observable,
			setValue: action
		});
	}
	language = 'ru';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natLocalizedValueInputStore = new NatLocalizedValueInputStore();

export default NatLocalizedValueInputStore;
