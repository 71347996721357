import { CButton, CCol, CFormGroup, CLabel } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import NatInputGroup from './NatInputGroup';

interface PropsType {
	buttonItemList?: Array<ButtonItem>;
	label?: string;
}
interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatButtonInput extends Component<PropsType> {
	id: string;
	constructor(props: PropsType) {
		super(props);
		this.id = generateRandomId();
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatInputGroup>
				{() => (
					<CFormGroup
						row
						className={(() => {
							if (this.injected.directoryStore.models.windowSize < 490) {
								return 'w-100 align-items-center nat__form__group flex-nowrap';
							} else {
								return 'w-100 align-items-center nat__form__group';
							}
						})()}>
						{!_.isEmpty(this.props.label) && (
							<CLabel
								htmlFor={this.id}
								title={this.props.label}
								className={(() => {
									if (this.props.label) {
										if ((this.props.label.length > 13 && this.injected.directoryStore.models.windowSize < 490) || (this.props.label.length > 5 && this.injected.directoryStore.models.windowSize < 430)) {
											return 'align-self-center text-truncate text-left pl-3 m-0 nat__input__label__mobile';
										} else {
											return 'align-self-center text-truncate pl-3 m-0';
										}
									}
								})()}>
								{this.props.label}
							</CLabel>
						)}
						<CCol className={!_.isEmpty(this.props.label) ? 'align-self-center pr-0 d-flex' : 'align-self-center pr-0 pl-0 d-flex'}>
							{_.map(this.props.buttonItemList, (value: ButtonItem, index: number) => {
								return (
									<CButton key={index} variant="ghost" className="nat__form__button text-right w-100" onClick={value.onClick} disabled={value.disabled}>
										{value.title}
									</CButton>
								);
							})}
						</CCol>
					</CFormGroup>
				)}
			</NatInputGroup>
		);
	}
}

export default NatButtonInput;
