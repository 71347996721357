import { observable, action, makeObservable, set } from 'mobx';

import { SubscriptionOptionType } from '../../shared/support/modelTypes';

export interface SubscriptionOptionFormStoreType {
	model: SubscriptionOptionType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class SubscriptionOptionFormStore implements SubscriptionOptionFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		tag: '',
		typeId: '',
		meaningId: null,
		valueAtZero: null,
		description: null,
		name: { ru: '' },
		displayName: null,
		priority: 0,
		deleted: false,
		defaultName: null,
		parentId: null,
		elementType: 0
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'subscriptionOptions';
	filterInclude = ['meaning', 'type'];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmSubscriptionOption';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const subscriptionOptionFormStore = new SubscriptionOptionFormStore();

export default SubscriptionOptionFormStore;
