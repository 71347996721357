import _ from 'lodash';

import { catalog } from '../../support/Catalog';
import { LoyaltyPolicyType } from '../../support/modelTypes';
import AutocompleteAdapter from './AutocompleteAdapter';

class LoyaltyPolicyAutocompleteAdapter extends AutocompleteAdapter<LoyaltyPolicyType, string> {
	public fetch(source: LoyaltyPolicyType): Promise<Array<string>> {
		let result: string = '';
		if (source.elementType === 0) {
			if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.sum') {
				if (source.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_bonus_points') {
					result += `${source.policyValue} бонусов программы лояльности ${catalog.renderCatalogName(source.loyaltyProgram)}`;
				} else {
					result += `${source.policyValue} руб.`;
				}
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.percentage') {
				if (source.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_bonus_points') {
					result += `${source.policyValue * 100}%, программа лояльности ${catalog.renderCatalogName(source.loyaltyProgram)}`;
				} else {
					result += `${source.policyValue * 100}%`;
				}
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.sum_per_row') {
				result += `${source.policyValue} руб.`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.quantity') {
				result += `Бесплатно ${source.policyValue} ед. из ${source.quantityDiscountCondition} ед.`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.price_type') {
				result += `Вид цены: ${catalog.renderCatalogName(source.priceType)}`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.message') {
				result += `Сообщение: ${catalog.fetchLocalizeString(source.messageText)}`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.issuing_loyalty_card') {
				result += `Карта лояльности: ${catalog.renderCatalogName(source.loyaltyCardKind)}`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.rounding_amount') {
				result += `Округление суммы документа, точность ${source.roundingAccuracy}`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.rounding_per_row') {
				result += `Округление построчно, точность ${source.roundingAccuracy}`;
			} else if (source.methodOfProvidingId === 'loyalty_policy_methods_of_providing.assign_manager') {
				result += `Назначение менеджера: ${catalog.renderCatalogName(source.manager)}`;
			}
			if (source.methodOfApplyingId === 'loyalty_policy_methods_of_applying.apply_bonus_points') {
				result = `Бонус: ${result}`;
			}
			if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.scheme') {
				result += ' на номенклатуру по отбору';
			} else if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.list') {
				result += ' на номенклатуру из списка';
			} else if (source.productSelectionOptionId === 'loyalty_policy_product_selection_options.segment') {
				result += ` на номенклатуру сегмента ${catalog.renderCatalogName(source.productSegment)}`;
			}
			let rules = source.ruleItems || [];
			if (rules.length === 1) {
				let rule = _.head(rules);
				result += ` (${catalog.renderCatalogName(rule.rule)})`;
			} else if (rules.length > 1) {
				result += ' (несколько условий)';
			}
		} else {
			result += `${catalog.renderCatalogName(source.jointUseOption)}`;
		}

		return Promise.resolve([result]);
	}
}

export let loyaltyPolicyAutocompleteAdapter = new LoyaltyPolicyAutocompleteAdapter();

export default LoyaltyPolicyAutocompleteAdapter;
