import { observable, action, makeObservable, set } from 'mobx';

import { SubscriptionPlanType } from '../../shared/support/modelTypes';

export interface SubscriptionPlanFormStoreType {
	model: SubscriptionPlanType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	selectedPeriodItems: Array<any>;
	productItem: any;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class SubscriptionPlanFormStore implements SubscriptionPlanFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			selectedPeriodItems: observable,
			productItem: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		applyLimitValidityPeriodId: 'periodicity.month',
		applyLimitValidityPeriodCount: 0,
		renewable: true,
		level: 0,
		enabled: true,
		description: null,
		name: { ru: '' },
		displayName: null,
		priority: 0,
		deleted: false,
		defaultName: null,
		trial: false,
		applyLimitValidityPeriod: {
			id: 'periodicity.month',
			keyId: 'month',
			ownerId: 'periodicity',
			name: { ru: 'Месяц' },
			description: null,
			priority: 6,
			predefined: true,
			predefinedName: 'PeriodicityMonth',
			predefinedVersion: 0
		},
		periodItems: [],
		periodItemList: [],
		productItems: [],
		productItemList: []
	};
	searchText = '';
	productItem = {};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'subscriptionPlans';
	filterInclude = [
		'applyLimitValidityPeriod',
		{
			relation: 'productItems',
			scope: {
				include: ['unit', 'feature', 'product', 'validityPeriod', 'option', 'productSet']
			}
		},
		{
			relation: 'periodItems',
			scope: {
				include: ['validityPeriod']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	selectedPeriodItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmSubscriptionPlan';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const subscriptionPlanFormStore = new SubscriptionPlanFormStore();

export default SubscriptionPlanFormStore;
