import { observable, action, makeObservable, set } from 'mobx';

import { SubscriptionOptionType, SubscriptionPlanType } from '../../shared/support/modelTypes';

export interface SubscriptionOptionItem {
	subscriptionOption: SubscriptionOptionType;
	validityPeriodId: string;
	validityPeriodCount: number;
	quantity: number;
	expiredAt: Date | null;
}

export interface SubscriptionItem {
	quantity: number;
	subscriptionPlan: SubscriptionPlanType;
	validityPeriodId: string;
	validityPeriodCount: number;
	expiredAt: Date | null;
	subscriptionOptionItemList: Array<SubscriptionOptionItem>;
}

export interface PartnerSubscriptionInfo {
	subscriptionItemList: Array<SubscriptionItem>;
	periodAt: Date | null;
}

export interface ModelsType {
	clientHeight: number;
	windowSize: number;
	showAlert: boolean;
	alertText: string;
	constants: any;
	language: string;
	locales: any;
	shoppingCartMode: string;
	currentPartnerSettlement: {
		settlementAmount: number;
		prepaymentAmount: number;
	};
	apiData: {
		mode: string;
		started: string | null;
		uptime: number;
	};
	appError: any;
	requestError: any;
	partnerSubscriptionInfo: PartnerSubscriptionInfo;
}

export interface OrmStoreType {
	models: ModelsType;
	setValue(model: any, key: string, value: any): void;
	// deleteId(isLast: boolean, index?: number): void
}

class OrmStore implements OrmStoreType {
	constructor() {
		makeObservable(this, {
			models: observable,
			setValue: action
		});
	}

	models = {
		requestError: {
			status: 0
		},
		appError: null,
		apiData: {
			mode: 'tenant',
			started: '',
			uptime: 0
		},
		clientHeight: window.innerHeight,
		showAlert: false,
		alertText: '',
		windowSize: window.innerWidth,
		constants: {},
		language: 'ru',
		locales: [],
		shoppingCartMode: 'change',
		currentPartnerSettlement: {
			settlementAmount: 0,
			prepaymentAmount: 0
		},
		partnerSubscriptionInfo: {
			periodAt: null,
			subscriptionItemList: []
		}
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const directoryStore = new OrmStore();

export default OrmStore;
