import CIcon from '@coreui/icons-react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CLink, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { natiwiController } from '../core/Controllers/OrmNatiwiController';
import { OrmStoreType } from '../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../core/Stores/OrmUserStore';
import NatFilters from '../shared/Components/NatFilters';
import { dateFormatter } from '../shared/Formatters/DateFormatter';
import { listDateTimeFormatter } from '../shared/Formatters/ListDateTimeFormatter';
import { priceFormatter } from '../shared/Formatters/PriceFormatter';
import { stringFormatter } from '../shared/Formatters/StringFormatter';
import NatDefaultCheckbox from '../shared/Inputs/NatDefaultCheckBox';
import NatFormGroupLabel from '../shared/Inputs/NatFormGroupLabel';
import NatValueInput from '../shared/Inputs/NatValueInput';
import { catalog } from '../shared/support/Catalog';
import NatNatiwiOrdersStore, { NatNatiwiOrdersStoreType } from './NatNatiwiOrdersStore';

interface PropsType extends RouteComponentProps {
	toggleNatNatiwiOrders(status: boolean): void;
	isModalOpen: boolean;
}

interface StateType {
	querySelector: any;
	timer: any;
	columns: Array<any>;
	isFilterOpen: boolean;
}

interface InjectedProps extends PropsType {
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
}

interface ButtonItem {
	title: string;
	onClick: (e?: any) => void;
	disabled?: boolean;
	children?: Array<ButtonItem>;
	className?: string;
	dontShow?: boolean;
}

@inject('userStore', 'directoryStore')
@observer
class NatNatiwiOrders extends Component<PropsType, StateType> {
	myRef: any;
	reactions: Array<IReactionDisposer>;
	treeRef: any;
	natNatiwiOrdersStore: NatNatiwiOrdersStoreType;
	constructor(props: PropsType) {
		super(props);
		this.myRef = React.createRef();
		this.treeRef = React.createRef();
		this.handleScrollToElement = this.handleScrollToElement.bind(this);
		this.sortList = this.sortList.bind(this);
		this.showList = this.showList.bind(this);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.print = this.print.bind(this);
		this.perform = this.perform.bind(this);
		this.cancelPartnerOrder = this.cancelPartnerOrder.bind(this);
		this.reactions = [];
		this.natNatiwiOrdersStore = new NatNatiwiOrdersStore();
		this.state = {
			querySelector: null,
			timer: null,
			isFilterOpen: false,
			columns: [
				{
					id: 1,
					field: 'sequenceNumber',
					label: 'Номер',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{data.sequenceNumber}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 2,
					field: 'createdAt',
					label: 'Дата создания',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{listDateTimeFormatter.renderValue(data.createdAt)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 3,
					field: 'productItems',
					label: 'Товары',
					width: '450px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner d-flex flex-column">
								{_.map(_.get(data, 'productItems', []), (item, index) => {
									if (!_.isEmpty(item.product)) {
										return (
											<span key={index} className="d-flex align-items-center m-1">
												<div className="mr-auto">
													<span>{catalog.renderCatalogName(item.product)}</span>
												</div>
												<div className="ml-auto">
													<span title={item.amount}>
														{item.amount}
														<span className="ml-1">руб</span>
													</span>
												</div>
											</span>
										);
									}
								})}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 4,
					field: 'amount',
					label: 'Сумма',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary text-right';
									} else {
										return 'rgt-cell-inner rgt-text-truncate text-right';
									}
								})()}>
								{catalog.renderPrice(data)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 5,
					field: 'status',
					label: 'Статус',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div
								className={(() => {
									if (data.deleted) {
										return 'rgt-cell-inner rgt-text-truncate text-secondary';
									} else {
										return 'rgt-cell-inner rgt-text-truncate';
									}
								})()}>
								{catalog.renderCatalogName(data.status)}
							</div>
						);
					},
					sort: () => {}
				},
				{
					id: 6,
					field: 'action',
					label: 'Действие',
					width: '220px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner text-center d-flex flex-column action">
								<CLink className="m-2 d-flex justify-content-center" onClick={() => this.print(data)}>
									Скачать в PDF
								</CLink>
								{data.statusId === 'partner_subscription_operation_statuses.awaiting_payment' && (
									<CLink className="m-2 d-flex justify-content-center" onClick={() => this.perform(data)}>
										Оплатить
									</CLink>
								)}
								{(() => {
									if (data.statusId === 'partner_subscription_operation_statuses.awaiting_payment' || data.statusId === 'partner_subscription_operation_statuses.created') {
										return (
											<CLink className="m-2 d-flex justify-content-center" onClick={() => this.cancelPartnerOrder(data)}>
												Отменить
											</CLink>
										);
									}
								})()}
							</div>
						);
					},
					sort: () => {}
				}
			]
		};
		this.reactions.push(
			reaction(
				() => this.natNatiwiOrdersStore.chips,
				() => {
					if (!_.isEmpty(this.state.querySelector)) {
						this.state.querySelector.scrollTo(0, 0);
						clearTimeout(this.state.timer);
						this.setState({
							timer: setTimeout(this.showList, 500)
						});
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: this.myRef.current.querySelector('.rgt-container')
		});
		let filter = {
			where: this.natNatiwiOrdersStore.filterWhere,
			include: this.natNatiwiOrdersStore.filterInclude,
			skip: this.natNatiwiOrdersStore.filterSkip,
			limit: this.natNatiwiOrdersStore.itemsPerPage,
			order: this.natNatiwiOrdersStore.filterOrder
		};
		natiwiController
			.findAll(this.natNatiwiOrdersStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', data);
				}
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			});
		let printFilter = {
			where: { predefined: true, predefinedName: 'InvoicePrintTemplate' },
			limit: 1
		};
		natiwiController
			.findAll('layoutTemplates', printFilter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'layoutTemplates', data);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.reactions = [];
		this.myRef.current.querySelector('.rgt-container')?.addEventListener('scroll', this.handleScrollToElement);
		this.setState({
			querySelector: null
		});
	}
	handleScrollToElement() {
		let scrollTop = this.state.querySelector.scrollTop;
		let scrollHeight = this.state.querySelector.scrollHeight;
		let clientHeight = this.state.querySelector.clientHeight;
		let arr = this.natNatiwiOrdersStore.list;
		let itemsPerPage = this.natNatiwiOrdersStore.itemsPerPage;
		let itemsListLength = arr.length;
		if (scrollTop >= scrollHeight - clientHeight && this.natNatiwiOrdersStore.currentPage * itemsPerPage <= itemsListLength && scrollTop !== 0) {
			this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'currentPage', this.natNatiwiOrdersStore.currentPage + 1);
			this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterSkip', this.natNatiwiOrdersStore.currentPage * this.natNatiwiOrdersStore.itemsPerPage);
			let filter = this.createFilter();
			this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
			natiwiController
				.findAll(this.natNatiwiOrdersStore.pluralName, filter)
				.then((data) => {
					if (!_.isEmpty(data)) {
						this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', this.natNatiwiOrdersStore.list.concat(data));
					}
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
				});
		}
	}
	createFilter() {
		let and: Array<any> = [];
		let createdAtGt: { createdAt: { gt: string } } = {
			createdAt: {
				gt: ''
			}
		};
		let createdAtLt: { createdAt: { lt: string } } = {
			createdAt: {
				lt: ''
			}
		};
		let amountGte: { amount: { gte: number } } = {
			amount: {
				gte: 0
			}
		};
		let amountLte: { amount: { lte: number } } = {
			amount: {
				lte: 0
			}
		};
		let filter: any = {
			where: this.natNatiwiOrdersStore.filterWhere,
			include: this.natNatiwiOrdersStore.filterInclude,
			skip: this.natNatiwiOrdersStore.filterSkip,
			limit: this.natNatiwiOrdersStore.itemsPerPage,
			order: this.natNatiwiOrdersStore.filterOrder
		};
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterWhere', {
			deleted: false,
			statusId: {
				nin: ['partner_subscription_operation_statuses.canceled']
			}
		});
		_.forEach(this.natNatiwiOrdersStore.chipsFields, (item: any, key) => {
			if (item.isRelation) {
				if (!_.isEmpty(this.natNatiwiOrdersStore.chipsFields[key].chips)) {
					let ids = _.chain(this.natNatiwiOrdersStore.chipsFields[key].chips)
						.cloneDeep()
						.transform((result: any, value: any) => {
							result.push(value.id);
						}, [])
						.value();
					filter.where[key + 'Id'] = { inq: ids };
				}
			} else {
				if (item.isDate) {
					if (item.id !== undefined && item.id !== null) {
						if (item.isGt) {
							createdAtGt = {
								createdAt: {
									gt: item.id.toISOString()
								}
							};
							and.push(createdAtGt);
							filter.where.and = and;
						} else {
							createdAtLt = {
								createdAt: {
									lt: item.id.toISOString().substring(0, 10) + 'T23:59:00.000Z'
								}
							};
							and.push(createdAtLt);
							filter.where.and = and;
						}
					}
				} else if (item.isPrice) {
					if (item.id !== undefined && item.id !== null) {
						if (item.isGte) {
							amountGte = {
								amount: {
									gte: item.id
								}
							};
							and.push(amountGte);
							filter.where.and = and;
						} else {
							amountLte = {
								amount: {
									lte: item.id
								}
							};
							and.push(amountLte);
							filter.where.and = and;
						}
					}
				} else if (item.isLocalizedString) {
					if (!_.isEmpty(item.id)) {
						filter.where[`${key}.${this.injected.directoryStore.models.language}`] = {
							like: item.id,
							options: 'i'
						};
					} else {
						delete filter.where[`${key}.${this.injected.directoryStore.models.language}`];
					}
				} else {
					if (!_.isEmpty(item.id)) {
						filter.where[key] = {
							like: item.id,
							options: 'i'
						};
					} else {
						delete filter.where[key];
					}
				}
			}
		});
		return filter;
	}
	showList() {
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterSkip', 0);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'currentPage', 0);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
		let filter = this.createFilter();
		natiwiController
			.findAll(this.natNatiwiOrdersStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', data);
				} else {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', []);
				}
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			});
	}
	sortList(colId: number, isAsc: boolean) {
		let order: Array<any> = [];
		if (colId !== null) {
			const groupedColumns = _.groupBy(this.state.columns, 'id');
			const sortBy = isAsc ? 'ASC' : 'DESC';
			order = [`${groupedColumns[colId][0].field} ${sortBy}`];
		} else {
			order = ['createdAt DESC'];
		}
		this.state.querySelector.scrollTo(0, 0);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterOrder', order);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterSkip', 0);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'currentPage', 0);
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
		let filter: any = this.createFilter();
		natiwiController
			.findAll(this.natNatiwiOrdersStore.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', data);
				}
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			});
	}
	resetSearchFields() {
		runInAction(() => {
			_.forEach(this.natNatiwiOrdersStore.chipsFields.status.list, (item) => {
				item.isChecked = false;
			});
			this.natNatiwiOrdersStore.chipsFields = {
				sequenceNumber: {
					id: null,
					chips: [],
					title: 'Номер'
				},
				createdAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true
				},
				createdAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false
				},
				amountGte: {
					isPrice: true,
					id: null,
					chips: [],
					isGte: true
				},
				amountLte: {
					isPrice: true,
					id: null,
					chips: [],
					isGte: false
				},
				status: {
					isRelation: true,
					id: null,
					chips: [],
					list: this.natNatiwiOrdersStore.chipsFields.status.list
				}
			};
		});
	}
	toggleFilters(status: boolean) {
		this.setState({
			isFilterOpen: status
		});
	}
	perform(model: any) {
		let data = {
			paymentOptionId: model.paymentOptionId,
			resolveCallbackUrl: window.location.origin + `/shoppingCart/checkout/${model.paymentOptionId}/validate`,
			rejectCallbackUrl: window.location.origin + '/shoppingCart/checkout/failure',
			documentItemList: [{ documentType: 'OrmPartnerSubscriptionOperation', documentId: model.id }]
		};
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
		natiwiController
			.perform(data)
			.then((data) => {
				if (!_.isEmpty(data)) {
					if (!_.isEmpty(data.formUrl)) {
						window.open(data.formUrl, '_self');
					} else {
						this.props.history.push('/shoppingCart/checkout/failure');
					}
				} else {
					this.props.history.push('/shoppingCart/checkout/failure');
				}
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			});
	}
	print(data: any) {
		if (!_.isEmpty(this.natNatiwiOrdersStore.layoutTemplates[0])) {
			let printData = {
				objectType: 'OrmPartnerSubscriptionOperation',
				objectId: data.id
			};
			this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
			natiwiController
				.displayLayoutTemplate(printData, this.natNatiwiOrdersStore.layoutTemplates[0], { format: 'pdf' })
				.then((data) => {
					if (data !== undefined) {
						let url = URL.createObjectURL(new Blob([data], { type: data.type }));
						window.open(url);
					}
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
				})
				.catch((error) => {
					catalog.handleNatError(error);
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
				});
		}
	}
	cancelPartnerOrder(data: any) {
		this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
		natiwiController
			.cancelOperation(data)
			.then(() => {
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterSkip', 0);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'currentPage', 0);
				let filter = this.createFilter();
				return natiwiController.findAll(this.natNatiwiOrdersStore.pluralName, filter);
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', data);
				} else {
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', []);
				}
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
			});
	}
	render() {
		let controlButtons: Array<ButtonItem> = [
			{
				title: 'ОБНОВИТЬ',
				onClick: () => {
					this.state.querySelector.scrollTo(0, 0);
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'filterSkip', 0);
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'currentPage', 0);
					this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', true);
					let filter = this.createFilter();
					natiwiController
						.findAll(this.natNatiwiOrdersStore.pluralName, filter)
						.then((data) => {
							if (!_.isEmpty(data)) {
								this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', data);
							} else {
								this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'list', []);
							}
							this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
						})
						.catch((error) => {
							catalog.handleNatError(error);
							this.natNatiwiOrdersStore.setValue(this.natNatiwiOrdersStore, 'isLoading', false);
						});
				}
			},
			{
				title: 'ОТМЕНА',
				onClick: () => {
					this.props.toggleNatNatiwiOrders(false);
				}
			}
		];
		return ReactDOM.createPortal(
			<>
				<CModal
					show={this.props.isModalOpen}
					className={this.injected.directoryStore.models.windowSize >= 1200 ? 'nat__modal nat__scrollbar nat__modal__fullscreen' : 'nat__modal nat__scrollbar nat__modal__fullscreen nat__modal__mobile'}
					size="xl"
					closeOnBackdrop={false}>
					<CModalHeader className="d-flex mt-4 p-2 mx-4 nat__card__wrapper nat__modal__header nat__borders__2-75 align-items-center justify-content-between">
						<div>
							{(() => {
								if (this.injected.directoryStore.models.windowSize > 1084) {
									return (
										<form className="d-flex w-100">
											{_.map(controlButtons, (item, index) => {
												if (!item.dontShow) {
													return (
														<CButton
															key={index}
															type="button"
															size="sm"
															className="mr-1 nat__button__hover"
															onClick={() => {
																item.onClick();
															}}>
															{item.title}
														</CButton>
													);
												}
											})}
										</form>
									);
								} else {
									return (
										<CDropdown>
											<CDropdownToggle caret className="nat__button__hover">
												МЕНЮ
											</CDropdownToggle>
											<CDropdownMenu placement="bottom-start" className="rounded-0 nat__nav__dropdown__list nat__dropdown__transparent__scrollbar nat__dropdown__shadow border-0 nat__borders__2-75 p-0">
												{_.map(controlButtons, (item, index) => {
													if (!item.dontShow) {
														return (
															<CDropdownItem
																key={index}
																onClick={() => {
																	item.onClick();
																}}>
																{item.title}
															</CDropdownItem>
														);
													}
												})}
											</CDropdownMenu>
										</CDropdown>
									);
								}
							})()}
						</div>
						<CButton
							type="button"
							size="sm"
							className="nat__button__hover ml-auto"
							onClick={() => {
								this.toggleFilters(true);
							}}>
							{this.injected.directoryStore.models.windowSize < 584 ? <CIcon name="cil-filter" size="lg" /> : `ФИЛЬТРЫ (${this.natNatiwiOrdersStore.chips.length})`}
						</CButton>
					</CModalHeader>
					<CModalBody className="p-0 m-4 nat__table__hover">
						<div className="d-flex default__nat__card__body__height">
							<div className="nat__modal__table__wrapper nat__card__wrapper nat__borders__2-75 w-100">
								<div className="nat__modal__table_1 text-body" ref={this.myRef}>
									<GridTable
										columns={this.state.columns}
										rows={this.natNatiwiOrdersStore.list}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										onSortChange={({ colId, isAsc }: { colId: any; isAsc: any }) => {
											this.sortList(colId, isAsc);
										}}
									/>
								</div>
							</div>
						</div>
					</CModalBody>
					{this.natNatiwiOrdersStore.isLoading && (
						<div className="nat__spinner__absolute">
							<CSpinner style={{ width: '4rem', height: '4rem' }} className="m-3 spinner" />
						</div>
					)}
				</CModal>
				{this.state.isFilterOpen && (
					<div className="nat__selection__filters">
						<NatFilters
							store={this.natNatiwiOrdersStore}
							toggleModal={this.toggleFilters}
							isModalOpen={this.state.isFilterOpen}
							resetSearchFields={this.resetSearchFields}
							ownerId="partner_subscription_operation_statuses"
							enumerationItems="status">
							<>
								<NatValueInput<string> object={this.natNatiwiOrdersStore.chipsFields.sequenceNumber} property="id" placeholder="Введите номер" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="Номер" />
								<NatValueInput<Date> object={this.natNatiwiOrdersStore.chipsFields.createdAtGt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Создан от" />
								<NatValueInput<Date> object={this.natNatiwiOrdersStore.chipsFields.createdAtLt} property="id" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="до" />
								<NatValueInput<number> object={this.natNatiwiOrdersStore.chipsFields.amountGte} property="id" placeholder="Введите сумму" type="text" pattern="^([0-9]*[.,])?[0-9]*$" formatter={priceFormatter} size="sm" label="Сумма от" />
								<NatValueInput<number> object={this.natNatiwiOrdersStore.chipsFields.amountLte} property="id" placeholder="Введите сумму" type="text" pattern="^([0-9]*[.,])?[0-9]*$" formatter={priceFormatter} size="sm" label="до" />
								<NatFormGroupLabel label="Статус" labelColumnSettings="7" labelColumnClassName="text-left align-self-center text-black pl-0" inputColumnClassName="pl__4_5" row={false}>
									{_.map(this.natNatiwiOrdersStore.chipsFields.status.list, (item, index) => {
										return <NatDefaultCheckbox key={index} object={item} property="isChecked" columnClassName="" wrapperClassName="checkbox" row={true} label={catalog.renderCatalogName(item)} />;
									})}
								</NatFormGroupLabel>
							</>
						</NatFilters>
					</div>
				)}
			</>,
			document.querySelector('#modal') as Element
		);
	}
}

export default withRouter(NatNatiwiOrders);
