import { catalog } from '../../shared/support/Catalog';
import { natiwiApi } from '../Api/NatiwiApi';

class OrmNatiwiController {
	login(loginData: { login: string; password: string }) {
		return natiwiApi
			.login(loginData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	logout(logoutData: { tenantAccessTokenId: string | null; tenantClientId: string | null }) {
		return natiwiApi
			.logout(logoutData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	resetPasswordTenantClient(resetPasswordData: { login: string; callbackUrl: string }) {
		return natiwiApi
			.resetPasswordTenantClient(resetPasswordData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	setPasswordTenantClient(setPasswordData: { userId: string; password: string; accessTokenId: string; tenantClientId: string }) {
		return natiwiApi
			.setPasswordTenantClient(setPasswordData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	registerTenantClient(registrationData: { email: string; password: string }) {
		return natiwiApi
			.registerTenantClient(registrationData)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	deployTenantClient() {
		return natiwiApi
			.deployTenantClient()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	checkTenantClientDeployAvailability() {
		return natiwiApi
			.checkTenantClientDeployAvailability()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	findAll(pluralName: string, filter: any) {
		return natiwiApi
			.findAll(pluralName, filter)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	find(pluralName: string, filter: any, id: string) {
		return natiwiApi
			.find(pluralName, filter, id)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	perform(data: any) {
		return natiwiApi
			.perform(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchPartnerSubscriptionInfo() {
		return natiwiApi
			.fetchPartnerSubscriptionInfo()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchAvailableSubscriptionPlans(data?: any) {
		return natiwiApi
			.fetchAvailableSubscriptionPlans(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchPaymentOptionList() {
		return natiwiApi
			.fetchPaymentOptionList()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	resetShoppingCart() {
		return natiwiApi
			.resetShoppingCart()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchShoppingCart() {
		return natiwiApi
			.fetchShoppingCart()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	updateShoppingCart(shoppingCart: any) {
		return natiwiApi
			.updateShoppingCart(shoppingCart)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	checkoutShoppingCart(data: any) {
		return natiwiApi
			.checkoutShoppingCart(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	validate(data: any, paymentOptionId: string) {
		return natiwiApi
			.validate(data, paymentOptionId)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	cancelCustomerOrder(data: any) {
		return natiwiApi
			.cancelCustomerOrder(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	cancelOperation(data: any) {
		return natiwiApi
			.cancelOperation(data)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	fetchCurrentPartnerSettlement() {
		return natiwiApi
			.fetchCurrentPartnerSettlement()
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	displayLayoutTemplate(data: { objectType: string; objectId: string }, layoutTemplate: any, params: any = {}) {
		return natiwiApi
			.displayLayoutTemplate(data, layoutTemplate, params)
			.then((response) => {
				return this.returnApiResult(response);
			})
			.catch((error) => {
				return Promise.reject(catalog.handleRequestError(error));
			});
	}
	returnApiResult(response: any) {
		if (response.status === 200) {
			return response.data;
		}
	}
}

export const natiwiController = new OrmNatiwiController();

export default OrmNatiwiController;
