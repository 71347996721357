import { observable, action, makeObservable, set } from 'mobx';

import { SettingPricesOfProductType } from '../../shared/support/modelTypes';

export interface SettingPricesOfProductFormStoreType {
	model: SettingPricesOfProductType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	selectedPriceTypeItems: Array<any>;
	productItemsColumns: Array<any>;
	productItemsTable: Array<any>;
	featureIds: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isRoot: boolean;
	parentProductItem: any;
	tableQuerySelector: any;
	scrollbarPosition: number;
	isFirstLoad: boolean;
	name: string;
	isSequenceNumberChangedManually: boolean;
	searchPriceTypeText: string;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class SettingPricesOfProductFormStore implements SettingPricesOfProductFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			selectedPriceTypeItems: observable,
			productItemsColumns: observable,
			productItemsTable: observable,
			featureIds: observable,
			requiredToFill: observable,
			isRoot: observable,
			parentProductItem: observable,
			tableQuerySelector: observable,
			scrollbarPosition: observable,
			isFirstLoad: observable,
			name: observable,
			searchPriceTypeText: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		deleted: false,
		note: null,
		organizationId: null,
		ownerId: null,
		ownerType: null,
		posted: false,
		responsibleId: null,
		periodAt: null,
		authorId: null,
		productItemList: [],
		productItems: [],
		priceTypeItems: [],
		priceTypeItemList: []
	};
	searchText = '';
	searchPriceTypeText = '';
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'settingPricesOfProducts';
	filterInclude = [
		'organization',
		'owner',
		{
			relation: 'productItems',
			scope: {
				include: ['unit', 'feature', 'priceType', 'product', 'validityPeriod']
			}
		},
		{
			relation: 'priceTypeItems',
			scope: {
				include: ['priceType']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	selectedPriceTypeItems = [] as Array<any>;
	productItemsColumns = [] as Array<any>;
	productItemsTable = [] as Array<any>;
	featureIds = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		}
	];
	isRoot = true;
	parentProductItem = null;
	tableQuerySelector = null;
	scrollbarPosition = 0;
	isFirstLoad = true;
	name = 'OrmSettingPricesOfProducts';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const settingPricesOfProductFormStore = new SettingPricesOfProductFormStore();

export default SettingPricesOfProductFormStore;
