import { observable, action, makeObservable, set } from 'mobx';

import { LoyaltyCardKindType } from '../../shared/support/modelTypes';

export interface LoyaltyCardKindFormStoreType {
	model: LoyaltyCardKindType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	isFirstLoad: boolean;
	name: string;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class LoyaltyCardKindFormStore implements LoyaltyCardKindFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			searchText: observable,
			filterInclude: observable,
			setValue: action
		});
	}

	model = {
		loyaltyProgramId: null,
		organizationId: null,
		statusId: 'loyalty_card_kind_statuses.valid',
		startedAt: null,
		expiredAt: null,
		personalized: true,
		note: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		status: {
			id: 'loyalty_card_kind_statuses.valid',
			keyId: 'valid',
			ownerId: 'loyalty_card_kind_statuses',
			name: {
				ru: 'Действует',
				id: '3c4e2aa8-edb9-4656-820a-195c24eaebfe'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'LoyaltyCardKindStatusesValid',
			predefinedVersion: 0
		},
		numberTemplateList: [],
		numberTemplates: []
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'loyaltyCardKinds';
	selectedItems = [] as Array<any>;
	searchText = '';
	isFirstLoad = true;
	filterInclude = ['loyaltyProgram', 'organization', 'status', 'numberTemplates'];
	name = 'OrmLoyaltyCardKind';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const loyaltyCardKindFormStore = new LoyaltyCardKindFormStore();

export default LoyaltyCardKindFormStore;
