import { observable, action, makeObservable, set } from 'mobx';

import { CouponKindType } from '../../shared/support/modelTypes';

export interface CouponKindFormStoreType {
	model: CouponKindType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	selectedItems: Array<any>;
	filterInclude?: Array<any>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class CouponKindFormStore implements CouponKindFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			selectedItems: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}

	model = {
		organizationId: null,
		statusId: 'coupon_kind_statuses.valid',
		startedAt: null,
		expiredAt: null,
		personalized: true,
		note: null,
		name: { ru: '' },
		displayName: null,
		priority: null,
		deleted: false,
		status: {
			id: 'coupon_kind_statuses.valid',
			keyId: 'valid',
			ownerId: 'coupon_kind_statuses',
			name: {
				ru: 'Действует',
				id: '0885a051-3bdf-485c-898a-45148155247f'
			},
			description: null,
			priority: 0,
			predefined: true,
			predefinedName: 'CouponKindStatusesValid',
			predefinedVersion: 0
		}
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'couponKinds';
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmCouponKind';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const couponKindFormStore = new CouponKindFormStore();

export default CouponKindFormStore;
