import { observable, action, makeObservable, set } from 'mobx';

interface ListType {
	list: Array<any>;
	filterWhere: { deleted?: boolean };
	filterInclude: Array<any>;
	filterSkip?: number;
	itemsPerPage?: number;
	filterOrder: Array<string>;
	pluralName: string;
}
export interface NatFillModalStoreType {
	list: Array<any>;
	filterWhere: { ownerId?: string; deleted?: boolean };
	filterInclude: Array<any>;
	filterSkip: number;
	currentPage: number;
	itemsPerPage: number;
	filterOrder: Array<string>;
	collectedModels: Array<any>;
	responseCode: number;
	deleted: boolean;
	isLoading: boolean;
	pluralName: string | null;
	receiptOfProducts: ListType;
	settingPricesOfProducts: ListType;
	setValue(model: any, key: string, value: any): void;
}

class NatFillModalStore implements NatFillModalStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			filterWhere: observable,
			filterInclude: observable,
			filterSkip: observable,
			currentPage: observable,
			itemsPerPage: observable,
			filterOrder: observable,
			collectedModels: observable,
			responseCode: observable,
			deleted: observable,
			isLoading: observable,
			pluralName: observable,
			receiptOfProducts: observable,
			settingPricesOfProducts: observable,
			setValue: action
		});
	}
	list = [];
	filterWhere = { deleted: false };
	filterInclude = [];
	filterSkip = 0;
	currentPage = 0;
	itemsPerPage = 50;
	filterOrder = ['createdAt DESC'];
	collectedModels = [] as Array<any>;
	responseCode = 0;
	deleted = false;
	isLoading = false;
	pluralName = 'receiptOfProducts';
	receiptOfProducts = {
		list: [],
		filterWhere: { deleted: false },
		filterInclude: [
			{
				relation: 'productItems',
				scope: {
					include: ['unit', 'feature', 'serialNumber', 'product']
				}
			}
		],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'receiptOfProducts'
	};
	settingPricesOfProducts = {
		list: [],
		filterWhere: { deleted: false },
		filterInclude: [
			{
				relation: 'productItems',
				scope: {
					include: ['unit', 'feature', 'priceType', 'product', 'validityPeriod']
				}
			}
		],
		filterSkip: 0,
		itemsPerPage: 50,
		filterOrder: ['createdAt DESC'],
		pluralName: 'settingPricesOfProducts'
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natFillModalStore = new NatFillModalStore();

export default NatFillModalStore;
