import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { NatSelectionStoreType } from '../../shared/Modals/NatSelectionStore';
import { catalog } from '../../shared/support/Catalog';
import { LoyaltyPolicyType, FormContainerPropsType } from '../../shared/support/modelTypes';
import LoyaltyPolicyForm from './LoyaltyPolicyForm';
import { LoyaltyPolicyFormStoreType } from './LoyaltyPolicyFormStore';

interface PropsType extends FormContainerPropsType<LoyaltyPolicyFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	natSelectionStore: NatSelectionStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'natSelectionStore')
@observer
class LoyaltyPolicyFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.saveRuleItems = this.saveRuleItems.bind(this);
		this.buildRuleItems = this.buildRuleItems.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		_.forEach(_.get(this.props.store.model, 'ruleItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			});
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.ruleItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.productSelectionOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'productSelectionOptionId', 'loyalty_policy_product_selection_options.none');
						this.props.store.setValue(this.props.store.model, 'productSelectionOption', {
							id: 'loyalty_policy_product_selection_options.none',
							keyId: 'none',
							ownerId: 'loyalty_policy_product_selection_options',
							name: {
								ru: 'Без ограничений',
								id: '94a4b215-056f-40d8-91e4-5c4ccb968c83'
							},
							description: null,
							priority: 0,
							predefined: true,
							predefinedName: 'LoyaltyPolicyProductSelectionOptionsNone',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.validityPeriodId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'validityPeriodId', 'periodicity.week');
						this.props.store.setValue(this.props.store.model, 'validityPeriod', {
							id: 'periodicity.week',
							keyId: 'week',
							ownerId: 'periodicity',
							name: {
								ru: 'Неделя',
								id: '0aa76a8b-fde6-49da-819f-a986470864c1'
							},
							description: null,
							priority: 4,
							predefined: true,
							predefinedName: 'PeriodicityWeek',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.validityDelayPeriodId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'validityDelayPeriodId', 'periodicity.week');
						this.props.store.setValue(this.props.store.model, 'validityDelayPeriod', {
							id: 'periodicity.week',
							keyId: 'week',
							ownerId: 'periodicity',
							name: {
								ru: 'Неделя',
								id: '0aa76a8b-fde6-49da-819f-a986470864c1'
							},
							description: null,
							priority: 4,
							predefined: true,
							predefinedName: 'PeriodicityWeek',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.roundingOptionId,
				(value) => {
					if (_.isEmpty(value)) {
						this.props.store.setValue(this.props.store.model, 'roundingOptionId', 'rounding_options.by_arithmetic_rules');
						this.props.store.setValue(this.props.store.model, 'roundingOption', {
							id: 'rounding_options.by_arithmetic_rules',
							keyId: 'by_arithmetic_rules',
							ownerId: 'rounding_options',
							name: {
								ru: 'По арифметическим правилам',
								id: '1b2dff6f-2b70-44a0-b401-845322d11f8b'
							},
							description: null,
							priority: 0,
							predefined: true,
							predefinedName: 'RoundingOptionsByArithmeticRules',
							predefinedVersion: 0
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.methodOfApplyingId,
				(value, previousValue) => {
					if (value !== previousValue) {
						runInAction(() => {
							this.props.store.model.methodOfProvidingId = null;
							delete this.props.store.model.methodOfProviding;
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => any, elementType?: number, parentId?: string) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			if (!_.isEmpty(parentId)) {
				let filter = {
					where: { id: parentId },
					limit: 1
				};
				controller
					.findAll(this.props.store.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.store.setValue(this.props.store.model, 'parent', data[0]);
							this.props.store.setValue(this.props.store.model, 'parentId', data[0].id);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
			this.props.store.setValue(this.props.store.model, 'elementType', Number(elementType));
			if (Number(elementType) === 0) {
				catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			}
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: LoyaltyPolicyType) {
		if (model.roundingOptionId === 'rounding_options.by_arithmetic_rules' && _.isEmpty(model.roundingOption)) {
			model.roundingOption = _.cloneDeep(this.props.store.model.roundingOption);
		}
		if (model.validityDelayPeriodId === 'periodicity.week' && _.isEmpty(model.validityDelayPeriod)) {
			model.validityDelayPeriod = _.cloneDeep(this.props.store.model.validityDelayPeriod);
		}
		if (model.validityPeriodId === 'periodicity.week' && _.isEmpty(model.validityPeriod)) {
			model.validityPeriod = _.cloneDeep(this.props.store.model.validityPeriod);
		}
		if (model.productSelectionOptionId === 'loyalty_policy_product_selection_options.none' && _.isEmpty(model.productSelectionOption)) {
			model.productSelectionOption = _.cloneDeep(this.props.store.model.productSelectionOption);
		}
		return model;
	}
	prepareModelForPost(model: LoyaltyPolicyType) {
		_.forEach(model.ruleItems, (item) => {
			delete item.rule;
		});
		runInAction(() => {
			model.ruleItemList = model.ruleItems;
		});
		delete model.priceType;
		delete model.loyaltyProgram;
		delete model.loyaltyCardKind;
		delete model.manager;
		delete model.jointUseOption;
		delete model.calcJointUseOption;
		delete model.productSelectionOption;
		delete model.productSegment;
		delete model.methodOfProviding;
		delete model.methodOfApplying;
		delete model.validityPeriod;
		delete model.validityDelayPeriod;
		delete model.methodOfProviding;
		return model;
	}
	validate() {
		let result = !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.methodOfApplyingId) && !_.isEmpty(this.props.store.model.methodOfProvidingId);
		if (this.props.store.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.price_type') {
			return result && !_.isEmpty(this.props.store.model.priceTypeId);
		} else if (this.props.store.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.issuing_loyalty_card') {
			return result && !_.isEmpty(this.props.store.model.loyaltyCardKindId);
		} else if (this.props.store.model.methodOfProvidingId === 'loyalty_policy_methods_of_providing.assign_manager') {
			return result && !_.isEmpty(this.props.store.model.managerId);
		} else {
			return result;
		}
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	buildRuleItems(arrayIds: Array<string>) {
		const ids = arrayIds;
		const selectedItemsDict = _.chain(this.injected.natSelectionStore.list).cloneDeep().keyBy('id').value();
		const currentRuleItemMap = _.chain(this.injected.natSelectionStore.selectedItems).cloneDeep().keyBy('ruleId').value();
		_.map(ids, (item: string) => {
			const rule = selectedItemsDict[item];
			const ruleItem = {
				ruleId: item,
				rule,
				id: generateRandomId()
			};
			runInAction(() => {
				if (currentRuleItemMap[ruleItem.ruleId] === undefined) {
					this.injected.natSelectionStore.selectedItems.push(ruleItem);
				}
			});
		});
		const buffer = [] as Array<any>;
		_.map(this.injected.natSelectionStore.selectedItems, (item: any) => {
			if (_.find(ids, (itm: string) => itm === item.ruleId) !== undefined) {
				buffer.push(item);
			}
		});
		runInAction(() => {
			this.injected.natSelectionStore.selectedItems = buffer;
		});
	}
	saveRuleItems() {
		const arr = [] as Array<any>;
		const currentRuleItemMap = _.chain(this.props.store.model.ruleItems).cloneDeep().keyBy('ruleId').value();
		_.forEach(this.injected.natSelectionStore.selectedItems, (item: any) => {
			if (currentRuleItemMap[item.priceTypeId] === undefined) {
				arr.push(item);
			}
		});
		this.props.store.setValue(this.props.store.model, 'ruleItems', _.get(this.props.store, 'model.ruleItems', []).concat(arr));
		this.makeReactions();
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/loyaltyPolicies/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<LoyaltyPolicyForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						loyaltyPolicyFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						saveRuleItems={this.saveRuleItems}
						buildRuleItems={this.buildRuleItems}
					/>
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default LoyaltyPolicyFormContainer;
