import { CCol, CFormGroup } from '@coreui/react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatDefaultFormTemplate from '../../shared/Components/NatDefaultFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { MatchType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { ChildrenPropsType } from './NatSettingsContainer';

interface PropsType extends RouteComponentProps {
	match: MatchType;
	removeNavigationRoute(navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean; parentRoute: string }): void;
	navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean; parentRoute: string; title: string };
	goTo(path: string, state?: Record<string, unknown>): void;
	store: any;
	childrenProps: ChildrenPropsType;
	fill(): void;
}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class Settings extends Component<PropsType> {
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatDefaultFormTemplate
				store={this.props.store}
				isAclModalOpen={false}
				toggleAclModal={() => {}}
				fields={
					<>
						{!_.isEmpty(this.props.store.formRows) && (
							<CCol lg="12">
								{_.map(this.props.store.formRows, (item: any, index: number) => {
									return (
										<CFormGroup row className="mb-0" key={index}>
											<CCol md="6">{!_.isEmpty(item.first) && this.props.childrenProps.selectInputComponent(item.first)}</CCol>
											<CCol md="6" className="pr-4">
												{!_.isEmpty(item.second) && this.props.childrenProps.selectInputComponent(item.second)}
											</CCol>
										</CFormGroup>
									);
								})}
							</CCol>
						)}
					</>
				}
				controlButtons={[
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.store.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(Settings));
