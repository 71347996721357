import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
import { ChildrenPropsType } from '../../shared/Components/NatDocumentFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { dateTimeFormatter } from '../../shared/Formatters/DateTimeFormatter';
import { quantityFormatter } from '../../shared/Formatters/QuantityFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatTextarea from '../../shared/Inputs/NatTextarea';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { MovementOfProductsFormStoreType } from './MovementOfProductsFormStore';

interface PropsType extends FormPropsType {
	movementOfProductsFormStore: MovementOfProductsFormStoreType;
	childrenProps: ChildrenPropsType;
	fill(): void;
	resetData(): void;
	makeReactions(): void;
}

interface StateType {
	columns: Array<any>;
	isModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class MovementOfProductsForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.toggleSelection = this.toggleSelection.bind(this);
		this.state = {
			isModalOpen: false,
			columns: [
				{
					id: 'checkbox',
					pinned: true,
					className: '',
					width: '54px',
					minResizeWidth: 0,
					maxResizeWidth: null,
					resizable: false,
					visible: true,
					headerCellRenderer: () => {
						return <div></div>;
					},
					cellRenderer: ({ data }: { onChange: any; value: any; data: any }) => {
						return (
							<div className="rgt-cell-inner">
								<input type="checkbox" id="checkbox" onChange={() => this.props.childrenProps.saveSelected(data)} />
							</div>
						);
					}
				},
				{
					id: 1,
					field: 'product',
					label: 'Товар',
					width: '325px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="productId"
									relation="product"
									placeholder="Выберите товар"
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="products"
									filterWhere={{
										deleted: false,
										elementType: 0,
										typeId: {
											nin: ['product_types.set']
										}
									}}
									include={['unit']}
									autoComplete="off"
									hierarchicalSelect
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 2,
					field: 'unit',
					label: 'Ед.изм.',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatRelationInput
									object={data}
									property="unitId"
									relation="unit"
									placeholder="Выберите ед.изм."
									type="text"
									size="sm"
									inputClassName="text-left"
									formatter={relationFormatter()}
									pluralName="productUnits"
									apiMethod={(): any => {
										if (data.product !== undefined) {
											return controller.fetchUnitsByProduct({ product: data.product });
										} else {
											return new Promise((resolve) => {
												resolve([]);
											});
										}
									}}
									autoComplete="off"
									goTo={this.props.goTo}
								/>
							</div>
						);
					}
				},
				{
					id: 3,
					field: 'feature',
					label: 'Характеристика',
					width: '325px',
					checkCondition: (item: any) => {
						return catalog.checkFeaturesCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (_.isEmpty(data.product.useFeatureOptionId) || data.product.useFeatureOptionId === 'use_feature_options.not_used') {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="featureId"
													relation="feature"
													placeholder="Выберите характеристику"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter()}
													pluralName="productFeatures"
													apiMethod={(): any => {
														return controller.fetchFeaturesByProduct({ product: data.product });
													}}
													create={(pluralName, handlers) => {
														this.props.goTo(`/${pluralName}/new`, { ownerId: data.product.id, ownerType: 'OrmProduct' }, handlers);
													}}
													autoComplete="off"
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 4,
					field: 'serialNumber',
					label: 'Серийный номер',
					width: '250px',
					checkCondition: (item: any) => {
						return catalog.checkSerialNumbersCondition(item);
					},
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								{(() => {
									if (data.product !== undefined) {
										if (!data.product.useSerialNumbers) {
											return null;
										} else {
											return (
												<NatRelationInput
													object={data}
													property="serialNumberId"
													relation="serialNumber"
													placeholder="Выберите серийный номер"
													type="text"
													size="sm"
													inputClassName="text-left"
													formatter={relationFormatter(false, true)}
													pluralName="serialNumbers"
													filterWhere={{ deleted: false, or: [{ ownerId: data.productId }] }}
													autoComplete="off"
												/>
											);
										}
									} else {
										return null;
									}
								})()}
							</div>
						);
					}
				},
				{
					id: 5,
					field: 'quantity',
					label: 'Количество',
					width: '140px',
					cellRenderer: ({ data }: { data: any }) => {
						return (
							<div className="rgt-cell-inner ml-3 p-0">
								<NatValueInput<number>
									object={data}
									property="quantity"
									placeholder="Введите кол-во"
									type="text"
									pattern="^([0-9]*[.,])?[0-9]*$"
									inputClassName="text-right"
									formatter={quantityFormatter}
									autoComplete="off"
									verticalSwitching
									size="sm"
								/>
							</div>
						);
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	toggleSelection(status: boolean) {
		this.setState({
			isModalOpen: status
		});
	}
	render() {
		let productItemTemplate = {
			featureId: null,
			productId: null,
			quantity: 1,
			id: '',
			serialNumberId: null,
			unitId: null,
			feature: {
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			},
			product: {
				accountingOptionId: 'product_accounting_options.standart',
				area: 0,
				areaUnitId: null,
				article: null,
				caption: null,
				deleted: false,
				description: null,
				displayName: null,
				elementType: 0,
				giftCardValidityExpiredAt: null,
				giftCardValidityPeriodCount: 0,
				giftCardValidityPeriodId: 'periodicity.year',
				giftCardValidityTypeId: 'gift_card_validity_types.without_restrictions',
				giftCardValue: 0,
				height: 0,
				heightUnitId: null,
				kindId: null,
				labelTemplateId: null,
				length: 0,
				lengthUnitId: null,
				name: { ru: '' },
				organizationId: null,
				packageSetId: null,
				parentId: null,
				primaryImageId: null,
				priority: null,
				supplierId: null,
				typeId: 'product_types.goods',
				unitId: null,
				useArea: false,
				useCustomLabelTemplate: false,
				useFeatureOptionId: 'use_feature_options.not_used',
				useGiftCardPartially: false,
				useHeight: false,
				useLength: false,
				usePackageOptionId: 'use_package_options.not_used',
				useSerialNumbers: false,
				useVolume: false,
				useWeight: false,
				useWidth: false,
				volume: 0,
				volumeUnitId: null,
				weight: 0,
				weightCanManuallySet: false,
				weightUnitId: null,
				width: 0,
				widthUnitId: null
			},
			unit: {
				baseUnitId: null,
				deleted: false,
				displayName: null,
				elementType: 0,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				packageTypeId: 'unit_package_types.final',
				parentId: null,
				priority: null,
				quantityOfOwnerUnitIncludedInPackage: 0,
				quantityOfPackageIncludedInOwnerUnit: 1,
				quantityOfParentPackageIncludedInPackage: 0,
				typeId: null
			},
			serialNumber: {
				serialNumber: '',
				gtin: null,
				deleted: false,
				displayName: null,
				name: { ru: '' },
				ownerId: null,
				ownerType: null,
				priority: null
			}
		};
		return (
			<NatHeaderCollapseFormTemplate
				makeReactions={this.props.makeReactions}
				store={this.props.movementOfProductsFormStore}
				productSelection
				productItemTemplate={productItemTemplate}
				selectionColumns={this.state.columns}
				navTabs={[{ name: 'Товары' }, { name: 'Примечание' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				isRelatedDocumentsModalOpen={this.props.childrenProps.isRelatedDocumentsModalOpen}
				toggleNatRelatedDocumentsModal={this.props.childrenProps.toggleNatRelatedDocumentsModal}
				toggleSequenceNumberChangingAlert={this.props.childrenProps.toggleSequenceNumberChangingAlert}
				isSequenceNumberChangingModalOpen={this.props.childrenProps.isSequenceNumberChangingModalOpen}
				typeFilter={{
					nin: ['product_types.set']
				}}
				tabsContent={(toggleSelection: (status: boolean) => void) => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.childrenProps.pushEmptyTemplate(productItemTemplate);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.deleteProductItems(this.props.movementOfProductsFormStore.selectedItems);
										},
										withoutIcon: true,
										disabled: this.props.movementOfProductsFormStore.selectedItems.length === 0
									},
									{
										title: 'ПОДБОР',
										onClick: () => {
											toggleSelection(true);
										},
										withoutIcon: true,
										disabled: false
									},
									{
										title: 'ВВЕРХ',
										onClick: () => {
											catalog.moveItems(this.props.movementOfProductsFormStore.selectedItems, this.props.movementOfProductsFormStore.model.productItems as Array<any>, -1);
										},
										withoutIcon: true,
										disabled: this.props.movementOfProductsFormStore.selectedItems.length === 0
									},
									{
										title: 'ВНИЗ',
										onClick: () => {
											catalog.moveItems(this.props.movementOfProductsFormStore.selectedItems, this.props.movementOfProductsFormStore.model.productItems as Array<any>, 1);
										},
										withoutIcon: true,
										disabled: this.props.movementOfProductsFormStore.selectedItems.length === 0
									}
								]}
								inputList={[
									<div className="d-flex mr-4 search__height ml-1" key={generateRandomId()}>
										<NatValueInput<string> object={this.props.movementOfProductsFormStore} property="searchText" placeholder="Поиск" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" mobile />
									</div>
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.checkColumnVisibility(catalog.convertCellMethods(this.state.columns), _.get(this.props.movementOfProductsFormStore.model, 'productItems', []))}
										rows={this.props.movementOfProductsFormStore.model.productItems}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										highlightSearch={false}
										isVirtualScroll={false}
										showSearch={false}
										searchText={this.props.movementOfProductsFormStore.searchText}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<NatTextarea
										object={this.props.movementOfProductsFormStore.model}
										property="note"
										label=""
										placeholder="Введите примечание"
										type="text"
										inputColumnClassName="default__nat__card__body__height px-0"
										labelColumnSettings="12"
										labelColumnClassName="text-left align-self-center"
										wrapperClassName="default__nat__card__body__height"
										inputClassName="default__nat__card__body__height nat__scrollbar text-left"
										formatter={stringFormatter()}
										row={false}
										size="sm"
									/>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<Date> object={this.props.movementOfProductsFormStore.model} property="periodAt" placeholder="Введите дату" type="datetime-local" step="1" formatter={dateTimeFormatter} size="sm" label="Дата документа" />
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.movementOfProductsFormStore.model}
										property="organizationId"
										relation="organization"
										placeholder="Выберите организацию"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="organizations"
										filterWhere={{ deleted: false }}
										autoComplete="off"
										label="Организация"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.movementOfProductsFormStore.model}
										property="warehouseSenderId"
										relation="warehouseSender"
										placeholder="Выберите склад"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="warehouses"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										label="Склад отправитель"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
								<CCol md="4">
									<NatRelationInput
										object={this.props.movementOfProductsFormStore.model}
										property="warehouseRecipientId"
										relation="warehouseRecipient"
										placeholder="Выберите склад"
										type="text"
										size="sm"
										formatter={relationFormatter()}
										pluralName="warehouses"
										filterWhere={{ deleted: false, elementType: 0 }}
										autoComplete="off"
										label="Склад получатель"
										mobile
										goTo={this.props.goTo}
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatValueInput<string>
										object={this.props.movementOfProductsFormStore.model}
										property="sequenceNumber"
										placeholder="Введите номер"
										type="text"
										formatter={stringFormatter()}
										size="sm"
										autoComplete="off"
										label="Номер"
										mobile
										onClick={() => {
											if (!this.props.movementOfProductsFormStore.isSequenceNumberChangedManually) {
												this.props.childrenProps.toggleSequenceNumberChangingAlert(true);
											}
										}}
									/>
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.movementOfProductsFormStore.setValue(this.props.movementOfProductsFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.movementOfProductsFormStore.isLoading
					},
					{
						title: 'ПРОВЕСТИ',
						onClick: (e: any) => {
							this.props.childrenProps.makePosted(e, 'post', true);
						},
						disabled: this.props.movementOfProductsFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							if (this.props.movementOfProductsFormStore.model.posted) {
								this.props.childrenProps.makePosted(e, 'post', true);
							} else {
								this.props.childrenProps.makePosted(e, 'write', true);
							}
						},
						disabled: this.props.movementOfProductsFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.movementOfProductsFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							},
							{
								title: 'Связанные документы',
								onClick: () => {
									this.props.childrenProps.toggleNatRelatedDocumentsModal(true);
								}
							},
							{
								title: 'Отменить проведение',
								onClick: (e: any) => {
									this.props.childrenProps.makePosted(e, 'unpost', true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(MovementOfProductsForm));
