import { cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CCol, CFormGroup, CInput, CLabel, CRow } from '@coreui/react';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { v4 as generateRandomId } from 'uuid';

import ValueFormatter from '../Formatters/ValueFormatter';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	inputColumnClassName?: string;
	inputClassName?: string;
	type: string;
	invalid?: boolean;
	placeholder: string;
	pattern?: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	invalidFeedback?: string;
	autoComplete?: string;
	useHandleEnter?: boolean;
	verticalSwitching?: boolean;
}

@observer
class NatChipsInput<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	id: string;
	constructor(props: PropsType<T>) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.addChip = this.addChip.bind(this);
		this.removeChip = this.removeChip.bind(this);
		this.setChips = this.setChips.bind(this);
		this.getValue = this.getValue.bind(this);
		this.id = generateRandomId();
	}
	componentDidMount() {}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		this.setState({
			value: e.target.value
		});
	}
	handleFocus(event: any) {
		event.target.select();
	}
	handleEnter(event: any) {
		if (event.keyCode === 13) {
			event.preventDefault();
			if (this.props.pattern) {
				if (this.state.value.match(this.props.pattern)) {
					this.addChip(this.state.value);
				}
			} else {
				this.addChip(this.state.value);
			}
		}
	}
	addChip(value: any) {
		const chips = this.state.chips.slice();
		chips.push(value);
		this.setState({ chips, value: '' });
		this.setChips(chips);
	}
	removeChip(index: number) {
		const chips = this.state.chips.slice();
		chips.splice(index, 1);
		this.setState({ chips });
		this.setChips(chips);
	}
	parseValue(value: string): T | undefined | null {
		return this.props.formatter.parseValue(value);
	}

	renderValue(value: T | undefined | null): string {
		return this.props.formatter.renderValue(value);
	}
	setChips(value: string[]) {
		let object: any = this.props.object;
		runInAction(() => {
			object[this.props.property] = value;
		});
	}
	getValue(): T {
		return null as any;
	}
	render() {
		return (
			<div className="chips__input">
				<CFormGroup row className="w-100 align-items-center nat__form__group">
					{!_.isEmpty(this.props.label) && (
						<CLabel htmlFor={this.id} className="align-self-center text-truncate pl-3 m-0">
							{this.props.label}
						</CLabel>
					)}
					<CCol className="align-self-center pr-0">
						<CRow className="align-items-center no-gutters">
							{_.map(this.state.chips, (item, index) => {
								return (
									<CCol lg="auto" className="chip" key={index}>
										<CBadge color="danger" shape="pill" className="m-1 chip__font__size d-flex align-items-center">
											{item}
											<CButton
												onClick={() => {
													this.removeChip(index);
												}}
												className="pr-0 pt-0 pl-1 pb-0 chip__close__button">
												<CIcon content={cilX} size="sm" className="text-white" />
											</CButton>
										</CBadge>
									</CCol>
								);
							})}
							<CCol>
								<CInput
									id={this.id}
									invalid={this.props.invalid}
									type={this.props.type}
									name={this.props.property}
									placeholder={this.props.placeholder}
									onChange={this.handleChange}
									onFocus={this.handleFocus}
									value={this.state.value}
									pattern={this.props.pattern}
									className={this.props.inputClassName}
									readOnly={this.props.readonly}
									disabled={this.props.disabled}
									size={this.props.size}
									autoComplete={this.props.autoComplete}
									onKeyDown={_.get(this.props, 'useHandleEnter', true) ? this.handleEnter : () => {}}
								/>
							</CCol>
						</CRow>
					</CCol>
				</CFormGroup>
			</div>
		);
	}
}

export default NatChipsInput;
