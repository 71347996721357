import CIcon from '@coreui/icons-react';
import { CButton, CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatPolymorphicInput from '../../shared/Inputs/NatPolymorphicInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { PaymentOptionFormStoreType } from './PaymentOptionFormStore';

interface PropsType extends FormPropsType {
	paymentOptionFormStore: PaymentOptionFormStoreType;
	childrenProps: ChildrenPropsType;
	resetData(): void;
	showValueItems(data: any): void;
}
interface StateType {
	columns: Array<any>;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class PaymentOptionForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.state = {
			columns: [
				{
					id: 1,
					field: 'name',
					label: 'Имя',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{catalog.renderCatalogName(data)}</div>;
					}
				},
				{
					id: 2,
					field: 'description',
					label: 'Описание',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{data.description}</div>;
					}
				},
				{
					id: 3,
					field: 'required',
					label: 'Обязательный',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						return <div className="rgt-cell-inner">{data.required ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
					}
				},
				{
					id: 4,
					field: 'valueId',
					label: 'Значение',
					width: '250px',
					cellRenderer: ({ data }: { data: any }) => {
						if (data.valueType === 'value_types.array') {
							return (
								<CButton
									type="button"
									size="sm"
									className="mr-1 nat__button__hover"
									onClick={() => {
										this.props.paymentOptionFormStore.setValue(this.props.paymentOptionFormStore, 'isRoot', false);
										this.props.showValueItems(data);
									}}>
									К ЗНАЧЕНИЯМ
								</CButton>
							);
						} else {
							return (
								<div className="rgt-cell-inner m-1 p-0">
									<NatPolymorphicInput property="valueId" relation="value" object={data} label="" row={true} size="sm" inputClassName="text-left" goTo={this.props.goTo} />
								</div>
							);
						}
					}
				}
			]
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				store={this.props.paymentOptionFormStore}
				navTabs={[{ name: 'Аргументы' }, { name: 'Дополнительно' }]}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							{!this.props.paymentOptionFormStore.isRoot && (
								<NatControlPanel
									buttonItemList={[
										{
											title: 'К АРГУМЕНТАМ',
											onClick: () => {
												this.props.paymentOptionFormStore.setValue(this.props.paymentOptionFormStore, 'argumentItem', null);
												this.props.paymentOptionFormStore.setValue(this.props.paymentOptionFormStore, 'isRoot', true);
											},
											withoutIcon: true,
											disabled: this.props.paymentOptionFormStore.isRoot
										}
									]}
								/>
							)}
							<div className={this.props.paymentOptionFormStore.isRoot ? 'nat__form__table__wrapper mx-4 mb-4 h-100' : 'nat__form__table__wrapper m-4 h-100'}>
								<form className="nat__form__table">
									<GridTable
										columns={this.state.columns}
										rows={(() => {
											if (this.props.paymentOptionFormStore.isRoot) {
												return this.props.paymentOptionFormStore.model.argumentItems;
											} else {
												return _.get(this.props.paymentOptionFormStore, 'argumentItem.valueId', []);
											}
										})()}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										isVirtualScroll={false}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.paymentOptionFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.paymentOptionFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={
					<>
						<CCol lg="12">
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.paymentOptionFormStore.model}
										property="name"
										placeholder="Введите наименование"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										invalid={true}
										invalidFeedback="Строка не заполнена"
										autoComplete="off"
										label="Наименование"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatLocalizedValueInput<LocalizedString>
										object={this.props.paymentOptionFormStore.model}
										property="description"
										placeholder="Введите описание"
										type="text"
										formatter={localizedStringFormatter}
										size="sm"
										autoComplete="off"
										label="Описание"
										mobile
									/>
								</CCol>
							</CFormGroup>
							<CFormGroup row className="mb-0">
								<CCol md="4">
									<NatRelationInput
										object={this.props.paymentOptionFormStore.model}
										property="ownerId"
										relation="owner"
										placeholder="Выберите вид"
										type="text"
										size="sm"
										formatter={relationFormatter(true)}
										pluralName="paymentOptionKinds"
										filterWhere={{ deleted: false }}
										include={[
											{
												relation: 'argumentItems',
												scope: {
													include: ['value']
												}
											}
										]}
										invalid={_.isEmpty(this.props.paymentOptionFormStore.model.ownerId)}
										invalidFeedback="Элемент не выбран"
										autoComplete="off"
										label="Вид способа оплаты"
										mobile
									/>
								</CCol>
								<CCol md="4">
									<NatCheckbox object={this.props.paymentOptionFormStore.model} property="enabled" label="Используется" />
								</CCol>
							</CFormGroup>
						</CCol>
					</>
				}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.paymentOptionFormStore.setValue(this.props.paymentOptionFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.paymentOptionFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.paymentOptionFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.paymentOptionFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(PaymentOptionForm));
