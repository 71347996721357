import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { AcquiringOperationType, FormContainerPropsType } from '../../shared/support/modelTypes';
import AcquiringOperationForm from './AcquiringOperationForm';
import { AcquiringOperationFormStoreType } from './AcquiringOperationFormStore';

interface PropsType extends FormContainerPropsType<AcquiringOperationFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

interface StateType {
	isAlertOpen: boolean;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class AcquiringOperationFormContainer extends Component<PropsType, StateType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.toggleAlert = this.toggleAlert.bind(this);
		this.state = {
			isAlertOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.specificationItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'specificationItems', [])));
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.contractId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'specificationItems', []), (item) => {
							item.contractId = value;
							if (!_.isEmpty(this.props.store.model.contract)) {
								item.contract = this.props.store.model.contract;
							}
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.organizationId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'specificationItems', []), (item) => {
							item.organizationId = value;
							if (!_.isEmpty(this.props.store.model.organization)) {
								item.organization = this.props.store.model.organization;
							}
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.partnerId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'specificationItems', []), (item) => {
							item.partnerId = value;
							if (!_.isEmpty(this.props.store.model.partner)) {
								item.partner = this.props.store.model.partner;
							}
						});
						if (!_.isEmpty(value)) {
							catalog.fetchApplicableContract(this.props.store);
						} else {
							this.props.store.model.contractId = null;
							delete this.props.store.model.contract;
						}
					}
				}
			)
		);
		_.forEach(_.get(this.props.store.model, 'specificationItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			});
			this.formReactions.push(
				reaction(
					() => item.amount,
					(value, previousValue) => {
						if (item.amount !== previousValue) {
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'specificationItems', [])));
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: AcquiringOperationType) {
		return model;
	}
	prepareModelForPost(model: AcquiringOperationType) {
		_.forEach(model.specificationItems, (item) => {
			delete item.document;
			delete item.order;
			delete item.organization;
			delete item.partner;
			delete item.contract;
		});
		runInAction(() => {
			model.specificationItemList = model.specificationItems;
		});
		delete model.organization;
		delete model.partner;
		delete model.paymentOption;
		delete model.status;
		delete model.contract;
		delete model.type;
		return model;
	}
	validate() {
		let specificationItemsGroupedByOrganizationId = _.chain(this.props.store.model.specificationItems).groupBy('organizationId').value();
		let specificationItemsGroupedByPartnerId = _.chain(this.props.store.model.specificationItems).groupBy('partnerId').value();
		return (
			specificationItemsGroupedByPartnerId['null'] === undefined &&
			specificationItemsGroupedByOrganizationId['null'] === undefined &&
			!_.isEmpty(this.props.store.model.partnerId) &&
			!_.isEmpty(this.props.store.model.organizationId) &&
			!_.isEmpty(this.props.store.model.statusId) &&
			!_.isEmpty(this.props.store.model.paymentOptionId) &&
			!_.isEmpty(this.props.store.model.typeId)
		);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	toggleAlert(status: boolean) {
		this.setState({
			isAlertOpen: status
		});
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				isDocument
				parentRoute="/acquiringOperations/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<AcquiringOperationForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						acquiringOperationFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
						isAlertOpen={this.state.isAlertOpen}
						toggleAlert={this.toggleAlert}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default AcquiringOperationFormContainer;
