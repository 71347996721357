import { observable, action, makeObservable, set } from 'mobx';
import { v4 as generateRandomId } from 'uuid';

import { ChipsBooleanType, ChipsDateType, ChipsRelationType, ChipsStringType } from '../../shared/support/modelTypes';

export interface ResponsiblePersonsListStoreType {
	itemsPerPage: number;
	currentPage: number;
	list: Array<any>;
	responseCode: number;
	filterOrder: Array<string>;
	filterSkip: number;
	filterWhere: { deleted?: boolean };
	pluralName: string;
	collectedModels: Array<any>;
	sortConfig: { collId: string; isAsc: boolean };
	chips: Array<any>;
	chipsFields: { name: ChipsStringType; position: ChipsStringType; createdAtGt: ChipsDateType; createdAtLt: ChipsDateType; deleted: ChipsBooleanType; owner: ChipsRelationType };
	filterInclude: Array<string>;
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class ResponsiblePersonsListStore implements ResponsiblePersonsListStoreType {
	constructor() {
		makeObservable(this, {
			itemsPerPage: observable,
			currentPage: observable,
			list: observable,
			responseCode: observable,
			filterOrder: observable,
			filterSkip: observable,
			filterWhere: observable,
			pluralName: observable,
			collectedModels: observable,
			sortConfig: observable,
			chips: observable,
			chipsFields: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}
	name = 'OrmResponsiblePerson';
	itemsPerPage = 50;
	currentPage = 0;
	list = [] as Array<any>;
	responseCode = 0;
	filterOrder = ['createdAt DESC'];
	filterInclude = ['type', 'naturalPerson', 'owner'];
	filterSkip = 0;
	filterWhere = { deleted: false };
	pluralName = 'responsiblePersons';
	collectedModels = [] as Array<any>;
	sortConfig = { collId: '', isAsc: false };
	chips = [];
	isFirstLoad = true;
	chipsFields = {
		name: {
			id: null,
			chips: [],
			title: 'Наименование',
			isLocalizedString: true
		},
		owner: {
			isRelation: true,
			id: null,
			chips: []
		},
		position: {
			id: null,
			chips: [],
			title: 'Должность'
		},
		createdAtGt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: true
		},
		createdAtLt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: false
		},
		deleted: {
			id: false,
			display: { isChecked: false, title: 'Отображать' },
			doNotDisplay: { isChecked: true, title: 'Не отображать' },
			chips: [
				{
					id: generateRandomId(),
					name: {
						ru: 'Удаленные: не отображать'
					}
				}
			],
			isBoolean: true,
			title: 'Удаленные'
		}
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const responsiblePersonsListStore = new ResponsiblePersonsListStore();

export default ResponsiblePersonsListStore;
