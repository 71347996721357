import CIcon from '@coreui/icons-react';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import NatList from '../../shared/Components/NatList';
import NatListContainer from '../../shared/Components/NatListContainer';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType } from '../../shared/support/modelTypes';
import { SalesReportsListStoreType } from './SalesReportsListStore';

interface PropsType extends FormContainerPropsType<SalesReportsListStoreType> {}

interface StateType {}

interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
}

@inject('directoryStore', 'userStore')
@observer
class SalesReportsListContainer extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.resetSearchFields = this.resetSearchFields.bind(this);
		this.createFilter = this.createFilter.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	createFilter() {
		// if (!_.isEmpty(salesReportsSearchFields.name)) {
		// 	or = [
		// 		{
		// 			sequenceNumber: {
		// 				like: salesReportsSearchFields.name
		// 			}
		// 		}
		// 	];
		// 	filter.where.or = or;
		// }
	}
	resetSearchFields() {
		runInAction(() => {
			this.props.store.chipsFields = {
				warehouse: {
					isRelation: true,
					id: null,
					chips: []
				},
				cashRegister: {
					isRelation: true,
					id: null,
					chips: []
				},
				sequenceNumber: {
					id: null,
					chips: [],
					title: 'Номер'
				},
				periodAtGt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: true,
					property: 'periodAt'
				},
				periodAtLt: {
					isDate: true,
					id: null,
					chips: [],
					isGt: false,
					property: 'periodAt'
				},
				deleted: {
					id: false,
					display: { isChecked: false, title: 'Отображать' },
					doNotDisplay: { isChecked: true, title: 'Не отображать' },
					chips: [
						{
							id: generateRandomId(),
							name: {
								ru: 'Удаленные: не отображать'
							}
						}
					],
					isBoolean: true,
					title: 'Удаленные'
				}
			};
		});
	}
	render() {
		return (
			<NatListContainer store={this.props.store} createFilter={this.createFilter} resetSearchFields={this.resetSearchFields} defaultFilterWhere={{ deleted: false }} navigationRoute={this.props.navigationRoute} index={this.props.index}>
				{(childrenProps) => (
					<NatList
						name="salesReports"
						store={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						// dontCreateNewModel
						dontDeleteModels
						columns={[
							{
								id: 'checkbox',
								pinned: true,
								className: '',
								width: '54px',
								minResizeWidth: 0,
								maxResizeWidth: null,
								resizable: false,
								visible: true,
								headerCellRenderer: () => {
									return <div></div>;
								},
								cellRenderer: ({ onChange, value }: { onChange: any; value: any }) => {
									return (
										<div className="rgt-cell-inner ml-0 mr-0">
											<input type="checkbox" id="checkbox" onChange={onChange} checked={value} />
										</div>
									);
								}
							},
							{
								id: 1,
								field: 'posted',
								label: '',
								width: '70px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div className="rgt-cell-inner">
											<CIcon src={catalog.pickupDocumentStateImage(data)} size="sm" />
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 2,
								field: 'sequenceNumber',
								label: 'Номер',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{data.sequenceNumber}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 3,
								field: 'createdAt',
								label: 'Дата создания',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{listDateTimeFormatter.renderValue(data.createdAt)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 4,
								field: 'warehouse',
								label: 'Склад',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.warehouse)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 5,
								field: 'cashRegister',
								label: 'Касса ККМ',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary';
												} else {
													return 'rgt-cell-inner rgt-text-truncate';
												}
											})()}>
											{catalog.renderCatalogName(data.cashRegister)}
										</div>
									);
								},
								sort: () => {}
							},
							{
								id: 6,
								field: 'note',
								label: 'Примечание',
								width: '220px',
								cellRenderer: ({ data }: { data: any }) => {
									return (
										<div
											className={(() => {
												if (data.deleted) {
													return 'rgt-cell-inner rgt-text-truncate text-secondary text-right';
												} else {
													return 'rgt-cell-inner rgt-text-truncate text-right';
												}
											})()}>
											{data.note}
										</div>
									);
								},
								sort: () => {}
							}
						]}>
						<>
							<NatRelationInput
								object={this.props.store.chipsFields.warehouse}
								property="id"
								relation="relation"
								placeholder="Выберите склад"
								type="text"
								size="sm"
								formatter={relationFormatter(true)}
								pluralName="warehouses"
								filterWhere={{ elementType: 0, deleted: false }}
								autoComplete="off"
								label="Склад"
							/>
							<NatRelationInput
								object={this.props.store.chipsFields.cashRegister}
								property="id"
								relation="relation"
								placeholder="Выберите кассу"
								type="text"
								size="sm"
								formatter={relationFormatter(true)}
								pluralName="cashRegisters"
								filterWhere={{ deleted: false }}
								placement="bottom-end"
								autoComplete="off"
								label="Касса ККМ"
							/>
						</>
					</NatList>
				)}
			</NatListContainer>
		);
	}
}

export default SalesReportsListContainer;
