import { useLocation } from 'react-router-dom';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const UseLocation = (Component: any) => {
	const InjectedQuery = function (props: any) {
		const query = useQuery();
		const search = useLocation().search;
		const location = useLocation();
		return <Component query={query} search={search} loc={location} {...props} />;
	};
	return InjectedQuery;
};
