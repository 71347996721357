import _ from 'lodash';
import { observer } from 'mobx-react';
import { Component, ReactElement } from 'react';
import { v4 as generateRandomId } from 'uuid';

import { FileType } from '../support/modelTypes';

interface PropsType {
	children: ReactElement;
	modelStore: any;
	upload(data: any): void;
	parentGroup: FileType;
}
interface StateType {
	dragActive: boolean;
}

@observer
class NatDropImageLoad extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.dropHandler = this.dropHandler.bind(this);
		this.dragoverHandler = this.dragoverHandler.bind(this);
		this.dragHandler = this.dragHandler.bind(this);
		this.state = {
			dragActive: false
		};
	}
	dragHandler(e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === 'dragenter' || e.type === 'dragover') {
			this.setState({
				dragActive: true
			});
		} else if (e.type === 'dragleave') {
			this.setState({
				dragActive: false
			});
		}
	}
	dropHandler(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			dragActive: false
		});
		if (e.dataTransfer.files) {
			let data = new FormData();
			let metadata: any = {};
			_.forEach(e.dataTransfer.files, (item) => {
				data.append(item.name, item);
				metadata[item.name] = {
					id: generateRandomId(),
					name: item.name,
					parentId: this.props.parentGroup.id,
					ownerId: this.props.modelStore.model.id as string,
					ownerType: this.props.modelStore.ownerType
				};
			});
			data.append('metadata', JSON.stringify(metadata));
			this.props.upload(data);
		}
	}
	dragoverHandler(e: any) {
		e.preventDefault();
		e.dataTransfer.dropEffect = 'move';
	}
	render() {
		return (
			<div onDragEnter={this.dragHandler} id="fileUpload" className={this.state.dragActive ? 'dragActive' : ''}>
				{this.props.children}
				{this.state.dragActive && <div id="dragFileElement" onDragEnter={this.dragHandler} onDragLeave={this.dragHandler} onDragOver={this.dragoverHandler} onDrop={this.dropHandler}></div>}
			</div>
		);
	}
}

export default NatDropImageLoad;
