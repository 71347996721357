import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { numberFormatter } from '../../shared/Formatters/NumberFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatChipsInput from '../../shared/Inputs/NatChipsInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { models } from '../../shared/support/NatModels';
import { MatchType } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';

export interface ChildrenPropsType {
	postModel(e: any, saveWithoutClosing?: boolean): void;
	toggleAclModal(status: boolean): void;
	isAclModalOpen: boolean;
	selectInputComponent(constant: any): any;
}
interface PropsType extends RouteComponentProps {
	match: MatchType;
	index: number;
	navigationRoute: { dataTab: string; url: string; displayed: boolean; cloaseble: boolean; parentRoute: string; title: string };
	query: any;
	title: string;
	replace(newUrl: string, navigationRoute: any): void;
	makeReactions(): void;
	extractStore(store: any): void;
	parentRoute: string;
	store: any;
	children(childrenProps: ChildrenPropsType): ReactElement;
	getChildMethod(method: any, isDocument?: boolean): void;
	prepareForPost(list: Array<any>): any;
}

interface StateType {
	isAclModalOpen: boolean;
	plurals: any;
}
interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class NatSettingsContainer extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	inputs: any;
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.formReactions = [];
		this.makeReactions = this.makeReactions.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.bulkUpsert = this.bulkUpsert.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.findModel = this.findModel.bind(this);
		this.toggleAclModal = this.toggleAclModal.bind(this);
		this.selectInputComponent = this.selectInputComponent.bind(this);
		this.buildFormRows = this.buildFormRows.bind(this);
		this.inputs = {
			['value_types.relation']: (constant: any) => {
				return (
					<NatRelationInput
						object={constant}
						property="valueId"
						relation="value"
						placeholder="Выберите из списка"
						type="text"
						size="sm"
						formatter={relationFormatter()}
						pluralName={models[constant.valueRelationType].plural}
						filterWhere={!_.isEmpty(this.state.plurals[constant.valueRelationType]) ? this.state.plurals[constant.valueRelationType].where : { deleted: false }}
						autoComplete="off"
						label={constant.name[this.injected.directoryStore.models.language]}
						mobile
					/>
				);
			},
			['value_types.boolean']: (constant: any) => {
				return <NatCheckbox object={constant} property="valueId" label={constant.name[this.injected.directoryStore.models.language]} />;
			},
			['value_types.number']: (constant: any) => {
				return (
					<NatValueInput<number>
						object={constant}
						property="valueId"
						placeholder="Введите число"
						type="text"
						pattern="^([0-9]*[.,])?[0-9]*$"
						formatter={numberFormatter}
						size="sm"
						label={constant.name[this.injected.directoryStore.models.language]}
						mobile
					/>
				);
			},
			['value_types.string']: (constant: any) => {
				return (
					<NatValueInput<string>
						object={constant}
						property="valueId"
						placeholder="Введите строку"
						type="text"
						formatter={stringFormatter()}
						size="sm"
						autoComplete="off"
						label={constant.name[this.injected.directoryStore.models.language]}
						mobile
					/>
				);
			},
			['value_types.array']: (constant: any) => {
				return (
					<NatChipsInput<string>
						object={constant}
						property="valueId"
						placeholder="Введите строку"
						type="text"
						formatter={stringFormatter()}
						size="sm"
						inputClassName="chips__input__field"
						pattern="^[^ ]+@[^ ]+\.[a-z]{2,3}$"
						autoComplete="off"
						label={constant.name[this.injected.directoryStore.models.language]}
					/>
				);
			}
		};
		this.state = {
			isAclModalOpen: false,
			plurals: {
				OrmWarehouse: {
					plural: 'warehouses',
					where: { deleted: false, elementType: 0 }
				},
				OrmOrganization: {
					plural: 'organizations',
					where: { deleted: false }
				},
				OrmPriceType: {
					plural: 'priceTypes',
					where: { deleted: false }
				},
				OrmCashBox: {
					plural: 'cashBoxs',
					where: { deleted: false }
				},
				OrmPaymentOption: {
					plural: 'paymentOptions',
					where: { deleted: false }
				},
				OrmProductSegment: {
					plural: 'productSegments',
					where: { deleted: false, elementType: 0 }
				},
				OrmStore: {
					plural: 'stores',
					where: { deleted: false, elementType: 0 }
				},
				OrmLoyaltyCardKind: {
					plural: 'loyaltyCards',
					where: { deleted: false }
				},
				OrmProduct: {
					plural: 'products',
					where: { deleted: false, elementType: 0 }
				}
			}
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		_.forEach(this.props.store.list, (item) => {
			this.formReactions.push(
				reaction(
					() => item.valueId,
					(value, previousValue) => {
						if (value !== previousValue) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
		_.forEach(this.props.store.formRows, (item) => {
			this.formReactions.push(
				reaction(
					() => item.first.valueId,
					(value, previousValue) => {
						if (value !== previousValue) {
							let constantsMap = _.chain(this.props.store.list).keyBy('id').value();
							if (constantsMap[item.first.id] !== undefined) {
								constantsMap[item.first.id].valueId = value;
								if (item.first.valueType === 'value_types.relation') {
									constantsMap[item.first.id].valueRelationValue = value;
								}
							}
						}
					}
				)
			);
			if (!_.isEmpty(item.second)) {
				this.formReactions.push(
					reaction(
						() => item.second.valueId,
						(value, previousValue) => {
							if (value !== previousValue) {
								let constantsMap = _.chain(this.props.store.list).keyBy('id').value();
								if (constantsMap[item.second.id] !== undefined) {
									constantsMap[item.second.id].valueId = value;
									if (item.second.valueType === 'value_types.relation') {
										constantsMap[item.second.id].valueRelationValue = value;
									}
								}
							}
						}
					)
				);
			}
		});
	}
	componentDidMount() {
		if (this.props.navigationRoute.url === this.props.match.url) {
			if (this.props.store.isFirstLoad) {
				this.loadPage();
				this.props.store.setValue(this.props.store, 'isFirstLoad', false);
			} else {
				this.makeReactions();
			}
			this.props.getChildMethod(this.bulkUpsert);
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	loadPage() {
		document.title = 'Настройки';
		this.findModel();
	}
	findModel() {
		let filter = {
			where: this.props.store.filterWhere,
			include: this.props.store.filterInclude
		};
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					const array = data;
					this.buildFormRows(array);
					this.props.store.setValue(this.props.store, 'list', array);
				}
				catalog.generateTitle(this.props.navigationRoute, '', '', this.props.title);
				this.props.store.setValue(this.props.store, 'isChanged', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
			});
	}
	onBlur(e: any) {
		let form = e.target.form;
		let inputs = form.querySelectorAll('input');
		inputs.forEach((item: any) => {
			item.blur();
		});
	}
	bulkUpsert(e: any, saveWithoutClosing?: boolean) {
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		this.onBlur(e);
		this.props.store.setValue(this.props.store, 'isLoading', true);
		let list = this.props.prepareForPost(this.props.store.list);
		controller
			.bulkUpsert(this.props.store.pluralName, list)
			.then(() => {
				this.props.store.setValue(this.props.store, 'isLoading', false);
				this.props.store.setValue(this.props.store, 'responseCode', 200);
				catalog.showAlert('Запись прошла успешно');
				if (saveWithoutClosing) {
					this.findModel();
					runInAction(() => {
						this.props.navigationRoute.title = this.props.title;
					});
					this.props.replace(this.props.parentRoute, this.props.navigationRoute);
					let filter = {
						where: { deleted: false },
						include: ['value']
					};
					return controller.findAll('constants', filter);
				}
			})
			.then((data) => {
				if (!_.isEmpty(data)) {
					this.injected.directoryStore.setValue(this.injected.directoryStore.models, 'constants', _.chain(data).keyBy('predefinedName').value());
				}
				this.props.store.setValue(this.props.store, 'isLoading', false);
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	toggleAclModal(status: boolean) {
		this.setState({
			isAclModalOpen: status
		});
	}
	buildFormRows(constants: Array<any>) {
		let constantsForRows: Array<any> = _.chain(constants).cloneDeep().orderBy(['valueType'], ['desc']).chunk(2).value();
		let result: Array<any> = [];
		_.transform(
			constantsForRows,
			(result: any, value: any) => {
				result.push(_.zipObject(['first', 'second'], value));
			},
			result
		);
		this.props.store.setValue(this.props.store, 'formRows', result);
		this.makeReactions();
	}
	selectInputComponent(constant: any) {
		return this.inputs[constant.valueType](constant);
	}
	render() {
		let childrenProps = {
			postModel: this.bulkUpsert,
			toggleAclModal: this.toggleAclModal,
			isAclModalOpen: this.state.isAclModalOpen,
			selectInputComponent: this.selectInputComponent
		};
		return <>{this.props.children(childrenProps)}</>;
	}
}

export default UseLocation(withRouter(NatSettingsContainer));
