import CIcon from '@coreui/icons-react';
import { CCol, CFormGroup } from '@coreui/react';
import GridTable from '@nadavshaar/react-grid-table';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import AlertDialog from '../../shared/Alerts/AlertDialog';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatControlPanel from '../../shared/Components/NatControlPanel';
// import NatAclMapping from '../../shared/Components/NatAclMapping';
import { ChildrenPropsType } from '../../shared/Components/NatDirectoryFormContainer';
import NatHeaderCollapseFormTemplate from '../../shared/Components/NatHeaderCollapseFormTemplate';
import { dateFormatter } from '../../shared/Formatters/DateFormatter';
import { listDateTimeFormatter } from '../../shared/Formatters/ListDateTimeFormatter';
import { localizedStringFormatter } from '../../shared/Formatters/LocalizedStringFormatter';
import { numberNullableFormatter } from '../../shared/Formatters/NumberNullableFormatter';
import { relationFormatter } from '../../shared/Formatters/RelationFormatter';
import { stringFormatter } from '../../shared/Formatters/StringFormatter';
import NatCheckbox from '../../shared/Inputs/NatCheckBox';
import NatLocalizedValueInput from '../../shared/Inputs/NatLocalizedValueInput';
import NatRelationInput from '../../shared/Inputs/NatRelationInput';
import NatValueInput from '../../shared/Inputs/NatValueInput';
import { catalog } from '../../shared/support/Catalog';
import { FormPropsType, LocalizedString } from '../../shared/support/modelTypes';
import { UseLocation } from '../../shared/support/useLocationHoC';
import { PartnerFormStoreType } from './PartnerFormStore';

interface PropsType extends FormPropsType {
	partnerFormStore: PartnerFormStoreType;
	childrenProps: ChildrenPropsType;
}

interface StateType {
	activeTab: string;
}
interface InjectedProps extends PropsType {
	directoryStore: OrmStoreType;
	userStore: OrmUserStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('directoryStore', 'userStore', 'formAlertStore')
@observer
class PartnerForm extends Component<PropsType, StateType> {
	constructor(props: PropsType) {
		super(props);
		this.state = {
			activeTab: 'contracts'
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	render() {
		return (
			<NatHeaderCollapseFormTemplate
				alert={
					<AlertDialog
						isModalOpen={this.props.childrenProps.modals.includes('contracts')}
						title="Выполнить действие?"
						buttonItemList={[
							{
								title: 'Да',
								onClick: () => {
									this.props.childrenProps.toggleDeleteModal('contracts');
									this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', true);
									catalog
										.makeDeleted('partnerContracts', this.props.partnerFormStore.collectedModels)
										.then(() => {
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'collectedModels', []);
											return controller.fetchAvailableContracts(this.props.partnerFormStore.model);
										})
										.then((data) => {
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'list', []);
											if (!_.isEmpty(data)) {
												this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'list', data);
											}
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', false);
										})
										.catch((error) => {
											catalog.handleNatError(error);
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', false);
										});
								}
							},
							{
								title: 'ОТМЕНА',
								onClick: () => {
									this.props.childrenProps.toggleDeleteModal('contracts');
								}
							}
						]}
					/>
				}
				store={this.props.partnerFormStore}
				navTabs={(() => {
					if (this.props.partnerFormStore.model.elementType === 0) {
						return [{ name: 'Договоры' }, { name: 'Документы' }, { name: 'Дополнительно' }];
					} else {
						return [];
					}
				})()}
				isAclModalOpen={this.props.childrenProps.isAclModalOpen}
				toggleAclModal={this.props.childrenProps.toggleAclModal}
				tabsContent={() => {
					return [
						<>
							<NatControlPanel
								buttonItemList={[
									{
										title: 'ДОБАВИТЬ',
										onClick: () => {
											this.props.goTo(`/partnerContracts/new?partnerId=${this.props.partnerFormStore.model.id}`);
										},
										withoutIcon: true,
										disabled: this.props.match.params.elementId === 'new'
									},
									{
										title: 'УДАЛИТЬ',
										onClick: () => {
											this.props.childrenProps.toggleDeleteModal('contracts');
										},
										withoutIcon: true,
										disabled: this.props.partnerFormStore.collectedModels.length === 0
									},
									{
										title: 'ОБНОВИТЬ',
										onClick: () => {
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', true);
											this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'collectedModels', []);
											controller
												.fetchAvailableContracts(this.props.partnerFormStore.model)
												.then((data) => {
													this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'list', []);
													if (!_.isEmpty(data)) {
														this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'list', data);
													}
													this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', false);
												})
												.catch((error) => {
													catalog.handleNatError(error);
													this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', false);
												});
										},
										icon: 'cil-reload',
										withoutIcon: true,
										disabled: this.props.match.params.elementId === 'new'
									}
								]}
							/>
							<div className="nat__form__table__wrapper m-4 h-100">
								<form className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods([
											{
												id: 'checkbox',
												pinned: true,
												className: '',
												width: '54px',
												minResizeWidth: 0,
												maxResizeWidth: null,
												resizable: false,
												visible: true,
												headerCellRenderer: () => {
													return <div></div>;
												},
												cellRenderer: ({ onChange }: { onChange: any; value: any }) => {
													return (
														<div className="rgt-cell-inner ml-0 mr-0">
															<input type="checkbox" id="checkbox" onChange={onChange} />
														</div>
													);
												}
											},
											{
												id: 1,
												field: 'partnerId',
												label: 'Основной',
												cellRenderer: ({ data }: { data: any }) => {
													return <div className="rgt-cell-inner">{data.id === this.props.partnerFormStore.model.contractId ? <CIcon name="cil-check-alt" size="lg" /> : ''}</div>;
												},
												sort: () => {}
											},
											{
												id: 2,
												field: 'createdAt',
												label: 'Дата создания',
												width: '150px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.createdAt)}
														</div>
													);
												}
											},
											{
												id: 3,
												field: 'contractNumber',
												label: 'Номер договора',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{data.contractNumber}
														</div>
													);
												}
											},
											{
												id: 4,
												field: 'name',
												label: 'Наименование',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{catalog.renderCatalogName(data)}
														</div>
													);
												}
											},
											{
												id: 5,
												field: 'contractDate',
												label: 'Дата договора',
												width: '150px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.contractDate)}
														</div>
													);
												}
											},
											{
												id: 6,
												field: 'startedAt',
												label: 'Начало действия',
												width: '150px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.startedAt)}
														</div>
													);
												}
											},
											{
												id: 7,
												field: 'expiredAt',
												label: 'Окончание действия',
												width: '150px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.expiredAt)}
														</div>
													);
												}
											}
										])}
										rows={this.props.partnerFormStore.list}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										onRowClick={({ data, column }: { data: any; column: any }) => {
											if (column.id !== 'checkbox') {
												this.props.goTo('/partnerContracts/' + data.id);
											}
										}}
										selectedRowsIds={_.map(this.props.partnerFormStore.collectedModels, 'id')}
										onSelectedRowsChange={(selectedRowsIds: any) => {
											this.props.childrenProps.collectModels(selectedRowsIds, this.props.partnerFormStore.list);
										}}
										highlightSearch={false}
										isVirtualScroll={false}
									/>
								</form>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<GridTable
										columns={catalog.convertCellMethods([
											{
												id: 1,
												field: 'posted',
												label: '',
												width: '70px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div className="rgt-cell-inner">
															<CIcon src={catalog.pickupDocumentStateImage(data)} size="sm" />
														</div>
													);
												},
												sort: () => {}
											},
											{
												id: 2,
												field: 'sequenceNumber',
												label: 'Номер',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{data.sequenceNumber}
														</div>
													);
												},
												sort: () => {}
											},
											{
												id: 3,
												field: 'createdAt',
												label: 'Дата создания',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{listDateTimeFormatter.renderValue(data.createdAt)}
														</div>
													);
												},
												sort: () => {}
											},
											{
												id: 4,
												field: 'defaultName',
												label: 'Имя',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate';
																}
															})()}>
															{catalog.renderCatalogName(data)}
														</div>
													);
												},
												sort: () => {}
											},
											{
												id: 5,
												field: 'amount',
												label: 'Сумма',
												width: '220px',
												cellRenderer: ({ data }: { data: any }) => {
													return (
														<div
															className={(() => {
																if (data.deleted) {
																	return 'rgt-cell-inner rgt-text-truncate text-secondary text-right';
																} else {
																	return 'rgt-cell-inner rgt-text-truncate text-right';
																}
															})()}>
															{data.amount !== undefined && catalog.renderPrice(data)}
														</div>
													);
												},
												sort: () => {}
											}
										])}
										rows={this.props.partnerFormStore.secondList}
										isPaginated={false}
										texts={{ search: 'Поиск:', totalRows: 'Кол-во строк:', columnVisibility: 'Отображение столбцов', noResults: 'Элементы отсутствуют' }}
										showSearch={false}
										showColumnVisibilityManager={false}
										showRowsInformation={false}
										onColumnsChange={() => {}}
										onRowClick={({ data }: { data: any; column: any }) => {
											if (!_.isEmpty(data.url)) {
												this.props.goTo(data.url);
											}
										}}
										highlightSearch={false}
										isVirtualScroll={false}
									/>
								</div>
							</div>
						</>,
						<>
							<div className="nat__form__table__wrapper mx-4 mb-4 h-100">
								<div className="nat__form__table">
									<CCol lg="12">
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<number>
													object={this.props.partnerFormStore.model}
													property="priority"
													placeholder="Введите число"
													type="text"
													pattern="^-?([0-9]*[.,])?[0-9]*$"
													formatter={numberNullableFormatter}
													size="sm"
													label="Приоритет"
													mobile
												/>
											</CCol>
											<CCol md="4">
												<NatLocalizedValueInput<LocalizedString>
													object={this.props.partnerFormStore.model}
													property="displayName"
													placeholder="Введите наименование"
													type="text"
													formatter={localizedStringFormatter}
													size="sm"
													autoComplete="off"
													label="Отображаемое наименование"
													mobile
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4">
												<NatValueInput<string> object={this.props.partnerFormStore.model} property="iec" placeholder="Введите код" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="КПП" mobile />
											</CCol>
											<CCol md="4">
												<NatRelationInput
													object={this.props.partnerFormStore.model}
													property="bankAccountId"
													relation="bankAccount"
													placeholder="Выберите счет"
													type="text"
													size="sm"
													formatter={relationFormatter()}
													pluralName="partnerBankAccounts"
													filterWhere={{ deleted: false }}
													autoComplete="off"
													label="Банковский счет"
													mobile
													goTo={this.props.goTo}
												/>
											</CCol>
										</CFormGroup>
										<CFormGroup row className="mb-0">
											<CCol md="4" className="d-flex">
												<NatCheckbox object={this.props.partnerFormStore.model} property="client" label="Клиент" />
											</CCol>
											<CCol md="4" className="d-flex">
												<NatCheckbox object={this.props.partnerFormStore.model} property="supplier" label="Поставщик" />
											</CCol>
										</CFormGroup>
										{this.props.partnerFormStore.model.typeId === 'partner_types.individual' && (
											<>
												<CFormGroup row className="mb-0">
													<CCol md="4">
														<NatRelationInput
															object={this.props.partnerFormStore.model}
															property="genderId"
															relation="gender"
															placeholder="Выберите пол"
															type="text"
															size="sm"
															formatter={relationFormatter()}
															pluralName="enumerationItems"
															onlySelect={true}
															filterWhere={{ ownerId: 'gender' }}
															autoComplete="off"
															label="Пол"
															mobile
															filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
														/>
													</CCol>
													<CCol md="4">
														<NatValueInput<Date> object={this.props.partnerFormStore.model} property="birthdate" placeholder="Введите дату" type="date" formatter={dateFormatter} size="sm" label="Дата рождения" />
													</CCol>
												</CFormGroup>
											</>
										)}
									</CCol>
								</div>
							</div>
						</>
					];
				}}
				fields={(() => {
					if (this.props.partnerFormStore.model.elementType === 0) {
						return (
							<>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatLocalizedValueInput<LocalizedString>
												object={this.props.partnerFormStore.model}
												property="name"
												placeholder="Введите наименование"
												type="text"
												formatter={localizedStringFormatter}
												size="sm"
												invalid={true}
												invalidFeedback="Строка не заполнена"
												autoComplete="off"
												label="Наименование"
												mobile
											/>
										</CCol>
										<CCol md="4">
											<NatValueInput<string> object={this.props.partnerFormStore.model} property="itn" placeholder="Введите инн" type="text" formatter={stringFormatter()} size="sm" autoComplete="off" label="ИНН" mobile />
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="typeId"
												relation="type"
												placeholder="Выберите тип"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="enumerationItems"
												onlySelect={true}
												filterWhere={{ ownerId: 'partner_types' }}
												autoComplete="off"
												label="Тип регистрации"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="ownershipTypeId"
												relation="ownershipType"
												placeholder="Выберите тип"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="enumerationItems"
												onlySelect={true}
												filterWhere={{ ownerId: 'partner_ownership_types' }}
												autoComplete="off"
												label="Тип владения"
												mobile
												filterOrder={['priority ASC', `name.${this.injected.directoryStore.models.language} ASC`]}
											/>
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="priceTypeId"
												relation="priceType"
												placeholder="Выберите тип"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="priceTypes"
												filterWhere={{ deleted: false }}
												autoComplete="off"
												label="Тип цены"
												mobile
												goTo={this.props.goTo}
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="contractId"
												relation="contract"
												placeholder="Выберите договор"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="partnerContracts"
												apiMethod={(): any => {
													return controller.fetchAvailableContracts(this.props.partnerFormStore.model);
												}}
												create={(pluralName, handlers) => {
													this.props.goTo(`/${pluralName}/new?partnerId=${this.props.partnerFormStore.model.id}`, {}, handlers);
												}}
												autoComplete="off"
												disabled={this.props.match.params.elementId === 'new'}
												label="Основной договор"
												mobile
											/>
										</CCol>
									</CFormGroup>
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="parentId"
												relation="parent"
												placeholder="Выберите группу"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="partners"
												filterWhere={{ deleted: false, elementType: 1 }}
												autoComplete="off"
												label="Группа"
												mobile
												treeSelect
												goTo={this.props.goTo}
											/>
										</CCol>
									</CFormGroup>
								</CCol>
							</>
						);
					} else {
						return (
							<>
								<CCol lg="12">
									<CFormGroup row className="mb-0">
										<CCol md="4">
											<NatLocalizedValueInput<LocalizedString>
												object={this.props.partnerFormStore.model}
												property="name"
												placeholder="Введите наименование"
												type="text"
												formatter={localizedStringFormatter}
												size="sm"
												invalid={true}
												invalidFeedback="Строка не заполнена"
												autoComplete="off"
												label="Наименование"
												mobile
											/>
										</CCol>
										<CCol md="4">
											<NatRelationInput
												object={this.props.partnerFormStore.model}
												property="parentId"
												relation="parent"
												placeholder="Выберите группу"
												type="text"
												size="sm"
												formatter={relationFormatter()}
												pluralName="partners"
												filterWhere={{ deleted: false, elementType: 1 }}
												autoComplete="off"
												label="Группа"
												mobile
												treeSelect
												goTo={this.props.goTo}
											/>
										</CCol>
									</CFormGroup>
								</CCol>
							</>
						);
					}
				})()}
				controlButtons={[
					{
						title: 'ОК',
						onClick: (e: any) => {
							this.props.childrenProps
								.post(e)
								.then(() => {
									this.props.removeNavigationRoute(this.props.navigationRoute);
								})
								.catch((error) => {
									catalog.handleNatError(error);
									this.props.partnerFormStore.setValue(this.props.partnerFormStore, 'isLoading', false);
								});
						},
						disabled: this.props.partnerFormStore.isLoading
					},
					{
						title: 'ЗАПИСАТЬ',
						onClick: (e: any) => {
							this.props.childrenProps.postModel(e, true);
						},
						disabled: this.props.partnerFormStore.isLoading
					},
					{
						title: 'ОТМЕНА',
						onClick: () => {
							this.props.removeNavigationRoute(this.props.navigationRoute);
						}
					},
					{
						title: this.props.partnerFormStore.model.deleted ? 'ОТМЕНИТЬ УДАЛЕНИЕ' : 'УДАЛИТЬ',
						onClick: () => {
							this.props.childrenProps.makeDeleted();
						},
						disabled: this.props.match.params.elementId === 'new'
					},
					{
						title: 'ЕЩЕ',
						onClick: () => {},
						disabled: this.props.match.params.elementId === 'new',
						children: [
							{
								title: 'Управление доступом',
								onClick: () => {
									this.props.childrenProps.toggleAclModal(true);
								}
							}
						]
					}
				]}
			/>
		);
	}
}

export default UseLocation(withRouter(PartnerForm));
