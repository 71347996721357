import { observable, action, makeObservable, set } from 'mobx';

export interface NatAclMappingStoreType {
	list: Array<any>;
	roles: Array<any>;
	currentPage: number;
	itemsPerPage: number;
	deleted: boolean;
	responseCode: number;
	filterInclude: Array<string>;
	pluralName: string;
	aclRoleTable: Array<any>;
	aclUserTable: Array<any>;
	selectedItems: Array<any>;
	collectedModels: Array<any>;
	isLoading: boolean;
	subjectType: string;
	sets: Array<any>;
	aclSetTable: Array<any>;
	setValue(model: any, key: string, value: any): void;
}

class NatAclMappingStore implements NatAclMappingStoreType {
	constructor() {
		makeObservable(this, {
			list: observable,
			roles: observable,
			currentPage: observable,
			itemsPerPage: observable,
			deleted: observable,
			responseCode: observable,
			filterInclude: observable,
			pluralName: observable,
			aclRoleTable: observable,
			aclUserTable: observable,
			selectedItems: observable,
			collectedModels: observable,
			isLoading: observable,
			subjectType: observable,
			sets: observable,
			aclSetTable: observable,
			setValue: action
		});
	}
	list = [] as Array<any>;
	sets = [] as Array<any>;
	roles = [] as Array<any>;
	currentPage = 0;
	itemsPerPage = 50;
	deleted = false;
	responseCode = 0;
	pluralName = 'aclMappings';
	filterInclude = ['source', 'principal', 'subject'];
	aclRoleTable = [];
	aclUserTable = [];
	aclSetTable = [];
	selectedItems = [] as Array<any>;
	collectedModels = [] as Array<any>;
	isLoading = false;
	subjectType = '';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const natAclMappingStore = new NatAclMappingStore();

export default NatAclMappingStore;
