export interface ButtonItem<T> {
	className?: string;
	icon: string;
	title?: string;
	onClick: T;
	to?: string;
	children?: Array<ButtonItem<T>>;
	text?: string;
	disable?: boolean;
}

abstract class ValueFormatter<T, K> {
	dontShowActionButtons: boolean;
	constructor(dontShowActionButtons?: boolean) {
		this.dontShowActionButtons = false;
		if (dontShowActionButtons) {
			this.dontShowActionButtons = dontShowActionButtons;
		}
	}
	abstract parseValue(value: K, property?: any, language?: string): T | undefined | null;

	abstract renderValue(value: T, object?: any, property?: string, renderName?: (model: any) => string, language?: string): K;

	public getActionButtons(object?: any, property?: string, pluralName?: string, buttonItems?: Array<ButtonItem<any>>): Array<ButtonItem<any>> {
		return [];
	}
	protected changeValue(value: number, object: any, validate: (object: any, value: number) => boolean, setValue: (value: number) => void, offset: number) {
		let result: number = 0;
		if (value !== null && value !== undefined) {
			result = value + offset;
		} else {
			result = result + offset;
		}
		if (validate(object, result)) {
			setValue(result);
		}
	}
}

export default ValueFormatter;
