import { observable, action, makeObservable, set } from 'mobx';

export interface PrintLabelFormStoreType {
	model: any;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class PrintLabelFormStore implements PrintLabelFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		labelTemplateId: null,
		organizationId: null,
		priceTypeId: null,
		createdAt: new Date(),
		labelTemplate: {},
		productItemList: [],
		productItems: []
	};
	searchText = '';
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = '';
	filterInclude = [];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultPriceType',
			property: 'priceTypeId',
			relation: 'priceType'
		}
	];
	isFirstLoad = true;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const printLabelFormStore = new PrintLabelFormStore();

export default PrintLabelFormStore;
