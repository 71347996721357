import { observable, action, makeObservable, set } from 'mobx';

import { RecountOfProductType } from '../../shared/support/modelTypes';

export interface RecountOfProductFormStoreType {
	model: RecountOfProductType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude: Array<any>;
	selectedItems: Array<any>;
	requiredToFill: Array<{ predefinedName: string; property: string; relation: string }>;
	isFirstLoad: boolean;
	name: string;
	isSequenceNumberChangedManually: boolean;
	searchText: string;
	setValue(model: any, key: string, value: any): void;
}

class RecountOfProductFormStore implements RecountOfProductFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			filterInclude: observable,
			selectedItems: observable,
			requiredToFill: observable,
			isFirstLoad: observable,
			name: observable,
			isSequenceNumberChangedManually: observable,
			searchText: observable,
			setValue: action
		});
	}

	model = {
		deleted: false,
		note: null,
		organizationId: null,
		posted: false,
		responsibleId: null,
		warehouseId: null,
		periodAt: null,
		authorId: null,
		samplingSchemeValue: null,
		productItemList: [],
		productItems: []
	};
	searchText = '';
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	isSequenceNumberChangedManually = false;
	pluralName = 'recountOfProducts';
	filterInclude = [
		'organization',
		'warehouse',
		{
			relation: 'productItems',
			scope: {
				include: ['unit', 'feature', 'serialNumber', 'product']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	requiredToFill = [
		{
			predefinedName: 'DefaultOrganization',
			property: 'organizationId',
			relation: 'organization'
		},
		{
			predefinedName: 'DefaultWarehouse',
			property: 'warehouseId',
			relation: 'warehouse'
		}
	];
	isFirstLoad = true;
	name = 'OrmRecountOfProducts';
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const recountOfProductFormStore = new RecountOfProductFormStore();

export default RecountOfProductFormStore;
