import { observable, action, makeObservable, set } from 'mobx';
import { v4 as generateRandomId } from 'uuid';

import { ChipsStringType, ChipsRelationType, ChipsDateType, ChipsBooleanType, ChipsPriceType } from '../../shared/support/modelTypes';

export interface IncomeCashOrdersListStoreType {
	itemsPerPage: number;
	currentPage: number;
	list: Array<any>;
	responseCode: number;
	filterOrder: Array<string>;
	filterSkip: number;
	filterWhere: { deleted?: boolean };
	filterInclude: Array<string>;
	pluralName: string;
	collectedModels: Array<any>;
	sortConfig: { collId: string; isAsc: boolean };
	chips: Array<any>;
	chipsFields: { partner: ChipsRelationType; sequenceNumber: ChipsStringType; periodAtGt: ChipsDateType; periodAtLt: ChipsDateType; deleted: ChipsBooleanType; amountGte: ChipsPriceType; amountLte: ChipsPriceType };
	isFirstLoad: boolean;
	name: string;
	setValue(model: any, key: string, value: any): void;
}

class IncomeCashOrdersListStore implements IncomeCashOrdersListStoreType {
	constructor() {
		makeObservable(this, {
			itemsPerPage: observable,
			currentPage: observable,
			list: observable,
			responseCode: observable,
			filterOrder: observable,
			filterSkip: observable,
			filterWhere: observable,
			filterInclude: observable,
			pluralName: observable,
			collectedModels: observable,
			sortConfig: observable,
			chips: observable,
			chipsFields: observable,
			isFirstLoad: observable,
			name: observable,
			setValue: action
		});
	}
	name = 'OrmIncomeCashOrder';
	chips = [];
	chipsFields = {
		partner: {
			isRelation: true,
			id: null,
			chips: []
		},
		sequenceNumber: {
			id: null,
			chips: [],
			title: 'Номер'
		},
		periodAtGt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: true,
			property: 'periodAt'
		},
		periodAtLt: {
			isDate: true,
			id: null,
			chips: [],
			isGt: false,
			property: 'periodAt'
		},
		deleted: {
			id: false,
			display: { isChecked: false, title: 'Отображать' },
			doNotDisplay: { isChecked: true, title: 'Не отображать' },
			chips: [
				{
					id: generateRandomId(),
					name: {
						ru: 'Удаленные: не отображать'
					}
				}
			],
			isBoolean: true,
			title: 'Удаленные'
		},
		amountGte: {
			isPrice: true,
			id: null,
			chips: [],
			isGte: true
		},
		amountLte: {
			isPrice: true,
			id: null,
			chips: [],
			isGte: false
		}
	};
	itemsPerPage = 50;
	currentPage = 0;
	list = [] as Array<any>;
	responseCode = 0;
	filterOrder = ['createdAt DESC'];
	filterSkip = 0;
	filterWhere = { deleted: false };
	filterInclude = ['partner'];
	pluralName = 'incomeCashOrders';
	collectedModels = [] as Array<any>;
	sortConfig = { collId: '', isAsc: false };
	isFirstLoad = true;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const incomeCashOrdersListStore = new IncomeCashOrdersListStore();

export default IncomeCashOrdersListStore;
