import _ from 'lodash';
import { observable, action, makeObservable, set } from 'mobx';

import { models } from '../support/NatModels';
import { routesMap } from '../support/Routes';

export interface NavigationGroupsChildrenItemType {
	url: string;
	title: string;
	id?: string;
}

export interface NavigationRouteType {
	dataTab: string;
	url: string;
	displayed: boolean;
	cloaseble: boolean;
	elementId?: string;
	parentRoute?: string;
	route?: any;
	store?: any;
	object?: any;
	title?: string;
	icon?: any;
	group?: string;
	x?: number | string;
	handlers?: Record<string, unknown>;
}

export interface NavigationContainerStoreType {
	routeHandlers: Record<string, Record<string, unknown>>;
	navigationRoutes: Array<NavigationRouteType>;
	// navigationList: Array<NavigationListItemType>;
	activeNavigationRoute: string;
	currentNavigationRouteIndex: number;
	stores: any;
	navTabs: any;
	navigationRoute: any;
	navigationGroups: Array<{
		group: string;
		name: string;
		icon: string;
		children: {
			create?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			wholesale?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			retail?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			catalog?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			document?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			reports?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			processing?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			nomenclatureAccounting?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			partners?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			other?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
			mainSettings?: { list: Array<NavigationGroupsChildrenItemType>; title: string };
		};
	}>;
	setValue(model: any, key: string, value: any): void;
	resetNavigationRoutes(): void;
}

class NavigationContainerStore implements NavigationContainerStoreType {
	constructor() {
		makeObservable(this, {
			navigationRoutes: observable,
			// navigationList: observable,
			activeNavigationRoute: observable,
			currentNavigationRouteIndex: observable,
			stores: observable,
			navTabs: observable,
			navigationRoute: observable,
			navigationGroups: observable,
			setValue: action,
			resetNavigationRoutes: action
		});
	}
	routeHandlers = {};
	navigationGroups = [
		{
			group: 'all',
			name: 'Все разделы',
			icon: '',
			children: {}
		},
		{
			group: 'sales',
			name: 'Продажи',
			icon: 'cil-basket',
			children: {
				create: {
					list: [
						{
							url: '/customerOrders/new',
							title: models['OrmCustomerOrder'].displayName
						},
						{
							url: '/partnerSubscriptionOperations/new',
							title: models['OrmPartnerSubscriptionOperation'].displayName
						},
						{
							url: '/saleOfProducts/new',
							title: models['OrmSaleOfProducts'].displayName
						},
						{
							url: '/refundOfProducts/new',
							title: models['OrmRefundOfProducts'].displayName
						},
						{
							url: '/partners/new',
							title: models['OrmPartner'].displayName
						},
						{
							url: '/products/new',
							title: models['OrmProduct'].displayName
						}
					],
					title: 'Создать'
				},
				wholesale: {
					list: [
						{
							url: '/customerOrders',
							title: models['OrmCustomerOrder'].pluralDisplayName
						},
						{
							url: '/partnerSubscriptionOperations',
							title: models['OrmPartnerSubscriptionOperation'].pluralDisplayName
						},
						{
							url: '/saleOfProducts',
							title: models['OrmSaleOfProducts'].pluralDisplayName
						},
						{
							url: '/refundOfProducts',
							title: models['OrmRefundOfProducts'].pluralDisplayName
						}
					],
					title: 'Оптовые продажи'
				},
				retail: {
					list: [
						{
							url: '/checks',
							title: models['OrmCheck'].pluralDisplayName
						},
						{
							url: '/salesReports',
							title: models['OrmSalesReport'].pluralDisplayName
						},
						{
							url: '/cashRegisterShifts',
							title: models['OrmCashRegisterShift'].pluralDisplayName
						}
					],
					title: 'Розничные продажи'
				},
				catalog: {
					list: [
						{
							url: '/partners',
							title: models['OrmPartner'].pluralDisplayName
						},
						{
							url: '/products',
							title: models['OrmProduct'].pluralDisplayName
						}
					],
					title: 'Справочники'
				},
				reports: {
					list: [
						{
							url: '',
							title: 'Заказы'
						},
						{
							url: '',
							title: 'Клиенты'
						},
						{
							url: '',
							title: 'Продажи по номенклатуре'
						},
						{
							url: '',
							title: 'Взаиморасчеты'
						}
					],
					title: 'Отчеты'
				}
			}
		},
		{
			group: 'warehouse',
			name: 'Склад',
			icon: 'cil-home',
			children: {
				create: {
					list: [
						{
							url: '/receiptOfProducts/new',
							title: models['OrmReceiptOfProducts'].displayName
						},
						{
							url: '/postingOfProducts/new',
							title: models['OrmPostingOfProducts'].displayName
						},
						{
							url: '/writeOffOfProducts/new',
							title: models['OrmWriteOffOfProducts'].displayName
						},
						{
							url: '/recountOfProducts/new',
							title: models['OrmRecountOfProducts'].displayName
						},
						{
							url: '/products/new',
							title: models['OrmProduct'].displayName
						}
					],
					title: 'Создать'
				},
				document: {
					list: [
						{
							url: '/receiptOfProducts',
							title: models['OrmReceiptOfProducts'].pluralDisplayName
						},
						{
							url: '/postingOfProducts',
							title: models['OrmPostingOfProducts'].pluralDisplayName
						},
						{
							url: '/writeOffOfProducts',
							title: models['OrmWriteOffOfProducts'].pluralDisplayName
						},
						{
							url: '/recountOfProducts',
							title: models['OrmRecountOfProducts'].pluralDisplayName
						}
					],
					title: 'Документы'
				},
				catalog: {
					list: [
						{
							url: '/products',
							title: models['OrmProduct'].pluralDisplayName
						},
						{
							url: '/productKinds',
							title: models['OrmProductKind'].pluralDisplayName
						},
						{
							url: '/productUnits',
							title: models['OrmProductUnit'].pluralDisplayName
						},
						{
							url: '/warehouses',
							title: models['OrmWarehouse'].pluralDisplayName
						}
					],
					title: 'Справочники'
				},
				reports: {
					list: [
						{
							url: '',
							title: 'Остатки товаров в ценах номенклатуры'
						},
						{
							url: '',
							title: 'Ведомость по товарам'
						}
					],
					title: 'Отчеты'
				}
			}
		},
		{
			group: 'finance',
			name: 'Финансы',
			icon: 'cil-bank',
			children: {
				create: {
					list: [
						{
							url: '/incomeCashOrders/new',
							title: models['OrmIncomeCashOrder'].displayName
						},
						{
							url: '/outcomeCashOrders/new',
							title: models['OrmOutcomeCashOrder'].displayName
						},
						{
							url: '/cashlessReceipts/new',
							title: models['OrmCashlessReceipt'].displayName
						},
						{
							url: '/cashlessWriteOffs/new',
							title: models['OrmCashlessWriteOff'].displayName
						},
						{
							url: '/acquiringOperations/new',
							title: models['OrmAcquiringOperation'].displayName
						},
						{
							url: '/partnerSettlementOperations/new',
							title: models['OrmPartnerSettlementOperation'].displayName
						}
					],
					title: 'Создать'
				},
				document: {
					list: [
						{
							url: '/incomeCashOrders',
							title: models['OrmIncomeCashOrder'].pluralDisplayName
						},
						{
							url: '/outcomeCashOrders',
							title: models['OrmOutcomeCashOrder'].pluralDisplayName
						},
						{
							url: '/cashlessReceipts',
							title: models['OrmCashlessReceipt'].pluralDisplayName
						},
						{
							url: '/cashlessWriteOffs',
							title: models['OrmCashlessWriteOff'].pluralDisplayName
						},
						{
							url: '/acquiringOperations',
							title: models['OrmAcquiringOperation'].pluralDisplayName
						},
						{
							url: '/partnerSettlementOperations',
							title: models['OrmPartnerSettlementOperation'].pluralDisplayName
						}
					],
					title: 'Документы'
				},
				catalog: {
					list: [
						{
							url: '/cashBoxs',
							title: models['OrmCashBox'].pluralDisplayName
						},
						{
							url: '/cashRegisters',
							title: models['OrmCashRegister'].pluralDisplayName
						},
						{
							url: '/partnerBankAccounts',
							title: models['OrmPartnerBankAccount'].pluralDisplayName
						},
						{
							url: '/organizationBankAccounts',
							title: models['OrmOrganizationBankAccount'].pluralDisplayName
						}
					],
					title: 'Справочники'
				}
			}
		},
		{
			group: 'prices',
			name: 'Цены',
			icon: 'cil-ruble',
			children: {
				create: {
					list: [
						{
							url: '/settingPricesOfProducts/new',
							title: models['OrmSettingPricesOfProducts'].displayName
						},
						{
							url: '/priceTypes/new',
							title: models['OrmPriceType'].displayName
						},
						{
							url: '/paymentOptions/new',
							title: models['OrmPaymentOption'].displayName
						}
					],
					title: 'Создать'
				},
				document: {
					list: [
						{
							url: '/settingPricesOfProducts',
							title: models['OrmSettingPricesOfProducts'].pluralDisplayName
						}
					],
					title: 'Документы'
				},
				catalog: {
					list: [
						{
							url: '/priceTypes',
							title: models['OrmPriceType'].pluralDisplayName
						},
						{
							url: '/paymentOptions',
							title: models['OrmPaymentOption'].pluralDisplayName
						},
						{
							url: '/loyaltyPolicies',
							title: models['OrmLoyaltyPolicy'].pluralDisplayName
						},
						{
							url: '/loyaltyPrograms',
							title: models['OrmLoyaltyProgram'].pluralDisplayName
						},
						{
							url: '/loyaltyCardKinds',
							title: models['OrmLoyaltyCardKind'].pluralDisplayName
						},
						{
							url: '/loyaltyCards',
							title: models['OrmLoyaltyCard'].pluralDisplayName
						},
						{
							url: '/couponKinds',
							title: models['OrmCouponKind'].pluralDisplayName
						},
						{
							url: '/coupons',
							title: models['OrmCoupon'].pluralDisplayName
						}
					],
					title: 'Справочники'
				},
				processing: {
					list: [
						{
							url: '/printLabels/new',
							title: 'Печать ценников и этикеток'
						}
					],
					title: 'Обработки'
				},
				reports: { list: [], title: 'Отчеты' }
			}
		},
		{
			group: 'directories',
			name: 'Справочники',
			icon: 'cil-folder-open',
			children: {
				nomenclatureAccounting: {
					list: [
						{
							url: '/products',
							title: models['OrmProduct'].pluralDisplayName
						},
						{
							url: '/productKinds',
							title: models['OrmProductKind'].pluralDisplayName
						},
						{
							url: '/productUnits',
							title: models['OrmProductUnit'].pluralDisplayName
						},
						{
							url: '/priceTypes',
							title: models['OrmPriceType'].pluralDisplayName
						},
						{
							url: '/warehouses',
							title: models['OrmWarehouse'].pluralDisplayName
						}
					],
					title: 'Номенклатурный учет'
				},
				partners: {
					list: [
						{
							url: '/partners',
							title: models['OrmPartner'].pluralDisplayName
						},
						{
							url: '/partnerBankAccounts',
							title: models['OrmPartnerBankAccount'].pluralDisplayName
						},
						{
							url: '/partnerContracts',
							title: models['OrmPartnerContract'].pluralDisplayName
						}
					],
					title: 'Партнеры'
				},
				loyaltyPolicies: {
					list: [
						{
							url: '/loyaltyPolicies',
							title: models['OrmLoyaltyPolicy'].pluralDisplayName
						},
						{
							url: '/loyaltyPrograms',
							title: models['OrmLoyaltyProgram'].pluralDisplayName
						},
						{
							url: '/loyaltyCardKinds',
							title: models['OrmLoyaltyCardKind'].pluralDisplayName
						},
						{
							url: '/loyaltyCards',
							title: models['OrmLoyaltyCard'].pluralDisplayName
						},
						{
							url: '/couponKinds',
							title: models['OrmCouponKind'].pluralDisplayName
						},
						{
							url: '/coupons',
							title: models['OrmCoupon'].pluralDisplayName
						}
					],
					title: 'Политики лояльности'
				},
				other: {
					list: [
						{
							url: '/organizations',
							title: models['OrmOrganization'].pluralDisplayName
						},
						{
							url: '/organizationBankAccounts',
							title: models['OrmOrganizationBankAccount'].pluralDisplayName
						},
						{
							url: '/paymentOptions',
							title: models['OrmPaymentOption'].pluralDisplayName
						},
						{
							url: '/news',
							title: models['OrmNewsPost'].pluralDisplayName
						},
						{
							url: '/cashRegisters',
							title: models['OrmCashRegister'].pluralDisplayName
						},
						{
							url: '/cashBoxs',
							title: models['OrmCashBox'].pluralDisplayName
						},
						{
							url: '/naturalPersons',
							title: models['OrmNaturalPerson'].pluralDisplayName
						},
						{
							url: '/responsiblePersons',
							title: models['OrmResponsiblePerson'].pluralDisplayName
						},
						{
							url: '/subscriptionOptions',
							title: models['OrmSubscriptionOption'].pluralDisplayName
						},
						{
							url: '/subscriptionPlans',
							title: models['OrmSubscriptionPlan'].pluralDisplayName
						}
					],
					title: 'Прочее'
				}
			}
		},
		{
			group: 'reports',
			name: 'Отчеты',
			icon: 'cil-chart-line',
			children: {
				reports: {
					list: [],
					title: 'Отчеты'
				}
			}
		},
		{
			group: 'settings',
			name: 'Параметры',
			icon: 'cil-settings',
			children: {
				mainSettings: {
					list: [
						{
							url: '/settings/main',
							title: 'Общие параметры'
						},
						{
							url: '/settings/warehouse',
							title: 'Склад'
						},
						{
							url: '/settings/loyaltyPolicy',
							title: 'Система лояльности'
						},
						{
							url: '/settings/onlineStore',
							title: 'Интернет-магазин'
						}
					],
					title: 'Параметры'
				}
			}
		}
	];
	navigationRoutes = [
		{
			dataTab: 'main',
			url: '/',
			parentRoute: '/',
			route: routesMap['/'],
			store: new routesMap['/'].store(),
			displayed: true,
			cloaseble: false,
			title: 'Главная'
		}
	];
	activeNavigationRoute = 'main';
	currentNavigationRouteIndex = 0;
	stores = {};
	navTabs = null;
	navigationRoute = _.head(this.navigationRoutes);
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
	resetNavigationRoutes() {
		let nonCloasebleNavigationRoutes: Array<any> = [];
		for (let item of this.navigationRoutes) {
			if (!item.cloaseble) {
				nonCloasebleNavigationRoutes.push(item);
			}
		}
		this.navigationRoutes = nonCloasebleNavigationRoutes;
	}
}

export const navigationContainerStore = new NavigationContainerStore();

export default NavigationContainerStore;
