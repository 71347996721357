import _ from 'lodash';
import { IReactionDisposer, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as generateRandomId } from 'uuid';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../Alerts/FormAlertStore';
import { NavigationContainerStoreType, NavigationRouteType } from '../ContainersStores/NavigationContainerStore';
import { catalog } from '../support/Catalog';
import { MatchType } from '../support/modelTypes';
import { UseLocation } from '../support/useLocationHoC';

export interface ChildrenPropsType {
	collectModels(arrayIds: Array<any>, array: Array<any>): void;
	postModel(e: any, saveWithoutClosing?: boolean): void;
	toggleAclModal(status: boolean): void;
	toggleDeleteModal(modalName: string): void;
	isAclModalOpen: boolean;
	modals: Array<string>;
	makeDeleted(): void;
	saveSelected(productItem: any): void;
	deleteProductItems(selectedItems: Array<any>, key?: string): void;
	pushEmptyTemplate(template: any, key?: string): void;
	isProductSetEditingModalOpen: boolean;
	toggleNatSetEditing(status: boolean): void;
	post(e: any): Promise<any>;
}
interface PropsType extends RouteComponentProps {
	match: MatchType;
	index: number;
	navigationRoute: NavigationRouteType;
	query: any;
	replace(newUrl: string, navigationRoute: any): void;
	makeReactions(): void;
	resetFormReactions(): void;
	loadPage(elementId: string, findModel: (elementId: string) => void, elementType?: number, parentId?: string): void;
	extractStore(store: any): void;
	fillEmptyRelations(model: any): any;
	validate(): boolean;
	prepareModelForPost(model: any): any;
	parentRoute: string;
	store: any;
	children(childrenProps: ChildrenPropsType): ReactElement;
	getChildMethod?: (method: any, isDocument?: boolean) => void;
	updateList?: (model: any) => void;
	bulkUpsert?: () => any;
	executeAfterFinding?: (model: any) => any;
	handlers?: Record<string, unknown>;
}

interface StateType {
	isAclModalOpen: boolean;
	modals: Array<string>;
	isProductSetEditingModalOpen: boolean;
}
interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class NatDirectoryFormContainer extends Component<PropsType, StateType> {
	reactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.reactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.bulkUpsert = this.bulkUpsert.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.findModel = this.findModel.bind(this);
		this.collectModels = this.collectModels.bind(this);
		this.toggleAclModal = this.toggleAclModal.bind(this);
		this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
		this.updateForm = this.updateForm.bind(this);
		this.showUpdatedData = this.showUpdatedData.bind(this);
		this.makeDeleted = this.makeDeleted.bind(this);
		this.saveSelected = this.saveSelected.bind(this);
		this.deleteProductItems = this.deleteProductItems.bind(this);
		this.pushEmptyTemplate = this.pushEmptyTemplate.bind(this);
		this.toggleNatSetEditing = this.toggleNatSetEditing.bind(this);
		this.post = this.post.bind(this);
		this.state = {
			isAclModalOpen: false,
			modals: [],
			isProductSetEditingModalOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	componentDidMount() {
		const query = this.props.query;
		const elementType = query.get('elementType');
		const parentId = query.get('parentId');
		if (this.props.navigationRoute.url === this.props.match.url) {
			if (this.props.store.isFirstLoad) {
				this.loadPage(this.props.match.params.elementId, elementType, parentId);
			} else {
				this.props.makeReactions();
				if (this.props.updateList !== undefined) {
					if (this.props.match.params.elementId !== 'new') {
						this.props.updateList(this.props.store.model);
					}
				}
			}
			if (this.props.getChildMethod) {
				this.props.getChildMethod(this.bulkUpsert);
			}
		}
	}
	componentDidUpdate() {
		if (!this.props.store.isFirstLoad) {
			this.props.makeReactions();
		}
	}
	componentWillUnmount() {
		this.reactions.forEach((dispose) => dispose());
		this.props.resetFormReactions();
	}
	loadPage(elementId: string, elementType?: number, parentId?: string) {
		document.title = 'Журнал';
		this.props.loadPage(elementId, this.findModel, elementType, parentId);
		if (elementId === 'new') {
			this.props.makeReactions();
			this.props.store.setValue(this.props.store, 'isFirstLoad', false);
		}
	}
	findModel(elementId: string) {
		let filter = {
			where: { id: elementId },
			include: this.props.store.filterInclude,
			limit: 1
		};
		this.props.store.setValue(this.props.store, 'isLoading', true);
		controller
			.findAll(this.props.store.pluralName, filter)
			.then((data) => {
				if (!_.isEmpty(data)) {
					const model = this.props.fillEmptyRelations(data[0]);
					if (this.props.executeAfterFinding) {
						this.props.executeAfterFinding(model);
					}
					this.props.store.setValue(this.props.store, 'model', model);
					this.props.store.setValue(this.props.store, 'isChanged', false);
				} else {
					catalog.showAlert('Объект не найден!');
					this.props.store.setValue(this.props.store, 'isChanged', true);
				}
				catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
				this.props.makeReactions();
				this.props.store.setValue(this.props.store, 'isFirstLoad', false);
				if (!this.props.executeAfterFinding) {
					this.props.store.setValue(this.props.store, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	onBlur(e: any) {
		let form = e.target.form;
		let inputs = form.querySelectorAll('input');
		inputs.forEach((item: any) => {
			item.blur();
		});
	}
	bulkUpsert(e: any, saveWithoutClosing?: boolean) {
		this.props.store.setValue(this.props.store, 'responseCode', 0);
		this.onBlur(e);
		const copyObject: any = this.props.prepareModelForPost(_.cloneDeep(this.props.store.model));
		if (this.props.validate()) {
			this.props.store.setValue(this.props.store, 'isLoading', true);
			if (!this.props.bulkUpsert) {
				controller
					.bulkUpsert(this.props.store.pluralName, [copyObject])
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.showUpdatedData(200, data[0], saveWithoutClosing);
							let onSave = _.get(this.props.handlers, 'onSave') as (model: any) => Promise<void>;
							if (onSave) {
								return onSave(data[0]);
							}
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.props.store.setValue(this.props.store, 'isLoading', false);
					});
			} else {
				this.props
					.bulkUpsert()
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.showUpdatedData(200, data, saveWithoutClosing);
							let onSave = _.get(this.props.handlers, 'onSave') as (model: any) => Promise<void>;
							if (onSave) {
								return onSave(data[0]);
							}
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
						this.props.store.setValue(this.props.store, 'isLoading', false);
					});
			}
		} else {
			catalog.showAlert('Ошибка при сохранении элемента! Проверьте правильность заполнения полей!');
		}
	}
	post(e: any) {
		this.onBlur(e);
		const copyObject: any = this.props.prepareModelForPost(_.cloneDeep(this.props.store.model));
		if (this.props.validate()) {
			this.props.store.setValue(this.props.store, 'isLoading', true);
			let promise: Promise<any>;
			if (!this.props.bulkUpsert) {
				promise = controller.bulkUpsert(this.props.store.pluralName, [copyObject]);
			} else {
				promise = this.props.bulkUpsert();
			}
			return promise
				.then((data) => {
					if (!_.isEmpty(data)) {
						let onSave = _.get(this.props.handlers, 'onSave') as (model: any) => Promise<void>;
						if (onSave) {
							return onSave(data[0]);
						}
					}
				})
				.then(() => {
					this.props.store.setValue(this.props.store, 'isChanged', false);
					catalog.showAlert('Запись прошла успешно!');
					this.props.store.setValue(this.props.store, 'isLoading', false);
				});
		} else {
			return Promise.reject(new Error('Ошибка при сохранении элемента! Проверьте правильность заполнения полей!'));
		}
	}
	makeDeleted() {
		this.props.store.setValue(this.props.store, 'isLoading', true);
		catalog
			.makeDeleted(this.props.store.pluralName, [this.props.store.model])
			.then((response) => {
				if (response[0].status === 200) {
					catalog.showAlert('Запись прошла успешно!');
					this.findModel(response[0].data.id);
					this.props.store.setValue(this.props.store, 'isLoading', false);
				}
			})
			.catch((error) => {
				catalog.handleNatError(error);
				this.props.store.setValue(this.props.store, 'isLoading', false);
			});
	}
	showUpdatedData(responseStatus: any, responseData: any, saveWithoutClosing?: boolean) {
		this.props.store.setValue(this.props.store, 'responseCode', responseStatus);
		catalog.showAlert('Запись прошла успешно!');
		if (saveWithoutClosing) {
			this.findModel(responseData.id);
			this.props.replace(this.props.parentRoute + responseData.id, this.props.navigationRoute);
		}
		this.props.store.setValue(this.props.store, 'isLoading', false);
	}
	collectModels(arrayIds: Array<any>, array: Array<any>) {
		let grouped = _.chain(array).cloneDeep().keyBy('id').value();
		_.map(arrayIds, (item: string) => {
			let model = grouped[item];
			if (!_.isEmpty(model)) {
				if (_.find(this.props.store.collectedModels, (itm: any) => itm.id === model.id) === undefined) {
					runInAction(() => {
						this.props.store.collectedModels.push(model);
					});
				}
			}
		});
		const buffer = [] as Array<any>;
		_.map(this.props.store.collectedModels, (item: any) => {
			if (_.find(arrayIds, (itm: string) => itm === item.id) !== undefined) {
				buffer.push(item);
			}
		});
		this.props.store.setValue(this.props.store, 'collectedModels', buffer);
	}
	toggleAclModal(status: boolean) {
		this.setState({
			isAclModalOpen: status
		});
	}
	toggleDeleteModal(modalName: string) {
		const position = this.state.modals.indexOf(modalName);
		let newDetails = this.state.modals.slice();
		if (position !== -1) {
			newDetails.splice(position, 1);
		} else {
			newDetails.push(modalName);
		}
		this.setState({
			modals: newDetails
		});
	}
	updateForm(elementId: string) {
		this.findModel(elementId);
		this.props.replace(this.props.parentRoute + elementId, this.props.navigationRoute);
	}
	saveSelected(productItem: any) {
		const currentProductItemsMap = _.chain(this.props.store.selectedItems).cloneDeep().keyBy('id').value();
		runInAction(() => {
			if (currentProductItemsMap[productItem.id] === undefined) {
				this.props.store.selectedItems.push(productItem);
			} else {
				this.props.store.selectedItems.splice(this.props.store.selectedItems.indexOf(productItem), 1);
			}
		});
	}
	deleteProductItems(selectedItems: Array<any>, key?: string) {
		// const currentProductItemsMap = _.chain(this.props.store.model.productItems).cloneDeep().keyBy('id').value();
		// const buffer: Array<any> = [];
		// _.forEach(selectedItems, (item) => {
		// 	if (currentProductItemsMap[item.id] !== undefined) {
		// 		buffer.push(item);
		// 		runInAction(() => {
		// 			_.get(this.props.store, 'model.productItems', []).splice(_.get(this.props.store, 'model.productItems', []).indexOf(item), 1);
		// 		});
		// 	}
		// });
		// _.forEach(buffer, (item) => {
		// 	runInAction(() => {
		// 		this.props.store.selectedItems.splice(this.props.store.selectedItems.indexOf(item), 1);
		// 	});
		// });
		// this.props.store.setValue(this.props.store, 'isChanged', true);
		let itemsKey: string = 'productItems';
		if (key) {
			itemsKey = key;
		}
		const currentProductItemsMap = _.chain(this.props.store.model[itemsKey]).cloneDeep().keyBy('id').value();
		_.forEach(selectedItems, (item) => {
			if (currentProductItemsMap[item.id] !== undefined) {
				runInAction(() => {
					_.get(this.props.store, `model.${itemsKey}`, []).splice(_.get(this.props.store, `model.${itemsKey}`, []).indexOf(item), 1);
				});
			}
		});
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store, 'isChanged', true);
	}
	pushEmptyTemplate(template: any, key?: string) {
		// let arr: any = _.cloneDeep(this.props.store.model.productItems);
		// let copyTemplate = _.cloneDeep(template);
		// copyTemplate.id = generateRandomId();
		// arr.push(copyTemplate);
		// this.props.store.setValue(this.props.store.model, 'productItems', arr);
		// this.props.makeReactions();
		let itemsKey: string = 'productItems';
		if (key) {
			itemsKey = key;
		}
		let arr: any = _.cloneDeep(this.props.store.model[itemsKey]);
		let copyTemplate = _.cloneDeep(template);
		copyTemplate.id = generateRandomId();
		arr.push(copyTemplate);
		this.props.store.setValue(this.props.store.model, `${itemsKey}`, arr);
		this.props.makeReactions();
	}
	toggleNatSetEditing(status: boolean) {
		this.setState({
			isProductSetEditingModalOpen: status
		});
	}
	render() {
		let childrenProps = {
			collectModels: this.collectModels,
			postModel: this.bulkUpsert,
			toggleAclModal: this.toggleAclModal,
			isAclModalOpen: this.state.isAclModalOpen,
			toggleDeleteModal: this.toggleDeleteModal,
			modals: this.state.modals,
			makeDeleted: this.makeDeleted,
			saveSelected: this.saveSelected,
			deleteProductItems: this.deleteProductItems,
			pushEmptyTemplate: this.pushEmptyTemplate,
			isProductSetEditingModalOpen: this.state.isProductSetEditingModalOpen,
			toggleNatSetEditing: this.toggleNatSetEditing,
			post: this.post
		};
		return <>{this.props.children(childrenProps)}</>;
	}
}

export default UseLocation(withRouter(NatDirectoryFormContainer));
