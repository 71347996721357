import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDocumentFormContainer from '../../shared/Components/NatDocumentFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, RefundOfProductsType } from '../../shared/support/modelTypes';
import RefundOfProductsForm from './RefundOfProductsForm';
import { RefundOfProductsFormStoreType } from './RefundOfProductsFormStore';

interface PropsType extends FormContainerPropsType<RefundOfProductsFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
}
interface StateType {
	isAlertOpen: boolean;
}
@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore')
@observer
class RefundOfProductsFormContainer extends Component<PropsType, StateType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
		this.rebuild = this.rebuild.bind(this);
		this.toggleAlert = this.toggleAlert.bind(this);
		this.state = {
			isAlertOpen: false
		};
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
					}
				}
			)
		);
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.productItems,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
							catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.organizationId,
				(value, previousValue) => {
					if (value !== previousValue) {
						_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
							item.organizationId = value;
						});
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.partnerId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							catalog.fetchApplicableContract(this.props.store, ['priceType']);
						} else {
							this.props.store.model.contractId = null;
							delete this.props.store.model.contract;
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.contractId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(value)) {
							if (!_.isEmpty(this.props.store.model.contract) && this.props.store.model.contract !== undefined) {
								if (!_.isEmpty(this.props.store.model.contract.priceType)) {
									this.props.store.model.priceType = this.props.store.model.contract.priceType;
								}
								if (!_.isEmpty(this.props.store.model.contract.priceTypeId)) {
									this.props.store.model.priceTypeId = this.props.store.model.contract.priceTypeId;
								}
							}
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.model.priceTypeId,
				(value, previousValue) => {
					if (value !== previousValue) {
						if (!_.isEmpty(this.props.store.model.productItems)) {
							if (!_.isEmpty(value) && value !== null) {
								this.toggleAlert(true);
							}
						}
					}
				}
			)
		);
		_.forEach(_.get(this.props.store.model, 'productItems', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (key !== 'feature' && key !== 'unit' && key !== 'serialNumber') {
								if (value !== previousValue) {
									this.props.store.setValue(this.props.store, 'isChanged', true);
								}
							}
							if (key === 'quantity' || key === 'price') {
								catalog.recalculateList(_.get(this.props.store.model, 'productItems', []));
							}
						}
					)
				);
			});
			this.formReactions.push(
				reaction(
					() => item.featureId,
					(value, previousValue) => {
						if (value !== previousValue) {
							if (!_.isEmpty(item.productId)) {
								this.props.store.setValue(this.props.store, 'isLoading', true);
								controller
									.updateProductItemListPrices([item], this.props.store.model.priceType)
									.then((data) => {
										if (!_.isEmpty(data)) {
											runInAction(() => {
												item.price = data[0].price;
											});
										}
										this.props.store.setValue(this.props.store, 'isLoading', false);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.props.store.setValue(this.props.store, 'isLoading', false);
									});
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.productId,
					(value, previousValue) => {
						if (value !== previousValue) {
							item.featureId = null;
							item.serialNumberId = null;
							delete item.feature;
							delete item.serialNumber;
							if (!_.isEmpty(value)) {
								if (!_.isEmpty(item.product)) {
									item.unitId = item.product.unitId;
									item.unit = item.product.unit;
								}
								this.props.store.setValue(this.props.store, 'isLoading', true);
								controller
									.updateProductItemListPrices([item], this.props.store.model.priceType)
									.then((data) => {
										if (!_.isEmpty(data)) {
											runInAction(() => {
												item.price = data[0].price;
											});
										}
										this.props.store.setValue(this.props.store, 'isLoading', false);
									})
									.catch((error) => {
										catalog.handleNatError(error);
										this.props.store.setValue(this.props.store, 'isLoading', false);
									});
							} else {
								item.unitId = null;
								delete item.product;
								delete item.unit;
							}
						}
					}
				)
			);
			this.formReactions.push(
				reaction(
					() => item.amount,
					(value, previousValue) => {
						if (item.amount !== previousValue) {
							this.props.store.setValue(this.props.store.model, 'amount', catalog.recalculateAmount(_.get(this.props.store.model, 'productItems', [])));
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				)
			);
		});
	}
	loadPage(elementId: string, findModel: (elementId: string) => void) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			catalog.setConstants(this.props.store, this.props.store.requiredToFill);
			catalog.generateTitle(this.props.navigationRoute, 'OrmDocument', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	toggleAlert(status: boolean) {
		this.setState({
			isAlertOpen: status
		});
	}
	fillEmptyRelations(model: RefundOfProductsType) {
		return model;
	}
	prepareModelForPost(model: RefundOfProductsType) {
		_.forEach(model.productItems, (item) => {
			delete item.unit;
			delete item.feature;
			delete item.serialNumber;
			delete item.product;
			delete item.organization;
		});
		runInAction(() => {
			model.productItemList = model.productItems;
		});
		runInAction(() => {
			model.bonusPointIncomeItemList = model.bonusPointIncomeItems;
		});
		runInAction(() => {
			model.bonusPointOutcomeItemList = model.bonusPointOutcomeItems;
		});
		runInAction(() => {
			model.giftCardItemList = model.giftCardItems;
		});
		delete model.organization;
		delete model.partner;
		delete model.contract;
		delete model.priceType;
		delete model.warehouse;
		return model;
	}
	validate() {
		let productItemsGrouped = _.chain(this.props.store.model.productItems).groupBy('productId').value();
		return productItemsGrouped['null'] === undefined && !_.isEmpty(this.props.store.model.partnerId) && !_.isEmpty(this.props.store.model.organizationId);
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	rebuild(productItems: any) {
		let items = _.cloneDeep(productItems);
		this.props.store.setValue(this.props.store, 'selectedItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', []);
		this.props.store.setValue(this.props.store.model, 'productItems', items);
		this.makeReactions();
	}
	render() {
		return (
			<NatDocumentFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				isDocument
				parentRoute="/refundOfProducts/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<RefundOfProductsForm
						removeNavigationRoute={this.props.removeNavigationRoute}
						refundOfProductsFormStore={this.props.store}
						navigationRoute={this.props.navigationRoute}
						goTo={this.props.goTo}
						childrenProps={childrenProps}
						resetData={this.resetData}
						makeReactions={this.makeReactions}
						isAlertOpen={this.state.isAlertOpen}
						toggleAlert={this.toggleAlert}
					/>
				)}
			</NatDocumentFormContainer>
		);
	}
}

export default RefundOfProductsFormContainer;
