import { observable, action, makeObservable, set } from 'mobx';

export interface UserLoginType {
	login: string;
	email: string;
	password: string;
	isAuth: boolean;
	isRemember: boolean;
	loginStatusCode: number;
	user: any;
	statusCode: number;
}
export interface UserRegistrationType {
	email: string;
	password: string;
	repeatPassword: string;
	redirectUrl: string;
}
export interface UserRecoveryPasswordType {
	recoveryEmail: string;
	password: string;
	userId: string;
	accessTokenId: string;
	tenantClientId: string;
}
export interface OrmUserStoreType {
	userLogin: UserLoginType;
	userRegistration: UserRegistrationType;
	userRecoveryPassword: UserRecoveryPasswordType;
	redirect: string | null;
	setValue(model: any, key: string, value: any): void;
}

class OrmUserStore implements OrmUserStoreType {
	constructor() {
		makeObservable(this, {
			userLogin: observable,
			userRegistration: observable,
			userRecoveryPassword: observable,
			redirect: observable,
			setValue: action
		});
	}
	redirect = null;
	userLogin = {
		login: '',
		email: '',
		password: '',
		isAuth: false,
		isRemember: false,
		loginStatusCode: 0,
		user: {},
		statusCode: 0
	};
	userRegistration = {
		email: '',
		password: '',
		repeatPassword: '',
		redirectUrl: window.location.origin + '?registrationConfirmed=true'
	};
	userRecoveryPassword = {
		recoveryEmail: '',
		password: '',
		userId: '',
		accessTokenId: '',
		tenantClientId: ''
	};
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const userStore = new OrmUserStore();

export default OrmUserStore;
