import { CCol, CFormGroup, CLabel, CTextarea } from '@coreui/react';
import _ from 'lodash';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { v4 as generateRandomId } from 'uuid';

import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import ValueFormatter from '../Formatters/ValueFormatter';
import NatLocalizedValueInputStore, { NatLocalizedValueInputStoreType } from './InputsStore/NatLocalizedValueInputStore';
import NatValueField, { PropsType as ValueFieldPropsType } from './NatValueField';

interface PropsType<T> extends ValueFieldPropsType {
	invalidFeedback?: string;
	autoComplete?: string;
	label?: string;
	mobile?: boolean;
	wrapperClassName?: string;
	labelColumnSettings: string;
	labelColumnClassName: string;
	inputColumnClassName?: string;
	inputClassName?: string;
	row: boolean;
	type: string;
	invalid?: boolean;
	placeholder: string;
	pattern?: string;
	formatter: ValueFormatter<T | undefined | null, string>;
	size: string;
	rows?: number;
	language: string;
}
interface InjectedProps extends PropsType<any> {
	directoryStore: OrmStoreType;
}

@inject('directoryStore')
@observer
class NatLocalizedTextarea<T> extends NatValueField<T | undefined | null, string, PropsType<T>> {
	id: string;
	natLocalizedValueInputStore: NatLocalizedValueInputStoreType;
	constructor(props: PropsType<T>) {
		super(props);
		this.natLocalizedValueInputStore = new NatLocalizedValueInputStore();
		this.id = generateRandomId();
		this.reactions.push(
			reaction(
				() => this.natLocalizedValueInputStore.language,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.setState({
							value: this.renderValue(this.getValue())
						});
					}
				}
			)
		);
		this.reactions.push(
			reaction(
				() => this.props.language,
				(value, previousValue) => {
					if (value !== previousValue) {
						this.natLocalizedValueInputStore.setValue(this.natLocalizedValueInputStore, 'language', value);
					}
				}
			)
		);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if ((this.props.pattern && e.target.value.match(this.props.pattern) != null) || !this.props.pattern) {
			this.setState({
				value: e.target.value
			});
		}
	}
	parseValue(value: string): T | undefined | null {
		let language: string = _.get(this.natLocalizedValueInputStore, 'language', 'ru');
		return this.props.formatter.parseValue(value, this.props.object[this.props.property], language);
	}

	renderValue(value: T | undefined | null): string {
		let language: string = _.get(this.natLocalizedValueInputStore, 'language', 'ru');
		return this.props.formatter.renderValue(value, language);
	}
	render() {
		return (
			<CFormGroup row={this.props.row} className={this.props.wrapperClassName}>
				{this.props.label !== '' && (
					<CCol md={this.props.labelColumnSettings} className={this.props.labelColumnClassName}>
						<CLabel>{this.props.label}</CLabel>
					</CCol>
				)}
				<CCol className={this.props.inputColumnClassName}>
					<CTextarea
						type={this.props.type}
						name={this.props.property}
						placeholder={this.props.placeholder}
						onChange={this.handleChange}
						value={this.state.value}
						pattern={this.props.pattern}
						size={this.props.size}
						className={this.props.inputClassName}
						readOnly={this.props.readonly}
						disabled={this.props.disabled}
						rows={this.props.rows}
						onBlur={() => {
							let value: T | undefined | null = this.parseValue(this.state.value);
							this.setValue(this.parseValue(this.state.value));
							this.setState({
								value: this.renderValue(value)
							});
						}}
					/>
				</CCol>
			</CFormGroup>
		);
	}
}

export default NatLocalizedTextarea;
