import _ from 'lodash';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';

import { controller } from '../../core/Controllers/OrmController';
import { OrmStoreType } from '../../core/Stores/DirectoryStore';
import { OrmUserStoreType } from '../../core/Stores/OrmUserStore';
import { FormAlertStoreType } from '../../shared/Alerts/FormAlertStore';
import NatDirectoryFormContainer from '../../shared/Components/NatDirectoryFormContainer';
import { NavigationContainerStoreType } from '../../shared/ContainersStores/NavigationContainerStore';
import { NatSelectionStoreType } from '../../shared/Modals/NatSelectionStore';
import { catalog } from '../../shared/support/Catalog';
import { FormContainerPropsType, LoyaltyPolicyRuleType } from '../../shared/support/modelTypes';
import LoyaltyPolicyRuleForm from './LoyaltyPolicyRuleForm';
import { LoyaltyPolicyRuleFormStoreType } from './LoyaltyPolicyRuleFormStore';

interface PropsType extends FormContainerPropsType<LoyaltyPolicyRuleFormStoreType> {}

interface InjectedProps extends PropsType {
	navigationContainerStore: NavigationContainerStoreType;
	userStore: OrmUserStoreType;
	directoryStore: OrmStoreType;
	formAlertStore: FormAlertStoreType;
	natSelectionStore: NatSelectionStoreType;
}

@inject('navigationContainerStore', 'userStore', 'directoryStore', 'formAlertStore', 'natSelectionStore')
@observer
class LoyaltyPolicyRuleFormContainer extends Component<PropsType> {
	formReactions: Array<IReactionDisposer>;
	constructor(props: PropsType) {
		super(props);
		this.formReactions = [];
		this.loadPage = this.loadPage.bind(this);
		this.makeReactions = this.makeReactions.bind(this);
		this.resetFormReactions = this.resetFormReactions.bind(this);
		this.fillEmptyRelations = this.fillEmptyRelations.bind(this);
		this.prepareModelForPost = this.prepareModelForPost.bind(this);
		this.validate = this.validate.bind(this);
		this.resetData = this.resetData.bind(this);
	}
	get injected() {
		return this.props as InjectedProps;
	}
	makeReactions() {
		this.resetFormReactions();
		_.forIn(this.props.store.model, (value, key) => {
			if (key !== 'isChanged') {
				this.formReactions.push(
					reaction(
						() => this.props.store.model[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			}
		});
		_.forEach(_.get(this.props.store.model, 'timeOfSaleIntervals', []), (item) => {
			_.forIn(item, (value, key) => {
				this.formReactions.push(
					reaction(
						() => item[key],
						(value, previousValue) => {
							if (value !== previousValue) {
								this.props.store.setValue(this.props.store, 'isChanged', true);
							}
						}
					)
				);
			});
		});
		this.formReactions.push(
			reaction(
				() => this.props.store.model.timeOfSaleIntervals,
				(value, previousValue) => {
					if (value !== undefined && previousValue !== undefined) {
						if (value.length !== previousValue.length) {
							this.props.store.setValue(this.props.store, 'isChanged', true);
						}
					}
				}
			)
		);
		this.formReactions.push(
			reaction(
				() => this.props.store.isChanged,
				(value, previousValue) => {
					if (value !== previousValue) {
						catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
					}
				}
			)
		);
	}
	loadPage(elementId: string, findModel: (elementId: string) => any, elementType?: number, parentId?: string) {
		document.title = 'Журнал';
		if (elementId !== 'new') {
			findModel(elementId);
		} else {
			this.props.store.setValue(this.props.store, 'responseCode', 200);
			if (!_.isEmpty(parentId)) {
				let filter = {
					where: { id: parentId },
					limit: 1
				};
				controller
					.findAll(this.props.store.pluralName, filter)
					.then((data) => {
						if (!_.isEmpty(data)) {
							this.props.store.setValue(this.props.store.model, 'parent', data[0]);
							this.props.store.setValue(this.props.store.model, 'parentId', data[0].id);
						}
					})
					.catch((error) => {
						catalog.handleNatError(error);
					});
			}
			this.props.store.setValue(this.props.store.model, 'elementType', Number(elementType));
			catalog.generateTitle(this.props.navigationRoute, 'OrmCatalog', 'form');
			this.props.store.setValue(this.props.store, 'isChanged', true);
		}
	}
	fillEmptyRelations(model: LoyaltyPolicyRuleType) {
		return model;
	}
	prepareModelForPost(model: LoyaltyPolicyRuleType) {
		_.forEach(model.timeOfSaleIntervals, (item) => {
			delete item.conditionType;
			delete item.periodicity;
		});
		runInAction(() => {
			model.timeOfSaleIntervalList = model.timeOfSaleIntervals;
		});
		delete model.paymentOption;
		delete model.loyaltyCardKind;
		delete model.partnerSegment;
		delete model.productSelectionOption;
		delete model.productSegment;
		delete model.conditionOfProviding;
		delete model.volumeOfSalesLimitationCriteria;
		delete model.validityPeriod;
		delete model.comparisonType;
		return model;
	}
	validate() {
		let result = !_.isEmpty(this.props.store.model.name[this.injected.directoryStore.models.language]) && !_.isEmpty(this.props.store.model.productSelectionOptionId) && !_.isEmpty(this.props.store.model.conditionOfProvidingId);
		if (this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.one_time_sales') {
			return result && !_.isEmpty(this.props.store.model.volumeOfSalesLimitationCriteriaId) && !_.isEmpty(this.props.store.model.comparisonTypeId);
		} else if (this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.accumulated_sales') {
			return result && !_.isEmpty(this.props.store.model.loyaltyCardKindId) && !_.isEmpty(this.props.store.model.comparisonTypeId);
		} else if (this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.payment_option') {
			return result && !_.isEmpty(this.props.store.model.paymentOptionId);
		} else if (
			this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.presence_loyalty_card' ||
			this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.absence_loyalty_card'
		) {
			return result && !_.isEmpty(this.props.store.model.loyaltyCardKindId);
		} else if (this.props.store.model.conditionOfProvidingId === 'loyalty_policy_rule_conditions_of_providing.partner_segment') {
			return result && !_.isEmpty(this.props.store.model.partnerSegmentId);
		} else {
			return result;
		}
	}
	resetFormReactions() {
		this.formReactions.forEach((dispose) => dispose());
		this.formReactions = [];
	}
	resetData() {
		this.props.store.setValue(this.props.store, 'list', []);
	}
	render() {
		return (
			<NatDirectoryFormContainer
				index={this.props.index}
				navigationRoute={this.props.navigationRoute}
				replace={this.props.replace}
				makeReactions={this.makeReactions}
				resetFormReactions={this.resetFormReactions}
				loadPage={this.loadPage}
				fillEmptyRelations={this.fillEmptyRelations}
				validate={this.validate}
				prepareModelForPost={this.prepareModelForPost}
				store={this.props.store}
				getChildMethod={this.props.getChildMethod}
				parentRoute="/loyaltyPolicyRules/"
				handlers={this.props.handlers}>
				{(childrenProps) => (
					<LoyaltyPolicyRuleForm removeNavigationRoute={this.props.removeNavigationRoute} loyaltyPolicyRuleFormStore={this.props.store} navigationRoute={this.props.navigationRoute} goTo={this.props.goTo} childrenProps={childrenProps} />
				)}
			</NatDirectoryFormContainer>
		);
	}
}

export default LoyaltyPolicyRuleFormContainer;
