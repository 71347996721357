import { observable, action, makeObservable, set } from 'mobx';

import { PaymentOptionType } from '../../shared/support/modelTypes';

export interface PaymentOptionFormStoreType {
	model: PaymentOptionType;
	list: Array<any>;
	isLoading: boolean;
	isNew: boolean;
	isChanged: boolean;
	responseCode: number;
	responseHistoryCode: number;
	pluralName: string;
	collectedModels: Array<any>;
	filterInclude?: Array<any>;
	selectedItems: Array<any>;
	isFirstLoad: boolean;
	name: string;
	isRoot: boolean;
	argumentItem: any;
	setValue(model: any, key: string, value: any): void;
}

class PaymentOptionFormStore implements PaymentOptionFormStoreType {
	constructor() {
		makeObservable(this, {
			model: observable,
			list: observable,
			isLoading: observable,
			isNew: observable,
			responseCode: observable,
			responseHistoryCode: observable,
			isChanged: observable,
			pluralName: observable,
			collectedModels: observable,
			selectedItems: observable,
			filterInclude: observable,
			isFirstLoad: observable,
			name: observable,
			isRoot: observable,
			argumentItem: observable,
			setValue: action
		});
	}

	model = {
		deleted: false,
		description: null,
		displayName: null,
		enabled: false,
		name: { ru: '' },
		ownerId: null,
		ownerType: 'OrmPaymentOptionKind',
		primaryImageId: null,
		priority: null,
		typeId: '',
		paymentDocumentType: '',
		instantPayment: false
	};
	list = [];
	isLoading = false;
	isNew = false;
	responseCode = 0;
	responseHistoryCode = 0;
	isChanged = false;
	pluralName = 'paymentOptions';
	filterInclude = [
		'type',
		'owner',
		{
			relation: 'argumentItems',
			scope: {
				include: ['value']
			}
		}
	];
	collectedModels = [] as Array<any>;
	selectedItems = [] as Array<any>;
	isFirstLoad = true;
	name = 'OrmPaymentOption';
	isRoot = true;
	argumentItem = null;
	setValue(model: any, key: string, value: any) {
		set(model, key, value);
	}
}

export const paymentOptionFormStore = new PaymentOptionFormStore();

export default PaymentOptionFormStore;
